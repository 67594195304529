import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
const MapComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    // googleMapURL:
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <>
    {props.lat && props.lng && (
      <>
        <GoogleMap
          defaultZoom={20}
          defaultCenter={{ lat: props.lat, lng: props.lng }}
          mapTypeId="satellite"
        >
          {props.isMarkerShown && (
            <Marker position={{ lat: props.lat, lng: props.lng }} />
          )}
        </GoogleMap>
      </>
    )}
  </>
));

export default MapComponent;
