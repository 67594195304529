import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  notification,
  Button,
  Modal,
  Input,
  Upload,
  Form,
  Table,
  Popover,
  Spin,
} from "antd";
import "antd/dist/antd.css";
import {
  PlusOutlined,
  FileJpgOutlined,
  DeleteOutlined,
  UploadOutlined,
  PushpinTwoTone,
  PushpinFilled,
} from "@ant-design/icons";
import { Storage } from "aws-amplify";
import { list, add, deleteRecord, update } from "../../api/Apis";
import { withAppContext } from "../../store/contextStore";
import document1 from "../../images/Document.png";
import link from "../../images/link.png";
import pdf from "../../images/PDF.png";
import { ModalTitle } from "../../StyledComponents/StyledComponents";
import Loading from "../../common/Loading";
import moment from "moment";

const Search = Input.Search;
export class ResourcesLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoading: false,
      openForm: false,
      resourceTitle: "",
      resourceImage: null,
      resourcesList: [],
      imageType: "",
      resourceLink: "",
      dataLoadingStatus: false,
      resourceUrl: "",
      filterResource: [],
      pinCount: 0,
    };
  }

  componentDidMount = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    this.getAllResources();
  };

  getAllResources = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let orgDetails = JSON.parse(localStorage.getItem("orgDetails"));

    try {
      const response = await list("virtualOfficeResources", {
        orgId: orgDetails.id,
      });
      if (response.success) {
        if (userDetails?.userProfile === "AG") {
          response.data = response.data.filter((oo) => oo.pinToShowAgents);
        }
        response.data = response.data.sort((x, y) =>
          x.pinToShowAgents === y.pinToShowAgents
            ? 0
            : x.pinToShowAgents
            ? -1
            : 1
        );
        this.setState({
          resourcesList: response.data,
          dataLoadingStatus: false,
          filterResource: response.data,
          pinCount: response.data.filter((pinData) => pinData.pinToShowAgents)
            .length,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        notification["error"]({
          message: "Something went wrong",
        });
      }
    } catch (error) {
      this.setState({
        dataLoadingStatus: false,
      });
      notification["error"]({
        message: "Something went wrong",
      });
    }
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  resourceImage: result.key,
                  imageLoading: false,
                  loading: false,
                  imageType: info.file.type,
                });
              });
            } else {
              this.setState({
                imageLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  createResource = async (values) => {
    this.setState({
      loading: true,
    });
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const submitData = {
      entityData: {
        userId: userDetails.id,
        resourceTitle: values["resourceTitle"],
        resourceLink: values["resourceLink"],
        resourceImage: this.state.resourceImage,
        imageType: this.state.imageType,
        pinToShowAgents: false,
        orgId: userDetails.orgId,
      },
    };
    console.log(submitData, "data");
    try {
      const response = await add("virtualOfficeResources", submitData);
      if (response.success) {
        this.handleReset();
        notification["success"]({
          message: "Resource added successfully.",
          description: "Thank you.",
        });
        this.getAllResources();
        this.setState({
          imageType: "",
          loading: false,
        });
      } else {
        notification["error"]({
          message: response.message,
        });
        this.setState({
          imageType: "",
          loading: false,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
      this.setState({
        loading: false,
      });
    }
  };

  handleReset = () => {
    this.setState({
      openForm: false,
      resourceTitle: "",
      resourceImage: "",
    });
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  fileDownload = async (fileName, downloadFileName, companyName) => {
    let savedFileName = downloadFileName + "_" + companyName;
    this.setState({
      dataLoadingStatus: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    console.log(url, "url123");
    const a = document.createElement("a");
    console.log(url, "url");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      dataLoadingStatus: false,
    });
    return a;
  }

  deleteResource = async (item) => {
    deleteRecord("virtualOfficeResources", item.id)
      .then((response) => {
        if (response.success) {
          this.handleReset();
          notification["success"]({
            message: `Removed Successfully`,
            description: "Thank you.",
          });
          this.getAllResources();
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };

  searchData = (event, searchType) => {
    let totalOrdersList = [];
    let searchValue = "";

    searchValue = event?.trim().toLowerCase();
    console.log(searchType, event);

    if (searchType === "text") {
      totalOrdersList = this.state.resourcesList?.filter(
        (user) =>
          user?.resourceTitle?.toLowerCase().indexOf(searchValue) > -1 ||
          user?.resourceLink?.toLowerCase().indexOf(searchValue) > -1
      );
    } else {
      totalOrdersList = this.state.resourcesList;
    }
    this.setState({
      filterResource: totalOrdersList,
    });
  };

  handleToPinFunc = (pinRecord) => {
    if (this.state.pinCount === 10 && !pinRecord?.pinToShowAgents) {
      notification["error"]({
        message:
          "Max of 10 links can be pinned. Please unpin another link to pin this one.",
      });
      return;
    }
    this.setState({
      dataLoadingStatus: true,
    });
    let data = {
      entityData: {
        pinToShowAgents:
          "pinToShowAgents" in pinRecord === true
            ? !pinRecord.pinToShowAgents
            : true,
      },
    };
    update("virtualOfficeResources", pinRecord.id, data).then((linkRes) => {
      if (linkRes.success) {
        this.getAllResources();
      }
    });
  };

  render() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const {
      resourceImage,
      dataLoadingStatus,
      filterResource,
      openForm,
      imageType,
      loading,
      resourceUrl,
    } = this.state;
    const webColumns = [
      {
        title: "",
        dataIndex: "resourceTitle",
        key: "resourceTitle",
        sorter: false,
        className: "col_styling",
        render: (resourceTitle, record) => {
          return (
            <>
              <Row>
                <Col
                  span={12}
                  className="table_desc"
                  style={{
                    textTransform: "capitalize",
                    fontSize: "16px",
                    color: "#131419",
                    fontWeight: "500",
                    display: "flex",
                  }}
                >
                  <span
                    style={{ marginRight: record.imageType ? "10px" : "0px" }}
                  >
                    {record.imageType?.includes("pdf") ? (
                      <img
                        src={pdf}
                        className="ant-icon"
                        alt="pdf"
                        style={{ width: "30px" }}
                        onClick={() =>
                          this.fileDownload(
                            record.resourceImage,
                            resourceTitle.length > 20
                              ? resourceTitle.slice(0, 20)
                              : resourceTitle,
                            "PoweredByAgents"
                          )
                        }
                      />
                    ) : record.imageType?.includes("image") ? (
                      <FileJpgOutlined
                        style={{ fontSize: "30px" }}
                        onClick={() =>
                          this.fileDownload(
                            record.resourceImage,
                            resourceTitle.length > 20
                              ? resourceTitle.slice(0, 20)
                              : resourceTitle,
                            "PoweredByAgents"
                          )
                        }
                      />
                    ) : record.imageType?.includes("doc") ||
                      record.imageType?.includes("text/xml") ? (
                      <img
                        src={document1}
                        className="ant-icon"
                        alt="document"
                        style={{ width: "30px" }}
                        onClick={() =>
                          this.fileDownload(
                            record.resourceImage,
                            resourceTitle.length > 20
                              ? resourceTitle.slice(0, 20)
                              : resourceTitle,
                            "PoweredByAgents"
                          )
                        }
                      />
                    ) : (
                      record.imageType?.includes("com") && (
                        <img
                          src={link}
                          className="ant-icon"
                          style={{ width: "30px" }}
                          alt="link"
                          onClick={() =>
                            this.fileDownload(
                              record.resourceImage,
                              resourceTitle.length > 20
                                ? resourceTitle.slice(0, 20)
                                : resourceTitle,
                              "PoweredByAgents"
                            )
                          }
                        />
                      )
                    )}
                  </span>
                  {/* <span style={{ display: "flex" }}> */}
                  {resourceTitle}

                  {/* </span> */}
                </Col>
                <Col
                  span={12}
                  className="table_desc"
                  style={{
                    textTransform: "capitalize",
                    fontSize: "16px",
                    color: "#131419",
                    fontWeight: "500",
                  }}
                >
                  <span style={{ fontSize: "16px", float: "right" }}>
                    {moment(record.createdAt).format("L")}
                  </span>
                </Col>
                <Col span={24} style={{ fontSize: "16px" }}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://${record?.resourceLink}`}
                    style={{ textDecoration: "none" }}
                  >
                    {record?.resourceLink}
                  </a>
                  {userDetails && userDetails.userProfile === "A" && (
                    <span style={{ fontSize: "16px", float: "right" }}>
                      <Popover
                        placement="topLeft"
                        content={
                          userDetails?.userProfile === "A" &&
                          "pinToShowAgents" in record === true ? (
                            record.pinToShowAgents ? (
                              <h3>Unpin Link </h3>
                            ) : (
                              <h3>Pin Link</h3>
                            )
                          ) : (
                            <h3>Pin Link</h3>
                          )
                        }
                      >
                        {userDetails?.userProfile === "A" &&
                        "pinToShowAgents" in record === true &&
                        record.pinToShowAgents ? (
                          <PushpinTwoTone
                            style={{ fontSize: "20px", marginRight: "10px" }}
                            onClick={() => this.handleToPinFunc(record)}
                          />
                        ) : (
                          <PushpinFilled
                            style={{ fontSize: "20px", marginRight: "10px" }}
                            onClick={() => this.handleToPinFunc(record)}
                          />
                        )}
                      </Popover>

                      <DeleteOutlined
                        className="todo-delete-option"
                        onClick={() => this.deleteResource(record)}
                      />
                    </span>
                  )}
                </Col>
              </Row>
            </>
          );
        },
      },
    ];

    return (
      <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
        <Loading enableLoading={dataLoadingStatus} />
        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <Card
            title={
              <div style={{ display: "flex" }}>
                Links/Resources
                <Col style={{ padding: "0px" }} offset={1}>
                  <Search
                    className="search"
                    size="large"
                    placeholder="Search"
                    onChange={(event) =>
                      this.searchData(event.target.value, "text")
                    }
                  />
                </Col>
              </div>
            }
            extra={
              <Button
                icon={<PlusOutlined style={{ color: "#3045d2" }} />}
                onClick={() => {
                  this.setState({
                    openForm: true,
                  });
                }}
                className="todo_button"
                style={{ float: "right", border: "1px solid #3045d2" }}
              >
                <span className="virtual_button_text btn-color">ADD</span>
              </Button>
            }
            className="card_virtual"
            hoverable={true}
            bodyStyle={{ paddingTop: "0px" }}
          >
            <Table
              columns={webColumns}
              dataSource={filterResource}
              loading={loading}
              rowKey={(record) => record.id}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "25", "50", "100"],
                total: filterResource?.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              className="resource-links headerNone"
            />
          </Card>
        </Col>

        <Modal
          className="modal-width"
          visible={openForm}
          title="Create Link/Resources"
          style={{ marginTop: "2%" }}
          onCancel={() =>
            this.setState({
              openForm: false,
              resourceImage: "",
            })
          }
          destroyOnClose={true}
          footer={
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Button
                    onClick={() =>
                      this.setState({
                        openForm: false,
                        resourceImage: "",
                      })
                    }
                    htmlType="reset"
                    type="default"
                    style={{
                      width: "100%",
                      backgroundColor: "#bbb",
                      border: "#bbb",
                      color: "#FFF",
                      height: "40px",
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    htmlType="submit"
                    form="resource"
                    type="primary"
                    style={{ width: "100%", height: "40px" }}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          }
        >
          <Form onFinish={this.createResource} id="resource">
            <Card
              style={{ marginTop: "10px" }}
              className="card_virtual"
              hoverable={true}
            >
              <ModalTitle>Resource Title</ModalTitle>

              <Form.Item
                name="resourceTitle"
                rules={[
                  {
                    required: true,
                    message: "Please input your resource title!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter resource title"
                  className="form-input"
                />
              </Form.Item>

              <ModalTitle>Resource Link</ModalTitle>

              <Form.Item
                name="resourceLink"
                rules={[
                  {
                    required: resourceImage ? false : true,
                    message: "Please input your resource link!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter resource link"
                  className="form-input"
                  onChange={(e) => {
                    this.setState({ resourceUrl: e.target.value });
                  }}
                />
              </Form.Item>

              <ModalTitle>Upload</ModalTitle>
              <Form.Item
                name="upload"
                rules={[
                  {
                    required: resourceUrl !== "" ? false : true,
                    message: "Please upload a file if any",
                  },
                ]}
                // style={{ marginTop: "1vw" }}
              >
                <Upload
                  // name="avatar"
                  // listType="picture-card"
                  // className="avatar-uploader"
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                  }}
                  showUploadList={false}
                  customRequest={this.dummyRequest}
                  onChange={(e) => this.handleUploadPic(e)}
                >
                  <Spin spinning={this.state.imageLoading}>
                    <Row>
                      {resourceImage !== null && resourceImage !== "" ? (
                        <>
                          {imageType?.includes("pdf") ? (
                            <img
                              src={pdf}
                              className="ant-icon"
                              alt="pdf"
                              style={{ width: "50px" }}
                            />
                          ) : imageType?.includes("image") ? (
                            <img
                              src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${resourceImage}`}
                              alt="avatar"
                              style={{
                                width: "100px",
                                height: "100%",
                              }}
                            />
                          ) : imageType?.includes("doc") ||
                            imageType?.includes("text/xml") ? (
                            <img
                              src={document1}
                              className="ant-icon"
                              alt="document"
                              style={{ width: "50px" }}
                            />
                          ) : (
                            imageType?.includes("com") && (
                              <img
                                src={link}
                                style={{ width: "50px" }}
                                className="ant-icon"
                                alt="link"
                              />
                            )
                          )}
                        </>
                      ) : (
                        <Button icon={<UploadOutlined />}>Upload</Button>

                        // <CameraOutlined
                        //   className="camera-icon"
                        //   style={{ fontSize: "45px" }}
                        // />
                      )}
                    </Row>
                  </Spin>
                </Upload>
              </Form.Item>
            </Card>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default withAppContext(withRouter(ResourcesLinks));
