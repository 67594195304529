import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import { Col, Row, Form, Input, Checkbox } from "antd";
import Advertisement from "../../../common/Advertisement";
const { TextArea } = Input;

class BuyerPropertyDetails2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnBuyersPage: true,
      matchingProfile: true,
    };
  }
  componentDidMount() {
    const { buyerHomeDetails } = this.props.context.state;
    if (
      buyerHomeDetails.buyerPropertyDetails2 &&
      buyerHomeDetails.buyerPropertyDetails2.showOnBuyersPage
    ) {
      this.setState({
        showOnBuyersPage:
          buyerHomeDetails.buyerPropertyDetails2 &&
          buyerHomeDetails.buyerPropertyDetails2.showOnBuyersPage &&
          buyerHomeDetails.buyerPropertyDetails2.showOnBuyersPage,
      });
    }
    if (
      buyerHomeDetails.buyerPropertyDetails2 &&
      buyerHomeDetails.buyerPropertyDetails2.showOnBuyersPage === false
    ) {
      this.setState({
        showOnBuyersPage:
          buyerHomeDetails.buyerPropertyDetails2 &&
          buyerHomeDetails.buyerPropertyDetails2.showOnBuyersPage &&
          buyerHomeDetails.buyerPropertyDetails2.showOnBuyersPage,
      });
    }
    if (
      buyerHomeDetails.buyerPropertyDetails2 &&
      buyerHomeDetails.buyerPropertyDetails2.matchingProfile === false
    ) {
      this.setState({
        matchingProfile:
          buyerHomeDetails.buyerPropertyDetails2 &&
          buyerHomeDetails.buyerPropertyDetails2.matchingProfile &&
          buyerHomeDetails.buyerPropertyDetails2.matchingProfile,
      });
    }
    if (
      buyerHomeDetails.buyerPropertyDetails2 &&
      buyerHomeDetails.buyerPropertyDetails2.matchingProfile
    ) {
      this.setState({
        matchingProfile:
          buyerHomeDetails.buyerPropertyDetails2 &&
          buyerHomeDetails.buyerPropertyDetails2.matchingProfile &&
          buyerHomeDetails.buyerPropertyDetails2.matchingProfile,
      });
    }
  }
  onFinish = (e) => {
    e["showOnBuyersPage"] = this.state.showOnBuyersPage;
    e["matchingProfile"] = this.state.matchingProfile;
    if (this.props.doBackPage) {
      this.props.handleBuyerDetails(e, "BUYER_PROPERTY_DETAILS2");
    } else {
      this.props.saveBuyerReq(e, "BUYER_PROPERTY_DETAILS2");
    }
    this.props.context.setBuyerHomeDetails(e, "BUYER_PROPERTY_DETAILS2");
  };
  handleToshowOnBuyerspage = (e) => {
    this.setState({
      showOnBuyersPage: e.target.checked,
    });
  };
  handleToMatchingProfile = (e) => {
    this.setState({
      matchingProfile: e.target.checked,
    });
  };
  render() {
    // const { buyerHomeDetails } = this.props.context.state;
    const { buyerHomeDetails } = this.props;
    return (
      <div>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="privateD-title">Buyer Details</div>
            <div style={{ marginBottom: 20 }}>
              <span className="privateD-des">
                Enter a descriptive title to tell potential sellers and listing
                agents what you are looking for in your perfect home. Tell us
                the story of your perfect home!
              </span>
            </div>
          </Col>
          <Col
            xl={{ span: 16, offset: 0 }}
            lg={{ span: 16, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Form id="form" onFinish={this.onFinish} size="large">
              <Row>
                <Col
                  xl={{ span: 11, offset: 0 }}
                  lg={{ span: 11, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                >
                  <div className="form-titles">Title</div>
                  <p style={{ marginTop: "-15px", color: "grey" }}>
                    Enter a short description for public view of buyer.
                  </p>
                  <Form.Item
                    name="title"
                    initialValue={
                      buyerHomeDetails.buyerPropertyDetails2 &&
                      buyerHomeDetails.buyerPropertyDetails2.title
                    }
                    rules={[
                      { required: true, message: "Please input your title!" },
                    ]}
                  >
                    <Input
                      placeholder="Eg. New family looking for bigger home"
                      className="form-input"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">Description of buyer needs </div>
                  <Form.Item
                    name="description"
                    initialValue={
                      buyerHomeDetails.buyerPropertyDetails2 &&
                      buyerHomeDetails.buyerPropertyDetails2.description
                    }
                  >
                    <TextArea
                      placeholder="Start typing"
                      rows={4}
                      name="description"
                      style={{ borderRadius: "5px" }}
                    />
                  </Form.Item>
                  <Form.Item name="matchingProfile">
                    <Checkbox
                      onChange={this.handleToMatchingProfile}
                      checked={this.state.matchingProfile}
                    >
                      <span className="form-titles">
                        Send me alerts when new on and off MLS properties hit
                        the market matching this profile
                      </span>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="showOnBuyersPage">
                    <Checkbox
                      onChange={this.handleToshowOnBuyerspage}
                      checked={this.state.showOnBuyersPage}
                    >
                      <span className="form-titles">
                        Check if you want this profile to show on the buyer page
                        results.
                      </span>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Advertisement
              otherAds={false}
              adType="Square"
              adWhereToShow="Buyers"
              adLimit="1"
              zipCode=""
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default withAppContext(withRouter(BuyerPropertyDetails2));
