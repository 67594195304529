import React, { Component } from "react";
import { Col, Select } from "antd";

class CheckBoxGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.plainOptions[1].label,
      defaultValueSet: [],
      showFilterCheckBoxes: false,
      staticState: true,
    };
  }

  componentDidMount() {
    this.setOldFilterData();
  }

  static getDerivedStateFromProps = (props, state) => {
    if (
      state.staticState &&
      props.checkBoxFor !== undefined &&
      props?.checkBoxFor?.length > 0 &&
      JSON.stringify(props?.checkBoxFor) !==
        JSON.stringify(state?.defaultValueSet)
    ) {
      return {
        defaultValueSet: props?.checkBoxFor,
      };
    }
  };

  setOldFilterData = () => {
    if (window.location.pathname !== "/buyers") {
      this.props.methodOldSavedSearch();
    }
    this.setState({
      showFilterCheckBoxes: true,
      defaultValueSet: this.props.checkBoxFor,
    });
  };

  onCheckBoxChange = (checkedValues, checkItemNames) => {
    this.setState({
      defaultValueSet: checkedValues,
      staticNumber: 1,
      staticState: false,
    });
    if (this.props.status) {
      this.props.handleCheckStatus(checkedValues, checkItemNames);
    } else if (this.props.Category) {
      this.props.handleCheckCategory(checkedValues, checkItemNames);
    } else if (this.props.label) {
      this.props.handleCheckLabel(checkedValues, checkItemNames);
    } else {
      this.props.handleCheckProperty(checkedValues, checkItemNames);
    }
  };

  render() {
    const { showFilterCheckBoxes, defaultValueSet } = this.state;
    return (
      <Col className="property-status-row">
        {showFilterCheckBoxes && (
          <Select
            mode={this.props.status ? "" : "multiple"}
            allowClear
            className="check-box-property-status"
            placeholder="You Can Select Multiple"
            // defaultValue={defaultValueSet}
            value={defaultValueSet}
            onChange={this.onCheckBoxChange}
            showSearch={window.screen.width >= "992" ? true : false}
          >
            {this.props.plainOptions.map((province, index) => (
              <Select.Option key={index} value={province.value}>
                {province.label}
              </Select.Option>
            ))}
          </Select>
        )}
        {this.props.setOldSavedSearch && this.setOldFilterData()}
      </Col>
    );
  }
}

export default CheckBoxGroup;
