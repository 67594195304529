import React from "react";
import { Layout, Row, Col, Button } from "antd";
import Filter from "../filter/Filter";
import Advertisement from "../../common/Advertisement";
import { withAppContext } from "../../store/contextStore";
import { withRouter } from "react-router-dom";
import BuyerSearch from "../../containers/Modules/BuyerSearch";
import { InfoCircleOutlined } from "@ant-design/icons";

class BuyersTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FilterData: "",
      filterCount: 0,
      divHeight: 0,
      enableInfo: false,
    };
  }

  update = (FilterData) => {
    this.setState({
      filterCount: this.state.filterCount + 1,
      FilterData,
    });
  };

  updateDivHeight = (height) => {
    this.setState({
      divHeight: height,
    });
  };

  showAds = () => {
    return (
      <Advertisement
        otherAds={false}
        adType="Square"
        adWhereToShow="Buyers"
        adLimit="10"
        zipCode=""
      />
    );
  };

  BuyerInfo = () => {
    return (
      <Row style={{ background: "#f0f2f5" }}>
        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <p
            style={{
              fontWeight: "600",
              fontSize: "15px",
              color: "#000",
              marginTop: "1vw",
              marginBottom: "0",
              background: "#f0f2f5",
            }}
          >
            Search for and contact buyer profiles on the system who may be a
            match with a home you have for sale or are representing as a
            seller’s agent.
          </p>
        </Col>
      </Row>
    );
  };

  render() {
    const { FilterData, filterCount, enableInfo } = this.state;
    return (
      <div className="homeSearch">
        <Layout className="margin-body-agent">
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row style={{ backgroundColor: "#f0f2f5" }}>
              <Col
                xl={{ span: 5, offset: 0 }}
                lg={{ span: 5, offset: 0 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="side-bar"
              >
                <div>
                  <Row justify="center">
                    <p
                      className="header-down-text"
                      style={{ textAlign: "center" }}
                    >
                      Find a buyer to match your needs
                    </p>
                    <div
                      className="show-on-mobile"
                      style={{ cursor: "pointer" }}
                    >
                      <Button
                        type="link"
                        onClick={() => {
                          this.setState({
                            enableInfo: !enableInfo,
                          });
                        }}
                        icon={<InfoCircleOutlined />}
                      ></Button>
                    </div>
                  </Row>
                </div>
                {enableInfo && this.BuyerInfo()}

                <Filter
                  update={this.update}
                  oldFilterDataProps={this.props.context.state.filterSearchData}
                />
                <div
                  className="Advertisement-show"
                  style={{ marginTop: "2vw" }}
                >
                  {this.showAds()}
                </div>
              </Col>
              <Col
                xl={{ span: 18, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="margin-left-agent"
              >
                <BuyerSearch
                  FilterData={FilterData}
                  filterCount={filterCount}
                  getDivHeight={this.updateDivHeight}
                />
              </Col>
              <Col
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <div className="Advertisement-show-on-mobile-view">
                  {this.showAds()}
                </div>
              </Col>
            </Row>
          </Col>
        </Layout>
      </div>
    );
  }
}

export default withAppContext(withRouter(BuyersTab));
