import {
  Col,
  Row,
  Form,
  Input,
  // Popover,
  Modal,
  Button,
  Table,
  notification,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import Advertisement from "../../../common/Advertisement";
import { getDetails, list } from "../../../api/Apis";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import AddContact from "../../../components/VirtualOffice/contactLeads/AddContact";
import Loading from "../../../common/Loading";
import { ModalData } from "../../../StyledComponents/StyledComponents";
import NumberFormat from "react-number-format";

class BuyerPrivateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      citySearch: [],
      buyerName: "",
      buyerPhone: "",
      // totalContactList: [],
      contactList: [],
      visibleDetails: {},
      selectedContact: true,
      selectedBuyerContact: "",
      showModal: false,
      showContactModal: false,
      contactData: [],
      contactInput: "",
      filterContacts: [],
      loading: false,
      newContact: false,
      mobileNum: "",
    };
  }
  privateFormRef = React.createRef();

  componentDidMount() {
    // this.getAllContactsList();
    // This will call from admin login to Add listing alert
    if (
      window.location.pathname ===
      "/VirtualOffice/ViewAllContacts/" + this.props.match.params.id
    ) {
      this.setState({
        selectedContact: false,
      });
      // This will call from admin login to Add listing alert
      if (
        window.location.pathname ===
        "/VirtualOffice/ViewAllContacts/" + this.props.match.params.id
      ) {
        let contactObj = {};
        getDetails("virtualContactLeads", this.props.match.params.id).then(
          (res) => {
            contactObj = {
              label: res.data?.firstName,
              firstName: res.data?.firstName,
              lastName: res.data?.lastName,
              phone: res.data?.cellPhone,
              email: res.data?.emailAddress,
              value: res.data.id,
            };
            this.handleToSelectContact(contactObj);
          }
        );

        this.setState({
          selectedBuyerContact: this.props.match.params.id,
        });
      }
    } else {
      if (this.props?.buyerHomeDetails?.buyerPrivateDetails) {
        let formEditData = this.props.buyerHomeDetails.buyerPrivateDetails;
        this.privateFormRef.current.setFieldsValue({
          firstName: formEditData["firstName"],
          email: formEditData["email"]
            ? formEditData["email"]
            : formEditData["emailAddress"],
          lastName: formEditData["lastName"],
          phone: formEditData["phone"],
        });
        this.setState({
          mobileNum: formEditData["phone"],
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      window.location.pathname !==
        "/VirtualOffice/ViewAllContacts/" + this.props.match.params.id &&
      JSON.stringify(prevProps.buyerHomeDetails.buyerPrivateDetails) !==
        JSON.stringify(this.state.visibleDetails)
    ) {
      let formEditData = prevProps.buyerHomeDetails.buyerPrivateDetails;

      if (Object.keys(this.state.visibleDetails).length > 0) {
        formEditData = this.state.visibleDetails;
      }
      this.privateFormRef.current.setFieldsValue({
        firstName: formEditData["firstName"],
        email: formEditData["email"]
          ? formEditData["email"]
          : formEditData["emailAddress"],
        lastName: formEditData["lastName"],
        phone: formEditData["phone"],
      });
    }
  }

  onFinish = (e) => {
    const { buyerHomeDetails } = this.props;

    e["selectedBuyerFromContacts"] =
      this.state.selectedBuyerContact !== ""
        ? this.state.selectedBuyerContact
        : buyerHomeDetails &&
          buyerHomeDetails?.buyerPrivateDetails.selectedBuyerFromContacts;
    e["contactBuyerId"] =
      this.state.contactData.length > 0
        ? this.state.contactData[0].id
        : buyerHomeDetails?.buyerPrivateDetails?.contactBuyerId;
    e["phone"] = this.state.mobileNum;
    this.props.context.setBuyerHomeDetails(e, "BUYER_PRIVATE_DETAILS");
    this.props.handleBuyerDetails(e);
  };

  handleSingleSearch = (values) => {
    let address = values.label.split(",");
    this.setState({
      citySearch: address,
    });
  };

  handleMobileNum = (num) => {
    this.setState({
      mobileNum: num,
    });
  };

  handleToSelectContact = (contact) => {
    let visibleDetails = {};
    // const { totalContactList } = this.state;
    // let visibleDetails = totalContactList.filter(
    //   (checkContact) => checkContact.value === contact
    // );

    if (contact) {
      visibleDetails = contact;
      this.setState({
        contactData: [contact],
      });
      this.setState({
        visibleDetails,
        selectedBuyerContact:
          visibleDetails["firstName"] + " " + visibleDetails["lastName"],
        selectedContact: true,
        contactInput:
          visibleDetails["firstName"] + " " + visibleDetails["lastName"],
        mobileNum: visibleDetails["phone"],
      });
      this.privateFormRef.current.setFieldsValue({
        firstName: visibleDetails["firstName"],
        email: visibleDetails["email"],
        lastName: visibleDetails["lastName"],
        phone: visibleDetails["phone"],
      });
    }
  };

  onSubmit = () => {
    const { contactData } = this.state;
    if (contactData.length > 0) {
      this.setState({
        visibleDetails: contactData[0],
        selectedBuyerContact:
          contactData[0]["firstName"] + " " + contactData[0]["lastName"],
        selectedContact: true,
        showContactModal: false,
        mobileNum: contactData[0]["cellPhone"],
      });
      this.privateFormRef.current.setFieldsValue({
        firstName: contactData[0]["firstName"],
        email: contactData[0]["emailAddress"],
        lastName: contactData[0]["lastName"],
        phone: contactData[0]["cellPhone"],
      });
    }
  };

  handleSearch = () => {
    this.setState({
      loading: true,
    });
    const { contactInput } = this.state;
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgId = JSON.parse(localStorage.getItem("orgId"));

    let id = userDetails && userDetails?.id;
    let query = {};
    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
    } else {
      query["userId"] = id;
    }
    query["contactType"] = "Contact";
    query = {
      ...query,
      filterSearch: true,
    };

    if (contactInput !== "") {
      if (contactInput.split(" ").length === 2) {
        query = {
          ...query,
          lastNameQuery: contactInput.split(" ")[1],
          textSearch: contactInput.split(" ")[0],
        };
      } else {
        query = {
          ...query,
          textSearch: contactInput,
        };
      }
    }
    this.getResultByPage(query);
  };

  getResultByPage = (query) => {
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          if (result.data.contactList?.length > 0) {
            this.setState({
              filterContacts: result.data.contactList,
              loading: false,
              showContactModal: true,
              newContact: false,
            });
          } else {
            this.privateFormRef.current.setFieldsValue({
              firstName: "",
              email: "",
              lastName: "",
              phone: "",
            });
            this.setState({
              buyerPhone: "",
              contactData: [],
              newContact: true,
              loading: false,
              showContactModal: false,
            });
            notification["error"]({
              message:
                "Could not find matching contact. Please add a new conatct",
              placement: "topRight",
            });
          }
          // setLoading(false);
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err);
      });
  };

  render() {
    const {
      filterContacts,
      selectedContact,
      showModal,
      showContactModal,
      visibleDetails,
      loading,
      newContact,
    } = this.state;
    // const { buyerHomeDetails } = this.props;

    const webColumns = [
      {
        title: "FIRST NAME",
        dataIndex: "firstName",
        key: "firstName",
        render: (firstName) => {
          return (
            <ModalData
              style={{
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              {firstName}
            </ModalData>
          );
        },
      },

      {
        title: "LAST NAME",
        dataIndex: "lastName",
        key: "lastName",
        render: (lastName) => {
          return (
            <ModalData
              style={{
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              {lastName}
            </ModalData>
          );
        },
      },
      {
        title: "PHONE",
        dataIndex: "cellPhone",
        key: "cellPhone",
        render: (cellPhone) => {
          return (
            <ModalData
              style={{
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              {cellPhone}
            </ModalData>
          );
        },
      },
      {
        title: "DATE ADDED",
        dataIndex: "registered",
        key: "registered",
        render: (registered) => {
          return (
            <ModalData
              style={{
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              {registered}
            </ModalData>
          );
        },
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          contactData: selectedRows,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <div>
        {window.location.pathname ===
          "/VirtualOffice/ViewAllContacts/" + this.props.match.params.id && (
          <Loading enableLoading={!selectedContact} />
        )}
        <Loading enableLoading={loading} />
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="privateD-title">Private Details</div>
            <div style={{ marginBottom: 20 }}>
              <span className="privateD-des">
                Other agents can't see this info but if you want to use John Doe
                or Unknown you can
              </span>
            </div>
          </Col>

          <Col
            xl={{ span: 16, offset: 0 }}
            lg={{ span: 16, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            {selectedContact && (
              <Form
                id="form"
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                size="large"
                ref={this.privateFormRef}
              >
                <Row>
                  <Col
                    xl={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    xs={{ span: 22, offset: 1 }}
                    sm={{ span: 22, offset: 1 }}
                  >
                    {selectedContact && (
                      <>
                        <Input
                          className="input-width select_Height"
                          onChange={(e) =>
                            this.setState({
                              contactInput: e.target.value,
                              newContact: false,
                            })
                          }
                          value={this.state.contactInput}
                          disabled={
                            window.location.pathname ===
                            "/VirtualOffice/ViewAllContacts/" +
                              this.props.match.params.id
                              ? true
                              : false
                          }
                          placeholder="Search Contacts"
                        />
                        {newContact ? (
                          <Button
                            type="primary"
                            onClick={() => {
                              this.setState({
                                showModal: true,
                              });
                            }}
                            className="add-user-width"
                            icon={<PlusOutlined />}
                          >
                            Add Contact
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => this.handleSearch()}
                            className="add-user-width"
                            icon={
                              <SearchOutlined style={{ marginRight: "5px" }} />
                            }
                          >
                            Search
                          </Button>
                        )}
                      </>
                    )}
                  </Col>

                  <Col
                    xl={{ span: 11, offset: 0 }}
                    lg={{ span: 11, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                  >
                    <div className="form-titles">
                      First Name (Only you will see this){" "}
                    </div>
                    <Form.Item name="firstName">
                      <Input
                        placeholder="First Name"
                        className="form-input"
                        readOnly
                      />
                    </Form.Item>
                    <div className="form-titles" style={{ height: "45px" }}>
                      Email (Only you will see this, required for client to
                      receive email alerts)
                    </div>
                    <Form.Item name="email">
                      <Input
                        placeholder="Email"
                        className="form-input"
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xl={{ span: 11, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    md={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                  >
                    <div className="form-titles">
                      Last Name (Only you will see this)
                    </div>
                    <Form.Item name="lastName">
                      <Input
                        placeholder="Last Name"
                        className="form-input"
                        readOnly
                      />
                    </Form.Item>
                    <div className="form-titles" style={{ height: "45px" }}>
                      Buyer Phone # (Only you will see this)
                    </div>
                    <Form.Item name="phone">
                      <NumberFormat
                        className="ant-input-number"
                        placeholder="Phone"
                        format="(###) ###-####"
                        style={{
                          width: "100%",
                          padding: "4px 11px",
                          height: "50px",
                          fontSize: "14px",
                        }}
                        onChange={this.handleMobileNum}
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Advertisement
              otherAds={false}
              adType="Square"
              adWhereToShow="Buyers"
              adLimit="1"
              zipCode=""
            />
          </Col>
        </Row>
        {showModal && (
          <AddContact
            showModal={showModal}
            proConatct={""}
            loadig={false}
            onCancel={() => this.setState({ showModal: false })}
            editModal={false}
            getUserProfile={this.getAllContactsList}
            setLoading={false}
          />
        )}
        {showContactModal && (
          <Modal
            className="modal-black-close"
            visible={showContactModal}
            title="Contact Details"
            onCancel={() =>
              this.setState({
                showContactModal: false,
                selectedBuyerContact:
                  visibleDetails["firstName"] +
                  " " +
                  visibleDetails["lastName"],
              })
            }
            footer={[
              <Button
                key="back"
                onClick={() =>
                  this.setState({
                    showContactModal: false,
                  })
                }
                style={{
                  height: "35px",
                  width: "100px",
                  borderRadius: "5px",
                  marginRight: "15px",
                }}
              >
                <span style={{ fontSize: "16px" }}>Cancel</span>
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => this.onSubmit()}
                style={{ height: "35px", width: "100px", borderRadius: "5px" }}
              >
                <span style={{ fontSize: "16px" }}>Submit</span>
              </Button>,
              <Button
              type="primary"
              onClick={() => {
                this.setState({
                  showModal: true,
                  showContactModal: false,
                });
              }}
              style={{ height: "35px", width: "150px", borderRadius: "5px" }}
              icon={<PlusOutlined />}
            >
              Add Contact
            </Button>
            ]}
            width={"60%"}
          >
            <Row style={{ paddingTop: "0.73vw" }}>
              <Table
                columns={webColumns}
                dataSource={filterContacts}
                rowKey={(record) => record.id}
                rowSelection={{
                  type: "radio",
                  ...rowSelection,
                }}
                style={{ width: "100%" }}
              />

              
            </Row>
          </Modal>
        )}
      </div>
    );
  }
}
export default withAppContext(withRouter(BuyerPrivateDetails));
