import React, { Component } from "react";
import { Row, Col, Layout, Rate, notification, Button } from "antd";
import { withRouter, Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import AOS from "aos";
import "aos/dist/aos.css";
import { withAppContext } from "../../store/contextStore";
import { noAuthlist } from "../../api/Apis";
import FilterAgent from "../../components/filter/FilterAgent";
import PortfoliosList from "../../common/PortfoliosList";
import SendMessage from "../../common/SendMessage";
import Advertisement from "../../common/Advertisement";
import Loading from "../../common/Loading";
import DataNotFound from "../../common/DataNotFound";
import profilePic from "../../images/profileAvatar.png";
import Pagination from "../../components/pagination/Paginations";
import {
  BankOutlined,
  MailOutlined,
  PhoneOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import Invitation from "../../common/Invitation";

class Marketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VendorsDetails: "",
      FilterState: "",
      beforeLoginLoading: false,
      count: 1,
      beforerFilterVendors: "",
      Viewprofile: true,
      currentPage: 1,
      perpageSize: 10,
      minValue: 0,
      maxValue: 10,
      showingAdsAtSearch: false,
      enableInviteLenersPopUp: false,
    };
  }

  componentDidMount = () => {
    AOS.init({
      duration: 1500,
    });
    this.getAllUsers();
    this.setState({
      beforeLoginLoading: true,
    });
    if (
      this.props.context.state.currentPage &&
      this.props.context.state.pageSize
    ) {
      this.setState({
        currentPage: this.props.context.state.currentPage,
      });
      this.handlePagination(
        this.props.context.state.currentPage,
        this.props.context.state.pageSize
      );
    }
  };

  handleItem = (id) => {
    this.props.history.push(`/agentHomes/${id}`);
  };

  getAllUsers = async () => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let query = {};
    query = {
      orgId,
      isActive:true
    };
    try {
      const allUsers = await noAuthlist("getUsers?userProfile=V", query);

      const allFilterAgents = allUsers.data.filter(
        (o) =>
          o.subscriptionPack &&
          o.subscriptionPack &&
          o.subscriptionPack.premierStatus === "Y"
      );

      let FilterAgents = allFilterAgents.sort((a, b) =>
        parseInt(a.testimonialCount) > parseInt(b.testimonialCount) ? -1 : 1
      );

      // FilterAgents = allUsers.data;
      const VendorsDetails = [];
      if (FilterAgents) {
        for (let singleItem of FilterAgents) {
          let newImage = "";
          if (singleItem.profilePicture) {
            let newImage2 =
              `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
              singleItem.profilePicture[0];
            newImage = newImage2;
            singleItem["AgnetProfileUrl"] = newImage;
            VendorsDetails.push({ ...singleItem });
          } else {
            singleItem["AgnetProfileUrl"] = "";
            VendorsDetails.push({ ...singleItem });
          }
        }
        this.setState({
          VendorsDetails,
          beforerFilterVendors: VendorsDetails,
          beforeLoginLoading: false,
        });
        const { filtersearch } = this.props.context.state;
        if (filtersearch && filtersearch.length > 0) {
          this.handleFilterDetails(filtersearch);
        }
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
    }
  };

  handleFilterDetails = (filterData) => {
    const { beforerFilterVendors } = this.state;
    const FilterObj = {};
    for (let filterOne of filterData) {
      if (filterOne.type === "NAME" && filterOne.name) {
        FilterObj["name"] = filterOne.name;
      }
      if (filterOne.type === "ZIP" && filterOne.zipCode) {
        FilterObj["zip"] = filterOne.zipCode;
      }
      if (filterOne.type === "cities" && filterOne.multi.length > 0) {
        FilterObj["cities"] = filterOne.multi;
      }
      if (filterOne.type === "CTA" && filterOne.categories.length > 0) {
        FilterObj["categories"] = filterOne.categories;
      }
    }
    this.props.context.setAgentFilterSearch(filterData);
    const FilterState = beforerFilterVendors.filter((o) => {
      let count = 0;

      if (
        FilterObj.name &&
        o.firstName &&
        (o.firstName.toLowerCase().includes(FilterObj.name.toLowerCase()) ||
          o.lastName.toLowerCase().includes(FilterObj.name.toLowerCase()))
      ) {
        count += 1;
      }

      if (
        FilterObj.zip &&
        o.newdata &&
        o.newdata.zipCodes &&
        o.newdata.zipCodes !== undefined &&
        o.newdata.zipCodes.includes(FilterObj.zip)
      ) {
        count += 1;
      }
      // if (o && o.bussinessAdress && FilterObj.cities) {
      //   const addressList = o.bussinessAdress.split(", ");
      //   const titleAddress = FilterObj.cities.filter((title) =>
      //     addressList.includes(title)
      //   );
      //   if (titleAddress.length !== 0) {
      //     count += 1;
      //   }
      // }
      if (o.newdata && o.newdata.agentTowns && FilterObj.cities) {
        let getAgentbyZipCodes = o.newdata.agentTowns.filter((savedTown) =>
          FilterObj.cities.some(
            (searchTown) => savedTown.cityName === searchTown.cityName
          )
        );
        if (getAgentbyZipCodes.length !== 0) {
          count += 1;
        }
      }
      if (o && o.portfolio && FilterObj.categories) {
        const categoriesList = [];
        o.portfolio.map((getItem) =>
          categoriesList.push(getItem.portfolioName)
        );
        const titleAddress = FilterObj.categories.filter((title) =>
          categoriesList.includes(title)
        );
        if (titleAddress.length !== 0) {
          count += 1;
        }
      }
      if (count === Object.keys(FilterObj).length) {
        return true;
      } else {
        return false;
      }
    });
    if (FilterState) {
      const FilterAgents = FilterState.sort((a, b) =>
        parseInt(a.testimonialCount) > parseInt(b.testimonialCount) ? -1 : 1
      );
      this.setState({
        VendorsDetails: FilterAgents,
        filterFalse: true,
        beforeLoginLoading: false,
        showingAdsAtSearch: true,
      });
    }
  };

  update = (filterdata, type) => {
    this.setState({
      beforeLoginLoading: true,
    });
    setTimeout(() => {
      this.handleFilterDetails(filterdata, type);
    }, 1000);
  };

  handleItems = (userid, agent, profileUrl, authenticate, locationState) => {
    this.props.context.setOffMLSuserId(
      userid,
      agent,
      profileUrl,
      authenticate,
      locationState
    );
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  showAds = () => {
    return (
      <Advertisement
        otherAds={false}
        adType="Square"
        adWhereToShow="MarketPlace"
        adLimit="10"
        zipCode=""
      />
    );
  };
  showAdsSearch = () => {
    return (
      <Advertisement
        otherAds={false}
        adType="Square"
        adWhereToShow="Agents"
        adLimit="10"
        zipCode=""
      />
    );
  };
  vendorCard = (userInfo) => {
    return (
      <>
        <div
          data-aos-offset="100"
          data-aos-delay="200"
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-easing="ease-in"
          className="card card-agent"
          style={{
            marginLeft: "0px",
            marginRight: "0",
            marginBottom: "1vw",
            marginTop: "",
            height: "fit-content",
          }}
        >
          <Row
            style={{
              paddingTop: "1vw",
              paddingBottom: "1vw",
              backgroundColor: "white",
            }}
          >
            <Col
              xxl={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
              lg={{ span: 2, offset: 0 }}
              md={{ span: 6, offset: 1 }}
              sm={{ span: 6, offset: 1 }}
              xs={{ span: 6, offset: 1 }}
              className="img-marketplace-lender"
            >
              <Link
                to={`/marketplace/viewProfile/${userInfo.id && userInfo.id}`}
                style={{ textDecoration: "none" }}
              >
                {userInfo.AgnetProfileUrl ? (
                  <img
                    src={userInfo.AgnetProfileUrl}
                    className="img-width-agent"
                    alt="Agnet-Profile-Url"
                  />
                ) : (
                  <img
                    src={profilePic}
                    className="img-width-agent"
                    alt="Agnet-Profile-Url"
                  />
                )}
              </Link>
            </Col>
            <Col
              xxl={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              md={{ span: 16, offset: 1 }}
              sm={{ span: 16, offset: 1 }}
              xs={{ span: 16, offset: 1 }}
              className="Name-agent1"
            >
              <Col className="Name-agent">
                {userInfo.firstName &&
                  userInfo.firstName + " " + userInfo.lastName}
              </Col>
              <Col>
                <Rate disabled defaultValue={userInfo.avgRating} /> (
                {userInfo.testimonialCount})
              </Col>
              {userInfo.subscriptionPack &&
              userInfo.subscriptionPack &&
              userInfo.subscriptionPack.premierStatus === "Y" ? (
                <Col>
                  <span
                    style={{
                      fontSize: "15px",
                      backgroundColor: "#3045D2",
                      width: " 76px",
                      height: "15px",
                      color: "#FFF",
                      borderRadius: "2px",
                      padding: "5px 10px 5px",
                      textTransform: "uppercase",
                      marginBottom: "5px",
                    }}
                  >
                    Premium Partner
                  </span>

                  <div className="popover-display-view">
                    <Link
                      to={`/marketplace/viewProfile/${
                        userInfo.id && userInfo.id
                      }`}
                      style={{ textDecoration: "none" }}
                      onClick={this.handleLink}
                    >
                      <span style={{ fontSize: "15px" }}>
                        <p className="view-agent">View Profile</p>
                      </span>
                    </Link>
                  </div>
                </Col>
              ) : (
                <Col></Col>
              )}
            </Col>
            <div className="divided-line-agent" />
            <Col
              xxl={{ span: 4 }}
              xl={{ span: 5 }}
              lg={{ span: 6 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              className="Email-agent-marketplace"
            >
              {userInfo.BusinessName && (
                <Row style={{ marginBottom: "5px" }}>
                  <Col xl={2} lg={2}>
                    <BankOutlined
                      style={{ color: "#fc572b" }}
                      className="message-icon-marketplace"
                    />
                  </Col>
                  <Col xl={21} lg={21} style={{ marginLeft: "5px" }}>
                    <span
                      style={{
                        color: "#131419",
                        fontWeight: "500",
                      }}
                      className="font-size-value-agent"
                    >
                      {userInfo.BusinessName && userInfo.BusinessName}
                    </span>
                  </Col>
                </Row>
              )}

              {userInfo.newdata && userInfo.newdata.companyName && (
                <Row style={{ marginBottom: "5px" }}>
                  <Col xl={2} lg={2}>
                    <BankOutlined
                      style={{ color: "#fc572b" }}
                      className="message-icon-marketplace"
                    />
                  </Col>
                  <Col xl={21} lg={21} style={{ marginLeft: "5px" }}>
                    <span
                      style={{
                        color: "#131419",
                        fontWeight: "500",
                      }}
                      className="font-size-value-agent"
                    >
                      {userInfo.newdata && userInfo.newdata.companyName}
                    </span>
                  </Col>
                </Row>
              )}
              <Row style={{ marginBottom: "5px" }}>
                <Col xl={2}>
                  <PhoneOutlined
                    style={{ color: "#fc572b", transform: "rotate(100deg)" }}
                    className="message-icon-marketplace"
                  />
                </Col>
                <Col xl={21} style={{ marginLeft: "5px", cursor: "pointer" }}>
                  <a href={userInfo.phone && "tel:" + userInfo.phone}>
                    <span
                      className="font-size-value-agent"
                      style={{
                        color: "#131419",
                        fontWeight: "500",
                      }}
                    >
                      <NumberFormat
                        format="(###) ###-####"
                        value={userInfo.phone && userInfo.phone}
                        displayType={"text"}
                      />
                    </span>
                  </a>
                </Col>
              </Row>
            </Col>

            <div className="divided-line-agent" />
            <Col
              xxl={{ span: 7 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              className="Email-agent-marketplace"
            >
              <a
                type="link"
                target="_blank"
                href={"mailto:" + userInfo.email}
                className="btn-marketplace"
                rel="noreferrer"
              >
                <Row style={{ marginBottom: "5px" }}>
                  <Col xl={1}>
                    <MailOutlined
                      style={{ color: "#fc572b" }}
                      className="message-icon-marketplace"
                    />
                  </Col>
                  <Col xl={22} style={{ marginLeft: "5px" }}>
                    <span
                      style={{
                        color: "#131419",
                        fontWeight: "500",
                      }}
                      className="font-size-value-agent"
                    >
                      {userInfo.email && userInfo.email}
                    </span>
                  </Col>
                </Row>
              </a>
              {userInfo.Website && (
                <Col style={{ lineHeight: "1" }}>
                  <a
                    type="link"
                    target="_blank"
                    href={userInfo.Website}
                    className="btn-marketplace"
                    rel="noreferrer"
                  >
                    <Row style={{ marginBottom: "5px" }}>
                      <Col xl={1}>
                        <GlobalOutlined
                          style={{ color: "#fc572b" }}
                          className="message-icon-marketplace"
                        />
                      </Col>
                      <Col xl={22} style={{ marginLeft: "5px" }}>
                        <span
                          style={{
                            color: "#131419",
                            fontWeight: "500",
                          }}
                          className="font-size-value-agent"
                        >
                          {userInfo.Website && userInfo.Website}
                        </span>
                      </Col>
                    </Row>
                  </a>
                </Col>
              )}
            </Col>

            <Col
              xxl={{ span: 4 }}
              xl={{ span: 4 }}
              lg={{ span: 4 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              className="viewprofile-display"
            >
              <div
                style={{ marginBottom: "8px" }}
                className="viewprofile-display1"
              >
                <Link
                  to={`/marketplace/viewProfile/${userInfo.id && userInfo.id}`}
                  style={{ textDecoration: "none" }}
                  onClick={this.handleLink}
                >
                  <span style={{ fontSize: "15px" }}>
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      View Profile
                    </p>
                  </span>
                </Link>
              </div>

              <SendMessage
                className="btn-message-agent"
                msgButtonTitle="Message"
                toName={
                  userInfo.firstName &&
                  userInfo.firstName + " " + userInfo.lastName
                }
                propertyTitle={null}
                buttonAlign="agents-desktop"
                mlsId={userInfo && userInfo.mlsId && userInfo.mlsId}
                agentEmail={userInfo.email}
                AgentMessage
                isRegistred={true}
                messageType="general"
                isRegistredUser={true}
              />
            </Col>
            <Col
              xl={{ span: 2 }}
              lg={{ span: 1 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              className="popover-display-marketplace"
            >
              <SendMessage
                className="btn-message-agent"
                msgButtonTitle="Message"
                toName={
                  userInfo.firstName &&
                  userInfo.firstName + " " + userInfo.lastName
                }
                propertyTitle={null}
                buttonAlign="agents"
                mlsId={userInfo && userInfo.mlsId && userInfo.mlsId}
                agentEmail={userInfo.email}
                AgentMessage
                isRegistred={true}
                messageType="general"
                isRegistredUser={true}
              />
            </Col>
          </Row>
          {userInfo.portfolio && userInfo.portfolio.length > 0 && (
            <PortfoliosList portfolioList={userInfo.portfolio} />
          )}
        </div>
      </>
    );
  };

  handlePagination = async (page, pageSize) => {
    this.props.context.handleToSetCurrentPage(page, pageSize);
    this.setState({
      beforeLoginLoading: true,
    });
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    await this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
    setTimeout(() => {
      this.setState({
        beforeLoginLoading: false,
      });
    }, 3000);
  };
  handleCloseInvitationModal = () => {
    this.setState({
      enableInviteLenersPopUp: false,
    });
  };
  render() {
    const {
      VendorsDetails,
      beforeLoginLoading,
      showingAdsAtSearch,
      currentPage,
      perpageSize,
      enableInviteLenersPopUp,
    } = this.state;
    let checkUserLogin = JSON.parse(localStorage.getItem("userLogin"));
    let checkAgentLogin = JSON.parse(localStorage.getItem("userDetails"));

    return (
      <div className="homeSearch">
        <Loading enableLoading={beforeLoginLoading} />
        <Layout className="margin-body-agent">
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row style={{ backgroundColor: "#f0f2f5" }}>
              <Col
                xl={{ span: 5, offset: 0 }}
                lg={{ span: 5, offset: 0 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="side-bar"
              >
                <p
                  className="header-down-text show-on-desktop"
                  style={{ textAlign: "center" }}
                >
                  Search for a vendor for your home
                </p>
                <FilterAgent update={this.update} />
                <div
                  className="Advertisement-show"
                  style={{ marginTop: "2vw" }}
                >
                  {/* {this.showAds()} */}
                  {showingAdsAtSearch && this.showAdsSearch()}
                  {!showingAdsAtSearch && this.showAds()}
                </div>
              </Col>
              <Col
                xl={{ span: 18, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="margin-left-agent"
              >
                <Row>
                  <Col
                    xl={{ span: 22, offset: 0 }}
                    lg={{ span: 20, offset: 0 }}
                    md={{ span: 22, offset: 1 }}
                    sm={{ span: 12, offset: 0 }}
                    xs={{ span: 12, offset: 0 }}
                  >
                    <p className="listing-heading">
                      {VendorsDetails && VendorsDetails.length} Vendor(s) Found
                    </p>
                  </Col>
                  {checkUserLogin &&
                    checkAgentLogin &&
                    checkAgentLogin.userProfile === "AG" && (
                      <Col
                        xl={{ span: 2, offset: 0 }}
                        lg={{ span: 4, offset: 0 }}
                        md={{ span: 22, offset: 1 }}
                        sm={{ span: 12, offset: 0 }}
                        xs={{ span: 12, offset: 0 }}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            this.setState({ enableInviteLenersPopUp: true });
                          }}
                          className="invitation-col"
                        >
                          <span>Invite Vendor</span>
                        </Button>
                      </Col>
                    )}
                </Row>

                {VendorsDetails &&
                !beforeLoginLoading &&
                VendorsDetails.length > 0
                  ? VendorsDetails.slice(
                      this.state.minValue,
                      this.state.maxValue
                    ).map((userInfo) => this.vendorCard(userInfo))
                  : !beforeLoginLoading && (
                      <div style={{ padding: "4vw" }}>
                        <DataNotFound type="market" title="No vendors Found" />
                      </div>
                    )}
                {!beforeLoginLoading &&
                  VendorsDetails &&
                  VendorsDetails.length > 0 && (
                    <Col
                      className="align-right"
                      style={{
                        marginTop: "20px",
                        marginBottom: "30px",
                      }}
                    >
                      <Pagination
                        total={VendorsDetails.length}
                        handlePagination={this.handlePagination}
                        currentPage={currentPage}
                        handleLoadMoreButton={this.handleLoadMoreButton}
                        perpageSize={perpageSize}
                      />
                    </Col>
                  )}
              </Col>
              <Col
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <div className="Advertisement-show-on-mobile-view">
                  {/* {this.showAds()}. */}
                  {showingAdsAtSearch && this.showAdsSearch()}
                  {!showingAdsAtSearch && this.showAds()}
                </div>
              </Col>
            </Row>
          </Col>
        </Layout>

        {enableInviteLenersPopUp && (
          <Invitation
            enableInvitationModal={enableInviteLenersPopUp}
            handleCloseInvitationModal={this.handleCloseInvitationModal}
            invitationTitle={"vendor"}
          />
        )}
      </div>
    );
  }
}

export default withAppContext(withRouter(Marketplace));
