import React, { Component } from "react";
import "antd/dist/antd.css";

import {
  Row,
  Col,
  Popover,
  message,
  Divider,
  notification,
  Drawer,
  Skeleton,
} from "antd";
import {
  HeartOutlined,
  TwitterOutlined,
  FacebookOutlined,
  WhatsAppOutlined,
  EditTwoTone,
  EllipsisOutlined,
  ShareAltOutlined,
  CopyOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Layout from "antd/lib/layout/layout";
import NumberFormat from "react-number-format";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AOS from "aos";
import "aos/dist/aos.css";
import { withAppContext } from "../../store/contextStore";
import { add, noAuthadd } from "../../api/Apis";
import SendMessage from "../../common/SendMessage";
import bedIcon from "../../images/awesome-bed-icon.png";
import bathIcon from "../../images/awesome-bath-icon.png";
import reSize from "../../images/sq-feet-icon.png";
import privatePost from "../../images/protection.png";
import defaultImage from "../../images/defaultImage.jpg";
import SignupForm from "../../containers/RegisterForm";
import LoginForm from "../../containers/LoginForm";
import DeleteRecord from "../../common/DeleteRecord";
import Advertisement from "../../common/Advertisement";

export class HomeCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      Favorites: [],
      shareUrlTitile: "",
      shareUrl: "",
      mainPhotoUrl: "",
      enableSignupForm: false,
      showLoginSiderForm: false,
      popOverVisible: false,
      EditIcon: false,
      favClickLoad: false,
      favCardId: "",
      favList: [],
      deleteList: [],
    };
  }

  componentDidMount() {
    AOS.init({
      duration: 1500,
    });
    let agentId = JSON.parse(localStorage.getItem("userDetails"));
    let userId = this.props.match.params.id;
    if (agentId && userId === agentId.id) {
      this.setState({
        EditIcon: true,
      });
    } else {
      this.setState({
        EditIcon: false,
      });
    }
  }

  handleDeleteLoad = (resultsType, id) => {
    let newFavList = this.state.favList;
    if (id !== "") {
      newFavList = this.state.favList.filter(
        (eachFilter) => eachFilter.favId !== id
      );
    }
    console.log(newFavList, "aaa");
    this.setState({
      favClickLoad: resultsType,
      favList: newFavList,
    });
  };

  handleFavorites = async (value) => {
    let favClicksList = this.state.favList ? this.state.favList : [];
    this.setState({
      favClickLoad: true,
      favCardId: value.id,
    });

    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    // let filteQueryData = JSON.parse(localStorage.getItem("filterSearchData"));
    const favoriteHomeId = value.id;
    const data = {
      entityData: {
        userId: userDetails.id,
        orgId: orgId,
        leadId: favoriteHomeId,
        data: value,
        mls: value.mls,
        price:
          window.location.pathname === "/homes" && value.mls
            ? value.listPrice !== null
              ? value.listPrice
              : value.minPrice !== null
              ? value.minPrice
              : 0
            : value.cost !== null
            ? value.cost
            : value.minPrice !== null
            ? value.minPrice
            : 0,
        date: moment(value.listDate).format("MMM Do YYYY"),
        stateType: value && value.stateName,
        leadStatus: value.status !== undefined ? value.status : "",
      },
    };

    // console.log(value, ";;;", data);
    // return;
    // const postFavorites =
    // await
    add("userFavorites", data).then((postFavorites) => {
      if (postFavorites.success) {

        favClicksList.push({
          favId: postFavorites.data.data.id,
          listingId: favoriteHomeId,
        });
        // this.props.handleToLoadListings();
        message.success(
          <>
            <span>
              Listing added to favorites.<br></br>Click on the heart symbol in
              the top menu to see your favorite listings
            </span>
          </>
        );

        this.setState({
          favClickLoad: false,
          favList: favClicksList,
        });

        this.hidePopOver();
        this.homeAnalytics(true, favoriteHomeId);

        // let mlsType = "";
        // let filterItems =
        //   Object.keys(filteQueryData).length > 0
        //     ? filteQueryData
        //     : this.props.context.state &&
        //       this.props.context.state.filterSearchData
        //     ? this.props.context.state.filterSearchData
        //     : {
        //         filter: true,
        //         sortOrder: "-listprice",
        //       };

        // if (window.location.pathname === "/homes") {
        //   mlsType = "both";
        // } else {
        //   mlsType = "off";
        // }

        // this.props.getHomesList(filterItems, mlsType, "homeCard");
      }
    });
    // }
  };

  handleDetailsAfterDeleteFav = async (id) => {
    this.hidePopOver();
    this.homeAnalytics(false, id);
    let mlsType = "";
    const filterItems =
      this.props.context.state && this.props.context.state.filterSearchData
        ? this.props.context.state.filterSearchData
        : {
            filter: true,
            sortOrder: "-listprice",
          };
    if (window.location.pathname === "/homes") {
      mlsType = "both";
    } else {
      mlsType = "off";
    }
    if (
      window.location.pathname === `/favourties/${this.props.match.params.id}`
    ) {
      this.props.getFourites();
    } else {
      this.props.getHomesList(filterItems, mlsType, "homeCard");
    }
  };

  homeAnalytics = async (isFav, id) => {
    const favData = {
      entityData: {
        listingId: id,
        listingFavorites: isFav ? true : false,
      },
    };
    try {
      const response = await noAuthadd("homesAnalytics", favData);
      console.log(response);
    } catch (error) {
      console.log("Error :", error);
    }
  };

  openNotification = () => {
    notification.info({
      placement: "topLeft",
      message:
        "Please create your free account today to save your favorite listings and be notified of new listings",
      style: {
        fontWeight: "500",
      },
    });
    this.setState({
      enableSignupForm: true,
    });
  };

  shareThisHome = (e) => {
    const shareUrlTitile = e.title.split(",")[1];
    const shareUrl = `${process.env.REACT_APP_API_GATEWAY}/noAuth/getHomes/${e.id}/${e.stateName}?website=${window.location.hostname}`;
    this.setState({
      shareUrlTitile,
      shareUrl,
    });
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.context.handleToSetCurrentPage(
      this.props.currentPage,
      this.props.perpageSize
    );
  };

  handleEditHome = async (value) => {
    const {
      privateDetails,
      propertyDetails,
      propertyFeatures,
      propertyDetails2,
    } = value.editHomesData;
    if (privateDetails) {
      await this.props.contextProps.setHomeDetails(
        privateDetails,
        "PRIVATE_DETAILS"
      );
    }
    if (propertyDetails) {
      await this.props.contextProps.setHomeDetails(
        propertyDetails,
        "PROPERTY_DETAILS"
      );
    }
    if (propertyFeatures) {
      await this.props.contextProps.setHomeDetails(
        propertyFeatures,
        "PROPERTY_FEATURES"
      );
    }
    if (propertyDetails2) {
      await this.props.contextProps.setHomeDetails(
        propertyDetails2,
        "PROPERTY_DETAILS2"
      );
    }
  };

  cancel = (e) => {
    console.log(e);
  };

  handleToshowLoginSider = () => {
    this.setState({
      showLoginSiderForm: true,
    });
  };

  handletoShowSignupsider = () => {
    this.setState({
      showLoginSiderForm: false,
    });
  };

  handleReset = () => {
    this.setState({
      enableSignupForm: false,
      popOverVisible: false,
    });
  };

  handleVisibleChange = () => {
    this.setState({
      popOverVisible: true,
    });
  };

  hidePopOver = () => {
    this.setState({
      popOverVisible: false,
    });
  };

  handle_to_copy_property_url = () => {
    notification["success"]({
      message: "Link copied to clipboard",
    });
  };

  handleCardDetailsView = (value, content, userLogin, userInfo) => {
    let favoritesSelectedId = "";
    favoritesSelectedId =
      value.favorites && value.favorites.length > 0
        ? value.favorites[0].id
        : "";

    let stateFav =
      this.state.favList.length > 0 &&
      this.state.favList.filter((fav) => fav.listingId === value.id);

    if (favoritesSelectedId === "" && stateFav.length > 0) {
      console.log(stateFav, "0000");
      favoritesSelectedId = stateFav[0]["favId"];
    }
    console.log(this.props.showMatchesPage, "showMatchesPage");
    return (
      <>
        <div
          data-aos-offset={this.props.showMatchesPage ? "0" : "100"}
          data-aos-delay={this.props.showMatchesPage ? "0" : "200"}
          data-aos-once={this.props.showMatchesPage ? "false" : "true"}
          data-aos={this.props.showMatchesPage ? "" : "fade-up"}
          data-aos-easing={this.props.showMatchesPage ? "" : "ease-in"}
        >
          <Layout
            style={{
              backgroundColor:
                value.homesList || value.mlsList || this.props.showColor
                  ? "white"
                  : "",
            }}
          >
            <Row
              className="home-card-space-bw"
              style={{ background: "rgb(240 242 245)" }}
            >
              <Col
                xl={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                className="ant-card-hoverable"
                style={{ borderRadius: "5px" }}
              >
                <Row style={{ background: "white", borderRadius: "5px" }}>
                  <Col
                    xl={{
                      span: value.homesList || value.mlsList ? 5 : 4,
                      offset: 0,
                    }}
                    lg={{
                      span: value.homesList || value.mlsList ? 5 : 4,
                      offset: 0,
                    }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    style={{
                      backgroundColor: "#d4d8f1fa",
                      borderRadius: "5px",
                    }}
                  >
                    <Link
                      className="no-lines"
                      to={{
                        pathname: `/homeDetails/${value.stateName}/${
                          value.openHouseKey ? value.openHouseKey : value.id
                        }`,
                        state:
                          (this.props.match.path === "/homes" ||
                            this.props.match.path === "/offMLSHomes") &&
                          true,
                      }}
                      onClick={this.handleLink}
                    >
                      <img
                        className={
                          value.photos ? "img-fit-mobile" : "default-image-card"
                        }
                        src={value.photos ? value.photos : defaultImage}
                        alt="space"
                      />

                      {value.homesList && (
                        <div className="when-to-added">
                          Added {moment(value.createdAt).fromNow()}
                        </div>
                      )}
                    </Link>
                  </Col>
                  <Col
                    xl={{
                      span: value.homesList || value.mlsList ? 19 : 20,
                    }}
                    lg={{
                      span: value.homesList || value.mlsList ? 19 : 20,
                    }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ padding: "5px", paddingTop: "10px" }}
                  >
                    <Row>
                      <Col
                        xl={{
                          span: value.homesList || value.mlsList ? 24 : 24,
                        }}
                        lg={{
                          span: value.homesList || value.mlsList ? 24 : 24,
                        }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col
                            xl={{ span: 17, offset: 0 }}
                            lg={{ span: 17, offset: 0 }}
                            md={{ span: 16, offset: 1 }}
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                          >
                            <Link
                              className="no-lines"
                              to={{
                                pathname: `/homeDetails/${value.stateName}/${
                                  value.openHouseKey
                                    ? value.openHouseKey
                                    : value.id
                                }`,
                                state:
                                  (this.props.match.path === "/homes" ||
                                    this.props.match.path === "/offMLSHomes") &&
                                  true,
                              }}
                              onClick={this.handleLink}
                            >
                              <Row style={{ marginLeft: "10px" }}>
                                <span
                                  className="searchHomes-titles"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {value.title}
                                </span>
                              </Row>
                              {value &&
                              value.postType !== "" &&
                              value.postType !== null &&
                              value.postType !== undefined ? (
                                <>
                                  <Row
                                    style={{
                                      marginLeft: "10px",
                                      paddingTop: "1%",
                                      paddingBottom: "1%",
                                    }}
                                    className="hide-post-type-mobile"
                                  >
                                    <img
                                      src={privatePost}
                                      alt="postType"
                                      width="12px"
                                      height="14px"
                                      style={{ marginTop: 2 }}
                                    />
                                    <p
                                      style={{
                                        marginLeft: "8px",
                                        color: "#414555",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {value.postType}
                                    </p>
                                  </Row>
                                  {value && value.openHouseDate && (
                                    <Row
                                      className="hide-post-type-mobile"
                                      style={{ paddingBottom: "1em" }}
                                    >
                                      <p
                                        style={{
                                          marginLeft: "8px",
                                          color: "#1890ff",
                                          fontSize: "18px",
                                          marginBottom: "0px",
                                          fontWeight: "600",
                                          fontFamily: "Poppins, sans-serif",
                                        }}
                                      >
                                        Open House:
                                      </p>
                                      <p
                                        style={{
                                          marginLeft: "8px",
                                          color: "#1890ff",
                                          fontSize: "18px",
                                          marginBottom: "0px",
                                          fontFamily: "Poppins, sans-serif",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {value &&
                                          value.multiOpenHouses &&
                                          value.multiOpenHouses.length > 0 &&
                                          value.multiOpenHouses.map(
                                            (eachDate, index) => (
                                              <>
                                                {moment
                                                  .utc(eachDate.startTime)
                                                  .format("MMM Do YYYY h:mm a")}
                                                {eachDate.endTime && (
                                                  <>
                                                    {" - "}
                                                    {moment
                                                      .utc(eachDate.endTime)
                                                      .format("LT")}
                                                  </>
                                                )}
                                                {value.multiOpenHouses.length -
                                                  1 !==
                                                  index && ", "}{" "}
                                              </>
                                            )
                                          )}
                                      </p>
                                    </Row>
                                  )}
                                  {value && value.openHouseDescription && (
                                    <Row
                                      className="hide-post-type-mobile"
                                      style={{ paddingBottom: "1em" }}
                                    >
                                      <p
                                        style={{
                                          marginLeft: "8px",
                                          color: "#414555",
                                          fontSize: "16px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {value.openHouseDescription}
                                      </p>
                                    </Row>
                                  )}
                                  {value && value.status && (
                                    <Row
                                      className="hide-post-type-mobile"
                                      style={{ paddingBottom: "1em" }}
                                    >
                                      <p
                                        style={{
                                          marginLeft: "8px",
                                          color: "#99a2c3",
                                          fontSize: "16px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        Status:
                                      </p>
                                      <p
                                        style={{
                                          marginLeft: "8px",
                                          color: "#414555",
                                          fontSize: "16px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {value.mls
                                          ? value.status && value.status
                                          : value.status &&
                                            value.status.length > 0 &&
                                            value.status.includes(
                                              "comingsoon"
                                            ) &&
                                            value.status.length === 1
                                          ? "Coming Soon"
                                          : value.status.includes("active") &&
                                            value.status.length === 1
                                          ? "New Listing"
                                          : value.status.includes("pending") &&
                                            value.status.includes(
                                              "activeundercontract"
                                            ) &&
                                            value.status.length === 2
                                          ? "Under Agreement"
                                          : value.status.includes("active") &&
                                            value.status.includes(
                                              "activeundercontract"
                                            ) &&
                                            value.status.includes("comingsoon")
                                          ? "For Sale (On MLS)"
                                          : value.status.includes(
                                              "For Sale (Off MLS)"
                                            )
                                          ? "For Sale (Off MLS)"
                                          : value.status.includes(
                                              "For Sale by Owner"
                                            )
                                          ? "For Sale by Owner"
                                          : value.status.includes(
                                              "Temp off MLS"
                                            ) && "Temp off MLS"}
                                      </p>
                                    </Row>
                                  )}
                                  {value &&
                                    value.homesList &&
                                    window.location.pathname ===
                                      `/viewProfile/${this.props.match.params.id}` && (
                                      <Row style={{ paddingBottom: "1%" }}>
                                        <div className="layout-last-step">
                                          <span>
                                            <p
                                              className="mobile-view-padding-down"
                                              style={{
                                                fontSize: "14px",
                                                color: "#000",
                                                paddingLeft: "10px",
                                                marginBottom: "0",
                                              }}
                                            >
                                              {value &&
                                                value.homesList &&
                                                window.location.pathname ===
                                                  `/viewProfile/${this.props.match.params.id}` &&
                                                value.labels !== "" &&
                                                typeof value.labels ===
                                                  "string" &&
                                                value.labels}
                                              {value &&
                                                value.homesList &&
                                                window.location.pathname ===
                                                  `/viewProfile/${this.props.match.params.id}` &&
                                                value.labels &&
                                                value.labels.length > 0 &&
                                                typeof value.labels !==
                                                  "string" &&
                                                value.labels.join(", ")}
                                            </p>
                                          </span>
                                        </div>
                                      </Row>
                                    )}
                                </>
                              ) : (
                                <></>
                              )}
                              {value &&
                                value.mlsList &&
                                window.location.pathname !==
                                  `/favourties/${this.props.match.params.id}` && (
                                  <Row style={{ marginRight: "10px" }}>
                                    <span className="searchHomes-titles">
                                      &nbsp;{" "}
                                      <NumberFormat
                                        value={parseFloat(
                                          value.mls
                                            ? value.listPrice
                                            : value.cost
                                        ).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    </span>
                                  </Row>
                                )}

                              {value &&
                                value.homesList &&
                                window.location.pathname !==
                                  `/favourties/${this.props.match.params.id}` && (
                                  <>
                                    {this.props && value.cost ? (
                                      <Row style={{ marginRight: "10px" }}>
                                        <span className="searchHomes-titles">
                                          &nbsp;{" "}
                                          <NumberFormat
                                            value={parseFloat(
                                              value.cost
                                            ).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                          />
                                        </span>
                                      </Row>
                                    ) : (
                                      <Row style={{ marginRight: "10px" }}>
                                        <span className="searchHomes-titles">
                                          &nbsp;{" "}
                                          <NumberFormat
                                            value={parseFloat(
                                              value.minPrice
                                            ).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                          />{" "}
                                          -
                                          <NumberFormat
                                            value={parseFloat(
                                              value.maxPrice
                                            ).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                          />{" "}
                                        </span>
                                      </Row>
                                    )}
                                  </>
                                )}
                            </Link>
                          </Col>
                          <Col
                            xl={{ span: 7 }}
                            lg={{ span: 7 }}
                            md={{ span: 7 }}
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                          >
                            {(value.homesList || value.mlsList) &&
                            window.location.pathname !==
                              `/favourties/${this.props.match.params.id}` ? (
                              <Col
                                span={8}
                                offset={16}
                                className="hide-desktop-version-none"
                              >
                                {this.state.EditIcon && !value.mls && (
                                  <Row>
                                    <Col
                                      span={12}
                                      onClick={() => this.handleEditHome(value)}
                                    >
                                      <Link
                                        to={
                                          value.sellerHomeAdded
                                            ? {
                                                pathname: `/add/userHomeForm/${value.id}`,
                                                state: value && value,
                                              }
                                            : {
                                                pathname: `/add/sellerProfile/${value.id}`,
                                                state: value && value,
                                              }
                                        }
                                      >
                                        <EditTwoTone
                                          style={{
                                            fontSize: "20px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Link>
                                    </Col>
                                    <Col span={12}>
                                      <DeleteRecord
                                        recordId={value.id}
                                        apiPath={"homesList"}
                                        getHomesList={this.props.getHomesList}
                                        fontSizeProp={"18px"}
                                        deletionType={"DeleteOutlined"}
                                        deletionSuccessMessage={
                                          "Your record deleted successfully"
                                        }
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            ) : (
                              <>
                                <Row>
                                  <Col
                                    xs={{ span: 14 }}
                                    lg={{ span: 24 }}
                                    md={{ span: 24 }}
                                    xl={{ span: 24 }}
                                    sm={{ span: 14 }}
                                  >
                                    {!value.mlsList && (
                                      <Row style={{ marginLeft: "10px" }}>
                                        <span className="searchHomes-titles">
                                          <NumberFormat
                                            value={parseFloat(
                                              value.mls
                                                ? value.listPrice
                                                : value.cost
                                            ).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                          />{" "}
                                        </span>
                                      </Row>
                                    )}
                                  </Col>
                                  <Col
                                    xs={{ span: 10 }}
                                    lg={{ span: 24 }}
                                    md={{ span: 24 }}
                                    xl={{ span: 24 }}
                                    sm={{ span: 10 }}
                                  >
                                    <Row className="home-card-date">
                                      <div className="layout-last-step">
                                        <span className="layout-subtitles">
                                          <p
                                            className="mobile-view-padding-down"
                                            style={{
                                              fontSize: "14px",
                                              marginRight: "10px",
                                              marginBottom: "0",
                                            }}
                                          >
                                            {value.date
                                              ? value.date
                                              : moment(value.listDate).format(
                                                  "MMM Do YYYY"
                                                )}
                                          </p>
                                        </span>
                                      </div>
                                    </Row>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xl={{
                          span: value.homesList || value.mlsList ? 24 : 24,
                        }}
                        lg={{
                          span: value.homesList || value.mlsList ? 24 : 24,
                        }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col
                            xl={{ span: 17, offset: 0 }}
                            lg={{ span: 17, offset: 0 }}
                            md={{ span: 16, offset: 1 }}
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                          >
                            <Link
                              className="no-lines"
                              to={{
                                pathname: `/homeDetails/${value.stateName}/${
                                  value.openHouseKey
                                    ? value.openHouseKey
                                    : value.id
                                }`,
                                state:
                                  (this.props.match.path === "/homes" ||
                                    this.props.match.path === "/offMLSHomes") &&
                                  true,
                              }}
                              onClick={this.handleLink}
                            >
                              {!value.homesList && value.type && (
                                <Row>
                                  <div className="layout-last-step">
                                    <span>
                                      <p
                                        className="mobile-view-padding-down"
                                        style={{
                                          fontSize: "14px",
                                          color: "#000",
                                          paddingLeft: "10px",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {!value.homesList &&
                                        value.type &&
                                        value.type === "LND"
                                          ? "Land"
                                          : value.type === "MBL"
                                          ? "Mobile Homes"
                                          : value.type === "MLF"
                                          ? "Residential Multi Family Home"
                                          : value.type === "RES"
                                          ? "Residential"
                                          : value.type === "RNT"
                                          ? "Residential Rental"
                                          : value.type === "CND"
                                          ? "Condominium"
                                          : value.type === "CRE"
                                          ? "Commercial"
                                          : value.type}
                                      </p>
                                    </span>
                                  </div>
                                </Row>
                              )}
                              <Row style={{ paddingBottom: "1%" }}>
                                <div className="layout-last-step">
                                  <span>
                                    <p
                                      className="mobile-view-padding-down"
                                      style={{
                                        fontSize: "14px",
                                        color: "#000",
                                        paddingLeft: "10px",
                                        marginBottom: "0",
                                      }}
                                    >
                                      {value &&
                                        !value.homesList &&
                                        value.labels !== "" &&
                                        typeof value.labels === "string" &&
                                        value.labels}
                                      {value &&
                                        !value.homesList &&
                                        value.labels &&
                                        value.labels.length > 0 &&
                                        typeof value.labels !== "string" &&
                                        value.labels.join(", ")}
                                    </p>
                                  </span>
                                </div>
                              </Row>
                              <Row style={{ marginLeft: "10px" }}>
                                <Col span={24}>
                                  <Row>
                                    <div className="layout-last-step">
                                      <span>
                                        &nbsp;
                                        <img
                                          src={bedIcon}
                                          alt="space"
                                          width="18px"
                                          height="10px"
                                        />
                                      </span>
                                      <span className="layout-subtitles">
                                        <p className="beds-size-mobile">
                                          {value.beds === null ||
                                          value.beds === ""
                                            ? 0
                                            : value.beds}{" "}
                                          Beds
                                        </p>
                                      </span>
                                    </div>
                                    <div
                                      className="layout-last-step"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      <span>
                                        <img
                                          src={bathIcon}
                                          alt="space"
                                          width="18px"
                                          height="10px"
                                        />
                                      </span>
                                      <span className="layout-subtitles">
                                        <p
                                          style={{ fontSize: "16px" }}
                                          className="beds-size-mobile"
                                        >
                                          {value.baths === null ||
                                          value.baths === ""
                                            ? 0
                                            : value.baths}
                                          {value.halfBaths > 0 ? ".5" : ""}{" "}
                                          Baths
                                        </p>
                                      </span>
                                    </div>

                                    <div
                                      className="layout-last-step"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      <span>
                                        <img
                                          src={reSize}
                                          alt="space"
                                          width="16px"
                                          height="10px"
                                        />
                                      </span>

                                      <span className="layout-subtitles">
                                        <p
                                          style={{ fontSize: "16px" }}
                                          className="beds-size-mobile"
                                        >
                                          {value.area &&
                                          (value.area !== null ||
                                            value.area !== undefined ||
                                            value.area !== "" ||
                                            value.area <= 99999)
                                            ? `${value.area} sq.ft`
                                            : "NA"}
                                        </p>
                                      </span>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                            </Link>
                          </Col>
                          <Col
                            xl={{ span: 7 }}
                            lg={{ span: 7 }}
                            md={{ span: 7 }}
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                          >
                            <Row>
                              <Col
                                xs={{ span: 18 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                                md={{ span: 24 }}
                                sm={{ span: 18 }}
                              >
                                {(value.homesList || value.mlsList) &&
                                window.location.pathname !==
                                  `/favourties/${this.props.match.params.id}` ? (
                                  <Col
                                    xs={{ span: 18 }}
                                    md={{ span: 24 }}
                                    sm={{ span: 18 }}
                                    onClick={() => this.handleEditHome(value)}
                                    className="edit-delete-option-mobile"
                                  >
                                    {userInfo?.userId ===
                                      this.props.match.params.id && (
                                      <Row>
                                        <Col span={12}>
                                          <Link
                                            to={
                                              value.sellerHomeAdded
                                                ? {
                                                    pathname: `/add/userHomeForm/${value.id}`,
                                                    state: value && value,
                                                  }
                                                : {
                                                    pathname: `/add/sellerProfile/${value.id}`,
                                                    state: value && value,
                                                  }
                                            }
                                          >
                                            <EditTwoTone
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Link>
                                        </Col>
                                        <Col span={12}>
                                          <DeleteRecord
                                            recordId={value.id}
                                            apiPath={"homesList"}
                                            getHomesList={
                                              this.props.getHomesList
                                            }
                                            fontSizeProp={"18px"}
                                            deletionType={"DeleteOutlined"}
                                            deletionSuccessMessage={
                                              "Your record deleted successfully"
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    )}
                                  </Col>
                                ) : (
                                  <>
                                    <Row
                                      style={{
                                        paddingBottom: "1%",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <div className="layout-last-step">
                                        <span>
                                          {value &&
                                          value.homeAgentProfileUrl ? (
                                            <img
                                              src={value.homeAgentProfileUrl}
                                              alt="space"
                                              width="40px"
                                              height="40px"
                                              style={{
                                                borderRadius: "50%",
                                                marginRight: "8px",
                                              }}
                                            />
                                          ) : value.userRegisterd &&
                                            value.userRegisterd.length > 0 &&
                                            value.userRegisterd[0] ? (
                                            <img
                                              src={
                                                value.userRegisterd[0]
                                                  .profileUrl
                                              }
                                              alt="space"
                                              width="40px"
                                              height="40px"
                                              style={{
                                                borderRadius: "50%",
                                                marginRight: "8px",
                                              }}
                                            />
                                          ) : (
                                            <h5
                                              style={{
                                                height: "32px",
                                                width: "32px",
                                                borderRadius: "16px",
                                                backgroundColor: "#99A2C3",
                                                textAlign: "center",
                                                color: "#000",
                                                fontSize: "20px",
                                                marginRight: "8px",
                                              }}
                                            >
                                              {value.agent !==
                                                "undefined undefined" &&
                                                value.agent &&
                                                value.agent
                                                  .charAt(0)
                                                  .toUpperCase()}
                                            </h5>
                                          )}
                                        </span>
                                        <span className="layout-subtitles">
                                          {(value.mls && value.userid) ||
                                          !value.mls ? (
                                            <Link
                                              to={`/viewProfile/${
                                                this.props &&
                                                value &&
                                                value.userid
                                              }`}
                                              style={{
                                                textDecoration: "none",
                                              }}
                                              onClick={this.handleLink}
                                            >
                                              <p
                                                style={{
                                                  fontSize: "16px",
                                                  marginTop: "5px",
                                                  color: "#3045D2",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {value.agent !==
                                                  "undefined undefined" &&
                                                  value.agent}
                                              </p>
                                            </Link>
                                          ) : (
                                            <Link
                                              to={{
                                                pathname:
                                                  value.userRegisterd &&
                                                  value.userRegisterd.length >
                                                    0 &&
                                                  value.userRegisterd[0]
                                                    ? `/viewProfile/${value.userRegisterd[0].id}`
                                                    : `/homeDetails/${
                                                        value.stateName
                                                      }/${
                                                        value.openHouseKey
                                                          ? value.openHouseKey
                                                          : value.id
                                                      }`,
                                                state:
                                                  (this.props.match.path ===
                                                    "/homes" ||
                                                    this.props.match.path ===
                                                      "/offMLSHomes") &&
                                                  true,
                                              }}
                                              style={{
                                                textDecoration: "none",
                                              }}
                                              onClick={this.handleLink}
                                            >
                                              <p
                                                style={{
                                                  fontSize: "16px",
                                                  marginTop: "8px",
                                                  color: "#3045D2",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {value.userRegisterd &&
                                                value.userRegisterd.length >
                                                  0 &&
                                                value.userRegisterd[0]
                                                  ? value.userRegisterd[0]
                                                      .firstName +
                                                    "" +
                                                    value.userRegisterd[0]
                                                      .lastName
                                                  : value.agent !==
                                                      "undefined undefined" &&
                                                    value.agent}
                                              </p>
                                            </Link>
                                          )}
                                        </span>
                                      </div>
                                    </Row>
                                  </>
                                )}
                                <Row style={{ paddingBottom: "1%" }}>
                                  <div className="layout-last-step">
                                    <span>
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          color: "#000",
                                          paddingLeft: "10px",
                                        }}
                                      ></p>
                                    </span>
                                  </div>
                                </Row>
                              </Col>
                              <Col
                                xs={{ span: 4, offset: 2 }}
                                lg={{ span: 24, offset: 0 }}
                                xl={{ span: 24, offset: 0 }}
                                md={{ span: 24, offset: 0 }}
                                sm={{ span: 4, offset: 2 }}
                                style={{ padding: "10px" }}
                              >
                                <Row
                                  style={{
                                    marginTop: "5px",
                                    float:
                                      value.homesList || value.mlsList
                                        ? ""
                                        : "",
                                  }}
                                >
                                  <Popover
                                    placement="bottomRight"
                                    title={null}
                                    content={content}
                                    trigger="click"
                                    className={
                                      value.homesList || value.mlsList
                                        ? "show-on-profile-details-mobile"
                                        : "showing-sharing-like"
                                    }
                                    visible={this.state.popOverVisible}
                                    onVisibleChange={this.handleVisibleChange}
                                  >
                                    <EllipsisOutlined
                                      style={{ fontSize: "30px" }}
                                    />
                                  </Popover>
                                  <Col
                                    span={24}
                                    className={
                                      value.homesList || value.mlsList
                                        ? "show-on-profile-details"
                                        : "hide-sharing-like"
                                    }
                                  >
                                    <Row>
                                      <div
                                        className={
                                          value.homesList
                                            ? "side-favorites-homeList"
                                            : "side-favorites"
                                        }
                                      >
                                        <div
                                          className="column1 left center-item"
                                          onClick={() =>
                                            this.shareThisHome(value)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <Popover
                                            trigger="click"
                                            content={content}
                                            title="Share this Home"
                                            onClick={this.hidePopOver}
                                          >
                                            <div
                                              style={{
                                                marginLeft: "2px",
                                                marginTop: "4px",
                                              }}
                                            >
                                              <ShareAltOutlined className="share" />
                                            </div>
                                          </Popover>
                                        </div>
                                        <div>
                                          <Divider
                                            type="vertical"
                                            className="divider-favorite"
                                          />
                                        </div>
                                        <div className="column1 middle center-item">
                                          <div
                                            style={{
                                              marginLeft: "2px",
                                              marginTop: "4px",
                                            }}
                                          >
                                            {window.location.pathname !==
                                              `/favourties/${this.props.match.params.id}` &&
                                            userLogin &&
                                            ((value.favorites &&
                                              value.favorites.length > 0 &&
                                              value.id ===
                                                value.favorites[0].leadId) ||
                                              this.state.favList.filter(
                                                (o) => o.listingId === value.id
                                              ).length > 0) ? (
                                              <>
                                                <DeleteRecord
                                                  recordId={favoritesSelectedId}
                                                  apiPath={"userFavorites"}
                                                  getHomesList={
                                                    this
                                                      .handleDetailsAfterDeleteFav
                                                  }
                                                  deletionType={"HeartFilled"}
                                                  deletionSuccessMessage={
                                                    "Removed from favorites list"
                                                  }
                                                  deleteLoadingMethod={
                                                    this.handleDeleteLoad
                                                  }
                                                  recordData={value}
                                                />
                                              </>
                                            ) : (
                                              window.location.pathname !==
                                                `/favourties/${this.props.match.params.id}` && (
                                                <>
                                                  {value.id ===
                                                    this.state.favCardId &&
                                                  (this.state.favClickLoad ||
                                                    this.props.dataLoading) ? (
                                                    <LoadingOutlined />
                                                  ) : (
                                                    <HeartOutlined
                                                      className="homeSearch heart"
                                                      onClick={
                                                        userLogin
                                                          ? () =>
                                                              this.handleFavorites(
                                                                value
                                                              )
                                                          : () =>
                                                              this.openNotification()
                                                      }
                                                    />
                                                  )}
                                                </>
                                              )
                                            )}

                                            {window.location.pathname ===
                                              `/favourties/${this.props.match.params.id}` &&
                                              value &&
                                              value.favorited && (
                                                <DeleteRecord
                                                  recordId={
                                                    this.props &&
                                                    value &&
                                                    value.favouriteId &&
                                                    value.favouriteId
                                                  }
                                                  apiPath={"userFavorites"}
                                                  getHomesList={
                                                    this
                                                      .handleDetailsAfterDeleteFav
                                                  }
                                                  deletionType={"HeartFilled"}
                                                  deletionSuccessMessage={
                                                    "Removed from favorites list"
                                                  }
                                                  deleteLoadingMethod={
                                                    this.handleDeleteLoad
                                                  }
                                                  recordData={value}
                                                />
                                              )}
                                          </div>
                                        </div>

                                        {(value.homesList || value.mlsList) &&
                                        window.location.pathname !==
                                          `/favourties/${this.props.match.params.id}` ? (
                                          ""
                                        ) : (
                                          <>
                                            <div>
                                              <Divider
                                                type="vertical"
                                                className="divider-favorite"
                                              />
                                            </div>
                                            <div
                                              style={{
                                                margin: "5px 10px 0px 2px",
                                                width: "auto",
                                              }}
                                              onClick={this.hidePopOver}
                                            >
                                              <SendMessage
                                                msgButtonTitle="Message"
                                                toName={
                                                  value.agent !==
                                                    "undefined undefined" &&
                                                  value.agent !== ""
                                                    ? value.agent
                                                    : ""
                                                }
                                                propertyId={value && value.id}
                                                propertyTitle={
                                                  value.title !== ""
                                                    ? value.title
                                                    : "enter your message"
                                                }
                                                propertyImages={value.photos}
                                                buttonAlign="listing"
                                                mlsId={
                                                  value && value.mlsId
                                                    ? value.mlsId
                                                    : value.id
                                                }
                                                agentEmail={
                                                  value && value.agentEmail
                                                }
                                                messageType="potential"
                                                isRegistredUser={
                                                  this.props &&
                                                  value &&
                                                  value.mls
                                                    ? false
                                                    : true
                                                }
                                                stateType={
                                                  value && value.mls === false
                                                    ? "offMls"
                                                    : this.props &&
                                                      value &&
                                                      value.stateName
                                                }
                                                preferedAgent={
                                                  userInfo.preferedAgent &&
                                                  userInfo.preferedAgent
                                                }
                                                prefereAgentName={
                                                  userInfo.prefereAgentName &&
                                                  userInfo.prefereAgentName
                                                }
                                                buyerLoginSendMessage={
                                                  userInfo &&
                                                  (userInfo.userProfile ===
                                                    "B" ||
                                                    userInfo.userProfile ===
                                                      "S")
                                                    ? true
                                                    : false
                                                }
                                                homeCardMsg={true}
                                                prefereAgentDetails={userInfo}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Layout>
        </div>
      </>
    );
  };

  render() {
    const { showLoginSiderForm } = this.state;
    let userLogin = localStorage.getItem("userLogin");
    let userInfo = {};
    const loginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserDetails && loginUserDetails.id) {
      const loginUserId = loginUserDetails.id;
      const loginUserName =
        loginUserDetails.firstName + " " + loginUserDetails.lastName;
      userInfo = {
        userId: loginUserId,
        userName: loginUserName,
        userProfile: loginUserDetails.userProfile,
        preferedAgent:
          loginUserDetails.agentInfo && loginUserDetails.agentInfo.agentEmail,
        prefereAgentName:
          loginUserDetails.agentInfo && loginUserDetails.agentInfo.agentName,
        preferedId:
          "agentInfo" in loginUserDetails === true &&
          "assignAgentId" in loginUserDetails.agentInfo === true
            ? loginUserDetails.agentInfo?.assignAgentId
            : "",
      };
    }

    const content = (
      <div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            onClick={() => {
              window.FB.ui(
                {
                  display: "popup",
                  method: "share",
                  href: this.state.shareUrl,
                },
                function (response) {
                  console.log(response, "response");
                }
              );
            }}
            href={() => false}
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <FacebookOutlined style={{ fontSize: 18 }} />
            &nbsp;&nbsp; facebook
          </a>
        </div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://api.whatsapp.com/send?text=${this.state.shareUrl}`}
            style={{ textDecoration: "none" }}
          >
            <WhatsAppOutlined style={{ fontSize: 18 }} />
            &nbsp;&nbsp; Whatsapp
          </a>
        </div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://twitter.com/intent/tweet?text=${this.state.shareUrl}`}
            style={{ textDecoration: "none" }}
          >
            <TwitterOutlined style={{ fontSize: 18 }} /> &nbsp;&nbsp; Twitter
          </a>
        </div>
        <div className="share-to-social-media">
          <CopyToClipboard
            text={this.state.shareUrl}
            onCopy={this.handle_to_copy_property_url}
          >
            <span style={{ color: "#1890ff" }}>
              <CopyOutlined />
              &nbsp;&nbsp;&nbsp;Copy URL
            </span>
          </CopyToClipboard>
        </div>
      </div>
    );

    const {
      allHomesList,
      minValue,
      maxValue,
      dataLoading,
      cities,
      otherAds,
      adType,
      adWhereToShow,
      adLimit,
      stateName,
      zipCode,
      postalCode,
    } = this.props;

    return (
      <>
        {allHomesList.length > 0 &&
          allHomesList.slice(minValue, maxValue).map((value, index) => (
            <Skeleton title={false} loading={dataLoading} active={dataLoading}>
              {index % 4 === 0 && index > 1 ? (
                <>
                  <Advertisement
                    otherAds={otherAds}
                    adType={adType}
                    adWhereToShow={adWhereToShow}
                    adLimit={adLimit}
                    zipCode={zipCode}
                    showMatchesPage={this.props.showMatchesPage}
                    cityName={cities && cities.citiesList}
                    postalCode={postalCode}
                    stateName={stateName}
                  />
                  {this.handleCardDetailsView(
                    value,
                    content,
                    userLogin,
                    userInfo
                  )}
                </>
              ) : (
                this.handleCardDetailsView(value, content, userLogin, userInfo)
              )}
            </Skeleton>
          ))}

        {this.state.enableSignupForm && (
          <Drawer
            className="homecard-drawer"
            title=""
            placement="right"
            width={window.screen.width >= "768" ? "40%" : "100%"}
            mask={true}
            closable={true}
            visible={this.state.enableSignupForm}
            onClose={() => this.handleReset()}
            headerStyle={{ textAlign: "center" }}
            bodyStyle={{
              paddingBottom: 80,
              backgroundColor: "#eff2f5",
              padding: "0px",
            }}
          >
            <Row>
              <Col span={24}>
                {showLoginSiderForm ? (
                  <LoginForm
                    showAdd={false}
                    modalTrue
                    goTopreviousPage={true}
                    handleToshowLoginSider={this.handletoShowSignupsider}
                    showLoginSiderForm={showLoginSiderForm}
                    questionPage
                  />
                ) : (
                  <SignupForm
                    questionPage
                    showAdd={false}
                    modalTrue
                    goTopreviousPage={true}
                    handleToshowLoginSider={this.handleToshowLoginSider}
                    showLoginSiderForm={showLoginSiderForm}
                  />
                )}
              </Col>
            </Row>
          </Drawer>
        )}
      </>
    );
  }
}

export default withAppContext(withRouter(HomeCard));
