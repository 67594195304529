import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Modal,
  Input,
  Form,
  Upload,
  Dropdown,
  message,
  Spin,
} from "antd";
import { Link, withRouter } from "react-router-dom";
import {
  FacebookFilled,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
  EditTwoTone,
  EditOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { withAppContext } from "../../store/contextStore";
import { update } from "../../api/Apis";
import { Storage } from "aws-amplify";
import { ModalTitle } from "../../StyledComponents/StyledComponents.js";
import { Panel as ColorPickerPanel } from "rc-color-picker";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export class OpenFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFooterModal: false,
      buttonColor: "",
      buttonbgColor: "",
      footerImage: "",
      tabColor: "",
      iconColor: "",
      backgroundColor: "",
      showEditModalText: "",
      loading: false,
      richtTextBiofield: "",
      editImagesLoading: false,
      lastFootertextColor: "",
    };
  }

  componentDidMount() {}

  handleclick = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  goTo = () => {
    window.location.replace(`/register`);
  };

  handleUploadPic = async (info) => {
    this.setState({
      editImagesLoading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                console.log(result.key, "result.key");
                this.setState({
                  footerImage: result.key,
                  editImagesLoading: false,
                });
              });
            } else {
              this.setState({
                editImagesLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  onFinish = (values, editType) => {
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserProfile && loginUserProfile.orgId) {
      let websiteId = loginUserProfile && loginUserProfile?.orgId;
      const {
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        WhyJoinUsPage,
        contactUsPage,
      } = this.props.context.state.websiteThemes;
      const {
        buttonColor,
        buttonbgColor,
        tabColor,
        iconColor,
        footerImage,
        backgroundColor,
        lastFootertextColor,
      } = this.state;
      let data = {};

      this.setState({
        loading: true,
      });

      if (editType === "footerCard") {
        let buttonData = {
          buttonText: values["buttonText"],
          buttonColor: buttonColor
            ? buttonColor
            : footer["imageCard"]["onImageButton"]["buttonColor"],
          buttonBackground: buttonbgColor
            ? buttonbgColor
            : footer["imageCard"]["onImageButton"]["buttonBackground"],
        };

        footer["imageCard"]["text"] = values["onImageTitle"];
        footer["imageCard"]["footerBackgroundImage"] =
          footerImage || footer?.imageCard?.footerBackgroundImage;
        footer["imageCard"]["onImageButton"] = buttonData;
        data = {
          entityData: {
            landingScreen,
            Header,
            footer,
            aboutUsPage,
            contactUsPage,
            WhyJoinUsPage,
          },
        };
      } else if (editType === "footerTabs") {
        let tabsData = {
          logo: footerImage ? footerImage : footer["tabs"]["logo"],
          iconColor: iconColor ? iconColor : footer["tabs"]["iconColor"],
          tabColor: tabColor ? tabColor : footer["tabs"]["iconColor"],
          backgroundColor: backgroundColor
            ? backgroundColor
            : footer["tabs"]["backgroundColor"],
          fbUrl: values["fbUrl"]
            ? values["fbUrl"].includes("https://")
              ? values["fbUrl"]
              : "https://" + values["fbUrl"]
            : "",
          instaUrl: values["instaUrl"]
            ? values["instaUrl"].includes("https://")
              ? values["instaUrl"]
              : "https://" + values["instaUrl"]
            : "",
          twitterUrl: values["twitterUrl"]
            ? values["twitterUrl"].includes("https://")
              ? values["twitterUrl"]
              : "https://" + values["twitterUrl"]
            : "",
          youtubeUrl: values["youtubeUrl"]
            ? values["youtubeUrl"].includes("https://")
              ? values["youtubeUrl"]
              : "https://" + values["youtubeUrl"]
            : "",
          linkUrl: values["linkUrl"]
            ? values["linkUrl"].includes("https://")
              ? values["linkUrl"]
              : "https://" + values["linkUrl"]
            : "",
        };
        footer["tabs"] = tabsData;

        data = {
          entityData: {
            landingScreen,
            Header,
            footer,
            aboutUsPage,
            contactUsPage,
            WhyJoinUsPage,
          },
        };
      } else if (editType === "footerCopyRight") {
        let copyRightData = {
          logo: footerImage ? footerImage : footer["copyRight"]["logo"],
          description: values["description"]
            ? values["description"]
            : footer["copyRight"]["description"],
          text: values["text"] ? values["text"] : footer["copyRight"]["text"],
          backgroundColor: buttonbgColor
            ? buttonbgColor
            : footer["copyRight"]["backgroundColor"],
          lastFootertextColor: lastFootertextColor
            ? lastFootertextColor
            : footer["copyRight"]["lastFootertextColor"],
        };
        footer["copyRight"] = copyRightData;
        data = {
          entityData: {
            landingScreen,
            Header,
            footer,
            aboutUsPage,
            contactUsPage,
            WhyJoinUsPage,
          },
        };
      }
      data["entityData"]["isNew"] = false;

      let userData = {
        entityData: {
          isNew: false,
        },
      };

      update("saveWebsitesData", websiteId, data)
        .then((result) => {
          if (result.success) {
            message.success("Updated successfully!");
            update("users", loginUserProfile?.id, userData);
            this.setState({
              showFooterModal: false,
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            showFooterModal: false,
            loading: false,
          });
          console.log(error);
        });
    }
  };

  footerCard = () => {
    const { buttonColor, buttonbgColor, editImagesLoading, footerImage } =
      this.state;
    const { footer } = this.props.context.state.websiteThemes;
    const ButtonColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={buttonColor}
          onChange={(e) =>
            this.setState({
              buttonColor: e.color,
            })
          }
        />
      </div>
    );
    const bgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={buttonbgColor}
          onChange={(e) =>
            this.setState({
              buttonbgColor: e.color,
            })
          }
        />
      </div>
    );
    return (
      <div>
        {footer && (
          <Row style={{ padding: "10px" }}>
            <Col span={24}>
              <Form id="form1" onFinish={(e) => this.onFinish(e, "footerCard")}>
                <ModalTitle>Background image</ModalTitle>

                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                  }}
                  showUploadList={false}
                  customRequest={dummyRequest}
                  onChange={(e) => this.handleUploadPic(e)}
                >
                  <Spin spinning={editImagesLoading}>
                    {footerImage ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${footerImage}`}
                        // src={editCard.cardImage}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : footer?.imageCard?.footerBackgroundImage ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${footer.imageCard.footerBackgroundImage}`}
                        // src={editCard.cardImage}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : (
                      <CameraOutlined className="camera-icon" />
                    )}
                  </Spin>
                </Upload>

                <ModalTitle>On Image Text</ModalTitle>
                <Form.Item
                  name="onImageTitle"
                  rules={[
                    {
                      required: true,
                      message: "Please input title",
                    },
                  ]}
                  initialValue={footer?.imageCard.text}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>

                <Row style={{ marginBottom: "1vw" }}>
                  <Col span={7}>
                    <ModalTitle>Button Text</ModalTitle>
                    <Form.Item
                      name="buttonText"
                      rules={[
                        {
                          required: true,
                          message: "Please input button text!",
                        },
                      ]}
                      initialValue={footer?.imageCard?.onImageButton.buttonText}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Button Color</ModalTitle>

                    <Input
                      value={
                        buttonColor
                          ? buttonColor
                          : footer?.imageCard?.onImageButton.buttonColor
                      }
                      onChange={(e) =>
                        this.setState({
                          buttonColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={ButtonColor}>
                          <Button
                            style={{
                              background:
                                buttonColor ||
                                footer?.imageCard?.onImageButton.buttonColor,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Button Background Color</ModalTitle>
                    <Input
                      value={
                        buttonbgColor
                          ? buttonbgColor
                          : footer?.imageCard?.onImageButton?.buttonBackground
                      }
                      onChange={(e) =>
                        this.setState({
                          buttonbgColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={bgColor}>
                          <Button
                            style={{
                              background:
                                buttonbgColor ||
                                footer?.imageCard?.onImageButton
                                  ?.buttonBackground,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  footerTabs = () => {
    const { tabColor, iconColor, backgroundColor, footerImage } = this.state;
    const { footer } = this.props.context.state.websiteThemes;
    const ButtonColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={tabColor}
          onChange={(e) =>
            this.setState({
              tabColor: e.color,
            })
          }
        />
      </div>
    );
    const IconColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={iconColor}
          onChange={(e) =>
            this.setState({
              iconColor: e.color,
            })
          }
        />
      </div>
    );
    const bgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={backgroundColor}
          onChange={(e) =>
            this.setState({
              backgroundColor: e.color,
            })
          }
        />
      </div>
    );
    return (
      <div>
        {footer && (
          <Row style={{ padding: "10px" }}>
            <Col span={24}>
              <Form id="form1" onFinish={(e) => this.onFinish(e, "footerTabs")}>
                <ModalTitle>Footer Logo</ModalTitle>
                <Form.Item
                  name="footerImage"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Card Logo!",
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    style={{
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    showUploadList={false}
                    customRequest={dummyRequest}
                    onChange={(e) => this.handleUploadPic(e)}
                  >
                    {footerImage ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${footerImage}`}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : footer.tabs.logo ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${footer.tabs.logo}`}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : (
                      <CameraOutlined className="camera-icon" />
                    )}
                  </Upload>
                </Form.Item>

                <ModalTitle>Background Color</ModalTitle>
                <Input
                  value={
                    backgroundColor
                      ? backgroundColor
                      : footer.tabs.backgroundColor
                  }
                  onChange={(e) =>
                    this.setState({
                      backgroundColor: e.target.value,
                    })
                  }
                  style={{ marginBottom: "1vw" }}
                  suffix={
                    <Dropdown trigger={["click"]} overlay={bgColor}>
                      <Button
                        style={{
                          background:
                            backgroundColor || footer.tabs.backgroundColor,
                        }}
                      >
                        {" "}
                      </Button>
                    </Dropdown>
                  }
                />
                <Row style={{ marginBottom: "1vw" }}>
                  <Col span={11}>
                    <ModalTitle>Tab Color</ModalTitle>

                    <Input
                      value={tabColor ? tabColor : footer?.tabs.tabColor}
                      onChange={(e) =>
                        this.setState({
                          tabColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={ButtonColor}>
                          <Button
                            style={{
                              background: tabColor || footer?.tabs.tabColor,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>

                  <Col span={11} offset={1}>
                    <ModalTitle>Icon Color</ModalTitle>
                    <Input
                      value={iconColor ? iconColor : footer?.tabs?.iconColor}
                      onChange={(e) =>
                        this.setState({
                          iconColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={IconColor}>
                          <Button
                            style={{
                              background: iconColor || footer?.tabs?.iconColor,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>
                </Row>

                <ModalTitle>Facebook Url</ModalTitle>
                <Form.Item
                  name="fbUrl"
                  rules={[
                    {
                      required: false,
                      message: "Please input fbUrl",
                    },
                  ]}
                  initialValue={footer?.tabs?.fbUrl}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>
                <ModalTitle>Instagram Url</ModalTitle>
                <Form.Item
                  name="instaUrl"
                  rules={[
                    {
                      required: false,
                      message: "Please input instaUrl",
                    },
                  ]}
                  initialValue={footer?.tabs?.instaUrl}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>

                <ModalTitle>Twitter Url</ModalTitle>
                <Form.Item
                  name="twitterUrl"
                  rules={[
                    {
                      required: false,
                      message: "Please input twitterUrl",
                    },
                  ]}
                  initialValue={footer?.tabs?.twitterUrl}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>
                <ModalTitle>Youtube Url</ModalTitle>
                <Form.Item
                  name="youtubeUrl"
                  rules={[
                    {
                      required: false,
                      message: "Please input youtubeUrl",
                    },
                  ]}
                  initialValue={footer?.tabs?.youtubeUrl}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>

                <ModalTitle>Linkedin Url</ModalTitle>
                <Form.Item
                  name="linkUrl"
                  rules={[
                    {
                      required: false,
                      message: "Please input linkUrl",
                    },
                  ]}
                  initialValue={footer?.tabs?.linkUrl}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  footerCopyRight = () => {
    const { buttonbgColor, footerImage, lastFootertextColor } = this.state;
    const { footer } = this.props.context.state.websiteThemes;
    const bgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={buttonbgColor}
          onChange={(e) =>
            this.setState({
              buttonbgColor: e.color,
            })
          }
        />
      </div>
    );
    const textColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={lastFootertextColor}
          onChange={(e) =>
            this.setState({
              lastFootertextColor: e.color,
            })
          }
        />
      </div>
    );
    return (
      <div>
        {footer && (
          <Row style={{ padding: "10px" }}>
            <Col span={24}>
              <Form
                id="form1"
                onFinish={(e) => this.onFinish(e, "footerCopyRight")}
              >
                <ModalTitle>Copy Right</ModalTitle>
                <Form.Item
                  name="text"
                  rules={[
                    {
                      required: true,
                      message: "Please input title",
                    },
                  ]}
                  initialValue={footer?.copyRight.text}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>

                <ModalTitle>Copy Right Logo</ModalTitle>
                <Form.Item
                  name="copyRightLogo"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Logo!",
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    style={{
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    showUploadList={false}
                    customRequest={dummyRequest}
                    onChange={(e) => this.handleUploadPic(e)}
                  >
                    {footerImage ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${footerImage}`}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : footer.copyRight.logo ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${footer.copyRight.logo}`}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : (
                      <CameraOutlined className="camera-icon" />
                    )}
                  </Upload>
                </Form.Item>

                <ModalTitle>Descripition</ModalTitle>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input description",
                    },
                  ]}
                  initialValue={footer?.copyRight.description}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>

                <ModalTitle>Footer Background Color</ModalTitle>
                <Input
                  value={
                    buttonbgColor
                      ? buttonbgColor
                      : footer?.copyRight.backgroundColor
                  }
                  onChange={(e) =>
                    this.setState({
                      buttonbgColor: e.target.value,
                    })
                  }
                  suffix={
                    <Dropdown trigger={["click"]} overlay={bgColor}>
                      <Button
                        style={{
                          background:
                            buttonbgColor || footer?.copyRight.backgroundColor,
                        }}
                      >
                        {" "}
                      </Button>
                    </Dropdown>
                  }
                />
                <ModalTitle>Footer Text Color</ModalTitle>
                <Input
                  value={
                    lastFootertextColor
                      ? lastFootertextColor
                      : footer?.copyRight.lastFootertextColor
                  }
                  onChange={(e) =>
                    this.setState({
                      lastFootertextColor: e.target.value,
                    })
                  }
                  suffix={
                    <Dropdown trigger={["click"]} overlay={textColor}>
                      <Button
                        style={{
                          background:
                            lastFootertextColor ||
                            footer?.copyRight.lastFootertextColor,
                        }}
                      >
                        {" "}
                      </Button>
                    </Dropdown>
                  }
                />
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  render() {
    const { footer } = this.props.context.state.websiteThemes;

    const dates = JSON.parse(
      localStorage.getItem("rets_data_last_updated_date")
    );
    let moments = dates && dates.length > 0 && dates.map((d) => moment(d)),
      maxDate = moment.max(moments);
    const { showFooterModal, showEditModalText, loading } = this.state;
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));

    return (
      <div className="footer" style={{ width: "100%" }}>
        {footer && (
          <Layout>
            <Row
              style={{
                justifyContent: "end",
                fontSize: "24px",
                background: "white",
              }}
            >
              {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                <EditTwoTone
                  style={{
                    float: "right",
                    fontSize: "24px",
                    marginRight: "16px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      showFooterModal: true,
                      showEditModalText: "footerCard",
                      footerImage: "",
                    })
                  }
                />
              )}
            </Row>
            <Row>
              <Row
                className="footer-background"
                style={{
                  backgroundImage: `url(https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${footer.imageCard.footerBackgroundImage})`,
                  width: "100%",
                }}
              >
                <p className="footer-text">{footer.imageCard.text}</p>
                <Link to="/register">
                  <Button
                    className="footer-button"
                    size="large"
                    style={{
                      color: footer.imageCard.onImageButton.buttonColor,
                      background:
                        footer.imageCard.onImageButton.buttonBackground,
                      border: "none",
                    }}
                  >
                    <span className="footer-buttonText">
                      {footer.imageCard.onImageButton.buttonText}
                    </span>
                  </Button>
                </Link>
              </Row>
            </Row>
            <Row
              style={{
                justifyContent: "end",
                fontSize: "24px",
                backgroundColor: footer?.tabs.backgroundColor,
              }}
            >
              {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                <EditOutlined
                  style={{
                    fontSize: "24px",
                    marginRight: "16px",
                    marginTop: "10px",
                    cursor: "pointer",
                    color: `${footer.tabs.tabColor}`,
                  }}
                  onClick={() =>
                    this.setState({
                      showFooterModal: true,
                      showEditModalText: "footerTabs",
                      footerImage: "",
                    })
                  }
                />
              )}
            </Row>
            <div style={{ backgroundColor: footer?.tabs.backgroundColor }}>
              <Row
                justify="center"
                style={{
                  margin: "0",
                  padding: "1vw",
                }}
              >
                <img
                  src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${footer.tabs.logo}`}
                  alt="space"
                  className="footer-logo"
                />
              </Row>
              <Row justify="space-between" className="footer-about">
                <Col className="margin-Top">
                  <Link
                    to="/about"
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={this.handleclick}
                  >
                    <p style={{ color: footer.tabs.tabColor }}>About Us</p>
                  </Link>
                </Col>
                <Col className="margin-Top">
                  <Link
                    to="/whyJoinUs"
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={this.handleclick}
                  >
                    <p style={{ color: footer.tabs.tabColor }}>Why Join Us</p>
                  </Link>
                </Col>
                <Col className="margin-Top">
                  <Link
                    to="/contactUs"
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={this.handleclick}
                  >
                    <p style={{ color: footer.tabs.tabColor }}>Contact Us</p>
                  </Link>
                </Col>
                <Col className="margin-Top">
                  <Link
                    to="/buyers"
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={this.handleclick}
                  >
                    <p style={{ color: footer.tabs.tabColor }}>Buy</p>
                  </Link>
                </Col>
                <Col className="margin-Top">
                  <Link onClick={this.handleclick}>
                    <p style={{ color: footer.tabs.tabColor }}>Sell</p>
                  </Link>
                </Col>
                <Col className="margin-Top">
                  <Link
                    to="/agent"
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={this.handleclick}
                  >
                    <p style={{ color: footer.tabs.tabColor }}>Agents</p>
                  </Link>
                </Col>
                <Col className="margin-Top">
                  <Link
                    to="/marketplace"
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={this.handleclick}
                  >
                    <p style={{ color: footer.tabs.tabColor }}>Marketplace</p>
                  </Link>
                </Col>
              </Row>
              <Row justify="space-between" className="icons-padding">
                {footer?.tabs?.fbUrl && (
                  <Col className="margin-Top">
                    <a
                      target="_blank"
                      href={footer["tabs"]["fbUrl"]}
                      // href={`https://www.facebook.com/Homes-Listed-By-101359968421070`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <FacebookFilled
                        style={{ color: footer.tabs.iconColor }}
                        className="icon-size-color"
                      />
                    </a>
                  </Col>
                )}
                {footer?.tabs?.instaUrl && (
                  <Col className="margin-Top">
                    <a
                      target="_blank"
                      href={footer["tabs"]["instaUrl"]}
                      // href={`https://www.instagram.com/homeslistedby`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <InstagramOutlined
                        style={{ color: footer.tabs.iconColor }}
                        className="icon-size-color"
                      />
                    </a>
                  </Col>
                )}

                {footer?.tabs?.twitterUrl && (
                  <Col className="margin-Top">
                    <a
                      target="_blank"
                      href={footer["tabs"]["twitterUrl"]}
                      // href={`https://twitter.com/homeslistedby`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <TwitterOutlined
                        style={{ color: footer.tabs.iconColor }}
                        className="icon-size-color"
                      />
                    </a>
                  </Col>
                )}
                {footer?.tabs?.youtubeUrl && (
                  <Col className="margin-Top">
                    <a
                      target="_blank"
                      href={footer["tabs"]["youtubeUrl"]}
                      // href={`https://www.youtube.com/channel/UC3NoEZFQ083fyZPeygAIYkw`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <YoutubeOutlined
                        style={{ color: footer.tabs.iconColor }}
                        className="icon-size-color"
                      />
                    </a>
                  </Col>
                )}
                {footer?.tabs?.linkUrl && (
                  <Col className="margin-Top">
                    <a
                      target="_blank"
                      href={footer["tabs"]["linkUrl"]}
                      // href={` https://www.linkedin.com/`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <LinkedinOutlined
                        style={{ color: footer.tabs.iconColor }}
                        className="icon-size-color"
                      />
                    </a>
                  </Col>
                )}

                {/* <Col className="margin-Top">
              <a
                target="_blank"
                href={`https://www.skype.com/en/`}
                style={{ textDecoration: "none" }}
              >
                <SkypeOutlined className="icon-size-color" />
              </a>
            </Col> */}
                {/* <Col className="margin-Top">
              <a
                target="_blank"
                href={`https://www.google.com/`}
                style={{ textDecoration: "none" }}
              >
                <GooglePlusOutlined className="icon-size-color" />
              </a>
            </Col> */}
              </Row>
            </div>

            <Row
              style={{
                justifyContent: "end",
                fontSize: "24px",
                backgroundColor: footer?.copyRight.backgroundColor,
              }}
            >
              {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                <EditOutlined
                  style={{
                    fontSize: "24px",
                    marginRight: "16px",
                    marginTop: "10px",
                    cursor: "pointer",
                    color: footer?.copyRight.lastFootertextColor,
                  }}
                  onClick={() =>
                    this.setState({
                      showFooterModal: true,
                      showEditModalText: "footerCopyRight",
                      footerImage: "",
                    })
                  }
                />
              )}
            </Row>
            <Row
              justify="center"
              style={{
                // backgroundColor: "#2a2a2a",
                backgroundColor: footer?.copyRight.backgroundColor,
              }}
            >
              <Col span={24}>
                <p
                  className="copyRight"
                  style={{
                    textAlign: "center",
                    color: footer?.copyRight.lastFootertextColor,
                  }}
                >
                  {footer.copyRight.text}
                </p>
              </Col>
              <Col span={24}>
                <p
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Link
                    to="/privacyPolicies"
                    onClick={this.handleclick}
                    style={{
                      color: footer?.copyRight.lastFootertextColor,
                    }}
                  >
                    Privacy Policy
                  </Link>{" "}
                  <span style={{ color: "#fff" }}>|</span>{" "}
                  <Link
                    to="/termsOfService"
                    onClick={this.handleclick}
                    style={{
                      color: footer?.copyRight.lastFootertextColor,
                    }}
                  >
                    Terms of Service
                  </Link>
                </p>
              </Col>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "11px",
                  color: "#a5a2a2",
                  fontFamily: "'Poppins', sans-serif",
                  width: "61%",
                }}
              >
                <img
                  src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${footer.copyRight.logo}`}
                  alt="copyrightLogo"
                  width="70px"
                  height="32px"
                  style={{ verticalAlign: "bottom" }}
                />

                <span
                  style={{
                    // backgroundColor: "#2a2a2a",
                    color: footer?.copyRight.lastFootertextColor,
                  }}
                >
                  {" "}
                  {" " +
                    footer.copyRight.description +
                    " " +
                    moment.utc(maxDate).format("ll")}
                </span>
                <br />
              </p>
            </Row>
          </Layout>
        )}
        <Modal
          className="modal-black-close"
          visible={showFooterModal}
          title="Footer changes"
          onCancel={() =>
            this.setState({
              showFooterModal: false,
            })
          }
          footer={[
            <Button
              key="back"
              onClick={() =>
                this.setState({
                  showFooterModal: false,
                })
              }
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              form="form1"
              loading={loading}
            >
              Proceed
            </Button>,
          ]}
          width={
            window.screen.width >= "768"
              ? "40%"
              : window.screen.width > "501" && window.screen.width <= "767"
              ? "50%"
              : "100%"
          }
        >
          {showEditModalText === "footerCard"
            ? this.footerCard()
            : showEditModalText === "footerTabs"
            ? this.footerTabs()
            : this.footerCopyRight()}
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(OpenFooter));
