import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import { Col, Row, Form, Input, Select } from "antd";
import Advertisement from "../../../common/Advertisement";

class BuyerPropertyFeatures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minArea: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.minArea
        : 1,
      maxArea: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.maxArea
        : 20000,
      yearBuild: "",
      featuresOfchecked: this.props.context.state.sellerHomeDetails
        .propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures
            .featuresOfchecked
        : [],
    };
  }
  onFinish = async (e) => {
    e["minArea"] = this.state.minArea;
    e["maxArea"] = this.state.maxArea;
    e["minPrice"] = this.state.minPrice;
    e["maxPrice"] = this.state.maxPrice;
    e["title"] = this.state.title;
    const buyerHomeDetails = this.props.context.state.buyerHomeDetails;
    const data =
      buyerHomeDetails &&
      buyerHomeDetails.buyerPropertyDetails &&
      buyerHomeDetails.buyerPropertyDetails;
    data["propertyType"] = e.propertyType;
    this.props.handleForm(data, "ADD_BUYER_GENERAL_FORM");
    this.props.handleForm(e, "ADD_BUYER_FEATURE_DETAIL_FORM", "saveBuyer");
  };
  onChangeMin = (value) => {
    this.setState({ minArea: value.target.value });
  };
  onChangeMax = (value) => {
    this.setState({ maxArea: value.target.value });
  };
  componentDidMount() {
    const { buyerHomeDetails } = this.props;
    if (buyerHomeDetails && buyerHomeDetails.buyerPropertyFeatures) {
      this.setState({
        maxArea:
          buyerHomeDetails && buyerHomeDetails.buyerPropertyFeatures.maxArea,
        minArea:
          buyerHomeDetails && buyerHomeDetails.buyerPropertyFeatures.minArea,
      });
    }
  }

  handleFeaturesBox = (featuresOfchecked) => {
    this.setState({
      featuresOfchecked,
    });
  };

  render() {
    const DesiredBeds = [
      { label: "1+", value: 1 },
      { label: "2+", value: 2 },
      { label: "3+", value: 3 },
      { label: "4+", value: 4 },
      { label: "5+", value: 5 },
      { label: "6+", value: 6 },
      { label: "7+", value: 7 },
      { label: "8+", value: 8 },
      { label: "9+", value: 9 },
    ];
    const DesiredBaths = [
      { label: "1+", value: 1 },
      { label: "1.5+", value: 1.5 },
      { label: "2+", value: 2 },
      { label: "2.5+", value: 2.5 },
      { label: "3+", value: 3 },
      { label: "3.5+", value: 3.5 },
      { label: "4+", value: 4 },
      { label: "4.5+", value: 4.5 },
      { label: "5+", value: 5 },
    ];
    const yearBuiltOptions = [
      "1700-1920",
      "1920-1950",
      "1950-1980",
      "1990-2010",
      "2010+",
    ];
    const LotSizeOptionsForBuyerForm = [
      "0-0.5 acre",
      "0.5-1 acre",
      "1-2 acres",
      "2-5 acres",
      "5+ acres",
    ];
    const GarageSpaceSelction = ["1+", "2+", "3+"];
    const { maxArea, minArea } = this.state;
    const { buyerUserDetails, buyerHomeDetails } = this.props;
    const property1 = [
      { label: "Land", value: "Land" },
      { label: "Mobile Homes", value: "Mobile Homes" },
      {
        label: "Residential Multi Family Home",
        value: "Residential Multi Family Home",
      },
      {
        label: "Residential Single Family Home",
        value: "Residential Single Family Home",
      },
      { label: "Residential Rental ", value: "Residential Rental" },
      { label: "Residential Condominium", value: "Residential Condominium" },
      { label: "Coopertive", value: "Coopertive" },
      { label: "Commercial Office", value: "Commercial Office" },
      { label: "Commercial MLS", value: "Commercial MLS" },
      { label: "Business Opportunity", value: "Business Opportunity" },
    ];
    const FeaturesTags = [
      "Air Conditioning",
      "Fireplace",
      "Gym",
      "Laundry",
      "Outdoor Shower",
      "Sauna",
      "Shed",
      "Swimming Pool",
      "TV Cable",
      "Waterfront",
      "Wood Stove",
      "Walkout basement",
      "Fenced yard",
      "Fire pit",
      "Flat yard",
      "Wet bar",
      " Gas fireplace",
      "Walk in closet",
      "Screen porch",
      "Hardwood floors",
      "Granite counters",
      "Culdesac",
      "Hot Tub",
      "Sprinklers",
      "Central Vacuum",
      "Security System",
      "Walk-up Attic",
    ];

    const typeOfDwelling = [
      "Colonial",
      "Commercial",
      "Condo",
      "Contemporary",
      "Gambrel",
      "Multi Family",
      "Ranch",
      "Split Level",
      "Townhome",
      "Triple Decker",
    ];
    return (
      <div>
        <Row>
          {this.props && !this.props.BuyerProfile && (
            <Col
              xl={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
            >
              <div className="privateD-title"></div>
              <div style={{ marginBottom: 20 }}>
                <span className="privateD-des"></span>
              </div>
            </Col>
          )}
          <Col
            xl={{
              span: this.props && this.props.BuyerProfile ? 22 : 16,
              offset: this.props && this.props.BuyerProfile ? 1 : 0,
            }}
            lg={{
              span: this.props && this.props.BuyerProfile ? 22 : 16,
              offset: this.props && this.props.BuyerProfile ? 1 : 0,
            }}
            md={{
              span: this.props && this.props.BuyerProfile ? 22 : 24,
              offset: this.props && this.props.BuyerProfile ? 1 : 0,
            }}
            xs={{
              span: this.props && this.props.BuyerProfile ? 24 : 22,
              offset: this.props && this.props.BuyerProfile ? 0 : 1,
            }}
            sm={{
              span: this.props && this.props.BuyerProfile ? 24 : 22,
              offset: this.props && this.props.BuyerProfile ? 0 : 1,
            }}
          >
            <Form
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              size="large"
              id="form"
            >
              <Row>
                <Col
                  xl={{ span: 11, offset: 0 }}
                  lg={{ span: 11, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <Row>
                    <Col span={11}>
                      <div className="form-titles">
                        Desired Living area (Min)
                      </div>
                      <Input
                        type="number"
                        addonAfter="sq.ft"
                        className="form-input-number-addonAfter"
                        value={minArea}
                        onChange={this.onChangeMin}
                        step="Any"
                      />
                    </Col>
                    <Col span={11} offset={2}>
                      <div
                        className="form-titles"
                        // style={{ marginLeft: "4vw" }}
                      >
                        Desired Living area (Max)
                      </div>
                      <Input
                        type="number"
                        addonAfter="sq.ft"
                        value={maxArea}
                        onChange={this.onChangeMax}
                        className="form-input-number-addonAfter"
                        step="Any"
                      />
                    </Col>
                  </Row>

                  <>
                    <div className="form-titles">Desired Beds</div>
                    <Form.Item
                      name="beds"
                      initialValue={
                        buyerHomeDetails &&
                        buyerHomeDetails.buyerPropertyFeatures
                          ? buyerHomeDetails.buyerPropertyFeatures.beds
                          : buyerUserDetails &&
                            buyerUserDetails.buyerHomeFeatures &&
                            buyerUserDetails.buyerHomeFeatures.beds
                          ? buyerUserDetails.buyerHomeFeatures.beds
                          : ""
                      }
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Desired Beds"
                      >
                        {DesiredBeds.map((province) => (
                          <Select.Option
                            key={province.value}
                            value={province.value}
                          >
                            {province.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                  <div className="form-titles">Desired Baths</div>
                  <Form.Item
                    name="baths"
                    initialValue={
                      buyerHomeDetails && buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures.baths
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.baths
                        ? buyerUserDetails.buyerHomeFeatures.baths
                        : ""
                    }
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Desired Baths"
                    >
                      {DesiredBaths.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="form-titles">Desired Type of Dwelling</div>
                  <Form.Item
                    name="dwelling"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.dwelling
                        ? buyerHomeDetails.buyerPropertyFeatures.dwelling
                        : []
                    }
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select Type Of Dwelling"
                      mode={
                        this.props.AddBuyer || this.props.BuyerProfile
                          ? "multiple"
                          : ""
                      }
                    >
                      {typeOfDwelling.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="form-titles">Desired Garage</div>
                  <Form.Item
                    name="garageSpace"
                    initialValue={
                      buyerHomeDetails && buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures.garageSpace
                        : ""
                    }
                  >
                    <Select placeholder="Select">
                      {GarageSpaceSelction.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="form-titles">Desired Year Built</div>
                  <Form.Item
                    name="yearBuilt"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.yearBuilt
                        ? buyerHomeDetails.buyerPropertyFeatures.yearBuilt
                        : []
                    }
                  >
                    <Select
                      placeholder="You can select multiple"
                      mode="multiple"
                      allowClear
                    >
                      {yearBuiltOptions.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="form-titles">Desired Condition</div>
                  <Form.Item
                    name="condition"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.condition
                        ? buyerHomeDetails.buyerPropertyFeatures.condition
                        : []
                    }
                  >
                    <Select
                      className="form-input"
                      placeholder="Select Condition"
                      mode={
                        this.props.AddBuyer || this.props.BuyerProfile
                          ? "multiple"
                          : ""
                      }
                    >
                      <Select.Option value="Poor">Poor</Select.Option>
                      <Select.Option value="Fair">Fair</Select.Option>
                      <Select.Option value="Average">Average</Select.Option>
                      <Select.Option value="Good">Good</Select.Option>
                      <Select.Option value="Excellent">Excellent</Select.Option>
                      <Select.Option value="Brand New">Brand New</Select.Option>
                    </Select>
                  </Form.Item>
                  <div className="form-titles">Desired Basement</div>
                  <Form.Item
                    name="basement"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.basement
                        ? buyerHomeDetails.buyerPropertyFeatures.basement
                        : []
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <>
                    <div className="form-titles">Property Type</div>
                    <Form.Item
                      name="propertyType"
                      initialValue={
                        buyerHomeDetails &&
                        buyerHomeDetails.buyerPropertyFeatures &&
                        buyerHomeDetails.buyerPropertyFeatures.propertyType
                          ? buyerHomeDetails.buyerPropertyFeatures.propertyType
                          : []
                      }
                    >
                      <Select placeholder="Select" mode={"multiple"} allowClear>
                        {property1.map((province) => (
                          <Select.Option
                            multiple
                            key={province.value}
                            value={province.value}
                          >
                            {province.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                  <div className="form-titles">Desired Lot Size</div>
                  <Form.Item
                    name="LotSize"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.LotSize
                        ? buyerHomeDetails.buyerPropertyFeatures.LotSize
                        : []
                    }
                  >
                    <Select placeholder="Select" mode="multiple">
                      {LotSizeOptionsForBuyerForm.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <div className="form-titles">Desired Cooling</div>
                  <Form.Item
                    name="colling"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.colling
                        ? buyerHomeDetails.buyerPropertyFeatures.colling
                        : []
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Desired Heating</div>
                  <Form.Item
                    name="heating"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.heating
                        ? buyerHomeDetails.buyerPropertyFeatures.heating
                        : []
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Desired Utilities</div>
                  <Form.Item
                    name="utilities"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.utilities
                        ? buyerHomeDetails.buyerPropertyFeatures.utilities
                        : []
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Desired Interior Features</div>
                  <Form.Item
                    name="interiorFeatures"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.interiorFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures
                            .interiorFeatures
                        : []
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Desired Exterior Features</div>
                  <Form.Item
                    name="exteriorFeatures"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.interiorFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures
                            .interiorFeatures
                        : []
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: this.props.BuyerProfile ? 23 : 23 }}
                  lg={{ span: this.props.BuyerProfile ? 23 : 23 }}
                  md={{ span: this.props.BuyerProfile ? 23 : 23 }}
                  xs={{ span: this.props.BuyerProfile ? 24 : 24 }}
                  sm={{ span: this.props.BuyerProfile ? 24 : 24 }}
                >
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "600",
                      fontFamily: "Poppins,sans-serif",
                    }}
                  >
                    Desired Features
                  </div>
                  <Form.Item
                    name="featuresOfchecked"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyFeatures &&
                      buyerHomeDetails.buyerPropertyFeatures.featuresOfchecked
                        ? buyerHomeDetails.buyerPropertyFeatures
                            .featuresOfchecked
                        : []
                    }
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="You Can Select Multiple Features"
                    >
                      {FeaturesTags.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          {this.props && !this.props.BuyerProfile && (
            <Col
              xl={{ span: 7, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              md={{ span: 24, offset: 0 }}
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
            >
              <Advertisement
                otherAds={false}
                adType="Square"
                adWhereToShow="Buyers"
                adLimit="3"
                zipCode=""
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default withAppContext(withRouter(BuyerPropertyFeatures));
