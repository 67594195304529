import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import { Col, Row, Form, InputNumber, notification, Select } from "antd";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import Advertisement from "../../../common/Advertisement";

class BuyerPropertyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      publicAddress: "",
      city: "",
      state: "",
      zipCode: "",
      clint: "",
      listPrice: "",
      maxPrice: 900000,
      minPrice: 1000,
      citySearch: "",
      usersTypeData: [],
      newIntrestedTown: [],
    };
  }
  componentDidMount() {
    const { buyerHomeDetails } = this.props;
    if (
      buyerHomeDetails &&
      buyerHomeDetails.buyerPropertyDetails &&
      buyerHomeDetails.buyerPropertyDetails.stateTypeAdded &&
      buyerHomeDetails.buyerPropertyDetails.stateTypeAdded
    ) {
      this.setState({
        newIntrestedTown:
          buyerHomeDetails.buyerPropertyDetails.updatedInterestedTowns,
      });
    }

    if (
      buyerHomeDetails &&
      buyerHomeDetails.buyerPropertyDetails &&
      buyerHomeDetails.buyerPropertyDetails.minprice
    ) {
      this.setState({
        minPrice: buyerHomeDetails.buyerPropertyDetails.minprice,
      });
    }
    if (
      buyerHomeDetails &&
      buyerHomeDetails.buyerPropertyDetails &&
      buyerHomeDetails.buyerPropertyDetails.maxprice
    ) {
      this.setState({
        maxPrice: buyerHomeDetails.buyerPropertyDetails.maxprice,
      });
    }
  }
  handlePrice = (e) => {
    this.setState({
      maxPrice: e[1],
      minPrice: e[0],
      listPrice: e[1],
    });
  };

  onChangeMin = (value) => {
    if (this.state.maxPrice > value) {
      this.setState({ minPrice: value });
    }
  };

  onChangeMax = (value) => {
    if (this.state.minPrice < value) {
      this.setState({ maxPrice: value, listPrice: value });
    }
  };

  handleTimeFrame = (value, dateFormate) => {
    this.setState({
      startTime: dateFormate[0],
      endTime: dateFormate[1],
    });
  };

  onFinish = (e) => {
    e["sellerInterestedTown"] = [""];
    e["updatedInterestedTowns"] = this.state.newIntrestedTown;
    e["minprice"] = this.state.minPrice;
    e["maxprice"] = this.state.maxPrice;
    e["stateTypeAdded"] = true;
    this.props.context.setBuyerHomeDetails(e, "BUYER_PROPERTY_DETAILS");
    this.props.handleBuyerDetails(e);
  };

  handleListPrice = (e) => {
    this.setState({
      listPrice: e,
    });
  };

  handleSingleSearch = (address) => {
    let cityName = "";
    let stateName = "";
    let citiesList2 =
      this.state.newIntrestedTown && this.state.newIntrestedTown.length > 0
        ? this.state.newIntrestedTown
        : [];
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityName = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateName = address[i].short_name;
          }
        }
        const stateCodes = JSON.parse(
          localStorage.getItem("stateCodesFromAdmin")
        );
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateName.toLowerCase() ||
            restState.stateType.toLowerCase() === stateName.toLowerCase()
        );

        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityName,
            stateName: stateName,
            type: "stateName",
          };
          if (newSearchLocation) {
            citiesList2.push(newSearchLocation);
          }
          this.setState({
            newIntrestedTown: citiesList2,
          });
        } else {
          notification["error"]({
            message: "Please enter valid city...",
            description: "You can search cities from MA, CT, RI, NH states",
            placement: "topLeft",
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  changeCity = (e) => {
    this.setState({
      city: e,
    });
  };

  onClose = (value, stateRemove) => {
    let updateSearchLocations = this.state.newIntrestedTown;

    updateSearchLocations.splice(
      updateSearchLocations.findIndex(
        (e) => e.cityName === value && e.stateName === stateRemove
      ),
      1
    );
    this.setState({
      newIntrestedTown: updateSearchLocations,
    });
  };

  render() {
    const { minPrice, maxPrice, listPrice } = this.state;
    const { buyerHomeDetails } = this.props;
    const property1 = [
      { label: "Land", value: "Land" },
      { label: "Mobile Homes", value: "Mobile Homes" },
      {
        label: "Residential Multi Family Home",
        value: "Residential Multi Family Home",
      },
      {
        label: "Residential Single Family Home",
        value: "Residential Single Family Home",
      },
      { label: "Residential Rental ", value: "Residential Rental" },
      { label: "Residential Condominium", value: "Residential Condominium" },
      { label: "Coopertive", value: "Coopertive" },
      { label: "Commercial Office", value: "Commercial Office" },
      { label: "Commercial MLS", value: "Commercial MLS" },
      { label: "Business Opportunity", value: "Business Opportunity" },
    ];

    const timeFrame = [
      "1-4 months",
      "4-6 months",
      "6-12 months",
      "12-16 months",
      "16+ months",
    ];

    return (
      <div>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="privateD-title">Buyer Details</div>
          </Col>
          <Col
            xl={{ span: 16, offset: 0 }}
            lg={{ span: 16, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Form
              id="form"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              size="large"
            >
              <Row>
                <Col
                  xl={{ span: 11, offset: 0 }}
                  lg={{ span: 11, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">Property Type</div>
                  <Form.Item
                    name="propertyType"
                    initialValue={
                      buyerHomeDetails.buyerPropertyDetails
                        ? buyerHomeDetails.buyerPropertyDetails.propertyType
                        : []
                    }
                  >
                    <Select
                      placeholder="You can select multiple"
                      mode="multiple"
                      allowClear
                      showSearch={window.screen.width >= "992" ? true : false}
                    >
                      {property1.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Row style={{ marginTop: "2vw" }}>
                    <Col span={11} className="style-price">
                      <div
                        style={{
                          fontSize: 15,
                          fontWeight: 600,
                          fontFamily: "Poppins,sans-serif",
                        }}
                      >
                        Min Price
                      </div>

                      <InputNumber
                        formatter={(value) =>
                          ` $ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        // className="form-input-for-media1"
                        value={`${minPrice}`}
                        onChange={this.onChangeMin}
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Col>
                    <Col span={11} offset={2}>
                      <div
                        style={{
                          fontSize: 15,
                          fontWeight: 600,
                          fontFamily: "Poppins,sans-serif",
                        }}
                      >
                        Max Price
                      </div>
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        value={`${listPrice ? listPrice : maxPrice}`}
                        onChange={this.onChangeMax}
                        // className="form-input-for-media2"
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">
                    Desired Time Frame for Moving
                  </div>
                  <Form.Item
                    name="timeFrame"
                    initialValue={
                      buyerHomeDetails.buyerPropertyDetails &&
                      buyerHomeDetails.buyerPropertyDetails.timeFrame
                    }
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Desired Time Frame"
                    >
                      {timeFrame.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="form-titles">Buyer towns of interest</div>
                  <Form.Item
                    name="sellerInterestedTown"
                    className="google-form-search-add-home"
                  >
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        types: ["(cities)"],
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      className=" css-1uccc91-singleValue"
                      selectProps={{
                        placeholder: "Search for city",
                        value: "",
                        onChange: (result) => {
                          this.handleSingleSearch(result);
                        },
                      }}
                    ></GooglePlacesAutocomplete>

                    {this.state.newIntrestedTown &&
                      this.state.newIntrestedTown.map((city, index) => (
                        <div
                          key={index}
                          style={{
                            display: "inline-flex",
                            margin: "0.73vw",
                            padding: "0 7px",
                            flexWrap: "wrap",
                            justifyContent: "space-around",
                            border: "1px solid darkgrey",
                            marginLeft: "0px",
                          }}
                        >
                          {city.cityName && (
                            <>
                              <div>
                                {city.cityName}, {city.stateName}
                              </div>
                              {/* <div>{city.cityName ? city.cityNamecity.stateName : city}</div> */}
                              <div
                                style={{
                                  color: "grey",
                                  cursor: "pointer",
                                  marginLeft: "0.4vw",
                                }}
                                onClick={() =>
                                  this.onClose(city.cityName, city.stateName)
                                }
                              >
                                x
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Advertisement
              otherAds={false}
              adType="Square"
              adWhereToShow="Buyers"
              adLimit="1"
              zipCode=""
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withAppContext(withRouter(BuyerPropertyDetails));
