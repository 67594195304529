import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../store/contextStore";
import { Layout, Row, Steps, Button, notification, Col } from "antd";
import { add } from "../../api/Apis";
import { Link } from "react-router-dom";
import { update, noAuthgetDetails } from "../../api/Apis";
import GeneralForm from "./AdduserBuyerfrom/GeneralForm";
import BuyerPropertyFeatures from "./AdduserBuyerfrom/BuyerPropertyFeatures";
import Loading from "../../common/Loading";

const { Step } = Steps;
class AllusersBuyerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      next: 0,
      form: "form",
      errorMsg: "",
      savingDetails: false,
      data: {
        entityData: {},
      },
      buyerHomeDetails: "",
      doBackPage: false,
    };
  }
  componentDidMount = () => {   
    if (window.location.pathname !== "/add/buyerform") {
      this.setState({
        savingDetails: true
      })
      const id = this.props.location.state.id;
      noAuthgetDetails("getBuyers", id).then((editBuyer) => {
      this.props.context.setBuyerHomeDetails(editBuyer.data, "ALL_DATA");
        this.setState({ buyerHomeDetails: editBuyer.data,savingDetails: false });
      });
    }
  };
  newMethod = (e, type, saveBuyer) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (type === "ADD_BUYER_GENERAL_FORM") {
      this.props.context.setBuyerHomeDetails(e, "ADD_BUYER_GENERAL_FORM");
    } else if (type === "BUYER_PROPERTY_DETAILS2") {
      this.props.context.setBuyerHomeDetails(e, "BUYER_PROPERTY_DETAILS2");
    } else if (type === "ADD_BUYER_FEATURE_DETAIL_FORM") {
      this.props.context.setBuyerHomeDetails(
        e,
        "ADD_BUYER_FEATURE_DETAIL_FORM"
      );
      if (saveBuyer && !this.state.doBackPage) {
        this.handleAddhome();
      }
    }
    if (e && this.state.current !== 1 && !this.state.doBackPage) {
      this.setState({ current: this.state.current + 1 });
    } else {
      if (this.state.doBackPage) {
        this.setState({ current: 0 });
      }
    }
  };

  prev = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      doBackPage: true,
    });
    // this.setState({ current: this.state.current - 1 });
  };
  next = () => {
    this.setState({
      doBackPage: false,
    });
  };
  callingApiForAddHome = async () => {
    const { buyerHomeDetails, userDetails } = this.props.context.state;
    let orgId = JSON.parse(localStorage.getItem("orgId"));

    if (userDetails && userDetails.id) {
      // console.log(data,"ggg");
      //   return
      this.setState({
        savingDetails: true,
      });
      if (
        window.location.pathname ===
        `/add/buyerform/${this.props.match.params.id}`
      ) {
        const data = Object.assign(
          {},
          {
            entityData: {
              createdAt: this.props.location.state.createdAt,
              buyerPrivateDetails: buyerHomeDetails.buyerPrivateDetails,
              buyerPropertyDetails: buyerHomeDetails.buyerPropertyDetails,
              buyerPropertyFeatures: buyerHomeDetails.buyerPropertyFeatures,
              buyerPropertyDetails2: buyerHomeDetails.buyerPropertyDetails2,
              userId: userDetails.id,
              sellerVsBuyerVsVendor: true,
              orgId,
              isActive:true              
            },
          }
        );
        try {
          const UpdateHomeDetails = await update(
            "buyers",
            this.props.match.params.id,
            data
          );
          if (UpdateHomeDetails.success) {
            this.setState({
              savingDetails: false,
            });
            notification["success"]({
              message: "Buyer details updated",
            });
            if (userDetails.userProfile === "V") {
              this.props.history.push(
                `/sellerBuyer/viewProfile/${userDetails.id}`
              );
            } else {
              this.props.history.push(`/viewProfile/${userDetails.id}`);
            }
          }
        } catch (error) {
          notification["error"]({
            message: "Something went wrong!",
            description: "Please try again",
          });
        }
      } else {
        const data = Object.assign(
          {},
          {
            entityData: {
              userId: userDetails.id,
              buyerPrivateDetails: buyerHomeDetails.buyerPrivateDetails,
              buyerPropertyDetails: buyerHomeDetails.buyerPropertyDetails,
              buyerPropertyFeatures: buyerHomeDetails.buyerPropertyFeatures,
              buyerPropertyDetails2: buyerHomeDetails.buyerPropertyDetails2,
              sellerVsBuyerVsVendor: true,
              orgId,
              isActive:true,
              buyerAgentId: userDetails?.agentInfo?.assignAgentId,
              virtualContactId: userDetails?.virtualContactId
            },
          }
        );
        let data12 = "";
        try {
          add("buyers", data).then(
            (result) =>
              result.success &&
              this.setState({
                savingDetails: false,
              }),
            this.props.context.setBuyerHomeDetails(data12, "BUYER_EMPTY_DATA")
          );
          notification["success"]({
            message: "New Buyer added",
          });
          if (userDetails.userProfile === "V") {
            this.props.history.push(
              `/sellerBuyer/viewProfile/${userDetails.id}`
            );
          } else {
            this.props.history.push(`/viewProfile/${userDetails.id}`);
          }
        } catch (error) {
          notification["error"]({
            message: "Something went wrong!",
            description: "Please try again",
          });
        }
      }
    } else {
      notification["error"]({
        message: "Something went wrong!",
        description: "Please try again",
      });
    }
  };
  handleAddhome = async () => {
    this.setState({
      savingDetails: true,
    });
    await setTimeout(() => this.callingApiForAddHome(), 3000);
  };

  setLoadingFalse = () => {
    this.props.context.setLoading(true);
  };

  render() {
    const { current, savingDetails, buyerHomeDetails } = this.state;

    const steps = [
      {
        title: "General",
        content: (
          <GeneralForm
            handleForm={this.newMethod}
            editPrivateDetails={
              this.props.location.state &&
              this.props.location.state.privateDetails
            }
            buyerHomeDetails={
              window.location.pathname === "/add/buyerform"
                ? this.props.context.state.buyerHomeDetails
                : buyerHomeDetails
            }
            doBackPage={this.state.doBackPage}
          />
        ),
      },
      {
        title: "Property Features",
        content: (
          <BuyerPropertyFeatures
            handleForm={this.newMethod}
            buyerHomeDetails={
              window.location.pathname === "/add/buyerform"
                ? this.props.context.state.buyerHomeDetails
                : buyerHomeDetails
            }
            doBackPage={this.state.doBackPage}
          />
        ),
      },
    ];
    return (
      <div style={{ backgroundColor: "rgb(249, 250, 255)" }}>
        <Loading enableLoading={savingDetails} />
        <Layout className="Add-home-details">
          {buyerHomeDetails ? (
            <>
              <Steps
                size="small"
                current={current}
                direction={
                  window.screen.width >= "605" ? "horizontal" : "vertical"
                }
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title}></Step>
                ))}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
            </>
          ) : (
            window.location.pathname === "/add/buyerform" && (
              <>
                <Steps
                  size="small"
                  current={current}
                  direction={
                    window.screen.width >= "605" ? "horizontal" : "vertical"
                  }
                >
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title}></Step>
                  ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
              </>
            )
          )}
        </Layout>

        <Row style={{ marginBottom: "1vw" }}>
          <Col
            xl={{ span: 3, offset: 8 }}
            lg={{ span: 3, offset: 8 }}
            md={{ span: 3, offset: 8 }}
            xs={{ span: 10, offset: 1 }}
            sm={{ span: 10, offset: 1 }}
          >
            {current > 0 ? (
              <Button
                // type="primary"
                // onClick={this.prev}
                // style={{ width: "100%", height: "40px" }}

                onClick={this.prev}
                type="primary"
                form="form"
                style={{ width: "100%", height: "40px" }}
                htmlType="submit"
              >
                Back
              </Button>
            ) : (
              <Link
                to={`/viewProfile/${this.props.context.state.userDetails.id}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ width: "100%", height: "40px" }}
                  type="primary"
                  onClick={this.setLoadingFalse}
                >
                  Cancel
                </Button>
              </Link>
            )}
          </Col>
          <Col
            xl={{ span: 3, offset: 1 }}
            lg={{ span: 3, offset: 1 }}
            md={{ span: 3, offset: 1 }}
            xs={{ span: 10, offset: 2 }}
            sm={{ span: 10, offset: 2 }}
          >
            {current < steps.length - 1 && (
              <>
                <Button
                  type="primary"
                  form="form"
                  style={{ width: "100%", height: "40px" }}
                  htmlType="submit"
                  onClick={this.next}
                >
                  Next Step
                </Button>
              </>
            )}
            {current === steps.length - 1 && (
              <>
                <Button
                  type="primary"
                  id="step1"
                  style={{ width: "100%", height: "40px" }}
                  form="form"
                  htmlType="submit"
                >
                  Save
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
export default withAppContext(withRouter(AllusersBuyerForm));
