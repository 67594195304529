import React, { Component } from "react";
import {
  Row,
  Col,
  Divider,
  notification,
  Popover,
  List,
  Badge,
  Skeleton,
  Drawer,
  Tabs,
} from "antd";
import "antd/dist/antd.css";
import { withRouter } from "react-router";
import moment from "moment";
import {
  ClockCircleOutlined,
  HomeOutlined,
  BellOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { list, update } from "../api/Apis";
import { withAppContext } from "../store/contextStore";

const { TabPane } = Tabs;
export class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      viewAllAlerts: [],
      viewAllUnreadAlerts: [],
      viewAllNotifications: [],
      viewAllQueNotifications: [],
      viewAllAnsNotifications: [],
      allNotifications: [],
      queNotifications: [],
      ansNotifications: [],
      loginUserId: "",
    };
  }

  componentDidMount() {
    this.getQuestionAlerts();
  }

  getQuestionAlerts = async () => {
    const loginUserId = JSON.parse(localStorage.getItem("userDetails"));
    let userId = loginUserId.id;
    if (userId && userId !== "") {
      try {
        const allAlerts = await list("notifications?relatedTo=" + userId);
        const allUnreadAlerts = await list(
          "notifications?relatedTo=" + userId + "&isNew=true"
        );
        this.setState({
          loginUserId: userId,
          viewAllAlerts: allAlerts.data,
          viewAllUnreadAlerts: allUnreadAlerts.data,
        });
      } catch (error) {
        notification["error"]({
          message: error.message,
        });
      }
    }
  };

  readAllAlerts = async () => {
    const loginUserId = JSON.parse(localStorage.getItem("userDetails"));
    let userId = loginUserId.id;
    if (userId && userId !== "") {
      try {
        const response = await update("clearNotifications", userId);
        if (response.success) {
          this.getQuestionAlerts();
        } else {
          this.getQuestionAlerts();
          notification["error"]({
            message: response.message,
          });
        }
      } catch (error) {
        this.getQuestionAlerts();
        notification["error"]({
          message: error.message,
        });
      }
    }
  };

  updateQuery = async (propertyId, queryId, stateType, icon) => {
    let data;
    data = {
      entityData: {
        isNew: false,
      },
    };

    try {
      const response = await update("updateNotificationStatus", queryId, data);
      if (response.success) {
        this.getQuestionAlerts();
        this.resetToDefaults();
        if (icon === "C") {
          window.location.replace(`/VirtualOffice/ViewAllContacts/${propertyId}`);
        } else {
          window.location.replace(
            `/homeDetails/${stateType}/${propertyId}${
              icon === "P" || icon === "R"
                ? "#schedule"
                : icon === "Q" || icon === "A"
                ? "#questions"
                : ""
            }`
          );
        }
      } else {
        this.resetToDefaults();
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      this.resetToDefaults();
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
    }
  };

  resetToDefaults = () => {
    this.setState({
      enableAllNotifications: false,
    });
  };

  noResults = () => {
    return (
      <List.Item>
        <h4
          style={{
            color: "gray",
            textAlign: "center",
            marginTop: "20px",
            marginLeft: "120px",
          }}
        >
          No results found
        </h4>
      </List.Item>
    );
  };

  showMatchesAlerts = (alerts, action) => {
    return alerts
      .filter((qanda) => qanda.status === "SM")
      .map((alert) =>
        this.propertyItem(
          alert.status,
          alert.propertyTitle,
          alert.message,
          alert.createdBy,
          alert.updatedAt,
          alert.propertyId,
          alert.id,
          alert.isNew,
          alert.stateType,
          action
        )
      );
  };

  scheduleAlerts = (alerts, action) => {
    return alerts
      .filter((qanda) => qanda.status === "P" || qanda.status === "R")
      .map((alert) =>
        this.propertyItem(
          alert.status,
          alert.propertyTitle,
          alert.message,
          alert.createdBy,
          alert.updatedAt,
          alert.propertyId,
          alert.id,
          alert.isNew,
          alert.stateType,
          action
        )
      );
  };

  qandaAlerts = (alerts, action) => {
    return alerts
      .filter((qanda) => qanda.status === "Q" || qanda.status === "A")
      .map((alert) =>
        this.propertyItem(
          alert.status,
          alert.propertyTitle,
          alert.message,
          alert.createdBy,
          alert.updatedAt,
          alert.propertyId,
          alert.id,
          alert.isNew,
          alert.stateType,
          action
        )
      );
  };

  conatctAlerts = (alerts, action) => {
    return alerts
      .filter((qanda) => qanda.notificationType === "contactLead")
      .map((alert) =>
        this.propertyItem(
          alert.status,
          alert.propertyTitle,
          alert.message,
          alert.createdBy,
          alert.updatedAt,
          alert.propertyId,
          alert.id,
          alert.isNew,
          alert.stateType,
          action
        )
      );
  };

  propertyItem = (
    icon,
    title,
    description,
    userName,
    date,
    propertyId,
    queryId,
    isNew,
    stateType,
    fullDesc
  ) => {

    return (
      <div
        onClick={() => this.updateQuery(propertyId, queryId, stateType, icon)}
        style={{
          border: "1px solid #eee",
          marginBottom: "5px",
          padding: "0 10px",
          cursor: "pointer",
          backgroundColor: isNew ? "#FFF" : "#eee",
        }}
      >
        <List.Item>
          <Skeleton avatar title={false} loading={false} active>
            <List.Item.Meta
              avatar={
                <h1
                  style={{
                    backgroundColor: isNew ? "#d4d8f1fa" : "#ccc",
                    width: "50px",
                    height: "50px",
                    textAlign: "center",
                    color: "#333",
                    marginRight: "-6px",
                  }}
                >
                  {icon === "P" ? (
                    <ClockCircleOutlined style={{ marginTop: "10px" }} />
                  ) : icon === "SM" ? (
                    <HomeOutlined style={{ marginTop: "10px" }} />
                  ) : icon === "C" ? (
                    <UserOutlined style={{ marginTop: "10px" }} />
                  ) : (
                    icon
                  )}
                </h1>
              }
              title={
                <div style={{ marginTop: "-5px" }}>
                  <h4>
                    {title && title !== "" && title !== undefined
                      ? title.substring(
                          0,
                          title.length >= 45 ? title.length : 45
                        )
                      : ""}

                    {title &&
                    title !== "" &&
                    title !== undefined &&
                    title.length > 45
                      ? "..."
                      : ""}
                  </h4>
                </div>
              }
              description={
                <Row style={{ marginTop: "-5px" }}>
                  <Col span={24}>
                    <h5>
                      {description &&
                      description !== "" &&
                      description !== undefined
                        ? description.substring(
                            0,
                            description.length >= 45 ? description.length : 45
                          )
                        : ""}

                      {description &&
                      description !== "" &&
                      description !== undefined &&
                      description.length > 45
                        ? "..."
                        : ""}
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h6
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      {userName}{" "}
                      <span style={{ color: "#aaa" }}>
                        {" "}
                        {moment(date).format("lll")}
                      </span>
                    </h6>
                  </Col>
                </Row>
              }
            />
          </Skeleton>
        </List.Item>
        {/* <h6
          style={{
            marginTop: "-16px",
            marginLeft: "60px",
            fontWeight: "700",
          }}
        >
          {userName}{" "}
          <span style={{ color: "#aaa" }}> {moment(date).format("lll")}</span>
        </h6> */}
      </div>
    );
  };

  render() {
    const { enableAllNotifications, viewAllAlerts, viewAllUnreadAlerts } =
      this.state;
    return (
      <>
        {enableAllNotifications ? (
          <Drawer
            title={"All notifications (" + viewAllAlerts.length + ")"}
            placement="right"
            width={
              window.screen.width >= "768"
                ? "25%"
                : window.screen.width > "501" && window.screen.width <= "767"
                ? "50%"
                : "100%"
            }
            mask={true}
            closable={true}
            getContainer={false}
            visible={enableAllNotifications}
            onClose={() =>
              this.setState({
                enableAllNotifications: false,
              })
            }
            headerStyle={{ textAlign: "center" }}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Row>
              <Col span={24}>
                <Tabs
                  centered
                  animated
                  useOnPan
                  swipeable
                  size="small"
                  type="card"
                  tabPosition="top"
                  defaultActiveKey="1"
                >
                  <TabPane tab="Homes" key="1">
                    {viewAllAlerts.length > 0
                      ? this.showMatchesAlerts(viewAllAlerts, true)
                      : this.noResults()}
                  </TabPane>
                  <TabPane tab="Schedule" key="2">
                    {viewAllAlerts.length > 0
                      ? this.scheduleAlerts(viewAllAlerts, true)
                      : this.noResults()}
                  </TabPane>
                  <TabPane tab="Q & A" key="3">
                    {viewAllAlerts.length > 0
                      ? this.qandaAlerts(viewAllAlerts, true)
                      : this.noResults()}
                  </TabPane>
                  <TabPane tab="Contacts" key="4">
                    {viewAllAlerts.length > 0
                      ? this.conatctAlerts(viewAllAlerts, true)
                      : this.noResults()}
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Drawer>
        ) : (
          <Popover
            placement="bottom"
            title={<h3 style={{ textAlign: "center" }}>Notifications</h3>}
            content={
              <div className="notification-height">
                <Tabs
                  centered
                  animated
                  useOnPan
                  swipeable
                  size="small"
                  type="card"
                  tabPosition="top"
                  defaultActiveKey="1"
                >
                  <TabPane tab="Homes" key="1">
                    {viewAllUnreadAlerts && viewAllUnreadAlerts.length > 0
                      ? this.showMatchesAlerts(viewAllUnreadAlerts, false)
                      : this.noResults()}
                  </TabPane>
                  <TabPane tab="Schedule" key="2">
                    {viewAllUnreadAlerts && viewAllUnreadAlerts.length > 0
                      ? this.scheduleAlerts(viewAllUnreadAlerts, false)
                      : this.noResults()}
                  </TabPane>
                  <TabPane tab="Q & A" key="3">
                    {viewAllUnreadAlerts && viewAllUnreadAlerts.length > 0
                      ? this.qandaAlerts(viewAllUnreadAlerts, false)
                      : this.noResults()}
                  </TabPane>
                  <TabPane tab="Contacts" key="4">
                    {viewAllUnreadAlerts && viewAllUnreadAlerts.length > 0
                      ? this.conatctAlerts(viewAllUnreadAlerts, false)
                      : this.noResults()}
                  </TabPane>
                </Tabs>

                <Divider></Divider>

                <div>
                  <Row justify="space-between">
                    <h4
                      style={{
                        color: "#1890ff",
                        textAlign: "center",
                        marginTop: "-10px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          enableAllNotifications: true,
                        });
                      }}
                    >
                      Show all alerts
                    </h4>

                    <h4
                      style={{
                        color: "#1890ff",
                        textAlign: "center",
                        marginTop: "-10px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.readAllAlerts();
                      }}
                    >
                      Mark all as read
                    </h4>
                  </Row>
                </div>
              </div>
            }
            trigger="click"
          >
            <a
              href="!#"
              style={{
                color: "white",
                textDecoration: "none",
                display: "inline-box",
                borderRadius: "2px",
              }}
            >
              <BellOutlined
                alt="notification"
                // className="notify-header"
                className="heart-header"
                style={{ marginRight: "4px", color: this.props.color }}
              />
              {/* <notificationBell
                src={notificationBell}
                alt="notification"
                className="notify-header"
                style={{ marginRight: "4px" }}
              /> */}
              <Badge count={viewAllUnreadAlerts.length}>
                <span className="head-example" />
              </Badge>
            </a>
          </Popover>
        )}
      </>
    );
  }
}

export default withAppContext(withRouter(Notifications));
