import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  notification,
  Modal,
  Button,
  List,
  Skeleton,
  Rate,
  Input,
  Avatar,
  Spin,
} from "antd";
import {
  PlusCircleTwoTone,
  CopyOutlined,
  ShareAltOutlined,
  FacebookOutlined,
  CheckCircleTwoTone,
  CloseSquareOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import { Storage } from "aws-amplify";
import { withRouter } from "react-router";
import * as htmlToImage from "html-to-image";
import { noAuthlist, add } from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import ExternalTestimonials from "./ExternalTestimonials";
// import profilePic from "../images/profilePic.png";
// import logo1 from "../images/logo1.png";
import DataNotFound from "../common/DataNotFound";
import getImageBase64 from "get-image-base64";
import DeleteRecord from "./DeleteRecord";
const { TextArea } = Input;
export class Testmonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonialsList: [],
      testimonialData: "",
      testimonialRating: 0,
      localKnowledgeRating: 0,
      professionalismRating: 0,
      responsivenessRating: 0,
      industryKnowledgeRating: 0,
      enableShareTestimonial: false,
      enableAddTestimonial: false,
      editTestimonial: false,
      enableDelete: false,
      dataLoading: false,
      valildUser: false,
      enableSharePreview: false,
      previewTestimonialInfo: "",
      loggedeUserInfo: "",
      isLoading: false,
      isShared: false,
      sharedProfilePic: "",
    };
  }

  componentDidMount() {
    this.getUserTestmonials();
    this.getBase64FromUrl();
    this.setState({ dataLoading: true });
  }

  getBase64FromUrl = async () => {
    let orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    let orgniziationLogo = orgDetails?.Header?.headerLogo;

    Storage.get(orgniziationLogo).then((ur) =>
      getImageBase64(
        ur,
        (base64Str) => {
          this.setState({
            orgLogo: base64Str,
          });
        },
        "jpeg",
        1
      )
    );

    if (
      this.props?.context?.state?.userDetails?.profilePicture &&
      this.props?.context?.state?.userDetails?.profilePicture.length > 0
    ) {
      let newProfile = await Storage.get(
        this.props.context.state.userDetails.profilePicture[0]
      );
      await getImageBase64(
        newProfile,
        (base64Str) => {
          this.setState({
            sharedProfilePic: base64Str,
          });
        },
        "jpeg",
        1
      );
    }
  };

  getUserTestmonials = async () => {
    let userLogin = JSON.parse(localStorage.getItem("userDetails"));
    const { userDetails } = this.props.context.state;
    const { userId } = this.props;
    this.setState({
      testimonialsList: [],
      loggedeUserInfo: userDetails,
    });

    if (userDetails && userDetails.id !== "") {
      this.setState({
        valildUser: true,
      });
    }

    if (userLogin && userLogin.id === userId) {
      this.setState({
        enableDelete: true,
      });
    } else {
      this.setState({
        enableDelete: false,
      });
    }

    this.setState({
      dataLoading: true,
    });

    try {
      const response = await noAuthlist(
        "testimonials?userId=" + this.props.userId + "&dataType=testimonial"
      );
      if (response.success) {
        this.setState({
          testimonialsList: response.data,
          dataLoading: false,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
        this.setState({
          dataLoading: false,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
      this.setState({
        dataLoading: false,
      });
    }
  };

  saveUserTestmonials = async () => {
    if (this.state.testimonialData === "") {
      notification["error"]({
        message: "Please enter your comment.",
      });
      return;
    }

    if (
      this.state.localKnowledgeRating === 0 &&
      this.state.professionalismRating === 0 &&
      this.state.responsivenessRating === 0 &&
      this.state.industryKnowledgeRating === 0
    ) {
      notification["error"]({
        message: "Please give your rating.",
      });
      return;
    }

    this.setState({
      enableAddTestimonial: false,
      dataLoading: true,
    });

    const { userDetails } = this.props.context.state;
    const { userId } = this.props;
    const {
      testimonialData,
      localKnowledgeRating,
      professionalismRating,
      responsivenessRating,
      industryKnowledgeRating,
    } = this.state;

    let ratingAverage = (
      (localKnowledgeRating +
        professionalismRating +
        responsivenessRating +
        industryKnowledgeRating) /
      4
    ).toFixed(1);
    let orgId = JSON.parse(localStorage.getItem("orgId"));

    const data = {
      entityData: {
        userId: userId,
        testimonialDesc: testimonialData,
        postedBy: userDetails.id,
        rating: ratingAverage,
        localKnowledgeRating: localKnowledgeRating,
        responsivenessRating: responsivenessRating,

        professionalismRating: professionalismRating,
        industryKnowledgeRating: industryKnowledgeRating,

        dataType: "testimonial",
        orgId,
      },
    };

    try {
      const response = await add("testimonials", data);
      if (response.success) {
        notification["success"]({
          message: "Your record added successfully",
        });
        this.getUserTestmonials();
        this.resetToDefaults();
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
    }
  };

  cancel = (e) => {
    console.log(e);
  };

  handleTestMonials = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  resetToDefaults = () => {
    this.setState({
      testimonialData: "",
      testimonialRating: 0,
      localKnowledgeRating: 0,
      professionalismRating: 0,
      responsivenessRating: 0,
      industryKnowledgeRating: 0,
      enableAddTestimonial: false,
      dataLoading: false,
      enableSharePreview: false,
      previewTestimonialInfo: "",
      isLoading: false,
      isShared: false,
    });
  };

  resetTestimonial = () => {
    this.setState({
      testimonialData: "",
      testimonialRating: 0,
      enableAddTestimonial: false,
      dataLoading: false,
    });
    this.getUserTestmonials();
  };

  copyUrl = () => {
    var textBox = document.getElementById("shareUrl");
    textBox.select();
    document.execCommand("copy");
    notification["info"]({
      message: "URL copied successfully",
    });
  };

  sharePreview = (info) => {
    this.setState({
      enableSharePreview: true,
      previewTestimonialInfo: info,
    });
  };

  generateImage = async (testimonialId) => {
    this.setState({
      isLoading: true,
    });
    var post = document.getElementById("generateImage");
    htmlToImage
      .toJpeg(post, {
        style: {
          backgroundColor: "#FFF",
        },
        quality: 0.5,
      })
      .then((dataUrl) => {
        this.openFacebook(testimonialId, dataUrl);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };
  downloadTestmonial = () => {
    const post = document.getElementById("generateImage");
    htmlToImage
      .toJpeg(post, {
        style: {
          backgroundColor: "#fff",
        },
        quality: 0.95,
      })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "Testmonial.jpeg";
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => console.log(error, "oooo"));
  };

  openFacebook = async (testimonialId, dataUrl) => {
    let access = { contentType: "image/jpeg" };
    let imageData = await fetch(dataUrl);
    let blobData = await imageData.blob();
    let imageName = testimonialId + "_sharing_profile.jpeg";
    Storage.put(imageName, blobData, access).then(async (result) => {
      let sharingUrl = `https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${result.key}`;
      window.FB.ui(
        {
          method: "share",
          href: sharingUrl,
        },
        this.setState({
          isLoading: false,
          isShared: true,
          enableSharePreview: false,
          previewTestimonialInfo: "",
        })
      );
      console.log(sharingUrl, "sharingUrl");
    });
  };

  testimonialItem = (testimonial) => {
    let firstName =
      testimonial.postedBy &&
      testimonial.postedBy.firstName &&
      (testimonial.postedBy.firstName !== undefined ||
        testimonial.postedBy.firstName !== "")
        ? testimonial.postedBy.firstName
        : testimonial &&
          testimonial.firstName &&
          (testimonial.firstName !== undefined || testimonial.firstName !== "")
        ? testimonial.firstName
        : "";

    let lastName =
      testimonial.postedBy &&
      testimonial.postedBy.lastName &&
      (testimonial.postedBy.lastName !== undefined ||
        testimonial.postedBy.lastName !== "")
        ? testimonial.postedBy.lastName
        : testimonial &&
          testimonial.lastName &&
          (testimonial.lastName !== undefined || testimonial.lastName !== "")
        ? testimonial.lastName
        : "";

    return (
      <Card
        hoverable
        style={{
          marginBottom: "8px",
        }}
      >
        <Row>
          <Col
            xxl={{ span: 19, offset: 0 }}
            xl={{ span: 18, offset: 0 }}
            lg={{ span: 17, offset: 0 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Col span={24}>
              <List.Item>
                <Skeleton
                  avatar
                  title={false}
                  loading={this.state.dataLoading}
                  active
                >
                  <List.Item.Meta
                    avatar={
                      testimonial &&
                      testimonial.postedBy &&
                      testimonial.postedBy.profileUrl !== null ? (
                        <Avatar
                          size={64}
                          src={testimonial.postedBy.profileUrl}
                        />
                      ) : (
                        <Avatar size={64}>
                          {firstName.charAt(0).toUpperCase()}
                        </Avatar>
                      )
                    }
                    title={firstName + " " + lastName}
                    description={
                      <Col span={24} style={{ marginTop: "-8px" }}>
                        <Rate disabled defaultValue={testimonial.rating} />
                        <h5
                          style={{
                            fontWeight: "300",
                            color: "grey",
                          }}
                        >
                          {moment(testimonial.updatedAt).format("lll")}
                        </h5>
                      </Col>
                    }
                  />
                </Skeleton>
              </List.Item>
            </Col>
            <Col span={24} style={{ marginTop: "-10px" }}>
              <p className="testimonila-desc">{testimonial.testimonialDesc}</p>
            </Col>
          </Col>

          <Col
            xxl={{ span: 4, offset: 1 }}
            xl={{ span: 5, offset: 1 }}
            lg={{ span: 6, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Row
              justify="end"
              style={{ paddingTop: "12px", paddingBottom: "12px" }}
            >
              <Col span={24} className="testmonial-star">
                <h4 style={{ marginBottom: "-8px", color: "gray" }}>
                  Local Knowledge
                </h4>
                <Rate
                  disabled
                  defaultValue={testimonial.localKnowledgeRating}
                />
              </Col>
              <Col span={24} className="testmonial-star">
                <h4 style={{ marginBottom: "-8px", color: "gray" }}>
                  Professionalism
                </h4>
                <Rate
                  disabled
                  defaultValue={testimonial.professionalismRating}
                />
              </Col>
              <Col span={24} className="testmonial-star">
                <h4 style={{ marginBottom: "-8px", color: "gray" }}>
                  Responsiveness
                </h4>
                <Rate
                  disabled
                  defaultValue={testimonial.responsivenessRating}
                />
              </Col>
              <Col span={24} className="testmonial-star">
                <h4 style={{ marginBottom: "-8px", color: "gray" }}>
                  Industry Knowledge
                </h4>
                <Rate
                  disabled
                  defaultValue={testimonial.industryKnowledgeRating}
                />
              </Col>
            </Row>
            <Row justify="end">
              <Col span={24}>
                {this.state.enableDelete && (
                  <Row
                    className="testmonial-share"
                    style={{ marginBottom: "5px" }}
                  >
                    <Col
                      style={{
                        cursor: "pointer",
                        border: "1px solid green",
                        color: "white",
                        padding: "5px",
                      }}
                      onClick={() => this.sharePreview(testimonial)}
                    >
                      <span
                        style={{
                          color: "green",
                        }}
                      >
                        <ShareAltOutlined /> Share
                      </span>
                    </Col>
                    <Col className="Delete-testmonial">
                      <DeleteRecord
                        recordId={testimonial.id}
                        apiPath={"testimonials"}
                        getHomesList={this.getUserTestmonials}
                        handleReset={this.resetToDefaults}
                        buttonText={"Delete"}
                        fontSizeProp={"14px"}
                        deletionType={"DeleteOutlined"}
                        deletionSuccessMessage={
                          "Your record deleted successfully"
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const {
      testimonialsList,
      enableAddTestimonial,
      editTestimonial,
      testimonialData,
      localKnowledgeRating,
      professionalismRating,
      responsivenessRating,
      industryKnowledgeRating,
      enableDelete,
      valildUser,
      enableShareTestimonial,
      previewTestimonialInfo,
      enableSharePreview,
      loggedeUserInfo,
      isLoading,
      dataLoading,
      isShared,
      sharedProfilePic,
      orgLogo,
    } = this.state;
    return (
      <>
        <Spin spinning={dataLoading}>
          <Card
            bordered={false}
            title={testimonialsList.length + " Testimonial(s)"}
            extra={
              !enableDelete ? (
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      enableAddTestimonial: true,
                    })
                  }
                >
                  <PlusCircleTwoTone style={{ fontSize: "28px" }} />
                </Col>
              ) : (
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      enableShareTestimonial: !enableShareTestimonial,
                    })
                  }
                >
                  <ShareAltOutlined
                    style={{ fontSize: "28px", color: "green" }}
                  />
                </Col>
              )
            }
          >
            {enableShareTestimonial && (
              <Row style={{ marginBottom: "20px" }}>
                <Col span={18}>
                  <Input
                    name="shareUrl"
                    id="shareUrl"
                    value={
                      this.props && this.props.userId
                        ? window.location.origin +
                          "/" +
                          "testimonials/" +
                          this.props.userId
                        : ""
                    }
                  />
                </Col>

                <Col span={6}>
                  <Button
                    type="primary"
                    icon={<CopyOutlined />}
                    onClick={() => this.copyUrl()}
                    style={{ width: "100%" }}
                  >
                    Copy URL
                  </Button>
                </Col>
              </Row>
            )}

            <List
              itemLayout="horizontal"
              dataSource={testimonialsList}
              renderItem={(item, index) => this.testimonialItem(item, index)}
            />
          </Card>
          {testimonialsList && testimonialsList.length === 0 && (
            <DataNotFound type="testimonial" title="No testimonials as yet." />
          )}
        </Spin>
        <Modal
          visible={enableAddTestimonial}
          title="ADD TESTIMONIAL"
          width={window.screen.width >= "768" ? "45vw" : "90vw"}
          style={{ marginTop: "2%" }}
          onCancel={() =>
            this.setState({
              enableAddTestimonial: false,
            })
          }
          className="buyer-agent-modal"
          footer={
            valildUser ? (
              <Col span={24}>
                <Row>
                  <Col span={12} style={{ padding: "10px" }}>
                    <Button
                      type="default"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.setState({
                          enableAddTestimonial: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>

                  <Col span={12} style={{ padding: "10px" }}>
                    <Button
                      type="primary"
                      style={{ width: "100%" }}
                      onClick={this.saveUserTestmonials}
                    >
                      {editTestimonial ? "Update" : "Save"}
                    </Button>
                  </Col>
                </Row>
              </Col>
            ) : null
          }
        >
          {valildUser ? (
            <Row style={{ padding: "20px" }}>
              <Card style={{ marginTop: "20px" }}>
                <Col span={24} style={{ padding: "10px" }}>
                  <div className="label-titles">Testimonial :</div>
                  <TextArea
                    placeholder="Start typing..."
                    rows={4}
                    name="testimonialData"
                    value={testimonialData}
                    onChange={this.handleTestMonials}
                  />
                </Col>

                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <div className="label-titles">Local knowledge :</div>
                  <span>
                    <Rate
                      name="localKnowledgeRating"
                      onChange={(value) =>
                        this.setState({
                          localKnowledgeRating: value,
                        })
                      }
                      value={localKnowledgeRating}
                    />
                  </span>
                </Col>

                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <div className="label-titles">Professionalism</div>
                  <span>
                    <Rate
                      name="professionalismRating"
                      onChange={(value) =>
                        this.setState({
                          professionalismRating: value,
                        })
                      }
                      value={professionalismRating}
                    />
                  </span>
                </Col>

                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <div className="label-titles">Responsiveness :</div>
                  <span>
                    <Rate
                      name="responsivenessRating"
                      onChange={(value) =>
                        this.setState({
                          responsivenessRating: value,
                        })
                      }
                      value={responsivenessRating}
                    />
                  </span>
                </Col>

                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                  sm={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <div className="label-titles">Industry Knowledge :</div>
                  <span>
                    <Rate
                      name="industryKnowledgeRating"
                      onChange={(value) =>
                        this.setState({
                          industryKnowledgeRating: value,
                        })
                      }
                      value={industryKnowledgeRating}
                    />
                  </span>
                </Col>
              </Card>
            </Row>
          ) : (
            <Row>
              <Col span={24} style={{ padding: "10px", marginTop: "20px" }}>
                <ExternalTestimonials
                  resetTestimonial={this.resetTestimonial}
                />
              </Col>
            </Row>
          )}
        </Modal>

        <Modal
          className="modal-black-close"
          visible={enableSharePreview}
          title={null}
          closable={false}
          afterClose={() =>
            this.setState({
              isLoading: false,
              isShared: false,
              enableSharePreview: false,
              previewTestimonialInfo: "",
            })
          }
          onCancel={() =>
            this.setState({
              isLoading: false,
              isShared: false,
              enableSharePreview: false,
              previewTestimonialInfo: "",
            })
          }
          footer={
            isShared
              ? [
                  <Button
                    style={{
                      cursor: "pointer",
                    }}
                    size="large"
                    type="default"
                    icon={<CloseSquareOutlined style={{ fontSize: "22px" }} />}
                    onClick={() =>
                      this.setState({
                        isLoading: false,
                        enableSharePreview: false,
                        previewTestimonialInfo: "",
                      })
                    }
                  >
                    Cancel
                  </Button>,
                ]
              : [
                  <Button
                    style={{
                      cursor: "pointer",
                      // backgroundColor: "red",
                    }}
                    size="large"
                    // type="primary"
                    loading={this.state.dataLoading}
                    onClick={() => this.downloadTestmonial()}
                  >
                    <Row>
                      <Col>
                        <DownloadOutlined style={{ fontSize: "22px" }} />
                      </Col>
                      <Col className="facebook-text">Download</Col>
                    </Row>
                  </Button>,
                  <Button
                    style={{
                      cursor: "pointer",
                    }}
                    size="large"
                    type="primary"
                    loading={this.state.dataLoading}
                    onClick={() =>
                      this.generateImage(previewTestimonialInfo.id)
                    }
                  >
                    <Row>
                      <Col>
                        <FacebookOutlined style={{ fontSize: "22px" }} />
                      </Col>
                      <Col className="facebook-text">Facebook</Col>
                    </Row>
                  </Button>,
                  <Button
                    style={{
                      cursor: "pointer",
                    }}
                    size="large"
                    type="default"
                    onClick={() =>
                      this.setState({
                        isLoading: false,
                        enableSharePreview: false,
                        previewTestimonialInfo: "",
                      })
                    }
                  >
                    <Row>
                      <Col>
                        <CloseSquareOutlined style={{ fontSize: "22px" }} />
                      </Col>
                      <Col className="facebook-text"> Cancel</Col>
                    </Row>
                  </Button>,
                ]
          }
        >
          <Spin spinning={isLoading} size="large" className="center-item">
            {!isShared ? (
              <>
                <Row justify="center">
                  <div id="generateImage">
                    <div
                      style={{
                        backgroundColor: "transparent",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          padding: "40px",
                          borderRadius: "20px",
                          backgroundColor: "#fa952e",
                          backgroundImage: "linear-gradient(#fa952e, #fde14b)",
                        }}
                      >
                        {loggedeUserInfo && loggedeUserInfo.firstName && (
                          <div
                            style={{ marginLeft: "30px", marginTop: "30px" }}
                          >
                            <Col span={24}>
                              <Row>
                                {loggedeUserInfo.profilePicture && (
                                  <img
                                    src={sharedProfilePic}
                                    // {`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-1.amazonaws.com/public/${loggedeUserInfo.profilePicture[0]}`}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "50%",
                                    }}
                                    alt="download_image"
                                  />
                                )}

                                <div
                                  style={{
                                    marginLeft: "20px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Rate
                                    disabled
                                    style={{ fontSize: "32px" }}
                                    defaultValue={previewTestimonialInfo.rating}
                                  />
                                  <h2 style={{ marginTop: "5px" }}>
                                    {loggedeUserInfo.firstName +
                                      " " +
                                      loggedeUserInfo.lastName}
                                  </h2>
                                </div>
                              </Row>
                            </Col>
                          </div>
                        )}

                        <div
                          style={{
                            padding: "30px",
                            marginTop: "-80px",
                            borderRadius: "20px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          <Col span={24}>
                            <h5
                              style={{
                                textAlign: "justify",
                                marginTop: "50px",
                              }}
                            >
                              {previewTestimonialInfo.testimonialDesc}
                            </h5>

                            <h4
                              style={{ float: "right", marginBottom: "20px" }}
                            >
                              -{" "}
                              {previewTestimonialInfo &&
                              previewTestimonialInfo.postedBy &&
                              previewTestimonialInfo.postedBy.firstName !==
                                undefined
                                ? previewTestimonialInfo.postedBy.firstName
                                : previewTestimonialInfo.firstName}{" "}
                              {previewTestimonialInfo &&
                              previewTestimonialInfo.postedBy &&
                              previewTestimonialInfo.postedBy.lastName !==
                                undefined
                                ? previewTestimonialInfo.postedBy.lastName
                                : previewTestimonialInfo.lastName}
                            </h4>
                          </Col>
                        </div>

                        <Row
                          justify="center"
                          style={{ margin: "30px 0px 0px 0px" }}
                        >
                          <img src={orgLogo} style={{ width: "60%" }} alt="" />
                        </Row>
                      </div>
                    </div>
                  </div>
                </Row>
              </>
            ) : (
              <div style={{ padding: "40px" }}>
                <h1 style={{ color: "green", textAlign: "center" }}>
                  <span style={{ color: "green" }}>
                    <CheckCircleTwoTone
                      style={{
                        fontSize: "120px",
                        color: "green",
                        textAlign: "center",
                      }}
                    />
                  </span>
                  <hr></hr>
                  You have Successfully Shared.
                </h1>
              </div>
            )}
          </Spin>
        </Modal>
      </>
    );
  }
}

export default withAppContext(withRouter(Testmonials));
