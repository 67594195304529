import React, { Component } from "react";
import { Drawer } from "antd";
import Lottie from "lottie-react";
import loading from "../images/loading.json";
// import heartLoading from "../images/heart.json";

export default class Loading extends Component {
  render() {
    const { enableLoading } = this.props;
    const style = {
      height: 120,
      marginTop: "35px",
    };
    return (
      <div>
        <Drawer
          className="background-transparent"
          visible={enableLoading}
          closable={false}
          placement="top"
          width="100%"
          height="100%"
          size="large"
          style={{ backgroundColor: "transparent" }}
        >
          <div
            style={{
              width: "240px",
              height: "240px",
              backgroundColor: "#00000073",
              borderRadius: "50%",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
            }}
          >
            <Lottie animationData={loading} style={style} />
            <h2 style={{ color: "#fc572b", textAlign: "center" }}>
              Please wait...
            </h2>
          </div>
        </Drawer>
      </div>
    );
  }
}
