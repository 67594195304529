import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Divider,
  Form,
  Input,
  Upload,
  Button,
  Spin,
  Collapse,
} from "antd";
import { Storage } from "aws-amplify";

import Loading from "../../common/Loading";
import { update } from "../../api/Apis";
import { withAppContext } from "../../store/contextStore";
import { withRouter } from "react-router-dom";
import { EditTwoTone, CameraOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import RichText from "../../common/RichText";
const Panel = Collapse.Panel;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoading: true,
      editVisible: false,
      activeItemKey: 1,
      richtTextBiofield: "",
      uploadProfileIsLoading: false,
      myPicture: "",
      card_title: "",
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        initialLoading: false,
      });
    }, 2000);

    // this.savePageThemes();
  };
  savePageThemes = async (aboutUsPage) => {
    const { landingScreen, Header, footer, WhyJoinUsPage, contactUsPage } =
      this.props.context.state.websiteThemes;
    let adminDetails = JSON.parse(localStorage.getItem("orgDetails"));

    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserProfile && loginUserProfile.orgId) {
      // let websiteId = "89e875c0-93cb-11ec-9d69-a3aa85cc7e98"
      let websiteId = await loginUserProfile.orgId;

      let data = {
        entityData: {
          landingScreen,
          Header,
          footer,
          aboutUsPage,
          contactUsPage,
          WhyJoinUsPage,
        },
      };

      data["entityData"]["isNew"] = false;
      let userData = {
        entityData: {
          isNew: false,
        },
      };

      update("saveWebsitesData", websiteId, data)
        .then((result) => {
          if (result.success) {
            update("users", loginUserProfile?.id, userData);
            this.setState({
              showTitle: "",
              editVisible: false,
              card_title: "",
              richtTextBiofield: "",
            });
            this.props.context.getWebsites(adminDetails.websiteName);
            return true;
          }
        })
        .catch((error) => console.log(error));
    }
  };
  handlePageEdit = (text, myPicture) => {
    this.setState({
      editVisible: true,
      showTitle: text,
      myPicture,
    });
  };
  handleRichtextBio = (bio) => {
    this.setState({
      richtTextBiofield: bio,
    });
  };
  handleMenuItems = (e) => {
    if (e.key) {
      this.setState({
        activeItemKey: e.key,
      });
    }
  };
  handleEditText = (values) => {
    const { aboutUsPage } = this.props.context.state.websiteThemes;
    const { myPicture, richtTextBiofield } = this.state;
    console.log(richtTextBiofield, ";;;;");
    let data = {
      aboutUs_title: values["aboutUs_title"],
      ourStory_title: values["ourStory_title"],
      myPicture: myPicture ? myPicture : aboutUsPage?.myPicture,
      aboutUs_conclusion: aboutUsPage.aboutUs_conclusion,
      ourStory_description: richtTextBiofield
        ? richtTextBiofield
        : aboutUsPage?.ourStory_description,
      aboutUs_cards: aboutUsPage.aboutUs_cards,
    };

    this.savePageThemes(data);
  };
  handleCroppedProfileImage = async (info) => {
    this.setState({
      uploadProfileIsLoading: true,
    });

    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  myPicture: result.key,
                  uploadProfileIsLoading: false,
                });
              });
            } else {
              this.setState({
                uploadProfileIsLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  handleTitle = (e) => {
    this.setState({
      card_title: e.target.value,
    });
  };
  handleCardsData = (cardsData) => {
    const { aboutUsPage } = this.props.context.state.websiteThemes;

    aboutUsPage.aboutUs_cards.forEach((element, index) => {
      if (element.tab_key === cardsData.tab_key) {
        element["title_name"] =
          this.state.card_title !== ""
            ? this.state.card_title
            : cardsData.title_name;
        element["title_des"] =
          this.state.richtTextBiofield !== ""
            ? this.state.richtTextBiofield
            : cardsData.title_des;
      }
    });

    this.savePageThemes(aboutUsPage);
  };

  sumbitProfileData = () => {
    const { aboutUsPage } = this.props.context.state.websiteThemes;
    const { richtTextBiofield, myPicture } = this.state;
    aboutUsPage["aboutUs_conclusion"]["conclusion_des"] = richtTextBiofield;
    aboutUsPage["aboutUs_conclusion"]["profile_image"] = myPicture;
    this.savePageThemes(aboutUsPage);
  };

  aboutUs_card_edit = () => {
    const { aboutUsPage } = this.props.context.state.websiteThemes;
    const { myPicture, uploadProfileIsLoading } = this.state;
    let page_data = "";
    page_data = aboutUsPage;
    const pageData = page_data?.ourStory_description;
    const showProfilePic = myPicture ? myPicture : aboutUsPage?.myPicture;
    return (
      <div>
        <Row style={{ padding: "10px" }}>
          <Col span={24}>
            <Form
              initialValues={{ remember: true }}
              onFinish={this.handleEditText}
              id="form"
            >
              <div className="form-titles">About Us title</div>
              <Form.Item
                name="aboutUs_title"
                rules={[
                  {
                    required: false,
                    message: "Please input text",
                  },
                ]}
                initialValue={page_data.aboutUs_title}
              >
                <Input
                  placeholder="First Name"
                  className="form-input"
                  // value={sellerName}
                />
              </Form.Item>
              <div className="form-titles">Our story title</div>
              <Form.Item
                name="ourStory_title"
                rules={[
                  {
                    required: false,
                    message: "Please input text!",
                  },
                ]}
                initialValue={page_data.ourStory_title}
              >
                <Input
                  placeholder="Last Name"
                  className="form-input"
                  // value={sellerName}
                />
              </Form.Item>
              <div className="form-titles">Story description</div>
              <Form.Item
                name="lenderLastName"
                rules={[
                  {
                    required: false,
                    message: "Please input  text",
                  },
                ]}
              >
                <RichText
                  handleRichtextBio={this.handleRichtextBio}
                  richtTextBiofieldProp={pageData}
                />
              </Form.Item>
              <Form.Item
                name="lenderLastName"
                rules={[
                  {
                    required: false,
                    message: "Please input Last name!",
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                  }}
                  showUploadList={false}
                  customRequest={dummyRequest}
                  onChange={(e) => this.handleCroppedProfileImage(e)}
                >
                  <Spin
                    spinning={uploadProfileIsLoading}
                    size="large"
                    className="center-item"
                    style={{ marginTop: "10%" }}
                  >
                    {showProfilePic !== "" ? (
                      <img
                        src={
                          `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                          showProfilePic
                        }
                        alt="profile"
                        className="img-profile"
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <CameraOutlined className="camera-icon" />
                    )}
                  </Spin>
                </Upload>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  };
  aboutUs_cards_edit = () => {
    const { aboutUsPage } = this.props.context.state.websiteThemes;
    return (
      <div style={{ padding: "10px" }}>
        <Collapse bordered={false} defaultActiveKey={["1"]} accordion>
          {aboutUsPage.aboutUs_cards?.map((editCard) => (
            <>
              <Panel
                header={editCard.title_name}
                key={editCard.tab_key}
                className="customPanelStyle"
              >
                <Row style={{ padding: "10px" }}>
                  <Col span={24}>
                    <Form
                      initialValues={{ remember: true }}
                      onFinish={() => this.handleCardsData(editCard)}
                      id="form"
                    >
                      <div className="form-titles">Title</div>
                      <Form.Item
                        name="title_name"
                        rules={[
                          {
                            required: false,
                            message: "Please input First name",
                          },
                        ]}
                        initialValue={editCard.title_name}
                      >
                        <Input
                          placeholder="First Name"
                          className="form-input"
                          onChange={this.handleTitle}
                        />
                      </Form.Item>
                      <div className="form-titles">Description</div>
                      <Form.Item
                        name="title_des"
                        rules={[
                          {
                            required: false,
                            message: "Please input Last name!",
                          },
                        ]}
                        initialValue={editCard.title_des}
                        style={{ marginBottom: "30px" }}
                      >
                        <RichText
                          handleRichtextBio={this.handleRichtextBio}
                          richtTextBiofieldProp={editCard.title_des}
                        />
                      </Form.Item>
                      <Button
                        key="back"
                        onClick={() =>
                          this.setState({
                            editVisible: false,
                          })
                        }
                      >
                        Cancel
                      </Button>{" "}
                      &nbsp; &nbsp;
                      <Button
                        key="submit"
                        type="primary"
                        // onClick={() => this.update(true)}
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Panel>
            </>
          ))}
        </Collapse>
      </div>
    );
  };

  aboutUs_profile = () => {
    const { aboutUsPage } = this.props.context.state.websiteThemes;
    const { myPicture, uploadProfileIsLoading } = this.state;
    const showProfilePic =
      myPicture !== ""
        ? myPicture
        : aboutUsPage.aboutUs_conclusion?.profile_image;

    return (
      <div>
        <Row style={{ padding: "10px" }}>
          <Col span={24}>
            <Form
              initialValues={{ remember: true }}
              onFinish={this.sumbitProfileData}
              id="form"
            >
              {/* <Spin
                spinning={uploadProfileIsLoading}
                size="large"
                className="center-item"
                style={{ marginTop: "10%" }}
              >
                <img
                  src={
                    `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                    showProfilePic
                  }
                  alt="profile"
                  className="img-profile"
                  width="100%"
                  height="100%"
                />
                <Button
                  type="primary"
                  className="update-profile-btn"
                  style={{
                    width: "52%",
                  }}
                >
                  
                  <Upload
                    showUploadList={false}
                    customRequest={dummyRequest}
                    onChange={(e) => this.handleCroppedProfileImage(e)}
                  >
                    <span className="Btn-name">Upload picture</span>
                  </Upload>
                </Button>
              </Spin> */}
              <Form.Item
                name="lenderLastName"
                rules={[
                  {
                    required: false,
                    message: "Please input Last name!",
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                  }}
                  showUploadList={false}
                  customRequest={dummyRequest}
                  onChange={(e) => this.handleCroppedProfileImage(e)}
                >
                  <Spin
                    spinning={uploadProfileIsLoading}
                    size="large"
                    className="center-item"
                    style={{ marginTop: "10%" }}
                  >
                    {showProfilePic !== "" ? (
                      <img
                        src={
                          `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                          showProfilePic
                        }
                        alt="profile"
                        className="img-profile"
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <CameraOutlined className="camera-icon" />
                    )}
                  </Spin>
                </Upload>
              </Form.Item>
              <div className="form-titles">Story description</div>
              <Form.Item
                name="lenderLastName"
                rules={[
                  {
                    required: false,
                    message: "Please input Last name!",
                  },
                ]}
              >
                <RichText
                  handleRichtextBio={this.handleRichtextBio}
                  richtTextBiofieldProp={
                    aboutUsPage?.aboutUs_conclusion?.conclusion_des
                  }
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  };
  render() {
    // const { page_data } = this.state;
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));

    const { aboutUsPage } = this.props.context.state.websiteThemes;
    const { editVisible, showTitle } = this.state;
    let page_data = "";
    page_data = aboutUsPage;

    const edit_aboutUs_page = (
      <div
        onClick={() => this.handlePageEdit("profile_story")}
        style={{ cursor: "pointer" }}
      >
        <EditTwoTone style={{ fontSize: "30px" }} />
      </div>
    );
    const edit_aboutUs_page_cards = (
      <div
        onClick={() => this.handlePageEdit("cards", aboutUsPage?.myPicture)}
        style={{ cursor: "pointer", float: "right" }}
      >
        <EditTwoTone style={{ fontSize: "30px" }} />
      </div>
    );
    const edit_aboutUs_profile = (
      <div
        onClick={() =>
          this.handlePageEdit(
            "profile",
            aboutUsPage.aboutUs_conclusion.profile_image
          )
        }
        style={{ cursor: "pointer", float: "right" }}
      >
        <EditTwoTone style={{ fontSize: "30px" }} />
      </div>
    );
    return (
      <div>
        {page_data && (
          <>
            <Loading enableLoading={this.state.initialLoading} />
            <Row>
              <Col span={20} offset={2}>
                <Divider>
                  <h1>{page_data.aboutUs_title}</h1>
                </Divider>
              </Col>
            </Row>

            <Row>
              <Col
                xl={{ span: 20, offset: 2 }}
                lg={{ span: 20, offset: 2 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Card
                  bordered={false}
                  title={page_data.ourStory_title}
                  bodyStyle={{ padding: "25px" }}
                  extra={
                    loginUserProfile &&
                    loginUserProfile?.userProfile === "A" &&
                    edit_aboutUs_page
                  }
                >
                  <Row>
                    <Col
                      xl={{ span: 10, offset: 1 }}
                      lg={{ span: 10, offset: 1 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <img
                        alt="Tracy"
                        src={
                          `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                          page_data?.myPicture
                        }
                        className="img-about"
                        style={{
                          // marginLeft: "20px",
                          // // float: "right",
                          // width: "45%",
                          // padding: " 1%",
                          objectFit: "cover",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </Col>
                    <Col
                      xl={{ span: 11, offset: 1 }}
                      lg={{ span: 11, offset: 1 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${page_data?.ourStory_description}`,
                        }}
                      />
                    </Col>
                    {/* <Col
                      xl={{ span: 11, offset: 1 }}
                      lg={{ span: 11, offset: 1 }}
                      md={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      {page_data.ourStory_description?.map((eachParagraph) => (
                        <>
                          <p className="margin-aboutus">{eachParagraph}</p>
                        </>
                      ))}
                    </Col> */}
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row style={{ backgroundColor: "#f9faff", marginTop: "2.5vw" }}>
              <Col
                xl={{ span: 20, offset: 2 }}
                lg={{ span: 20, offset: 2 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                style={{
                  // padding: "25px",
                  marginTop: "2.5vw",
                  marginBottom: "2.5vw",
                }}
              >
                {loginUserProfile &&
                  loginUserProfile?.userProfile === "A" &&
                  edit_aboutUs_page_cards}
                <Row gutter={[24, 24]}>
                  {page_data.aboutUs_cards?.map((eachCard) => (
                    <Col
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Card
                        hoverable
                        bordered={false}
                        title={null}
                        headerStyle={{
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                        bodyStyle={{ padding: "25px" }}
                      >
                        <Divider>
                          <h2>{eachCard.title_name.toUpperCase()}</h2>
                        </Divider>
                        <p style={{ textAlign: "center" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${eachCard.title_des}`,
                            }}
                          />
                        </p>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            {page_data.aboutUs_conclusion && (
              <Row>
                <Col
                  xl={{ span: 7, offset: 5 }}
                  lg={{ span: 10, offset: 2 }}
                  md={{ span: 11, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  style={{
                    marginTop: "2.5vw",
                    marginBottom: "2.5vw",
                    padding: "1vw",
                  }}
                  bodyStyle={{ padding: "0px" }}
                >
                  <Card
                    bordered={false}
                    title={null}
                    bodyStyle={{ padding: "0px" }}
                    style={{ background: "#FFF", borderColor: "black" }}
                    cover={
                      <img
                        alt="Tracy"
                        src={
                          `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                          page_data.aboutUs_conclusion?.profile_image
                        }
                        // src={page_data.aboutUs_conclusion.profile_image}
                        className="img-about"
                        style={{ objectFit: "cover", padding: "3%" }}
                      />
                    }
                  ></Card>
                </Col>
                <Col
                  xl={{ span: 7 }}
                  lg={{ span: 9, offset: 1 }}
                  md={{ span: 10, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  style={{
                    marginTop: "2.5vw",
                    marginBottom: "2.5vw",
                    padding: "1vw",
                  }}
                >
                  <h3 className="text-about" style={{ marginTop: "1vw" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${page_data.aboutUs_conclusion?.conclusion_des}`,
                      }}
                    />
                    {/* {page_data.aboutUs_conclusion.conclusion_des?.conclusin1} */}
                  </h3>
                  {/* <h3 className="text-about">
                    {page_data.aboutUs_conclusion.conclusion_des?.conclusin2}
                  </h3>
                  <h3 className="text-about">
                    {page_data.aboutUs_conclusion.conclusion_des.conclusin3}
                  </h3>
                  <h3 className="text-about">
                    {
                      page_data.aboutUs_conclusion.conclusion_des
                        .Sincerely_title
                    }
                    ,
                  </h3>
                  <h3 className="text-about">
                    {
                      page_data.aboutUs_conclusion.conclusion_des
                        .nameOfTheFounder
                    }
                    ,
                  </h3>
                  <h3 className="text-about">
                    {page_data.aboutUs_conclusion.conclusion_des.typeofFounder},
                  </h3>
                  <h3 className="text-about">
                    {page_data.aboutUs_conclusion.conclusion_des.websiteName}.
                  </h3> */}
                </Col>
                {loginUserProfile &&
                  loginUserProfile?.userProfile === "A" &&
                  edit_aboutUs_profile}
              </Row>
            )}

            <Modal
              className="modal-black-close"
              visible={editVisible}
              // title={`Send invitation to ${invitationTitle}`}
              onCancel={() =>
                this.setState({
                  editVisible: false,
                })
              }
              footer={
                showTitle !== "cards" && [
                  <Button
                    key="back"
                    onClick={() =>
                      this.setState({
                        editVisible: false,
                      })
                    }
                  >
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    // onClick={() => this.update(true)}
                    htmlType="submit"
                    form="form"
                  >
                    Save
                  </Button>,
                ]
              }
              width={"40%"}
            >
              {showTitle === "profile_story"
                ? this.aboutUs_card_edit()
                : showTitle === "cards"
                ? this.aboutUs_cards_edit()
                : this.aboutUs_profile()}
            </Modal>
          </>
        )}
      </div>
    );
  }
}

export default withAppContext(withRouter(About));
