import { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { noAuthadd, noAuthlist, noAuthupdate } from "../api/Apis";
import Loading from "../common/Loading";

const { Option } = Select;

// const isLocalhost = Boolean(
//   window.location.hostname === 'localhost' ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === '[::1]' ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );

// // Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [
//   localRedirectSignIn,
//   productionRedirectSignIn,
// ] = awsConfig.oauth.redirectSignIn.split(',');

// const [
//   localRedirectSignOut,
//   productionRedirectSignOut,
// ] = awsConfig.oauth.redirectSignOut.split(',');

// const updatedAwsConfig = {
//   ...awsConfig,
//   oauth: {
//     ...awsConfig.oauth,
//     redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
//     redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
//   }
// }

// Amplify.configure(updatedAwsConfig);

function SignInWithFacebook({ getLoginDetails, buttonText }) {
  // const [userExits, setuserExits] = useState([]);
  const [isSelectedUserType, setisSelectedUserType] = useState({
    isOpen: false,
    isType: "",
    isLoginTrue: false,
    payload:{}
  });

  // const [isCallUseEffect, setisCallUseEffect] = useState(false);

  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      // console.log(event, data);
      switch (event) {
        case "signIn":
          // case 'cognitoHostedUI':
          if (data.username.indexOf("Facebook") > -1) {
            handleRegisterUser(data);
          }
          // let userDetails = {
          //   userid: data?.signInUserSession?.idToken?.payload?.sub,
          //   userProfile: ""
          // }
          // getLoginDetails(userDetails);
          break;
        case "signOut":
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          // console.log("Sign in failure", data);
          break;
        default:
          console.log("Default");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function getUser() {
  //   return Auth.currentAuthenticatedUser()
  //     .then((userData) => userData)
  //     .catch(() => console.log("Not signed in"));
  // }

  const handleRegisterUser = (data) => {
    setIsPageLoading(true);
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let selectedProfile = JSON.parse(
      localStorage.getItem("signupUserSelectedProfile")
    );

    const fb = window.FB;
    let entityData = {};
    let access_token = JSON.parse(localStorage.getItem("facebookAccessToken"));
    fb.api(
      `${data?.signInUserSession?.idToken?.payload?.identities[0]["userId"]}`,
      { fields: "first_name,last_name,email,picture", access_token },
      (response1) => {
        // console.log(response1, "me=====> api for fb ");
        entityData["email"] = response1.email;
        entityData["firstName"] = response1.first_name;
        entityData["lastName"] = response1.last_name;
        entityData["profileUrl"] = response1.picture.data.url;
        entityData["profileUrl2"] = response1.picture.data.url;
        localStorage.setItem(
          "fbUserProfilePic",
          JSON.stringify(response1.picture.data.url)
        );
      }
    );
    entityData = {
      ...entityData,
      id: data?.signInUserSession?.idToken?.payload?.sub,
      userProfile: selectedProfile ? selectedProfile : "",
      facebookCreatedUserName: data.username,
      facebookAppUserId:
        data?.signInUserSession?.idToken?.payload?.identities[0]["userId"],
      agentInfo: {
        agentEmail: "",
        agentName: "",
      },
      addUserName: "",
      recordStatus: true,
      orgId,
    };
    let payload = {
      entityData,
    };
// console.log(payload,"log");
    noAuthlist(
      `checkUser/${data?.signInUserSession?.idToken?.payload?.sub}?isSocial=true`
    )
      .then((res) => {
        // console.log(res);
        if (res.success) {
          if (res.data.length > 0) {
            // setuserExits(res.data);
            setisSelectedUserType({
              ...isSelectedUserType,
              payload
            })
            updateUserProfile(payload);
          } else {
            if (selectedProfile !== "") {
              handleToAddUserToTable(payload);
            } else {
              setisSelectedUserType({
                ...isSelectedUserType,
                isOpen: true,
                isLoginTrue: true,
                payload,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err, ";;;");
      });
  };

  const updateUserProfile = (payload) => {
    let userCreatedId = payload.entityData.id;
    delete payload.entityData.id;
    delete payload.entityData.userProfile;
    noAuthupdate("users", userCreatedId, payload).then((res) => {
      if (res.success) {
        let userDetails = {
          userid: userCreatedId,
          userProfile: payload.entityData.userProfile,
        };
        getLoginDetails(userDetails);
      }
    });
  };

  const handleToAddUserToTable = (payload) => {
    if (payload.entityData?.email !== "") {
      if (payload.entityData.userProfile === "") {
        setisSelectedUserType({
          ...isSelectedUserType,
          isOpen: true,
          isLoginTrue: true,
        });
      } else {
        noAuthadd("users", payload).then((userSuccess) => {
          if (userSuccess.success) {
            let userDetails = {
              userid: payload.entityData.id,
              userProfile: payload.entityData.userProfile,
            };
            getLoginDetails(userDetails);
          }
        });
      }
    }
  };

  const handleClickble = () => {
    if (buttonText === "Sign Up") {
      setisSelectedUserType({
        ...isSelectedUserType,
        isOpen: true,
        isLoginTrue: false,
      });
    } else {
      signIn();
    }
  };

  const signIn = () => {
    setisSelectedUserType({ ...isSelectedUserType, isOpen: false });
    const fb = window.FB;
    fb.getLoginStatus((response) => {
      if (response.status === "connected") {
        // console.log(response, "user connected");
        // getAWSCredentials(response.authResponse);
        localStorage.setItem(
          "facebookAccessToken",
          JSON.stringify(response.authResponse.accessToken)
        );
        handleToContinueSignUp(response.authResponse.accessToken);
      } else {
        fb.login(
          (response) => {
            if (!response || !response.authResponse) {
              return;
            }
            handleToContinueSignUp();
            localStorage.setItem(
              "facebookAccessToken",
              JSON.stringify(response.authResponse.accessToken)
            );
            // console.log(response, "user loging");
          },
          {
            // the authorized scopes
            scope: "public_profile,email",
          }
        );
      }
    });
  };

  const handleToContinueSignUp = () => {
    setisSelectedUserType({ ...isSelectedUserType, isOpen: false });
    setIsPageLoading(true);
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {/* <p>User: {user ? JSON.stringify(user.attributes) : 'None'}</p>
      {user ? (
        <button onClick={() => Auth.signOut()}>Sign Out</button>
      ) : (
        <>
        <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook })}>Open Facebook</button>
        </>
      )} */}
      <Loading enableLoading={isPageLoading} />
      <button
        onClick={() => handleClickble()}
        className="loginBtn loginBtn--facebook"
      >
        {" "}
        {buttonText} With Facebook{" "}
      </button>

      {isSelectedUserType.isOpen && (
        <Modal
          className="modal-black-close"
          visible={isSelectedUserType.isOpen}
          style={{ marginTop: "5%" }}
          onCancel={() =>
            setisSelectedUserType({ ...isSelectedUserType, isOpen: false })
          }
          // footer={null}
          title="SELECT PROFILE TYPE"
          okText={"Submit"}
          onOk={
            isSelectedUserType.isLoginTrue ? handleToAddUserToTable(isSelectedUserType.payload) : signIn
          }
        >
          <Select
            style={{
              width: "100%",
            }}
            placeholder="I am "
            onChange={
              (e) =>
                localStorage.setItem(
                  "signupUserSelectedProfile",
                  JSON.stringify(e)
                )
              // setisSelectedUserType({ ...isSelectedUserType, isType: e })
            }
            className="select_height"
          >
            <Option value="AG">Agent</Option>
            <Option value="B">Buyer</Option>
            <Option value="S">Seller</Option>
            <Option value="L">Lender</Option>
            <Option value="V">Vendor</Option>
          </Select>
        </Modal>
      )}
    </div>
  );
}
export default SignInWithFacebook;
