import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { withAppContext } from "../store/contextStore";
import Header from "../common/Header";
import AdditionalContent from "../common/AdditionalContent";
import Footer from "../components/home/OpenFooter";
import Navigation from "../common/Navigation";
import { EditOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Col, Input, Form, Dropdown } from "antd";
import { ModalTitle } from "../StyledComponents/StyledComponents";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import { update } from "../api/Apis";
class Authenticated extends Component {
  state = {
    footerModal: false,
    activebgColor: "",
    bgColor: "",
    textColor: "",
  };
  // componentDidMount() {
  //   this.getWebsiteData();
  // }
  // getWebsiteData = () => {
  //   this.props.context.getWebsites(window.location.hostname);
  // };
  handleCancel = () => {
    this.setState({
      footerModal: this.props.onCancel,
    });
  };

  showData = () => {
    const { bgColor, textColor, activebgColor } = this.state;
    const { footer } = this.props.context.state.websiteThemes;
    const TextColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={textColor}
          onChange={(e) =>
            this.setState({
              textColor: e.color,
            })
          }
        />
      </div>
    );
    const ButtonColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={bgColor}
          onChange={(e) =>
            this.setState({
              bgColor: e.color,
            })
          }
        />
      </div>
    );

    const ActiveButtonColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={activebgColor}
          onChange={(e) =>
            this.setState({
              activebgColor: e.color,
            })
          }
        />
      </div>
    );

    return (
      <div>
        {footer && (
          <Row style={{ padding: "10px" }}>
            <Col span={24}>
              <Form id="form" onFinish={this.onFinish}>
                <Col span={23} style={{ marginBottom: "1vw" }}>
                  <ModalTitle>Background Color</ModalTitle>
                  <Input
                    value={bgColor ? bgColor : footer?.menuTabs?.bgColor}
                    onChange={(e) =>
                      this.setState({
                        bgColor: e.target.value,
                      })
                    }
                    suffix={
                      <Dropdown trigger={["click"]} overlay={ButtonColor}>
                        <Button
                          style={{
                            background: bgColor || footer?.menuTabs?.bgColor,
                          }}
                        >
                          {" "}
                        </Button>
                      </Dropdown>
                    }
                  />
                </Col>

                <Col span={23} style={{ marginBottom: "1vw" }}>
                  <ModalTitle>Active Background Color</ModalTitle>
                  <Input
                    value={
                      activebgColor
                        ? activebgColor
                        : footer?.menuTabs?.activebgColor
                    }
                    onChange={(e) =>
                      this.setState({
                        activebgColor: e.target.value,
                      })
                    }
                    suffix={
                      <Dropdown trigger={["click"]} overlay={ActiveButtonColor}>
                        <Button
                          style={{
                            background:
                              activebgColor || footer?.menuTabs?.activebgColor,
                          }}
                        >
                          {" "}
                        </Button>
                      </Dropdown>
                    }
                  />
                </Col>

                <Col span={23} style={{ marginBottom: "1vw" }}>
                  <ModalTitle>Text and Icon Color</ModalTitle>
                  <Input
                    value={textColor ? textColor : footer?.menuTabs?.textColor}
                    onChange={(e) =>
                      this.setState({
                        textColor: e.target.value,
                      })
                    }
                    suffix={
                      <Dropdown trigger={["click"]} overlay={TextColor}>
                        <Button
                          style={{
                            background:
                              textColor || footer?.menuTabs?.textColor,
                          }}
                        >
                          {" "}
                        </Button>
                      </Dropdown>
                    }
                  />
                </Col>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  onFinish = async () => {
    const {
      landingScreen,
      Header,
      footer,
      aboutUsPage,
      WhyJoinUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;

    footer["menuTabs"] = {
      textColor: this.state.textColor || footer?.menuTabs?.textColor,
      bgColor: this.state.bgColor || footer?.menuTabs?.bgColor,
      activebgColor:
        this.state.activebgColor || footer?.menuTabs?.activebgColor,
    };

    let data = {
      entityData: {
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        contactUsPage,
        WhyJoinUsPage,
      },
    };
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserProfile && loginUserProfile.orgId) {
      let websiteId = await loginUserProfile.orgId;
      data["entityData"]["isNew"] = false;

      let userData = {
        entityData: {
          isNew: false,
        },
      };

      //FROM CONTEXT STORE UPDATING ORG INFORMATION
      this.props.context.updateWebsite(websiteId, data);
      this.setState({
        footerModal: false,
      });
      update("users", loginUserProfile?.id, userData);
    }
  };

  render() {
    const profileBasedURL = JSON.parse(localStorage.getItem("profileBasedURL"));
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));

    return (
      <div style={{ background: "#f0f2f5", marginBottom: "10vw" }}>
        {window.location.pathname !== "/login" &&
          window.location.pathname !== "/register" && (
            <>
              {" "}
              <Header />
              <div
                style={{
                  marginTop: profileBasedURL?.profileBasedURL && "54px",
                }}
              ></div>
            </>
          )}
        <Switch>
          {AppRoutes.map((route) => (
            <Route
              exact={route.exact}
              path={route.path}
              component={route.component}
              key={route.key}
            />
          ))}
        </Switch>

        {window.location.pathname !== "/login" &&
          window.location.pathname !== "/register" && (
            <div>
              <AdditionalContent />
              <div className="show-on-desktop">
                <Footer />
              </div>
              <div className="show-on-mobile">
                {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                  <EditOutlined
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      float: "right",
                      marginRight: "16px",
                    }}
                    onClick={() => {
                      this.setState({
                        footerModal: true,
                      });
                    }}
                  />
                )}
                <div style={{ minHeight: "90px" }}></div>

                <Navigation />
              </div>
            </div>
          )}

        <Modal
          className="modal-black-close"
          visible={this.state.footerModal}
          title={"Footer"}
          onCancel={() => {
            this.setState({ footerModal: false });
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                this.setState({ footerModal: false });
              }}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              // onClick={() => this.update(true)}
              htmlType="submit"
              form="form"
            >
              Submit
            </Button>,
          ]}
        >
          {this.showData()}
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(Authenticated));
