import React,{ useState } from "react";
import * as XLSX from "xlsx";
import { Upload, Button, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";

function ImportCsvFile({ handleUploadCsvData,selectedAgent }) {
  const [fileList, setfileList] = useState([])
  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    //  console.log(dataString,"kkkk",dataStringLines,dataStringLines.length);
    const list = [];
    let address = {};
    let userDetails = selectedAgent
    // JSON.parse(localStorage.getItem("userDetails"));

    userDetails = {
      firstName:userDetails["firstName"],
      lastName: userDetails["lastName"],
      id:userDetails["id"],
      email:userDetails["email"],
      userProfile: userDetails["userProfile"],
      lastActive: userDetails["lastActive"],
      isActive:userDetails["isActive"],
      loginCount:userDetails["loginCount"],
      orgId:userDetails["orgId"],
      phone:userDetails["phone"],

    }

    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      let validHeaders = [
        "FirstName",
        "LastName",
        "EmailAddress",
        "CellPhone",
        "HomePhone",
        "WorkPhone",
        "Address",
        "City",
        "ZIP",
        "AgentAssigned",
        "ListingAgentAssigned",
        "Registered",
        "Listsidepricepoint",
        "Buysidepricepoint",
        "FavoriteCity",
        "Timeframe",
        "HousetoSell",
        "FirstTimeBuyer",
        "Notes",
        "Property",
        "Buyer/Seller",
        "leadState",
        "sourceLead",
        "typeofProperty",
        "SellerSideCommission",
        "SellerSideReferralFee",
        "BuyerSideCommission",
        "BuyerSideReferralFee",
        "listingStatus",
        "SecondaryEmail",
        "LastActiveDate"
      ];

      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            if (headers[j] === "Source") {
              headers[j] = "sourceLead";
              obj[headers[j]] = d;
            } else if (headers[j] === "State") {
              headers[j] = "leadState";
              obj[headers[j]] = d;
            } else if (
              headers[j] === "Address" ||
              headers[j] === "City" ||
              headers[j] === "State" ||
              headers[j] === "ZIP"
            ) {
              address = {
                ...address,
                ...(headers[j] === "Address" && { citySearch: d }),
                ...(headers[j] === "City" && { city: d }),
                ...(headers[j] === "State" && { addressState: d }),
                ...(headers[j] === "ZIP" && { zipcode: d }),
              };
            } else if (headers[j] === "Buyer/Seller") {
              delete headers[j]
              // headers[j] = "listingStatus";
              // console.log(headers[j],"else if condition",headers);

              // if (d === "Buyer") {
              //   obj[headers[j]] = "Prospective Buyer";
              // } else if (d === "Seller") {
              //   obj[headers[j]] = "Prospective seller or buyer";
              // } else {
              //   obj[headers[j]] = "Prospective seller or buyer";
              // }
            } else if (headers[j] === "Property") {
              headers[j] = "typeofProperty";
              obj[headers[j]] = d;
            } else {
              let formatHeader = headers[j].replaceAll(" ", "");
              if (validHeaders.includes(formatHeader)) {
                formatHeader =
                  formatHeader.charAt(0).toLowerCase() + formatHeader.slice(1);
                if (formatHeader === "favoriteCity") {
                  obj[formatHeader] = d;
                } else {
                  obj[formatHeader] = d;
                }
              } else {
                alert(
                  "Csv format incorrect. Please follow the sample csv file format"
                );
                return;
              }
            }
          }
        }
        obj["homeAddress"] = {};
        obj["closeDate"] = null;
        obj["followDate"] = null;
        obj["referredByClient"] = null;
        obj["businessCard"] = null;
        obj["commission"] = null;
        obj["referralFee"] = null;
        obj["address"] = address;
        obj["buyerSideCommission"] = 2.5;
        obj["buyerSideReferralFee"] = 0;
        obj["sellerSideCommission"] = 2.5;
        obj["sellerSideReferralFee"] = 0;
        obj["assignAgent"] = [userDetails];
        obj["listingStatus"] = "Import";
        obj["privateNotes"] = `Favorite city => ${obj["favoriteCity"]}` 
        obj["orgId"] = userDetails.orgId
        obj["firstName"] = obj["firstName"].charAt(0).toUpperCase() + obj["firstName"].slice(1)
        obj["lastName"] = obj["lastName"].charAt(0).toUpperCase() + obj["lastName"].slice(1)
        obj["emailAddress"] = obj["emailAddress"].toLowerCase()

        obj["lastActiveDate"] = obj["lastActiveDate"] === "" ?  moment(obj["registered"]).unix() : moment(obj["lastActiveDate"]).unix()
        delete obj["Buyer/Seller"]
        delete obj["favoriteCity"]
        
        list.push(obj);
      }
    }
    let userObj = {
      userId: userDetails.id,
      agentDetails: {
        full_name: userDetails?.firstName + " " + userDetails.lastName,
        agent_id: userDetails.id,
        email: userDetails.email,
        orgId: userDetails.orgId,
      },
    }
    handleUploadCsvData(list,userObj);
  };

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.file.originFileObj;    
    if (e.file.status === "done") {
      if(e.fileList.length > 1 ){
        e.fileList = e.fileList.filter(file=>e.file.uid !== file.uid)
        setfileList(e.fileList)
        e.file.status = "removed"
        notification["error"]({
          message:"Please Delete File And Import New File"
        })
        return
      }
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processData(data);
        setfileList(e.fileList)
      };
      reader.readAsBinaryString(file);
    }else if(e.file.status === "removed"){
      setfileList(e.fileList)
      handleUploadCsvData([],{});
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div>
      <Upload
        customRequest={dummyRequest}
        onChange={(e) => handleFileUpload(e)}
        listType="text"
        maxCount={1}
        fileList={fileList}
      >
        <Button icon={<UploadOutlined />} type="primary" 
        >
          Import file
        </Button>
      </Upload>
    </div>
  );
}

export default ImportCsvFile;
