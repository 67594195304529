import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../../store/contextStore";
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Table,
  Select,
  Collapse,
  Typography,
  notification,
} from "antd";
import {
  EditTwoTone,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import NumberFormat from "react-number-format";
import { FaFileCsv } from "react-icons/fa";
import { Storage } from "aws-amplify";
import { add, getDetails, list, noAuthlist } from "../../../api/Apis";
import moment from "moment";
import Loading from "../../../common/Loading.js";
import EmailPopupCom from "../EmailPopupCom";
import ImportCsvFile from "./ImportCsvFile";
import AddContact from "./AddContact";

// const Search = Input.Search;
const { Paragraph } = Typography;
const { Panel } = Collapse;

function AllContacts(props) {
  const [proConatct, setProConatct] = useState("");
  const [allcontacts, setAllcontacts] = useState({
    totalContacts: [],
    // filterContacts: [],
    paginationCount: 0,
    lastEvaluatedKey: "",
    lastActivePerformDate: "",
  });
  const [pages, setPages] = useState({
    minPage: 0,
    maxPage: 20,
    currentPage: 1,
    disableNextButton: false,
    finalCurrentPage: 0,
  });

  const [searchContacts, getSearchContacts] = useState({
    textSearch: "",
    statusSearch: "all",
    searchLeadSource: "all",
    isFilterSearch: false,
    queryFilter: "",
  });

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedContactKeys, setSelectedContactKeys] = useState([]);
  const [messagePopup, setMessagePopup] = useState({
    showSendMessage: false,
    selectedContacts: [],
    isAllSelected: false,
    deSelectedKeys: [],
    isSingleSelect: true,
  });

  const [exitsRecordsModal, setExitsRecordsModal] = useState({
    openModal: false,
    exitsRecords: [],
  });
  const [assignAgentTrue, setAssignAgent] = useState({
    activeAgents: [],
    assignAgentOpen: false,
    selectedAgent: {},
    importedListDetails: [],
    assignUserDetails: {},
  });

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const orgId = JSON.parse(localStorage.getItem("orgId"));

  const fetchBusinesses = useCallback(() => {
    let userId = userDetails.id;
    let query = {};
    let totalUserId = userDetails.id;

    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
      totalUserId = orgId;
    } else {
      query["userId"] = userId;
    }
    query = {
      ...query,
      contactType: "Contact",
      contactsLimit: 20,
    };

    getDetails(
      `virtualContactsTotal/${userDetails?.userProfile}`,
      totalUserId
    ).then((contactRes) => {
      if (contactRes.success) {
        setAllcontacts((a) => ({
          ...a,
          paginationCount: contactRes.data.totalRecordsCount,
        }));
      }
    });
    setLoading(true);
    // list("getVirtualContactLeads", query)
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          let totalContacts = allcontacts.totalContacts;
          // let filterContacts = allcontacts.filterContacts;
          totalContacts = totalContacts.concat(result.data.contactList);
          // filterContacts = filterContacts.concat(result.data.contactList);
          // totalContacts = totalContacts.sort((a, b) =>
          //   a.lastActiveDate < b.lastActiveDate ? 1 : -1
          // );
          setAllcontacts((a) => ({
            ...a,
            totalContacts,
            // filterContacts,
            lastEvaluatedKey: result.data.lastEvaluatedKey,
            lastActivePerformDate: result.data.lastActivePerformDate,
          }));

          // if (result.data.lastEvaluatedKey === "none") {
          //   setPages({
          //     ...pages,
          //     disableNextButton: true,
          //   });
          // }

          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    noAuthlist("getAgents", { orgId, userProfile: "AG", isActive: true }).then(
      (agentRes) => {
        if (agentRes.success) {
          setAssignAgent({
            ...assignAgentTrue,
            activeAgents: agentRes.data,
          });
        }
      }
    );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);

  const getUserProfile = (resetStateCon) => {
    let id = userDetails && userDetails?.id;
    let query = {};
    let totalUserId = id;
    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
      query["totalUserId"] = orgId;
    } else {
      query["userId"] = id;
    }
    query = {
      ...query,
      contactType: "Contact",
      contactsLimit: 20,
    };
    // if(query["userId"] === "c4ebcf80-0e00-4cd3-bb0b-c9bf1703b959" ){
    //   query = {
    //     ...query,
    //     contactType: "Contact",
    //     contactsLimit: 500,
    //     totalRecordCount: 150,
    //     getTotalRecords: false,
    //   };
    // }
    setLoading(true);
    getDetails(
      `virtualContactsTotal/${userDetails?.userProfile}`,
      totalUserId
    ).then((contactRes) => {
      if (contactRes.success) {
        setAllcontacts((a) => ({
          ...a,
          paginationCount: contactRes.data.totalRecordsCount,
        }));
        setAssignAgent({
          ...assignAgentTrue,
          assignAgentOpen: false,
          selectedAgent: {},
          importedListDetails: [],
          assignUserDetails: {},
        });
      }
    });
    getResultByPage(query, resetStateCon);
  };

  const getResultByPage = (query, isSearch) => {
    // list("getVirtualContactLeads", query)
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          let totalContacts = [];
          if (!isSearch) {
            totalContacts = allcontacts.totalContacts;
          }
          totalContacts = totalContacts.concat(result.data.contactList);
          setAllcontacts({
            ...allcontacts,
            totalContacts,
            lastEvaluatedKey: result.data.lastEvaluatedKey,
            lastActivePerformDate: result.data.lastActivePerformDate,
          });

          console.log(selectedContactKeys,messagePopup.isAllSelected);

          //SELECT ALL CONTACTS SCENARIO
          if (selectedContactKeys.length > 0 && messagePopup.isAllSelected) {
            handleSelectBulkContacts(totalContacts);
          }

          //SINGLE SELECT SCENARIO TO AND FROM NEXT PAGE
          if (selectedContactKeys.length > 0 && messagePopup.isSingleSelect) {
            handleForSingleSelect(totalContacts);
          }

          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClickRow = (record) => {
    props.history.push(
      `/VirtualOffice/ViewAllContacts/${record.id}`,
      "contacts"
    );
  };

  const webColumns = [
    {
      title: "NAME",
      dataIndex: "firstName",
      key: "firstName",
      sorter: false,
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (firstName, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {`${firstName} ${record.lastName}`}
          </span>
        );
      },
    },
    {
      title: "PHONE #",
      dataIndex: "cellPhone",
      key: "cellPhone",
      sorter: false,
      render: (cellPhone) => {
        return (
          <span>
            <NumberFormat
              className="ant-input-number"
              placeholder="Phone"
              value={cellPhone}
              displayType="text"
              format="(###) ###-####"
              style={{ width: "100%", border: "none" }}
            />
          </span>
        );
      },
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
    },
    {
      title: "EMAIL",
      dataIndex: "emailAddress",
      key: "emailAddress",
      sorter: false,
      render: (emailAddress) => {
        return <span>{emailAddress}</span>;
      },
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
    },
    {
      title: "STATUS",
      dataIndex: "listingStatus",
      key: "listingStatus",
      sorter: false,
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (listingStatus) => {
        return (
          <span
          // style={{
          //   textTransform: "capitalize",
          // }}
          >
            {listingStatus === "Prospective seller or buyer"
              ? "Prospective Seller and Buyer"
              : listingStatus}
          </span>
        );
      },
    },
    {
      title: "TYPE",
      dataIndex: "typeofProperty",
      key: "typeofProperty",
      sorter: false,
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (typeofProperty) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {typeofProperty}
          </span>
        );
      },
    },
    {
      title: "LEAD SOURCE",
      dataIndex: "sourceLead",
      key: "sourceLead",
      sorter: false,
      onCell: (record) => ({
        onClick: () => this.handleClickRow(record),
      }),
      render: (sourceLead) => {
        return <span>{sourceLead}</span>;
      },
    },
    {
      title: "LAST ACTIVE",
      dataIndex: "lastActiveDate",
      key: "lastActiveDate",
      sorter: false,
      // (a, b) => new Date(a.lastActiveDate) - new Date(b.lastActiveDate),
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (lastActiveDate) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              textAlign: "center",
            }}
          >
            {lastActiveDate
              ? moment.unix(lastActiveDate).format("MM/DD/YYYY hh:mm A")
              : ""}
          </span>
        );
      },
    },
    {
      title: "DATE",
      dataIndex: "registered",
      key: "registered",
      sorter: false,
      // (a, b) => new Date(a.registered) - new Date(b.registered),
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (registered) => {
        return (
          <span
          // style={{
          //   textTransform: "capitalize",
          // }}
          >
            {registered ? moment(registered).format("L") : ""}
          </span>
        );
      },
    },

    {
      title: "EDIT",
      dataIndex: "id",
      key: "id",
      sorter: false,
      render: (id, record) => {
        return (
          <span onClick={() => showData(id, record)}>
            <EditTwoTone style={{ fontSize: "20px" }} />
          </span>
        );
      },
    },
  ];

  const mobColumns = [
    {
      title: "Add Clients/Prospective Business",
      dataIndex: "productOriginDetails",
      key: "productOriginDetails",
      render: (productOriginDetails, record) => (
        <Collapse collapsible="true">
          <Panel
            header={record.firstName}
            key="1"
            extra={
              <ArrowRightOutlined
                onClick={() => {
                  window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
                }}
              />
            }
          >
            <Row
              className="row-margin-table"
              onClick={() => {
                window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
              }}
            >
              <Col className="col-tilte">Name</Col>
              <Col className="col-desc">{record.firstName}</Col>
            </Row>
            <Row
              className="row-margin-table"
              onClick={() => {
                window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
              }}
            >
              <Col className="col-tilte">Phone #</Col>
              <Col className="col-desc">{record.cellPhone}</Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => {
                window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
              }}
            >
              <Col className="col-tilte">Email</Col>
              <Col className="col-desc" style={{ marginLeft: "0px" }}>
                {record.emailAddress}
              </Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => {
                window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
              }}
            >
              <Col className="col-tilte">Status</Col>
              <Col className="col-desc">{record.listingStatus}</Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => {
                window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
              }}
            >
              <Col className="col-tilte">Rating</Col>
              <Col className="col-desc">
                {record.timeframe && record.timeframe}
              </Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => {
                window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
              }}
            >
              <Col className="col-tilte">Type</Col>
              <Col className="col-desc">{record.typeofProperty}</Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => {
                window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
              }}
            >
              <Col className="col-tilte">Notes</Col>
              <Col className="col-desc" style={{ marginLeft: "0px" }}>
                {record.notes}
              </Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => {
                window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
              }}
            >
              <Col className="col-tilte">Date</Col>
              <Col className="col-desc">{record.registered}</Col>
            </Row>
          </Panel>
        </Collapse>
      ),
    },
  ];

  const showData = (id, record) => {
    setLoading(true);
    try {
      getDetails("/virtualContactLeads", id).then((res) => {
        if (res.success) {
          setLoading(false);
          setProConatct(res.data);
          setEditModal(true);
          setShowModal(true);
        }
      });
    } catch (error) {
      console.log(error, "r=errr");
    }
  };

  const status = [
    { label: "All", value: "all" },
    { label: "Closed", value: "Closed" },
    { label: "Under Agreement", value: "Under Agreement" },
    { label: "Active Listing", value: "Active Listing" },
    { label: "Active Buyer", value: "Active Buyer" },
    { label: "Prospective Buyer", value: "Prospective Buyer" },
    {
      label: "Prospective Seller",
      value: "Prospective Seller",
    },
    {
      label: "Prospective Seller and Buyer",
      value: "Prospective seller or buyer",
    },
    {
      label: "Past Client",
      value: "Past Client",
    },
    {
      label: "CRM entry",
      value: "CRM entry",
    },
    {
      label: "Import",
      value: "Import",
    },
  ];
  const sourceLead = [
    { label: "All", value: "all" },
    { label: "Sphere referral", value: "Sphere referral" },
    { label: "Family or Friend", value: "Family and Friend" },
    { label: "Return Client", value: "Return Client" },
    { label: "Google Advertising", value: "Google Advertising" },
    { label: "Facebook Organic", value: "Facebook Organic" },
    { label: "Facebook Paid Ad", value: "Facebook Paid Ad" },
    { label: "Realtor.com", value: "Realtor.com" },
    { label: "Zillow ", value: "Zillow" },
    { label: "Opcity", value: "Opcity" },
    {
      label: "Office lead",
      value: "Office lead",
    },
    // {
    //   label: "Office lead- Realtor.com and Zillow",
    //   value: "Office lead- Realtor.com and Zillow",
    // },
    // {
    //   label: "Office lead- Seller or Home Value Lead",
    //   value: "Office lead- Seller or Home Value Lead",
    // },
    { label: "Sign call", value: "Sign call" },
    { label: "Open house", value: "Open house" },
    { label: "Farm mailing call", value: "Farm mailing call" },
    { label: "Meet on the street", value: "Meet on the street" },
    { label: "2nd Level referral", value: "2nd Level referral" },
    { label: "3rd level referral ", value: "3rd level referral" },
    {
      label: "Referral from FB paid lead ",
      value: "Referral from FB paid lead ",
    },
    {
      label: "Referral from Zillow paid lead ",
      value: "Referral from Zillow paid lead ",
    },
    {
      label: "Referral from Realtor.com paid lead ",
      value: "Referral from Realtor.com paid lead",
    },
    { label: "Agent to agent referral", value: "Agent to agent referral" },
    { label: "FSBO", value: "FSBO" },
    { label: "Expired", value: "Expired" },
    { label: "Upnest referral", value: "Upnest referral" },
    { label: "Other (see notes)", value: "Other (see notes)" },
  ];
  const handleSearchText = (event, searchType) => {
    // console.log (event,"event");
    if (searchType === "text") {
      getSearchContacts({
        ...searchContacts,
        textSearch: event,
      });
    } else if (searchType === "status") {
      getSearchContacts({
        ...searchContacts,
        statusSearch: event,
      });
    } else if (searchType === "leadSource") {
      getSearchContacts({
        ...searchContacts,
        searchLeadSource: event,
      });
    }
  };

  const restSearchResults = () => {
    setLoading(true);

    getSearchContacts({
      ...searchContacts,
      statusSearch: "all",
      textSearch: "",
      searchLeadSource: "all",
      isFilterSearch: false,
      queryFilter: {},
    });
    setPages({
      ...pages,
      currentPage: 1,
      minPage: 0,
      maxPage: 20,
      disableNextButton: true,
      finalCurrentPage: 0,
    });
    let id = userDetails && userDetails?.id;
    let query = {};
    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
    } else {
      query["userId"] = id;
    }
    query["contactType"] = "Contact";
    query = {
      ...query,
      contactsLimit: 20,
    };
    getResultByPage(query, true);
  };

  const searchQueryResults = () => {
    getSearchContacts({
      ...searchContacts,
      isFilterSearch: !searchContacts.isFilterSearch,
    });
    setPages({
      ...pages,
      currentPage: 1,
      minPage: 0,
      maxPage: 20,
      disableNextButton: true,
    });
    setAllcontacts({
      ...allcontacts,
      totalContacts: [],
      // filterContacts: [],
    });
    setLoading(true);
    let id = userDetails && userDetails?.id;
    let query = {};
    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
    } else {
      query["userId"] = id;
    }
    query["contactType"] = "Contact";
    query = {
      ...query,
      filterSearch: true,
    };

    if (
      searchContacts.statusSearch !== "" &&
      searchContacts.statusSearch !== "all"
    ) {
      query = {
        ...query,
        statusQuery: searchContacts.statusSearch,
      };
    }

    if (searchContacts.textSearch !== "") {
      if (searchContacts.textSearch.split(" ").length === 2) {
        query = {
          ...query,
          lastNameQuery: searchContacts.textSearch.split(" ")[1],
          textSearch: searchContacts.textSearch.split(" ")[0],
        };
      } else {
        query = {
          ...query,
          textSearch: searchContacts.textSearch,
        };
      }
    }
    if (
      searchContacts.searchLeadSource !== "" &&
      searchContacts.searchLeadSource !== "all"
    ) {
      query = {
        ...query,
        leadSourceQuery: searchContacts.searchLeadSource,
      };
    }
    getSearchContacts({
      ...searchContacts,
      queryFilter: query,
    });

    getResultByPage(query, true);
  };

  const handleUploadCsvData = (tableList, userObj) => {
    // let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let orgnizationId = JSON.parse(localStorage.getItem("orgId"));
    setLoading(true);

    let payLoad = {
      entityData: {
        tableList,
        agentDetails: userObj.agentDetails,
        userId: userObj.userId,
        orgId: orgnizationId,
      },
    };
    add("saveCsvFileData", payLoad)
      .then(
        (res) => {
          if (res.success) {
            getUserProfile(true);
            if (res.data?.exitsRecords?.length > 0) {
              setExitsRecordsModal({
                ...exitsRecordsModal,
                openModal: true,
                exitsRecords: res.data.exitsRecords,
              });
            }
          }
        }
        // fetchBusinesses()
      )
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const showExitsRecords = () => {
    return (
      <>
        <Modal
          className="modal-black-close"
          title="Aleardy exists records."
          visible={exitsRecordsModal.openModal}
          onCancel={() =>
            setExitsRecordsModal({ ...exitsRecordsModal, openModal: false })
          }
          footer={null}
          width={
            window.screen.width >= "768"
              ? "30%"
              : window.screen.width > "501" && window.screen.width <= "767"
              ? "50%"
              : "90%"
          }
        >
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              {" "}
              Email Address:
            </span>
            {exitsRecordsModal.exitsRecords?.map((eachRecord) => (
              <>
                <div>
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      <Paragraph
                        copyable
                        style={{ fontWeight: "600", fontSize: "16px" }}
                      >
                        {eachRecord}
                      </Paragraph>
                    </li>
                  </ul>
                </div>
              </>
            ))}
          </div>
        </Modal>
      </>
    );
  };

  const handleSelectBulkContacts = (selectedCont) => {
    let allCont = [];
    if (
      selectedContactKeys.length > 0 &&
      messagePopup.deSelectedKeys.length > 0
    ) {
      allCont = selectedCont.filter(
        (i) =>
          i.emailAddress &&
          messagePopup.deSelectedKeys.filter((ke) => ke === i.id).length === 0
      );
    } else {
      allCont = selectedCont.filter((i) => i.emailAddress);
    }
    setMessagePopup({
      ...messagePopup,
      selectedContacts: allCont,
      isAllSelected: true,
      isSingleSelect: false
    });
    allCont = allCont.map((cId) => cId.id);
    setSelectedContactKeys(allCont);
  };

  const handleForSingleSelect = (selectedCont) => {
    let allCont = [];
    console.log(messagePopup,"messagePopup",selectedContactKeys.length);
    if (
      selectedContactKeys.length > 0 
    ) {
      console.log(selectedContactKeys,"kkkk");
      allCont = selectedCont.filter(
        (i) =>
          i.emailAddress &&
          selectedContactKeys.filter((ke) => ke === i.id).length > 0
      );
    } else {
      allCont = selectedCont.filter((i) => i.emailAddress);
    }
    allCont = allCont.map((cId) => cId.id);
    setSelectedContactKeys(allCont);
    setMessagePopup({
      ...messagePopup,
      isSingleSelect: true
    })

  }
  console.log(selectedContactKeys,"+++++++++++++");

  const rowSelection = {
    selectedRowKeys: selectedContactKeys,
    selections: [
      {
        key: "SELECTION_ALL",
        text: "Select All Contacts",
        Table: "SELECTION_ALL",
        onSelect: () => handleSelectBulkContacts(allcontacts.totalContacts),
      },
      {
        key: "CLEAR_ALL",
        text: "Clear All Contacts",
        Table: "CLEAR_ALL",
        onSelect: () => {
          setMessagePopup({
            ...messagePopup,
            selectedContacts: [],
            isAllSelected: false,
            deSelectedKeys: [],
            isSingleSelect: false
          });
          setSelectedContactKeys([]);
        },
      },
    ],
    onChange: (selectedRowKeys, selectedRows, info) => {
      console.log(info,"empty.");
      // && selectedContactKeys.length < selectedRowKeys.length
      if (!messagePopup.isAllSelected && pages.currentPage === 1 ) {
        console.log("firstPage",selectedContactKeys);
        setSelectedContactKeys(selectedRowKeys);
        setMessagePopup({
          ...messagePopup,
          selectedContacts: selectedRows,
          isSingleSelect: true
        });

      }
      if (!messagePopup.isAllSelected && messagePopup.isSingleSelect && pages.currentPage > 1) {
        console.log("second etxcc...",selectedContactKeys);

        selectedRowKeys = selectedRowKeys.filter(newKey=> selectedContactKeys.includes(newKey) === false)
        selectedRows = selectedRows.filter(newKeyCont=> selectedContactKeys.includes(newKeyCont.id) === false)

        selectedRowKeys = [...selectedRowKeys,...selectedContactKeys]
        selectedRows = [...selectedRows,...messagePopup.selectedContacts]
        // selectedRowKeys.concat(selectedContactKeys)

        console.log(selectedContactKeys,messagePopup.selectedContacts,"next page",selectedRowKeys,selectedRows);
        setSelectedContactKeys(selectedRowKeys);
        setMessagePopup({
          ...messagePopup,
          selectedContacts: selectedRows,
          isSingleSelect: true
        });




      }
    },

    getCheckboxProps: (record) => ({
      disabled: record?.emailAddress === "" || record?.emailAddress === null, // Column configuration not to be checked
      // emailAddress: record?.emailAddress,
    }),

    onSelect: (record, selected, selectedRows) => {
      
      if (!selected && messagePopup.isAllSelected) {
        let deSelectedKeys = messagePopup.deSelectedKeys;
        let newKeys = selectedContactKeys.filter((ke) => ke !== record.id);
        let newCont = messagePopup.selectedContacts.filter(
          (co) => co.id !== record.id
        );
        setSelectedContactKeys(newKeys);
        deSelectedKeys.push(record.id);
        setMessagePopup({
          ...messagePopup,
          showSendMessage: selected,
          selectedContacts: newCont,
          deSelectedKeys,
        });
      }else if (!selected && messagePopup.isSingleSelect) {
        console.log(messagePopup.isSingleSelect,selected,"deselect");
        let deSelectedKeys = messagePopup.deSelectedKeys;
        let newKeys = selectedContactKeys.filter((ke) => ke !== record.id);
        let newCont = messagePopup.selectedContacts.filter(
          (co) => co.id !== record.id
        );
        console.log(newKeys,"deselect",newCont);

        setSelectedContactKeys(newKeys);
        deSelectedKeys.push(record.id);
        setMessagePopup({
          ...messagePopup,
          showSendMessage: selected,
          selectedContacts: newCont,
          deSelectedKeys,
        });
      }else {
        // console.log(record, "record", selectedRows);
        // let selectedKeys = selectedContactKeys;
        // selectedKeys.push(record.id);
        // console.log(selectedKeys,"__________________");
        // setSelectedContactKeys(selectedKeys);
        setMessagePopup({
          ...messagePopup,
          showSendMessage: selected,
          selectedContacts: selectedRows,
        });
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setMessagePopup({
        ...messagePopup,
        showSendMessage: selected,
        selectedContacts: selectedRows,
      });
    },
  };

  const handleToRestContactSelection = () => {
    setSelectedContactKeys([]);
    setMessagePopup({
      ...messagePopup,
      showSendMessage: false,
      selectedContacts: [],
    });
  };

  const fileDownload = async (fileName) => {
    Storage.get(fileName, { download: true }).then((res) =>
      downloadBlob(res.Body, fileName)
    );
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };

  const handleCancel = () => {
    setShowModal(false);
    setProConatct("");
    setEditModal(false);
    setLoading(false);
    setAllcontacts({
      totalContacts: [],
      //  filterContacts: []
    });
    getUserProfile(true);
  };
  const handleToAssignAgent = () => {
    setAssignAgent({
      ...assignAgentTrue,
      assignAgentOpen: true,
    });
  };

  const handleToNextPage = (page, actionType) => {
    // console.log(searchContacts.queryFilter, "next page");
    if (Object.keys(searchContacts.queryFilter).length > 0) {
      getSearchContacts({
        ...searchContacts,
        isFilterSearch: true,
      });
    }
    // return
    if (
      allcontacts.lastEvaluatedKey === "none" &&
      pages.finalCurrentPage === 0
    ) {
      let finalPage = allcontacts.totalContacts.length / 20;
      setPages({
        ...pages,
        finalCurrentPage: finalPage,
      });
      return;
    }
    if (
      allcontacts.lastEvaluatedKey !== "none" &&
      allcontacts.lastEvaluatedKey !== "search"
    ) {
      setLoading(true);
      let id = userDetails && userDetails?.id;
      let query =
        searchContacts.queryFilter !== "" ? searchContacts.queryFilter : {};
      if (userDetails?.userProfile === "A") {
        query["orgId"] = orgId;
      } else {
        query["userId"] = id;
      }
      query = {
        ...query,
        contactType: "Contact",
        contactsLimit: 20,
        lastEvaluatedKey: allcontacts.lastEvaluatedKey,
        lastActivePerformDate: allcontacts.lastActivePerformDate,
      };
      getResultByPage(query, false);
    }
    setPages({
      ...pages,
      minPage: (page - 1) * 20,
      maxPage: page * 20,
      currentPage: page,
    });
  };

  const handleToPreviousPage = (page) => {
    setPages({
      ...pages,
      minPage: (page - 1) * 20,
      maxPage: page * 20,
      currentPage: page,
      finalCurrentPage: page,
    });
  };
  const tableContent = () => {
    // console.log(searchContacts,"pages",parseInt(allcontacts.totalContacts.length / 20) < pages.currentPage);
    return (
      <Row style={{ width: "100%" }}>
        <Col
          style={{
            display: "flex",
            marginLeft: "auto",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontWeight: "600",
              display: "flex",
              marginRight: "5px",
            }}
          >
            {allcontacts.totalContacts.length >= 20 && (
              <>
                {" "}
                {pages.minPage + 1} {" - "} {pages.maxPage} {" of "}{" "}
              </>
            )}
            {searchContacts.queryFilter?.filterSearch
              ? allcontacts.totalContacts.length
              : allcontacts.paginationCount}{" "}
            {"Contacts"}{" "}
            {searchContacts.queryFilter?.filterSearch
              ? parseInt(allcontacts.totalContacts.length / 20) <
                pages.currentPage
                ? ""
                : "And Load More"
              : ""}
          </span>{" "}
          {allcontacts.totalContacts.length >= 20 && (
            <>
              <Button
                onClick={() =>
                  handleToPreviousPage(pages.currentPage - 1, "previous")
                }
                disabled={pages.currentPage !== 1 ? false : true}
                className="todo_button"
                style={{
                  border: "1px solid #3045d2",
                }}
              >
                <ArrowLeftOutlined />
                <span className="virtual_button_text btn-color">Previous</span>
              </Button>
              <Button
                onClick={() => handleToNextPage(pages.currentPage + 1, "next")}
                disabled={
                  // pages.currentPage === pages.finalCurrentPage &&
                  // pages.finalCurrentPage ===
                  //   allcontacts.totalContacts.length / 20
                  parseInt(allcontacts.totalContacts.length / 20) <
                  pages.currentPage
                    ? true
                    : false
                }
                className="todo_button"
                style={{
                  border: "1px solid #3045d2",
                  marginLeft: "5px",
                }}
              >
                <span className="virtual_button_text btn-color">Next</span>
                <ArrowRightOutlined />
              </Button>
            </>
          )}
        </Col>
      </Row>
    );
  };
  const handleToSelectAgent = (id, options) => {
    setAssignAgent({
      ...assignAgentTrue,
      selectedAgent: assignAgentTrue.activeAgents.filter(
        (ag) => ag.id === id
      )[0],
    });
  };

  const openImportFile = () => {
    if (assignAgentTrue?.importedListDetails.length > 0) {
      handleUploadCsvData(
        assignAgentTrue.importedListDetails,
        assignAgentTrue.assignUserDetails
      );
    } else {
      notification["error"]({
        message: "Please Import file",
      });
    }
  };

  const handleToGetImportedList = (listOfData, userObj) => {
    setAssignAgent({
      ...assignAgentTrue,
      importedListDetails: listOfData,
      assignUserDetails: userObj,
    });
  };
  return (
    <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
      {exitsRecordsModal.openModal && showExitsRecords()}
      <Loading enableLoading={loading} />
      <Row gutter={window.screen.width > 996 && [24, 24]}>
        <Col
          xxl={{ span: 4, offset: 0 }}
          xl={{ span: 8, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          xs={{ span: 20, offset: 1 }}
          sm={{ span: 20, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <span className="contacts-search">Search By Name, Email</span>
          <Input
            className="search"
            size="large"
            placeholder="Search..."
            onChange={(event) => handleSearchText(event.target.value, "text")}
            value={searchContacts.textSearch}
          />
        </Col>
        <Col
          xxl={{ span: 4, offset: 0 }}
          xl={{ span: 6, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          xs={{ span: 10, offset: 1 }}
          sm={{ span: 10, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <div className="loads-select">
            <span className="contacts-search"> Status</span>
            <Select
              onChange={(event) => handleSearchText(event, "status")}
              options={status.label}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ borderRadius: "5px", width: "100%", cursor: "pointer" }}
              notFoundContent={"Not found?!"}
              placeholder="Status"
              // defaultValue={searchContacts.statusSearch}
              value={searchContacts.statusSearch}
            >
              {status.map((province) => (
                <Select.Option key={province.value} value={province.value}>
                  {province.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col
          xxl={{ span: 4, offset: 0 }}
          xl={{ span: 6, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          xs={{ span: 10, offset: 1 }}
          sm={{ span: 10, offset: 1 }}
          className="mobile_view_margin_Top"
          style={{ padding: "12px" }}
        >
          <div className="loads-select">
            <span className="contacts-search"> Lead Source</span>

            <Select
              onChange={(event) => handleSearchText(event, "leadSource")}
              options={sourceLead.label}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ borderRadius: "5px", width: "100%", cursor: "pointer" }}
              notFoundContent={"Not found?!"}
              placeholder="Lead Source"
              value={searchContacts.searchLeadSource}
            >
              {sourceLead.map((province) => (
                <Select.Option key={province.value} value={province.value}>
                  {province.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col
          xxl={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
        >
          <Button
            type="primary"
            size="default"
            style={{ height: "35px", marginTop: "25px" }}
            onClick={searchQueryResults}
          >
            <span
              style={{ color: "#FFF", fontSize: "15px", fontWeight: "500" }}
            >
              Apply
            </span>
          </Button>
          <Button
            // type="primary"
            size="default"
            style={{
              height: "35px",
              marginTop: "25px",
              marginLeft: "15px",
              border: "1px solid #fc572b",
            }}
            onClick={restSearchResults}
          >
            <span
              style={{ color: "#fc572b", fontSize: "15px", fontWeight: "500" }}
            >
              Reset
            </span>
          </Button>
        </Col>
        {/* <Col
          xxl={{ span: 2, offset: 0 }}
          xl={{ span: 2, offset: 0 }}
          lg={{ span: 3, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
        >
          
        </Col> */}
        <Col
          xxl={{ span: 2, offset: 0 }}
          xl={{ span: 4, offset: 10 }}
          lg={{ span: 4, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
          style={{ marginBottom: "0vw", paddingTop: "15px", marginTop: "25px" }}
        >
          {userDetails.userProfile === "A" && (
            <Button
              icon={<UploadOutlined />}
              type="primary"
              onClick={handleToAssignAgent}
            >
              Import file
            </Button>
          )}

          {assignAgentTrue.assignAgentOpen ? (
            <div>
              <Modal
                className="modal-black-close"
                visible={assignAgentTrue.assignAgentOpen}
                title="Select Agent To Assign Contacts"
                onCancel={() => setAssignAgent(false)}
                width={"30%"}
                onOk={openImportFile}
                okText={"Submit"}
              >
                <Row>
                  <Col span={24} style={{ marginTop: "20px" }}>
                    <Select
                      // className="input-width"
                      showSearch
                      placeholder="Select Agent"
                      optionFilterProp="children"
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onChange={handleToSelectAgent}
                    >
                      {assignAgentTrue?.activeAgents?.length > 0 &&
                        assignAgentTrue.activeAgents.map(
                          (province) =>
                            province.firstName !== undefined && (
                              <Select.Option
                                key={province.id}
                                value={province.id}
                                // label={province.firstName}
                              >
                                {" "}
                                {`${
                                  province.firstName + " " + province.lastName
                                }`}{" "}
                              </Select.Option>
                            )
                        )}
                    </Select>
                  </Col>
                  <Col style={{ marginTop: "20px" }}>
                    <ImportCsvFile
                      handleUploadCsvData={handleToGetImportedList}
                      selectedAgent={assignAgentTrue.selectedAgent}
                    />
                  </Col>
                </Row>
              </Modal>
            </div>
          ) : (
            ""
          )}

          {userDetails.userProfile === "AG" && (
            <ImportCsvFile
              handleUploadCsvData={handleUploadCsvData}
              handleToAssignAgent={handleToAssignAgent}
              selectedAgent={userDetails}
            />
          )}
        </Col>
        <Col
          xxl={{ span: 3, offset: 0 }}
          xl={{ span: 6, offset: 0 }}
          lg={{ span: 7, offset: 1 }}
          xs={{ span: 10, offset: 2 }}
          sm={{ span: 10, offset: 2 }}
        >
          <span
            style={{ cursor: "pointer" }}
            onClick={() => fileDownload("sampleCsvfile.xlsx")}
          >
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <span
                style={{
                  color: "#7B8190",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Download and edit &nbsp;{" "}
              </span>
              {/* <img
                src={csvFile}
                alt="Doc"
                title="Download"
                style={{ width: "25px", height: "30px" }}
              /> */}
              <span>
                <FaFileCsv
                  style={{ width: "25px", height: "30px", color: "#3045d2" }}
                />
              </span>
            </div>
          </span>
        </Col>
        <Col
          xxl={{ span: 3, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
          xs={{ span: 10, offset: 2 }}
          sm={{ span: 10, offset: 2 }}
          className="mobile_view_margin_Top"
        >
          <span className="contacts-search"></span>

          <Button
            type="primary"
            size="default"
            style={{ width: "100%", height: "40px", marginTop: "25px" }}
            onClick={() => {
              setShowModal(true);
              setProConatct("");
            }}
          >
            <h1 style={{ color: "#fff", marginTop: "5px" }}>
              <i className="fa fa-plus-circle"></i> Contacts{" "}
            </h1>
          </Button>
        </Col>

        {/* TABLE OF CONTACTS */}

        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
          style={{ marginBottom: "1vw" }}
        >
          <Card
            title={
              window.screen.width > 996
                ? "Add Clients/Prospective Business"
                : ""
            }
            className="card_virtual"
            hoverable={true}
            bodyStyle={{ padding: "0px" }}
            style={{ overflow: "auto" }}
            extra={
              // messagePopup?.selectedContacts?.length > 0 && (
              <EmailPopupCom
                selectedContacts={messagePopup?.selectedContacts}
                handleToRestContactSelection={handleToRestContactSelection}
              />
              // )
            }
          >
            <Table
              className="table_conatcts"
              columns={window.screen.width > 996 ? webColumns : mobColumns}
              dataSource={
                allcontacts?.totalContacts.slice(
                  pages.minPage,
                  pages.maxPage
                ) || ""
              }
              // dataSource={
              //   allcontacts?.totalContacts
              // }
              loading={loading}
              rowKey={(record) => record.id}
              pagination={false}
              // pagination={{
              //   defaultPageSize: 20,
              //   // showSizeChanger: true,
              //   pageSizeOptions: ["20","50", "100"],
              //   total: allcontacts?.totalContacts,
              //   showTotal: (total, range) =>
              //     `${range[0]}-${range[1]} of ${total} items`,
              //   onChange: handleToNextPage,
              //   // onShowSizeChange: handleToSizeChange,
              //   // itemRender: itemRender

              // }}
              onScroll={true}
              rowSelection={window.screen.width > 996 && rowSelection}
              locale={{
                triggerDesc: "Sort By New Date",
                triggerAsc: "Sort By Old Date",
                cancelSort: "Reset",
              }}
              footer={tableContent}
            />
          </Card>
        </Col>

        {/* {messagePopup?.selectedContacts?.length > 0 && (
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 22 }}
            xs={{ span: 22 }}
            style={{ background: "grey", padding: "12px" }}
          >
            <EmailPopupCom
              selectedContacts={messagePopup?.selectedContacts}
              handleToRestContactSelection={handleToRestContactSelection}
            />
          </Col>
        )} */}
      </Row>
      {showModal && (
        <AddContact
          showModal={showModal}
          proConatct={proConatct}
          loadig={loading}
          onCancel={handleCancel}
          editModal={editModal}
          getUserProfile={getUserProfile}
          setLoading={setLoading}
        />
      )}
    </div>
  );
}
export default withAppContext(withRouter(AllContacts));
