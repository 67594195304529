import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import { list } from "../../../api/Apis";
import {
  Col,
  Row,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Modal,
  Table,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Advertisement from "../../../common/Advertisement";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import AddContact from "../../../components/VirtualOffice/contactLeads/AddContact";
import { ModalData } from "../../../StyledComponents/StyledComponents";
import NumberFormat from "react-number-format";
import Loading from "../../../common/Loading";
class PrivateDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // privateDetails: {
      sellerName: "",
      sellerPhone: "",
      sellerAddress: "",
      citySearch: [],
      city: "",
      addressState: "",
      zipCode: "",
      geoIdData: {},
      // }
      Addressempty: true,
      makePrivateAddress: false,
      adverCount: 0,
      totalContactList: [],
      visibleDetails: {},
      selectedContact: true,
      showModal: false,
      selectedBuyerContact: "",
      newContact: false,
      filterContacts: [],
      showContactModal: false,
    };
  }

  privateFormRef = React.createRef();

  componentDidMount() {
    this.setState({
      adverCount: 1,
    });
    const { sellerHomeDetails } = this.props;
    const address =
      sellerHomeDetails.privateDetails &&
      sellerHomeDetails.privateDetails.address &&
      sellerHomeDetails.privateDetails.address;

    this.setState({
      citySearch: address,
      city:
        sellerHomeDetails.privateDetails &&
        sellerHomeDetails.privateDetails.city,
      addressState:
        sellerHomeDetails.privateDetails &&
        sellerHomeDetails.privateDetails.state,
      zipCode:
        sellerHomeDetails.privateDetails &&
        sellerHomeDetails.privateDetails.postalCode,
      makePrivateAddress:
        sellerHomeDetails.privateDetails &&
        sellerHomeDetails.privateDetails.makePrivateAddress &&
        sellerHomeDetails.privateDetails.makePrivateAddress,
    });
    this.getAllContactsList();
  }

  getAllContactsList = () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let id = userDetails && userDetails?.id;
    let query = {};
    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
    } else {
      query["userId"] = id;
    }
    query = {
      ...query,
      contactType: "Contact",
    };
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          let totalContactList = [];
          result.data.contactList.map((eachContact) => {
            return totalContactList.push({
              label: eachContact?.firstName,
              firstName: eachContact?.firstName,
              lastName: eachContact?.lastName,
              phone: eachContact?.cellPhone,
              email: eachContact?.emailAddress,
              value: eachContact.id,
              address: eachContact.address?.citySearch,
              city: eachContact.address?.city,
              contactState: eachContact.address?.addressState,
              zipCode: eachContact.address?.zipcode,
            });
          });
          this.setState({
            totalContactList,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hanldeCheck = (e) => {
    this.setState({ makePrivateAddress: e.target.checked });
  };

  handleSellereProfile = async (name, value) => {
    await this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onFinish = (e) => {
    const { sellerHomeDetails } = this.props;

    e["userId"] = this.props.context.state.user.userid;
    e["city"] = this.state.city;
    e["state"] = this.state.addressState;
    e["address"] = this.state.citySearch;
    e["postalCode"] = this.state.zipCode;
    e["makePrivateAddress"] = this.state.makePrivateAddress;

    e["selectedBuyerFromContacts"] =
      this.state.selectedBuyerContact !== ""
        ? this.state.selectedBuyerContact
        : sellerHomeDetails &&
          sellerHomeDetails?.privateDetails.selectedBuyerFromContacts;
    this.props.handleForm(e, "PRIVATE_DETAILS");
  };

  // handleToSelectContact = (contact) => {
  //   this.setState({
  //     selectedContact: false,
  //   });
  //   const { totalContactList } = this.state;
  //   let visibleDetails = totalContactList.filter(
  //     (checkContact) => checkContact.value === contact
  //   );
  //   if (visibleDetails) {
  //     this.setState({
  //       visibleDetails: visibleDetails[0],
  //       selectedBuyerContact:
  //         visibleDetails[0]["firstName"] + " " + visibleDetails[0]["lastName"],
  //       addressState: visibleDetails[0]["contactState"],
  //       city: visibleDetails[0]["city"],
  //       zipCode: visibleDetails[0]["zipCode"],
  //       citySearch: visibleDetails[0]["address"],
  //     });
  //   }
  //   setTimeout(() => {
  //     this.setState({
  //       selectedContact: true,
  //     });
  //   }, 100);
  // };

  handleSelect = (address) => {
    this.setState(
      {
        city: "",
        addressState: "",
        zipCode: "",
      },
      () => {
        setTimeout(() => {
          this.getFormattedAddress(address);
        }, 600);
      }
    );
  };

  getFormattedAddress = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  onSubmit = () => {
    const { contactData } = this.state;
    if (contactData.length > 0) {
      this.setState({
        visibleDetails: contactData[0],
        selectedBuyerContact:
          contactData[0]["firstName"] + " " + contactData[0]["lastName"],
        selectedContact: true,
        showContactModal: false,
        addressState: contactData[0]["address"]["addressState"],
        zipCode: contactData[0]["address"]["zipCode"],
        citySearch: contactData[0]["address"]["citySearch"],
        city: contactData[0]["address"]["city"],
      });
      this.privateFormRef.current.setFieldsValue({
        firstName: contactData[0]["firstName"],
        email: contactData[0]["emailAddress"],
        lastName: contactData[0]["lastName"],
        sellerPhone: contactData[0]["cellPhone"],
      });
    }
  };

  handleSearch = () => {
    this.setState({
      loading: true,
    });
    const { contactInput } = this.state;
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgId = JSON.parse(localStorage.getItem("orgId"));

    let id = userDetails && userDetails?.id;
    let query = {};
    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
    } else {
      query["userId"] = id;
    }
    query["contactType"] = "Contact";
    query = {
      ...query,
      filterSearch: true,
    };

    if (contactInput !== "") {
      if (contactInput.split(" ").length === 2) {
        query = {
          ...query,
          lastNameQuery: contactInput.split(" ")[1],
          textSearch: contactInput.split(" ")[0],
        };
      } else {
        query = {
          ...query,
          textSearch: contactInput,
        };
      }
    }
    this.getResultByPage(query);
  };

  getResultByPage = (query) => {
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          if (result.data.contactList?.length > 0) {
            this.setState({
              filterContacts: result.data.contactList,
              loading: false,
              showContactModal: true,
              newContact: false,
            });
          } else {
            this.privateFormRef.current.setFieldsValue({
              firstName: "",
              email: "",
              lastName: "",
              phone: "",
            });
            this.setState({
              buyerPhone: "",
              contactData: [],
              newContact: true,
              loading: false,
              showContactModal: false,
            });
            notification["error"]({
              message:
                "Could not find matching contact. Please add a new conatct",
              placement: "topRight",
            });
          }
          // setLoading(false);
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err);
      });
  };

  render() {
    const {
      citySearch,
      city,
      addressState,
      zipCode,
      visibleDetails,
      selectedContact,
      showModal,
      newContact,
      filterContacts,
      showContactModal,
      loading,
    } = this.state;
    const webColumns = [
      {
        title: "FIRST NAME",
        dataIndex: "firstName",
        key: "firstName",
        render: (firstName) => {
          return (
            <ModalData
              style={{
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              {firstName}
            </ModalData>
          );
        },
      },

      {
        title: "LAST NAME",
        dataIndex: "lastName",
        key: "lastName",
        render: (lastName) => {
          return (
            <ModalData
              style={{
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              {lastName}
            </ModalData>
          );
        },
      },
      {
        title: "PHONE",
        dataIndex: "cellPhone",
        key: "cellPhone",
        render: (cellPhone) => {
          return (
            <ModalData
              style={{
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              {cellPhone}
            </ModalData>
          );
        },
      },
      {
        title: "DATE ADDED",
        dataIndex: "registered",
        key: "registered",
        render: (registered) => {
          return (
            <ModalData
              style={{
                textTransform: "capitalize",
                marginBottom: "0px",
              }}
            >
              {registered}
            </ModalData>
          );
        },
      },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          contactData: selectedRows,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    // const { sellerHomeDetails } = this.props.context.state;
    const { sellerHomeDetails } = this.props;
    return (
      <div>
        <Loading enableLoading={loading} />
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="privateD-title">Private Details</div>
            <div style={{ marginBottom: 20 }}>
              <span className="privateD-des">
                Other agents can't see this info but if you want to use John Doe
                or Unknown you can
              </span>
            </div>
          </Col>

          <Col
            xl={{ span: 15, offset: 0 }}
            lg={{ span: 15, offset: 0 }}
            md={{ span: 15, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            {selectedContact && (
              <>
                <Input
                  className="input-width select_Height"
                  onChange={(e) =>
                    this.setState({
                      contactInput: e.target.value,
                      newContact: false,
                    })
                  }
                  value={this.state.contactInput}
                  disabled={
                    window.location.pathname ===
                    "/VirtualOffice/ViewAllContacts/" +
                      this.props.match.params.id
                      ? true
                      : false
                  }
                  placeholder="Search Contacts..."
                />
                {newContact ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({
                        showModal: true,
                      });
                    }}
                    className="add-user-width"
                    icon={<PlusOutlined />}
                  >
                    Add Contact
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => this.handleSearch()}
                    className="add-user-width"
                    icon={<SearchOutlined style={{ marginRight: "5px" }} />}
                  >
                    Search
                  </Button>
                )}
              </>
            )}

            {/* <Select
              className="input-width select_Height"
              showSearch
              placeholder="Select Contact"
              optionFilterProp="children"
              style={{
                borderRadius: "5px",
                width: "100%",
                cursor: "pointer",
              }}
              onChange={(event) => this.handleToSelectContact(event)}
              defaultValue={
                sellerHomeDetails &&
                sellerHomeDetails?.privateDetails.selectedBuyerFromContacts
              }
            >
              {totalContactList.length > 0 &&
                totalContactList.map(
                  (province) =>
                    province.firstName !== undefined && (
                      <Select.Option
                        key={province.value}
                        value={province.value}
                        label={province.label}
                      >
                        {" "}
                        {`${province.firstName + " " + province.lastName}`}{" "}
                      </Select.Option>
                    )
                )}
            </Select>
            <Button
              type="primary"
              onClick={() => {
                this.setState({
                  showModal: true,
                });
              }}
              className="add-user-width"
              icon={<PlusOutlined />}
            >
              Add Contact
            </Button> */}
          </Col>

          <Col
            xl={{ span: 16, offset: 0 }}
            lg={{ span: 16, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            {selectedContact && (
              <Form
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                size="large"
                id="form"
                ref={this.privateFormRef}
              >
                <Row>
                  <Col
                    xl={{ span: 11, offset: 0 }}
                    lg={{ span: 11, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                  >
                    <div className="form-titles">
                      First Name (Only you will see this)
                    </div>
                    <Form.Item
                      name="firstName"
                      initialValue={
                        visibleDetails && visibleDetails?.firstName
                          ? visibleDetails?.firstName
                          : sellerHomeDetails &&
                            sellerHomeDetails.privateDetails.firstName
                      }
                    >
                      <Input
                        placeholder="First Name"
                        className="form-input"
                        readOnly
                        // value={sellerName}
                      />
                    </Form.Item>
                    <div className="form-titles">
                      Email (only you will see this)
                    </div>
                    <Form.Item
                      name="email"
                      initialValue={
                        visibleDetails && visibleDetails?.email
                          ? visibleDetails?.email
                          : sellerHomeDetails &&
                            sellerHomeDetails.privateDetails.email
                      }
                    >
                      <Input
                        placeholder="Email"
                        className="form-input"
                        readOnly
                      />
                    </Form.Item>

                    <div className="form-titles">Address</div>
                    <Form.Item className="google-form-search-add-home">
                      <GooglePlacesAutocomplete
                        className="form-input"
                        disabled
                        apiKey={process.env.REACT_APP_MAPS_KEY}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          citySearch,
                          defaultInputValue: `${
                            visibleDetails && visibleDetails?.address
                              ? visibleDetails?.address?.citySearch
                              : sellerHomeDetails.privateDetails
                              ? sellerHomeDetails.privateDetails.address &&
                                sellerHomeDetails.privateDetails.address
                              : ""
                          }`,
                          placeholder: "Search Address...",
                          onChange: (address) => {
                            this.handleSelect(address);
                          },
                        }}
                      />
                      <Checkbox
                        onChange={this.hanldeCheck}
                        // defaultChecked={
                        //   sellerHomeDetails &&
                        //   sellerHomeDetails.privateDetails &&
                        //   sellerHomeDetails.privateDetails.makePrivateAddress &&
                        //   sellerHomeDetails.privateDetails.makePrivateAddress
                        // }
                        checked={this.state.makePrivateAddress}
                      >
                        Make private
                      </Checkbox>
                    </Form.Item>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "15px",
                        fontWeight: " 600",
                        letterSpacing: "0px",
                        color: "#131419",
                        marginTop: "-5px",
                        marginBottom: "5px",
                      }}
                    >
                      City / Town
                    </div>
                    <Input className="form-input" value={city} readOnly />
                  </Col>

                  <Col
                    xl={{ span: 11, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    md={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                  >
                    <div className="form-titles">
                      Last Name (Only you will see this)
                    </div>
                    <Form.Item
                      name="lastName"
                      initialValue={
                        visibleDetails && visibleDetails?.lastName
                          ? visibleDetails?.lastName
                          : sellerHomeDetails &&
                            sellerHomeDetails.privateDetails.lastName
                      }
                    >
                      <Input
                        placeholder="Last Name"
                        className="form-input"
                        // value={sellerName}
                        readOnly
                      />
                    </Form.Item>

                    <div className="form-titles">
                      Seller Phone # (Only you will see this)
                    </div>
                    <Form.Item
                      name="sellerPhone"
                      initialValue={
                        visibleDetails && visibleDetails?.phone
                          ? visibleDetails?.phone
                          : sellerHomeDetails &&
                            sellerHomeDetails.privateDetails.sellerPhone
                      }
                    >
                      <NumberFormat
                        className="ant-input-number"
                        placeholder="Phone"
                        format="(###) ###-####"
                        style={{
                          width: "100%",
                          padding: "4px 11px",
                          height: "50px",
                          fontSize: "14px",
                        }}
                        readOnly
                      />

                      {/* <InputNumber
                        placeholder="Phone"
                        readOnly
                        className="form-input-number"
                        value={sellerPhone}
                        formatter={(value) => {
                          const cleaned = `${value}`.replace(/\D/g, "");
                          const match = cleaned.match(
                            /^(1|)?(\d{3})(\d{3})(\d{4})$/
                          );
                          if (match) {
                            const intlCode = match[1] ? "+1 " : "";
                            return [
                              intlCode,
                              "(",
                              match[2],
                              ") ",
                              match[3],
                              "-",
                              match[4],
                            ].join("");
                          }
                          return null;
                        }}
                      /> */}
                    </Form.Item>

                    <div className="form-titles">State</div>
                    <Input
                      className="form-input"
                      value={addressState}
                      readOnly
                    />

                    <div className="form-titles">Zip Code</div>
                    <Input
                      className="form-input"
                      value={zipCode}
                      style={{ marginTop: "12px" }}
                      readOnly
                    />

                    <Form.Item
                      name="postalCode"
                      initialValue={
                        sellerHomeDetails &&
                        sellerHomeDetails.privateDetails.postalCode
                      }
                    ></Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            {this.state.adverCount === 1 && (
              <Advertisement
                otherAds={false}
                adType="Square"
                adWhereToShow="Homes"
                adLimit="1"
                zipCode=""
              />
            )}
          </Col>
        </Row>
        {showModal && (
          <AddContact
            showModal={showModal}
            proConatct={""}
            loadig={false}
            onCancel={() => this.setState({ showModal: false })}
            editModal={false}
            getUserProfile={this.getAllContactsList}
            setLoading={false}
          />
        )}
        {showContactModal && (
          <Modal
            className="modal-black-close"
            visible={showContactModal}
            title="Contact Details"
            onCancel={() =>
              this.setState({
                showContactModal: false,
                selectedBuyerContact:
                  visibleDetails["firstName"] +
                  " " +
                  visibleDetails["lastName"],
              })
            }
            footer={[
              <Button
                key="back"
                onClick={() =>
                  this.setState({
                    showContactModal: false,
                  })
                }
                style={{
                  height: "35px",
                  width: "100px",
                  borderRadius: "5px",
                  marginRight: "15px",
                }}
              >
                <span style={{ fontSize: "16px" }}>Cancel</span>
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => this.onSubmit()}
                style={{ height: "35px", width: "100px", borderRadius: "5px" }}
              >
                <span style={{ fontSize: "16px" }}>Submit</span>
              </Button>,
              <Button
              type="primary"
              onClick={() => {
                this.setState({
                  showModal: true,
                  showContactModal: false,
                });
              }}
              style={{ height: "35px", width: "150px", borderRadius: "5px" }}
              icon={<PlusOutlined />}
            >
              Add Contact
            </Button>
            ]}
            width={"60%"}
          >
            <Row style={{ paddingTop: "0.73vw" }}>
              <Table
                columns={webColumns}
                dataSource={filterContacts}
                rowKey={(record) => record.id}
                rowSelection={{
                  type: "radio",
                  ...rowSelection,
                }}
                style={{ width: "100%" }}
              />
            </Row>
          </Modal>
        )}
      </div>
    );
  }
}
export default withAppContext(withRouter(PrivateDetails));
