import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import { Col, Row, Form, Input, InputNumber, Select, DatePicker } from "antd";
import Advertisement from "../../../common/Advertisement";
import moment from "moment";
class HomePropertyFeatures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minArea: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.minArea
        : 1,
      maxArea: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.maxArea
        : "",
      minAge: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.minAge
        : 10,
      maxAge: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.maxAge
        : 50,
      yearBuild: "",
      featuresOfchecked: this.props.context.state.sellerHomeDetails
        .propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures
            .featuresOfchecked
        : [],
      minPrice: 0,
      maxPrice: 0,
      title:
        this.props.buyerUserDetails &&
        this.props.buyerUserDetails.buyerHomeFeatures
          ? this.props.buyerUserDetails.buyerHomeFeatures.title
          : "",
    };
  }
  handleYearBuilt = (date) => {
    const date2 = moment(date).format("YYYY");
    this.setState({
      yearBuild: date2,
    });
  };

  onFinish = async (e) => {
    e["minArea"] = this.state.minArea;
    e["maxArea"] = this.state.maxArea;
    e["yearBuilt"] = this.state.yearBuild;
    e["minPrice"] = this.state.minPrice;
    e["maxPrice"] = this.state.maxPrice;
    e["title"] = this.state.title;
    this.props.handleForm(e, "PROPERTY_FEATURES");
  };

  onChangeMin = (value) => {
    this.setState({ minArea: value.target.value });
  };

  onChangeMax = (value) => {
    this.setState({ maxArea: value.target.value });
  };

  componentDidMount() {
    const { sellerHomeDetails } = this.props;

    if (sellerHomeDetails && sellerHomeDetails.propertyFeatures) {
      this.setState({
        maxArea:
          sellerHomeDetails && sellerHomeDetails.propertyFeatures.maxArea,
        minArea:
          sellerHomeDetails && sellerHomeDetails.propertyFeatures.minArea,
      });
    }
  }

  handleFeaturesBox = (featuresOfchecked) => {
    this.setState({
      featuresOfchecked,
    });
  };

  render() {
    const { maxArea } = this.state;
    const { sellerHomeDetails } = this.props;
    const prefixSelector = (
      <Form.Item
        name="suffix"
        initialValue={
          sellerHomeDetails.propertyFeatures &&
          sellerHomeDetails.propertyFeatures.suffix
            ? sellerHomeDetails.propertyFeatures.suffix
            : "Sq ft"
        }
        noStyle
      >
        <Select style={{ width: "5vw", height: "2vw" }}>
          <Select.Option value="Sq ft">Sq ft</Select.Option>
          <Select.Option value="Acres">Acres</Select.Option>
        </Select>
      </Form.Item>
    );
    const FeaturesTags = [
      "Air Conditioning",
      "Fireplace",
      "Gym",
      "Laundry",
      "Outdoor Shower",
      "Sauna",
      "Shed",
      "Swimming Pool",
      "TV Cable",
      "Waterfront",
      "Wood Stove",
      "Walkout basement",
      "Fenced yard",
      "Fire pit",
      "Flat yard",
      "Wet bar",
      " Gas fireplace",
      "Walk in closet",
      "Screen porch",
      "Hardwood floors",
      "Granite counters",
      "Culdesac",
      "Hot Tub",
      "Sprinklers",
      "Central Vacuum",
      "Security System",
      "Walk-up Attic",
    ];

    const typeOfDwelling = [
      "Colonial",
      "Commercial",
      "Condo",
      "Contemporary",
      "Gambrel",
      "Multi Family",
      "Ranch",
      "Split Level",
      "Townhome",
      "Triple Decker",
      "Two Family",
    ];
    const DesiredHalfBaths = [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
    ];
    return (
      <div>
        <Row>
          {this.props && !this.props.BuyerProfile && (
            <Col
              xl={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
            >
              <div className="privateD-title">Property Features</div>
              <div style={{ marginBottom: 20 }}>
                <span className="privateD-des">
                  Provide as much info as you can to potential buyers for this
                  property. The more information the better!
                </span>
              </div>
            </Col>
          )}
          <Col
            xl={{
              span: this.props && this.props.BuyerProfile ? 22 : 16,
              offset: this.props && this.props.BuyerProfile ? 1 : 0,
            }}
            lg={{
              span: this.props && this.props.BuyerProfile ? 22 : 16,
              offset: this.props && this.props.BuyerProfile ? 1 : 0,
            }}
            md={{
              span: this.props && this.props.BuyerProfile ? 22 : 24,
              offset: this.props && this.props.BuyerProfile ? 1 : 0,
            }}
            xs={{
              span: this.props && this.props.BuyerProfile ? 24 : 22,
              offset: this.props && this.props.BuyerProfile ? 0 : 1,
            }}
            sm={{
              span: this.props && this.props.BuyerProfile ? 24 : 22,
              offset: this.props && this.props.BuyerProfile ? 0 : 1,
            }}
          >
            <Form
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              size="large"
              id="form"
            >
              <Row>
                <Col
                  xl={{ span: 11, offset: 0 }}
                  lg={{ span: 11, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <>
                    <div className="form-titles">Living area</div>
                    <Input
                      type="number"
                      addonAfter="sq.ft"
                      value={maxArea}
                      onChange={this.onChangeMax}
                      className="form-input-number-addonAfter"
                      style={{ height: "50px" }}
                      step="Any"
                    />
                  </>
                  <>
                    <div className="form-titles">Beds</div>
                    <Form.Item
                      name="beds"
                      initialValue={
                        sellerHomeDetails.propertyFeatures
                          ? sellerHomeDetails.propertyFeatures.beds
                          : ""
                      }
                    >
                      <InputNumber className="form-input-number" />
                    </Form.Item>
                  </>
                  <>
                    <div className="form-titles">Baths</div>
                    <Form.Item
                      name="baths"
                      initialValue={
                        sellerHomeDetails.propertyFeatures
                          ? sellerHomeDetails.propertyFeatures.baths
                          : ""
                      }
                    >
                      <InputNumber className="form-input-number" />
                    </Form.Item>
                  </>
                  <>
                    <div className="form-titles">Half Baths</div>
                    <Form.Item
                      name="halfBaths"
                      initialValue={
                        sellerHomeDetails.propertyFeatures
                          ? sellerHomeDetails.propertyFeatures.halfBaths
                          : ""
                      }
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Half Baths"
                      >
                        {DesiredHalfBaths.map((province) => (
                          <Select.Option
                            key={province.value}
                            value={province.value}
                          >
                            {province.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                  <>
                    <div className="form-titles">Rooms</div>
                    <Form.Item
                      name="rooms"
                      initialValue={
                        sellerHomeDetails.propertyFeatures
                          ? sellerHomeDetails.propertyFeatures.rooms
                          : ""
                      }
                    >
                      <InputNumber className="form-input-number" />
                    </Form.Item>
                  </>
                  <div className="form-titles">Type of Dwelling</div>
                  <Form.Item
                    name="dwelling"
                    initialValue={
                      sellerHomeDetails && sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.dwelling
                        : ""
                    }
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select Type Of Dwelling"
                    >
                      {typeOfDwelling.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <div className="form-titles">Style</div>
                  <Form.Item
                    name="style"
                    initialValue={
                      sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.style
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Condition</div>
                  <Form.Item
                    name="condition"
                    initialValue={
                      sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.condition
                        : ""
                    }
                  >
                    <Select
                      className="form-input"
                      placeholder="Select Condition"
                    >
                      <Select.Option value="Poor">Poor</Select.Option>
                      <Select.Option value="Fair">Fair</Select.Option>
                      <Select.Option value="Average">Average</Select.Option>
                      <Select.Option value="Good">Good</Select.Option>
                      <Select.Option value="Excellent">Excellent</Select.Option>
                      <Select.Option value="Brand New">Brand New</Select.Option>
                    </Select>
                  </Form.Item>
                  <>
                    <div className="form-titles">Flooring</div>
                    <Form.Item
                      name="flooring"
                      initialValue={
                        sellerHomeDetails.propertyFeatures
                          ? sellerHomeDetails.propertyFeatures.flooring
                          : ""
                      }
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </>

                  <>
                    <div className="form-titles">Year Built</div>
                    <Form.Item name="yearBuilt">
                      <DatePicker
                        className="form-input-number"
                        defaultValue={
                          sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.yearBuilt
                            ? sellerHomeDetails.propertyFeatures.yearBuilt !==
                                "undefined" &&
                              moment(
                                sellerHomeDetails.propertyFeatures.yearBuilt,
                                "YYYY"
                              )
                            : ""
                        }
                        onChange={this.handleYearBuilt}
                        picker="year"
                      />
                    </Form.Item>
                  </>
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <>
                    <div className="form-titles">
                      Lot Description (i.e level, sloping, cleared, etc.)
                    </div>
                    <Form.Item
                      name="lotDescription"
                      initialValue={
                        sellerHomeDetails.propertyFeatures
                          ? sellerHomeDetails.propertyFeatures.lotDescription
                          : ""
                      }
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </>
                  <>
                    <div className="form-titles">Lot Size </div>
                    <Form.Item
                      name="LotSize"
                      initialValue={
                        sellerHomeDetails.propertyFeatures &&
                        sellerHomeDetails.propertyFeatures.LotSize
                          ? sellerHomeDetails.propertyFeatures.LotSize
                          : ""
                      }
                    >
                      <Input
                        type="number"
                        className="form-input-number-addonAfter"
                        addonAfter={prefixSelector}
                        step="any"
                      />
                    </Form.Item>
                  </>
                  <>
                    <div className="form-titles">
                      Garage (leave blank if none)
                    </div>
                    <Form.Item
                      name="garageSpace"
                      initialValue={
                        sellerHomeDetails.propertyFeatures
                          ? sellerHomeDetails.propertyFeatures.garageSpace
                          : ""
                      }
                    >
                      <InputNumber className="form-input-number" />
                    </Form.Item>
                  </>
                  <>
                    <div className="form-titles">Parking Spaces</div>
                    <Form.Item
                      name="parakingSpace"
                      initialValue={
                        sellerHomeDetails.propertyFeatures
                          ? sellerHomeDetails.propertyFeatures.parakingSpace
                          : ""
                      }
                    >
                      <InputNumber className="form-input-number" />
                    </Form.Item>
                  </>
                  <div className="form-titles">Cooling</div>
                  <Form.Item
                    name="colling"
                    initialValue={
                      sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.colling
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Heating</div>
                  <Form.Item
                    name="heating"
                    initialValue={
                      sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.heating
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Basement</div>
                  <Form.Item
                    name="basement"
                    initialValue={
                      sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.basement
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Utilities</div>
                  <Form.Item
                    name="utilities"
                    initialValue={
                      sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.utilities
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Interior Features</div>
                  <Form.Item
                    name="interiorFeatures"
                    initialValue={
                      sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.interiorFeatures
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">Exterior Features</div>
                  <Form.Item
                    name="exteriorFeatures"
                    initialValue={
                      sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.exteriorFeatures
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                </Col>

                <Col
                  xl={{ span: 23 }}
                  lg={{ span: 23 }}
                  md={{ span: 23 }}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                >
                  <>
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        fontFamily: "Poppins,sans-serif",
                      }}
                    >
                      Fireplaces
                    </div>
                    <Form.Item
                      name="firePlaces"
                      initialValue={
                        sellerHomeDetails.propertyFeatures
                          ? sellerHomeDetails.propertyFeatures.firePlaces
                          : ""
                      }
                    >
                      <InputNumber className="form-input-number" />
                    </Form.Item>
                  </>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "600",
                      fontFamily: "Poppins,sans-serif",
                    }}
                  >
                    Features
                  </div>
                  <Form.Item
                    name="featuresOfchecked"
                    initialValue={
                      sellerHomeDetails && sellerHomeDetails.propertyFeatures
                        ? sellerHomeDetails.propertyFeatures.featuresOfchecked
                          ? sellerHomeDetails.propertyFeatures.featuresOfchecked
                          : []
                        : []
                    }
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="You Can Select Multiple Features"
                    >
                      {FeaturesTags.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Advertisement
              otherAds={false}
              adType="Square"
              adWhereToShow="Homes"
              adLimit="3"
              zipCode=""
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withAppContext(withRouter(HomePropertyFeatures));
