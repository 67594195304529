import { Col, Row, Form, Input, notification, Button, Spin } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import { update, getDetails } from "../../../api/Apis";

class SocialMediaForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saveSocialMedia: false,
    };
  }
  handleSellereProfile = async (name, value) => {
    await this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onFinish = async (socialMedia) => {
    this.setState({
      saveSocialMedia: true,
    });
    const { userDetails } = this.props.context.state;
    const id = userDetails.id;
    let entityData = { socialMedia };
    const data = Object.assign(
      {},
      {
        entityData,
      }
    );
    const updatingUserDetails = await update("users", id, data);
    if (updatingUserDetails.success) {
      this.setState({
        saveSocialMedia: false,
      });
      notification["success"]({
        message: "Your profile has been updated",
        // description: "Your social media details updated successfully",
       
      });
      const userProfileDetails = await getDetails("users", id);
      if (userProfileDetails.success) {
        this.props.context.setUserDetails(userProfileDetails.data);
        localStorage.removeItem("userDetails");
        localStorage.setItem(
          "userDetails",
          JSON.stringify(userProfileDetails.data)
        );
      }
    }
  };
  handleSingleSearch = (values) => {
    let address = values.label.split(",");
    const citySearch = address;
    this.setState({ citySearch });
  };
  render() {
    // const { userDetails } = this.props;
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { saveSocialMedia } = this.state;
    return (
      <div>
        <Form
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          size="large"
        >
          <Spin spinning={saveSocialMedia}>
            <Row>
              <Col
                xl={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="label-titles">Facebook</div>
                <Form.Item
                  name="facebook"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.facebook
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter the facebook URL"
                    className="form-input"
                  />
                </Form.Item>
                <div className="label-titles">LinkedIn</div>
                <Form.Item
                  name="Linkedin"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.Linkedin
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter the Linkedin URL"
                    className="form-input"
                  />
                </Form.Item>
                <div className="label-titles">Google Plus</div>
                <Form.Item
                  name="GooglePlus"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.GooglePlus
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter the Google Plus URL"
                    className="form-input"
                  />
                </Form.Item>
                <div className="label-titles">Pinterest</div>
                <Form.Item
                  name="pinterest"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.pinterest
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter the Pinterest URL"
                    className="form-input"

                  />
                </Form.Item>
                <div className="label-titles">Skype</div>
                <Form.Item
                  name="skype"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.skype
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter the Skype ID"
                    className="form-input"
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="label-titles">Twitter</div>
                <Form.Item
                  name="twitter"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.twitter
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter the Twitter URL"
                    className="form-input"
                  />
                </Form.Item>
                <div className="label-titles">Instagram</div>
                <Form.Item
                  name="instagram"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.instagram
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter the Instagram"
                    className="form-input"
                  />
                </Form.Item>
                <div className="label-titles">Youtube </div>
                <Form.Item
                  name="youtube"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.youtube
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter the youtube URL"
                    className="form-input"
                  />
                </Form.Item>

                <div className="label-titles">Vimeo</div>
                <Form.Item
                  name="vimeo"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.vimeo
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter the vimeo URL"
                    className="form-input"
                  />
                </Form.Item>
                <div className="label-titles">Website</div>
                <Form.Item
                  name="website"
                  initialValue={
                    userDetails
                      ? userDetails.socialMedia
                        ? userDetails.socialMedia.website
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter your website URL"
                    className="form-input"
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 8, offset: 1 }}
                lg={{ span: 8, offset: 1 }}
                md={{ span: 8, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn btn-success update-btn"
                >
                  Update Profile
                </Button>
              </Col>
            </Row>
          </Spin>
        </Form>
      </div>
    );
  }
}
export default withAppContext(withRouter(SocialMediaForm));
