import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Col,
  Row,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  DatePicker,
  Button,
  notification,
} from "antd";
import moment from "moment";
import { update, getDetails } from "../../../api/Apis";
import { withAppContext } from "../../../store/contextStore";
import Advertisement from "../../../common/Advertisement";
const { TextArea } = Input;
class PropertyFeatures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minArea: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.minArea
        : 1,
      maxArea: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.maxArea
        : this.props.AddBuyer
        ? 20000
        : "",
      minAge: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.minAge
        : 10,
      maxAge: this.props.context.state.sellerHomeDetails.propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures.maxAge
        : 50,
      yearBuild: "",
      featuresOfchecked: this.props.context.state.sellerHomeDetails
        .propertyFeatures
        ? this.props.context.state.sellerHomeDetails.propertyFeatures
            .featuresOfchecked
        : [],
      minPrice: 0,
      maxPrice: 0,
      title:
        this.props.buyerUserDetails &&
        this.props.buyerUserDetails.buyerHomeFeatures
          ? this.props.buyerUserDetails.buyerHomeFeatures.title
          : "",
    };
  }

  componentDidMount() {
    const { buyerHomeDetails } = this.props.context.state;
    const { buyerUserDetails, sellerHomeDetails } = this.props;
    if (sellerHomeDetails && sellerHomeDetails.propertyFeatures) {
      this.setState({
        maxArea:
          sellerHomeDetails && sellerHomeDetails.propertyFeatures.maxArea,
        minArea:
          sellerHomeDetails && sellerHomeDetails.propertyFeatures.minArea,
      });
    }
    if (buyerHomeDetails && buyerHomeDetails.buyerPropertyFeatures) {
      this.setState({
        maxArea:
          buyerHomeDetails && buyerHomeDetails.buyerPropertyFeatures.maxArea,
        minArea:
          buyerHomeDetails && buyerHomeDetails.buyerPropertyFeatures.minArea,
      });
    }
    if (buyerUserDetails && buyerUserDetails.buyerHomeFeatures) {
      this.setState({
        maxPrice:
          buyerUserDetails && buyerUserDetails.buyerHomeFeatures.maxPrice,
        minPrice:
          buyerUserDetails && buyerUserDetails.buyerHomeFeatures.minPrice,
        maxArea: buyerUserDetails && buyerUserDetails.buyerHomeFeatures.maxArea,
        minArea: buyerUserDetails && buyerUserDetails.buyerHomeFeatures.minArea,
        title: buyerUserDetails && buyerUserDetails.buyerHomeFeatures.title,
      });
    }
  }

  handleYearBuilt = (date) => {
    const date2 = moment(date).format("YYYY");
    this.setState({
      yearBuild: date2,
    });
  };

  handleDesiredYearBuild = (year) => {
    this.setState({
      yearBuild: year,
    });
  };

  onFinish = async (e) => {
    e["minArea"] = this.state.minArea;
    e["maxArea"] = this.state.maxArea;
    e["yearBuilt"] = this.state.yearBuild;
    e["minPrice"] = this.state.minPrice;
    e["maxPrice"] = this.state.maxPrice;
    e["title"] = this.state.title;
    const { minPrice, maxPrice, minArea, maxArea } = this.state;
    if (
      window.location.pathname ===
        `/add/buyerProfile/${this.props.match.params.id}` ||
      window.location.pathname === "/add/buyerProfile" || 
      window.location.pathname ===
      "/VirtualOffice/ViewAllContacts/" + this.props.match.params.id
    ) {
      if (maxArea === "") {
        notification["error"]({
          message: "Invalid Living area",
          description: "Please enter living area",
        });
      } else if (maxArea < minArea) {
        notification["error"]({
          message: "Invalid Living area",
          description: "Max area should be greater than min area",
        });
      }
      if (maxArea && maxArea >= minArea) {
        this.props.context.setBuyerHomeDetails(e, "BUYER_PROPERTY_FEATURES");
        this.props.handleBuyerDetails(e);
      }
    } else {
      if (!this.props.BuyerProfile) {
        this.props.handleForm(e, "PROPERTY_FEATURES");
      }
    }

    if (this.props && this.props.BuyerProfile) {
      if ((e && e.title === "") || e.title === undefined) {
        notification["error"]({
          message: "Invalid Title",
          description: "Please enter title",
        });
      }
      if (
        (e && e.propertyType === "") ||
        e.propertyType === undefined ||
        e.propertyType.length <= 0
      ) {
        notification["error"]({
          message: "Invalid property",
          description: "Please enter propertyType",
        });
      }
      if (maxPrice && minPrice && maxPrice < minPrice) {
        notification["error"]({
          message: "Invalid Price Range",
          description: "Max price should be greater than min price",
        });
      } else if (minPrice === null || maxPrice === null) {
        notification["error"]({
          message: "Price is required ",
          description: "Please enter price range",
        });
      }
      if (maxArea < minArea) {
        notification["error"]({
          message: "Invalid Living area",
          description: "Max area should be greater than min area",
        });
      }

      const { userDetails } = this.props.context.state;
      const id = userDetails.id;
      const buyerHomeFeatures = e;
      let entityData = { buyerHomeFeatures };
      const data = Object.assign(
        {},
        {
          entityData,
        }
      );
      if (
        minPrice &&
        maxPrice &&
        maxPrice >= minPrice &&
        maxArea >= minArea &&
        e.title !== "" &&
        e.title !== undefined &&
        e.propertyType !== undefined &&
        e.propertyType.length > 0
      ) {
        const updatingUserDetails = await update("users", id, data);
        if (updatingUserDetails.success) {
          this.setState({
            saveSocialMedia: false,
          });
          notification["success"]({
            message: "Your profile has been updated",
          });
          this.handleToUpdateBuyerProfile(id);
        }
      }
    }
  };

  onChangeMin = (value) => {
    this.setState({ minArea: value.target.value });
  };

  onChangeMinPrice = (value) => {
    this.setState({ minPrice: value });
  };

  onChangeMaxPrice = (value) => {
    this.setState({ maxPrice: value, listPrice: value });
  };

  onChangeMax = (value) => {
    this.setState({ maxArea: value.target.value });
  };

  handleTitle = (value) => {
    this.setState({ title: value.target.value });
  };

  handleFeaturesBox = (featuresOfchecked) => {
    this.setState({
      featuresOfchecked,
    });
  };

  handleToUpdateBuyerProfile = async (id) => {
    const userProfileDetails = await getDetails("users", id);
    if (userProfileDetails.success) {
      this.props.context.setUserDetails(userProfileDetails.data);
      localStorage.removeItem("userDetails");
      localStorage.setItem(
        "userDetails",
        JSON.stringify(userProfileDetails.data)
      );
    }
  };

  render() {
    const timeFrame = [
      "90-120 days",
      "1-4 months",
      "4-6 months",
      "6-12 months",
      "12-16 months",
      "16+ months",
    ];
    const DesiredBeds = [
      { label: "1+", value: 1 },
      { label: "2+", value: 2 },
      { label: "3+", value: 3 },
      { label: "4+", value: 4 },
      { label: "5+", value: 5 },
      { label: "6+", value: 6 },
      { label: "7+", value: 7 },
      { label: "8+", value: 8 },
      { label: "9+", value: 9 },
    ];
    const DesiredBaths = [
      { label: "1+", value: 1 },
      { label: "1.5+", value: 1.5 },
      { label: "2+", value: 2 },
      { label: "2.5+", value: 2.5 },
      { label: "3+", value: 3 },
      { label: "3.5+", value: 3.5 },
      { label: "4+", value: 4 },
      { label: "4.5+", value: 4.5 },
      { label: "5+", value: 5 },
    ];
    const DesiredHalfBaths = [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
    ];
    const yearBuiltOptions = [
      "1700-1920",
      "1920-1950",
      "1950-1980",
      "1990-2010",
      "2010+",
    ];
    const LotSizeOptionsForBuyerForm = [
      "0-0.5 acre",
      "0.5-1 acre",
      "1-2 acres",
      "2-5 acres",
      "5+ acres",
    ];
    const GarageSpaceSelction = ["1+", "2+", "3+"];
    const { maxArea, minArea, minPrice, maxPrice } = this.state;
    const { buyerUserDetails, buyerHomeDetails, sellerHomeDetails } =
      this.props;
    const property1 = [
      { label: "Land", value: "Land" },
      { label: "Mobile Homes", value: "Mobile Homes" },
      {
        label: "Residential Multi Family Home",
        value: "Residential Multi Family Home",
      },
      {
        label: "Residential Single Family Home",
        value: "Residential Single Family Home",
      },
      { label: "Residential Rental ", value: "Residential Rental" },
      { label: "Residential Condominium", value: "Residential Condominium" },
      { label: "Coopertive", value: "Coopertive" },
      { label: "Commercial Office", value: "Commercial Office" },
      { label: "Commercial MLS", value: "Commercial MLS" },
      { label: "Business Opportunity", value: "Business Opportunity" },
    ];
    const prefixSelector = (
      <Form.Item
        name="suffix"
        initialValue={
          sellerHomeDetails &&
          sellerHomeDetails.propertyFeatures &&
          sellerHomeDetails.propertyFeatures.suffix
            ? sellerHomeDetails.propertyFeatures.suffix
            : "Sq ft"
        }
        noStyle
      >
        <Select style={{ width: "5vw", height: "2vw" }}>
          <Select.Option value="Sq ft">Sq ft</Select.Option>
          <Select.Option value="Acres">Acres</Select.Option>
        </Select>
      </Form.Item>
    );
    const FeaturesTags = [
      "Air Conditioning",
      "Fireplace",
      "Gym",
      "Laundry",
      "Outdoor Shower",
      "Sauna",
      "Shed",
      "Swimming Pool",
      "TV Cable",
      "Waterfront",
      "Wood Stove",
      "Walkout basement",
      "Fenced yard",
      "Fire pit",
      "Flat yard",
      "Wet bar",
      " Gas fireplace",
      "Walk in closet",
      "Screen porch",
      "Hardwood floors",
      "Granite counters",
      "Culdesac",
      "Hot Tub",
      "Sprinklers",
      "Central Vacuum",
      "Security System",
      "Walk-up Attic",
    ];

    const typeOfDwelling = [
      "Colonial",
      "Commercial",
      "Condo",
      "Contemporary",
      "Gambrel",
      "Multi Family",
      "Ranch",
      "Split Level",
      "Townhome",
      "Triple Decker",
      "Two Family",
    ];
    return (
      <div>
        <Row>
          {this.props && !this.props.BuyerProfile && (
            <Col
              xl={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
            >
              <div className="privateD-title">Property Features</div>
              <div style={{ marginBottom: 20 }}>
                <span className="privateD-des">
                  {this.props && this.props.AddBuyer
                    ? "Provide as much info as you can about what you are looking for in a home or investment purchase. The more information the better!"
                    : "Provide as much info as you can to potential buyers for this property. The more information the better!"}
                </span>
              </div>
            </Col>
          )}
          <Col
            xl={{
              span: this.props && this.props.BuyerProfile ? 22 : 16,
              offset: this.props && this.props.BuyerProfile ? 1 : 0,
            }}
            lg={{
              span: this.props && this.props.BuyerProfile ? 22 : 16,
              offset: this.props && this.props.BuyerProfile ? 1 : 0,
            }}
            md={{
              span: this.props && this.props.BuyerProfile ? 22 : 24,
              offset: this.props && this.props.BuyerProfile ? 1 : 0,
            }}
            xs={{
              span: this.props && this.props.BuyerProfile ? 24 : 22,
              offset: this.props && this.props.BuyerProfile ? 0 : 1,
            }}
            sm={{
              span: this.props && this.props.BuyerProfile ? 24 : 22,
              offset: this.props && this.props.BuyerProfile ? 0 : 1,
            }}
          >
            <Form
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              size="large"
              id="form"
            >
              <Row>
                <Col
                  xl={{ span: 11, offset: 0 }}
                  lg={{ span: 11, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  {this.props && this.props.BuyerProfile && (
                    <>
                      <div className="form-titles">Desired Title</div>
                      <Form.Item
                        name="title"
                        initialValue={
                          buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.title
                            ? buyerUserDetails.buyerHomeFeatures.title
                            : ""
                        }
                      >
                        <Input
                          placeholder="Eg. New family looking for bigger home"
                          className="form-input"
                          onChange={this.handleTitle}
                          value={this.state.title}
                        />
                      </Form.Item>

                      <div className="form-titles">
                        Desired Time Frame for Moving{" "}
                      </div>
                      <Form.Item
                        name="timeFrame"
                        initialValue={
                          buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.timeFrame
                            ? buyerUserDetails.buyerHomeFeatures.timeFrame
                            : ""
                        }
                      >
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Select Desired Time Frame "
                        >
                          {timeFrame.map((province) => (
                            <Select.Option key={province} value={province}>
                              {province}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}

                  {(this.props && this.props.AddBuyer) ||
                  this.props.BuyerProfile ? (
                    <Row>
                      <Col span={11}>
                        <div className="form-titles">
                          Desired Living area (Min)
                        </div>
                        <Input
                          type="number"
                          addonAfter="sq.ft"
                          className="form-input-number-addonAfter"
                          value={minArea}
                          onChange={this.onChangeMin}
                          step="Any"
                        />
                      </Col>
                      <Col span={11} offset={2}>
                        <div
                          className="form-titles"
                          // style={{ marginLeft: "4vw" }}
                        >
                          Desired Living area (Max)
                        </div>
                        <Input
                          type="number"
                          addonAfter="sq.ft"
                          value={maxArea}
                          onChange={this.onChangeMax}
                          className="form-input-number-addonAfter"
                          step="Any"
                        />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <div className="form-titles">Living area</div>
                      <Input
                        type="number"
                        addonAfter="sq.ft"
                        value={maxArea}
                        onChange={this.onChangeMax}
                        className="form-input-number-addonAfter"
                        style={{ height: "50px" }}
                        step="Any"
                      />
                    </>
                  )}

                  {this.props.AddBuyer || this.props.BuyerProfile ? (
                    <>
                      <div className="form-titles">Desired Beds</div>
                      <Form.Item
                        name="beds"
                        initialValue={
                          buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                            ? buyerHomeDetails.buyerPropertyFeatures.beds
                            : buyerUserDetails &&
                              buyerUserDetails.buyerHomeFeatures &&
                              buyerUserDetails.buyerHomeFeatures.beds
                            ? buyerUserDetails.buyerHomeFeatures.beds
                            : ""
                        }
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Desired Beds"
                        >
                          {DesiredBeds.map((province) => (
                            <Select.Option
                              key={province.value}
                              value={province.value}
                            >
                              {province.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <div className="form-titles">Beds</div>
                      <Form.Item
                        name="beds"
                        initialValue={
                          sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.beds
                        }
                      >
                        <InputNumber className="form-input-number" />
                      </Form.Item>
                    </>
                  )}

                  {this.props.AddBuyer || this.props.BuyerProfile ? (
                    <>
                      <div className="form-titles">Desired Baths</div>
                      <Form.Item
                        name="baths"
                        initialValue={
                          buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                            ? buyerHomeDetails.buyerPropertyFeatures.baths
                            : buyerUserDetails &&
                              buyerUserDetails.buyerHomeFeatures &&
                              buyerUserDetails.buyerHomeFeatures.baths
                            ? buyerUserDetails.buyerHomeFeatures.baths
                            : ""
                        }
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Desired Baths"
                        >
                          {DesiredBaths.map((province) => (
                            <Select.Option
                              key={province.value}
                              value={province.value}
                            >
                              {province.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <div className="form-titles">Baths</div>
                      <Form.Item
                        name="baths"
                        initialValue={
                          sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.baths
                        }
                      >
                        <InputNumber className="form-input-number" />
                      </Form.Item>
                    </>
                  )}
                  {!this.props.AddBuyer && !this.props.BuyerProfile && (
                    <>
                      <div className="form-titles">Half Baths</div>
                      <Form.Item
                        name="halfBaths"
                        initialValue={
                          sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.halfBaths
                        }
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Half Baths"
                        >
                          {DesiredHalfBaths.map((province) => (
                            <Select.Option
                              key={province.value}
                              value={province.value}
                            >
                              {province.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}

                  {!this.props.AddBuyer && !this.props.BuyerProfile && (
                    <>
                      <div className="form-titles">Rooms</div>
                      <Form.Item
                        name="rooms"
                        initialValue={
                          window.location.pathname ===
                            `/add/sellerProfile/${this.props.match.params.id}` ||
                          window.location.pathname === "/add/sellerProfile"
                            ? sellerHomeDetails.propertyFeatures &&
                              sellerHomeDetails.propertyFeatures.rooms
                            : buyerHomeDetails &&
                              buyerHomeDetails.buyerPropertyFeatures
                            ? buyerHomeDetails.buyerPropertyFeatures.rooms
                            : buyerUserDetails &&
                              buyerUserDetails.buyerHomeFeatures &&
                              buyerUserDetails.buyerHomeFeatures.rooms
                            ? buyerUserDetails.buyerHomeFeatures.rooms
                            : ""
                        }
                      >
                        <InputNumber className="form-input-number" />
                      </Form.Item>
                    </>
                  )}
                  <div className="form-titles">
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Type of Dwelling"
                      : "Type of Dwelling"}
                  </div>
                  <Form.Item
                    name="dwelling"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails &&
                          sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.dwelling
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures.dwelling
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.dwelling
                        ? buyerUserDetails.buyerHomeFeatures.dwelling
                        : []
                    }
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select Type Of Dwelling"
                      mode={
                        this.props.AddBuyer || this.props.BuyerProfile
                          ? "multiple"
                          : ""
                      }
                      showSearch={
                        window.screen.width >= "992" &&
                        (this.props.AddBuyer || this.props.BuyerProfile)
                          ? true
                          : false
                      }
                    >
                      {typeOfDwelling.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {(this.props.AddBuyer || this.props.BuyerProfile) && (
                    <>
                      <div className="form-titles">Desired Garage</div>
                      <Form.Item
                        name="garageSpace"
                        initialValue={
                          window.location.pathname ===
                            `/add/sellerProfile/${this.props.match.params.id}` ||
                          window.location.pathname === "/add/sellerProfile"
                            ? sellerHomeDetails.propertyFeatures &&
                              sellerHomeDetails.propertyFeatures.garageSpace
                            : buyerHomeDetails &&
                              buyerHomeDetails.buyerPropertyFeatures
                            ? buyerHomeDetails.buyerPropertyFeatures.garageSpace
                            : buyerUserDetails &&
                              buyerUserDetails.buyerHomeFeatures &&
                              buyerUserDetails.buyerHomeFeatures.garageSpace
                            ? buyerUserDetails.buyerHomeFeatures.garageSpace
                            : ""
                        }
                      >
                        <Select placeholder="Select">
                          {GarageSpaceSelction.map((province) => (
                            <Select.Option key={province} value={province}>
                              {province}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}

                  <div className="form-titles">
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Style"
                      : "Style"}
                  </div>
                  <Form.Item
                    name="style"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.style
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures.style
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.style
                        ? buyerUserDetails.buyerHomeFeatures.style
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  {(this.props.BuyerProfile || this.props.AddBuyer) && (
                    <>
                      <div className="form-titles">Desired Year Built</div>
                      <Form.Item
                        name="yearBuilt"
                        initialValue={
                          this.props.AddBuyer
                            ? buyerHomeDetails &&
                              buyerHomeDetails.buyerPropertyFeatures &&
                              buyerHomeDetails.buyerPropertyFeatures.yearBuilt
                              ? buyerHomeDetails.buyerPropertyFeatures.yearBuilt
                              : []
                            : buyerUserDetails &&
                              buyerUserDetails.buyerHomeFeatures &&
                              buyerUserDetails.buyerHomeFeatures.yearBuilt
                            ? buyerUserDetails.buyerHomeFeatures.yearBuilt
                            : []
                        }
                      >
                        <Select
                          placeholder="You can select multiple"
                          mode="multiple"
                          allowClear
                          showSearch={
                            window.screen.width >= "992" ? true : false
                          }
                          onChange={this.handleDesiredYearBuild}
                        >
                          {yearBuiltOptions.map((province) => (
                            <Select.Option key={province} value={province}>
                              {province}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                  <div className="form-titles">
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Condition"
                      : "Condition"}
                  </div>
                  <Form.Item
                    name="condition"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.condition
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures.condition
                          ? buyerHomeDetails.buyerPropertyFeatures.condition
                          : []
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.condition
                        ? buyerUserDetails.buyerHomeFeatures.condition
                        : []
                    }
                  >
                    <Select
                      className="form-input"
                      placeholder="Select Condition"
                      mode={
                        this.props.AddBuyer || this.props.BuyerProfile
                          ? "multiple"
                          : ""
                      }
                      showSearch={
                        window.screen.width >= "992" &&
                        (this.props.AddBuyer || this.props.BuyerProfile)
                          ? true
                          : false
                      }
                    >
                      <Select.Option value="Poor">Poor</Select.Option>
                      <Select.Option value="Fair">Fair</Select.Option>
                      <Select.Option value="Average">Average</Select.Option>
                      <Select.Option value="Good">Good</Select.Option>
                      <Select.Option value="Excellent">Excellent</Select.Option>
                      <Select.Option value="Brand New">Brand New</Select.Option>
                    </Select>
                  </Form.Item>
                  {!this.props.AddBuyer && !this.props.BuyerProfile && (
                    <>
                      <div className="form-titles">Flooring</div>
                      <Form.Item
                        name="flooring"
                        initialValue={
                          window.location.pathname ===
                            `/add/sellerProfile/${this.props.match.params.id}` ||
                          window.location.pathname === "/add/sellerProfile"
                            ? sellerHomeDetails.propertyFeatures &&
                              sellerHomeDetails.propertyFeatures.flooring
                            : buyerHomeDetails &&
                              buyerHomeDetails.buyerPropertyFeatures
                            ? buyerHomeDetails.buyerPropertyFeatures.flooring
                            : buyerUserDetails &&
                              buyerUserDetails.buyerHomeFeatures &&
                              buyerUserDetails.buyerHomeFeatures.flooring
                            ? buyerUserDetails.buyerHomeFeatures.flooring
                            : ""
                        }
                      >
                        <Input className="form-input" />
                      </Form.Item>
                    </>
                  )}
                  {!this.props.BuyerProfile && !this.props.AddBuyer && (
                    <>
                      <div className="form-titles">Year Built</div>
                      <Form.Item name="yearBuilt">
                        <DatePicker
                          className="form-input-number"
                          defaultValue={
                            sellerHomeDetails.propertyFeatures &&
                            sellerHomeDetails.propertyFeatures.yearBuilt
                              ? moment(
                                  sellerHomeDetails.propertyFeatures.yearBuilt,
                                  "YYYY"
                                )
                              : ""
                          }
                          onChange={this.handleYearBuilt}
                          picker="year"
                        />
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  {this.props && this.props.BuyerProfile && (
                    <>
                      <div className="form-titles">Property Type</div>
                      <Form.Item
                        name="propertyType"
                        initialValue={
                          buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.propertyType
                            ? buyerUserDetails.buyerHomeFeatures.propertyType
                            : []
                        }
                      >
                        <Select
                          className="form-input"
                          placeholder="Select"
                          mode={"multiple"}
                        >
                          {property1.map((province) => (
                            <Select.Option
                              multiple
                              key={province.value}
                              value={province.value}
                            >
                              {province.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Row>
                        <Col span={11}>
                          <div
                            className="form-titles"
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                              fontFamily: "Poppins,sans-serif",
                            }}
                          >
                            Min Price
                          </div>

                          <InputNumber
                            formatter={(value) =>
                              ` $ ${value}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            value={`${
                              minPrice && minPrice !== "undefined"
                                ? minPrice
                                : 0
                            }`}
                            style={{ width: "100%", height: "46px" }}
                            onChange={this.onChangeMinPrice}
                          />
                        </Col>
                        <Col span={11} offset={2}>
                          <div
                            className="form-titles"
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                              fontFamily: "Poppins,sans-serif",
                            }}
                          >
                            Max Price
                          </div>
                          <InputNumber
                            formatter={(value) =>
                              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            value={`${
                              maxPrice && maxPrice !== "undefined"
                                ? maxPrice
                                : ""
                            }`}
                            onChange={this.onChangeMaxPrice}
                            style={{ width: "100%", height: "46px" }}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {this.props &&
                    !this.props.BuyerProfile &&
                    !this.props.AddBuyer && (
                      <>
                        <div className="form-titles">
                          Lot Description (i.e level, sloping, cleared, etc.)
                        </div>
                        <Form.Item
                          name="lotDescription"
                          initialValue={
                            window.location.pathname ===
                              `/add/sellerProfile/${this.props.match.params.id}` ||
                            window.location.pathname === "/add/sellerProfile"
                              ? sellerHomeDetails.propertyFeatures &&
                                sellerHomeDetails.propertyFeatures
                                  .lotDescription
                              : buyerHomeDetails &&
                                buyerHomeDetails.buyerPropertyFeatures
                              ? buyerHomeDetails.buyerPropertyFeatures
                                  .lotDescription
                              : buyerUserDetails &&
                                buyerUserDetails.buyerHomeFeatures &&
                                buyerUserDetails.buyerHomeFeatures
                                  .lotDescription
                              ? buyerUserDetails.buyerHomeFeatures
                                  .lotDescription
                              : ""
                          }
                        >
                          <Input className="form-input" />
                        </Form.Item>
                      </>
                    )}
                  {(this.props.AddBuyer || this.props.BuyerProfile) && (
                    <>
                      <div className="form-titles">Desired Lot Size</div>
                      <Form.Item
                        name="LotSize"
                        initialValue={
                          buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures &&
                          buyerHomeDetails.buyerPropertyFeatures.LotSize
                            ? buyerHomeDetails.buyerPropertyFeatures.LotSize
                            : buyerUserDetails &&
                              buyerUserDetails.buyerHomeFeatures &&
                              buyerUserDetails.buyerHomeFeatures.LotSize &&
                              buyerUserDetails.buyerHomeFeatures.LotSize
                        }
                      >
                        <Select
                          placeholder="Select"
                          mode="multiple"
                          allowClear
                          showSearch={
                            window.screen.width >= "992" ? true : false
                          }
                        >
                          {LotSizeOptionsForBuyerForm.map((province) => (
                            <Select.Option key={province} value={province}>
                              {province}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                  {!this.props.BuyerProfile && !this.props.AddBuyer && (
                    <>
                      <div className="form-titles">Lot Size </div>
                      <Form.Item
                        name="LotSize"
                        initialValue={
                          sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.LotSize
                            ? sellerHomeDetails.propertyFeatures.LotSize
                            : ""
                        }
                      >
                        <Input
                          type="number"
                          className="form-input-number-addonAfter"
                          addonAfter={prefixSelector}
                          step="any"
                        />
                      </Form.Item>
                    </>
                  )}
                  {!this.props.AddBuyer && !this.props.BuyerProfile && (
                    <>
                      <div className="form-titles">
                        Garage (leave blank if none)
                      </div>
                      <Form.Item
                        name="garageSpace"
                        initialValue={
                          window.location.pathname ===
                            `/add/sellerProfile/${this.props.match.params.id}` ||
                          window.location.pathname === "/add/sellerProfile"
                            ? sellerHomeDetails.propertyFeatures &&
                              sellerHomeDetails.propertyFeatures.garageSpace
                            : buyerHomeDetails &&
                              buyerHomeDetails.buyerPropertyFeatures
                            ? buyerHomeDetails.buyerPropertyFeatures.garageSpace
                            : buyerUserDetails &&
                              buyerUserDetails.buyerHomeFeatures &&
                              buyerUserDetails.buyerHomeFeatures.garageSpace
                            ? buyerUserDetails.buyerHomeFeatures.garageSpace
                            : ""
                        }
                      >
                        <InputNumber className="form-input-number" />
                      </Form.Item>
                    </>
                  )}
                  {!this.props.BuyerProfile && !this.props.AddBuyer && (
                    <>
                      <div className="form-titles">Parking Spaces</div>
                      <Form.Item
                        name="parakingSpace"
                        initialValue={
                          window.location.pathname ===
                            `/add/sellerProfile/${this.props.match.params.id}` ||
                          window.location.pathname === "/add/sellerProfile"
                            ? sellerHomeDetails.propertyFeatures &&
                              sellerHomeDetails.propertyFeatures.parakingSpace
                            : buyerHomeDetails &&
                              buyerHomeDetails.buyerPropertyFeatures
                            ? buyerHomeDetails.buyerPropertyFeatures
                                .parakingSpace
                            : buyerUserDetails &&
                              buyerUserDetails.buyerHomeFeatures &&
                              buyerUserDetails.buyerHomeFeatures.parakingSpace
                            ? buyerUserDetails.buyerHomeFeatures.parakingSpace
                            : ""
                        }
                      >
                        <InputNumber className="form-input-number" />
                      </Form.Item>
                    </>
                  )}
                  <div className="form-titles">
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Cooling"
                      : "Cooling"}
                  </div>
                  <Form.Item
                    name="colling"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.colling
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures.colling
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.colling
                        ? buyerUserDetails.buyerHomeFeatures.colling
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Heating"
                      : "Heating"}
                  </div>
                  <Form.Item
                    name="heating"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.heating
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures.heating
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.heating
                        ? buyerUserDetails.buyerHomeFeatures.heating
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Basement"
                      : "Basement"}
                  </div>
                  <Form.Item
                    name="basement"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.basement
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures.basement
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.basement
                        ? buyerUserDetails.buyerHomeFeatures.basement
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Utilities"
                      : "Utilities"}
                  </div>
                  <Form.Item
                    name="utilities"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.utilities
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures.utilities
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.utilities
                        ? buyerUserDetails.buyerHomeFeatures.utilities
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Interior Features"
                      : "Interior Features"}
                  </div>
                  <Form.Item
                    name="interiorFeatures"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.interiorFeatures
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures
                            .interiorFeatures
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.interiorFeatures
                        ? buyerUserDetails.buyerHomeFeatures.interiorFeatures
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                  <div className="form-titles">
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Exterior Features"
                      : "Exterior Features"}
                  </div>
                  <Form.Item
                    name="exteriorFeatures"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.exteriorFeatures
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures
                        ? buyerHomeDetails.buyerPropertyFeatures
                            .exteriorFeatures
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.exteriorFeatures
                        ? buyerUserDetails.buyerHomeFeatures.exteriorFeatures
                        : ""
                    }
                  >
                    <Input className="form-input" />
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 23 }}
                  lg={{ span: 23 }}
                  md={{ span: 23 }}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                >
                  <>
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        fontFamily: "Poppins,sans-serif",
                      }}
                    >
                      Fireplaces
                    </div>
                    <Form.Item
                      name="firePlaces"
                      initialValue={
                        window.location.pathname ===
                          `/add/sellerProfile/${this.props.match.params.id}` ||
                        window.location.pathname === "/add/sellerProfile"
                          ? sellerHomeDetails.propertyFeatures &&
                            sellerHomeDetails.propertyFeatures.firePlaces
                          : buyerHomeDetails &&
                            buyerHomeDetails.buyerPropertyFeatures
                          ? buyerHomeDetails.buyerPropertyFeatures.firePlaces
                          : buyerUserDetails &&
                            buyerUserDetails.buyerHomeFeatures &&
                            buyerUserDetails.buyerHomeFeatures.firePlaces
                          ? buyerUserDetails.buyerHomeFeatures.firePlaces
                          : ""
                      }
                    >
                      <InputNumber className="form-input-number" />
                    </Form.Item>
                  </>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "600",
                      fontFamily: "Poppins,sans-serif",
                    }}
                  >
                    {this.props.AddBuyer || this.props.BuyerProfile
                      ? "Desired Features"
                      : "Features"}
                  </div>
                  <Form.Item
                    name="featuresOfchecked"
                    initialValue={
                      window.location.pathname ===
                        `/add/sellerProfile/${this.props.match.params.id}` ||
                      window.location.pathname === "/add/sellerProfile"
                        ? sellerHomeDetails &&
                          sellerHomeDetails.propertyFeatures &&
                          sellerHomeDetails.propertyFeatures.featuresOfchecked
                          ? sellerHomeDetails.propertyFeatures.featuresOfchecked
                          : []
                        : buyerHomeDetails &&
                          buyerHomeDetails.buyerPropertyFeatures &&
                          buyerHomeDetails.buyerPropertyFeatures
                            .featuresOfchecked
                        ? buyerHomeDetails.buyerPropertyFeatures
                            .featuresOfchecked
                        : buyerUserDetails &&
                          buyerUserDetails.buyerHomeFeatures &&
                          buyerUserDetails.buyerHomeFeatures.featuresOfchecked
                        ? buyerUserDetails.buyerHomeFeatures.featuresOfchecked
                        : []
                    }
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="You Can Select Multiple Features"
                      showSearch={window.screen.width >= "992" ? true : false}
                    >
                      {FeaturesTags.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {this.props && this.props.BuyerProfile && (
                    <>
                      <Col span={24}>
                        <div className="form-titles">
                          Description of buyer needs{" "}
                        </div>
                        <Form.Item
                          name="description"
                          initialValue={
                            buyerUserDetails &&
                            buyerUserDetails.buyerHomeFeatures &&
                            buyerUserDetails.buyerHomeFeatures.description
                              ? buyerUserDetails.buyerHomeFeatures.description
                              : ""
                          }
                        >
                          <TextArea
                            placeholder="Start typing"
                            style={{ height: 182, borderRadius: 5 }}
                          />
                        </Form.Item>
                        <Form.Item name="remember" valuePropName="checked">
                          <Checkbox>
                            <span className="form-titles">
                              Send me alerts when new on and off MLS properties
                              hit the market matching this profile
                            </span>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  {this.props && this.props.BuyerProfile && (
                    <Col
                      xl={{ span: 9 }}
                      lg={{ span: 9 }}
                      md={{ span: 9 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="update-btn"
                      >
                        Update Profile
                      </Button>
                    </Col>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>

          {this.props && !this.props.BuyerProfile && (
            <Col
              xl={{ span: 7, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              md={{ span: 24, offset: 0 }}
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
            >
              <Advertisement
                otherAds={false}
                adType="Square"
                adWhereToShow="Homes"
                adLimit="3"
                zipCode=""
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default withAppContext(withRouter(PropertyFeatures));
