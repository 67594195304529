import {
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Button,
  Spin,
  Select,
  Drawer,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { withAppContext } from "../../../store/contextStore";
import { update, getDetails, noAuthlist } from "../../../api/Apis";
import Subscriptions from "../../Subscriptions/Subscriptions";
import RichText from "../../../common/RichText";

const { Option } = Select;
class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: {
        cities: [],
        type: "",
      },
      sellerName: "",
      sellerPhone: "",
      sellerAddress: "",
      citySearch: [],
      saveDetailsFalse: false,
      zipCode: "",
      serviceAreas: [],
      spec: 0,
      zip: "",
      Ziplist: [],
      Addmore: false,
      speclist: [],
      optionsSelected: [],
      zipCount: 0,
      showSubscriptions: false,
      dataLoadingFalse: false,
      richtTextBiofield: "",
      allUsersList: [],
      referedById: "",
      referedByName: "",
      handleZipErrorMsg: false,
      agentsList: [],
      userInput: false,
      addUserName: "",
      addUserEmail: "",
      agentTowns: [],
      agentPortfolio: "",
      oldCategories: [],
      loadTotalData: false,
      updateAgentPortfolio: false,
      lendersList: [],
      lenderInfo: [],
      loadSelect: false,
      mlsStateId: "",
      mlsState: [],
      mlsSateList: [],
      mlsIdsUpdateStatus: false,
      assignAgentId: "",
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getAllUsers();
    this.getAllAgents();
    this.getRefreshInfo();
    this.getPortfoliosList();
    this.getAllLenders();
  }
  getPortfoliosList = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    try {
      const response = await noAuthlist(
        "testimonials?userId=" + userDetails.id + "&dataType=portfolio"
      );
      if (response.success) {
        if (response.data.length > 0) {
          this.setState({
            updateAgentPortfolio: true,
          });
        }
        const oldCategories = [];
        response.data.map((eachPortfolio) =>
          oldCategories.push({
            label: eachPortfolio.portfolioName,
            id: eachPortfolio.id,
          })
        );
        this.setState({
          oldCategories,
          loadTotalData: true,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
    }
  };
  getRefreshInfo = () => {
    const id = this.props.match.params.id;
    if (id) {
      getDetails("users", id)
        .then((responce) => {
          this.setState({
            userDetails: responce.data,
          });
          if (
            responce.data &&
            responce.data.newdata &&
            responce.data.newdata.serviceAreas
          ) {
            const serviceAreas = responce.data.newdata.serviceAreas.filter(
              (checkObject) => typeof checkObject !== "string"
            );
            this.setState({
              serviceAreas: serviceAreas,
            });
          }

          if (
            responce.data &&
            responce.data.mlsSateList &&
            responce.data.mlsSateList.length > 0
          ) {
            this.setState({
              mlsSateList: responce.data.mlsSateList,
            });
          }

          if (
            responce.data &&
            responce.data.newdata &&
            responce.data.newdata.Specialties
          ) {
            this.setState({
              optionsSelected: responce.data.newdata.Specialties,
            });
          }
          if (
            responce.data &&
            responce.data.agentInfo &&
            responce.data.agentInfo.agentEmail &&
            responce.data.agentInfo.agentName
          ) {
            this.setState({
              addUserEmail: responce.data.agentInfo.agentEmail,
              addUserName: responce.data.agentInfo.agentName,
            });
          }

          if (
            responce.data &&
            responce.data.newdata &&
            responce.data.newdata.zipCodes &&
            responce.data.newdata.zipCodes
          ) {
            this.setState({
              Ziplist: responce.data.newdata.zipCodes,
            });
          }

          if (
            responce.data &&
            responce.data.newdata &&
            responce.data.newdata.agentTowns &&
            responce.data.newdata.agentTowns.length > 0
          ) {
            this.setState({
              agentTowns: responce.data.newdata.agentTowns,
            });
          }
          if (responce.data && responce.data.lenderInfo) {
            this.setState({
              lenderInfo: responce.data.lenderInfo,
              loadSelect: true,
            });
          } else {
            this.setState({
              loadSelect: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getAllUsers = async () => {
    try {
      const usersList = await noAuthlist("getUsers", { isActive: true });
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
        };
      });
      if (usersList.success) {
        this.setState({
          allUsersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };
  getAllLenders = async () => {
    try {
      const usersList = await noAuthlist(
        "getUsers?userProfile=L&isActive=true"
      );
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
          email: user.email,
        };
      });
      if (usersList.success) {
        this.setState({
          lendersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  getAllAgents = async () => {
    try {
      const orgId = JSON.parse(localStorage.getItem("orgId"));
      let query = {
        orgId,
        isActive: true,
      };
      const AllagentsList = await noAuthlist("getAgents", query);
      let singleAgent = AllagentsList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
          email: user.email,
        };
      });
      if (AllagentsList.success) {
        this.setState({
          agentsList: singleAgent,
        });
      } else {
        notification["error"]({
          message: AllagentsList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  handleZip = (value) => {
    this.setState({ zip: value.target.value, multiZip: value.target.value });
    if (this.state.Ziplist.includes(value.target.value)) {
      this.setState({
        handleZipErrorMsg: true,
      });
      notification["error"]({
        message:
          "You have already added " +
          value.target.value +
          " zip code. Please add another",
        // description: "You have already added " + this.state.zip + " zip code",
      });
    }
    //  else {
    //   const Ziplist = [...this.state.Ziplist];
    //   Ziplist.push(value.target.value);
    //   this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    // }
  };

  handleSellereProfile = async (name, value) => {
    await this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onFinish = (e) => {
    window.addEventListener("keydown", function (event) {
      //set default value for variable that will hold the status of keypress
      // pressedEnter = false;
      //if user pressed enter, set the variable to true
      // if (event.keyCode == 13)
      //   pressedEnter = true;
      // //we want forms to disable submit for a tenth of a second only
      // setTimeout(function() {
      //   pressedEnter = false;
      // }, 100)
    });
    this.updateDetails(e);
  };

  handleRichtextBio = (bio) => {
    this.setState({
      richtTextBiofield: bio,
    });
  };

  updateDetails = async (data2) => {
    // if (
    //   this.props.context.state &&
    //   this.props.context.state.userDetails &&
    //   this.props.context.state.userDetails.userProfile === "B" &&
    //   this.state.serviceAreas !== "" &&
    //   this.state.serviceAreas.length == 0
    // ) {
    //   notification["error"]({
    //     message: "Towns of Interest",
    //     description: "Please select atleast one town",
    //   });
    //   return;
    // }

    this.setState({
      saveDetailsFalse: true,
    });

    const { richtTextBiofield, assignAgentId } = this.state;
    const { userDetails } = this.props.context.state;
    const id = userDetails.id;
    data2["profilePicture"] =
      this.props.fileList.length > 0
        ? this.props.fileList
        : userDetails.profilePicture
        ? userDetails.profilePicture
        : "";
    data2["profileUrl"] = this.props.profileImage
      ? this.props.profileImage
      : userDetails.profileUrl
      ? userDetails.profileUrl
      : "";
    data2["address"] =
      this.state.citySearch.length > 0
        ? this.state.citySearch
        : userDetails.newdata && userDetails.newdata.companyAddress;
    data2["postalCode"] = this.state.zipCode ? this.state.zipCode : "";

    let agentInfo = {
      agentName: this.state.addUserName,
      agentEmail: this.state.addUserEmail,
      assignAgentId,
    };

    const data = Object.assign(
      {},
      {
        entityData: {
          newdata: {
            agentTowns: this.state.agentTowns,
            zipCodes: this.state.Ziplist ? this.state.Ziplist : "",
            whatsapp: data2.whatsapp ? data2.whatsapp : "",
            serviceAreas: this.state.serviceAreas
              ? this.state.serviceAreas
              : "",
            titleorPosition: data2.titleorPosition ? data2.titleorPosition : "",
            mobile: data2.mobile ? data2.mobile : "",
            language: data2.language ? data2.language : "",
            faxNumber: data2.faxNumber ? data2.faxNumber : "",
            Specialties: this.state.optionsSelected
              ? this.state.optionsSelected
              : "",
            TaxNumber: data2.TaxNumber ? data2.TaxNumber : "",
            companyName: data2.companyName ? data2.companyName : "",
            companyAddress: data2.address ? data2.address : "",
            officeZipCode: data2.postalCode,
            website: data2.website ? data2.website : "",
            hobbies: data2?.hobbies ? data2?.hobbies : "",
          },
          email: data2.email ? data2.email : "",
          firstName: data2.firstName ? data2.firstName : "",
          lastName: data2.lastName ? data2.lastName : "",
          mlsId: data2.mlsId ? data2.mlsId.toUpperCase() : "",
          phone: data2.phone ? data2.phone : "",
          profilePicture: data2.profilePicture ? data2.profilePicture : "",
          profileUrl: data2.profileUrl ? data2.profileUrl : "",
          userBio: richtTextBiofield ? richtTextBiofield : "",
          referedByName: this.state.referedByName,
          referedById: this.state.referedById,
          licenseIds: data2.licenseIds ? data2.licenseIds : "",
          agentInfo: agentInfo,
          addUserName: this.state.addUserName,
          lenderInfo: this.state.lenderInfo,
          mlsSateList: this.state.mlsSateList,
          mlsIdsUpdateStatus: this.state.mlsIdsUpdateStatus,
          isActive: true,
        },
      }
    );
    if (!this.state.updateAgentPortfolio) {
      data.entityData["portfolioName"] = ["My portfolio"];
    }

    // const removeCategory = this.state.oldCategories.filter(
    //   (removeItem) => ![this.state.agentPortfolio].includes(removeItem.label)
    // );
    // removeCategory &&
    //   removeCategory.map((removeWithId) => {
    //     deleteRecord("testimonials", removeWithId.id)
    //       .then((response) => {
    //         if (response.success) {
    //         } else {
    //           notification["error"]({
    //             message: response.message,
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   });
    if (data.entityData.email && data.entityData.firstName) {
      const updatingUserDetails = await update("users", id, data);
      if (updatingUserDetails.success) {
        this.setState({
          editing: false,
          saveDetailsFalse: false,
        });
        notification["success"]({
          message: "Your profile has been updated",
          // description: "Your profile details updated successfully",
          duration: 3,
        });
      }
      const userProfileDetails = await getDetails("users", id);
      localStorage.removeItem("userDetails");
      localStorage.setItem(
        "userDetails",
        JSON.stringify(userProfileDetails.data)
      );
      this.props.context.setUserDetails(userProfileDetails.data);
    }
  };

  handleZipCode = (address) => {
    let companyAddress = address.label.split(",");
    const citySearch = companyAddress;
    this.setState({ citySearch });
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "postal_code") {
            this.setState({
              zipCode: address[i].long_name,
            });
          }
        }
      })
      .catch((error) => console.error("Error", error));
  };

  handleServiceAreas = (address) => {
    let citiesList =
      this.state.serviceAreas.length > 0 ? this.state.serviceAreas : [];
    let stateName = "";
    let cityValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateName = address[i].short_name;
          }
        }
        // const stateCodes = JSON.parse(
        //   localStorage.getItem("stateCodesFromAdmin")
        // );
        // let setVendor = stateCodes.filter(
        //   (restState) =>
        //     restState.stateName.toLowerCase() === stateName.toLowerCase() ||
        //     restState.stateType.toLowerCase() === stateName.toLowerCase()
        // );

        // if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateName,
            type: "stateName",
          };
          if (newSearchLocation) {
            citiesList.push(newSearchLocation);
          }
          this.setState({
            serviceAreas: citiesList,
          });
        // } else {
        //   notification["error"]({
        //     message: "Please enter valid city...",
        //     description: "You can search cities from MA, CT, RI, NH states",
        //     placement: "topLeft",
        //   });
        // }
      })
      .catch((error) => console.error("Error", error));
  };

  onReferedBySelect = (value, option) => {
    this.setState({
      referedById: option.value,
      referedByName: option.label,
    });
  };

  onReferedBySelectLender = (value, option) => {
    this.setState({
      lenderInfo: value,
    });
  };

  onReferedBySelectAgent = (value, option) => {
    this.setState({
      addUserEmail: option.email,
      addUserName: option.label,
      assignAgentId: option.value,
    });
  };

  onSelect = (result) => {
    const address = result.label.split(",");
    let vara = this.state.cities.cities;
    vara.push(address[0]);
    this.setState({
      cities: {
        cities: vara,
        type: "cities",
      },
    });
  };
  onClose = (value) => {
    const data =
      this.state.serviceAreas &&
      this.state.serviceAreas.filter((city) => city !== value);
    this.setState({
      serviceAreas: data,
    });
  };

  onCloseMlsSate = (value) => {
    const data =
      this.state.mlsSateList &&
      this.state.mlsSateList.filter((id) => id !== value);
    this.setState({
      mlsSateList: data,
    });
  };

  handleChange = (value) => {
    if (this.state.Ziplist.includes(this.state.zip)) {
      notification["error"]({
        message:
          "You have already added " +
          this.state.zip +
          " zip code. Please add another",
        // description: "You have already added " + this.state.zip + " zip code",
      });
    } else {
      this.handleZipTowns(this.state.zip);
      const Ziplist = [...this.state.Ziplist];
      Ziplist.push(this.state.zip);
      this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    }
  };

  handleZipTowns = (address) => {
    try {
      geocodeByAddress(address)
        .then((results) => {
          let address = results[0].address_components;

          let agentTownArray =
            this.state.agentTowns.length > 0 ? this.state.agentTowns : [];
          let townInfo = {
            cityName: "",
            stateName: "",
            zipCode: "",
          };

          for (let i = 0; i < address.length; i++) {
            if (address[i].types[0] === "locality") {
              townInfo["cityName"] = address[i].long_name;
            }
            if (address[i].types[0] === "administrative_area_level_1") {
              townInfo["stateName"] = address[i].short_name;
            }
            if (address[i].types[0] === "postal_code") {
              townInfo["zipCode"] = address[i].long_name;
            }
          }
          agentTownArray.push(townInfo);
          this.setState({
            agentTowns: agentTownArray,
          });
        })
        .catch((error) => console.error("Error", error));
    } catch (error) {
      console.error("Error", error);
    }
  };

  handleDeselect = (value) => {
    this.handleDeselectZipTowns(value);
    const deleteItem = this.state.Ziplist.indexOf(value);
    let presentItems = this.state.Ziplist.splice(deleteItem, 1);
    this.setState({ zipCount: presentItems.length });
  };

  handleDeselectZipTowns = (value) => {
    let tempAgentTowns = this.state.agentTowns;
    let result = tempAgentTowns.filter(
      (agentTownInfo) => !agentTownInfo.zipCode.includes(value)
    );
    this.setState({
      agentTowns: result,
    });
  };

  handlespecialties = (value) => {
    this.setState({ optionsSelected: value });
    if (
      value.length === this.props &&
      this.props.userDetails &&
      this.props.userDetails.subscriptionPack &&
      this.props.userDetails.subscriptionPack.specialites &&
      this.props.userDetails.subscriptionPack.specialites
    ) {
      notification.info({
        message: "Please upgrade to add more specialites",
      });
    }
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.setState({
      showSubscriptions: true,
    });
  };

  setLoadingfalse = () => {
    this.setState({
      dataLoadingFalse: false,
    });
  };
  handleAddUser = () => {
    const { addUserName, addUserEmail } = this.state;
    if (addUserName !== "" && addUserEmail !== "") {
      let agentsList = this.state.agentsList;
      agentsList.push({
        label: addUserName,
        email: addUserEmail,
        value: addUserName,
      });
      this.setState({
        agentsList,
      });
    }
    this.setState({
      userInput: true,
    });
  };

  handleAddMlsSate = () => {
    const { mlsState, mlsStateId } = this.state;
    let mlsSateList = [];
    let checkData;
    this.state.mlsSateList &&
      this.state.mlsSateList.map((id) => {
        if (mlsStateId === id.id && mlsState === id.state) {
          checkData = true;
        }
        return true;
      });

    if (checkData) {
      notification["error"]({
        message:
          "You have already added " +
          mlsState +
          " state and " +
          mlsStateId +
          " zip code. Please add another",
      });
      this.setState({
        mlsStateId: "",
      });
    } else if ((mlsState && mlsState.length === 0) || mlsStateId === "") {
      notification["error"]({
        message: "Please input mlsState and mlsId",
      });
    } else {
      mlsSateList.push(...this.state.mlsSateList, {
        id: mlsStateId,
        state: mlsState,
      });
      this.setState({
        mlsSateList,
        mlsStateId: "",
        mlsIdsUpdateStatus: true,
      });
    }
  };

  handleMlsId = (e) => {
    this.setState({
      mlsStateId: e.target.value,
    });
  };

  handleMlsSate = (value) => {
    this.setState({
      mlsState: value,
    });
  };

  handleaddUserName = (e) => {
    this.setState({
      addUserName: e.target.value,
    });
  };

  handleaddUserEmail = (e) => {
    this.setState({
      addUserEmail: e.target.value,
    });
  };

  handleAgentPortFolio = (agentPortfolio) => {
    this.setState({
      agentPortfolio,
    });
  };

  render() {
    const {
      saveDetailsFalse,
      serviceAreas,
      optionsSelected,
      showSubscriptions,
      addUserEmail,
      addUserName,
      userInput,
      userDetails,
      mlsStateId,
      mlsState,
    } = this.state;

    const subscriptionPack = JSON.parse(
      localStorage.getItem("subscriptionPack")
    );
    // console.log(subscriptionPack.zipcodes, this.state.Ziplist.length);
    const opts = [
      { label: "Buyer representation", value: "Buyer representation" },
      { label: "Seller representation", value: "Seller representation" },
      { label: "Downsizing", value: "Downsizing" },
      { label: "Upsizing", value: "Upsizing" },
      { label: "Relocation", value: "Relocation" },
      { label: "Investment properties", value: "Investment properties" },
      { label: "Flipping", value: "Flipping" },
      { label: "1031 exchanges", value: "1031 exchanges" },
      { label: "Commercial", value: "Commercial" },
      { label: "Land", value: "Land" },
      { label: "New Construction", value: "New Construction" },
      { label: "Builder representation", value: "Builder representation" },
      { label: "waterfront properties", value: "waterfront properties" },
      { label: "Farm/horse properties", value: "Farm/horse properties" },
      { label: "Rentals", value: "Rentals" },
      { label: "First Time Buyers", value: "First Time Buyers" },
      { label: "Short Sales", value: "Short Sales" },
      { label: "Business Representation", value: "Business Representation" },
      { label: "Over 55/Active Adult", value: "Over 55/Active Adult" },
      { label: "Bank Owned", value: "Bank Owned" },
    ];

    const mlsStates = [
      { label: "Connecticut", value: "CT" },
      { label: "Rhode Island", value: "RI" },
      { label: "New Hampshire", value: "NH" },
      { label: "Massachusetts", value: "MA" },
      { label: "Alabama", value: "AL" },
      { label: "Alaska", value: "AK" },
      { label: "Arizona", value: "AZ" },
      { label: "Arkansas", value: "AR" },
      { label: "Colorado", value: "CO" },
      { label: "California", value: "CA" },
      { label: "Delaware", value: "DE" },
      { label: "Florida", value: "FL" },
      { label: "Georgia", value: "GA" },
      { label: "Hawaii", value: "HI" },
      { label: "Idaho", value: "ID" },
      { label: "Illinois", value: "IL" },
      { label: "Indiana", value: "IN" },
      { label: "Iowa", value: "IA" },
      { label: "Kansas", value: "KS" },
      { label: "Kentucky", value: "KY" },
      { label: "Louisiana", value: "LA" },
      { label: "Maine", value: "ME" },
      { label: "Maryland", value: "MD" },
      { label: "Michigan", value: "MI" },
      { label: "Minnesota", value: "MN" },
      { label: "Mississippi", value: "MS" },
      { label: "Missouri", value: "MO" },
      { label: "Montana", value: "MT" },
      { label: "Nebraska", value: "NE" },
      { label: "Nevada", value: "NV" },
      { label: "New Jersey", value: "NJ" },
      { label: "New Mexico", value: "NM" },
      { label: "New York", value: "NY" },
      { label: "North Carolina", value: "NC" },
      { label: "North Dakota", value: "ND" },
      { label: "Ohio", value: "OH" },
      { label: "Oklahoma", value: "OK" },
      { label: "Oregon", value: "OR" },
      { label: "Pennsylvania", value: "PA" },
      { label: "South Carolina", value: "SC" },
      { label: "South Dakota", value: "SD" },
      { label: "Tennessee", value: "TN" },
      { label: "Texas", value: "TX" },
      { label: "Utah", value: "UT" },
      { label: "Vermont", value: "VT" },
      { label: "Virginia", value: "VA" },
      { label: "Washington", value: "WA" },
      { label: "West Virginia", value: "WV" },
      { label: "Wisconsin", value: "WI" },
      { label: "Wyoming", value: "WY" },
    ];

    return (
      <>
        <Form
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          size="large"
        >
          {userDetails ? (
            <Spin spinning={saveDetailsFalse}>
              <Row>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 11, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="label-titles">First Name</div>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Full Name!",
                      },
                    ]}
                    initialValue={
                      userDetails
                        ? userDetails.firstName
                          ? userDetails.firstName
                          : ""
                        : ""
                    }
                  >
                    <Input placeholder="First Name" className="form-input" />
                  </Form.Item>

                  <div className="label-titles">Phone</div>
                  <Form.Item
                    name="phone"
                    initialValue={
                      userDetails
                        ? userDetails.phone
                          ? userDetails.phone
                          : ""
                        : ""
                    }
                  >
                    <InputNumber
                      placeholder="Enter your phone number"
                      className="form-input-number"
                      formatter={(value) => {
                        const cleaned = `${value}`.replace(/\D/g, "");
                        const match = cleaned.match(
                          /^(1|)?(\d{3})(\d{3})(\d{4})$/
                        );
                        if (match) {
                          const intlCode = match[1] ? "+1 " : "";
                          return [
                            intlCode,
                            "(",
                            match[2],
                            ") ",
                            match[3],
                            "-",
                            match[4],
                          ].join("");
                        }
                        return null;
                      }}
                    />
                  </Form.Item>
                  {(userDetails && userDetails.userProfile === "B") ||
                  (userDetails && userDetails.userProfile === "S") ? (
                    <>
                      <div className="label-titles">Referred by</div>
                      <Form.Item name="referedBy">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          options={this.state.allUsersList}
                          onSelect={this.onReferedBySelect}
                          placeholder="Referred by"
                          defaultValue={
                            userDetails &&
                            userDetails.referedByName &&
                            userDetails.referedByName !== ""
                              ? userDetails.referedByName
                              : ""
                          }
                          filterOption={(inputValue, option) =>
                            option.label
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        ></Select>
                      </Form.Item>
                    </>
                  ) : (
                    ""
                  )}
                  {userDetails &&
                    userDetails.userProfile !== "B" &&
                    userDetails.userProfile !== "S" && (
                      <>
                        <div className="label-titles">Company Name</div>
                        <Form.Item
                          name="companyName"
                          initialValue={
                            userDetails
                              ? userDetails.newdata
                                ? userDetails.newdata.companyName &&
                                  userDetails.newdata.companyName
                                : ""
                              : ""
                          }
                        >
                          <Input
                            placeholder="Enter your company name"
                            className="form-input"
                          />
                        </Form.Item>
                      </>
                    )}
                  {userDetails &&
                    userDetails.userProfile !== "B" &&
                    userDetails.userProfile !== "S" && (
                      <>
                        <div className="label-titles">Mobile</div>
                        <Form.Item
                          name="mobile"
                          initialValue={
                            userDetails
                              ? userDetails.newdata
                                ? userDetails.newdata.mobile
                                : ""
                              : ""
                          }
                        >
                          <InputNumber
                            className="form-input-number"
                            placeholder="mobile"
                            formatter={(value) => {
                              const cleaned = `${value}`.replace(/\D/g, "");
                              const match = cleaned.match(
                                /^(1|)?(\d{3})(\d{3})(\d{4})$/
                              );
                              if (match) {
                                const intlCode = match[1] ? "+1 " : "";
                                return [
                                  intlCode,
                                  "(",
                                  match[2],
                                  ") ",
                                  match[3],
                                  "-",
                                  match[4],
                                ].join("");
                              }
                              return null;
                            }}
                          />
                        </Form.Item>
                      </>
                    )}
                  {userDetails &&
                    userDetails.userProfile !== "B" &&
                    userDetails.userProfile !== "S" && (
                      <>
                        <div className="label-titles">Title or Position</div>
                        <Form.Item
                          name="titleorPosition"
                          initialValue={
                            userDetails
                              ? userDetails.newdata
                                ? userDetails.newdata.titleorPosition
                                : ""
                              : ""
                          }
                        >
                          <Input
                            placeholder="Enter your title or position"
                            className="form-input"
                          />
                        </Form.Item>

                        <div className="label-titles">Fax Number</div>
                        <Form.Item
                          name="faxNumber"
                          initialValue={
                            userDetails
                              ? userDetails.newdata
                                ? userDetails.newdata.faxNumber
                                : ""
                              : ""
                          }
                        >
                          <InputNumber
                            placeholder="Enter your Fax number"
                            className="form-input-number"
                          />
                        </Form.Item>
                      </>
                    )}
                  {userDetails &&
                    (userDetails.userProfile === "AG" ||
                      userDetails.userProfile === "concierge") && (
                      <>
                        <div className="label-titles">
                          Languages (Separate with commas)
                        </div>
                        <Form.Item
                          name="language"
                          initialValue={
                            userDetails
                              ? userDetails.newdata
                                ? userDetails.newdata.language
                                : ""
                              : ""
                          }
                        >
                          <Input
                            placeholder="English,Spanish,French"
                            className="form-input"
                          />
                        </Form.Item>
                      </>
                    )}
                </Col>

                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 11, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="label-titles">Last Name</div>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Full Name!",
                      },
                    ]}
                    initialValue={
                      userDetails
                        ? userDetails.lastName
                          ? userDetails.lastName
                          : ""
                        : ""
                    }
                  >
                    <Input
                      placeholder="Last Name"
                      value=""
                      className="form-input"
                    />
                  </Form.Item>

                  <div className="label-titles">Email</div>
                  <Form.Item
                    name="email"
                    initialValue={
                      userDetails
                        ? userDetails.email
                          ? userDetails.email
                          : ""
                        : ""
                    }
                  >
                    <Input
                      readOnly={true}
                      placeholder="email"
                      className="form-input"
                    />
                  </Form.Item>
                  {(userDetails && userDetails.userProfile === "B") ||
                  (userDetails && userDetails.userProfile === "S") ? (
                    <>
                      <div className="label-titles">Add Agent</div>
                      <Form.Item name="AddAgent">
                        <Select
                          className="input-width"
                          showSearch
                          optionFilterProp="children"
                          options={this.state.agentsList}
                          onSelect={this.onReferedBySelectAgent}
                          defaultValue={
                            userDetails &&
                            userDetails.addUserName &&
                            userDetails.addUserName !== ""
                              ? userDetails.addUserName
                              : ""
                          }
                          filterOption={(inputValue, option) =>
                            option.label
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                              ? this.handleAddUser
                              : ""
                          }
                        ></Select>
                        <Button
                          type="primary"
                          onClick={this.handleAddUser}
                          className="add-user-width"
                          icon={<PlusOutlined />}
                        >
                          Add User
                        </Button>
                      </Form.Item>
                      {userInput && (
                        <>
                          <Row>
                            <Col span={11}>
                              <div className="label-titles">Agent Name</div>
                              <Form.Item
                                name="AgentName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Agent Name!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Agent Name"
                                  type="name"
                                  value={addUserName}
                                  onChange={this.handleaddUserName}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={11} offset={1}>
                              <div className="label-titles">Agent Email</div>
                              <Form.Item
                                name="AgentEmail"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Agent Email!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Agent Email"
                                  type="email"
                                  value={addUserEmail}
                                  onChange={this.handleaddUserEmail}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {userDetails &&
                    (userDetails.userProfile === "AG" ||
                      userDetails.userProfile === "concierge") && (
                      <>
                        <div className="label-titles">Company Address</div>
                        <Form.Item
                          name="address"
                          className="google-form-search"
                        >
                          <GooglePlacesAutocomplete
                            className="form-input"
                            apiKey={process.env.REACT_APP_MAPS_KEY}
                            autocompletionRequest={{
                              // types: ["(cities)"],
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              defaultInputValue: `${
                                userDetails && userDetails.newdata
                                  ? userDetails.newdata.companyAddress
                                  : ""
                              }`,
                              placeholder: "Type Company Address",
                              onChange: (result) => {
                                this.handleZipCode(result);
                              },
                            }}
                          />
                        </Form.Item>

                        <div className="label-titles">Website</div>
                        <Form.Item
                          name="website"
                          initialValue={
                            userDetails
                              ? userDetails.newdata &&
                                userDetails.newdata.website
                                ? userDetails.newdata.website
                                : ""
                              : ""
                          }
                        >
                          <Input placeholder="website" className="form-input" />
                        </Form.Item>

                        <div className="label-titles">Hobbies</div>
                        <Form.Item
                          name="hobbies"
                          initialValue={
                            userDetails
                              ? userDetails?.newdata
                                ? userDetails.newdata?.hobbies &&
                                  userDetails.newdata.hobbies
                                : ""
                              : ""
                          }
                        >
                          <Input placeholder="Hobbies" className="form-input" />
                        </Form.Item>
                      </>
                    )}

                  {userDetails &&
                    userDetails.userProfile !== "B" &&
                    userDetails.userProfile !== "S" && (
                      <>
                        <div className="label-titles">WhatsApp</div>
                        <Form.Item
                          name="whatsapp"
                          initialValue={
                            userDetails
                              ? userDetails.newdata &&
                                userDetails.newdata.whatsapp
                                ? userDetails.newdata.whatsapp
                                : ""
                              : ""
                          }
                        >
                          <InputNumber
                            placeholder="Enter whatsApp number with country code"
                            className="form-input-number"
                            formatter={(value) => {
                              const cleaned = `${value}`.replace(/\D/g, "");
                              const match = cleaned.match(
                                /^(1|)?(\d{3})(\d{3})(\d{4})$/
                              );
                              if (match) {
                                const intlCode = match[1] ? "+1 " : "";
                                return [
                                  intlCode,
                                  "(",
                                  match[2],
                                  ") ",
                                  match[3],
                                  "-",
                                  match[4],
                                ].join("");
                              }
                              return null;
                            }}
                          />
                        </Form.Item>
                      </>
                    )}
                  {userDetails &&
                    (userDetails.userProfile === "AG" ||
                      userDetails.userProfile === "concierge") && (
                      <>
                        {/* <div className="label-titles">MLS id</div>
                      <Form.Item
                        name="mlsId"
                        initialValue={
                          userDetails
                            ? userDetails.mlsId
                              ? userDetails.mlsId
                              : ""
                            : ""
                        }
                      >
                        <Input
                          placeholder="MLS id"
                          style={{ textTransform: "uppercase" }}
                          className="form-input"
                        />
                      </Form.Item> */}
                        <div className="label-titles">
                          License IDs (if multiple, separate with commas)
                        </div>
                        <Form.Item
                          name="licenseIds"
                          initialValue={
                            userDetails
                              ? userDetails.licenseIds
                                ? userDetails.licenseIds
                                : ""
                              : ""
                          }
                        >
                          <Input
                            placeholder="License IDs"
                            className="form-input"
                            // placeholder="Add state-lic# example 'MA-9517648, CT-9492229'"
                          />
                        </Form.Item>
                        <div className="label-titles">Lenders list</div>
                        <Form.Item name="lenderslist">
                          {this.state.lendersList.length > 0 &&
                            this.state.loadSelect && (
                              <Select
                                mode="multiple"
                                showSearch
                                optionFilterProp="children"
                                // options={this.state.lendersList}
                                onChange={this.onReferedBySelectLender}
                                // onDeselect={() =>
                                //   this.onReferedByDeSelectLender(
                                //     this.state.lenderInfo
                                //   )
                                // }
                                placeholder="lenders list"
                                defaultValue={this.state.lenderInfo}
                                filterOption={(inputValue, option) =>
                                  option.label
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                                }
                              >
                                {this.state.lendersList.map((item) => (
                                  <Option
                                    disabled={
                                      this.state.lenderInfo &&
                                      this.state.lenderInfo.length === 2
                                        ? this.state.lenderInfo.includes(
                                            item.email
                                          )
                                          ? false
                                          : true
                                        : false
                                    }
                                    key={item.email}
                                  >
                                    {item.label}
                                  </Option>
                                ))}
                              </Select>
                            )}
                        </Form.Item>
                      </>
                    )}
                </Col>
                {userDetails &&
                  userDetails.userProfile !== "B" &&
                  userDetails.userProfile !== "S" &&
                  userDetails.userProfile !== "AG" &&
                  userDetails.userProfile !== "concierge" && (
                    <Col
                      xl={{ span: 23, offset: 1 }}
                      lg={{ span: 23, offset: 1 }}
                      md={{ span: 23, offset: 1 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <div className="label-titles">Referred by</div>
                      <Form.Item name="referedBy">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          options={this.state.allUsersList}
                          onSelect={this.onReferedBySelect}
                          placeholder="Referred by"
                          defaultValue={
                            userDetails &&
                            userDetails.referedByName &&
                            userDetails.referedByName !== ""
                              ? userDetails.referedByName
                              : ""
                          }
                          filterOption={(inputValue, option) =>
                            option.label
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        ></Select>
                      </Form.Item>
                    </Col>
                  )}
                {userDetails &&
                  (userDetails.userProfile === "AG" ||
                    userDetails.userProfile === "concierge") && (
                    <>
                      <Col
                        xl={{ span: 11, offset: 1 }}
                        lg={{ span: 11, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <div className="label-titles">Add your state</div>

                        <Form.Item name="mlsState">
                          <Select
                            options={mlsStates}
                            optionFilterProp="children"
                            onChange={this.handleMlsSate}
                            placeholder="Add your state"
                            allowClear
                            defaultValue={mlsState}
                            showSearch
                            filterOption={(inputValue, option) =>
                              option.label
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xl={{ span: 11, offset: 1 }}
                        lg={{ span: 11, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <div className="label-titles">MLS id</div>

                        <Form.Item
                          name="mlsStateId"
                          initialValue={
                            userDetails
                              ? userDetails.mlsSateList
                                ? userDetails.mlsId
                                : ""
                              : ""
                          }
                        >
                          <Input
                            placeholder="MLS id"
                            style={{ textTransform: "uppercase" }}
                            className="form-input input-width"
                            onChange={this.handleMlsId}
                            value={mlsStateId}
                          />
                          <Button
                            type="primary"
                            onClick={this.handleAddMlsSate}
                            className="add-user-width"
                            icon={<PlusOutlined />}
                          >
                            Add MLS Id
                          </Button>
                        </Form.Item>
                      </Col>

                      <Col
                        xl={{ span: 23, offset: 1 }}
                        lg={{ span: 23, offset: 1 }}
                        md={{ span: 23, offset: 1 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                        style={{ marginTop: "-15px", marginBottom: "10px" }}
                      >
                        {this.state.mlsSateList &&
                          this.state.mlsSateList.length > 0 &&
                          this.state.mlsSateList.map((id, state) => (
                            <div
                              style={{
                                display: "inline-flex",
                                margin: "0.73vw",
                                padding: "0 7px",
                                flexWrap: "wrap",
                                justifyContent: "space-around",
                                border: "1px solid darkgrey",
                                marginLeft: "0px",
                              }}
                            >
                              <div style={{ textTransform: "uppercase" }}>
                                {id.state + " - " + id.id}
                              </div>
                              <div
                                style={{
                                  color: "grey",
                                  cursor: "pointer",
                                  marginLeft: "0.4vw",
                                }}
                                onClick={() => this.onCloseMlsSate(id)}
                              >
                                x
                              </div>
                            </div>
                          ))}
                      </Col>
                    </>
                  )}

                {userDetails &&
                  (userDetails.userProfile === "AG" ||
                    userDetails.userProfile === "concierge") && (
                    <Col
                      xl={{ span: 23, offset: 1 }}
                      lg={{ span: 23, offset: 1 }}
                      md={{ span: 23, offset: 1 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      // style={{ marginTop: "-15px", marginBottom: "10px" }}
                    >
                      <div className="label-titles">Referred by</div>
                      <Form.Item name="referedBy">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          options={this.state.allUsersList}
                          onSelect={this.onReferedBySelect}
                          placeholder="Referred by"
                          defaultValue={
                            userDetails &&
                            userDetails.referedByName &&
                            userDetails.referedByName !== ""
                              ? userDetails.referedByName
                              : ""
                          }
                          filterOption={(inputValue, option) =>
                            option.label
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        ></Select>
                      </Form.Item>
                    </Col>
                  )}

                {userDetails &&
                  userDetails.userProfile !== "B" &&
                  userDetails.userProfile !== "S" && (
                    <Col
                      xl={{ span: 23, offset: 1 }}
                      lg={{ span: 23, offset: 1 }}
                      md={{ span: 23, offset: 1 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      {userDetails && userDetails.userProfile !== "B" ? (
                        <div className="label-titles">
                          List 3-4 towns or counties you consider yourself to be
                          an expert in
                        </div>
                      ) : (
                        <div className="label-titles">
                          Towns of Interest (You can choose multiple towns that
                          you are interested in. Select one at a time.)
                        </div>
                      )}
                      <Form.Item
                        name="serviceAreas"
                        initialValue={
                          userDetails
                            ? userDetails.newdata &&
                              userDetails.newdata.serviceAreas
                              ? userDetails.newdata.serviceAreas
                              : this.state.serviceAreas
                            : this.state.serviceAreas
                        }
                        className="google-form-search-service-area"
                      >
                        <GooglePlacesAutocomplete
                          apiKey={process.env.REACT_APP_MAPS_KEY}
                          autocompletionRequest={{
                            types: ["(cities)"],
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            value: "",
                            placeholder:
                              userDetails && userDetails.userProfile !== "B"
                                ? "Enter your service areas"
                                : "Enter your interseted town",
                            onChange: (result) => {
                              this.handleServiceAreas(result);
                            },
                          }}
                        ></GooglePlacesAutocomplete>
                        {serviceAreas &&
                          serviceAreas.map((city, index) => (
                            <>
                              {typeof city === "object" && (
                                <div
                                  key={index}
                                  style={{
                                    display: "inline-flex",
                                    margin: "0.73vw",
                                    padding: "0 7px",
                                    flexWrap: "wrap",
                                    justifyContent: "space-around",
                                    border: "1px solid darkgrey",
                                    marginLeft: "0px",
                                  }}
                                >
                                  {city.cityName && (
                                    <>
                                      <div>
                                        {city.cityName}, {city.stateName}
                                      </div>
                                      <div
                                        style={{
                                          color: "grey",
                                          cursor: "pointer",
                                          marginLeft: "0.4vw",
                                        }}
                                        onClick={() => this.onClose(city)}
                                      >
                                        x
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          ))}
                      </Form.Item>
                    </Col>
                  )}

                <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {userDetails &&
                    (userDetails.userProfile === "AG" ||
                      userDetails.userProfile === "concierge") && (
                      <>
                        <div className="label-titles">
                          Zip codes serviced
                          {subscriptionPack && subscriptionPack.zipcodes && (
                            <p
                              style={{
                                float: "right",
                                marginBottom: "0px",
                                color: "red",
                              }}
                            >
                              {subscriptionPack.zipcodes -
                                this.state.Ziplist.length}{" "}
                              Zipcodes left
                            </p>
                          )}
                        </div>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Form.List
                            name="multiZipCodesList"
                            initialValue={this.state.zip}
                          >
                            {(fields, { add, remove }, { errors }) => (
                              <>
                                <Form.Item
                                  name="zip"
                                  rules={[
                                    {
                                      required: this.state.handleZipErrorMsg,
                                      message: "Please input your Username!",
                                    },
                                  ]}
                                >
                                  <Input
                                    type="number"
                                    value={this.state.zip}
                                    onChange={this.handleZip}
                                    className="form-input-profile"
                                  />
                                  {subscriptionPack &&
                                    subscriptionPack.zipcodes &&
                                    (Number(subscriptionPack.zipcodes) ===
                                    Number(this.state.Ziplist.length) ? (
                                      <Button
                                        type="default"
                                        onClick={this.handleLink}
                                        className="upgrade-add-btn"
                                        icon={<PlusOutlined />}
                                        style={{
                                          background: "red",
                                          borderColor: "red",
                                          color: "white",
                                        }}
                                      >
                                        <span
                                          className="upgrade-add-more"
                                          style={{ color: "white" }}
                                        >
                                          {" "}
                                          Upgrade to Add More
                                        </span>
                                      </Button>
                                    ) : (
                                      <Button
                                        htmlType="submit"
                                        onClick={(e) => {
                                          add();
                                          this.handleChange();
                                          e.preventDefault();
                                        }}
                                        className="upgrade-add-zip"
                                        style={{
                                          borderTopRightRadius: "5px",
                                          borderBottomRightRadius: "5px",
                                          float: "right",
                                          background: "#3045d2",
                                          borderColor: "#3045d2",
                                          color: "white",
                                          fontFamily: "Poppins",
                                          fontSize: "16px",
                                          height: "50px",
                                        }}
                                        icon={<PlusOutlined />}
                                        disabled={
                                          (subscriptionPack &&
                                            subscriptionPack.zipcodes &&
                                            subscriptionPack.zipcodes ===
                                              this.state.Ziplist.length) ||
                                          this.state.zip.length === 0
                                            ? true
                                            : false
                                        }
                                      >
                                        Add Zipcode
                                      </Button>
                                    ))}
                                </Form.Item>
                                <Row>
                                  {this.state.Ziplist.map((field, index) => (
                                    <Form.Item required={false} key={field.key}>
                                      <Input
                                        readOnly
                                        style={{
                                          width: "70%",
                                          marginRight: "-50px",
                                        }}
                                        value={field}
                                        addonAfter={
                                          this.state.Ziplist.length >= 1 ? (
                                            <CloseOutlined
                                              className="dynamic-delete-button"
                                              onClick={() => {
                                                remove(field);
                                                this.handleDeselect(field);
                                              }}
                                              style={{ color: "red" }}
                                            />
                                          ) : null
                                        }
                                        className="form-input"
                                      />
                                    </Form.Item>
                                  ))}
                                </Row>
                              </>
                            )}
                          </Form.List>
                        </Form>
                      </>
                    )}
                </Col>

                {userDetails &&
                  (userDetails.userProfile === "AG" ||
                    userDetails.userProfile === "concierge") && (
                    <>
                      <Col
                        xl={{ span: 23, offset: 1 }}
                        lg={{ span: 23, offset: 1 }}
                        md={{ span: 23, offset: 1 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <div className="label-titles">
                          Specialties
                          {subscriptionPack && subscriptionPack.specialites && (
                            <p
                              style={{
                                float: "right",
                                marginBottom: "0px",
                                color: "red",
                              }}
                            >
                              {subscriptionPack.specialites -
                                optionsSelected.length}{" "}
                              Specialties left
                            </p>
                          )}
                        </div>
                        {subscriptionPack && subscriptionPack.specialites && (
                          <Form.Item name="specialties">
                            <Select
                              mode="multiple"
                              style={{
                                width:
                                  optionsSelected.length >
                                  subscriptionPack.specialites - 1
                                    ? window.screen.width >= "1199"
                                      ? "75%"
                                      : window.screen.width >= "768" &&
                                        window.screen.width < "1198"
                                      ? "70%"
                                      : "60%"
                                    : "100%",
                              }}
                              placeholder="Please select"
                              onChange={this.handlespecialties}
                              // defaultValue={
                              //   this.state.optionsSelected
                              // }
                              value={this.state.optionsSelected}
                              className="form-input-profile1"
                              showSearch={
                                window.screen.width >= "992" ? true : false
                              }
                            >
                              {opts.map(
                                (item) =>
                                  subscriptionPack &&
                                  subscriptionPack.specialites && (
                                    <Option
                                      disabled={
                                        optionsSelected &&
                                        optionsSelected.length >
                                          subscriptionPack.specialites - 1
                                          ? optionsSelected.includes(item.value)
                                            ? false
                                            : true
                                          : false
                                      }
                                      key={item.value}
                                    >
                                      {item.value}
                                    </Option>
                                  )
                              )}
                            </Select>
                            {optionsSelected.length >
                              subscriptionPack.specialites - 1 && (
                              <Button
                                type="default"
                                onClick={this.handleLink}
                                icon={<PlusOutlined />}
                                className="upgrade-add-btn-sub"
                                style={{
                                  background: "red",
                                  color: "white",
                                  borderColor: "red",
                                  height: "40px",
                                }}
                              >
                                <span
                                  className="upgrade-add-more-sub"
                                  style={{ color: "white" }}
                                >
                                  Upgrade to Add More
                                </span>
                              </Button>
                            )}
                          </Form.Item>
                        )}
                        {/* {loadTotalData && (
                        <>
                          <div className="label-titles">Portfolio</div>
                          <Form.Item name="Portfolio">
                            <Select
                              style={{
                                width: "100%",
                              }}
                              placeholder="Please select"
                              onChange={this.handleAgentPortFolio}
                              className="form-input-profile1"
                              defaultValue={
                                oldCategories &&
                                oldCategories.map((Category) => Category.label)
                              }
                            >
                              {["Previous works"].map((province, index) => (
                                <Select.Option key={index} value={province}>
                                  {province}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </>
                      )} */}
                      </Col>
                    </>
                  )}
                {userDetails &&
                  userDetails.userProfile !== "B" &&
                  userDetails.userProfile !== "S" && (
                    <Col
                      xl={{ span: 23, offset: 1 }}
                      lg={{ span: 23, offset: 1 }}
                      md={{ spana: 23, offset: 1 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <div id="About" className="form-titles">
                        About me{" "}
                      </div>
                      <Form.Item
                        name="userBio"
                        initialValue={
                          userDetails
                            ? userDetails.userBio
                              ? userDetails.userBio
                              : ""
                            : ""
                        }
                      >
                        <RichText
                          handleRichtextBio={this.handleRichtextBio}
                          richtTextBiofieldProp={
                            userDetails.userBio ? userDetails.userBio : ""
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                <Col
                  xl={{ span: 8, offset: 1 }}
                  lg={{ span: 8, offset: 1 }}
                  md={{ span: 8, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="update-btn"
                  >
                    Update Profile
                  </Button>
                </Col>
              </Row>
            </Spin>
          ) : (
            <Spin
              size="large"
              className="center-item"
              style={{ marginTop: "6vw" }}
            />
          )}
        </Form>

        <Drawer
          title={"Upgrade to Add More"}
          placement="bottom"
          width={"100%"}
          height={"100%"}
          mask={true}
          closable={true}
          getContainer={false}
          visible={showSubscriptions}
          onClose={() =>
            this.setState(
              {
                showSubscriptions: false,
              },
              this.getRefreshInfo()
            )
          }
          headerStyle={{ textAlign: "center" }}
        >
          <Row>
            <Col span={24}>
              <Subscriptions setLoadingfalse={this.setLoadingfalse} />
            </Col>
          </Row>
        </Drawer>
      </>
    );
  }
}
export default withAppContext(withRouter(ProfileForm));
