import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import App from "./App";
import "../src/stylesheets/global.scss";
import "react-image-lightbox/style.css";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./store/contextStore";
import "rc-time-picker/assets/index.css";

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.register();
reportWebVitals();
