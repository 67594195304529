import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Divider,
  Card,
  Button,
  Input,
  notification,
  Modal,
  Form,
  Upload,
  Collapse,
  Spin,
  Select,
} from "antd";
import Loading from "../../common/Loading";
import { noAuthadd, update } from "../../api/Apis";
import { withAppContext } from "../../store/contextStore";
import { withRouter } from "react-router-dom";
import { EditTwoTone, CameraOutlined } from "@ant-design/icons";
import { ModalTitle } from "../../StyledComponents/StyledComponents.js";
import { Storage } from "aws-amplify";
import NumberFormat from "react-number-format";
// import kristinPic from "../../images/kristin.jpeg";
// import tracyPic from "../../images/tracy2.jpeg";

const { TextArea } = Input;
const { Panel } = Collapse;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      contactVisible: false,
      loading: false,
      loanVisible: false,
      to: "",
      from: "",
      email: "",
      phone: "",
      subject: "New contact form submitted on HomesListedBy",
      message: "",
      EmailTo: "homeslistedby@gmail.com",
      Name: "Tracy Barber",
      initialLoading: true,
      showModal: false,
      contactImage: "",
      contactImage2: "",
      imageLoading: false,
      orgDetails: {},
      loading1: false,
      selectedEmail: "",
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        initialLoading: false,
      });
    }, 2000);
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    this.setState({
      orgDetails,
    });
  };

  handleMailForm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSendMail = async () => {
    if (this.state.email === "") {
      alert("Please enter email");
      return;
    } else if (this.state.subject === "") {
      alert("Please enter subject");
      return;
    } else if (this.state.message === "") {
      alert("Please enter message");
      return;
    } else {
      const data = {
        entityData: {
          // to: process.env.REACT_APP_AGENT_EMAIL,
          to:
            this.state.orgDetails.contactUsPage.contact2.name ===
            this.state.selectedEmail
              ? this.state.orgDetails.contactUsPage.contact2.email
              : this.state.orgDetails.contactUsPage.contact1.email,
          toName:
            this.state.selectedEmail ||
            this.state.orgDetails.contactUsPage.contact1.name,
          from: this.state.from,
          email: this.state.email,
          phone: this.state.phone,
          subject: this.state.subject,
          message: this.state.message,
          orgId: this.state.orgDetails.id,
        },
      };
      const response = await noAuthadd("submitContactForm", data);
      if (response.success) {
        this.handleCancel();
        notification["success"]({
          message: "You have successfully sent your message. Thank you.",
        });
      } else {
        notification["error"]({
          message: "Message sent failed.",
        });
      }
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      to: "",
      from: "",
      email: "",
      phone: "",
      subject: "New contact form submitted on HomesListedBy",
      message: "",
    });
  };

  handleSelect = (e) => {
    console.log(e);
    this.setState({
      EmailTo: e[0],
      Name: e[1],
    });
  };

  handleUploadPic = async (info, contactType) => {
    this.setState({
      imageLoading: true,
    });
    switch (info.file.status) {
      case "done":
        if (
          info.fileList.length > 0 &&
          info.file.status !== "removed" &&
          contactType === "contact1"
        ) {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  contactImage: result.key,
                  imageLoading: false,
                });
              });
            } else {
              this.setState({
                imageLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        if (
          info.fileList.length > 0 &&
          info.file.status !== "removed" &&
          contactType === "contact2"
        ) {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  contactImage2: result.key,
                  imageLoading: false,
                });
              });
            } else {
              this.setState({
                imageLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default: {
        this.setState({
          imageLoading: false,
        });
      }
    }
  };

  joinUsCard = () => {
    const { contactImage, imageLoading, contactImage2 } = this.state;
    // const { contactUsPage } = this.props.context.state.websiteThemes;
    const { contactUsPage } = JSON.parse(localStorage.getItem("orgDetails"));

    // const bgColor = (
    //   <div>
    //     <ColorPickerPanel
    //       enableAlpha={false}
    //       color="#fff"
    //       onChange={(e) =>
    //         this.setState({
    //           backgroundColor: e.color,
    //         })
    //       }
    //     />
    //   </div>
    // );
    return (
      <div style={{ marginTop: "1vw" }}>
        <Collapse accordion defaultActiveKey={["1"]}>
          <Panel
            key={"1"}
            header={
              "contact1" in contactUsPage === true
                ? contactUsPage?.contact1?.name
                : "Contact 1"
            }
          >
            {contactUsPage?.contact1 && (
              <Row style={{ padding: "10px" }}>
                <Col span={24}>
                  <Form
                    id="form"
                    onFinish={(e) => this.onFinish(e, "contact1")}
                  >
                    <Row>
                      <Col span={11}>
                        <ModalTitle>Image</ModalTitle>
                        <Form.Item
                          name="contactImage"
                          rules={[
                            {
                              required: false,
                              message: "Please input your Card Logo!",
                            },
                          ]}
                        >
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            style={{
                              borderRadius: "50%",
                              width: "100%",
                              height: "100%",
                            }}
                            showUploadList={false}
                            customRequest={dummyRequest}
                            onChange={(e) =>
                              this.handleUploadPic(e, "contact1")
                            }
                          >
                            <Spin spinning={imageLoading}>
                              {contactImage ? (
                                <img
                                  src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactImage}`}
                                  alt="avatar"
                                  style={{
                                    width: "100px",
                                    height: "100%",
                                  }}
                                />
                              ) : contactUsPage.contact1?.profile_picture ? (
                                <img
                                  src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactUsPage.contact1?.profile_picture}`}
                                  alt="avatar"
                                  style={{
                                    width: "100px",
                                    height: "100%",
                                  }}
                                />
                              ) : (
                                <CameraOutlined className="camera-icon" />
                              )}
                            </Spin>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={11}>
                        <ModalTitle>Name</ModalTitle>
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please input title",
                            },
                          ]}
                          initialValue={contactUsPage?.contact1?.name}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={11} offset={1}>
                        <ModalTitle>Designation</ModalTitle>
                        <Form.Item
                          name="designation"
                          rules={[
                            {
                              required: true,
                              message: "Please input designation",
                            },
                          ]}
                          initialValue={contactUsPage?.contact1?.designation}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={11}>
                        <ModalTitle>Email</ModalTitle>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input email",
                            },
                            {
                              type: "email",
                            },
                          ]}
                          initialValue={contactUsPage?.contact1?.email}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={11} offset={1}>
                        <ModalTitle>Phone</ModalTitle>
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please input number",
                            },
                          ]}
                          initialValue={contactUsPage?.contact1?.phone_number}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={11}>
                        <ModalTitle>Website Name</ModalTitle>
                        <Form.Item
                          name="webSiteName"
                          rules={[
                            {
                              required: true,
                              message: "Please input website name",
                            },
                          ]}
                          initialValue={contactUsPage?.contact1?.website_name}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={11} offset={1}>
                        <ModalTitle>Address</ModalTitle>
                        <Form.Item
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Please input website address",
                            },
                          ]}
                          initialValue={contactUsPage?.contact1?.address}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ float: "right" }}>
                      <Button
                        key="back"
                        onClick={() =>
                          this.setState({
                            showModal: false,
                          })
                        }
                        style={{ marginRight: "15px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        form="form"
                        loading={this.state.loading}
                      >
                        Proceed
                      </Button>
                    </Row>
                  </Form>
                </Col>
              </Row>
            )}
          </Panel>
        </Collapse>
        <Collapse>
          <Panel
            header={
              "contact2" in contactUsPage === true
                ? contactUsPage?.contact2?.name
                : "Contact 2"
            }
          >
            {contactUsPage?.contact2 && (
              <Row style={{ padding: "10px" }}>
                <Col span={24}>
                  <Form
                    id="form1"
                    onFinish={(e) => this.onFinish(e, "contact2")}
                  >
                    <ModalTitle>Image</ModalTitle>
                    <Form.Item
                      name="contactImage"
                      rules={[
                        {
                          required: false,
                          message: "Please input your Card Logo!",
                        },
                      ]}
                    >
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        style={{
                          borderRadius: "50%",
                          width: "100%",
                          height: "100%",
                        }}
                        showUploadList={false}
                        customRequest={dummyRequest}
                        onChange={(e) => this.handleUploadPic(e, "contact2")}
                      >
                        <Spin spinning={imageLoading}>
                          {contactImage2 ? (
                            <img
                              src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactImage2}`}
                              alt="avatar"
                              style={{
                                width: "100px",
                                height: "100%",
                              }}
                            />
                          ) : contactUsPage.contact2?.profile_picture ? (
                            <img
                              src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactUsPage.contact2?.profile_picture}`}
                              alt="avatar"
                              style={{
                                width: "100px",
                                height: "100%",
                              }}
                            />
                          ) : (
                            <CameraOutlined className="camera-icon" />
                          )}
                        </Spin>
                      </Upload>
                    </Form.Item>

                    <Row>
                      <Col span={11}>
                        <ModalTitle>Name</ModalTitle>
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please input title",
                            },
                          ]}
                          initialValue={contactUsPage?.contact2?.name}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={11} offset={1}>
                        <ModalTitle>Designation</ModalTitle>
                        <Form.Item
                          name="designation"
                          rules={[
                            {
                              required: true,
                              message: "Please input designation",
                            },
                          ]}
                          initialValue={contactUsPage?.contact2?.designation}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={11}>
                        <ModalTitle>Email</ModalTitle>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input email",
                            },
                            {
                              type: "email",
                            },
                          ]}
                          initialValue={contactUsPage?.contact2?.email}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={11} offset={1}>
                        <ModalTitle>Phone</ModalTitle>
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please input number",
                            },
                          ]}
                          initialValue={contactUsPage?.contact2?.phone_number}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={11}>
                        <ModalTitle>Website Name</ModalTitle>
                        <Form.Item
                          name="webSiteName"
                          rules={[
                            {
                              required: true,
                              message: "Please input website name",
                            },
                          ]}
                          initialValue={contactUsPage?.contact2?.website_name}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={11} offset={1}>
                        <ModalTitle>Address</ModalTitle>
                        <Form.Item
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Please input website address",
                            },
                          ]}
                          initialValue={contactUsPage?.contact2?.address}
                          style={{ marginBottom: "1vw" }}
                        >
                          <Input
                            placeholder="Start typing"
                            className="form-input"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ float: "right" }}>
                      <Button
                        key="back"
                        onClick={() =>
                          this.setState({
                            showModal: false,
                          })
                        }
                        style={{ marginRight: "15px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        form="form1"
                        loading={this.state.loading1}
                      >
                        Proceed
                      </Button>
                    </Row>
                  </Form>
                </Col>
              </Row>
            )}
          </Panel>
        </Collapse>
      </div>
    );
  };

  onFinish = async (values, type) => {
    const {
      landingScreen,
      Header,
      footer,
      WhyJoinUsPage,
      aboutUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;
    let adminDetails = JSON.parse(localStorage.getItem("orgDetails"));

    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserProfile && loginUserProfile.orgId) {
      let websiteId = await loginUserProfile.orgId;
      const { contactImage, backgroundColor, contactImage2 } = this.state;
      let contactData1 = contactUsPage.contact1 || {};
      let contactData2 = contactUsPage.contact2 || {};

      if (type === "contact1") {
        this.setState({
          loading: true,
        });
        contactData1 = {
          name: values["name"]
            ? values["name"]
            : contactUsPage["contact1"]["name"],
          designation: values["designation"]
            ? values["designation"]
            : contactUsPage["contact1"]["designation"],
          email: values["email"]
            ? values["email"]
            : contactUsPage["contact1"]["email"],
          website_name: values["webSiteName"]
            ? values["webSiteName"]
            : contactUsPage["contact1"]["webSiteName"],
          phone_number: values["phone"]
            ? values["phone"]
            : contactUsPage["contact1"]["phone"],
          address: values["address"]
            ? values["address"]
            : contactUsPage["contact1"]["address"],
          profile_picture: contactImage
            ? contactImage
            : contactUsPage["contact1"] &&
              contactUsPage["contact1"]["profile_picture"],
          backgroundColor: backgroundColor
            ? backgroundColor
            : contactUsPage["contact1"]["backgroundColor"],
        };
      } else if (type === "contact2") {
        this.setState({
          loading1: true,
        });

        contactData2 = {
          name: values["name"]
            ? values["name"]
            : contactUsPage["contact2"]["name"],
          designation: values["designation"]
            ? values["designation"]
            : contactUsPage["contact2"]["designation"],
          email: values["email"]
            ? values["email"]
            : contactUsPage["contact2"]["email"],
          website_name: values["webSiteName"]
            ? values["webSiteName"]
            : contactUsPage["contact2"]["webSiteName"],
          phone_number: values["phone"]
            ? values["phone"]
            : contactUsPage["contact2"]["phone"],
          address: values["address"]
            ? values["address"]
            : contactUsPage["contact2"]["address"],
          profile_picture: contactImage2
            ? contactImage2
            : contactUsPage["contact2"]["profile_picture"],
          backgroundColor: backgroundColor
            ? backgroundColor
            : contactUsPage["contact2"]["backgroundColor"],
        };
      }

      let data = {
        entityData: {
          contactUsPage: { contact1: contactData1, contact2: contactData2 },
          landingScreen,
          Header,
          footer,
          aboutUsPage,
          WhyJoinUsPage,
        },
      };
      data["entityData"]["isNew"] = false;
      let userData = {
        entityData: {
          isNew: false,
        },
      };

      update("saveWebsitesData", websiteId, data)
        .then((result) => {
          if (result.success) {
            this.props.context.getWebsites(adminDetails.websiteName);
            update("users", loginUserProfile?.id, userData)
              .then((result) => {
                if (result.success) {
                  this.setState({
                    showModal: false,
                    loading: false,
                    loading1: false,
                    contactImage: "",
                    contactImage2: "",
                  });
                }
              })
              .catch((err) => {
                this.setState({
                  showModal: false,
                  loading: false,
                  loading1: false,
                });
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            showModal: false,
            loading: false,
          });
        });
    }
  };

  render() {
    const { showModal } = this.state;
    const { contactUsPage } = JSON.parse(localStorage.getItem("orgDetails"));
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    console.log(contactUsPage);
    return (
      <div>
        {contactUsPage && (
          <>
            <Loading enableLoading={this.state.initialLoading} />
            <Row>
              <Col span={20} offset={2}>
                <Divider>
                  <h1>Contact Us</h1>
                </Divider>
              </Col>
            </Row>

            <Row>
              <Col
                xl={{ span: 20, offset: 2 }}
                lg={{ span: 20, offset: 2 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Row
                  style={{
                    justifyContent: "end",
                    fontSize: "24px",
                    background: "#f0f2f5",
                  }}
                >
                  {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                    <EditTwoTone
                      style={{
                        float: "right",
                        fontSize: "24px",
                        marginRight: "16px",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.setState({
                          showModal: true,
                          showEditModalText: "card",
                          contactImage: "",
                        })
                      }
                    />
                  )}
                </Row>
                <Row gutter={16}>
                  <Col
                    xl={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                  >
                    <Card
                      hoverable
                      bordered={false}
                      title={null}
                      bodyStyle={{ padding: "15px" }}
                      style={{
                        // background: contactUsPage.contact1.backgroundColor,
                        borderColor: "black",
                        marginTop: "1vw",
                        marginBottom: "1vw",
                      }}
                      className="padding-card1-whyjoin"
                    >
                      <Row className="padding">
                        <Col
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                        >
                          <h3
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              marginBottom: "2.5vw",
                            }}
                          >
                            Contact us anytime for questions, suggestions, etc:
                          </h3>
                        </Col>
                      </Row>
                      <Row className="padding">
                        <Col
                          xl={{ span: 4 }}
                          lg={{ span: 4 }}
                          md={{ span: 4 }}
                          xs={{ span: 5 }}
                          sm={{ span: 5 }}
                        >
                          <span className="color-grey  terms">To :</span>
                        </Col>
                        <Col
                          xl={{ span: 18, offset: 2 }}
                          lg={{ span: 18, offset: 2 }}
                          md={{ span: 18, offset: 2 }}
                          xs={{ span: 18, offset: 1 }}
                          sm={{ span: 18, offset: 1 }}
                        >
                          <Select
                            style={{ width: "100%" }}
                            bordered={false}
                            defaultValue={contactUsPage.contact1.name}
                            onChange={(value) => {
                              this.setState({
                                selectedEmail: value,
                              });
                            }}
                          >
                            <option
                              value={contactUsPage.contact1.name}
                              label="data"
                            >
                              {contactUsPage.contact1.name}
                            </option>
                            <option value={contactUsPage.contact2.name}>
                              {contactUsPage.contact2.name}
                            </option>
                          </Select>
                          {/* <Input
                            disabled={this.state.disableEdit}
                            name="to"
                            placeholder="Enter Your Name"
                            className="form-input"
                            value={contactUsPage.contact1.name}
                            onChange={this.handleMailForm}
                            style={{
                              border: "1px solid #fff",
                              color: "black",
                              background: "white",
                            }}
                          /> */}
                        </Col>
                      </Row>
                      <Divider style={{ margin: "5px" }}></Divider>

                      <Row className="padding">
                        <Col
                          xl={{ span: 4 }}
                          lg={{ span: 4 }}
                          md={{ span: 4 }}
                          xs={{ span: 5 }}
                          sm={{ span: 5 }}
                        >
                          <span className="color-grey  terms">Name :</span>
                        </Col>
                        <Col
                          xl={{ span: 18, offset: 2 }}
                          lg={{ span: 18, offset: 2 }}
                          md={{ span: 18, offset: 2 }}
                          xs={{ span: 18, offset: 1 }}
                          sm={{ span: 18, offset: 1 }}
                        >
                          <Input
                            disabled={this.state.disableEdit}
                            name="from"
                            placeholder="Enter Your Name"
                            className="form-input"
                            value={this.state.from}
                            onChange={this.handleMailForm}
                            style={{
                              border: "1px solid #fff",
                              color: "black",
                              background: "white",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider style={{ margin: "5px" }}></Divider>

                      <Row className="padding">
                        <Col
                          xl={{ span: 4 }}
                          lg={{ span: 4 }}
                          md={{ span: 4 }}
                          xs={{ span: 5 }}
                          sm={{ span: 5 }}
                        >
                          <span className="color-grey terms">Email :</span>
                        </Col>
                        <Col
                          xl={{ span: 18, offset: 2 }}
                          lg={{ span: 18, offset: 2 }}
                          md={{ span: 18, offset: 2 }}
                          xs={{ span: 18, offset: 1 }}
                          sm={{ span: 18, offset: 1 }}
                        >
                          <Input
                            disabled={this.state.disableEdit}
                            name="email"
                            placeholder="Enter Your Email"
                            className="form-input"
                            value={this.state.email}
                            onChange={this.handleMailForm}
                            style={{
                              border: "1px solid #fff",
                              color: "black",
                              background: "white",
                            }}
                          />
                        </Col>
                      </Row>

                      <Divider style={{ margin: "5px" }}></Divider>

                      <Row className="padding">
                        <Col
                          xl={{ span: 4 }}
                          lg={{ span: 4 }}
                          md={{ span: 4 }}
                          xs={{ span: 5 }}
                          sm={{ span: 5 }}
                        >
                          <span className="color-grey terms">Phone :</span>
                        </Col>
                        <Col
                          xl={{ span: 18, offset: 2 }}
                          lg={{ span: 18, offset: 2 }}
                          md={{ span: 18, offset: 2 }}
                          xs={{ span: 18, offset: 1 }}
                          sm={{ span: 18, offset: 1 }}
                        >
                          <Input
                            disabled={this.state.disableEdit}
                            name="phone"
                            placeholder="Enter Your Phone"
                            className="form-input"
                            value={this.state.phone}
                            onChange={this.handleMailForm}
                            style={{
                              border: "1px solid #fff",
                              color: "black",
                              background: "white",
                            }}
                          />
                        </Col>
                      </Row>

                      <Divider style={{ margin: "5px" }}></Divider>

                      {/* <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey terms">Subject :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        name="subject"
                        placeholder="Enter Your Subject"
                        className="form-input"
                        value={this.state.subject}
                        onChange={this.handleMailForm}
                        style={{ border: "1px solid #fff" }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "5px" }}></Divider> */}

                      <Row className="padding">
                        <Col
                          xl={{ span: 4 }}
                          lg={{ span: 5 }}
                          md={{ span: 4 }}
                          xs={{ span: 5 }}
                          sm={{ span: 5 }}
                        >
                          <span className="color-grey terms">Message :</span>
                        </Col>
                        <Col
                          xl={{ span: 18, offset: 2 }}
                          lg={{ span: 17, offset: 2 }}
                          md={{ span: 18, offset: 2 }}
                          xs={{ span: 18, offset: 1 }}
                          sm={{ span: 18, offset: 1 }}
                        >
                          <TextArea
                            placeholder="Start Typing.."
                            rows={3}
                            name="message"
                            value={this.state.message}
                            onChange={this.handleMailForm}
                            style={{ border: "1px solid #fff" }}
                          />
                        </Col>
                      </Row>

                      <Divider style={{ margin: "5px" }}></Divider>
                      <div style={{ float: "right" }}>
                        <Button
                          key="submit"
                          type="primary"
                          loading={this.state.loading}
                          onClick={this.handleSendMail}
                          className="send-button-mobile-view"
                        >
                          <h6
                            style={{
                              color: "#FFF",
                              fontWeight: "bold",
                              fontSize: "14px",
                              marginTop: "5px",
                            }}
                          >
                            Send
                          </h6>
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xl={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    className="card-description"
                  >
                    <Card
                      hoverable
                      bordered={false}
                      title={null}
                      bodyStyle={{ padding: "15px" }}
                      className="padding-card1-whyjoin-image-card"
                      style={{
                        // background: contactUsPage.contact1.backgroundColor,
                        borderColor: "black",
                        marginTop: "1vw",
                        marginBottom: "1vw",
                      }}
                    >
                      <Row>
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <img
                            alt="Tracy"
                            src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactUsPage.contact1.profile_picture}`}
                            style={{
                              boxShadow: "0px 0px 10px 0px",
                              border: "6px solid rgb(28, 45, 65)",
                              width: "100%",
                              height: "230px",
                              objectFit: "contain",
                              background: "#FFF",
                            }}
                          />
                        </Col>
                        <Col
                          xl={{ span: 14, offset: 2 }}
                          lg={{ span: 14, offset: 2 }}
                          md={{ span: 14, offset: 2 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                          className="card-description"
                        >
                          <h3 style={{ fontWeight: "bold" }}>
                            {contactUsPage.contact1.name +
                              ", " +
                              contactUsPage?.contact1?.designation}
                          </h3>
                          <h3>{contactUsPage.contact1.website_name}</h3>
                          <h3>{contactUsPage.contact1.address}</h3>
                          <h3>
                            Phone :
                            <NumberFormat
                              format="(###) ###-####"
                              value={contactUsPage.contact1.phone_number}
                              displayType={"text"}
                            />
                          </h3>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "1vw" }}>
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <img
                            alt="kristin"
                            // src={kristinPic}
                            src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactUsPage.contact2.profile_picture}`}
                            style={{
                              boxShadow: "0px 0px 10px 0px",
                              border: "6px solid rgb(28, 45, 65)",
                              width: "100%",
                              height: "230px",
                              objectFit: "contain",
                              background: "#FFF",
                            }}
                          />
                        </Col>
                        <Col
                          xl={{ span: 14, offset: 2 }}
                          lg={{ span: 14, offset: 2 }}
                          md={{ span: 14, offset: 2 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                          className="card-description"
                        >
                          <h3 style={{ fontWeight: "bold" }}>
                            {contactUsPage.contact2.name +
                              ", " +
                              contactUsPage?.contact2?.designation}
                          </h3>
                          <h3>{contactUsPage.contact2.website_name}</h3>
                          <h3>{contactUsPage.contact2.address}</h3>
                          <h3>
                            Phone :
                            <NumberFormat
                              format="(###) ###-####"
                              value={contactUsPage.contact2.phone_number}
                              displayType={"text"}
                            />
                          </h3>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        <Modal
          className="modal-black-close"
          visible={showModal}
          title="ContactUs changes"
          onCancel={() =>
            this.setState({
              showModal: false,
            })
          }
          footer={false}
          width={
            window.screen.width >= "768"
              ? "40%"
              : window.screen.width > "501" && window.screen.width <= "767"
              ? "50%"
              : "100%"
          }
        >
          {this.joinUsCard()}
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(ContactUs));
