import React, { useEffect, useState } from "react";
import { Card, Table, Tag, Row, Col, Button } from "antd";
// import { list } from "../../api/Apis";
import moment from "moment";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../../store/contextStore";
import { list } from "../../../api/Apis";

function PaidLeadSource(props) {
  const initialState = {
    agentsList: [],
    selectedId: 1,
    selectedStatus: "all",
    agentsBoard: [],
    leaderId: 0,
    selectedSource: "all",
    agentName: "All Agents",
  };
  const [dropDownLists, setDropDownLists] = useState(initialState);

  useEffect(() => {
    let loginUser = JSON.parse(localStorage.getItem("userDetails"));

    setDropDownLists((t) => ({
      ...t,
      agentsList: [],
    }));

    let mounted = true;
    if (loginUser && loginUser.userProfile !== "AG") {
      getAgentslist(mounted);
      getLeaderBoardDetails(mounted);
    }
    return () => (mounted = false);
  }, []);

  const getAgentslist = (mounted) => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    if (mounted) {
      list("users", { userProfile: "AG", orgId })
        .then((res) => {
          let newAgentsList = [];
          newAgentsList.push({
            label: "All Agents",
            value: "all",
            id: 1,
          });
          res.data.map((agent) =>
            newAgentsList.push({
              label: agent?.firstName + " " + agent?.lastName,
              value: agent,
              id: agent.id,
            })
          );

          setDropDownLists((t) => ({
            ...t,
            agentsList: newAgentsList,
          }));
        })
        .catch((error) => console.log(error));
    }
  };

  const getLeaderBoardDetails = (mounted) => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));

    if (mounted) {
      list("myPipelineLeaderBoard", { orgId })
        .then((res) => {
          if (res.success) {
            setDropDownLists((t) => ({
              ...t,
              agentsBoard: res.data.sort((a, b) => b.netIncome - a.netIncome),
            }));
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleClickRow = (record) => {
    props.history.push(
      `/VirtualOffice/ViewAllContacts/${record.id}`,
      "mypipeLine"
    );
  };

  const handleFilterContacts = (leaderId, agentName) => {
    setDropDownLists((t) => ({
      ...t,
      leaderId,
    }));
    props.handleAgents(leaderId);
  };

  const agentsLeadBoardColoumns = [
    {
      title: "AGENT NAME",
      dataIndex: "agentName",
      key: "agentName",
      onCell: (record) => ({
        onClick: () => handleFilterContacts(record.agentId, record.agentName),
      }),
      render: (agentName) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {agentName}
          </span>
        );
      },
    },
    {
      title: "NUM OF LEADS #",
      key: "numOfLeads",
      dataIndex: "numOfLeads",
      onCell: (record) => ({
        onClick: () => handleFilterContacts(record.agentId, record.agentName),
      }),
      render: (numOfLeads) => (
        <span
          style={{
            fontSize: "13px",
            fontWeight: "bold",
            fontFamily: "Poppins",
          }}
        >
          {numOfLeads}
        </span>
      ),
    },
    {
      title: "NET COMM AFTER ADJ",
      dataIndex: "netIncome",
      key: "netIncome",
      onCell: (record) => ({
        onClick: () => handleFilterContacts(record.agentId, record.agentName),
      }),
      render: (netIncome, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            <NumberFormat
              value={parseFloat(netIncome).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </span>
        );
      },
    },
  ];

  const columns = [
    {
      title: "DATE ADDED",
      dataIndex: "openDate",
      key: "openDate",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (openDate) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {moment(openDate).format("MM/DD/YYYY")}
          </span>
        );
      },
    },
    {
      title: "STATUS",
      key: "listingStatus",
      dataIndex: "listingStatus",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (tag) => (
        <Tag
          style={{
            border: "none",
            background:
              tag === "Closed"
                ? "red"
                : tag === "Under Agreement"
                ? "#DCF1FA"
                : tag === "Active Listing"
                ? "#E6E6F8"
                : tag === "Active Buyer"
                ? "#D5F7EF"
                : tag === "Prospective Buyer"
                ? "#FFE2E2"
                : tag === "Prospective Seller"
                ? "#E5CCFF"
                : tag === "Prospective seller or buyer"
                ? "#FFE8DE"
                : tag === "Went cold but didn't tell me no"
                ? "#FFF3E0"
                : tag === "Database or Past Clients" && "#ECECEB",

            color:
              tag === "Closed"
                ? "#FFF"
                : tag === "Under Agreement"
                ? "#34ACE0"
                : tag === "Active Listing"
                ? "#706FD3"
                : tag === "Active Buyer"
                ? "#33D9B2"
                : tag === "Prospective Buyer"
                ? "#FF5252"
                : tag === "Prospective Seller"
                ? "#9933FF"
                : tag === "Prospective seller or buyer"
                ? "#FF793F"
                : tag === "Went cold but didn't tell me no"
                ? "#FFB142"
                : tag === "Database or Past Clients" && "#84817A",
          }}
          key={tag}
        >
          <span
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              fontFamily: "Poppins",
            }}
          >
            {tag === "Prospective seller or buyer"
              ? "Prospective Seller and Buyer".toUpperCase()
              : tag?.toUpperCase()}
          </span>
        </Tag>
      ),
    },
    {
      title: "RATING",
      dataIndex: "timeframe",
      key: "timeframe",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (timeframe) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {timeframe?.charAt(0)}
          </span>
        );
      },
    },
    {
      title: "LEAD SOURCE",
      dataIndex: "sourceLead",
      key: "sourceLead",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (sourceLead) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {sourceLead}
          </span>
        );
      },
    },
    {
      title: "NAME",
      dataIndex: "firstName",
      key: "firstName",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (firstName, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {firstName + " " + record.lastName}
          </span>
        );
      },
    },
    {
      title: "NET COMM AFTER ADJ",
      dataIndex: "listPrice",
      key: "listPrice",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (listPrice, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              float: "right",
            }}
          >
            <NumberFormat
              value={parseFloat(listPrice + record.buyPrice).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </span>
        );
      },
    },
  ];

  const adminColumns = [
    {
      title: "DATE ADDED",
      dataIndex: "openDate",
      key: "openDate",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (openDate) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {moment(openDate).format("MM/DD/YYYY")}
          </span>
        );
      },
    },
    {
      title: "AGENT NAME",
      dataIndex: "agentDetails",
      key: "agentDetails",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (agentDetails) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {agentDetails.full_name}
          </span>
        );
      },
    },
    {
      title: "STATUS",
      key: "listingStatus",
      dataIndex: "listingStatus",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (tag) => (
        <Tag
          style={{
            border: "none",
            background:
              tag === "Closed"
                ? "red"
                : tag === "Under Agreement"
                ? "#DCF1FA"
                : tag === "Active Listing"
                ? "#E6E6F8"
                : tag === "Active Buyer"
                ? "#D5F7EF"
                : tag === "Prospective Buyer"
                ? "#FFE2E2"
                : tag === "Prospective Seller"
                ? "#E5CCFF"
                : tag === "Prospective seller or buyer"
                ? "#FFE8DE"
                : tag === "Went cold but didn't tell me no"
                ? "#FFF3E0"
                : tag === "Database or Past Clients" && "#ECECEB",

            color:
              tag === "Closed"
                ? "#FFF"
                : tag === "Under Agreement"
                ? "#34ACE0"
                : tag === "Active Listing"
                ? "#706FD3"
                : tag === "Active Buyer"
                ? "#33D9B2"
                : tag === "Prospective Buyer"
                ? "#FF5252"
                : tag === "Prospective Seller"
                ? "#9933FF"
                : tag === "Prospective seller or buyer"
                ? "#FF793F"
                : tag === "Went cold but didn't tell me no"
                ? "#FFB142"
                : tag === "Database or Past Clients" && "#84817A",
          }}
          key={tag}
        >
          <span
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              fontFamily: "Poppins",
            }}
          >
            {tag === "Prospective seller or buyer"
              ? "prospective Seller and Buyer".toUpperCase()
              : tag?.toUpperCase()}
            {/* {tag?.toUpperCase()} */}
          </span>
        </Tag>
      ),
    },
    {
      title: "LAST ACTIVE",
      dataIndex: "lastActiveDate",
      key: "lastActiveDate",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (lastActiveDate) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {/* {lastActiveDate ? lastActiveDate : ""} */}
            {lastActiveDate
              ? moment.unix(lastActiveDate).format("MM/DD/YYYY hh:mm A")
              : ""}
          </span>
        );
      },
    },
    {
      title: "LEAD SOURCE",
      dataIndex: "sourceLead",
      key: "sourceLead",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (sourceLead) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {sourceLead}
          </span>
        );
      },
    },
    {
      title: "NAME",
      dataIndex: "firstName",
      key: "firstName",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (firstName, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {firstName + " " + record.lastName}
          </span>
        );
      },
    },
    {
      title: "NET COMM AFTER ADJ",
      dataIndex: "listPrice",
      key: "listPrice",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (listPrice, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              float: "right",
            }}
          >
            <NumberFormat
              value={parseFloat(listPrice + record.buyPrice).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </span>
        );
      },
    },
  ];

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  let showLeadsData = [];
  const handleFooterTotal = (leadRecords) => {
    let totalInc = 0;
    let priceCountData = leadRecords.filter(
      (oo) => "listPrice" in oo === true && "buyPrice" in oo === true
    );
    let listPriceInc = priceCountData.reduce((a, v) => a + v.listPrice, 0);
    let buyerPriceInc = priceCountData.reduce((a, v) => a + v.buyPrice, 0);
    totalInc = parseFloat(listPriceInc + buyerPriceInc);

    return (
      <Row justify="end" style={{ padding: "0px", marginRight: "5px" }}>
        <span
          style={{
            textTransform: "capitalize",
            fontWeight: "500",
            float: "right",
            fontSize: "18px",
            padding: "5px 0 5px 0",
          }}
        >
          <span>Total: </span>
          <NumberFormat
            value={parseFloat(totalInc).toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </span>
      </Row>
    );
  };
  return (
    <div
      style={{ padding: window.screen.width > 996 ? "24px 24px 0px" : "0px" }}
    >
      {userDetails && userDetails?.userProfile === "A" && (
        <Row gutter={window.screen.width > 996 && [24, 24]}>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            className="mobile_view_margin_Top"
            style={{ marginBottom: "1vw" }}
          >
            <Card
              title="Leader board"
              className="card_virtual"
              hoverable={true}
              bodyStyle={{ padding: "0px", borderTop: "1px solid #ebebeb" }}
            >
              <Table
                columns={agentsLeadBoardColoumns}
                dataSource={dropDownLists.agentsBoard}
                loading={props?.loading}
                pagination={{
                  total: showLeadsData,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                style={{ overflow: "auto" }}
                onScroll={true}
              />
            </Card>
          </Col>
        </Row>
      )}

      <Row gutter={window.screen.width > 996 && [24, 24]}>
        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
          style={{ marginBottom: "1vw" }}
        >
          <Card
            // title="Clients/Prospective By Paid Lead Source"
            className="card_virtual"
            hoverable={true}
            bodyStyle={{ padding: "0px", borderTop: "1px solid #ebebeb" }}
            title={
              <Row>
                <Col
                  xl={{
                    span:
                      userDetails && userDetails?.userProfile === "AG"
                        ? 12
                        : 24,
                    offset: 0,
                  }}
                  lg={{
                    span:
                      userDetails && userDetails?.userProfile === "AG"
                        ? 13
                        : 24,
                    offset: 0,
                  }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                >
                  <span>Clients/Prospective By Lead Source</span>
                </Col>
              </Row>
            }
            extra={
              userDetails &&
              userDetails?.userProfile !== "AG" && (
                <Button
                  // onClick={() => props?.pieChartFilter("all")}
                  onClick={() => props?.handleShowAll("all")}
                  className="todo_button"
                  style={{ border: "1px solid #3045d2" }}
                >
                  <span className="virtual_button_text btn-color">
                    Show All
                  </span>
                </Button>
              )
            }
          >
            <Table
              columns={userDetails.userProfile === "A" ? adminColumns : columns}
              // dataSource={props?.leads}
              dataSource={props?.leads}
              loading={props?.loading}
              pagination={{
                total: props.leads,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                position: ["bottomLeft"],
              }}
              style={{ overflow: "auto" }}
              onScroll={true}
              footer={() => handleFooterTotal(props.leads)}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default withAppContext(withRouter(PaidLeadSource));
