import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../../store/contextStore";
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Table,
  Select,
  Form,
  Upload,
  message,
  Collapse,
  notification,
  Checkbox,
} from "antd";
import {
  UploadOutlined,
  EditTwoTone,
  ArrowRightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  ModalTitle,
  ModalDesc,
  ModalData,
} from "../../../StyledComponents/StyledComponents.js";
import { Storage } from "aws-amplify";
import { add, list, update, deleteRecord } from "../../../api/Apis";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import ImageGallery from "../../../common/ImageGallery";
import EmailPopupCom from "../EmailPopupCom";

const Search = Input.Search;
const { TextArea } = Input;
const { Panel } = Collapse;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function ProfessionalContacts(props) {
  const [proConatct, setProConatct] = useState("");
  const [loginDetails, setLoginDetails] = useState({});
  const [allcontacts, setAllcontacts] = useState({
    totalContacts: [],
    filterContacts: [],
  });
  const [city, setCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [pictureCard, setPictureCard] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewContact, setViewContact] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const [sendEmailContacts, setSendEmailContacts] = useState({
    selectedContacts: [],
  });
  const [selectedContactKeys, setSelectedContactKeys] = useState([]);

  const fetchBusinesses = useCallback(() => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgnizationId = JSON.parse(localStorage.getItem("orgId"));
    setLoginDetails(userDetails);
    // let userId = userDetails.id;
    let orgId = orgnizationId;
    let query = {};

    // if (userDetails?.userProfile === "A") {
    query["orgId"] = orgId;
    // } else {
    //   query["userId"] = userId;
    // }
    query = {
      ...query,
      contactType: "Professional",
    };
    setLoading(true);
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          setAllcontacts((a) => ({
            totalContacts: result.data,
            filterContacts: result.data,
          }));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // getUserProfile();
    fetchBusinesses();
  }, [fetchBusinesses]);

  const getUserProfile = () => {
    const orgId = JSON.parse(localStorage.getItem("orgId"));

    let query = {};
    query = {
      orgId,
      contactType: "Professional",
    };
    setLoading(true);
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          setAllcontacts({
            ...allcontacts,
            totalContacts: result.data,
            filterContacts: result.data,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClickRow = (record) => {
    props.history.push(
      `/VirtualOffice/ViewAllContacts/${record.id}`,
      "profissional"
    );
  };

  const handleRemoveRecord = (item) => {
    deleteRecord("virtualOfficeContacts", item.id)
      .then((response) => {
        if (response.success) {
          notification["success"]({
            message: `Deleted Successfully`,
            description: "Thank you.",
          });
          fetchBusinesses();
        }
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };

  let loginUserInfo = JSON.parse(localStorage.getItem("userDetails"));

  let webColumns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => (a.firstName < b.firstName ? 1 : -1),
      hide: false,
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (firstName) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {firstName}
          </span>
        );
      },
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
      hide: false,
      sorter: (a, b) => (a.lastName < b.lastName ? 1 : -1),
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (lastName) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {lastName}
          </span>
        );
      },
    },
    {
      title: "COMPANY NAME",
      dataIndex: "companyName",
      key: "companyName",
      hide: false,
      sorter: (a, b) => (a.companyName < b.companyName ? 1 : -1),
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (companyName) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {companyName}
          </span>
        );
      },
    },
    {
      title: "PHONE #",
      dataIndex: "phone",
      key: "phone",
      hide: false,
      sorter: (a, b) => (a.phone < b.phone ? 1 : -1),
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (phone) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            <NumberFormat
              className="ant-input-number"
              placeholder="Phone"
              displayType="text"
              format="(###) ###-####"
              style={{
                width: "100%",
                border: "none",
              }}
              value={phone}
            />
          </span>
        );
      },
    },

    {
      title: "NOTES",
      dataIndex: "notes",
      key: "notes",
      sorter: (a, b) => a.notes && (a.notes < b.notes ? 1 : -1),
      hide: false,
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (notes) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {notes}
          </span>
        );
      },
    },
    {
      title: "SERVICE CATEGORY",
      dataIndex: "serviceCategory",
      key: "serviceCategory",
      sorter: (a, b) => (a.serviceCategory < b.serviceCategory ? 1 : -1),
      hide: false,
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (serviceCategory) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {serviceCategory}
          </span>
        );
      },
    },
    {
      title: "ADDED BY",
      dataIndex: "addedBy",
      key: "addedBy",
      sorter: (a, b) =>
        a?.addedBy?.firstName < b?.addedBy?.firstName ? 1 : -1,
      hide: false,
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (addedBy) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {addedBy ? addedBy?.firstName + " " + addedBy?.lastName : ""}
          </span>
        );
      },
    },
    {
      title: "BUSINESS CARD",
      dataIndex: "businessCard",
      key: "businessCard",
      sorter: false,
      hide: false,
      render: (businessCard) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {businessCard && (
              <ImageGallery photos={businessCard} businessCard />
            )}
          </span>
        );
      },
    },
    {
      title: "EDIT",
      dataIndex: "id",
      key: "id",
      sorter: false,
      hide: false,

      // onCell: (record) => ({
      //   onClick: () => handleClickRow(record),
      // }),
      render: (id, record) => {
        return (
          <span onClick={() => showData(id, record)}>
            <EditTwoTone style={{ fontSize: "20px" }} />
          </span>
        );
      },
    },

    {
      title: "DELETE",
      dataIndex: "id",
      key: "view",
      sorter: false,
      hide: loginUserInfo && loginUserInfo.userProfile === "A" ? false : true,
      render: (id, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            <DeleteOutlined
              style={{
                color: "red",
                fontSize: "16PX",
                cursor: "pointer",
              }}
              onClick={() => handleRemoveRecord(record)}
            />
          </span>
        );
      },
    },
  ];

  webColumns = webColumns.filter((item) => !item.hide);

  const mobColumns = [
    {
      title: "Professional Contacts",
      dataIndex: "productOriginDetails",
      key: "productOriginDetails",
      render: (productOriginDetails, record) => (
        <Collapse collapsible="true">
          <Panel
            header={record.firstName}
            key="1"
            extra={
              <ArrowRightOutlined
                onClick={() => showViewData(record.id, record)}
              />
            }
          >
            <Row
              className="row-margin-table"
              onClick={() => showViewData(record.id, record)}
            >
              <Col className="col-tilte">FIRST NAME</Col>
              <Col className="col-desc">{record.firstName}</Col>
            </Row>
            <Row
              className="row-margin-table"
              onClick={() => showViewData(record.id, record)}
            >
              <Col className="col-tilte">LAST NAME</Col>
              <Col className="col-desc">{record.lastName}</Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => showViewData(record.id, record)}
            >
              <Col className="col-tilte">COMPANY NAME</Col>
              <Col className="col-desc">{record.companyName}</Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => showViewData(record.id, record)}
            >
              <Col className="col-tilte">PHONE #</Col>
              <Col className="col-desc">{record.phone}</Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => showViewData(record.id, record)}
            >
              <Col className="col-tilte">EMAIL</Col>
              <Col className="col-desc" span={24} style={{ marginLeft: "0px" }}>
                {record.email}
              </Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => showViewData(record.id, record)}
            >
              <Col
                className="col-tilte"
                span={24}
                style={{ marginLeft: "0px" }}
              >
                NOTES
              </Col>
              <Col className="col-desc" style={{ marginLeft: "0px" }}>
                {record.notes}
              </Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => showViewData(record.id, record)}
            >
              <Col className="col-tilte">SERVICE CATEGORY</Col>
              <Col className="col-desc">{record.serviceCategory}</Col>
            </Row>
            <Row
              className="row-margin-table"
              onClick={() => showViewData(record.id, record)}
            >
              <Col className="col-tilte">BUSINESS CARD</Col>
              <Col className="col-desc">
                <ImageGallery photos={record.businessCard} businessCard />
              </Col>
            </Row>
            {loginUserInfo && loginUserInfo.userProfile === "A" && (
              <Row
                className="row-margin-table"
                onClick={() => handleRemoveRecord(record)}
              >
                <Col className="col-tilte">DELETE</Col>
                <Col className="col-desc">
                  <DeleteOutlined
                    style={{
                      color: "red",
                      fontSize: "14PX",
                      cursor: "pointer",
                    }}
                  />
                </Col>
              </Row>
            )}
          </Panel>
        </Collapse>
      ),
    },
  ];

  const handleSelect = (address) => {
    getFormattedAddress(address);
  };

  const getFormattedAddress = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    setCitySearch(citySearch);
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        // console.log(results);
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        setCity(cityValue);
        setAddressState(stateValue);
        setZipcode(zipCodeValue);
      })
      .catch((error) => console.error("Error", error));
  };

  const showData = (id, record) => {
    setShowModal(true);
    setProConatct(record);
    setEditModal(true);
  };

  const showViewData = (id, record) => {
    setViewModal(true);
    setViewContact(true);
    setProConatct(record);
  };

  const openModal = (showModal, loading, proConatct) => (
    <Modal
      className="modal-black-close"
      title={ proConatct !== "" ? "Update Professional Contact": "Add New Professional Contact" }
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
        setProConatct("");
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setProConatct("");
            setShowModal(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="form"
          loading={loading}
        >
          Save
        </Button>,
      ]}
      width={
        window.screen.width >= "768"
          ? "40%"
          : window.screen.width > "501" && window.screen.width <= "767"
          ? "50%"
          : "90%"
      }
    >
      <Form
        id="form"
        onFinish={onFinish}
        initialValues={{ remember: true }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{
          padding: window.screen.width > 996 ? "10px" : "0px",
        }}
      >
        <Row style={{ marginTop: "1vw" }}>
          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>First Name</ModalTitle>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input firstName",
                    },
                  ]}
                  initialValue={
                    proConatct.firstName ? proConatct.firstName : ""
                  }
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="First Name" className="height_form" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Last Name</ModalTitle>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input lastName",
                    },
                  ]}
                  style={{ marginBottom: "1vw" }}
                  initialValue={proConatct.lastName ? proConatct.lastName : ""}
                >
                  <Input placeholder="Last Name" className="height_form" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Email</ModalTitle>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid Corporate Email",
                    },
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                  initialValue={proConatct?.email}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Email" className="height_form" />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Phone</ModalTitle>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input phone",
                    },
                  ]}
                  initialValue={proConatct?.phone}
                  style={{ marginBottom: "1vw" }}
                >
                  <NumberFormat
                    className="ant-input-number height_form"
                    placeholder="Phone"
                    format="(###) ###-####"
                    style={{
                      width: "100%",
                      padding: "4px 11px",
                      height: "40px",
                      fontSize: "14px",
                    }}
                  />
                  {/* <Input placeholder="Phone" className="height_form" /> */}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Company Name</ModalTitle>
                <Form.Item
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Please input companyName",
                    },
                  ]}
                  initialValue={proConatct?.companyName}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Company Name" className="height_form" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Service Category</ModalTitle>
                <div className="loads-select">
                  <Form.Item
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please input Category",
                      },
                    ]}
                    initialValue={
                      proConatct.serviceCategory
                        ? proConatct.serviceCategory
                        : ""
                    }
                    style={{ marginBottom: "1vw" }}
                  >
                    <Select
                      defaultValue={"Select.."}
                      showSearch
                      options={serviceCategoryData.label}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ borderRadius: "5px " }}
                    >
                      {serviceCategoryData.filter(service=>service.value !== "all" ).map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Address</ModalTitle>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: "Please input address",
                    },
                  ]}
                  initialValue={proConatct?.address}
                  style={{ marginBottom: "1vw" }}
                >
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_MAPS_KEY}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                    }}
                    selectProps={{
                      citySearch,
                      defaultInputValue: `${proConatct?.address || ""}`,
                      placeholder: "Search Address...",
                      onChange: (address) => {
                        handleSelect(address);
                      },
                    }}
                  />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>City</ModalTitle>
                <Input
                  placeholder="City"
                  className="height_form"
                  value={city || proConatct?.city}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>State</ModalTitle>
                <Input
                  placeholder="State"
                  className="height_form"
                  value={addressState || proConatct?.addressState}
                />
              </Col>
              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Zipcode</ModalTitle>
                <Input
                  placeholder="Zip code"
                  className="height_form"
                  value={zipcode || proConatct?.zipcode}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24} style={{ marginTop: "1vw" }}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Business Card</ModalTitle>

                <Form.Item
                  name="picture"
                  rules={[
                    {
                      required: false,
                      message: "Please input your image!",
                    },
                  ]}
                  initialValue={
                    proConatct.businessCard ? proConatct.businessCard : ""
                  }
                >
                  <Upload
                    customRequest={dummyRequest}
                    onChange={(e) => handleUploadPic(e)}
                    maxCount={1}
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>Upload Card</Button>
                  </Upload>
                  {/* <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                  }}
                  showUploadList={false}
                  customRequest={dummyRequest}
                  onChange={(e) => handleUploadPic(e)}
                >
                  <Spin spinning={loading}>
                    {proConatct.businessCard ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${proConatct.businessCard}`}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : pictureCard ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${pictureCard}`}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : (
                      <CameraOutlined className="camera-icon" />
                    )} 
                  </Spin>
                </Upload>*/}
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Website</ModalTitle>
                <Form.Item
                  name="website"
                  rules={[
                    {
                      required: false,
                      message: "Please input website",
                    },
                  ]}
                  initialValue={proConatct?.website}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Website" className="height_form" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <ModalTitle>Notes</ModalTitle>
            <Form.Item
              name="notes"
              rules={[
                {
                  required: false,
                  message: "Please input notes",
                },
              ]}
              initialValue={proConatct?.notes}
              style={{ marginBottom: "1vw" }}
            >
              <Input placeholder="Notes" className="height_form" />
            </Form.Item>
          </Col>

          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <ModalTitle>Testimonials</ModalTitle>
            <Form.Item
              name="testimonials"
              rules={[
                {
                  required: false,
                  message: "Please input testimonials",
                },
              ]}
              initialValue={proConatct?.testimonials}
              style={{ marginBottom: "1vw" }}
            >
              <TextArea
                style={{ borderRadius: "5px " }}
                placeholder="Start Typing.."
                rows={3}
                name="message"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );

  const showViewModal = (showModal, loading, proConatct) => (
    <div>
      <Modal
        className="modal-black-close"
        title="Professional Contact"
        visible={viewModal}
        onCancel={() => {
          setViewModal(false);
          setProConatct("");
        }}
        footer={null}
        width={
          window.screen.width >= "768"
            ? "40%"
            : window.screen.width > "501" && window.screen.width <= "767"
            ? "50%"
            : "90%"
        }
      >
        <Row style={{ paddingLeft: "20px", marginTop: "1vw" }}>
          <Col span={24}>
            <Row>
              <Col span={11}>
                <ModalDesc>First Name</ModalDesc>
                <ModalData>{proConatct.firstName}</ModalData>
              </Col>

              <Col span={11} offset={1}>
                <ModalDesc>Last Name</ModalDesc>
                <ModalData>{proConatct.lastName}</ModalData>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={11}>
                <ModalDesc>Email</ModalDesc>
                <ModalData>{proConatct.email}</ModalData>
              </Col>
              <Col span={11} offset={1}>
                <ModalDesc>Phone</ModalDesc>
                <ModalData>{proConatct?.phone}</ModalData>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={11}>
                <ModalDesc>Company Name</ModalDesc>
                <ModalData>{proConatct?.companyName}</ModalData>
              </Col>

              <Col span={11} offset={1}>
                <ModalDesc>Service Category</ModalDesc>
                <ModalData>
                  {proConatct.serviceCategory ? proConatct.serviceCategory : ""}
                </ModalData>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={11}>
                <ModalDesc>Address</ModalDesc>
                <ModalData>{citySearch || proConatct?.address}</ModalData>
              </Col>

              <Col span={11} offset={1}>
                <ModalDesc>City</ModalDesc>
                <ModalData>{city || proConatct?.city}</ModalData>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={11}>
                <ModalDesc>State</ModalDesc>
                <ModalData>
                  {addressState || proConatct?.addressState}
                </ModalData>
              </Col>
              <Col span={11} offset={1}>
                <ModalDesc>Zipcode</ModalDesc>
                <ModalData>{zipcode || proConatct?.zipcode}</ModalData>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={11}>
                <ModalDesc>Business Card</ModalDesc>
                <ModalData>
                  <img
                    src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${proConatct.businessCard}`}
                    alt="avatar"
                    style={{
                      width: "100px",
                      height: "100%",
                    }}
                  />
                </ModalData>
              </Col>

              <Col span={11} offset={1}>
                <ModalDesc>Website</ModalDesc>
                <ModalData>{proConatct?.website}</ModalData>
              </Col>
            </Row>
          </Col>

          <Col span={11}>
            <ModalDesc>Notes</ModalDesc>
            <ModalData>{proConatct?.notes}</ModalData>
          </Col>
          <Col span={11} offset={1}>
            <ModalDesc>Testimonials</ModalDesc>
            <ModalData>{proConatct?.testimonials}</ModalData>
          </Col>
        </Row>
      </Modal>
    </div>
  );

  const handleUploadPic = async (info) => {
    setLoading(true);
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                setLoading(false);
                setPictureCard(result.key);
              });
            } else {
              setLoading(false);
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let id = loginDetails && loginDetails?.id;
    let data = {
      entityData: {
        userId: id,
        orgId,
        isActive: true,
        contactType: "Professional",
        businessCard: pictureCard ? pictureCard : proConatct?.businessCard,
        firstName: values["firstName"],
        lastName: values["lastName"],
        email: values["email"],
        phone: values["phone"],
        companyName: values["companyName"],
        serviceCategory: values["category"],
        notes: values["notes"],
        address: citySearch || proConatct?.address,
        city: city || proConatct?.city,
        addressState: addressState || proConatct?.addressState,
        zipcode: zipcode || proConatct?.zipcode,
        website: values["website"],
        testimonials: values["testimonials"],
        vendorOrLender: false,
        addedBy: {
          firstName: loginDetails.firstName,
          lastName: loginDetails.lastName,
          email: loginDetails.email,
          id: loginDetails.id,
          phone: loginDetails.phone,
        },
      },
    };
    if (editModal) {
      update("virtualOfficeContacts", proConatct.id, data)
        .then((result) => {
          if (result.success) {
            message.success("Updated successfully !");
            setLoading(false);
            setShowModal(false);
            setEditModal(false);
            getUserProfile();
            setCity("");
            setAddressState("");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setCity("");
          setAddressState("");
        });
    } else {
      add("virtualOfficeContacts", data)
        .then((result) => {
          if (result.success) {
            message.success("Contact created successfully !");
            setLoading(false);
            setShowModal(false);
            setEditModal(false);
            getUserProfile();
            setCity("");
            setAddressState("");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setCity("");
          setAddressState("");
        });
    }
  };

  const serviceCategoryData = [
    { label: "All", value: "all" },
    { label: "Mortgage Lender", value: "Mortgage Lender" },
    { label: "Private Lender", value: "Private Lender" },
    { label: "Attorney", value: "Attorney" },
    { label: "Home Inspector", value: "Home Inspector" },
    { label: "Insurance", value: "Insurance" },
    { label: "Contractor+", value: "Contractor+" },
    { label: "Plumber", value: "Plumber" },
    { label: "Electrician", value: "Electrician" },
    { label: "Landscaper", value: "Landscaper" },
    { label: "Junk Removal", value: "Junk Removal" },
    { label: "Mold Remediation", value: "Mold Remediation" },
    {
      label: "Lead Paint Testing/ Remediation",
      value: "Lead Paint Testing/ Remediation",
    },
    { label: "Concrete/ Crack Repair", value: "Concrete/ Crack Repair" },
    { label: "Other", value: "Other" },
  ];

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const searchData = (event, searchType) => {
    let totalOrdersList = [];

    let searchValue =
      searchType === "text"
        ? event.target.value
        : searchType === "checkbox"
        ? event.target.checked
        : event;
    if (searchType !== "checkbox") {
      searchValue = searchValue.toLowerCase();
    }
    if (searchValue.length > 0 && searchType === "text") {
      totalOrdersList = allcontacts?.totalContacts.filter(
        (user) =>
          (user["firstName"] &&
            user["firstName"].toLowerCase().indexOf(searchValue) > -1) ||
          (user["firstName"] &&
            user["lastName"].toLowerCase().indexOf(searchValue) > -1) ||
          (user["phone"] &&
            user["phone"].toString().indexOf(searchValue) > -1) ||
          user["email"].toLowerCase().indexOf(searchValue) > -1 ||
          user["companyName"].toLowerCase().indexOf(searchValue) > -1
      );
    } else if (searchValue && searchType === "checkbox") {
      totalOrdersList = allcontacts?.totalContacts.filter(
        (data) => data.userId === loginDetails.id
      );
    } else if (
      searchValue.length > 0 &&
      searchType !== "text" &&
      searchValue !== "all"
    ) {
      totalOrdersList = allcontacts?.totalContacts.filter(
        (data) => data.serviceCategory.toLowerCase() === searchValue && data
      );
    } else {
      totalOrdersList = allcontacts?.totalContacts;
    }
    setAllcontacts({ ...allcontacts, filterContacts: totalOrdersList });
  };

  const handleToRestContactSelection = () => {
    setSendEmailContacts({
      ...sendEmailContacts,
      selectedContacts: [],
    });
    setSelectedContactKeys([]);
  };

  const rowSelection = {
    selectedRowKeys: selectedContactKeys,
    selections: [
      {
        key: "SELECTION_ALL",
        text: "Select All Contacts",
        Table: "SELECTION_ALL",
        onSelect: () => {
          let allCont = allcontacts?.filterContacts.filter(
            (i) => i.email
          );
          setSendEmailContacts({
            ...sendEmailContacts,
            selectedContacts: allCont,
          });
          allCont = allCont.map((cId) => cId.id);
          setSelectedContactKeys(allCont);
        },
      },
      {
        key: "CLEAR_ALL",
        text: "Clear All Contacts",
        Table: "CLEAR_ALL",
        onSelect: () => {
          setSendEmailContacts({
            ...sendEmailContacts,
            selectedContacts: [],
          });
          setSelectedContactKeys([]);
        },
      },
    ],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedContactKeys(selectedRowKeys);
    },

    getCheckboxProps: (record) => ({
      disabled: record?.email === "" || record?.email === null, // Column configuration not to be checked
    }),

    onSelect: (record, selected, selectedRows) => {
      setSendEmailContacts({
        ...sendEmailContacts,
        selectedContacts: selectedRows,
      });
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSendEmailContacts({
        ...sendEmailContacts,
        selectedContacts: selectedRows,
      });
    },
  };
  return (
    <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
      <Row gutter={window.screen.width > 996 && [24, 24]}>
        <Col
          xl={{ span: 7, offset: 0 }}
          lg={{ span: 7, offset: 0 }}
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <Search
            className="search"
            size="large"
            placeholder="Search"
            onChange={(event) => searchData(event, "text")}
          />
        </Col>

        <Col
          xl={{ span: 5, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
          xs={{ span: 10, offset: 1 }}
          sm={{ span: 10, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <div className="loads-select">
            <Select
              onChange={(event) => searchData(event)}
              options={serviceCategoryData.label}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ borderRadius: "5px", width: "100%", cursor: "pointer" }}
              notFoundContent={"Not found !!!"}
              defaultValue="Service Category"
            >
              {serviceCategoryData.map((province) => (
                <Select.Option key={province.value} value={province.value}>
                  {province.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col
          xl={{ span: 4, offset: 0 }}
          lg={{ span: 5, offset: 6 }}
          xs={{ span: 10, offset: 2 }}
          sm={{ span: 10, offset: 2 }}
          className="mobile_view_margin_Top"
        >
          {loginDetails.userProfile === "AG" && (
            <Checkbox
              onChange={(event) => searchData(event, "checkbox")}
              style={{ padding: "8px" }}
            >
              Added by me
            </Checkbox>
          )}
        </Col>
        <Col
          xl={{ span: 3, offset: 5 }}
          lg={{ span: 5, offset: 6 }}
          xs={{ span: 10, offset: 2 }}
          sm={{ span: 10, offset: 2 }}
          className="mobile_view_margin_Top"
        >
          <Button
            type="primary"
            size="default"
            style={{ width: "100%", height: "40px" }}
            onClick={() => setShowModal(true)}
          >
            <h1 style={{ color: "#fff", marginTop: "5px" }}>
              <i className="fa fa-plus-circle"></i> Contacts{" "}
            </h1>
          </Button>
        </Col>
        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <Card
            title={window.screen.width > 996 ? "Professional Contacts" : ""}
            className="card_virtual"
            hoverable={true}
            bodyStyle={{ padding: "0px" }}
            style={{ overflow: "auto" }}
            extra={
              // sendEmailContacts?.selectedContacts?.length > 0 && (
              <EmailPopupCom
                selectedContacts={sendEmailContacts?.selectedContacts}
                handleToRestContactSelection={handleToRestContactSelection}
              />
              // )
            }
          >
            <Table
              className="table_conatcts"
              columns={window.screen.width > 996 ? webColumns : mobColumns}
              dataSource={allcontacts?.filterContacts.length > 0 ?
                allcontacts?.filterContacts?.sort((a, b) =>
                  a.createdAt < b.createdAt ? 1 : -1
                ) : ""
              }
              loading={loading}
              rowKey={(record) => record.id}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "25", "50", "100"],
                total: allcontacts?.filterContacts.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              rowSelection={window.screen.width > 996 && rowSelection}
            />
          </Card>
        </Col>
      </Row>
      {showModal && openModal(showModal, loading, proConatct)}
      {viewContact && showViewModal(showModal, loading, proConatct)}
      {/* {sendEmailContacts?.selectedContacts?.length > 0 && (
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 22 }}
          xs={{ span: 22 }}
          style={{ background: "grey", padding: "12px" }}
        >
          <EmailPopupCom
            selectedContacts={sendEmailContacts?.selectedContacts}
          />
        </Col>
      )} */}
    </div>
  );
}
export default withAppContext(withRouter(ProfessionalContacts));
