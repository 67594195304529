import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import login from "../images/Login.png";
import {
  Button,
  Row,
  Col,
  Form,
  Upload,
  Input,
  Modal,
  Spin,
  Dropdown,
} from "antd";
// import logo from "../images/logo.png";
import { EditOutlined, CameraOutlined } from "@ant-design/icons";
import { ModalTitle } from "../StyledComponents/StyledComponents.js";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import { withAppContext } from "../store/contextStore";
import { Storage } from "aws-amplify";
// import powerLogo from "../images/poweredby.png";
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
class Login extends Component {
  state = {
    showModal: false,
    updateLoading: false,
    editImagesLoading: false,
    homeCardImageId: "",
  };

  componentDidMount() {
    this.props.context.getWebsites(window.location.hostname);
  }

  handleUploadPic = async (info) => {
    this.setState({
      editImagesLoading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  homeCardImageId: result.key,
                  editImagesLoading: false,
                });
              });
            } else {
              this.setState({
                editImagesLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  onFinish = (values) => {
    const {
      landingScreen,
      loginScreen,
      Header,
      footer,
      aboutUsPage,
      WhyJoinUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;
    const {
      buttonColor,
      buttonbgColor,
      homeCardImageId,
      regButtonbgColor,
      regButtonColor,
      textColor,
      loginbgColor,
    } = this.state;

    let buttonData = {
      buttonText: values["buttonText"],
      buttonColor: buttonColor
        ? buttonColor
        : loginScreen["loginButton"]["buttonColor"],
      textColor: textColor
        ? textColor
        : loginScreen["loginButton"]["textColor"],
      buttonBackground: buttonbgColor
        ? buttonbgColor
        : loginScreen["loginButton"]["buttonBackground"],
    };

    let regbuttonData = {
      regbuttonText: values["regbuttonText"],
      regButtonColor: regButtonColor
        ? regButtonColor
        : loginScreen["registerButton"]["regButtonColor"],
      regbuttonBackground: regButtonbgColor
        ? regButtonbgColor
        : loginScreen["registerButton"]["regbuttonBackground"],
    };

    loginScreen["onImageTitle"] = values["onImageTitle"];
    loginScreen["onImageTitle1"] = values["onImageTitle1"];
    loginScreen["loginTitle"] = values["loginTitle"];
    loginScreen["registerTitle"] = values["registerTitle"];
    loginScreen["loginButton"] = buttonData;
    loginScreen["registerButton"] = regbuttonData;
    loginScreen["loginbgColor"] = loginbgColor
      ? loginbgColor
      : loginScreen["loginbgColor"]
      ? loginScreen["loginbgColor"]
      : "#eff2f5";
    loginScreen["backgroundLoginImage"] =
      homeCardImageId !== ""
        ? homeCardImageId
        : loginScreen["backgroundLoginImage"];

    let data = {
      entityData: {
        loginScreen,
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        WhyJoinUsPage,
        contactUsPage,
      },
    };

    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserProfile && loginUserProfile.orgId) {
      let websiteId = loginUserProfile.orgId;

      //FROM CONTEXT STORE UPDATING ORG INFORMATION
      this.props.context.updateWebsite(websiteId, data);
      this.setState({
        showModal: false,
      });
    }
  };

  showEditLogin = () => {
    const {
      buttonColor,
      buttonbgColor,
      homeCardImageId,
      editImagesLoading,
      textColor,
      regButtonColor,
      regButtonbgColor,
      loginbgColor,
    } = this.state;
    const { loginScreen } = this.props.context.state.websiteThemes;
    const TextColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={textColor}
          onChange={(e) =>
            this.setState({
              textColor: e.color,
            })
          }
        />
      </div>
    );
    const loginBgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={loginbgColor}
          onChange={(e) =>
            this.setState({
              loginbgColor: e.color,
            })
          }
        />
      </div>
    );

    const ButtonColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={buttonColor}
          onChange={(e) =>
            this.setState({
              buttonColor: e.color,
            })
          }
        />
      </div>
    );

    const bgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={buttonbgColor}
          onChange={(e) =>
            this.setState({
              buttonbgColor: e.color,
            })
          }
        />
      </div>
    );

    const RigsterButtonColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={regButtonColor}
          onChange={(e) =>
            this.setState({
              regButtonColor: e.color,
            })
          }
        />
      </div>
    );
    const RegisterbgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={regButtonbgColor}
          onChange={(e) =>
            this.setState({
              regButtonbgColor: e.color,
            })
          }
        />
      </div>
    );
    return (
      <div>
        {loginScreen && (
          <Row style={{ padding: "10px" }}>
            <Col span={24}>
              <Form id="loginScreen" onFinish={this.onFinish}>
                <ModalTitle>Background Image</ModalTitle>
                <Form.Item
                  name="bgImage"
                  rules={[
                    {
                      required: false,
                      message: "Please input your image!",
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    style={{
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    showUploadList={false}
                    customRequest={dummyRequest}
                    onChange={(e) => this.handleUploadPic(e)}
                  >
                    <Spin spinning={editImagesLoading}>
                      {homeCardImageId ? (
                        <img
                          src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${homeCardImageId}`}
                          alt="avatar"
                          style={{
                            width: "100px",
                            height: "100%",
                          }}
                        />
                      ) : loginScreen.backgroundLoginImage ? (
                        <img
                          src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${loginScreen.backgroundLoginImage}`}
                          alt="avatar"
                          style={{
                            width: "100px",
                            height: "100%",
                          }}
                        />
                      ) : (
                        <CameraOutlined className="camera-icon" />
                      )}
                    </Spin>
                  </Upload>
                </Form.Item>
                <ModalTitle>Login Image Text</ModalTitle>
                <Form.Item
                  name="onImageTitle"
                  rules={[
                    {
                      required: false,
                      message: "Please input title",
                    },
                  ]}
                  initialValue={loginScreen?.onImageTitle}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>

                <ModalTitle>Register Image Text</ModalTitle>
                <Form.Item
                  name="onImageTitle1"
                  rules={[
                    {
                      required: false,
                      message: "Please input title",
                    },
                  ]}
                  initialValue={loginScreen?.onImageTitle1}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>

                <Col span={24} style={{ marginBottom: "1vw" }}>
                  <ModalTitle>Text Color</ModalTitle>

                  <Input
                    value={
                      textColor
                        ? textColor
                        : loginScreen?.loginButton?.textColor
                    }
                    onChange={(e) =>
                      this.setState({
                        textColor: e.target.value,
                      })
                    }
                    suffix={
                      <Dropdown trigger={["click"]} overlay={TextColor}>
                        <Button
                          style={{
                            background:
                              textColor || loginScreen?.loginButton?.textColor,
                          }}
                        >
                          {" "}
                        </Button>
                      </Dropdown>
                    }
                  />
                </Col>

                <Col span={24} style={{ marginBottom: "1vw" }}>
                  <ModalTitle>
                    Login & Register Page Background Color
                  </ModalTitle>

                  <Input
                    value={
                      loginbgColor ? loginbgColor : loginScreen?.loginbgColor
                    }
                    onChange={(e) =>
                      this.setState({
                        loginbgColor: e.target.value,
                      })
                    }
                    suffix={
                      <Dropdown trigger={["click"]} overlay={loginBgColor}>
                        <Button
                          style={{
                            background:
                              loginbgColor || loginScreen?.loginbgColor,
                          }}
                        >
                          {" "}
                        </Button>
                      </Dropdown>
                    }
                  />
                </Col>

                <ModalTitle>Login Title</ModalTitle>
                <Form.Item
                  name="loginTitle"
                  rules={[
                    {
                      required: false,
                      message: "Please input title",
                    },
                  ]}
                  initialValue={loginScreen?.loginTitle}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>

                <Row style={{ marginBottom: "1vw" }}>
                  <Col span={7}>
                    <ModalTitle>Login Button Text</ModalTitle>
                    <Form.Item
                      name="buttonText"
                      rules={[
                        {
                          required: false,
                          message: "Please input button text!",
                        },
                      ]}
                      initialValue={loginScreen?.loginButton?.buttonText}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Button Color</ModalTitle>

                    <Input
                      value={
                        buttonColor
                          ? buttonColor
                          : loginScreen?.loginButton?.buttonColor
                      }
                      onChange={(e) =>
                        this.setState({
                          buttonColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={ButtonColor}>
                          <Button
                            style={{
                              background:
                                buttonColor ||
                                loginScreen?.loginButton?.buttonColor,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Button Background Color</ModalTitle>
                    <Input
                      value={
                        buttonbgColor
                          ? buttonbgColor
                          : loginScreen?.loginButton?.buttonBackground
                      }
                      onChange={(e) =>
                        this.setState({
                          buttonbgColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={bgColor}>
                          <Button
                            style={{
                              background:
                                buttonbgColor ||
                                loginScreen?.loginButton?.buttonBackground,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>
                </Row>

                <ModalTitle>Register Title</ModalTitle>
                <Form.Item
                  name="registerTitle"
                  rules={[
                    {
                      required: false,
                      message: "Please input title",
                    },
                  ]}
                  initialValue={loginScreen?.registerTitle}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>

                <Row style={{ marginBottom: "1vw" }}>
                  <Col span={7}>
                    <ModalTitle>Register Button Text</ModalTitle>
                    <Form.Item
                      name="regbuttonText"
                      rules={[
                        {
                          required: false,
                          message: "Please input button text!",
                        },
                      ]}
                      initialValue={loginScreen?.registerButton?.regbuttonText}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Register Button Color</ModalTitle>

                    <Input
                      value={
                        regButtonColor
                          ? regButtonColor
                          : loginScreen?.registerButton?.regButtonColor
                      }
                      onChange={(e) =>
                        this.setState({
                          regButtonColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown
                          trigger={["click"]}
                          overlay={RigsterButtonColor}
                        >
                          <Button
                            style={{
                              background:
                                regButtonColor ||
                                loginScreen?.registerButton?.regButtonColor,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Button Background Color</ModalTitle>
                    <Input
                      value={
                        regButtonbgColor
                          ? regButtonbgColor
                          : loginScreen?.registerButton?.regbuttonBackground
                      }
                      onChange={(e) =>
                        this.setState({
                          regButtonbgColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={RegisterbgColor}>
                          <Button
                            style={{
                              background:
                                regButtonbgColor ||
                                loginScreen?.registerButton
                                  ?.regbuttonBackground,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  render() {
    const { showModal, updateLoading } = this.state;
    const { loginScreen } = this.props.context.state.websiteThemes;
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    return (
      <div className="login-container">
        {loginUserProfile && loginUserProfile?.userProfile === "A" && (
          <EditOutlined
            style={{
              fontSize: "24px",
              cursor: "pointer",
              color: "#FFF",
              float: "right",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            onClick={() =>
              this.setState({
                showModal: true,
              })
            }
          />
        )}
        {orgDetails && "Header" in orgDetails && loginScreen && (
          <>
            <img
              src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${loginScreen?.backgroundLoginImage}`}
              alt="login"
              className="image1 height-image"
              style={{ height: "100vh" }}
            />

            <div className="logo">
              <Col span={12}>
                <img
                  src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${orgDetails.Header.headerLogo}`}
                  onClick={() => {
                    this.props.history.push("/");
                  }}
                  alt="logo"
                  style={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    cursor: "pointer",
                  }}
                />
              </Col>
            </div>
            <div className="content">
              {this.props.location.pathname === "/login" && (
                <p style={{ color: loginScreen?.loginButton?.textColor }}>
                  {loginScreen?.onImageTitle}
                </p>
              )}
              {this.props.location.pathname === "/register" && (
                <p style={{ color: loginScreen?.loginButton?.textColor }}>
                  {loginScreen?.onImageTitle1}
                </p>
                // <p>
                // Create an account to create buyer and seller profiles, search
                // for opportunities, contact sellers and buyers, and more.
                // </p>
              )}
            </div>
          </>
        )}
        <Modal
          className="modal-black-close"
          visible={showModal}
          title="Login Customization"
          // onOk={() => this.changeProfile()}
          onCancel={() =>
            this.setState({
              showModal: false,
            })
          }
          footer={
            <div>
              <Button
                onClick={() => {
                  this.setState({
                    showModal: false,
                  });
                }}
                style={{
                  height: "35px",
                  width: "100px",
                  borderRadius: "5px",
                  marginRight: "25px",
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  height: "35px",
                  width: "100px",
                  borderRadius: "5px",
                }}
                form="loginScreen"
                loading={updateLoading}
              >
                Submit
              </Button>
            </div>
          }
          width={
            window.screen.width >= "768"
              ? "40%"
              : window.screen.width > "501" && window.screen.width <= "767"
              ? "50%"
              : "100%"
          }
        >
          {this.showEditLogin()}
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(Login));
