import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../../store/contextStore";
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Select,
  Form,
  Upload,
  message,
  DatePicker,
  InputNumber,
  Collapse,
  notification,
  Checkbox,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ModalTitle } from "../../../StyledComponents/StyledComponents.js";
import { Storage } from "aws-amplify";
import { add, list, update } from "../../../api/Apis";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import NumberFormat from "react-number-format";
import moment from "moment";

const { TextArea } = Input;
const { Panel } = Collapse;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function AddContact(props) {
  const [city, setCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [homeCity, setHomeCity] = useState("");
  const [homeAddressState, setHomeAddressState] = useState("");
  const [homezipcode, setHomeZipcode] = useState("");
  const [homeCitySearch, setHomeCitySearch] = useState("");
  const [contacLoading, setContacLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState({
    registered: moment(new Date()).format("L"),
    closeDate: moment(new Date()).format("L"),
    followUpdate: moment(new Date()).format("L"),
  });
  const [priceList, setPriceList] = useState({
    listSidePrice: "",
    buySidePrice: "",
  });
  const [fileList, setFileList] = useState([]);
  const [pictureCard, setPictureCard] = useState("");

  const [townsOfInterest, setTownsOfInterest] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [emailData, setEmailData] = useState({
    showCheckBox: false,
    createAccount: false,
    invalidEmail: false,
    emailValue: true,
  });

  const [propsSetState, setPropsSetState] = useState(false);

  useEffect(() => {
    getAllAgents();
  }, []);

  if (
    props.proConatct !== "" &&
    "buysidepricepoint" in props.proConatct &&
    props.proConatct?.buysidepricepoint !== priceList.buySidePrice &&
    !propsSetState
  ) {
    setPriceList({
      ...priceList,
      buySidePrice: props.proConatct?.buysidepricepoint,
    });
    setSelectedDates(true);
  }
  if (
    props.proConatct !== "" &&
    "listsidepricepoint" in props.proConatct &&
    props.proConatct?.listsidepricepoint !== priceList.listSidePrice &&
    !propsSetState
  ) {
    setPriceList({
      ...priceList,
      listSidePrice: props.proConatct?.listsidepricepoint,
    });
    setSelectedDates(true);
  }
  // if (
  //   props.proConatct !== "" &&
  //   props.proConatct?.favoriteCity !== null &&
  //   props.proConatct?.favoriteCity?.length !== townsOfInterest.length &&
  //   !propsSetState
  // ) {
  //   setTownsOfInterest(props.proConatct?.favoriteCity);
  // }

  if (
    props.proConatct !== "" &&
    props.proConatct?.businessCard !== null &&
    props.proConatct?.businessCard !== pictureCard &&
    !propsSetState
  ) {
    setPictureCard(props.proConatct["businessCard"]);
    let fileList = [];
    if (props.proConatct["businessCard"]) {
      fileList.push({
        uid: props.proConatct["businessCard"],
        name: props.proConatct["businessCard"]?.split("_")[1],
        url: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${props.proConatct["businessCard"]}`,
      });
    }
    setFileList(fileList);
  }

  const handleSelect = (address) => {
    getFormattedAddress(address);
  };

  const getAllAgents = () => {
    let orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    let userLoginDetails = JSON.parse(localStorage.getItem("userDetails"))
   
    let query = {};
    query = {
      orgId: orgDetails.id,
      userProfile: "AG",
    };

    list("users", query)
      .then((result) => {
        if (result.success) {
          let agentData = result.data;
          // agentData.push(data);
          if(userLoginDetails && userLoginDetails.userProfile === "A"){
            agentData.push(userLoginDetails)
          }
          setAllAgents(
            agentData.sort((a, b) =>
              a.firstName.charAt(0) > b.firstName.charAt(0) ? 1 : -1
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  const getFormattedAddress = (address) => {
    setCitySearch(address.label);
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        setCity(cityValue);
        setAddressState(stateValue);
        setZipcode(zipCodeValue);
      })
      .catch((error) => console.error("Error", error));
  };

  const handleSelectAddress = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    setHomeCitySearch(citySearch);
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        setHomeCity(cityValue);
        setHomeAddressState(stateValue);
        setHomeZipcode(zipCodeValue);
      })
      .catch((error) => console.error("Error", error));
  };

  const openModal = (showModal, loading, proConatct) => {
    let loginDetails = JSON.parse(localStorage.getItem("userDetails"));
    // const dateFormat = "MM/DD/YYYY";
    const handleToSetDate = (date, type) => {
      setPropsSetState(true);
      setSelectedDates({
        ...selectedDates,
        [type]: moment(date).format("MM/DD/YYYY HH:MM a"),
      });
    };

    const handleServiceAreas = (address) => {
      let citiesList = townsOfInterest?.length > 0 ? townsOfInterest : [];
      let stateName = "";
      let cityValue = "";
      geocodeByAddress(address.label)
        .then((results) => {
          let address = results[0].address_components;
          for (let i = 0; i < address.length; i++) {
            if (address[i].types[0] === "locality") {
              cityValue = address[i].long_name;
            }
            if (address[i].types[0] === "administrative_area_level_1") {
              stateName = address[i].short_name;
            }
          }
          const stateCodes = JSON.parse(
            localStorage.getItem("stateCodesFromAdmin")
          );
          let setVendor = stateCodes.filter(
            (restState) =>
              restState.stateName.toLowerCase() === stateName.toLowerCase() ||
              restState.stateType.toLowerCase() === stateName.toLowerCase()
          );

          if (setVendor && setVendor.length > 0) {
            let newSearchLocation = {
              cityName: cityValue,
              stateName: stateName,
              type: "stateName",
            };
            if (newSearchLocation) {
              citiesList.push(newSearchLocation);
            }
            setTownsOfInterest((prevState) => [...citiesList]);
            setPropsSetState(true);
          } else {
            notification["error"]({
              message: "Please enter valid city...",
              description: "You can search cities from MA, CT, RI, NH states",
              placement: "topLeft",
            });
          }
        })
        .catch((error) => console.error("Error", error));
    };

    const onClose = (value) => {
      let updateSearchLocations = townsOfInterest;
      updateSearchLocations.splice(
        updateSearchLocations.findIndex(
          (e) => e.cityName !== value.ctyName && e.stateName !== value.stateName
        ),
        1
      );
      setTownsOfInterest((prevState) => [...updateSearchLocations]);
      setPropsSetState(true);
    };

    const emailOnchange = (e) => {
      var emailPattern =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (e.target.value.match(emailPattern)) {
        setEmailData({
          ...emailData,
          showCheckBox: true,
          invalidEmail: false,
        });
      } else {
        setEmailData({
          ...emailData,
          showCheckBox: false,
          invalidEmail: false,
          createAccount: false,
          emailValue: false,
        });
      }
    };

    const handleAccount = (value) => {
      setEmailData({
        ...emailData,
        createAccount: value.target.checked,
      });
    };

    const handleToShowAlert = (value, data) => {
      var emailPattern =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (
        data &&
        data["emailAddress"] &&
        data["emailAddress"].match(emailPattern)
      ) {
        setEmailData({
          ...emailData,
          invalidEmail: false,
          showCheckBox: true,
          createAccount: value.target.checked,
        });
      } else {
        setEmailData({
          ...emailData,
          invalidEmail: value.target.checked,
        });
      }
    };
    const resetForm = () => {
      setSelectedDates({
        registered: moment(new Date()).format("L"),
        closeDate: moment(new Date()).format("L"),
        followUpdate: moment(new Date()).format("L"),
      });
      setTownsOfInterest([]);
      setPropsSetState(false);

      setPriceList({
        ...priceList,
        listSidePrice: "",
        buySidePrice: "",
      });
    };
    // console.log(proConatct,"++++++");
    return (
      <Modal
        className="modal-black-close"
        title={Object.keys(proConatct).length > 0 ? "Update Contact" : "Add New Contact"}
        visible={showModal}
        destroyOnClose={true}
        onCancel={() => {
          props.onCancel();
          resetForm();
        }}
        footer={[
          <Button
            htmlType="reset"
            onClick={() => {
              props.onCancel();
              resetForm();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="AddContactform"
            loading={contacLoading}
          >
            Save
          </Button>,
        ]}
        width={
          window.screen.width >= "768"
            ? "40%"
            : window.screen.width > "501" && window.screen.width <= "767"
            ? "50%"
            : "90%"
        }
      >
        <Form
          id="AddContactform"
          onFinish={onFinish}
          initialValues={{ remember: true }}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{
            padding: window.screen.width > 996 ? "10px" : "0px",
          }}
        >
          <Row style={{ marginTop: "1vw" }}>
            <Col
              xl={{ span: 11, offset: 0 }}
              lg={{ span: 11, offset: 0 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <Col span={24}>
                <div className="loads-select">
                  <ModalTitle>
                    Cilents/Prospect First Name{" "}
                    <span style={{ color: "red" }}>*</span>
                  </ModalTitle>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input First Name",
                      },
                    ]}
                    initialValue={Object.keys(proConatct).length > 0 ? proConatct.firstName : ""
                    }
                    style={{ marginBottom: "1vw" }}
                  >
                    <Input
                      placeholder="Cilents/Prospect First Name"
                      className="height_form"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <ModalTitle>
                  Status <span style={{ color: "red" }}>*</span>
                </ModalTitle>
                <Form.Item
                  name="listingStatus"
                  rules={[
                    {
                      required: true,
                      message: "Please input status",
                    },
                  ]}
                  style={{ marginBottom: "1vw",cursor:"pointer"  }}
                  initialValue={
                    proConatct.listingStatus && proConatct.listingStatus
                      ? proConatct.listingStatus
                      : "Prospective Buyer"
                  }
                >
                  <Select
                    defaultValue={"Select.."}
                    showSearch
                    options={status.label}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ borderRadius: "5px ",cursor:"pointer" }}
                    className="select_height"
                  >
                    {status.map((province) => (
                      <Select.Option
                        key={province.value}
                        value={province.value}
                      >
                        {province.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <div className="loads-select">
                  <ModalTitle>Rating A,B,C,D</ModalTitle>
                  <Form.Item
                    name="timeframe"
                    rules={[
                      {
                        required: false,
                        message: "Please input rating",
                      },
                    ]}
                    style={{ marginBottom: "1vw" }}
                    initialValue={
                      proConatct.timeframe
                        ? proConatct.timeframe
                        : "B - Warm(3-6 months)"
                    }
                  >
                    <Select
                      defaultValue={"Select.."}
                      // showSearch
                      options={rating.label}
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.props.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      style={{ borderRadius: "5px" }}
                    >
                      {rating.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Col>
            <Col
              xl={{ span: 11, offset: 2 }}
              lg={{ span: 11, offset: 2 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <Col span={24}>
                <ModalTitle>
                  Cilents/Prospect Last Name{" "}
                  <span style={{ color: "red" }}>*</span>
                </ModalTitle>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Last Name",
                    },
                  ]}
                  initialValue={proConatct.lastName ? proConatct.lastName : ""}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input
                    placeholder="Cilents/Prospect Last Name"
                    className="height_form"
                  />
                </Form.Item>
              </Col>

              <Col span={24} style={{ marginBottom: "1vw" }}>
                <ModalTitle>Date</ModalTitle>
                <Form.Item
                  name="registered"
                  rules={[
                    {
                      required: false,
                      message: "Please input date",
                    },
                  ]}
                  // initialValue={proConatct.openDate ? proConatct.openDate : ""}
                >
                  <DatePicker
                    // defaultValue={moment(selectedDates.registered, dateFormat)}
                    format="MM/DD/YYYY"
                    // disabledDate={(current) => {
                    //   return current && current < moment().subtract(1, "days");
                    // }}
                    style={{
                      height: "40px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    onChange={(e) => handleToSetDate(e, "registered")}
                  />
                </Form.Item>
              </Col>

              <Col span={24} style={{ marginBottom: "1vw" }}>
                <div className="loads-select">
                  <ModalTitle>Type of Client</ModalTitle>
                  <Form.Item
                    name="typeofProperty"
                    rules={[
                      {
                        required: false,
                        message: "Please input client",
                      },
                    ]}
                    initialValue={
                      proConatct.typeofProperty
                        ? proConatct.typeofProperty
                        : "Single Family"
                    }
                  >
                    <Select
                      defaultValue={"Select.."}
                      showSearch
                      options={typeClient.label}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ borderRadius: "5px " }}
                    >
                      {typeClient.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Col>
            <Col
              xl={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <ModalTitle>Town/Towns</ModalTitle>
              <Form.Item
                name="favoriteCity"
                rules={[
                  {
                    required: false,
                    message: "Please input Town/Towns",
                  },
                ]}
                // initialValue={proConatct.town ? proConatct.town : ""}
              >
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_MAPS_KEY}
                  autocompletionRequest={{
                    types: ["(cities)"],
                    componentRestrictions: {
                      country: ["us"],
                    },
                  }}
                  selectProps={{
                    value: "",
                    placeholder: "Enter your interested town",
                    onChange: (result) => {
                      handleServiceAreas(result);
                    },
                  }}
                ></GooglePlacesAutocomplete>
                {townsOfInterest.length > 0 &&
                  townsOfInterest.map((city, index) => (
                    <>
                      <div
                        key={index}
                        style={{
                          display: "inline-flex",
                          margin: "0.73vw",
                          padding: "0 7px",
                          flexWrap: "wrap",
                          justifyContent: "space-around",
                          border: "1px solid darkgrey",
                          marginLeft: "0px",
                        }}
                      >
                        <div>
                          {city.cityName}, {city.stateName}
                          {typeof city !== "object" ? city : ""}
                        </div>
                        <div
                          style={{
                            color: "grey",
                            cursor: "pointer",
                            marginLeft: "0.4vw",
                          }}
                          onClick={() => onClose(city)}
                        >
                          x
                        </div>
                      </div>
                    </>
                  ))}
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginBottom: "1vw" }}>
              <Collapse bordered={false}>
                <Panel
                  header="Address(for all sellers & buyers once UAG)"
                  key="1"
                  // className="customPanelStyle"
                >
                  <Row>
                    <Col
                      xl={{ span: 11, offset: 0 }}
                      lg={{ span: 11, offset: 0 }}
                      sm={{ span: 22, offset: 1 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <Col span={24}>
                        <ModalTitle>Address</ModalTitle>
                        <Form.Item
                          name="address"
                          rules={[
                            {
                              required: false,
                              message: "Please input address",
                            },
                          ]}
                          initialValue={proConatct?.address?.citySearch}
                          style={{ marginBottom: "1vw" }}
                        >
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_MAPS_KEY}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              citySearch,
                              defaultInputValue: `${
                                proConatct?.address?.citySearch || ""
                              }`,
                              placeholder: "Search Address...",
                              onChange: (address) => {
                                handleSelect(address);
                              },
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} style={{ marginBottom: "1vw" }}>
                        <ModalTitle>State</ModalTitle>
                        <Input
                          placeholder="State"
                          className="height_form"
                          value={
                            addressState || proConatct?.address?.addressState
                          }
                          readOnly
                        />
                      </Col>

                      <Col span={24}>
                        <ModalTitle>
                          Home address(if not owner occupied)
                        </ModalTitle>
                        <Form.Item
                          name="homeAddress"
                          rules={[
                            {
                              required: false,
                              message: "Please input homeAddress",
                            },
                          ]}
                          initialValue={proConatct?.homeAddress?.homeCitySearch}
                          style={{ marginBottom: "1vw" }}
                        >
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_MAPS_KEY}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              homeCitySearch,
                              defaultInputValue: `${
                                proConatct?.homeAddress?.homeCitySearch || ""
                              }`,
                              placeholder: "Search Address...",
                              onChange: (address) => {
                                handleSelectAddress(address);
                              },
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24} style={{ marginBottom: "1vw" }}>
                        <ModalTitle>State</ModalTitle>
                        <Input
                          placeholder="State"
                          className="height_form"
                          value={
                            homeAddressState ||
                            proConatct?.homeAddress?.homeAddressState
                          }
                          readOnly
                        />
                      </Col>
                    </Col>
                    <Col
                      xl={{ span: 11, offset: 2 }}
                      lg={{ span: 11, offset: 2 }}
                      sm={{ span: 22, offset: 1 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <Col span={24} style={{ marginBottom: "1vw" }}>
                        <ModalTitle style={{ marginBottom: "4px" }}>
                          City
                        </ModalTitle>
                        <Input
                          placeholder="City"
                          className="height_form"
                          value={city || proConatct?.address?.city}
                          readOnly
                        />
                      </Col>

                      <Col span={24} style={{ marginBottom: "1vw" }}>
                        <ModalTitle>Zipcode</ModalTitle>
                        <Input
                          placeholder="Zip code"
                          className="height_form"
                          value={zipcode || proConatct?.address?.zipcode}
                          readOnly
                        />
                      </Col>
                      <Col span={24} style={{ marginBottom: "1vw" }}>
                        <ModalTitle>City</ModalTitle>
                        <Input
                          placeholder="City"
                          className="height_form"
                          value={homeCity || proConatct?.homeAddress?.city}
                          readOnly
                        />
                      </Col>

                      <Col span={24} style={{ marginBottom: "1vw" }}>
                        <ModalTitle>Zipcode</ModalTitle>
                        <Input
                          placeholder="Zip code"
                          className="height_form"
                          value={
                            homezipcode || proConatct?.homeAddress?.homezipcode
                          }
                          readOnly
                        />
                      </Col>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>

            <Col
              xl={{ span: 11, offset: 0 }}
              lg={{ span: 11, offset: 0 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <ModalTitle>
                Primary email<span style={{ color: "red" }}></span>
              </ModalTitle>

              <Form.Item
                name="emailAddress"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid Email",
                  },
                  {
                    required: false,
                    message: "Please Input Your Primary Email !",
                  },
                ]}
                initialValue={proConatct?.emailAddress}
                style={{ marginBottom: "0vw" }}
              >
                <Input
                  placeholder="Primary email"
                  className="height_form"
                  onChange={emailOnchange}
                  readOnly={proConatct?.createAccount ? true : false}
                />
              </Form.Item>
              {emailData.invalidEmail && (
                <span style={{ color: "red" }}>
                  {" "}
                  Please Input Valid Email !{" "}
                </span>
              )}
            </Col>
            <Col
              xl={{ span: 11, offset: 2 }}
              lg={{ span: 11, offset: 2 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <ModalTitle>Secondary email</ModalTitle>

              <Form.Item
                name="secondaryEmail"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid Corporate Email",
                  },
                  {
                    required: false,
                    message: "Please input your secondaryEmail!",
                  },
                ]}
                initialValue={proConatct?.secondaryEmail}
                style={{ marginBottom: "1vw" }}
              >
                <Input
                  placeholder="Secondary email"
                  className="height_form"
                  onChange={emailOnchange}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: "1vw" }}>
              <Checkbox
                checked={
                  emailData.createAccount
                    ? emailData.createAccount
                    : proConatct && "createAccount" in proConatct === true
                    ? proConatct.createAccount
                    : emailData.createAccount
                }
                disabled={proConatct.createAccount === true ? true : false}
                onChange={
                  emailData.showCheckBox
                    ? handleAccount
                    : (e) => handleToShowAlert(e, proConatct)
                }
              />
              <span style={{ marginLeft: "5px" }}>
                {" "}
                Add contact as a user on{" "}
              </span>
              <a href={window.location.origin} target="_blank" rel="noreferrer">
                {window.location.hostname}.
              </a>
              {/* </Checkbox> */}
            </Col>

            <Col
              xl={{ span: 11, offset: 0 }}
              lg={{ span: 11, offset: 0 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <Col span={24}>
                <ModalTitle>
                  Primary Client Phone<span style={{ color: "red" }}>*</span>
                </ModalTitle>
                <Form.Item
                  name="cellPhone"
                  rules={[
                    {
                      required: true,
                      message: "Please input Primary Client Phone",
                    },
                  ]}
                  initialValue={proConatct?.cellPhone}
                  style={{ marginBottom: "1vw" }}
                >
                  <NumberFormat
                    className="ant-input-number height_form"
                    placeholder="Primary client phone"
                    format="(###) ###-####"
                    style={{
                      width: "100%",
                      padding: "4px 11px",
                      height: "40px",
                      fontSize: "14px",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <ModalTitle>Referred by (client referral tracking)</ModalTitle>
                <Form.Item
                  name="referredByClient"
                  rules={[
                    {
                      required: false,
                      message: "Please input referredByClient",
                    },
                  ]}
                  initialValue={
                    proConatct.referredByClient
                      ? proConatct.referredByClient
                      : ""
                  }
                  style={{ marginBottom: "1vw" }}
                >
                  <Input
                    placeholder="referredByClients"
                    className="height_form"
                  />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginBottom: "1vw" }}>
                <ModalTitle>List Side Price point or UAG $ </ModalTitle>
                <InputNumber
                  placeholder="Price"
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  className="ant-input-number height_form"
                  style={{
                    width: "100%",
                    padding: "4px 11px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  value={`${
                    priceList?.listSidePrice ? priceList?.listSidePrice : ""
                  }`}
                  onChange={(value) => {
                    setPriceList({ ...priceList, listSidePrice: value });
                    setPropsSetState(true);
                  }}
                />
                {/* </Form.Item> */}

                <span className="modal_description">
                  Average price range,List price,or CTG/UAG price
                </span>
              </Col>
              <Col span={24} style={{ marginBottom: "1vw" }}>
                <div className="loads-select">
                  <ModalTitle>Seller side Commission %</ModalTitle>
                  <Form.Item
                    name="sellerSideCommission"
                    rules={[
                      {
                        required: false,
                        message: "Please input commission",
                      },
                    ]}
                    initialValue={
                      proConatct.sellerSideCommission
                        ? proConatct.sellerSideCommission
                        : 2.25
                    }
                  >
                    <Input placeholder="2.25%" className="height_form" />
                  </Form.Item>
                </div>
                <span className="modal_description">
                  2.25% recommended if not CTG/UAG/LISTED
                </span>
              </Col>
              <Col span={24} style={{ marginBottom: "1vw" }}>
                <div className="loads-select">
                  <ModalTitle>
                    Seller side Referral Fee/TM Fee
                    <span style={{ color: "red" }}>*</span>
                  </ModalTitle>
                  <Form.Item
                    name="sellerSideReferralFee"
                    rules={[
                      {
                        required: true,
                        message: "Please input referralFee",
                      },
                    ]}
                    initialValue={
                      proConatct?.sellerSideReferralFee
                        ? proConatct?.sellerSideReferralFee
                        : 0
                    }
                  >
                    <Select
                      defaultValue={"Select.."}
                      // showSearch
                      options={referralFee.label}
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.props.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      style={{ borderRadius: "5px " }}
                    >
                      {referralFee.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <span className="modal_description">
                  Choose .25 for office lead, .35 for Opcity
                </span>
              </Col>
              <Col span={24} style={{ marginBottom: "1vw" }}>
                <ModalTitle>Closing date</ModalTitle>
                <Form.Item
                  name="closeDate"
                  rules={[
                    {
                      required: false,
                      message: "Please input date",
                    },
                  ]}
                  // initialValue={
                  //   proConatct.closeDate ? proConatct.closeDate : moment()
                  // }
                >
                  <DatePicker
                    format="MM/DD/YYYY"
                    // defaultValue={
                    //   props.proConatct !== ""
                    //     ? moment(selectedDates.closeDate, dateFormat)
                    //     : ""
                    // }
                    // disabledDate={(current) => {
                    //   return current && current < moment().subtract(1, "days");
                    // }}
                    style={{
                      height: "40px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    onChange={(e) => handleToSetDate(e, "closeDate")}
                  />
                </Form.Item>
                <span className="modal_description">
                  Only if UAG/CTG, can add later
                </span>
              </Col>
              <Col span={24}>
                <ModalTitle style={{ marginTop: "10px" }}>
                  Assign Agent
                </ModalTitle>
                <Form.Item
                  name="assignAgent"
                  rules={[
                    {
                      required: false,
                      message: "Please input your agent!",
                    },
                  ]}
                  initialValue={
                    loginDetails?.userProfile !== "AG"
                      ? proConatct?.assignAgent?.length > 0 &&
                        proConatct?.assignAgent[0]["id"]
                      : loginDetails?.id
                  }
                >
                  <Select
                    allowClear
                    // defaultValue={"attendees.."}
                    showSearch
                    options={allAgents.firstName}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ borderRadius: "5px " }}
                    placeholder="assignAgent.."
                    // defaultValue={

                    // }
                  >
                    {allAgents.map((province) => (
                      <Select.Option key={province.id} value={province.id}>
                        {province.firstName + " " + province.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={24} style={{ marginBottom: "1vw" }}>
                <ModalTitle>Added to CRM</ModalTitle>
                <Form.Item
                  name="crm"
                  rules={[
                    {
                      required: false,
                      message: "Please input crm",
                    },
                  ]}
                  initialValue={proConatct.crm ? proConatct.crm : ""}
                >
                  <Select
                    defaultValue={"Select.."}
                    showSearch
                    style={{ borderRadius: "5px " }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
                <span className="modal_description">
                  To confirm you have added them to an email drip campaign
                </span>
              </Col> */}
            </Col>
            <Col
              xl={{ span: 11, offset: 2 }}
              lg={{ span: 11, offset: 2 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <Col span={24}>
                <ModalTitle>Secondary Client Phone</ModalTitle>
                <Form.Item
                  name="WorkPhone"
                  rules={[
                    {
                      required: false,
                      message: "Please input secondaryPhone",
                    },
                  ]}
                  initialValue={proConatct?.WorkPhone}
                  style={{ marginBottom: "1vw" }}
                >
                  <NumberFormat
                    className="ant-input-number height_form"
                    placeholder="Secondary client phone"
                    format="(###) ###-####"
                    style={{
                      width: "100%",
                      padding: "4px 11px",
                      height: "40px",
                      fontSize: "14px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="loads-select">
                  <ModalTitle>
                    Source of lead<span style={{ color: "red" }}>*</span>
                  </ModalTitle>
                  <Form.Item
                    name="sourceLead"
                    rules={[
                      {
                        required: true,
                        message: "Please input Source of lead",
                      },
                    ]}
                    style={{ marginBottom: "1vw" }}
                    initialValue={
                      proConatct.sourceLead ? proConatct.sourceLead : ""
                    }
                  >
                    {/* {proConatct.sourceLead.toLowerCase().replace(" ","")} */}
                    <Select
                      defaultValue={"Select.."}
                      showSearch
                      options={sourceLead.label}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ borderRadius: "5px " }}
                      disabled={
                        proConatct?.sourceLead
                          ?.toLowerCase()
                          .replace(" ", "") !== "officelead"
                          ? false
                          : loginDetails.userProfile === "A"
                          ? false
                          : true
                      }
                    >
                      {sourceLead.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} style={{ marginBottom: "1vw" }}>
                <ModalTitle>Buy Side Price point or UAG $</ModalTitle>
                <InputNumber
                  placeholder="Price"
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  // prefix="$"
                  className="ant-input-number height_form"
                  style={{
                    width: "100%",
                    padding: "4px 11px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  value={`${
                    priceList?.buySidePrice ? priceList?.buySidePrice : ""
                  }`}
                  onChange={(value) => {
                    setPriceList((prev) => ({
                      ...priceList,
                      buySidePrice: value,
                    }));
                    setPropsSetState(true);
                  }}
                />
                <span className="modal_description">
                  Average price range,List price,or CTG/UAG price
                </span>
              </Col>
              <Col span={24} style={{ marginBottom: "1vw" }}>
                <div className="loads-select">
                  <ModalTitle>Buyer side Commission %</ModalTitle>
                  <Form.Item
                    name="buyerSideCommission"
                    rules={[
                      {
                        required: false,
                        message: "Please input commission",
                      },
                    ]}
                    initialValue={
                      proConatct?.buyerSideCommission
                        ? proConatct?.buyerSideCommission
                        : 2.25
                    }
                  >
                    <Input placeholder="2.25%" className="height_form" />
                  </Form.Item>
                </div>
                <span className="modal_description">
                  2.25% recommended if not CTG/UAG/LISTED
                </span>
              </Col>{" "}
              <Col span={24} style={{ marginBottom: "1vw" }}>
                <div className="loads-select">
                  <ModalTitle>
                    Buyer side Referral Fee/TM Fee
                    <span style={{ color: "red" }}>*</span>
                  </ModalTitle>
                  <Form.Item
                    name="buyerSideReferralFee"
                    rules={[
                      {
                        required: true,
                        message: "Please input referralFee",
                      },
                    ]}
                    initialValue={
                      proConatct?.buyerSideReferralFee
                        ? proConatct.buyerSideReferralFee &&
                          proConatct.buyerSideReferralFee
                        : 0
                    }
                  >
                    <Select
                      defaultValue={"Select.."}
                      // showSearch
                      options={referralFee.label}
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.props.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      style={{ borderRadius: "5px " }}
                    >
                      {referralFee.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <span className="modal_description">
                  Choose .25 for office lead, .35 for Opcity
                </span>
              </Col>
              <Col span={24} style={{ marginBottom: "1vw" }}>
                <ModalTitle>Follow up date</ModalTitle>
                <Form.Item
                  name="followDate"
                  rules={[
                    {
                      required: false,
                      message: "Please input date",
                    },
                  ]}
                  // initialValue={
                  //   proConatct.followDate ? proConatct.followDate : moment()
                  // }
                >
                  <DatePicker
                    format="MM/DD/YYYY"
                    // defaultValue={
                    //   // props.proConatct !== "" &&
                    //   moment(selectedDates.followUpdate, dateFormat)
                    // }
                    disabledDate={(current) => {
                      return current && current < moment().subtract(1, "days");
                    }}
                    style={{
                      height: "40px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    onChange={(e) => handleToSetDate(e, "followUpdate")}
                  />
                </Form.Item>
                <span className="modal_description">
                  When you want to follow up next
                </span>
              </Col>
              <Col span={24} style={{ marginTop: "1vw" }}>
                <ModalTitle>Picture</ModalTitle>

                <Form.Item
                  name="picture"
                  rules={[
                    {
                      required: false,
                      message: "Please input your image!",
                    },
                  ]}
                  initialValue={proConatct.picture ? proConatct.picture : ""}
                >
                  <Upload
                    customRequest={dummyRequest}
                    onChange={(e) => handleUploadPic(e)}
                    listType="picture"
                    fileList={fileList}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Upload picture</Button>
                  </Upload>
                </Form.Item>
                <span className="modal_description">
                  Can upload sticky notes, notes you wrote down, etc.
                </span>
              </Col>
            </Col>

            <Col
              xl={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              style={{ marginBottom: "1vw" }}
            >
              <Collapse bordered={false}>
                <Panel
                  header="Notes"
                  key="1"
                  // className="customPanelStyle"
                >
                  <Row>
                    <Col span={24}>
                      <ModalTitle style={{ marginBottom: "2px" }}>
                        Notes on lead(private)
                      </ModalTitle>
                      <span className="modal_description">
                        Will only be seen by you. Use date to track when notes
                        added (4/13: called LM)
                      </span>
                      <Form.Item
                        name="privateNotes"
                        rules={[
                          {
                            required: false,
                            message: "Please input notes",
                          },
                        ]}
                        initialValue={proConatct?.privateNotes}
                        style={{ marginBottom: "1vw", marginTop: "10px" }}
                      >
                        <TextArea
                          style={{ borderRadius: "5px " }}
                          placeholder="Start Typing.."
                          rows={3}
                          name="privateNotes"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <ModalTitle style={{ marginBottom: "2px" }}>
                        Notes on lead(public)
                      </ModalTitle>
                      <span className="modal_description">
                        Agents in office can see any info entered here
                      </span>
                      <Form.Item
                        name="notes"
                        rules={[
                          {
                            required: false,
                            message: "Please input notes",
                          },
                        ]}
                        initialValue={proConatct?.notes}
                        style={{ marginBottom: "1vw", marginTop: "10px" }}
                      >
                        <TextArea
                          style={{ borderRadius: "5px " }}
                          placeholder="Start Typing.."
                          rows={3}
                          name="notes"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };

  const handleUploadPic = async (info) => {
    props.setLoading(true);
    switch (info.file.status) {
      case "done":
        setPropsSetState(true);

        if (pictureCard !== "") {
          alert("You can't add more than one picture.");
          props.setLoading(false);
          return;
        }
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              setFileList(info.fileList);
              let fileName = info.file.uid + "_" + info.file.name;
              Storage.put(fileName, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                props.setLoading(false);
                setPictureCard(result.key);
                setPropsSetState(true);
              });
            } else {
              props.setLoading(false);
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      case "removed":
        setFileList([]);
        setPictureCard("");
        props.setLoading(false);
        setPropsSetState(true);
        break;
      default:
    }
  };
  const onFinish = (values) => {
    const { proConatct } = props;
    setContacLoading(true);
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let orgnizationId = JSON.parse(localStorage.getItem("orgId"));
    let id = userDetails && userDetails?.id;
    let newAddress = {
      citySearch: citySearch || proConatct?.address?.citySearch,
      city: city || proConatct?.address?.city,
      addressState: addressState || proConatct?.address?.addressState,
      zipcode: zipcode || proConatct?.address?.zipcode,
    };
    values["contactType"] = "Contact";
    values["address"] = newAddress;
    values["homeAddress"] = {
      city: homeCity || proConatct?.homeAddress?.city,
      homeAddressState:
        homeAddressState || proConatct?.homeAddress?.homeAddressState,
      homezipcode: homezipcode || proConatct?.homeAddress?.homezipcode,
      homeCitySearch: homeCitySearch || proConatct?.homeAddress?.homeCitySearch,
    };
    values["leadState"] =
      addressState || proConatct ? proConatct?.address?.addressState : "";
    values["city"] = city || proConatct ? proConatct?.address?.city : "";
    values["zIP"] = zipcode || proConatct ? proConatct?.address?.zipcode : "";
    values["businessCard"] = pictureCard;

    values["picture"] = "";
    values["registered"] = selectedDates.registered;
    values["closeDate"] = selectedDates.closeDate;
    values["followDate"] = selectedDates.followUpdate;
    values["buysidepricepoint"] = priceList.buySidePrice;
    values["listsidepricepoint"] = priceList.listSidePrice;
    values["orgId"] = orgnizationId;
    values["agentDetails"] = {
      full_name: userDetails?.firstName + " " + userDetails.lastName,
      agent_id: userDetails.id,
      email: userDetails.email,
      orgId: userDetails.orgId,
    };
    // return
    values["favoriteCity"] = townsOfInterest;
    if (typeof values["emailAddress"] === "undefined") {
      values["emailAddress"] = "";
    } else {
      values["emailAddress"] =
        values["emailAddress"] !== ""
          ? values["emailAddress"]?.toLowerCase()
          : "";
    }
    let contactPhone =
      typeof values["cellPhone"] !== "number"
        ? values["cellPhone"].replace(/[^+0-9]/g, "")
        : values["cellPhone"];
    values["cellPhone"] = parseInt(contactPhone);
    if(emailData.createAccount){
      values["createAccount"] = emailData.createAccount;
    }
    values["assignAgent"] = values["assignAgent"] ? allAgents.filter(
      (a) => values["assignAgent"] === a.id && a 
    ): [userDetails]

    values["userId"] =
      values["assignAgent"].length > 0 ? values["assignAgent"][0]["id"] : id;

    values["firstName"] =
      values["firstName"].charAt(0).toUpperCase() +
      values["firstName"].slice(1);
    values["lastName"] =
      values["lastName"].charAt(0).toUpperCase() + values["lastName"].slice(1);
    let isAssignedAgentUpdation =
      userDetails.id === values["userId"] ? false : true;
    values["isAssignedAgentUpdation"] = isAssignedAgentUpdation;

    if (values["assignAgent"].length > 0) {
      let assignAGobject = {
        firstName: values["assignAgent"][0]["firstName"],
        lastName: values["assignAgent"][0]["lastName"],
        id: values["assignAgent"][0]["id"],
        email: values["assignAgent"][0]["email"],
        userProfile: values["assignAgent"][0]["userProfile"],
        lastActive: values["assignAgent"][0]["lastActive"],
        isActive: values["assignAgent"][0]["isActive"],
        loginCount: values["assignAgent"][0]["loginCount"],
        orgId: values["assignAgent"][0]["orgId"],
        phone: values["assignAgent"][0]["phone"],
      };
      values["assignAgent"] = [assignAGobject];
    }
    // console.log(values,"fff");
    // return
    let data = {
      entityData: values,
    };
    if (props.editModal) {
      update("virtualContactLeads", proConatct.id, data)
        .then((result) => {
          if (result.success) {
            setContacLoading(false);
            message.success("Updated successfully !");
            props.onCancel();
            props.getUserProfile(true);
            setPropsSetState(false);
          }
        })
        .catch((err) => {
          props.onCancel();
          console.log(err);
          setPropsSetState(false);
        });
    } else {
      data["entityData"]["lastActiveDate"] = moment(moment(values["registered"]).format("MM/DD/YYY")).unix()

      add("virtualContactLeads", data)
        .then((result) => {
          if (result.success) {
            setContacLoading(false);
            if (result.data.message === "Failure") {
              alert(result.data.data);
              props.setLoading(false);

              return;
            } else {
              message.success("Contact created successfully !");
              props.onCancel();
              props.getUserProfile(true);
              setPropsSetState(false);
            }
          }
        })
        .catch((err) => {
          props.onCancel();
          console.log(err);
          setContacLoading(false);
        });
    }
  };

  const typeClient = [
    { label: "Single Family", value: "Single Family" },
    { label: "Two Family", value: "Two Family" },
    { label: "Three Family", value: "Three Family" },
    { label: "Commercial or Investor", value: "Commercial or Investor" },
    { label: "Land", value: "Land" },
    { label: "First time buyer+", value: "First time buyer+" },
    { label: "UpSize", value: "UpSize" },
    { label: "Down Size", value: "Down Size" },
    { label: "Relocation", value: "Relocation" },
    { label: "Condo", value: "Condo" },
  ];

  const status = [
    { label: "All", value: "all" },
    { label: "Closed", value: "Closed" },
    { label: "Under Agreement", value: "Under Agreement" },
    { label: "Active Listing", value: "Active Listing" },
    { label: "Active Buyer", value: "Active Buyer" },
    { label: "Prospective Buyer", value: "Prospective Buyer" },
    {
      label: "Prospective Seller",
      value: "Prospective Seller",
    },
    {
      label: "Prospective Seller and Buyer",
      value: "Prospective seller or buyer",
    },
    {
      label: "Past Client",
      value: "Past Client",
    },
    {
      label: "CRM entry",
      value: "CRM entry",
    },
    {
      label: "Import",
      value: "Import",
    },
  ];

  const rating = [
    { label: "A - Hot(1-3 months)", value: "A - Hot(1-3 months)" },
    {
      label: "B - Warm(3-6 months)",
      value: "B - Warm(3-6 months)",
    },
    { label: "C - Cool(6-12 months)", value: "C - Cool(6-12 months))" },
    { label: "D - Long term(12+ months)", value: "D - Long term(12+ months)" },
  ];

  const sourceLead = [
    { label: "Sphere referral", value: "Sphere referral" },
    { label: "Family or Friend", value: "Family and Friend" },
    { label: "Return Client", value: "Return Client" },
    { label: "Google Advertising", value: "Google Advertising" },
    { label: "Facebook Organic", value: "Facebook Organic" },
    { label: "Facebook Paid Ad", value: "Facebook Paid Ad" },
    { label: "Realtor.com", value: "Realtor.com" },
    { label: "Zillow ", value: "Zillow" },
    { label: "Opcity", value: "Opcity" },
    {
      label: "Office lead- Facebook or Google",
      value: "Office lead- Facebook or Google",
    },
    {
      label: "Office lead- Realtor.com and Zillow",
      value: "Office lead- Realtor.com and Zillow",
    },
    {
      label: "Office lead- Seller or Home Value Lead",
      value: "Office lead- Seller or Home Value Lead",
    },
    { label: "Sign call", value: "Sign call" },
    { label: "Open house", value: "Open house" },
    { label: "Farm mailing call", value: "Farm mailing call" },
    { label: "Meet on the street", value: "Meet on the street" },
    { label: "2nd Level referral", value: "2nd Level referral" },
    { label: "3rd level referral ", value: "3rd level referral" },
    {
      label: "Referral from FB paid lead ",
      value: "Referral from FB paid lead ",
    },
    {
      label: "Referral from Zillow paid lead ",
      value: "Referral from Zillow paid lead ",
    },
    {
      label: "Referral from Realtor.com paid lead ",
      value: "Referral from Realtor.com paid lead",
    },
    { label: "Agent to agent referral", value: "Agent to agent referral" },
    { label: "FSBO", value: "FSBO" },
    { label: "Expired", value: "Expired" },
    { label: "Upnest referral", value: "Upnest referral" },
    { label: "Other (see notes)", value: "Other (see notes)" },
  ];

  const referralFee = [
    { label: "0", value: "0" },
    { label: ".1", value: "0.1" },
    { label: ".15", value: "0.15" },
    { label: ".2", value: "0.2" },
    { label: ".25", value: "0.25" },
    { label: ".3", value: "0.3" },
    { label: ".35", value: "0.35" },
    { label: ".4", value: "0.4" },
    { label: ".45", value: "0.45" },
    { label: ".5", value: "0.5" },
  ];

  const onFinishFailed = (errorInfo) => {
    if (errorInfo?.errorFields.length > 0) {
      let newErrorFileds = [];

      errorInfo?.errorFields.map((eachFields) => {
        if (eachFields.name[0] === "firstName") {
          newErrorFileds.push("First Name");
        } else if (eachFields.name[0] === "lastName") {
          newErrorFileds.push("Last Name");
        } else if (eachFields.name[0] === "favoriteCity") {
          newErrorFileds.push("Interested towns");
        } else if (eachFields.name[0] === "cellPhone") {
          newErrorFileds.push("Primary Phone");
        } else if (eachFields.name[0] === "sourceLead") {
          newErrorFileds.push("Source of lead");
        }
        return true;
      });
      notification.error({
        message: `Please fill following fileds ${newErrorFileds.join(", ")}`,
      });
    }
  };
  return (
    <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
      {props.showModal &&
        openModal(
          props?.showModal,
          props?.loading,
          props?.proConatct,
          props?.fileList,
          props?.pictureCard,
          props?.townsOfInterest,
          props?.editModal
        )}
    </div>
  );
}
export default withAppContext(withRouter(AddContact));
