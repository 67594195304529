import React from "react";
import { Row, Card, Button, Modal } from "antd";
import { withRouter } from "react-router";
import "antd/dist/antd.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { noAuthlist } from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import BuyerAgentProfile from "../components/Card/BuyerAgentCard";
export class Advertisement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loanVisible: false,
      smallAdsList: [],
      bigAdsList: [],
      showAdPage: false,
      specificAdPage: false,
      adPageUrl: "",
      propertyZipCode: "",
      lendersDetails: [],
      agentsDetails: [],

      otherAds: false,
      adType: "Square",
      adWhereToShow: "Homes",
      adLimit: "0",
      zipCode: [],
    };
  }

  componentDidMount = () => {
    AOS.init({
      duration: 1500,
    });
    this.getAllUsers();
    this.getAllAgents();
    this.getAds();
  };

  getAllUsers = async () => {
    const allUsers = await noAuthlist("getUsers?userProfile=L&isActive=true");
    const allFilterLenders =
      allUsers.data &&
      allUsers.data.filter(
        (o) =>
          o.subscriptionPack &&
          o.subscriptionPack &&
          parseInt(o.subscriptionPack.price) > 0
      );

    const FilterLenders =
      allFilterLenders &&
      allFilterLenders.sort((a, b) =>
        parseInt(a.subscriptionPack.price) > parseInt(b.subscriptionPack.price)
          ? -1
          : 1
      );

    const lendersDetails = [];
    if (FilterLenders) {
      for (let singleItem of FilterLenders) {
        let newImage = "";
        if (singleItem.profilePicture) {
          let newImage2 =
            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            singleItem.profilePicture[0];
          newImage = newImage2;
          singleItem["AgnetProfileUrl"] = newImage;
          lendersDetails.push({ ...singleItem });
        } else {
          singleItem["AgnetProfileUrl"] = "";
          lendersDetails.push({ ...singleItem });
        }
      }
      this.setState({ lendersDetails: lendersDetails });
    }
  };

  getAllAgents = async () => {
    const allAgentsUsers = await noAuthlist("getAgents",{isActive:"true"});
    const allFilterAgents =
      allAgentsUsers.data &&
      allAgentsUsers.data.filter(
        (agent) => agent.email === process.env.REACT_APP_CLIENT_AGENT_EMAIL
      );
    const agentsDetails = [];
    if (allFilterAgents) {
      for (let singleItem of allFilterAgents) {
        let newImage = "";
        if (singleItem.profilePicture) {
          let newImage2 =
            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            singleItem.profilePicture[0];
          newImage = newImage2;
          singleItem["AgnetProfileUrl"] = newImage;
          agentsDetails.push({ ...singleItem });
        } else {
          singleItem["AgnetProfileUrl"] = "";
          agentsDetails.push({ ...singleItem });
        }
      }
      this.setState({ agentsDetails: agentsDetails });
    }
  };

  getAds = async () => {
    let adsQuery = {};
    const cityData = JSON.parse(localStorage.getItem("selectedCities"));

    if (this.props && this.props.adType && this.props.adType !== undefined) {
      let cityName = [],
        stateName = [],
        postalCode = [];
      if (this.props && !this.props.detailsPageAds) {
        this.props.context.state.filtersearch &&
          this.props.context.state.filtersearch.length > 0 &&
          this.props.context.state.filtersearch.map((eachSearch) => {
            postalCode.push(eachSearch.zipCode);
            eachSearch.multi &&
              eachSearch.multi.map((filterCity) => {
                cityName.push(filterCity.cityName);
                stateName.push(filterCity.stateName);
                return true;
              });
            return true;
          });
        cityData &&
          cityData.searchLocation.map((eachCity) => {
            cityName.push(eachCity.cityName);
            stateName.push(eachCity.stateName);
            return true;
          });

        if (cityName && cityName.length > 0) {
          adsQuery = {
            cityName: cityName.join(","),
            stateName: stateName.join(","),
          };
        }
        if (postalCode && postalCode.length > 0) {
          adsQuery = {
            ...adsQuery,
            postalCode: postalCode.join(","),
          };
        }
      } else {
        let { stateName, cityName, postalCode } = this.props;
        adsQuery = {
          cityName: cityName,
          stateName: stateName,
          postalCode: postalCode,
        };
      }
      let { otherAds, adType, adWhereToShow, adLimit, zipCode } = this.props;
      this.setState({
        otherAds: otherAds,
        adType: adType,
        adWhereToShow: adWhereToShow,
        adLimit: adLimit,
        propertyZipCode:
          zipCode !== "" && zipCode !== undefined && zipCode !== null
            ? zipCode
            : [],
      });
      adsQuery = {
        ...adsQuery,
        imageType: adType,
        whereToShow: adWhereToShow,
        limit: adLimit,
      };
      let orgId = JSON.parse(localStorage.getItem("orgId"))
      adsQuery["orgId"] = orgId
      const response = await noAuthlist("getAds", adsQuery);
      if (response.success) {
        let requiredAds = response.data;
        if (adType === "Square") {
          this.setState({
            smallAdsList: requiredAds,
          });
        } else {
          this.setState({
            bigAdsList: requiredAds,
          });
        }
      }
    }
  };

  getRandomAd = (adLength) => {
    return Math.floor(Math.random() * adLength);
  };

  handleApproveLone = () => {
    this.setState({
      loanVisible: true,
    });
  };

  loanCloseModal = () => {
    this.setState({
      loanVisible: false,
    });
  };

  handleShowAdPage = (url) => {
    this.setState({
      adPageUrl: url,
    });
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  render() {
    const {
      loanVisible,
      bigAdsList,
      smallAdsList,
      propertyZipCode,
      lendersDetails,
      agentsDetails,
      otherAds,
      adType,
    } = this.state;
    const { isLogin, cityName, postalCode, stateName } = this.props;

    return (
      <>
        {adType === "Square" ? (
          <div>
            {otherAds && (
              <Row className="row-padding">
                <Card bodyStyle={{ padding: "15px" }}>
                  <Row className="font-family-style font-medium-size center-item">
                    <p className="font-and-textalign">
                      Are you ready to buy a home?
                    </p>
                  </Row>
                  <Row className="center-item font-family-style padding-loan-btn">
                    <Button
                      type="primary"
                      className="size1-loan"
                      onClick={this.handleApproveLone}
                    >
                      Get pre approved for a loan
                    </Button>
                  </Row>
                </Card>
              </Row>
            )}

            {smallAdsList.map((ads) => (
              <div
                data-aos-offset="100"
                data-aos-delay="200"
                data-aos-once="true"
                data-aos="fade-up"
                // data-aos-easing="ease-in"
              >
                <Row className="row-padding">
                  <Card
                    hoverable
                    className="modal-add1"
                    onClick={() => this.handleShowAdPage(ads.imageUrl)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={
                        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                        ads.image
                      }
                      alt={ads.image}
                      style={{ width: "100%" }}
                    />
                  </Card>
                </Row>
              </div>
            ))}
          </div>
        ) : (
          <div
            data-aos-offset={this.props.showMatchesPage ? "0" : "100"}
            data-aos-delay={this.props.showMatchesPage ? "0" : "200"}
            data-aos-once={this.props.showMatchesPage ? "false" : "true"}
            data-aos={this.props.showMatchesPage ? "" : "fade-up"}
          >
            {bigAdsList.map((bigAds) => (
              <Card
                hoverable
                className="modal-add1"
                style={{
                  marginTop: "0.7%",
                  marginBottom: "0.7%",
                  background: "border-box",
                  cursor: "pointer",
                }}
                onClick={() => this.handleShowAdPage(bigAds.imageUrl)}
              >
                <img
                  src={
                    `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                    bigAds.image
                  }
                  alt={bigAds.image}
                  className="object-Fit"
                />
              </Card>
            ))}
          </div>
        )}

        <Modal
          className="modal-black-close"
          visible={loanVisible}
          style={{ marginTop: "2%" }}
          onCancel={() =>
            this.setState({
              loanVisible: false,
            })
          }
          footer={null}
        >
          <div>
            <Row>
              <div style={{ marginTop: "20px" }}>
                <h4>Get pre approved for a loan</h4>
                <p style={{ color: "#99A2C3", marginTop: "10px" }}>
                  Below are our preferred local lenders who will work with you
                  to get your dream home. If none are listed or you don't see a
                  good match send a message to our client concierge below and
                  our team of experts will help you find an experienced lender
                  in your area.
                </p>
              </div>
            </Row>

            {propertyZipCode !== "" &&
              propertyZipCode !== undefined &&
              propertyZipCode !== null &&
              lendersDetails &&
              lendersDetails.length > 0 &&
              lendersDetails
                .filter((owner) => {
                  if (
                    owner.newdata &&
                    owner.newdata.zipCodes &&
                    owner.newdata.zipCodes.length > 0 &&
                    owner.newdata.zipCodes.includes(propertyZipCode)
                  ) {
                    return owner;
                  }
                  return true;
                })
                .slice(0, 3)
                .map((lendersInfo) => (
                  <>
                    <BuyerAgentProfile
                      isLender={true}
                      agentInfo={lendersInfo}
                      viewProfileAuth={isLogin ? true : false}
                    />
                    <hr></hr>
                  </>
                ))}

            {agentsDetails &&
              agentsDetails.length > 0 &&
              agentsDetails.slice(0, 1).map((agentsInfo) => (
                <>
                  <BuyerAgentProfile
                    isLender={true}
                    agentInfo={agentsInfo}
                    viewProfileAuth={isLogin ? true : false}
                  />
                  <hr></hr>
                </>
              ))}
            <Row>
              <Advertisement
                otherAds={false}
                adType="Rectangle"
                adWhereToShow="PreApprovedPopup"
                adLimit="1"
                cityName={cityName}
                postalCode={postalCode}
                stateName={stateName}
                detailsPageAds={true}
              />
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

export default withAppContext(withRouter(Advertisement));
