// App.js
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import defaultImage2 from "../../../images/defaultImage2.jpg";
import { notification } from "antd";

function DetailCarsouel(props) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [click_count, set_click_count] = useState(0);

  useEffect(() => {
    if (props && props.photos && props.photos.length > 0) {
      setPhotos(props.photos);
    } else {
      handleDefaultPic();
    }
  }, [props]);

  const handleDefaultPic = () => {
    const defaultPic = [];
    defaultPic.push(defaultImage2);
    setPhotos(defaultPic);
  };

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    lazyLoad: "onDemand",
    adaptiveHeight: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    adaptiveHeight: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    lazyLoad: "onDemand",
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "0px",
    className: "down",
    beforeChange: (prev, next) => {
      // set_click_count(prev+1)
      set_click_count((prevState) => prevState + 1);
    },
  };

  let slidesData = [];
  if (photos && photos.length > 0) {
    photos.map((e, index) => {
      slidesData.push({ id: index + 1, img: e });
      return e;
    });
  }
  const favorite_property_alert = () => {
    notification["info"]({
      message:
        "Favorite this property to get alerts when there is a status change. Click on the heart symbol below the listing pictures.",
      duration: 10,
    });
    set_click_count((prevState) => prevState + 1);
  };
  return (
    <div className="App">
      <div className="slider-wrapper">
        <Slider
          {...settingsMain}
          asNavFor={nav2}
          ref={(slider) => setSlider1(slider)}
        >
          {slidesData.map((slide) => (
            <div className="slick-slide" key={slide.id}>
              <div
                style={{ textAlign: "center", backgroundColor: "#d4d8f1fa" }}
              >
                <img className="slick-slide-image" src={slide.img} alt="" />
              </div>
            </div>
          ))}
        </Slider>
        <div className="thumbnail-slider-wrap">
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            ref={(slider) => setSlider2(slider)}
          >
            {slidesData.map((slide) => (
              <div className="slick-slide" key={slide.id}>
                <img
                  className="slick-slide-image"
                  src={slide.img}
                  style={{
                    width: "100px",
                    height: "150px",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </div>
            ))}
          </Slider>
          {/* {click_count === 5 && !props.favorited && favorite_property_alert()} */}
          {photos.length !== 0 &&
            !props.favorited &&
            photos.length === click_count &&
            favorite_property_alert()}
        </div>
      </div>
    </div>
  );
}

export default DetailCarsouel;
