import {
  Row,
  Col,
  Modal,
  Button,
  notification,
  Input,
  Card,
  message,
  Select,
} from "antd";
import {
  FacebookFilled,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import RichText from "../../common/RichText";
// import profileAvatar from "../../images/profileAvatar.png";
import { add, list, update } from "../../api/Apis";

export default function EmailPopupCom(props) {

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const orgID = JSON.parse(localStorage.getItem("orgId"));


  const initialValue = {
    opeEmailPopup: false,
    emailSendRawData: "",
    emailSubject: "",
    selectedTemp: "",
  };

  const [templateDetails, setTemplateDetails] = useState({
    totalTemplatesData: [],
  });

  const [emailMessage, setEmailMessage] = useState(initialValue);
  const [previewPopup, setPreviewPopup] = useState({
    openPopup: false,
    totalPreviewData: [],
    editPopUp: false,
  });

  useEffect(() => {
    handleTogetTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTogetTemplates = () => {


    let query = {};

    // if (userDetails?.userProfile === "A") {
      query["orgId"] = orgID;
    // } else {
    //   query["userId"] = userDetails.id;
    // }
    query = {
      ...query,
      typeOfData: "templates",
    };


    list("virtualOfficeResources", query).then((res) => {
      if (res.success) {
        let templateData = res.data.filter(
          (e) => e.userId === userDetails?.id || e.showTemplate === true
        );
        setTemplateDetails((st) => ({
          ...st,
          totalTemplatesData: templateData,
        }));
      }
    });
  };

  const handleEmailModal = () => {
    if (props.selectedContacts?.length > 0) {
      // props.selectedContacts.map((eachRecord) => {
      //   deleteRecord("virtualOfficeContacts", eachRecord.id);
      // });
      setEmailMessage({
        ...emailMessage,
        opeEmailPopup: true,
        emailSendRawData: "",
      });
    } else {
      notification["info"]({
        message: "Please select one or more contacts to send an email.",
      });
    }
  };

  const handleRichtextBio = (data) => {
    setEmailMessage({ ...emailMessage, emailSendRawData: data });
  };

  const handleSendMessageData = (type) => {
    if (
      emailMessage.emailSubject !== "" ||
      emailMessage.emailSendRawData !== ""
    ) {
      let loginAgentDetails = JSON.parse(localStorage.getItem("userDetails"));
      let orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
      let websiteLogo = orgDetails["Header"]["headerLogo"];
      let orgFooterBgColor =
        orgDetails["footer"]["copyRight"]["backgroundColor"];
      let orgFooterTextColor =
        orgDetails["footer"]["copyRight"]["lastFootertextColor"];
      let senderConfig = process.env.REACT_APP_API_GATEWAY.indexOf("dev") > -1
          ? "poweredbyagents.com"
          : orgDetails["websiteName"];
      let userViewProfile =
        "userName" in loginAgentDetails === true
          ? window.location.origin + "/" + loginAgentDetails?.userName
          : window.location.origin +
            "/agent/viewProfile/" +
            loginAgentDetails?.id;
      let agentFaceBookUrl = "";
      let agentTwitterUrl = "";
      let agentYoutubeUrl = "";
      let agentPeterestUrl = "";
      let agentInstaUrl = "";
      if ("socialMedia" in loginAgentDetails === true) {
        agentFaceBookUrl = loginAgentDetails?.socialMedia?.facebook;
        agentTwitterUrl = loginAgentDetails?.socialMedia?.twitter;
        agentYoutubeUrl = loginAgentDetails?.socialMedia?.youtube;
        agentPeterestUrl = loginAgentDetails?.socialMedia?.pinterest;
        agentInstaUrl = loginAgentDetails?.socialMedia?.instagram;
      }

      let data = {
        entityData: {
          subject: emailMessage.emailSubject,
          writtenDescription: emailMessage.emailSendRawData,
          selectedContacts: props.selectedContacts,
          agentDetails: {
            name:
              loginAgentDetails?.firstName + " " + loginAgentDetails?.lastName,
            userProfileName: loginAgentDetails?.userName,
            mobileNum: loginAgentDetails?.phone,
            email: loginAgentDetails?.email,
            userProfileLink: userViewProfile,

            agentProfilePicture:
              loginAgentDetails?.profilePicture?.length > 0
                ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${loginAgentDetails?.profilePicture[0]}`
                : "https://militaryhealthinstitute.org/wp-content/uploads/sites/37/2021/08/blank-profile-picture-png.png",
            agentFaceBookUrl,
            agentTwitterUrl,
            agentYoutubeUrl,
            agentPeterestUrl,
            agentInstaUrl,
          },
          orgnizationLogo: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${websiteLogo}`,
          footerBackgrounndColor: orgFooterBgColor,
          footerTextColor: orgFooterTextColor,

          webDomainName: window.location.hostname,

          faceBookUrl: orgDetails["footer"]["tabs"]["fbUrl"],
          twitterUrl: orgDetails["footer"]["tabs"]["twitterUrl"],
          youtubeUrl: orgDetails["footer"]["tabs"]["youtubeUrl"],
          peterestUrl: "https://in.pinterest.com/",
          instgramUrl: orgDetails["footer"]["tabs"]["instaUrl"],

          orgnizationName: orgDetails["orgName"],
          websiteUrl: "https://" + orgDetails["websiteName"],
          senderEmail: orgDetails["orgEmailAddress"] + "@" + senderConfig,
        },
      };

      if (type === "preview") {
        setPreviewPopup({
          ...previewPopup,
          totalPreviewData: data["entityData"],
          openPopup: true,
          editPopUp: false,
        });
        setEmailMessage({
          ...emailMessage,
          opeEmailPopup: false,
        });
      } else {
        add("sendContactLeadEmail", data)
          .then((res) => {
            if (res.success) {
              notification["success"]({
                message: "Email was sent successfully",
                duration: 3,
              });
              setEmailMessage({
                opeEmailPopup: false,
                emailSendRawData: "",
                emailSubject: "",
              });
              setPreviewPopup({
                openPopup: false,
                totalPreviewData: [],
                editPopUp: false,
              });
              setTimeout(() => {
                props.handleToRestContactSelection();
              }, 1000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      message.error("Please input your fields");
    }
  };

  const handleCanel = () => {
    setEmailMessage({
      opeEmailPopup: false,
      emailSendRawData: "",
      emailSubject: "",
    });
    setPreviewPopup({
      openPopup: false,
      totalPreviewData: [],
      editPopUp: false,
    });
    setTimeout(() => {
      props.handleToRestContactSelection();
    }, 1000);
  };

  const handleEditChange = (type) => {
    if (type === "edit") {
      setPreviewPopup({ ...previewPopup, editPopUp: true });
    } else {
      setPreviewPopup({ ...previewPopup, editPopUp: false });
      handleSendMessageData("preview");
    }
  };

  const showPreviewModal = () => {
    return (
      <Modal
        className="modal-black-close"
        visible={previewPopup.openPopup}
        title={`Preview Email`}
        onCancel={handleCanel}
        footer={[
          <Button
            key="back"
            onClick={() =>
              handleEditChange(previewPopup.editPopUp ? "Ok" : "edit")
            }
          >
            {previewPopup.editPopUp ? "Ok" : "Edit"}
          </Button>,
          <Button key="back" onClick={handleCanel} htmlType="reset">
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            onClick={() => {
              handleSendMessageData("submit");
            }}
          >
            Send
          </Button>,
        ]}
        width={"40%"}
        destroyOnClose={true}
      >
        <div>
          <Row style={{ padding: "10px" }}>
            <Col
              span={24}
              style={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                marginBottom: "1vw",
              }}
            >
              <img
                src={previewPopup?.totalPreviewData?.orgnizationLogo}
                alt=""
                style={{
                  height: "auto",
                  maxWidth: "50%",
                  width: "auto",
                  margin: "auto",
                }}
              />
            </Col>
            <Col span={24}>
              <span className="text_preview">
                Dear{" "}
                {previewPopup?.totalPreviewData?.selectedContacts[0]
                  ?.firstName +
                  " " +
                  previewPopup?.totalPreviewData?.selectedContacts[0]?.lastName}
                ,
              </span>
            </Col>
            <Col span={24}>
              <span style={{ fontSize: "16px" }}>Subject : </span>
              {previewPopup.editPopUp ? (
                <Input
                  onChange={(e) =>
                    setEmailMessage({
                      ...emailMessage,
                      emailSubject: e.target.value,
                    })
                  }
                  defaultValue={previewPopup?.totalPreviewData?.subject}
                />
              ) : (
                previewPopup?.totalPreviewData?.subject
              )}
            </Col>
            <Col span={24} style={{ marginBottom: "2vw", marginTop: "1vw" }}>
              {previewPopup.editPopUp ? (
                <RichText
                  handleRichtextBio={handleRichtextBio}
                  richtTextBiofieldProp={
                    previewPopup?.totalPreviewData?.writtenDescription
                  }
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${
                      previewPopup?.totalPreviewData?.writtenDescription
                        ? previewPopup?.totalPreviewData?.writtenDescription
                        : ""
                    }`,
                  }}
                />
              )}
            </Col>
            <Col span={24} style={{ marginBottom: "1vw" }}>
              <div className="regards_preview">Regards,</div>
              <div className="regards_preview">
                {previewPopup?.totalPreviewData?.agentDetails?.name}
              </div>
            </Col>
            <Col span={24}>
              <Row>
                <Col>
                  <img
                    src={
                      previewPopup?.totalPreviewData?.agentDetails
                        ?.agentProfilePicture
                    }
                    alt=""
                    style={{ height: "125px", width: "125px" }}
                  />
                </Col>
                <Col style={{ marginLeft: "10px" }}>
                  <div
                    className="text_preview_footer"
                    style={{ marginBottom: "0px" }}
                  >
                    {previewPopup?.totalPreviewData?.agentDetails?.name}
                  </div>
                  <div
                    style={{
                      marginBottom: "0px",
                      fontSize: "16px",
                    }}
                  >
                    <a
                      href={
                        previewPopup?.totalPreviewData?.agentDetails
                          ?.userProfileLink
                      }
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underLine" }}
                    >
                      {
                        previewPopup?.totalPreviewData?.agentDetails
                          ?.userProfileLink
                      }
                    </a>
                  </div>
                  <div
                    style={{
                      marginBottom: "0px",
                      fontSize: "16px",
                    }}
                  >
                    {"+1 " +
                      previewPopup?.totalPreviewData?.agentDetails?.mobileNum}
                  </div>
                  <div
                    style={{
                      marginBottom: "0px",
                      fontSize: "16px",
                    }}
                  >
                    <a
                      style={{ color: "#333333", textDecoration: "underLine" }}
                      href={previewPopup?.totalPreviewData?.agentDetails?.email}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {previewPopup?.totalPreviewData?.agentDetails?.email}
                    </a>
                  </div>
                  <Row style={{ marginTop: "5px" }}>
                    <Col style={{ marginRight: "10px" }}>
                      <a
                        target="_blank"
                        href={`https://www.facebook.com/Homes-Listed-By-101359968421070`}
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        <FacebookFilled
                          style={{ color: "#2a2a2a", fontSize: "20px" }}
                        />
                      </a>
                    </Col>
                    <Col style={{ marginRight: "10px" }}>
                      <a
                        target="_blank"
                        href={`https://www.instagram.com/homeslistedby`}
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        <InstagramOutlined
                          style={{ color: "#2a2a2a", fontSize: "20px" }}
                        />
                      </a>
                    </Col>
                    <Col style={{ marginRight: "10px" }}>
                      <a
                        target="_blank"
                        href={`https://twitter.com/homeslistedby`}
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        <TwitterOutlined
                          style={{ color: "#2a2a2a", fontSize: "20px" }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a
                        target="_blank"
                        href={`https://www.youtube.com/channel/UC3NoEZFQ083fyZPeygAIYkw`}
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        <YoutubeOutlined
                          style={{ color: "#2a2a2a", fontSize: "20px" }}
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ marginTop: "10px" }}>
              <Card style={{ background: "#6e6a6a" }}>
                <Row justify="space-between" style={{ color: "#FFF" }}>
                  <Col>Home Search</Col>
                  <Col>|</Col>
                  <Col>Buyer Search</Col>
                  <Col>|</Col>
                  <Col>Off MLS Homes</Col>
                  <Col>|</Col>
                  <Col>Lenders</Col>
                </Row>
                <div style={{ textAlign: "center", marginTop: "1vw" }}>
                  <img
                    src={previewPopup?.totalPreviewData?.orgnizationLogo}
                    alt=""
                    style={{
                      height: "auto",
                      maxWidth: "35%",
                      width: "auto",
                      margin: "auto",
                    }}
                  />
                  <Row
                    style={{
                      justifyContent: "center",
                      marginTop: "7px",
                      color: "#FFF",
                    }}
                  >
                    <Col style={{ marginRight: "10px" }}>
                      <a
                        target="_blank"
                        href={`https://www.facebook.com/Homes-Listed-By-101359968421070`}
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        <FacebookFilled
                          style={{ color: "#2a2a2a", fontSize: "20px" }}
                        />
                      </a>
                    </Col>
                    <Col style={{ marginRight: "10px" }}>
                      <a
                        target="_blank"
                        href={`https://www.instagram.com/homeslistedby`}
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        <InstagramOutlined
                          style={{ color: "#2a2a2a", fontSize: "20px" }}
                        />
                      </a>
                    </Col>
                    <Col style={{ marginRight: "10px" }}>
                      <a
                        target="_blank"
                        href={`https://twitter.com/homeslistedby`}
                        // style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        <TwitterOutlined
                          style={{ color: "#2a2a2a", fontSize: "20px" }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a
                        target="_blank"
                        href={`https://www.youtube.com/channel/UC3NoEZFQ083fyZPeygAIYkw`}
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        <YoutubeOutlined
                          style={{ color: "#2a2a2a", fontSize: "20px" }}
                        />
                      </a>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    color: "#FFF",
                    lineHeight: "20px",
                    marginBottom: "15px",
                  }}
                >
                  You are receiving this email because you have visited our site
                  or asked us about regular newsletter. Make sure our messages
                  get to your Inbox (and not your bulk or junk folders).
                </div>
                <Row
                  justify="center"
                  style={{
                    color: "#d3d3d3",
                  }}
                >
                  <Col style={{ cursor: "pointer", marginRight: "5px" }}>
                    Privacy
                  </Col>
                  <Col>|</Col>
                  <Col style={{ cursor: "pointer", marginLeft: "5px" }}>
                    Unsubscribe
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  const handleToSelectTemp = (value, options) => {
    let tempData = templateDetails.totalTemplatesData.filter(
      (t) => t.id === options.key
    );
    if (tempData.length) {
      setEmailMessage({
        ...emailMessage,
        emailSubject: tempData[0]["templateSubject"],
        emailSendRawData: tempData[0]["templateDescription"],
        selectedTemp: value,
      });
      let payLoad = {
        entityData: {
          templateLastUse: new Date(),
        },
      };
      if (tempData[0]["id"] !== "") {
        update("virtualOfficeResources", tempData[0]["id"], payLoad).then(
          (updateRes) => {
            if (updateRes.success) {
            }
          }
        );
      }
    }
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <Button
            onClick={handleEmailModal}
            className="todo_button"
            style={{
              float: "right",
              cursor: "pointer",
              border: "1px solid #3045d2",
            }}
          >
            <span className="virtual_button_text btn-color">Send email</span>
          </Button>
        </Col>
        <Modal
          className="modal-black-close"
          visible={emailMessage.opeEmailPopup}
          title={`Send Email`}
          onCancel={handleCanel}
          footer={[
            <Button
              key="back"
              onClick={() => {
                handleSendMessageData("preview");
              }}
            >
              Preview
            </Button>,
            <Button
              key="back"
              // onClick={() =>
              //   setEmailMessage({
              //     ...emailMessage,
              //     opeEmailPopup: false,
              //     emailSendRawData: "",
              //   })
              // }
              onClick={() => handleCanel()}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                handleSendMessageData("submit");
              }}
              htmlType="submit"
            >
              Send
            </Button>,
          ]}
          width={"40%"}
        >
          <div>
            <Row style={{ padding: "10px" }}>
              <Col span={24}>
                <div className="label-titles">Template</div>
                <Select
                  showSearch
                  options={templateDetails.totalTemplatesData.id}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                  placeholder="Select Template"
                  onChange={handleToSelectTemp}
                  value={emailMessage.selectedTemp}
                >
                  {templateDetails.totalTemplatesData.map((province) => (
                    <Select.Option
                      key={province.id}
                      value={province.templateName}
                    >
                      {province.templateName}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={24}>
                <div className="label-titles">Subject</div>
                <Input
                  onChange={(e) =>
                    setEmailMessage({
                      ...emailMessage,
                      emailSubject: e.target.value,
                    })
                  }
                  value={emailMessage.emailSubject}
                />
              </Col>
              <Col span={24} style={{ marginTop: "1vw" }}>
                <div className="label-titles">Message</div>
                <RichText
                  handleRichtextBio={handleRichtextBio}
                  richtTextBiofieldProp={emailMessage?.emailSendRawData}
                />
              </Col>
            </Row>
          </div>
        </Modal>
      </Row>

      {previewPopup.openPopup && showPreviewModal()}
    </div>
  );
}