import React from "react";
import {
  Table,
  Input,
  Button,
  Select,
  Col,
  Row,
  message,
  Modal,
  notification,
  Card,
} from "antd";
import { list, update } from "../../../api/Apis";
import reassign from "../../../images/reassign.png";
import moment from "moment";
import NumberFormat from "react-number-format";
import Loading from "../../../common/Loading";

const { Option } = Select;
const Search = Input.Search;

class ShowAllLeads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 2,
      totalContacts: "",
      filterContacts: "",
      reAssign_lead: false,
      agentsList: [],
      assign_record: "",
      inProgressLeads: [],
      completedLeads: [],
      totalLeads: [],
      filterLeads: [],
      isLoading: false,
      defaultPageNum: 1,
    };
  }

  componentDidMount = () => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    this.getAllAgents(orgId);
    this.getAllContactLeads(orgId);
    this.getAllLeads(orgId);
  };

  // GET ALL WEBSITE LEADS
  getAllLeads = (orgId) => {
    this.setState({
      isLoading: true,
    });
    let query = {};
    query = {
      orgId: orgId,
      assignedStatus: ["pending", "completed"],
    };
    list("getMissedLeads", query).then((result) => {
      if (result.success) {
        let newResults = result.data.sort((a, b) =>
          a.createdAt < b.createdAt ? 1 : -1
        );
        this.setState({
          totalLeads: newResults,
          filterLeads: newResults,
          isLoading: false,
        });
      }
    });
  };

  // GET ALL CONTACT LEADS RECORDS
  getAllContactLeads = (orgId) => {
    let query = {
      contactType: "Contact",
      orgId,
    };
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          this.setState({
            totalContacts: result.data.contactList,
            filterContacts: result.data.contactList,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //GET AGENTS LIST FOR ASSIGN LEAD
  getAllAgents = async (orgId) => {
    let query = {
      userProfile: "AG",
      orgId,
      isActive: true,
    };
    list("users", query).then((result) => {
      if (result.success) {
        let agentsList = result.data.map((user) => {
          return {
            value: user.id,
            label: user.firstName + " " + user.lastName,
            email: user.email,
            userData: user,
          };
        });
        this.setState({
          agentsList,
        });
      }
    });
  };
  // SAVE ASSIGN LEAD CHANGES
  handle_reassing_lead = () => {
    const { assign_lead_details, assign_record } = this.state;
    let adminOrgId = JSON.parse(localStorage.getItem("orgId"));

    let agentDetails = {
      full_name: assign_lead_details.label,
      agent_id: assign_lead_details.value,
      email: assign_lead_details.email,
      orgId: assign_lead_details.userData?.orgId,
    };
    let data = {
      entityData: {
        agentDetails,
      },
    };
    update("virtualContactLeads", assign_record.id, data)
      .then((result) => {
        if (result.success) {
          message.success("Re assigned to new agent... !");
          this.setState({
            reAssign_lead: false,
            assign_record: "",
          });
          this.getAllContactLeads(adminOrgId);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          reAssign_lead: false,
        });
      });
  };

  assignAgent = (selectedProperty) => {
    const { assign_lead_details } = this.state;

    let orgnizationDetails = JSON.parse(localStorage.getItem("orgDetails"));

    let senderConfig =
      process.env.REACT_APP_API_GATEWAY.indexOf("dev") > -1
        ? "poweredbyagents.com"
        : orgnizationDetails["websiteName"];

    const reAssginTime = moment(selectedProperty.schedule_date_time).format();
    let submitData = {
      entityData: {
        propertyId: selectedProperty.propertyId,
        propertyTitle: selectedProperty.propertyTitle,
        propertyImgSrc: selectedProperty.propertyImgSrc,

        userName: selectedProperty.requestorDetails.name,
        userPhone: selectedProperty.requestorDetails.phone,
        userEmail: selectedProperty.requestorDetails.email,

        isRegistred: true,

        agentId: assign_lead_details.value,
        agentName: assign_lead_details.label,

        agentEmail: assign_lead_details.email,

        relatedTo: assign_lead_details.value,
        stateType: selectedProperty.stateType,
        orgId: selectedProperty.orgId,

        // TEMPLATE DATA
        webDomainName: window.location.hostname,
        orgnizationLogo: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${orgnizationDetails?.Header?.headerLogo}`,
        footerBackgrounndColor:
          orgnizationDetails["footer"]["copyRight"]["backgroundColor"],
        footerTextColor:
          orgnizationDetails["footer"]["copyRight"]["lastFootertextColor"],
        faceBookUrl: orgnizationDetails["footer"]["tabs"]["fbUrl"],
        twitterUrl: orgnizationDetails["footer"]["tabs"]["twitterUrl"],
        youtubeUrl: orgnizationDetails["footer"]["tabs"]["youtubeUrl"],
        peterestUrl: "https://in.pinterest.com/",
        instgramUrl: orgnizationDetails["footer"]["tabs"]["instaUrl"],

        orgnizationName: orgnizationDetails["orgName"],
        websiteUrl: "https://" + orgnizationDetails["websiteName"],
        senderEmail: orgnizationDetails["orgEmailAddress"] + "@" + senderConfig,
      },
    };
    if (selectedProperty.notificationType === "schedule") {
      submitData["entityData"]["createdBy"] = "user";
      submitData["entityData"]["scheduleStatus"] = "P";
      submitData["entityData"]["scheduleDate"] = moment(
        selectedProperty.schedule_date_time
      ).unix();
      submitData["entityData"]["scheduleTime"] = moment(reAssginTime).unix();
      submitData["entityData"]["formatedDateAndTime"] =
        selectedProperty.schedule_date_time;
      submitData["entityData"]["message"] = selectedProperty.message;
      submitData["entityData"]["scheduleHistory"] = [];
      submitData["entityData"]["typeOfSchedule"] = "listingSchedule";
      submitData["entityData"]["notificationType"] =
        selectedProperty.notificationType;
    } else if (selectedProperty.notificationType === "question") {
      submitData["entityData"]["message"] = selectedProperty.message;
      submitData["entityData"]["notificationType"] =
        selectedProperty.notificationType;
    } else {
      submitData["entityData"]["message"] = selectedProperty.message;
      submitData["entityData"]["notificationType"] =
        selectedProperty.notificationType;
    }

    update("assignProperty", selectedProperty.id, submitData).then((result) => {
      if (result.success) {
        this.getAllLeads(orgnizationDetails.id);
        message.success("Re assigned to new agent... !");
        this.setState({
          reAssign_lead: false,
          assign_record: "",
        });
      } else {
        this.setState({
          reAssign_lead: false,
          assign_record: "",
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  onReferedBySelectAgent = (id) => {
    const assign_lead_details = this.state.agentsList.filter(
      (agent) => agent.value === id
    )[0];

    this.setState({
      assign_lead_details,
    });
  };
  // OPEN ASSIGN LEAD POP UP
  open_re_assign_lead = () => {
    const { agentsList, assign_record } = this.state;
    return (
      <>
        <Row style={{ padding: "5px" }}>
          <Col span={24}>
            {/* <Row> */}
            <h4 style={{ color: "#aaa", marginTop: "0px" }}>
              Assign To The Agent
            </h4>
            {assign_record?.agentDetails && (
              <Select
                style={{ width: "100%" }}
                // className="input-width"
                showSearch
                optionFilterProp="children"
                options={agentsList}
                onSelect={(e) => this.onReferedBySelectAgent(e)}
                filterOption={(inputValue, option) =>
                  option.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) !== -1
                }
                // value={}
                defaultValue={assign_record?.agentDetails?.full_name}
              >
                {agentsList.map((agent) => (
                  <Option key={agent.value} value={agent.value}>
                    {agent.label}
                  </Option>
                ))}
              </Select>
            )}
            {/* </Row> */}
          </Col>
        </Row>
      </>
    );
  };

  completeMissedLead = async (id, record) => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    const data = {
      entityData: {
        assignedStatus: "completed",
        isAssigned: true,
      },
    };

    try {
      update("updateNotificationStatus", id, data).then((result) => {
        if (result.success) {
          notification["success"]({
            message: "Status changed successfully!",
          });
          this.setState({
            totalLeads: [],
          });
          this.getAllLeads(orgId);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          console.log(result.errors);
        }
      });
    } catch (error) {
      notification["error"]({
        message: "Something went wrong!",
      });
      console.log(error, "error");
    }
  };
  searchData = (event, searchType) => {
    const { filterLeads } = this.state;
    let totalOrdersList = [];
    let searchValue = "";
    searchValue = event?.trim().toLowerCase();
    if (searchType === "text") {
      filterLeads.filter((eachList) => {
        if ("requestorDetails" in eachList === true) {
          let phoneNumber =
            typeof eachList?.requestorDetails?.phone !== "number"
              ? eachList?.requestorDetails?.phone.replace(/[^+0-9]/g, "")
              : eachList?.requestorDetails?.phone;
          if (
            eachList?.requestorDetails?.name
              ?.toLowerCase()
              .indexOf(searchValue) > -1 ||
            eachList?.requestorDetails?.email
              ?.toLowerCase()
              .indexOf(searchValue) > -1 ||
            String(phoneNumber).indexOf(searchValue) > -1 ||
            eachList?.userDetails?.firstName?.toLowerCase().indexOf(searchValue) >
            -1 ||
          eachList?.userDetails?.lastName?.toLowerCase().indexOf(searchValue) >
            -1 ||
          (eachList?.notificationType?.toLowerCase() === "schedule" &&
            "requestashowing".indexOf(searchValue) > -1) ||
          (eachList?.notificationType === "buyer_interest" &&
            "buyerinterest".indexOf(searchValue) > -1) ||
          (eachList?.notificationType === "question" &&
            "question".indexOf(searchValue) > -1)
          ) {
            totalOrdersList.push(eachList);
          }
        } 
        // else if (
          
        // ) {
        //   totalOrdersList.push(eachList);
        // }
        return true;
      });

      console.log(totalOrdersList, "totalOrdersList");
      this.setState({
        totalLeads: totalOrdersList,
        defaultPageNum: 1
      });
    }
  };
  render() {
    const { reAssign_lead, assign_record, totalLeads, isLoading, defaultPageNum } = this.state;
    // MISSED LEADS TABLE COLOUMNS

    const columns = [
      {
        title: "STATUS",
        dataIndex: "assignedStatus",
        key: "assignedStatus",
        sorter: true,

        render: (assignedStatus, record) => {
          return (
            <Button
              style={{
                textTransform: "capitalize",
                backgroundColor:
                  assignedStatus === "completed"
                    ? "#27c24c"
                    : assignedStatus === "pending" && record.isAssigned
                    ? "#5bc0de"
                    : "#27c24c",
                fontWeight: "bold",
                padding: "5px 10px",
                color: "#fff",
                width: "100px",
              }}
            >
              {assignedStatus}
            </Button>
          );
        },
      },
      {
        title: "AGENT NAME",
        dataIndex: "userDetails",
        key: "userDetails",
        sorter: true,
        render: (userDetails) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {userDetails?.firstName + " " + userDetails?.lastName}
            </span>
          );
        },
      },
      {
        title: "CLIENTS/PROSPECT NAME",
        dataIndex: "requestorDetails",
        key: "requestorDetails",
        sorter: true,
        render: (requestorDetails, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {requestorDetails?.name}
            </span>
          );
        },
      },
      {
        title: "PHONE #",
        dataIndex: "requestorDetails",
        key: "requestorDetails",
        sorter: true,
        render: (requestorDetails) => {
          return (
            <NumberFormat
              className="ant-input-number"
              placeholder="Phone"
              format="(###) ###-####"
              displayType="text"
              style={{
                width: "100%",
                border: "none",
              }}
              value={requestorDetails?.phone}
            />
          );
        },
      },
      {
        title: "EMAIL",
        dataIndex: "requestorDetails",
        key: "requestorDetails",
        sorter: true,
        render: (requestorDetails) => {
          return <span>{requestorDetails?.email}</span>;
        },
      },
      {
        title: "TYPE",
        dataIndex: "notificationType",
        key: "notificationType",
        sorter: true,
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.setState({ modalVisible: true, viewnotificationType: record }),
        // }),
        render: (notificationType) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {notificationType === "buyer_interest"
                ? "Buyer Interest"
                : notificationType}
            </span>
          );
        },
      },
      {
        title: "RE-ASSIGN",
        dataIndex: "id",
        key: "id",
        sorter: false,
        render: (id, record) => {
          return (
            <span>
              <img
                src={reassign}
                alt="reassign_lead"
                width="30px"
                height="30px"
                style={{ marginLeft: "20px", cursor: "pointer" }}
                onClick={() =>
                  this.setState({
                    reAssign_lead: true,
                    assign_record: record,
                  })
                }
              />
            </span>
          );
        },
      },
      {
        title: "CONTACTED",
        dataIndex: "id",
        key: "id",
        sorter: false,
        render: (id, record) => {
          return (
            <span>
              <Button
                style={{
                  textTransform: "capitalize",
                  backgroundColor:
                    record["assignedStatus"] === "pending"
                      ? "#3045d2"
                      : "#27c24c",
                  fontWeight: "bold",
                  padding: "5px 10px",
                  color: "#fff",
                  cursor: "pointer",
                  width: "150px",
                }}
                onClick={() => {
                  this.completeMissedLead(id, record);
                }}
              >
                {record["assignedStatus"] === "pending"
                  ? "Mark as Contacted"
                  : "Completed"}
              </Button>
            </span>
          );
        },
      },
    ];
    return (
      <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
        <Loading enableLoading={isLoading} />
        <Row gutter={window.screen.width > 996 && [24, 24]}>
          <Col
            xl={{ span: 7, offset: 0 }}
            lg={{ span: 7, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            className="mobile_view_margin_Top"
          >
            <Search
              className="search"
              size="large"
              placeholder="Search"
              onChange={(event) => this.searchData(event.target.value, "text")}
              value={this.state.searchVal}
            />
          </Col>

          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            className="mobile_view_margin_Top"
          >
            <Card
              title={window.screen.width > 996 ? "All Leads" : ""}
              className="card_virtual"
              hoverable={true}
              bodyStyle={{ padding: "0px" }}
              style={{ overflow: "auto" }}
            >
              <Table
                columns={columns}
                dataSource={totalLeads}
                rowKey={(record) => record.id}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "25", "50", "100"],
                  total: totalLeads.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: defaultPageNum,
                  onChange:(e)=>this.setState({defaultPageNum:e})
                }}
                size="middle"
              />
            </Card>
          </Col>
          <Modal
            className="modal-black-close"
            visible={reAssign_lead}
            title={"Re Assign Lead"}
            onCancel={() =>
              this.setState({
                reAssign_lead: false,
                assign_record: "",
              })
            }
            footer={[
              <Button
                key="back"
                onClick={() =>
                  this.setState({
                    reAssign_lead: false,
                    assign_record: "",
                  })
                }
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                // onClick={() => this.handle_reassing_lead()}
                onClick={() => this.assignAgent(assign_record)}
                htmlType="submit"
              >
                Assign
              </Button>,
            ]}
          >
            {this.open_re_assign_lead(assign_record)}
          </Modal>
        </Row>
      </div>
    );
  }
}

export default ShowAllLeads;
