import React, { Component } from "react";
import { Row, Col, Button, notification, Select } from "antd";
import Sliders from "./Sliders";
import CheckBoxGroup from "./CheckBoxGroup";
import Number from "./Number";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { withRouter } from "react-router-dom";
import { FilterOutlined } from "@ant-design/icons";
const { Option } = Select;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {
        minprice: "",
        maxprice: "",
        type: "",
      },
      area: {
        minarea: 1,
        maxarea: 20000,
        type: "",
      },
      beds: {
        minbeds: 0,
        maxbeds: 0,
        type: "",
      },
      baths: {
        minbaths: 0,
        maxbaths: 0,
        type: "",
      },
      subTypeText: {
        subTypeText: [],
        type: "",
      },
      status: {
        status: [],
        type: "",
      },
      label: {
        label: [],
        type: "",
      },
      locations: {
        searchLocation: [],
        type: "",
      },

      enableFilter: true,
      // enableInfo: false,
      sortValue: "",
      halfBath: false,
    };
  }

  handlePrice = (min, max) => {
    this.setState({
      price: {
        minprice: min,
        maxprice: max,
        type: "price",
      },
    });
  };

  handleLivingArea = (min, max) => {
    this.setState({
      area: {
        minarea: min,
        maxarea: max,
        type: "area",
      },
    });
  };

  handleCheckProperty = (propertyTypes) => {
    this.setState({
      subTypeText: {
        subTypeText: propertyTypes,
        type: "subtype",
      },
    });
  };

  handleCheckStatus = (status) => {
    this.setState({
      status: {
        status: status,
        type: "status",
      },
    });
  };

  handleCheckLabel = (label) => {
    this.setState({
      label: {
        label: label,
        type: "label",
      },
    });
  };

  bedSize = (count) => {
    this.setState({
      beds: {
        minbeds: count,
        type: "beds",
      },
    });
  };

  bathSize = (count) => {
    this.setState({
      baths: {
        minbaths: count,
        type: "baths",
      },
    });
  };

  onSelect = (address) => {
    // const address = address.label.split(",");
    let newLocations = this.state.locations.searchLocation;
    let cityValue = "";
    let stateValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
        }

        const stateCodes = JSON.parse(
          localStorage.getItem("stateCodesFromAdmin")
        );
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateValue.toLowerCase()
        );

        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateValue,
            locationType: "location",
          };
          newLocations.push(newSearchLocation);
          this.setState({
            locations: {
              searchLocation: newLocations,
              type: "location",
            },
          });
        } else {
          notification["error"]({
            message: "Please enter valid city...",
            description: "You can search cities from MA, CT, RI, NH states",
            placement: "topLeft",
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  onClose = (value, stateRemove) => {
    let updateSearchLocations = this.state.locations.searchLocation.filter(
      (location) =>
        location.cityName !== value && location.stateName !== stateRemove
    );

    this.setState({
      locations: {
        searchLocation: updateSearchLocations,
        type:
          updateSearchLocations && updateSearchLocations.length > 0
            ? "location"
            : "",
      },
    });
  };

  handleClear = () => {
    window.location.reload();
  };

  handleCheck = (checked) => {
    this.setState({ halfBath: checked });
  };

  updateFilter = () => {
    this.setState({
      enableFilter: false,
    });

    const {
      price,
      area,
      beds,
      baths,
      subTypeText,
      status,
      label,
      locations,
      halfBath,
    } = this.state;

    const filter = [];
    if (price.type === "price") {
      filter.push({
        minprice: price.minprice,
        maxprice: price.maxprice,
        type: price.type,
      });
    }
    if (area.type === "area") {
      filter.push({
        minarea: parseInt(area.minarea),
        maxarea: parseInt(area.maxarea),
        type: area.type,
      });
    }
    if (beds.type === "beds") {
      filter.push({ minbeds: beds.minbeds, type: beds.type });
    }
    if (baths.type === "baths") {
      filter.push({ minbaths: baths.minbaths, type: baths.type });
    }
    if (subTypeText.type === "subtype") {
      filter.push({
        subTypeText: subTypeText.subTypeText,
        type: subTypeText.type,
      });
    }
    if (status.type === "status") {
      filter.push({ status: status.status, type: status.type });
    }
    if (label.type === "label") {
      filter.push({ label: label.label, type: label.type });
    }
    if (locations.type === "location") {
      filter.push({
        multiLocations: locations.searchLocation,
        type: "location",
      });
    }
    filter.push({
      halfBath: halfBath,
      type: "halfBath",
    });
    this.props.update(filter);
  };

  componentDidMount() {
    let oldsearchCity = [];
    if (
      this.props.oldFilterDataProps &&
      this.props.oldFilterDataProps.length > 0
    ) {
      this.props.oldFilterDataProps.map(
        (filterData) =>
          filterData.type === "location" &&
          oldsearchCity.push(filterData.multiLocations)
      );

      this.setState({
        locations: {
          searchLocation: oldsearchCity[0],
          type: "location",
        },
      });
    }

    if (this.props.oldFilterDataProps !== "") {
      this.setState({
        enableFilter: false,
      });
    } else {
      this.setState({
        enableFilter: true,
      });
    }
  }

  filterView = () => {
    const property1 = [
      // { label: "Commercial Land", value: "Commercial Land" },
      // { label: "Residential Land", value: "Residential Land" },
      // { label: "Residential Studio", value: "Residential Studio" },
      // { label: "Residential Apartment ", value: "Residential Apartment" },
      // { label: "Residential Condo", value: "Residential Condo" },
      // { label: "Condominium/Co-op", value: "Condominium/Co-op" },

      { label: "Land", value: "Land" },
      { label: "Mobile Homes", value: "Mobile Homes" },
      {
        label: "Residential Multi Family Home",
        value: "Residential Multi Family Home",
      },
      {
        label: "Residential Single Family Home",
        value: "Residential Single Family Home",
      },
      { label: "Residential Rental ", value: "Residential Rental" },
      { label: "Residential Condominium", value: "Residential Condominium" },
      { label: "Coopertive", value: "Coopertive" },
      { label: "Commercial Office", value: "Commercial Office" },
      { label: "Commercial MLS", value: "Commercial MLS" },
      { label: "Business Opportunity", value: "Business Opportunity" },
    ];

    const status1 = [
      { label: "Coming Soon", value: "Coming Soon" },
      { label: "New Listing", value: "New Listing" },
      { label: "Under Agreement", value: "Under Agreement" },

      // { label: "Temp off MLS", value: "Temp off MLS" },
      // { label: "For Sale by Owner", value: "For Sale by Owner" },
      // { label: "For Sale (Off MLS)", value: "For Sale (Off MLS)" },

      { label: "For Sale (On MLS)", value: "For Sale (On MLS)" },
    ];
    const lablesTags = [
      { label: "1031 Exchange", value: "1031 Exchange" },
      { label: "As Is Sale", value: "As Is Sale" },

      {
        label: "Available via kick-out clause",
        value: "Available via kick-out clause",
      },

      { label: "Cash cow", value: "Cash cow" },

      { label: "Downsize seller", value: "Downsize seller" },

      { label: "Hot offer", value: "Hot offer" },

      { label: "Like New", value: "Like New" },
      {
        label: "Need to find suitable housing",
        value: "Need to find suitable housing",
      },
      { label: "New construction", value: "New construction" },
      { label: "New listing", value: "New listing" },
      { label: "Temp Off MLS", value: "Temp Off MLS" },
      { label: "Open House", value: "Open House" },
      { label: "Portfolio", value: "Portfolio" },
      { label: "Ready to Move", value: "Ready to Move" },
      { label: "To be built", value: "To be built" },
      { label: "Under Agreement", value: "Under Agreement" },
      { label: "upsize", value: "upsize" },
      { label: "Cash Only", value: "Cash Only" },
      { label: "Investor Special", value: "Investor Special" },
    ];
    const multiState = [];
    const oldPrice = [];
    const oldArea = [];
    const oldBaths = [];
    const oldBeds = [];
    const oldSubTypeText = [];
    const oldStatus = [];
    const oldLabel = [];
    this.props.oldFilterDataProps &&
      this.props.oldFilterDataProps.length > 0 &&
      this.props.oldFilterDataProps.map((filterData) =>
        filterData.type === "cities"
          ? multiState.push(filterData.multi)
          : filterData.type === "price"
          ? oldPrice.push({
              maxprice: filterData.maxprice,
              minprice: filterData.minprice,
              type: "price",
            })
          : filterData.type === "area"
          ? oldArea.push({
              minarea: filterData.minarea,
              maxarea: filterData.maxarea,
              type: "area",
            })
          : filterData.type === "beds"
          ? oldBeds.push({ beds: filterData.minbeds })
          : filterData.type === "baths"
          ? oldBaths.push({ baths: filterData.minbaths })
          : filterData.type === "subtype"
          ? oldSubTypeText.push({ subTypeText: filterData.subTypeText })
          : filterData.type === "status"
          ? oldStatus.push({ status: filterData.status })
          : filterData.type === "label"
          ? oldLabel.push({ status: filterData.label })
          : ""
      );

    const checkBoxForSubtypeText =
      oldSubTypeText &&
      oldSubTypeText !== "undefined" &&
      oldSubTypeText.length > 0
        ? oldSubTypeText[0].subTypeText
        : [];
    const checkBoxForStatus =
      oldStatus && oldStatus !== "undefined" && oldStatus.length > 0
        ? oldStatus[0].status
        : [];
    const checkBoxForLabel =
      oldLabel && oldLabel !== "undefined" && oldLabel.length > 0
        ? oldLabel[0].label
        : [];
    return (
      <Row style={{ background: "white" }}>
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col
              xl={{ span: 10, offset: 1 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              sm={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              className="side-first-row"
            >
              <Button
                type="primary"
                onClick={this.updateFilter}
                className="side-filter"
              >
                Apply
              </Button>
            </Col>
            <Col
              xl={{ span: 10, offset: 2 }}
              lg={{ span: 10, offset: 2 }}
              md={{ span: 10, offset: 2 }}
              sm={{ span: 10, offset: 2 }}
              xs={{ span: 10, offset: 2 }}
              className="side-first-row"
            >
              <Button
                type="ghost"
                onClick={this.handleClear}
                className="side-Reset"
                style={{ float: "right" }}
              >
                Reset All
              </Button>
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <span style={{ fontSize: 13.5, color: "hsl(0, 0%, 50%)" }}>
                You can enter multiple cities, one at a time
              </span>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_MAPS_KEY}
                autocompletionRequest={{
                  types: ["(cities)"],
                  componentRestrictions: {
                    country: ["us"],
                  },
                }}
                selectProps={{
                  placeholder: "Enter City Name",
                  value: "",
                  onChange: (result) => {
                    this.onSelect(result);
                  },
                  style: { padding: "2%" },
                }}
              />
              {this.state.locations &&
                this.state.locations.searchLocation &&
                this.state.locations.searchLocation.map((city, index) => (
                  <div
                    key={index}
                    style={{
                      display: "inline-flex",
                      margin: "0.73vw",
                      padding: "0 7px",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                      border: "1px solid darkgrey",
                    }}
                  >
                    <div>{city.cityName}</div>
                    <div
                      style={{
                        color: "grey",
                        cursor: "pointer",
                        marginLeft: "0.4vw",
                      }}
                      onClick={() => this.onClose(city.cityName)}
                    >
                      x
                    </div>
                  </div>
                ))}
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <Sliders
                one
                title={"Budget "}
                handlePrice={this.handlePrice}
                oldPrice={oldPrice}
              />
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <Sliders
                three
                title={"Living Area"}
                handleLivingArea={this.handleLivingArea}
                oldArea={oldArea}
              />
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p className="second-row-title">Beds & Baths</p>
              <Number
                title={"Min Beds"}
                beds
                bedSize={this.bedSize}
                oldNumber={oldBeds}
              />
              <Number
                title={"Min Baths"}
                baths
                bathSize={this.bathSize}
                oldNumber={oldBaths}
              />
              {/* <Col offset={1} className="baths-level-show">
                <span className="margin-header">Half Bath</span>
                <Switch
                  style={{ marginLeft: "15px", marginRight: "15px" }}
                  onChange={this.handleCheck}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={this.state.halfBath}
                />
              </Col> */}
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p className="second-row-title">Property Type</p>
              <CheckBoxGroup
                property
                plainOptions={property1}
                handleCheckProperty={this.handleCheckProperty}
                oldFilter={oldSubTypeText}
                checkBoxFor={checkBoxForSubtypeText}
              />
            </Col>
            {this.props.match &&
              this.props.match.path !== "/buyers" &&
              this.props.match && (
                <Col
                  xl={{ span: 22, offset: 1 }}
                  lg={{ span: 22, offset: 1 }}
                  md={{ span: 22, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  className="side-second-row"
                >
                  <p className="second-row-title">Status</p>

                  <CheckBoxGroup
                    status
                    plainOptions={status1}
                    handleCheckStatus={this.handleCheckStatus}
                    oldFilter={oldStatus}
                    checkBoxFor={checkBoxForStatus}
                  />
                </Col>
              )}
            {window.location.pathname === "/offMLSHomes" && (
              <Col
                xl={{ span: 22, offset: 1 }}
                lg={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="side-second-row"
              >
                <p className="second-row-title">Labels</p>

                <CheckBoxGroup
                  label
                  plainOptions={lablesTags}
                  handleCheckLabel={this.handleCheckLabel}
                  oldFilter={oldStatus}
                  checkBoxFor={checkBoxForLabel}
                />
              </Col>
            )}
            <Col
              xl={{ span: 10, offset: 1 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              sm={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              className="side-first-row"
              style={{ marginTop: "1vw" }}
            >
              <Button
                type="primary"
                onClick={this.updateFilter}
                className="side-filter"
              >
                Apply
              </Button>
            </Col>
            <Col
              xl={{ span: 10, offset: 2 }}
              lg={{ span: 10, offset: 2 }}
              md={{ span: 10, offset: 2 }}
              sm={{ span: 10, offset: 2 }}
              xs={{ span: 10, offset: 2 }}
              className="side-first-row"
              style={{ marginTop: "1vw" }}
            >
              <Button
                type="ghost"
                onClick={this.handleClear}
                className="side-Reset"
                style={{ float: "right" }}
              >
                Reset All
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  handleSort = (sortValue) => {
    this.props.handleSort(sortValue);
  };

  render() {
    const { enableFilter, enableInfo } = this.state;
    return (
      <>
        <div className="show-on-desktop">{this.filterView()}</div>
        <div className="show-on-mobile">
          <Row
            justify="space-between"
            style={{ marginTop: "5px", marginBottom: "10px" }}
          >
            <Col md={{ span: 16 }} sm={{ span: 18 }} xs={{ span: 16 }}>
              <Select
                placeholder="Sort By :"
                className="sort-by-box"
                onChange={(sortValue) =>
                  this.props.handleSort(sortValue, "calledSortBy")
                }
              >
                <Option value="listprice">Price: Low to High</Option>
                <Option value="-listprice">Price: High to Low</Option>
                <Option value="-listdate">Date: New to Old</Option>
                <Option value="listdate"> Date: Old to New</Option>
              </Select>
            </Col>
            <Col md={{ span: 7 }} sm={{ span: 6 }} xs={{ span: 7 }}>
              <Button
                onClick={() => {
                  this.setState({
                    enableFilter: !enableFilter,
                  });
                }}
                type="primary"
                icon={<FilterOutlined />}
                ghost
                style={{ float: "right" }}
              >
                Filters
              </Button>
            </Col>
          </Row>
          {enableInfo && this.offmlsInfo()}
          {enableFilter && this.filterView()}
        </div>
      </>
    );
  }
}

export default withRouter(Filter);
