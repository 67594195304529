import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Avatar, Collapse, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { list } from "../../api/Apis";
import Lightbox from "react-image-lightbox";
import Loading from "../../common/Loading";
import EmailPopupCom from "./EmailPopupCom";
import NumberFormat from "react-number-format";

const Search = Input.Search;
const { Panel } = Collapse;
export default function Colleagues() {
  const [allAgents, setAllAgents] = useState({
    totalContacts: [],
    filterContacts: [],
  });
  const [loading, setLoading] = useState(false);
  const [showLightBox, setShowLightBox] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [messagePopup, setMessagePopup] = useState({
    showSendMessage: false,
    selectedContacts: [],
  });
  const [selectedContactKeys, setSelectedContactKeys] = useState([])


  useEffect(() => {
    setLoading(true);
    let orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));

    let query = {};
    query = {
      orgId: orgDetails.id,
      isActive: true,
    };

    list("users", query)
      .then((result) => {
        if (result.success) {
          let agentData = result.data.filter(
            (d) => d.userProfile === "AG" && d.id !== userDetails.id
          );
          setAllAgents({ filterContacts: agentData, totalContacts: agentData });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  const webColumns = [
    {
      title: "IMAGE",
      dataIndex: "profileUrl",
      key: "profileUrl",
      render: (profileUrl, record) => {
        return profileUrl && profileUrl !== "" ? (
          <Avatar
            className="avatar-img-header"
            src={profileUrl}
            onClick={() => {
              setShowLightBox(true);
              setImageUrl(profileUrl);
            }}
          />
        ) : (
          <Avatar icon={<UserOutlined />} />
        );
      },
    },
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {firstName}
          </span>
        );
      },
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
      render: (lastName) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {lastName}
          </span>
        );
      },
    },

    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      render: (email) => {
        return <span>{email}</span>;
      },
    },

    {
      title: "PHONE #",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return (
          <NumberFormat
            className="ant-input-number"
            placeholder="Phone"
            format="(###) ###-####"
            displayType="text"
            style={{
              width: "100%",
              border: "none",
            }}
            value={phone}
          />
        );
      },
    },
  ];

  const mobColumns = [
    {
      dataIndex: "productOriginDetails",
      key: "productOriginDetails",
      render: (productOriginDetails, record) => (
        <Collapse collapsible="true">
          <Panel
            header={record.firstName}
            key="1"
            // extra={
            //   <ArrowRightOutlined
            //     onClick={() =>
            //       window.location.pathname === "/app/driver/done"
            //         ? (window.location = "/app/driver/done/" + record.id)
            //         : (window.location = "/app/driver/todo/" + record.id)
            //     }
            //   />
            // }
          >
            <Row
              className="row-margin-table"
              onClick={() => {
                this.setState({ modalVisible: true, viewProperty: record });
              }}
            >
              <Col className="col-tilte">IMAGE</Col>
              <Col className="col-desc">
                {record.profileUrl && record.profileUrl !== "" ? (
                  <Avatar
                    className="avatar-img-header"
                    src={record.profileUrl}
                    onClick={() => {
                      setShowLightBox(true);
                      setImageUrl(record.profileUrl);
                    }}
                  />
                ) : (
                  <Avatar icon={<UserOutlined />} />
                )}
              </Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => {
                this.setState({ modalVisible: true, viewProperty: record });
              }}
            >
              <Col className="col-tilte">FIRST NAME</Col>
              <Col className="col-desc">{record.firstName}</Col>
            </Row>
            <Row
              className="row-margin-table"
              onClick={() => {
                this.setState({ modalVisible: true, viewProperty: record });
              }}
            >
              <Col className="col-tilte">LAST NAME</Col>
              <Col className="col-desc">{record.lastName}</Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => {
                this.setState({ modalVisible: true, viewProperty: record });
              }}
            >
              <Col className="col-tilte">EMAIL</Col>
              <Col className="col-desc" style={{ marginLeft: "0px" }}>
                {record.email}
              </Col>
            </Row>

            <Row
              className="row-margin-table"
              onClick={() => {
                this.setState({ modalVisible: true, viewProperty: record });
              }}
            >
              <Col className="col-tilte">PHONE #</Col>
              <Col className="col-desc">{record.phone}</Col>
            </Row>
          </Panel>
        </Collapse>
      ),
    },
  ];

  const handleToRestContactSelection = ()=>{
    setSelectedContactKeys([])
      setMessagePopup({
        ...messagePopup,
        selectedContacts: [],
      });
  }
  const rowSelection = {
    selectedRowKeys:selectedContactKeys,
    selections: [
      {
        key: "SELECTION_ALL",
        text: "Select All Contacts",
        Table: "SELECTION_ALL",
        onSelect: () => {
          let allCont = allAgents.filterContacts.filter(
            (i) => i.email !== ""
          );
          setMessagePopup({
            ...messagePopup,
            selectedContacts: allCont,
          });
          console.log(allCont,"allCont",allAgents);
          allCont = allCont.map((cId) => cId.id);
          setSelectedContactKeys(allCont);
        },
      },
      {
        key: "CLEAR_ALL",
        text: "Clear All Contacts",
        Table: "CLEAR_ALL",
        onSelect: () => {
          setMessagePopup({
            ...messagePopup,
            selectedContacts: [],
          });
          setSelectedContactKeys([]);
        },
      },
    ],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedContactKeys(selectedRowKeys)
      setMessagePopup({
        ...messagePopup,
        selectedContacts: selectedRows,
      });
    },

    getCheckboxProps: (record) => ({
      disabled: record?.emailAddress === "" || record?.emailAddress === null, // Column configuration not to be checked
      // emailAddress: record?.emailAddress,
    }),

    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows);
      // if(selected){
      setMessagePopup({
        ...messagePopup,
        showSendMessage: selected,
        selectedContacts: selectedRows,
      });
      // }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setMessagePopup({
        ...messagePopup,
        showSendMessage: selected,
        selectedContacts: selectedRows,
      });
    },
  };

  const searchData = (event, searchType) => {
    let totalOrdersList = [];
    let searchValue = "";
    searchValue = event?.trim().toLowerCase();
    if (searchType === "text") {
      totalOrdersList = allAgents?.totalContacts?.filter(
        (user) =>
          user?.firstName?.toLowerCase().indexOf(searchValue) > -1 ||
          user?.lastName?.toLowerCase().indexOf(searchValue) > -1 ||
          user?.email?.toLowerCase().indexOf(searchValue) > -1
      );
    } else {
      totalOrdersList = allAgents?.totalContacts;
    }
    setAllAgents({ ...allAgents, filterContacts: totalOrdersList });
  };

  return (
    <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
      <Loading enableLoading={loading} />
      <Row gutter={window.screen.width > 996 && [24, 24]}>
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Card
            title={
              <div style={{ display: "flex" }}>
                Colleagues
                <Col style={{ padding: "0px" }} offset={1}>
                  <Search
                    className="search"
                    size="large"
                    placeholder="Search"
                    onChange={(event) => searchData(event.target.value, "text")}
                  />
                </Col>
              </div>
            }
            className="card_virtual"
            hoverable={true}
            bodyStyle={{ padding: "0px", borderTop: "1px solid #ebebeb" }}
            style={{ overflow: "auto" }}
            extra={
              <EmailPopupCom
                selectedContacts={messagePopup?.selectedContacts}
                handleToRestContactSelection={handleToRestContactSelection}

              />

              // messagePopup?.selectedContacts?.length > 0 && (

              // )
            }
          >
            <Table
              columns={window.screen.width > 996 ? webColumns : mobColumns}
              dataSource={allAgents.filterContacts}
              rowKey={(record) => record.id}
              rowSelection={window.screen.width > 996 && rowSelection}
            />
          </Card>
        </Col>
      </Row>

      {showLightBox && (
        <Lightbox
          mainSrc={imageUrl}
          onCloseRequest={() => setShowLightBox(false)}
        />
      )}
    </div>
  );
}
