import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  notification,
  Button,
  Input,
  Modal,
  Collapse,
  Table,
  Form,
  Popover,
  Upload,
  Spin,
  Avatar,
} from "antd";
import "antd/dist/antd.css";
import {
  PlusOutlined,
  UserAddOutlined,
  ArrowRightOutlined,
  UploadOutlined,
  FilePdfOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { list, add, update, deleteRecord, noAuthlist } from "../../api/Apis";
import { withAppContext } from "../../store/contextStore";
import Loading from "../../common/Loading";
import moment from "moment";
import { BsFlagFill } from "react-icons/bs";
import { AiOutlineCalendar } from "react-icons/ai";
// import profile from "../../images/profilePic.png";
import { ModalTitle } from "../../StyledComponents/StyledComponents.js";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Storage } from "aws-amplify";
import document1 from "../../images/Document.png";
import link from "../../images/link.png";
import pdf from "../../images/PDF.png";
import userLogo from "../../images/userLogo.png";
import { FaUsers } from "react-icons/fa";
const { Panel } = Collapse;
const { TextArea } = Input;

class Todo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todoList: [],
      openForm: false,
      loading: false,
      selectedRowKeys: [],
      notes: "",
      allAgents: [],
      todoData: "",
      editModal: false,
      todoActiveDates: [],
      resourceImage: "",
      updateType: "",
      imageType: "",
      imageLoading: false,
      selectedAgent: [],
    };
  }

  componentDidMount = () => {
    this.getAllTodos();
    this.getAllAgents();
  };

  getAllAgents = async () => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let query = {};
    query = {
      orgId,
      isActive: true,
    };
    noAuthlist("getAgents", query)
      .then((res) => {
        if (res.success) {
          this.setState({
            allAgents: res.data,
          });
        }
      })
      .catch((error) => {
        notification["error"]({
          message: "Something went wrong",
        });
        console.log(error);
      });
  };

  showData = (id, record, type) => {
    this.setState({
      todoData: record,
      editModal: true,
      openForm: true,
      updateType: type,
      flagPriority: "",
      dueDate: "",
    });
  };

  getAllTodos = async () => {
    this.setState({
      loading: true,
    });
    let ActiveDates = [];
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const upcomingDate = new Date();
    upcomingDate.setDate(upcomingDate.getDate() + 3);
    const TodayDate = moment().format("L");
    const orgnizationId = JSON.parse(localStorage.getItem("orgId"));

    let userId = userDetails.id;
    let orgId = orgnizationId;
    let query = {};

    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
    } else {
      query["userId"] = userId;
      query["orgId"] = orgId;
    }
    try {
      const response = await list("getTodos", query);
      if (response.success) {
        response.data?.map((e) => {
          if (e.done !== true) {
            if (
              moment(upcomingDate).format("L") >=
                moment(e.dueDate).format("L") &&
              TodayDate <= moment(e.dueDate).format("L")
            ) {
              ActiveDates.push(e);
            }
          }
          return true;
        });
        this.setState({
          todoList: response.data.filter((e) => e.done === false),
          doneList: response.data.filter((e) => e.done === true),
          todoActiveDates: ActiveDates,
          loading: false,
        });
      } else {
        notification["error"]({
          message: "Something went wrong",
        });
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      this.setState({
        loading: false,
      });
    }
  };

  createToDo = async () => {
    const { userDetails } = this.props.context.state;
    const { todoTitle } = this.state;
    const orgnizationId = JSON.parse(localStorage.getItem("orgId"));

    const submitData = {
      entityData: {
        userId: userDetails.id,
        text: todoTitle,
        done: false,
        orgId: orgnizationId,
      },
    };

    try {
      const response = await add("virtualOfficeTodo", submitData);
      if (response.success) {
        this.handleReset();
        notification["success"]({
          message: "Task created successfully.",
          description: "Thank you.",
        });
        this.getAllTodos();
      } else {
        notification["error"]({
          message: response.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  deleteTodo = async (item) => {
    deleteRecord("virtualOfficeTodo", item.id)
      .then((response) => {
        if (response.success) {
          this.handleReset();
          notification["success"]({
            message: `${item.title} Removed Successfully`,
            description: "Thank you.",
          });
          this.getAllTodos();
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };

  updateTodo = async (item, id, type) => {
    let submitData = {};
    if (type === "priority") {
      submitData = {
        entityData: { priority: item },
      };
    } else {
      submitData = {
        entityData: { done: item },
      };
    }

    update("virtualOfficeTodo", id, submitData)
      .then((result) => {
        if (result.success) {
          notification["success"]({
            message: "Task updated successfully.",
            description: "Thank you.",
          });
          this.getAllTodos();
          this.handleReset();
        }
      })
      .catch((error) => {
        notification["error"]({
          message: "Something went wrong!",
        });
        console.log(error);
      });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleReset = () => {
    this.setState({
      openForm: false,
      flagPriority: "",
      notes: "",
      dueDate: "",
    });
  };

  fileDownload = async (fileName, downloadFileName, companyName) => {
    let savedFileName = downloadFileName + "_" + companyName.replace(/ /g, "");
    this.setState({
      loading: true,
    });
    Storage.get(fileName, { download: true }).then((res) =>
      this.downloadBlob(res.Body, savedFileName)
    );
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    this.setState({
      loading: false,
    });
    return a;
  }

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  resourceImage: result.key,
                  imageLoading: false,
                  loading: false,
                  imageType: info.file.type,
                });
              });
            } else {
              this.setState({
                imageLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  onSelectChange = (data) => {
    if (data.length > 0) {
      this.setState({
        loading: true,
      });
      this.updateTodo(true, data[0], "doneTodo");
    }
    this.setState({ selectedRowKeys: data });
  };

  onFinish = (e) => {
    this.setState({
      loading: true,
    });
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgnizationId = JSON.parse(localStorage.getItem("orgId"));
    let data = {
      entityData: {
        userId: userDetails.id,
        title: e["title"],
        assign:
          this.state.selectedAgent?.length > 0
            ? this.state.selectedAgent
            : this.state.todoData.assign,
        dueDate: this.state.dueDate || this.state.todoData.dueDate,
        priority: this.state.flagPriority || this.state.todoData.priority,
        Notes: e["notes"],
        done: false,
        uploadFile: this.state.resourceImage || this.state.todoData.uploadFile,
        orgId: orgnizationId,
        assignedBy: userDetails,
      },
    };

    // console.log(data, "data");
    if (this.state.editModal) {
      // data["entityData"]["done"] = false;
      update("virtualOfficeTodo", this.state.todoData.id, data)
        .then((result) => {
          if (result.success) {
            notification["success"]({
              message: "Task Updated successfully.",
              description: "Thank you.",
            });
            this.getAllTodos();
            if (window.location.pathname === "/VirtualOffice/Dashboard") {
              this.props.getAllTodosonDashboard(true);
            }
            this.setState({
              editModal: false,
              loading: false,
              openForm: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            editModal: false,
            loading: false,
            openForm: false,
          });
          console.log(err);
        });
    } else {
      // data["entityData"]["done"] = false;
      add("virtualOfficeTodo", data)
        .then((result) => {
          if (result.success) {
            notification["success"]({
              message: "Task created successfully.",
              description: "Thank you.",
            });
            this.getAllTodos();
            if (window.location.pathname === "/VirtualOffice/Dashboard") {
              this.props.getAllTodosonDashboard(true);
            }
            this.handleReset();
            this.setState({
              openForm: false,
              loading: false,
            });
          }
        })
        .catch((error) => {
          notification["error"]({
            message: error.message,
          });
          this.setState({
            openForm: false,
            loading: false,
          });
        });
    }
  };

  handleChange = (e) => {
    this.setState({
      dueDate: e,
    });
  };

  assignAgent = (e, type) => {
    let agents = this.state?.selectedAgent || [];
    let duplicateData = [];

    if (type !== "all") {
      duplicateData = agents?.filter((d) => d.id === e.id);
    } else {
      if (agents.length === e.length) {
        agents = [];
      } else {
        agents = e;
      }
      this.setState({
        selectedAgent: agents,
      });
      return;
    }

    if (duplicateData?.length > 0) {
      agents.splice(
        agents.findIndex((i) => i.id === e.id),
        1
      );
      this.setState({
        selectedAgent: agents,
      });
    } else {
      agents.push(e);
      this.setState({
        selectedAgent: agents,
      });
    }
  };

  openModal = (openForm, loading, todoData) => {
    const { flagPriority, notes } = this.state;
    const content = (
      <div>
        <Card
          className="card_virtual"
          hoverable={true}
          style={{ width: "450px" }}
        >
          <Calendar onChange={this.handleChange} value={this.state.dueDate} />
        </Card>
      </div>
    );

    const flagStatus = ["Urgent", "High", "Normal", "Low"];

    const priorityContent = (record, type) => (
      <div style={{ width: "100px" }}>
        {flagStatus?.map((e) => (
          <Row
            style={{
              padding: "5px",
              cursor: "pointer",
              background: e === this.state.flagPriority ? "#96c1ea" : "white",
            }}
            onClick={
              type === "Update"
                ? () =>
                    this.setState({ flagPriority: e }, () =>
                      this.updateTodo(e, record.id, "priority")
                    )
                : () => this.setState({ flagPriority: e })
            }
          >
            <Col span={6}>
              <BsFlagFill
                style={{
                  fontSize: "18px",
                  color:
                    e === "Urgent"
                      ? "red"
                      : e === "High"
                      ? "yellow"
                      : e === "Normal"
                      ? "skyblue"
                      : "gray",
                }}
              />
            </Col>
            <Col
              span={16}
              offset={2}
              style={{
                fontSize: "16px",
                lineHeight: 1,
                fontFamily: "Karla,sans-serif",
              }}
            >
              {e}
            </Col>
          </Row>
        ))}
      </div>
    );

    const Agentcontent = (data) => (
      <div style={{ width: "160px" }}>
        <Row
          style={{
            padding: "5px",
            cursor: "pointer",
            // background: this.state?.agentsData ? "#96c1ea" : "white",
          }}
          onClick={() => this.assignAgent(this.state?.allAgents, "all")}
        >
          <Col span={6}>
            <FaUsers
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50px",
              }}
            />
          </Col>
          <Col
            span={16}
            offset={2}
            style={{
              fontSize: "14px",
              fontFamily: "Karla,sans-serif",
            }}
          >
            All Agents
          </Col>
        </Row>

        {this.state?.allAgents?.map((agent) => (
          <Row
            style={{
              padding: "5px",
              cursor: "pointer",
              // background: this.state?.selectedAgent?.map((e) =>
              //   e.id === agent.id ? "#96c1ea" : "white"
              // ),
            }}
            onClick={() => this.assignAgent(agent)}
          >
            <Col span={6}>
              {agent.profilePicture ? (
                <img
                  src={
                    `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                    agent.profilePicture[0]
                  }
                  alt="agentlogo"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50px",
                  }}
                />
              ) : (
                <img
                  src={userLogo}
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50px",
                  }}
                  alt="avatar"
                />
              )}
            </Col>
            <Col
              span={16}
              offset={2}
              style={{
                fontSize: "14px",
                fontFamily: "Karla,sans-serif",
              }}
            >
              {agent.firstName + " " + agent.lastName}
            </Col>
          </Row>
        ))}
      </div>
    );

    const { imageType, resourceImage } = this.state;
    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };
    return (
      <Form onFinish={this.onFinish} id="form" initialValues={false}>
        <Modal
          className="modal-black-close"
          visible={openForm}
          title="TO DO"
          style={{ marginTop: "2%" }}
          destroyOnClose={true}
          onCancel={() =>
            this.setState({
              openForm: false,
              todoData: "",
              editModal: false,
              selectedAgent: "",
              dueDate: "",
              resourceImage: "",
            })
          }
          footer={
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Button
                    onClick={() =>
                      this.setState({
                        openForm: false,
                        todoData: "",
                        editModal: false,
                        selectedAgent: "",
                        dueDate: "",
                        resourceImage: "",
                      })
                    }
                    type="default"
                    style={{
                      width: "100%",
                      backgroundColor: "#bbb",
                      color: "#FFF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    htmlType="reset"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    htmlType="submit"
                    form="form"
                    // onClick={() => this.createToDo()}
                    type="primary"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    loading={loading}
                  >
                    {this.state.updateType === "done" && todoData
                      ? "Re-open"
                      : todoData
                      ? "Update Task"
                      : "Create Task"}
                  </Button>
                </Col>
              </Row>
            </Col>
          }
          width={"35%"}
        >
          <Card
            style={{ marginTop: "10px" }}
            className="card_virtual"
            hoverable={true}
          >
            <Row>
              <Col span={24}>
                <ModalTitle>Enter Task Title</ModalTitle>

                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title!",
                    },
                  ]}
                  initialValue={todoData.title ? todoData.title : ""}
                >
                  <Input
                    placeholder="Enter task title"
                    className="form-input"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={8} style={{ marginTop: "1vw" }}>
                <Form.Item
                  name="assign"
                  label={
                    <ModalTitle style={{ marginTop: "10px" }}>
                      ASSIGNED TO
                    </ModalTitle>
                  }
                  rules={[
                    {
                      required: false,
                      message: "Please Assign !",
                    },
                  ]}
                >
                  <div
                    style={{
                      borderRadius: "50px",
                      height: "40px",
                      width: "40px",
                      border: "1px solid #EBEBEB",
                    }}
                  >
                    <Popover
                      trigger="hover"
                      content={Agentcontent(todoData?.assign?.id)}
                    >
                      <UserAddOutlined
                        style={{
                          fontSize: "22px",
                          marginLeft: "8px",
                          marginTop: "6px",
                        }}
                      />
                    </Popover>
                  </div>
                  {/* <Avatar.Group
                    maxCount={2}
                    maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                  >
                    {todoData?.assign?.map((i) => (
                      <Avatar src={i?.profileUrl || userLogo} />
                    ))}
                  </Avatar.Group> */}
                </Form.Item>

                {this.state?.selectedAgent?.length > 0
                  ? this.state?.selectedAgent?.map((agent) => (
                      <Row>
                        <Col span={24} style={{ marginBottom: "10px" }}>
                          {agent?.profilePicture ? (
                            <img
                              src={
                                `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                agent?.profilePicture[0]
                              }
                              alt="agentlogo"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50px",
                              }}
                            />
                          ) : (
                            <img
                              src={userLogo}
                              alt="agentlogo"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50px",
                              }}
                            />
                          )}
                          <span
                            style={{
                              marginLeft: "5px",
                              fontSize: "16px",
                            }}
                          >
                            {agent?.firstName + " " + agent?.lastName}
                          </span>
                        </Col>
                      </Row>
                    ))
                  : todoData?.assign?.map((t) => (
                      <Row>
                        <Col span={24} style={{ marginBottom: "10px" }}>
                          {t?.profilePicture ? (
                            <img
                              src={
                                `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                t?.profilePicture[0]
                              }
                              alt="agentlogo"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50px",
                              }}
                            />
                          ) : (
                            <img
                              src={userLogo}
                              style={{
                                width: "35px",
                                height: "35px",
                                borderRadius: "50px",
                              }}
                              alt="avatar"
                            />
                          )}
                          <span
                            style={{
                              marginLeft: "5px",
                              fontSize: "16px",
                            }}
                          >
                            {t?.firstName + " " + t?.lastName}
                          </span>
                        </Col>
                      </Row>
                    ))}
              </Col>

              <Col span={8} style={{ marginTop: "1vw" }}>
                <Form.Item
                  name="dueDate"
                  label={
                    <ModalTitle style={{ marginTop: "10px" }}>
                      Due Date
                    </ModalTitle>
                  }
                  rules={[
                    {
                      required: false,
                      message: "Please input dueDate!",
                    },
                  ]}
                  // onClick={() => this.setState({ showDate: true })}
                >
                  <div
                    style={{
                      borderRadius: "50px",
                      height: "40px",
                      width: "40px",
                      border: "1px solid #EBEBEB",
                    }}
                  >
                    <Popover
                      trigger="hover"
                      content={content}
                      placement="bottom"
                    >
                      <AiOutlineCalendar
                        style={{
                          fontSize: "22px",
                          marginLeft: "8px",
                          marginTop: "7px",
                        }}
                      />
                    </Popover>
                  </div>
                </Form.Item>

                {this?.state?.dueDate ? (
                  <span
                    style={{
                      marginLeft: "5px",
                      fontSize: "16px",
                      marginBottom: "1vw",
                    }}
                  >
                    {moment(this.state.dueDate).format("L")}
                  </span>
                ) : todoData ? (
                  <Row>
                    <Col span={24} style={{ marginBottom: "10px" }}>
                      <span
                        style={{
                          marginLeft: "5px",
                          fontSize: "16px",
                          marginBottom: "1vw",
                        }}
                      >
                        {moment(todoData?.dueDate).format("L")}
                      </span>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Col>

              <Col span={8} style={{ marginTop: "1vw" }}>
                <Form.Item
                  name="priority"
                  label={
                    <ModalTitle style={{ marginTop: "10px" }}>
                      Priority
                    </ModalTitle>
                  }
                  rules={[
                    {
                      required: false,
                      message: "Please input priority!",
                    },
                  ]}
                >
                  <div
                    style={{
                      borderRadius: "50px",
                      height: "40px",
                      width: "40px",
                      border: "1px solid #EBEBEB",
                      marginBottom: "1vw",
                    }}
                  >
                    <Popover trigger="click" content={priorityContent()}>
                      <BsFlagFill
                        style={{
                          fontSize: "20px",
                          marginLeft: "9px",
                          marginTop: "10px",
                          color:
                            todoData?.priority === "Urgent" ||
                            flagPriority === "Urgent"
                              ? "red"
                              : todoData?.priority === "High" ||
                                flagPriority === "High"
                              ? "yellow"
                              : todoData?.priority === "Normal" ||
                                flagPriority === "Normal"
                              ? "skyblue"
                              : "gray",
                        }}
                      />
                    </Popover>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <ModalTitle>Upload</ModalTitle>
                <Form.Item
                  name="upload"
                  rules={[
                    {
                      required: false,
                      message: "Please input your images!",
                    },
                  ]}
                >
                  <Upload
                    // name="avatar"
                    // listType="picture-card"
                    // className="avatar-uploader"
                    style={{
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    showUploadList={false}
                    customRequest={dummyRequest}
                    onChange={(e) => this.handleUploadPic(e)}
                  >
                    <Spin spinning={this.state.imageLoading}>
                      <Row>
                        {resourceImage !== null && resourceImage !== "" ? (
                          <>
                            {imageType?.includes("pdf") ? (
                              <img
                                src={pdf}
                                className="ant-icon "
                                alt="pdf"
                                style={{ width: "50px" }}
                              />
                            ) : imageType?.includes("image") ? (
                              <img
                                src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${resourceImage}`}
                                alt="avatar"
                                style={{
                                  width: "100px",
                                  height: "100%",
                                }}
                              />
                            ) : imageType?.includes("doc") ||
                              imageType?.includes("text/xml") ? (
                              <img
                                src={document1}
                                className="ant-icon"
                                alt="document"
                                style={{ width: "50px" }}
                              />
                            ) : (
                              imageType?.includes("com") && (
                                <img
                                  src={link}
                                  className="ant-icon"
                                  alt="link"
                                  style={{ width: "50px" }}
                                />
                              )
                            )}
                          </>
                        ) : (
                          <Button icon={<UploadOutlined />}>Upload</Button>
                          // <CameraOutlined
                          //   className="camera-icon"
                          //   style={{ fontSize: "45px" }}
                          // />
                        )}
                      </Row>
                    </Spin>
                  </Upload>
                </Form.Item>
                {!resourceImage && todoData?.uploadFile && (
                  <span className="col_style_quarry">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.fileDownload(
                          todoData?.uploadFile,
                          "Attachment",
                          "uploadAttachment"
                        );
                      }}
                    >
                      <Row
                        style={{
                          color: "#7B8190",
                          fontSize: "13px",
                        }}
                      >
                        <FilePdfOutlined
                          style={{
                            fontSize: "30px",
                            height: "30px",
                            marginRight: "5px",
                          }}
                        />
                        <span style={{ marginTop: "6px" }}>Download</span>
                      </Row>
                    </span>
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="notes"
                  rules={[
                    {
                      required: false,
                      message: "Please input title!",
                    },
                  ]}
                  initialValue={todoData?.Notes}
                >
                  <TextArea
                    placeholder="Notes"
                    rows={4}
                    name="testimonialData"
                    value={notes}
                    onChange={(e) => this.setState({ notes: e })}
                    style={{ marginTop: "1vw" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Modal>
      </Form>
    );
  };

  render() {
    const { todoList, doneList, openForm, loading, todoData, todoActiveDates } =
      this.state;
    const flagStatus = ["Urgent", "High", "Normal", "Low"];
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const priorityContent = () => (
      <div style={{ width: "100px" }}>
        {flagStatus.map((e) => (
          <Row
            style={{
              padding: "5px",
              cursor: "pointer",
              background: e === this.state.flagPriority ? "#96c1ea" : "white",
            }}
            onClick={() => {
              this.setState({ flagPriority: e });
              // this.updateTodo(e, record.id, "priority");
            }}
          >
            <Col span={6}>
              <BsFlagFill
                style={{
                  fontSize: "18px",
                  color:
                    e === "Urgent"
                      ? "red"
                      : e === "High"
                      ? "yellow"
                      : e === "Normal"
                      ? "skyblue"
                      : "gray",
                }}
              />
            </Col>
            <Col
              span={16}
              offset={2}
              style={{
                fontSize: "16px",
                lineHeight: 1,
                fontFamily: "Karla,sans-serif",
              }}
            >
              {e}
            </Col>
          </Row>
        ))}
      </div>
    );
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange,
    // };

    const webColumns = [
      {
        title: "",
        dataIndex: "title",
        key: "title",
        sorter: false,
        className: "col_styling",
        width: "65%",
        onCell: (record) => ({
          onClick: () => this.showData(record.id, record),
        }),
        render: (title) => {
          return (
            <span
              className="table_desc"
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#131419",
                fontWeight: "500",
              }}
            >
              {title}
            </span>
          );
        },
      },
      {
        title: "ASSIGNED BY",
        dataIndex: "assignedBy",
        key: "assignedBy",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => this.showData(record.id, record),
        }),
        render: (assignedBy) => {
          return (
            <Popover
              trigger="hover"
              content={
                <div>{assignedBy?.firstName + " " + assignedBy?.lastName}</div>
              }
            >
              <span className="table_desc">
                <Avatar src={assignedBy?.profileUrl || userLogo} />
              </span>
            </Popover>
          );
        },
      },
      {
        title: "DUE DATE",
        dataIndex: "dueDate",
        key: "dueDate",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => this.showData(record.id, record),
        }),
        render: (dueDate) => {
          return (
            <span
              className="table_desc"
              style={{
                textTransform: "capitalize",
              }}
            >
              {moment(dueDate).format("DD MMM")}
            </span>
          );
        },
      },
      {
        title: "PRIORITY",
        dataIndex: "priority",
        key: "priority",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => this.showData(record.id, record),
        }),
        render: (priority, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color:
                  priority === "Urgent"
                    ? "red"
                    : priority === "High"
                    ? "yellow"
                    : priority === "Normal"
                    ? "skyblue"
                    : priority === "Done"
                    ? "#6bc950"
                    : "gray",
              }}
            >
              <BsFlagFill style={{ fontSize: "18px" }} />
              {/* <Popover
                trigger="hover"
                content={priorityContent(record, "Update")}
              >
                <BsFlagFill style={{ fontSize: "18px" }} />
              </Popover> */}
            </span>
          );
        },
      },

      {
        title: "ACTION",
        dataIndex: "id",
        key: "id",
        sorter: false,
        className: "col_styling",
        render: (id, record) => {
          return (
            <Row style={{ justifyContent: "space-between" }}>
              <span onClick={() => this.updateTodo(true, id)}>
                <Button type="primary">Mark Complete</Button>
              </span>
              {userDetails && userDetails.userProfile === "A" && (
                <span>
                  <DeleteOutlined
                    className="todo-delete-option"
                    onClick={() => this.deleteTodo(record)}
                  />
                </span>
              )}
            </Row>
          );
        },
      },
    ];

    let webDoneColumns = [
      {
        title: "",
        dataIndex: "title",
        key: "title",
        sorter: false,
        className: "col_styling",
        width: "65%",
        hiden: false,

        onCell: (record) => ({
          onClick: () => this.showData(record.id, record, "done"),
        }),
        render: (title) => {
          return (
            <span
              className="table_desc"
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#131419",
                fontWeight: "500",
              }}
            >
              {title}
            </span>
          );
        },
      },
      {
        title: "ASSIGNED BY",
        dataIndex: "assignedBy",
        key: "assignedBy",
        sorter: false,
        className: "col_styling",
        hiden: false,

        onCell: (record) => ({
          onClick: () => this.showData(record.id, record, "done"),
        }),
        render: (assignedBy) => {
          return (
            <Popover
              trigger="hover"
              content={
                <div>{assignedBy?.firstName + " " + assignedBy?.lastName}</div>
              }
            >
              <span className="table_desc">
                <Avatar src={assignedBy?.profileUrl || userLogo} />
              </span>
            </Popover>
          );
        },
      },
      {
        title: "DUE DATE",
        dataIndex: "dueDate",
        key: "dueDate",
        sorter: false,
        hiden: false,

        className: "col_styling",
        onCell: (record) => ({
          onClick: () => this.showData(record.id, record, "done"),
        }),
        render: (dueDate) => {
          return (
            <span
              className="table_desc"
              style={{
                textTransform: "capitalize",
              }}
            >
              {moment(dueDate).format("DD MMM")}
            </span>
          );
        },
      },
      {
        title: "PRIORITY",
        dataIndex: "priority",
        key: "priority",
        sorter: false,
        className: "col_styling",
        hiden: false,

        onCell: (record) => ({
          onClick: () => this.showData(record.id, record, "done"),
        }),
        render: (priority, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color:
                  priority === "Urgent"
                    ? "red"
                    : priority === "High"
                    ? "yellow"
                    : priority === "Normal"
                    ? "skyblue"
                    : priority === "Done"
                    ? "#6bc950"
                    : "gray",
              }}
            >
              {/* <Popover
                trigger="hover"
                content={priorityContent(record, "Update")}
              > */}
              <BsFlagFill style={{ fontSize: "18px" }} />
              {/* </Popover> */}
            </span>
          );
        },
      },
      {
        title: "DATE COMPLETED",
        dataIndex: "updatedAt",
        key: "updatedAt",
        sorter: false,
        className: "col_styling",
        hiden: false,

        onCell: (record) => ({
          onClick: () => this.showData(record.id, record, "done"),
        }),
        render: (updatedAt) => {
          return (
            <span
              className="table_desc"
              style={{
                textTransform: "capitalize",
              }}
            >
              {moment(updatedAt).format("DD MMM")}
            </span>
          );
        },
      },
      {
        title: "ACTION",
        dataIndex: "id",
        key: "id",
        sorter: false,
        className: "col_styling",
        hiden: userDetails && userDetails.userProfile === "AG" ? true : false,
        render: (id, record) => {
          return (
            <>
              <span>
                <DeleteOutlined
                  style={{
                    color: "red",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  className="todo-delete-option"
                  onClick={() => this.deleteTodo(record)}
                />
              </span>
            </>
          );
        },
      },
    ];

    webDoneColumns = webDoneColumns.filter((hidenCol) => !hidenCol.hiden);

    const webColumnsDashboard = [
      {
        title: "TASK",
        dataIndex: "title",
        key: "title",
        sorter: false,
        className: "col_styling",
        width: "50%",
        onCell: (record) => ({
          onClick: () => this.showData(record.id, record),
        }),
        render: (title) => {
          return (
            <span
              className="table_desc"
              style={{
                textTransform: "capitalize",
              }}
            >
              {title}
            </span>
          );
        },
      },
      {
        title: "ASSIGNED BY",
        dataIndex: "assignedBy",
        key: "assignedBy",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => this.showData(record.id, record),
        }),
        render: (assignedBy) => {
          return (
            <Popover
              trigger="hover"
              content={
                <div>{assignedBy?.firstName + " " + assignedBy?.lastName}</div>
              }
            >
              <span className="table_desc">
                <Avatar src={assignedBy?.profileUrl || userLogo} />
              </span>
            </Popover>
          );
        },
      },
      {
        title: "DUE DATE",
        dataIndex: "dueDate",
        key: "dueDate",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => this.showData(record.id, record),
        }),
        render: (dueDate) => {
          return (
            <span
              className="table_desc"
              style={{
                textTransform: "capitalize",
              }}
            >
              {moment(dueDate).format("DD MMM")}
            </span>
          );
        },
      },
      {
        title: "PRIORITY",
        dataIndex: "priority",
        key: "priority",
        sorter: false,
        className: "col_styling",
        onCell: (record) => ({
          onClick: () => this.showData(record.id, record),
        }),
        render: (priority, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color:
                  priority === "Urgent"
                    ? "red"
                    : priority === "High"
                    ? "yellow"
                    : priority === "Normal"
                    ? "skyblue"
                    : priority === "Done"
                    ? "#6bc950"
                    : "gray",
              }}
            >
              <BsFlagFill style={{ fontSize: "18px" }} />
              {/* <Popover
                trigger="hover"
                content={priorityContent(record, "Update")}
              >
                <BsFlagFill style={{ fontSize: "18px" }} />
              </Popover> */}
            </span>
          );
        },
      },

      {
        title: "ACTION",
        dataIndex: "id",
        key: "id",
        sorter: false,
        className: "col_styling",
        render: (id, record) => {
          return (
            <Row style={{ justifyContent: "space-between" }}>
              <span onClick={() => this.updateTodo(true, id)}>
                <Button type="primary">Mark Complete</Button>
              </span>
              {userDetails && userDetails.userProfile === "A" && (
                <span>
                  <DeleteOutlined
                    className="todo-delete-option"
                    onClick={() => this.deleteTodo(record)}
                  />
                </span>
              )}
            </Row>
          );
        },
      },
    ];

    const mobColumns = [
      {
        title: (
          <>
            To Do{" "}
            <span style={{ fontWeight: "400" }}>
              - ( {todoList?.length} tasks )
            </span>
          </>
        ),
        dataIndex: "title",
        key: "title",
        render: (title, record) => (
          <Collapse collapsible="true">
            <Panel
              header={title}
              key="1"
              extra={
                <ArrowRightOutlined
                  onClick={() => {
                    window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
                  }}
                />
              }
            >
              <Row className="row-margin-table">
                <Col className="col-tilte">ASSIGNED BY</Col>
                <Col className="col-desc">
                  <Popover
                    trigger="hover"
                    content={
                      <div>
                        {record.assignedBy?.firstName +
                          " " +
                          record.assignedBy?.lastName}
                      </div>
                    }
                  >
                    <span className="table_desc">
                      <Avatar src={record.assignedBy?.profileUrl || userLogo} />
                    </span>
                  </Popover>
                  {/* {
                    <Avatar.Group
                      maxCount={2}
                      maxStyle={{
                        color: "#f56a00",
                        backgroundColor: "#fde3cf",
                      }}
                    >
                      {record.assign.map((i) => (
                        <Avatar src={i?.profileUrl || userLogo} />
                      ))}
                    </Avatar.Group>
                  } */}
                </Col>
              </Row>
              <Row className="row-margin-table">
                <Col className="col-tilte">DUE DATE</Col>
                <Col className="col-desc">
                  {moment(record.dueDate).format("DD MMM")}
                </Col>
              </Row>
              <Row className="row-margin-table">
                <Col className="col-tilte">PRIORITY</Col>
                <Col className="col-desc">
                  {
                    <span
                      style={{
                        textTransform: "capitalize",
                        color:
                          record.priority === "Urgent"
                            ? "red"
                            : record.priority === "High"
                            ? "yellow"
                            : record.priority === "Normal"
                            ? "skyblue"
                            : record.priority === "Done"
                            ? "#6bc950"
                            : "gray",
                      }}
                    >
                      <Popover
                        trigger="hover"
                        content={priorityContent(record)}
                      >
                        <BsFlagFill style={{ fontSize: "18px" }} />
                      </Popover>
                    </span>
                  }
                </Col>
              </Row>
            </Panel>
          </Collapse>
        ),
      },
    ];

    return (
      <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
        <Loading enableLoading={loading} />
        {window.screen.width > 996 ? (
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            className="mobile_view_margin_Top"
          >
            {this.props.showData ? (
              <Col
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="mobile_view_margin_Top"
              >
                <Card
                  title={
                    <Link
                      to="/VirtualOffice/Todo"
                      style={{ color: "#000" }}
                    >
                      To Do
                    </Link>
                  }
                  bodyStyle={{
                    padding: window.screen.width > 996 ? "24px" : "0px",
                  }}
                  extra={
                    <Button
                      icon={<PlusOutlined style={{ color: "#3045d2" }} />}
                      onClick={() => {
                        this.setState({
                          openForm: true,
                          flagPriority: "",
                        });
                      }}
                      className="todo_button"
                      style={{ float: "right", border: "1px solid #3045d2" }}
                    >
                      <span className="virtual_button_text btn-color">
                        ADD TASK
                      </span>
                    </Button>
                  }
                  className="card_virtual"
                  hoverable={true}
                >
                  <div className="todo_collapse">
                    <Table
                      className="table_crm"
                      columns={webColumnsDashboard}
                      dataSource={todoActiveDates}
                      loading={loading}
                      // rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                      pagination={false}
                      style={{ cursor: "pointer" }}
                      rowClassName="ant-card-hoverable"
                    />
                  </div>
                </Card>
              </Col>
            ) : (
              <>
                {window.screen.width > 996 && (
                  <Row style={{ marginBottom: "1vw" }}>
                    <Col xl={{ span: 12 }}>
                      <span className="todo_title">To Do</span>
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Button
                        icon={<PlusOutlined style={{ color: "#3045d2" }} />}
                        onClick={() => {
                          this.setState({
                            openForm: true,
                            flagPriority: "",
                          });
                        }}
                        className="todo_button"
                        style={{ float: "right" }}
                      >
                        <span className="virtual_button_text btn-color">
                          ADD TASK
                        </span>
                      </Button>
                    </Col>
                  </Row>
                )}
                <Card
                  className="card_virtual"
                  bodyStyle={{
                    padding: window.screen.width > 996 ? "24px" : "0px",
                  }}
                >
                  <div className="todo_collapse">
                    <Collapse
                      defaultActiveKey={!this.props.showData && ["1"]}
                      style={{ padding: "0px", border: "none" }}
                    >
                      <Panel
                        header={
                          <>
                            To Do{" "}
                            <span style={{ color: "#FFF", fontWeight: "400" }}>
                              - ( {todoList?.length} tasks )
                            </span>
                          </>
                        }
                        key="1"
                        style={{ background: "#f0f2f5", padding: "0px" }}
                      >
                        <Table
                          className="table_crm"
                          columns={webColumns}
                          dataSource={todoList}
                          loading={loading}
                          // rowSelection={rowSelection}
                          rowKey={(record) => record.id}
                          pagination={false}
                          style={{ cursor: "pointer" }}
                          rowClassName="ant-card-hoverable"
                        />
                      </Panel>
                    </Collapse>
                  </div>

                  {/* <Col
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <div className="todo_collapse_goal">
                      <Collapse
                        defaultActiveKey={["1"]}
                        style={{ padding: "0px", border: "none" }}
                      >
                        <Panel
                          header="GOAL"
                          key="1"
                          style={{ background: "#f0f2f5", padding: "0px" }}
                        >
                          <Table
                            className="table_crm"
                            columns={goalColumns}
                            dataSource={goalData}
                            loading={loading}
                            rowSelection={rowSelection}
                            pagination={false}
                            style={{ cursor: "pointer" }}
                            rowClassName="ant-card-hoverable"
                          />
                        </Panel>
                      </Collapse>
                    </div>
                  </Col> */}

                  <Col
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <div className="todo_collapse_done todo_collapse_goal">
                      <Collapse
                        defaultActiveKey={["1"]}
                        style={{ padding: "0px", border: "none" }}
                      >
                        <Panel
                          header={
                            <>
                              DONE{" "}
                              <span
                                style={{ color: "#FFF", fontWeight: "400" }}
                              >
                                - ( {doneList?.length} tasks )
                              </span>
                            </>
                          }
                          key="1"
                          style={{ background: "#f0f2f5", padding: "0px" }}
                        >
                          <Table
                            className="table_crm"
                            columns={webDoneColumns}
                            dataSource={doneList}
                            loading={loading}
                            pagination={false}
                            style={{ cursor: "pointer" }}
                            rowClassName="ant-card-hoverable"
                          />
                        </Panel>
                      </Collapse>
                    </div>
                  </Col>
                </Card>
              </>
            )}
          </Col>
        ) : (
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            className="mobile_view_margin_Top"
          >
            <Card
              className="card_virtual"
              bodyStyle={{
                padding: window.screen.width > 996 ? "24px" : "0px",
              }}
            >
              <Col
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <Collapse style={{ padding: "0px" }}>
                  <Panel
                    style={{ padding: "0px" }}
                    header={
                      <Row>
                        <Col span={12}>To Do </Col>
                        <Col span={12} style={{ float: "right" }}>
                          <Button
                            icon={<PlusOutlined style={{ color: "#3045d2" }} />}
                            onClick={() => {
                              this.setState({
                                openForm: true,
                                flagPriority: "",
                              });
                            }}
                            className="todo_button"
                            style={{ float: "right", height: "30px" }}
                          >
                            <span
                              className="virtual_button_text btn-color"
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              ADD TASK
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    }
                    extra={false}
                  >
                    <Table
                      className="table_crm"
                      columns={mobColumns}
                      dataSource={todoList}
                      loading={loading}
                      rowKey={(record) => record.id}
                      pagination={false}
                      style={{ cursor: "pointer" }}
                      rowClassName="ant-card-hoverable"
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Card>
          </Col>
        )}
        {openForm && this.openModal(openForm, loading, todoData)}
      </div>
    );
  }
}
export default withAppContext(withRouter(Todo));
