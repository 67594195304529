import React, { Component } from "react";
import { Layout, Menu } from "antd";
import "antd/dist/antd.css";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import "../../stylesheets/_virtualoffice.scss";
import MainHeader from "../../common/Header";
import AppRoutes from "../../config/AppRoutes";
import { withAppContext } from "../../store/contextStore";
import { UserOutlined } from "@ant-design/icons";
import pipeline from "../../images/pipeline.png";
const { SubMenu } = Menu;
const { Content, Sider } = Layout;

class Application extends Component {
  rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  onLogout = async () =>
    new Promise((resolve) => {
      Auth.signOut();
      localStorage.clear();
      this.props.history.push("/login");
    });

  render() {
    const { collapsed } = this.state;
    const { Header } = this.props.context.state.websiteThemes;
    let header = "";
    header = Header;
    const loginUser = JSON.parse(localStorage.getItem("userDetails"));
    return (
      <Layout>
        <MainHeader />
        {header && (
          <Layout>
            <Sider
              collapsible
              collapsed={window.screen.width > "501" ? collapsed : true}
              onCollapse={this.onCollapse}
              style={{ background: header.headerbackground }}
              width={240}
            >
              <Menu
                theme="dark"
                inlineCollapsed={this.state.collapsed}
                defaultSelectedKeys={
                  window.location.pathname === "/VirtualOffice/Dashboard"
                    ? "1"
                    : window.location.pathname === "/VirtualOffice/AllContacts"
                    ? "51"
                    : window.location.pathname ===
                      "/VirtualOffice/ProfessionalContacts"
                    ? "52"
                    : window.location.pathname === "/VirtualOffice/Colleagues"
                    ? "53"
                    : window.location.pathname === "/VirtualOffice/MyPipeline"
                    ? "4"
                    : window.location.pathname === "/VirtualOffice/Todo"
                    ? "5"
                    : window.location.pathname ===
                      "/VirtualOffice/resourcesLinks"
                    ? "6"
                    : window.location.pathname === "/VirtualOffice/expenses"
                    ? "7"
                    : window.location.pathname === "/VirtualOffice/trainings"
                    ? "8"
                    : ["sub1"]
                }
                mode="inline"
                defaultOpenKeys={["sub1"]}
                style={{
                  marginTop: "1vw",
                  background: header.headerbackground,
                }}
              >
                <Menu.Item
                  key="1"
                  icon={
                    <Link to="/VirtualOffice/Dashboard">
                      <i
                        className="fa fa-tachometer fa-lg icon_dashboard"
                        aria-hidden="true"
                      />
                    </Link>
                  }
                >
                  <Link to="/VirtualOffice/Dashboard">
                    <span
                      className="menuitem"
                      style={{ color: header.tabColor }}
                    >
                      Dashboard
                    </span>
                  </Link>
                </Menu.Item>
                <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <UserOutlined className="icon_dashboard" />
                      <span
                        className="menuitem"
                        style={{ paddingLeft: "20px" }}
                      >
                        Contacts
                      </span>
                    </span>
                  }
                  style={{ color: header.tabColor }}
                >
                  <Menu.Item key="51">
                    <Link to="/VirtualOffice/AllContacts">
                      <span
                        className="menuitem"
                        style={{ color: header.tabColor }}
                      >
                        Contacts
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="52">
                    <Link to="/VirtualOffice/ProfessionalContacts">
                      <span
                        className="menuitem"
                        style={{ color: header.tabColor }}
                      >
                        Professional Contacts
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="53">
                    <Link to="/VirtualOffice/Colleagues">
                      <span
                        className="menuitem"
                        style={{ color: header.tabColor }}
                      >
                        Colleagues
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>

                {loginUser &&
                  (loginUser.markAsManager ||
                    loginUser.userProfile === "A" ||
                    loginUser.userProfile === "concierge") && (
                    <SubMenu
                      key="sub2"
                      title={
                        <span>
                          <i
                            className="fa fa-sitemap fa-lg icon_dashboard"
                            aria-hidden="true"
                          />
                          <span
                            className="menuitem"
                            style={{ paddingLeft: "10px" }}
                          >
                            Website Leads
                          </span>
                        </span>
                      }
                      style={{ color: header.tabColor }}
                    >
                      <Menu.Item key="9">
                        <Link to="/VirtualOffice/all_leads">
                          <span
                            className="menuitem"
                            style={{ color: header.tabColor }}
                          >
                            All Leads
                          </span>
                        </Link>
                      </Menu.Item>{" "}
                      <Menu.Item key="10">
                        <Link to="/VirtualOffice/missedLeads">
                          <span
                            className="menuitem"
                            style={{ color: header.tabColor }}
                          >
                            Missed leads
                          </span>
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                  )}
                <Menu.Item
                  key="4"
                  icon={
                    <Link to="/VirtualOffice/MyPipeline">
                      <img
                        src={pipeline}
                        alt="pipeline"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </Link>
                  }
                >
                  <Link to="/VirtualOffice/MyPipeline">
                    <span
                      className="menuitem"
                      style={{ color: header.tabColor, paddingLeft: "10px" }}
                    >
                      My Pipeline
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="5"
                  icon={
                    <Link to="/VirtualOffice/Todo">
                      <i
                        className="fa fa-calendar-check-o fa-lg icon_dashboard"
                        aria-hidden="true"
                      />
                    </Link>
                  }
                >
                  <Link to="/VirtualOffice/Todo">
                    <span
                      className="menuitem"
                      style={{ color: header.tabColor }}
                    >
                      To Do
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="6"
                  icon={
                    <Link to="/VirtualOffice/resourcesLinks">
                      <i
                        className="fa fa-link fa-lg icon_dashboard"
                        aria-hidden="true"
                      />
                    </Link>
                  }
                >
                  <Link to="/VirtualOffice/resourcesLinks">
                    <span
                      className="menuitem"
                      style={{ color: header.tabColor }}
                    >
                      Links/Resources
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="7"
                  icon={
                    <Link to="/VirtualOffice/expenses">
                      <i
                        className="fa fa-money fa-lg icon_dashboard"
                        aria-hidden="true"
                      />
                    </Link>
                  }
                >
                  <Link to="/VirtualOffice/expenses">
                    <span
                      className="menuitem"
                      style={{ color: header.tabColor }}
                    >
                      Expenses
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="8"
                  icon={
                    <Link to="/VirtualOffice/trainings">
                      <i
                        className="fa fa-graduation-cap  fa-lg icon_dashboard"
                        aria-hidden="true"
                        style={{ marginRight: "8px" }}
                      />
                    </Link>
                  }
                >
                  <Link to="/VirtualOffice/trainings">
                    <span
                      className="menuitem"
                      style={{ color: header.tabColor }}
                    >
                      Trainings
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="12"
                  icon={
                    <Link to="/VirtualOffice/emailTemplates">
                      <i
                        className="fa fa-envelope fa-lg icon_dashboard"
                        aria-hidden="true"
                      />
                    </Link>
                  }
                >
                  <Link to="/VirtualOffice/emailTemplates">
                    <span
                      className="menuitem"
                      style={{ color: header.tabColor }}
                    >
                     Email Templates
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="11"
                  onClick={this.onLogout}
                  icon={
                    <Link onClick={this.onLogout}>
                      <i
                        className="fa fa-sign-out fa-lg icon_dashboard"
                        aria-hidden="true"
                      />
                    </Link>
                  }
                >
                  <Link onClick={this.onLogout}>
                    <span
                      className="menuitem"
                      style={{ color: header.tabColor }}
                    >
                      Logout
                    </span>
                  </Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout>
              <Content
                className="site-layout-background"
                style={{
                  margin: 0,
                  minHeight: "100vh",
                }}
              >
                <Switch>
                  {AppRoutes.map((route) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                      key={route.key}
                    />
                  ))}
                </Switch>
              </Content>
            </Layout>
          </Layout>
        )}
      </Layout>
    );
  }
}
export default withRouter(withAppContext(Application));
