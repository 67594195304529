import {
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Button,
  Spin,
  Select,
  Drawer,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { withAppContext } from "../../../store/contextStore";
import {
  update,
  getDetails,
  noAuthlist,
  deleteRecord,
} from "../../../api/Apis";
import Subscriptions from "../../Subscriptions/Subscriptions";
import RichText from "../../../common/RichText";
const { Option } = Select;
class LenderProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: {
        cities: [],
        type: "",
      },
      sellerName: "",
      sellerPhone: "",
      sellerAddress: "",
      citySearch: [],
      saveDetailsFalse: false,
      zipCode: "",
      serviceAreas: [],
      spec: 0,
      zip: "",
      Ziplist: [],
      Addmore: false,
      speclist: [],
      optionsSelected: [],
      zipCount: 0,
      showSubscriptions: false,
      dataLoadingFalse: false,
      richtTextBiofield: "",
      allUsersList: [],
      referedById: "",
      referedByName: "",
      handleZipErrorMsg: false,
      agentsList: [],
      userInput: false,
      addUserName: "",
      addUserEmail: "",
      newSpecialtiesAdded: [],
      oldCategories: [],
      loadTotalData: false,
      agentTowns: [],
    };
  }

  componentDidMount() {
    this.getAllUsers();
    this.getAllAgents();
    this.getPortfoliosList();
    const { userDetails } = this.props;
    if (
      userDetails &&
      userDetails.newdata &&
      userDetails.newdata.serviceAreas
    ) {
      this.setState({
        serviceAreas: userDetails.newdata.serviceAreas,
      });
    }

    // if (userDetails && userDetails.newdata && userDetails.newdata.Specialties) {
    //   this.setState({
    //     optionsSelected: userDetails.newdata.Specialties,
    //   });
    // }
    if (
      userDetails &&
      userDetails.newdata &&
      userDetails.newdata.zipCodes &&
      userDetails.newdata.zipCodes
    ) {
      this.setState({
        Ziplist: userDetails.newdata.zipCodes,
      });
    }

    if (
      userDetails &&
      userDetails.newdata &&
      userDetails.newdata.agentTowns &&
      userDetails.newdata.agentTowns.length > 0
    ) {
      this.setState({
        agentTowns:
          userDetails.data &&
          userDetails.data.newdata &&
          userDetails.data.newdata.agentTowns &&
          userDetails.data.newdata.agentTowns,
      });
    }
  }
  getAllUsers = async () => {
    try {
      const usersList = await noAuthlist("getUsers",{isActive:true});
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
        };
      });
      if (usersList.success) {
        this.setState({
          allUsersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  getAllAgents = async () => {
    try {
      const AllagentsList = await noAuthlist("getAgents",{isActive:true});
      let singleAgent = AllagentsList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
          email: user.email,
        };
      });
      if (AllagentsList.success) {
        this.setState({
          agentsList: singleAgent,
        });
      } else {
        notification["error"]({
          message: AllagentsList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };
  getPortfoliosList = async (cancelProp) => {
    const { userDetails } = this.props.context.state;
    try {
      const response = await noAuthlist(
        "testimonials?userId=" + userDetails.id + "&dataType=portfolio"
      );
      if (response.success) {
        const oldCategories = [];
        const optionsSelected = [];
        response.data.map((eachPortfolio) =>
          oldCategories.push({
            label: eachPortfolio.portfolioName,
            id: eachPortfolio.id,
          })
        );
        response.data.map((eachPortfolio) =>
          optionsSelected.push(eachPortfolio.portfolioName)
        );
        this.setState({
          oldCategories,
          loadTotalData: true,
          optionsSelected,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
    }
  };
  handleZip = (value) => {
    this.setState({ zip: value.target.value, multiZip: value.target.value });
    if (this.state.Ziplist.includes(value.target.value)) {
      this.setState({
        handleZipErrorMsg: true,
      });
      notification["error"]({
        message:
          "You have already added " +
          value.target.value +
          " zip code. Please add another",
        // description: "You have already added " + this.state.zip + " zip code",
      });
    }
    //  else {
    //   const Ziplist = [...this.state.Ziplist];
    //   Ziplist.push(value.target.value);
    //   this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    // }
  };

  handleSellereProfile = async (name, value) => {
    await this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onFinish = (e) => {
    window.addEventListener("keydown", function (event) {});
    this.updateDetails(e);
  };

  handleRichtextBio = (bio) => {
    this.setState({
      richtTextBiofield: bio,
    });
  };

  updateDetails = async (data2) => {
    if (
      this.props.context.state &&
      this.props.context.state.userDetails &&
      this.props.context.state.userDetails.userProfile === "B" &&
      this.state.serviceAreas !== "" &&
      this.state.serviceAreas.length === 0
    ) {
      notification["error"]({
        message: "Towns of Interest",
        description: "Please select atleast one town",
      });
      return;
    }

    this.setState({
      saveDetailsFalse: true,
    });

    const { richtTextBiofield } = this.state;
    const { userDetails } = this.props.context.state;
    const id = userDetails.id;
    data2["profilePicture"] =
      this.props.fileList.length > 0
        ? this.props.fileList
        : userDetails.profilePicture
        ? userDetails.profilePicture
        : "";
    data2["profileUrl"] = this.props.profileImage
      ? this.props.profileImage
      : userDetails.profileUrl
      ? userDetails.profileUrl
      : "";
    data2["address"] =
      this.state.citySearch.length > 0
        ? this.state.citySearch
        : userDetails.newdata && userDetails.newdata.companyAddress;
    data2["postalCode"] = this.state.zipCode ? this.state.zipCode : "";

    let agentInfo = {
      agentName: this.state.addUserName,
      agentEmail: this.state.addUserEmail,
    };

    // referedByNameAgent: this.state.referedByNameAgent,
    // referedByIdAgent: this.state.referedByIdAgent,

    const data = Object.assign(
      {},
      {
        entityData: {
          newdata: {
            agentTowns: this.state.agentTowns,
            zipCodes: this.state.Ziplist ? this.state.Ziplist : "",
            whatsapp: data2.whatsapp ? data2.whatsapp : "",
            serviceAreas: this.state.serviceAreas
              ? this.state.serviceAreas
              : "",
            titleorPosition: data2.titleorPosition ? data2.titleorPosition : "",
            mobile: data2.mobile ? data2.mobile : "",
            language: data2.language ? data2.language : "",
            faxNumber: data2.faxNumber ? data2.faxNumber : "",
            TaxNumber: data2.TaxNumber ? data2.TaxNumber : "",
            companyName: data2.companyName ? data2.companyName : "",
            companyAddress: data2.address ? data2.address : "",
            officeZipCode: data2.postalCode,
          },
          email: data2.email ? data2.email : "",
          firstName: data2.firstName ? data2.firstName : "",
          lastName: data2.lastName ? data2.lastName : "",
          mlsId: data2.mlsId ? data2.mlsId.toUpperCase() : "",
          phone: data2.phone ? data2.phone : "",
          profilePicture: data2.profilePicture ? data2.profilePicture : "",
          profileUrl: data2.profileUrl ? data2.profileUrl : "",
          userBio: richtTextBiofield ? richtTextBiofield : "",
          referedByName: this.state.referedByName,
          referedById: this.state.referedById,
          licenseIds: data2.licenseIds ? data2.licenseIds : "",
          agentInfo: agentInfo,
          addUserName: this.state.addUserName,
          portfolioName: this.state.newSpecialtiesAdded,
          Website: data2.website ? data2.website : "",
        },
      }
    );
    const removeCategory = this.state.oldCategories.filter(
      (removeItem) => !this.state.optionsSelected.includes(removeItem.label)
    );
    removeCategory &&
      removeCategory.map((removeWithId) => {
        deleteRecord("testimonials", removeWithId.id)
          .then((response) => {
            if (response.success) {
            } else {
              notification["error"]({
                message: response.message,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        return true;
      });
    if (data.entityData.email && data.entityData.firstName) {
      const updatingUserDetails = await update("users", id, data);
      if (updatingUserDetails.success) {
        this.setState({
          editing: false,
          saveDetailsFalse: false,
        });
        notification["success"]({
          message: "Your profile has been updated",
          // description: "Your profile details updated successfully",
          duration: 3,
        });
      }
      const userProfileDetails = await getDetails("users", id);
      localStorage.removeItem("userDetails");
      localStorage.setItem(
        "userDetails",
        JSON.stringify(userProfileDetails.data)
      );
      this.props.context.setUserDetails(userProfileDetails.data);
    }
  };

  handleZipCode = (address) => {
    let companyAddress = address.label.split(",");
    const citySearch = companyAddress;
    this.setState({ citySearch });
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "postal_code") {
            this.setState({
              zipCode: address[i].long_name,
            });
          }
        }
      })
      .catch((error) => console.error("Error", error));
  };

  handleServiceAreas = (address) => {
    let serviceAreas = [];
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            serviceAreas.push(...this.state.serviceAreas, address[i].long_name);
            this.setState({
              serviceAreas,
            });
          }
        }
      })
      .catch((error) => console.error("Error", error));
  };

  onReferedBySelect = (value, option) => {
    this.setState({
      referedById: option.value,
      referedByName: option.label,
    });
  };

  onReferedBySelectAgent = (value, option) => {
    this.setState({
      addUserEmail: option.email,
      addUserName: option.label,
    });
  };

  onSelect = (result) => {
    const address = result.label.split(",");
    let vara = this.state.cities.cities;
    vara.push(address[0]);
    this.setState({
      cities: {
        cities: vara,
        type: "cities",
      },
    });
  };

  onClose = (value) => {
    const data =
      this.state.serviceAreas &&
      this.state.serviceAreas.filter((city) => city !== value);
    this.setState({
      serviceAreas: data,
    });
  };

  handleChange = (value) => {
    if (this.state.Ziplist.includes(this.state.zip)) {
      notification["error"]({
        message:
          "You have already added " +
          this.state.zip +
          " zip code. Please add another",
        // description: "You have already added " + this.state.zip + " zip code",
      });
    } else {
      this.handleZipTowns(this.state.zip);
      const Ziplist = [...this.state.Ziplist];
      Ziplist.push(this.state.zip);
      this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    }
  };
  handleZipTowns = (address) => {
    try {
      geocodeByAddress(address)
        .then((results) => {
          let address = results[0].address_components;

          let agentTownArray =
            this.state.agentTowns.length > 0 ? this.state.agentTowns : [];
          let townInfo = {
            cityName: "",
            stateName: "",
            zipCode: "",
          };

          for (let i = 0; i < address.length; i++) {
            if (address[i].types[0] === "locality") {
              townInfo["cityName"] = address[i].long_name;
            }
            if (address[i].types[0] === "administrative_area_level_1") {
              townInfo["stateName"] = address[i].long_name;
            }
            if (address[i].types[0] === "postal_code") {
              townInfo["zipCode"] = address[i].long_name;
            }
          }
          agentTownArray.push(townInfo);
          this.setState({
            agentTowns: agentTownArray,
          });
        })
        .catch((error) => console.error("Error", error));
    } catch (error) {
      console.error("Error", error);
    }
  };

  handleDeselect = (value) => {
    this.handleDeselectZipTowns(value);
    const deleteItem = this.state.Ziplist.indexOf(value);
    let presentItems = this.state.Ziplist.splice(deleteItem, 1);
    this.setState({ zipCount: presentItems.length });
  };

  handleDeselectZipTowns = (value) => {
    let tempAgentTowns = this.state.agentTowns;
    let result = tempAgentTowns.filter(
      (agentTownInfo) => !agentTownInfo.zipCode.includes(value)
    );
    this.setState({
      agentTowns: result,
    });
  };

  handlespecialties = (value) => {
    this.setState({
      optionsSelected: [...this.state.optionsSelected, value],
    });
    if (!this.state.optionsSelected.includes(value)) {
      this.setState({
        newSpecialtiesAdded: [...this.state.newSpecialtiesAdded, value],
      });
    }
    if (value.length === this.props.userDetails.subscriptionPack.specialites) {
      notification.info({
        message: "Please upgrade to add more specialites",
      });
    }
  };
  deleteSpecificCategory = (value) => {
    const optionsSelected = this.state.optionsSelected.filter(
      (deleteItem) => deleteItem !== value
    );
    const newSpecialtiesAdded = this.state.newSpecialtiesAdded.filter(
      (removeNewItem) => removeNewItem !== value
    );
    this.setState({
      optionsSelected,
      newSpecialtiesAdded,
    });
  };
  handleDeselect = (value) => {
    const deleteItem = this.state.Ziplist.indexOf(value);
    let presentItems = this.state.Ziplist.splice(deleteItem, 1);
    // this.state.Ziplist.splice(deleteItem, 1);
    this.setState({ zipCount: presentItems.length });
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.setState({
      showSubscriptions: true,
    });
  };

  setLoadingfalse = () => {
    this.setState({
      dataLoadingFalse: false,
    });
  };
  handleAddUser = () => {
    const { addUserName, addUserEmail } = this.state;
    if (addUserName !== "" && addUserEmail !== "") {
      let agentsList = this.state.agentsList;
      agentsList.push({
        label: addUserName,
        email: addUserEmail,
        value: addUserName,
      });
      this.setState({
        agentsList,
      });
    }
    this.setState({
      userInput: true,
    });
  };
  handleaddUserName = (e) => {
    this.setState({
      addUserName: e.target.value,
    });
  };
  handleaddUserEmail = (e) => {
    this.setState({
      addUserEmail: e.target.value,
    });
  };
  render() {
    const {
      saveDetailsFalse,
      optionsSelected,
      showSubscriptions,
      loadTotalData,
      oldCategories,
    } = this.state;
    const { userDetails } = this.props;
    const opts = [
      { label: "New Construction", value: "New Construction" },
      { label: "FHA Loans", value: "FHA Loans" },
      { label: "Renovation Loans", value: "Renovation Loans" },
      { label: "Conventional loans", value: "Conventional loans" },
      { label: "First Time Buyers", value: "First Time Buyers" },
      { label: "Investment Loans", value: "Investment Loans" },
      { label: "1031 Exchanges", value: "1031 Exchanges" },
      { label: "Commercial Loans", value: "Commercial Loans" },
      { label: "USDA Loans", value: "USDA Loans" },
      { label: "VA Loans", value: "VA Loans" },
      { label: "Jumbo Loans", value: "Jumbo Loans" },
      { label: "Direct Lender", value: "Direct Lender" },
      { label: "Mass Housing", value: "Mass Housing" },
    ];
    return (
      <>
        <Form
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          size="large"
        >
          <Spin spinning={saveDetailsFalse}>
            <Row>
              <Col
                xl={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="label-titles">First Name</div>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Full Name!",
                    },
                  ]}
                  initialValue={
                    userDetails
                      ? userDetails.firstName
                        ? userDetails.firstName
                        : ""
                      : ""
                  }
                >
                  <Input placeholder="First Name" className="form-input" />
                </Form.Item>

                <div className="label-titles">Phone</div>
                <Form.Item
                  name="phone"
                  initialValue={
                    userDetails
                      ? userDetails.phone
                        ? userDetails.phone
                        : ""
                      : ""
                  }
                >
                  <InputNumber
                    placeholder="Enter your phone number"
                    className="form-input-number"
                    formatter={(value) => {
                      const cleaned = `${value}`.replace(/\D/g, "");
                      const match = cleaned.match(
                        /^(1|)?(\d{3})(\d{3})(\d{4})$/
                      );
                      if (match) {
                        const intlCode = match[1] ? "+1 " : "";
                        return [
                          intlCode,
                          "(",
                          match[2],
                          ") ",
                          match[3],
                          "-",
                          match[4],
                        ].join("");
                      }
                      return null;
                    }}
                  />
                </Form.Item>

                <div className="label-titles">Company Name</div>
                <Form.Item
                  name="companyName"
                  initialValue={
                    userDetails
                      ? userDetails.newdata
                        ? userDetails.newdata.companyName &&
                          userDetails.newdata.companyName
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Enter your company name"
                    className="form-input"
                  />
                </Form.Item>

                <div className="label-titles">Mortgage License #</div>
                <Form.Item
                  name="licenseIds"
                  initialValue={
                    userDetails
                      ? userDetails.licenseIds
                        ? userDetails.licenseIds
                        : ""
                      : ""
                  }
                >
                  <Input placeholder="License IDs" className="form-input" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="label-titles">Last Name</div>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Full Name!",
                    },
                  ]}
                  initialValue={
                    userDetails
                      ? userDetails.lastName
                        ? userDetails.lastName
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Last Name"
                    value=""
                    className="form-input"
                  />
                </Form.Item>

                <div className="label-titles">Email</div>
                <Form.Item
                  name="email"
                  initialValue={
                    userDetails
                      ? userDetails.email
                        ? userDetails.email
                        : ""
                      : ""
                  }
                >
                  <Input
                    readOnly={true}
                    placeholder="email"
                    className="form-input"
                  />
                </Form.Item>

                <>
                  <div className="label-titles">Company Address</div>
                  <Form.Item name="address" className="google-form-search">
                    <GooglePlacesAutocomplete
                      className="form-input"
                      apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        // types: ["(cities)"],
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        defaultInputValue: `${
                          userDetails && userDetails.newdata
                            ? userDetails.newdata.companyAddress
                            : ""
                        }`,
                        placeholder: "Type Company Address",
                        onChange: (result) => {
                          this.handleZipCode(result);
                        },
                      }}
                    />
                  </Form.Item>
                </>
                <div className="label-titles">Website</div>
                <Form.Item
                  name="website"
                  initialValue={
                    userDetails
                      ? userDetails.Website
                        ? userDetails.Website
                        : ""
                      : ""
                  }
                >
                  <Input placeholder="Website" className="form-input" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 23, offset: 1 }}
                lg={{ span: 23, offset: 1 }}
                md={{ span: 23, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="label-titles">Referred by</div>
                <Form.Item name="referedBy">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    options={this.state.allUsersList}
                    onSelect={this.onReferedBySelect}
                    placeholder="Referred by"
                    defaultValue={
                      userDetails &&
                      userDetails.referedByName &&
                      userDetails.referedByName !== ""
                        ? userDetails.referedByName
                        : ""
                    }
                    filterOption={(inputValue, option) =>
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  ></Select>
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 23, offset: 1 }}
                lg={{ span: 23, offset: 1 }}
                md={{ span: 23, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="label-titles">
                  Zip codes serviced
                  {userDetails &&
                    userDetails.subscriptionPack &&
                    userDetails.subscriptionPack.zipcodes && (
                      <p
                        style={{
                          float: "right",
                          marginBottom: "0px",
                          color: "red",
                        }}
                      >
                        {userDetails.subscriptionPack.zipcodes -
                          this.state.Ziplist.length}{" "}
                        Zipcodes left
                      </p>
                    )}
                </div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Form.List
                    name="multiZipCodesList"
                    initialValue={this.state.zip}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        <Form.Item
                          name="zip"
                          rules={[
                            {
                              required: this.state.handleZipErrorMsg,
                              message: "Please input your Username!",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            value={this.state.zip}
                            onChange={this.handleZip}
                            className="form-input-profile"
                          />
                          {userDetails &&
                            userDetails.subscriptionPack &&
                            userDetails.subscriptionPack.zipcodes &&
                            (userDetails.subscriptionPack.zipcodes ===
                            this.state.Ziplist.length ? (
                              <Button
                                type="default"
                                onClick={this.handleLink}
                                className="upgrade-add-btn"
                                icon={<PlusOutlined />}
                                style={{
                                  background: "red",
                                  borderColor: "red",
                                  color: "white",
                                }}
                              >
                                <span
                                  className="upgrade-add-more"
                                  style={{ color: "white" }}
                                >
                                  {" "}
                                  Upgrade to Add More
                                </span>
                              </Button>
                            ) : (
                              <Button
                                htmlType="submit"
                                onClick={(e) => {
                                  add();
                                  this.handleChange();
                                  e.preventDefault();
                                }}
                                className="upgrade-add-zip"
                                style={{
                                  borderTopRightRadius: "5px",
                                  borderBottomRightRadius: "5px",
                                  float: "right",
                                  background: "#3045d2",
                                  borderColor: "#3045d2",
                                  color: "white",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  height: "50px",
                                }}
                                icon={<PlusOutlined />}
                                disabled={
                                  (userDetails.subscriptionPack &&
                                    userDetails.subscriptionPack.zipcodes &&
                                    userDetails.subscriptionPack.zipcodes ===
                                      this.state.Ziplist.length) ||
                                  this.state.zip.length === 0
                                    ? true
                                    : false
                                }
                              >
                                Add Zipcode
                              </Button>
                            ))}
                        </Form.Item>
                        <Row>
                          {this.state.Ziplist.map((field, index) => (
                            <Form.Item required={false} key={field.key}>
                              <Input
                                readOnly
                                style={{
                                  width: "70%",
                                  marginRight: "-50px",
                                }}
                                value={field}
                                addonAfter={
                                  this.state.Ziplist.length >= 1 ? (
                                    <CloseOutlined
                                      className="dynamic-delete-button"
                                      onClick={() => {
                                        remove(field);
                                        this.handleDeselect(field);
                                      }}
                                      style={{ color: "red" }}
                                    />
                                  ) : null
                                }
                                className="form-input"
                              />
                            </Form.Item>
                          ))}
                        </Row>
                      </>
                    )}
                  </Form.List>
                </Form>
              </Col>
              {loadTotalData && (
                <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="label-titles">
                    Specialties
                    {userDetails &&
                      userDetails.subscriptionPack &&
                      userDetails.subscriptionPack.specialites && (
                        <p
                          style={{
                            float: "right",
                            marginBottom: "0px",
                            color: "red",
                          }}
                        >
                          {userDetails.subscriptionPack.specialites -
                            optionsSelected.length}{" "}
                          Specialties left
                        </p>
                      )}
                  </div>
                  {userDetails &&
                    userDetails.subscriptionPack &&
                    userDetails.subscriptionPack.specialites && (
                      <Form.Item name="specialties">
                        <Select
                          mode="multiple"
                          style={{
                            width:
                              optionsSelected.length >
                              userDetails.subscriptionPack.specialites - 1
                                ? window.screen.width >= "768"
                                  ? "75%"
                                  : "60%"
                                : "100%",
                          }}
                          placeholder="Please select"
                          onSelect={this.handlespecialties}
                          onDeselect={this.deleteSpecificCategory}
                          className="form-input-profile1"
                          defaultValue={
                            oldCategories
                              ? oldCategories.map((Category) => Category.label)
                              : ["jjj"]
                          }
                        >
                          {opts.map(
                            (item) =>
                              userDetails &&
                              userDetails.subscriptionPack &&
                              userDetails.subscriptionPack.specialites && (
                                <Option
                                  disabled={
                                    optionsSelected &&
                                    optionsSelected.length >
                                      userDetails.subscriptionPack.specialites -
                                        1
                                      ? optionsSelected.includes(item.value)
                                        ? false
                                        : true
                                      : false
                                  }
                                  key={item.value}
                                >
                                  {item.value}
                                </Option>
                              )
                          )}
                        </Select>
                        {optionsSelected.length >
                          userDetails.subscriptionPack.specialites - 1 && (
                          <Button
                            type="default"
                            onClick={this.handleLink}
                            icon={<PlusOutlined />}
                            className="upgrade-add-btn-sub"
                            style={{
                              background: "red",
                              color: "white",
                              borderColor: "red",
                            }}
                          >
                            <span
                              className="upgrade-add-more-sub"
                              style={{ color: "white" }}
                            >
                              Upgrade to Add More
                            </span>
                          </Button>
                        )}
                      </Form.Item>
                    )}
                </Col>
              )}

              <Col
                xl={{ span: 23, offset: 1 }}
                lg={{ span: 23, offset: 1 }}
                md={{ span: 23, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div id="About" className="form-titles">
                  About me{" "}
                </div>
                <Form.Item
                  name="userBio"
                  initialValue={
                    userDetails
                      ? userDetails.userBio
                        ? userDetails.userBio
                        : ""
                      : ""
                  }
                >
                  {/* <TextArea placeholder="Start typing" rows={4} name="About" /> */}
                  {/* <ReactQuill /> */}
                  <RichText
                    handleRichtextBio={this.handleRichtextBio}
                    richtTextBiofieldProp={
                      userDetails.userBio ? userDetails.userBio : ""
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 8, offset: 1 }}
                lg={{ span: 8, offset: 1 }}
                md={{ span: 8, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Button type="primary" htmlType="submit" className="update-btn">
                  Update Profile
                </Button>
              </Col>
            </Row>
          </Spin>
        </Form>

        <Drawer
          title={"Upgrade to Add More"}
          placement="bottom"
          width={"100%"}
          height={"100%"}
          mask={true}
          closable={true}
          getContainer={false}
          visible={showSubscriptions}
          onClose={() =>
            this.setState({
              showSubscriptions: false,
            })
          }
          headerStyle={{ textAlign: "center" }}
        >
          <Row>
            <Col span={24}>
              <Subscriptions setLoadingfalse={this.setLoadingfalse} />
            </Col>
          </Row>
        </Drawer>
      </>
    );
  }
}
export default withAppContext(withRouter(LenderProfile));
