import {
  Call,
  ApiUrl,
  noAuthApiUrl,
  SimpleRestUrl,
  AgentSimpleRestUrl,
  PropertiesUrl,
  SimpleCall,
  ParseGeneralResponse,
  PropertiesUrlPin,
} from "./ApiUtils";

const queryString = require("query-string");

export function list(entityName, query = {}) {
  let url = ApiUrl(entityName);
  url = queryString.stringifyUrl({ url: url, query: query });
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function getDetails(entityName, id) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function add(entityName, data) {
  const url = ApiUrl(entityName);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function update(entityName, id, data) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function deleteRecord(entityName, id) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "delete",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function simple() {
  const url = SimpleRestUrl();
  return SimpleCall(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function PropertiesList(query) {
  let url = SimpleRestUrl();
  url = queryString.stringifyUrl({ url: url, query: query });
  return SimpleCall(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "", headers: "" } },
    true
  );
}

export function AgentPropertiesList(vendorType, id) {
  let url = AgentSimpleRestUrl(vendorType, id);
  return SimpleCall(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "", headers: "" } },
    true
  );
}

export function PropertiesDetail(id) {
  const url = PropertiesUrl(id);
  return SimpleCall(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
export function PropertiesDetailPin(id) {
  const url2 = PropertiesUrlPin(id);
  return SimpleCall(
    "get",
    url2,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
export function usersList(entityName) {
  const url = ApiUrl(entityName);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function noAuthlist(entityName, query = {}) {
  let url = noAuthApiUrl(entityName);
  const orgId =
    "orgId" in query === true
      ? query["orgId"]
      : JSON.parse(localStorage.getItem("orgId"));
  query = {
    ...query,
    orgId,
  };
  url = queryString.stringifyUrl({ url: url, query: query });
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}

export function noAuthgetDetails(entityName, id) {
  const url = noAuthApiUrl(entityName + "/" + id);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}
export function noAuthGetInfo(entityName) {
  const url = noAuthApiUrl(entityName);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}
export function noAuthadd(entityName, data) {
  const url = noAuthApiUrl(entityName);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}

export function noAuthupdate(entityName, id, data) {
  const url = noAuthApiUrl(entityName + "/" + id);
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

// export function  noAuthdeleteRecord(entityName,id) {
//     const url = noAuthApiUrl(entityName+'/'+id);
//     return Call('delete', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
// }
