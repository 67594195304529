import React, { Component } from "react";
import "antd/dist/antd.css";
import { Row, Col, Button, Drawer } from "antd";
import MortgageCalculator from "mortgage-calculator-react";
import { CalculatorOutlined } from "@ant-design/icons";
import Advertisement from "../common/Advertisement";
import { noAuthlist } from "../api/Apis";
// import customStyle from "./style.css";
export default class Mortgage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableMortgage: false,
      homePrice: 0,
      down_payment_percentage: 10,
      mortgage_interest: 0,
      mortgageDefault:false,
    };
  }

  componentDidMount = async () => {
    if (this.props & this.props.homePrice) {
      this.setState({
        homePrice: parseInt(this.props.homePrice),
      });
    }
    let mortgage_details = await noAuthlist("mortgageCalDefault");
    if (mortgage_details.success) {
      this.setState({
        down_payment_percentage:
          mortgage_details.data[0]["down_payment_percentage"],
        mortgage_interest: mortgage_details.data[0]["mortgage_interest"],
        mortgageDefault:true
      });
    } else {
      this.setState({
        mortgage_interest: 3.25,
        mortgageDefault:true
      });
    }
  };

  render() {
    const { enableMortgage, mortgage_interest, down_payment_percentage,mortgageDefault } =
      this.state;
    const { cityName, postalCode, stateName } = this.props;
    return (
      <div>
        <Button
          type="primary"
          className="createButton"
          icon={<CalculatorOutlined />}
          onClick={() => {
            this.setState({
              enableMortgage: true,
            });
          }}
        >
          Mortgage Calculator
        </Button>

        <Drawer
          placement="right"
          title="Mortgage Calculator"
          width={
            window.screen.width >= "768"
              ? "40%"
              : window.screen.width > "501" && window.screen.width <= "767"
              ? "80%"
              : "100%"
          }
          mask={true}
          closable={true}
          getContainer={false}
          visible={enableMortgage}
          onClose={() =>
            this.setState({
              enableMortgage: false,
            })
          }
          headerStyle={{ textAlign: "center" }}
          bodyStyle={{
            paddingBottom: 80,
            backgroundColor: "#eff2f5",
            padding: "0px",
          }}
          className="modal-black-close"
        >
          <Row>
            <Col span={24} style={{ padding: "20px" }}>
              {this.props && this.props.homePrice && (
                <Advertisement
                  otherAds={false}
                  adType="Rectangle"
                  adWhereToShow="MortgageCalculator"
                  adLimit={1}
                  cityName={cityName}
                  postalCode={postalCode}
                  stateName={stateName}
                  detailsPageAds={true}
                />
              )}
            </Col>
            <Col span={24} style={{ padding: "20px" }}>
              {this.props && this.props.homePrice && mortgageDefault &&  (
                <MortgageCalculator
                  // styles={customStyle}
                  price={this.props.homePrice}
                  showPaymentSchedule
                  mortgageInsuranceEnabled={false}
                  interestRate={+mortgage_interest / 100}
                  downPayment={+this.props.homePrice / +down_payment_percentage}
                  // taxRate={0.02}
                />
              )}
            </Col>
          </Row>
        </Drawer>
      </div>
    );
  }
}
