import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Card } from "antd";
import { withAppContext } from "../../../store/contextStore";
import { withRouter } from "react-router-dom";

class LeadSource extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pieChartValue: "",
      options: {
        chart: {
          width: 400,
          type: "pie",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // !this.props.prospectiveBSRLead &&
              this.props.handleLeadPie(
                config.w.config.labels[config.dataPointIndex]
              );
            },
          },
        },

        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      loading: true,
      totalLeads: 0,
      singleLeads: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      let lead = [];
      props.leads.map((d) =>
        props.prospectiveBSRLead
          ? d.listingStatus && lead.push(d.listingStatus)
          : d.sourceLead && lead.push(d.sourceLead)
      );
      let labelData = lead.filter((c, index) => {
        return lead.indexOf(c) === index;
      });

      const counts = {};
      lead.forEach((x) => {
        counts[x] = (counts[x] || 0) + 1;
      });
      return {
        options: {
          labels: labelData,
          responsive: state.options.responsive,
          chart: state.options.chart,
        },
        loading: false,
        totalLeads: props.leads.length,
        singleLeads: counts,
      };
    }
    return null; // No change to state
  }

  render() {
    const { options, singleLeads, totalLeads } = this.state;
    let countData = [];
    options.labels.forEach((x) =>
      [singleLeads].map((y) =>
        countData.push({
          leadType: x,
          value: (y[x] / totalLeads) * 100,
        })
      )
    );

    const pieChart = (
      <div id="chart">
        <Chart
          options={options}
          sourceLead
          series={countData.map((a) => parseInt(a.value))}
          type="pie"
          width={500}
          style={{ marginLeft: "-20px", marginBottom: "1vw" }}
        />
      </div>
    );
    return (
      <div style={{ padding: "24px 24px 0px 0px" }}>
        {this.props.prospectiveBSRLead ? (
          pieChart
        ) : (
          <Card
            title="Lead Source"
            className="card_virtual"
            hoverable={true}
            bodyStyle={{ padding: "0px" }}
          >
            {pieChart}
          </Card>
        )}
      </div>
    );
  }
}
export default withAppContext(withRouter(LeadSource));
