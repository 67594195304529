import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Tabs,
  Modal,
  Form,
  Input,
  Select,
  Upload,
  Collapse,
  message,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { withRouter } from "react-router-dom";

// import {
//   ModalDesc,
//   ModalData,
// } from "../../../StyledComponents/StyledComponents.js";
import { getDetails, list, update } from "../../../api/Apis";
import moment from "moment";
import Loading from "../../../common/Loading.js";
import ViewcontactStats from "./ViewcontactStats.js";
import AddContact from "./AddContact";
import EmailPopupCom from "../EmailPopupCom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { ModalTitle } from "../../../StyledComponents/StyledComponents";
import NumberFormat from "react-number-format";
import { Storage } from "aws-amplify";
import DeleteRecord from "../../../common/DeleteRecord";
import BuyersListing from "../../../common/BuyersListing";
import { withAppContext } from "../../../store/contextStore";
import AddBuyerprofile from "../../../containers/Modules/AddBuyerprofile";
const { Panel } = Collapse;

const { TabPane } = Tabs;
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const { TextArea } = Input;

function ViewAllContact(props) {
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  const [proConatct, setProConatct] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pictureCard, setPictureCard] = useState("");
  const [city, setCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [addListingAlert, setAddListingAlert] = useState(false);
  const [visitedPropertiesList, setVisitedPropertiesList] = useState([]);
  const [editListingId, setEditListingId] = useState({
    id: "",
    editMode: false,
  });

  const getUserProfile = useCallback(() => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    setLoading(true);
    getDetails("virtualContactLeads", props.match.params.id)
      .then((result) => {
        if (result.success) {
          setProConatct(result.data);
          setLoading(false);

          list("propertyStatastics", {
            contactId: props.match.params.id,
            orgId,
          }).then((res) => {
            if (res.success) {
              let visitedPropertiesList = res.data.filter(
                (pro) => pro.userId === ""
              );
              setVisitedPropertiesList(visitedPropertiesList);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [props.match.params.id]);

  useEffect(() => {
    getUserProfile();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [getUserProfile]);

  const handleBack = () => {
    props.history.goBack();
  };

  const handleCancel = () => {
    setShowModal(false);
    setProConatct("");
    setEditModal(false);
    setLoading(false);
    getUserProfile();
  };

  const handleSelect = (address) => {
    getFormattedAddress(address);
  };

  const getFormattedAddress = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    setCitySearch(citySearch);
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        setCity(cityValue);
        setAddressState(stateValue);
        setZipcode(zipCodeValue);
      })
      .catch((error) => console.error("Error", error));
  };

  const getRecordDetails = () => {
    let id = props.match.params.id;

    setLoading(true);
    getDetails("virtualContactLeads", id)
      .then((result) => {
        if (result.success) {
          setProConatct(result.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const openModal = (showModal, proConatct) => (
    <Modal
      className="modal-black-close"
      title="Add New Professional Contact"
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="form"
          loading={loading}
        >
          Save
        </Button>,
      ]}
      width={
        window.screen.width >= "768"
          ? "40%"
          : window.screen.width > "501" && window.screen.width <= "767"
          ? "50%"
          : "90%"
      }
    >
      <Form
        id="form"
        onFinish={onFinish}
        initialValues={{ remember: true }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{
          padding: window.screen.width > 996 ? "10px" : "0px",
        }}
      >
        <Row style={{ marginTop: "1vw" }}>
          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>First Name</ModalTitle>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input firstName",
                    },
                  ]}
                  initialValue={
                    proConatct.firstName ? proConatct.firstName : ""
                  }
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="First Name" className="height_form" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Last Name</ModalTitle>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input lastName",
                    },
                  ]}
                  style={{ marginBottom: "1vw" }}
                  initialValue={proConatct.lastName ? proConatct.lastName : ""}
                >
                  <Input placeholder="Last Name" className="height_form" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Email</ModalTitle>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid Corporate Email",
                    },
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                  initialValue={proConatct?.email}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Email" className="height_form" />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Phone</ModalTitle>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input phone",
                    },
                  ]}
                  initialValue={proConatct?.phone}
                  style={{ marginBottom: "1vw" }}
                >
                  <NumberFormat
                    className="ant-input-number height_form"
                    placeholder="Phone"
                    format="(###) ###-####"
                    style={{
                      width: "100%",
                      padding: "4px 11px",
                      height: "40px",
                      fontSize: "14px",
                    }}
                  />
                  {/* <Input placeholder="Phone" className="height_form" /> */}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Company Name</ModalTitle>
                <Form.Item
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Please input companyName",
                    },
                  ]}
                  initialValue={proConatct?.companyName}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Company Name" className="height_form" />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Service Category</ModalTitle>
                <div className="loads-select">
                  <Form.Item
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please input Category",
                      },
                    ]}
                    initialValue={
                      proConatct.serviceCategory
                        ? proConatct.serviceCategory
                        : ""
                    }
                    style={{ marginBottom: "1vw" }}
                  >
                    <Select
                      defaultValue={"Select.."}
                      showSearch
                      options={serviceCategoryData.label}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ borderRadius: "5px " }}
                    >
                      {serviceCategoryData.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Address</ModalTitle>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: "Please input address",
                    },
                  ]}
                  initialValue={proConatct?.address}
                  style={{ marginBottom: "1vw" }}
                >
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_MAPS_KEY}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                    }}
                    selectProps={{
                      citySearch,
                      defaultInputValue: `${proConatct?.address || ""}`,
                      placeholder: "Search Address...",
                      onChange: (address) => {
                        handleSelect(address);
                      },
                    }}
                  />
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>City</ModalTitle>
                <Input
                  placeholder="City"
                  className="height_form"
                  value={city || proConatct?.city}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>State</ModalTitle>
                <Input
                  placeholder="State"
                  className="height_form"
                  value={addressState || proConatct?.addressState}
                />
              </Col>
              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Zipcode</ModalTitle>
                <Input
                  placeholder="Zip code"
                  className="height_form"
                  value={zipcode || proConatct?.zipcode}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24} style={{ marginTop: "1vw" }}>
            <Row>
              <Col
                xl={{ span: 11, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Business Card</ModalTitle>

                <Form.Item
                  name="picture"
                  rules={[
                    {
                      required: false,
                      message: "Please input your image!",
                    },
                  ]}
                  initialValue={
                    proConatct.businessCard ? proConatct.businessCard : ""
                  }
                >
                  <Upload
                    customRequest={dummyRequest}
                    onChange={(e) => handleUploadPic(e)}
                    maxCount={1}
                    listType="picture"
                  >
                    {pictureCard ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${pictureCard}`}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : proConatct.businessCard ? (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${proConatct.businessCard}`}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                    ) : (
                      <Button icon={<UploadOutlined />}>Upload Card</Button>
                    )}
                  </Upload>
                </Form.Item>
              </Col>

              <Col
                xl={{ span: 11, offset: 2 }}
                lg={{ span: 11, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <ModalTitle>Website</ModalTitle>
                <Form.Item
                  name="website"
                  rules={[
                    {
                      required: false,
                      message: "Please input website",
                    },
                  ]}
                  initialValue={proConatct?.website}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Website" className="height_form" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <ModalTitle>Notes</ModalTitle>
            <Form.Item
              name="notes"
              rules={[
                {
                  required: false,
                  message: "Please input notes",
                },
              ]}
              initialValue={proConatct?.notes}
              style={{ marginBottom: "1vw" }}
            >
              <Input placeholder="Notes" className="height_form" />
            </Form.Item>
          </Col>

          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <ModalTitle>Testimonials</ModalTitle>
            <Form.Item
              name="testimonials"
              rules={[
                {
                  required: false,
                  message: "Please input testimonials",
                },
              ]}
              initialValue={proConatct?.testimonials}
              style={{ marginBottom: "1vw" }}
            >
              <TextArea
                style={{ borderRadius: "5px " }}
                placeholder="Start Typing.."
                rows={3}
                name="message"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );

  const onFinish = (values) => {
    setLoading(true);
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let id = userDetails && userDetails?.id;
    let data = {
      entityData: {
        userId: id,
        orgId,
        isActive: true,
        contactType: "Professional",
        businessCard: pictureCard ? pictureCard : proConatct?.businessCard,
        firstName: values["firstName"],
        lastName: values["lastName"],
        email: values["email"],
        phone: values["phone"],
        companyName: values["companyName"],
        serviceCategory: values["category"],
        notes: values["notes"],
        address: citySearch || proConatct?.address,
        city: city || proConatct?.city,
        addressState: addressState || proConatct?.addressState,
        zipcode: zipcode || proConatct?.zipcode,
        website: values["website"],
        testimonials: values["testimonials"],
      },
    };
    update("virtualContactLeads", proConatct.id, data)
      .then((result) => {
        if (result.success) {
          message.success("Updated successfully !");
          setLoading(false);
          setShowModal(false);
          setEditModal(false);
          getUserProfile();
          setCity("");
          setAddressState("");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setCity("");
        setAddressState("");
      });
  };

  const serviceCategoryData = [
    { label: "All", value: "all" },
    { label: "Mortgage Lendor", value: "Mortgage Lendor" },
    { label: "Private Lendor", value: "Private Lendor" },
    { label: "Attorney", value: "Attorney" },
    { label: "Home Inspector", value: "Home Inspector" },
    { label: "Insurance", value: "Insurance" },
    { label: "Contractor+", value: "Contractor+" },
    { label: "Plumber", value: "Plumber" },
    { label: "Electrician", value: "Electrician" },
    { label: "Landscaper", value: "Landscaper" },
    { label: "Junk Removal", value: "Junk Removal" },
    { label: "Mold Remediation", value: "Mold Remediation" },
    {
      label: "Lead Paint Testing/ Remediation",
      value: "Lead Paint Testing/ Remediation",
    },
    { label: "Concrete/ Crack Repair", value: "Concrete/ Crack Repair" },
    { label: "Other", value: "Other" },
  ];

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleUploadPic = async (info) => {
    setLoading(true);
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                setPictureCard(result.key);
                setLoading(false);
              });
            } else {
              setLoading(false);
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  const handleToCancleListingAlert = () => {
    setAddListingAlert(false);
    setEditListingId({ id: "", editMode: false });
  };
  const handleToSetListingAlert = (id) => {
    setAddListingAlert(true);
    setEditListingId({ id: id, editMode: true });
  };

  const showPropertyStats = (property) => {
    return (
      <div>
        <Row style={{ display: "flex" }}>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              marginLeft: "40px",
            }}
          >
            Property Link :{" "}
            <a
              href={property.propertyLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {property.propertyLink}
            </a>
          </Col>
          {/* <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Views :
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              {" "}
              &nbsp; {property.viewCount}
            </p>
          </Col> */}
          {/* <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            total duration spent :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
              {property.totalTimeSpentOnProperty.Hours} Hours{" "}
              {property.totalTimeSpentOnProperty.Min} min{" "}
            </p>
          </Col> */}
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Last visited :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
              {moment.unix(property.lastVisited).format("lll")}
            </p>
          </Col>
          {/* <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Question asked :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
              {property.questionAsked}
            </p>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Sheduled Time :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
              {property.setSheduledTime}
            </p>
          </Col> */}
        </Row>
      </div>
    );
  };

  let loginUser = JSON.parse(localStorage.getItem("userDetails"));
  let backpageUrl = props.location?.state ? props.location.state : "";
  return (
    <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
      <Loading enableLoading={loading} />

      <Row gutter={window.screen.width > 996 && [24, 24]}>
        {/* <Row style={{ marginBottom: "10px", marginTop: "1vw" }}> */}
        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            className="font-family-style font-medium-size"
            onClick={handleBack}
          >
            Back to{" "}
            {backpageUrl === "contacts"
              ? "Contacts"
              : backpageUrl === "mypipeLine"
              ? "My pipeline"
              : backpageUrl === "dashboard"
              ? "Dashboard"
              : backpageUrl === "profissional" && "Profissional contacts"}
          </Button>
        </Col>
      </Row>

      <Row gutter={window.screen.width > 996 && [24, 24]}>
        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <Card
            className="card_virtual"
            hoverable={true}
            bodyStyle={{
              padding: "0px",
            }}
          >
            <Tabs
              defaultActiveKey={1}
              type="card"
              tabBarExtraContent={
                <Row>
                  {parseInt(activeKey) === 3 && (
                    <Col style={{ margin: "5px" }}>
                      <Button
                        icon={<PlusOutlined style={{ color: "#3045d2" }} />}
                        onClick={() => {
                          setAddListingAlert(true);
                          setEditListingId({ editMode: false, id: "" });
                        }}
                        className="todo_button"
                        style={{
                          float: "right",
                          cursor: "pointer",
                          border: "1px solid #3045d2",
                        }}
                      >
                        <span className="virtual_button_text btn-color">
                          Listing Alert
                        </span>
                      </Button>
                    </Col>
                  )}
                  <Col style={{ margin: "5px" }}>
                    <EmailPopupCom selectedContacts={[proConatct]} />
                  </Col>
                  <Col style={{ margin: "5px", top: "10px" }}>
                    <DeleteRecord
                      recordId={proConatct.id}
                      apiPath={"virtualContactLeads"}
                      getHomesList={handleBack}
                      fontSizeProp={"18px"}
                      deletionType={"DeleteOutlined"}
                      deletionSuccessMessage={"Record Deleted"}
                    />
                  </Col>
                  {parseInt(activeKey) === 1 && (
                    <EditOutlined
                      style={{
                        fontSize: "24px",
                        marginRight: "10px",
                        marginLeft: "10px",
                        color: "#1890ff",
                        marginTop: "12px",
                      }}
                      onClick={() => {
                        setEditModal(true);
                        setShowModal(true);
                      }}
                    />
                  )}
                </Row>
              }
              onChange={(key) => {
                setActiveKey(key);
                setAddListingAlert(false);
              }}
            >
              <TabPane key={1} tab="Details">
                {backpageUrl !== "profissional" ? (
                  <>
                    <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
                      <Col span={24} style={{ padding: "24px" }}>
                        <Card
                          // className="mob-card"
                          className="ant-card-hoverable home-card-space-bw mob-card"
                          style={{ borderRadius: "5px" }}
                          bordered={false}
                        >
                          <Row justify="space-between">
                            <Col span={6}>
                              <h4 style={{ color: "#aaa" }}>NAME :</h4>
                              <h3 style={{ textTransform: "capitalize" }}>
                                {proConatct?.firstName +
                                  " " +
                                  proConatct.lastName}
                              </h3>
                              <h4 style={{ color: "#aaa" }}>STATUS :</h4>
                              <h3>
                                {proConatct?.listingStatus
                                  ? proConatct?.listingStatus ===
                                    "Prospective seller or buyer"
                                    ? "Prospective Seller and Buyer"
                                    : proConatct?.listingStatus
                                  : "-"}
                              </h3>
                              {/* <h4 style={{ color: "#aaa" }}>OPEN DATE :</h4>
                              <h3>{moment().format("MM-DD-YYYY H:M a")}</h3> */}
                            </Col>

                            <Col span={6}>
                              <h4 style={{ color: "#aaa" }}>E-MAIL ID :</h4>
                              <h3>
                                {proConatct?.emailAddress
                                  ? proConatct.emailAddress
                                  : ""}
                              </h3>
                              <h4 style={{ color: "#aaa" }}>
                                Secondary email :
                              </h4>
                              <h3>
                                {proConatct?.secondaryEmail
                                  ? proConatct.secondaryEmail
                                  : "-"}
                              </h3>
                            </Col>
                            <Col span={6}>
                              <h4 style={{ color: "#aaa" }}>PHONE # :</h4>
                              <h3>
                                <NumberFormat
                                  value={proConatct.cellPhone}
                                  displayType={"text"}
                                  format="(###) ###-####"
                                />
                              </h3>

                              <h4 style={{ color: "#aaa" }}>
                                Secondary client phone :
                              </h4>
                              <h3>
                                {proConatct?.secondaryPhone ? (
                                  <NumberFormat
                                    value={
                                      proConatct?.secondaryPhone &&
                                      proConatct.secondaryPhone
                                    }
                                    displayType={"text"}
                                    format="(###) ###-####"
                                  />
                                ) : (
                                  "-"
                                )}
                              </h3>
                            </Col>
                            <Col span={6}>
                              <h4 style={{ color: "#aaa" }}>ASSIGNED AGENT:</h4>
                              <h3>
                                {" "}
                                {proConatct?.assignAgent?.length > 0 &&
                                  proConatct?.assignAgent[0]["firstName"] +
                                    " " +
                                    proConatct?.assignAgent[0]["lastName"]}
                              </h3>
                              <h4 style={{ color: "#aaa" }}>
                                Source of lead :
                              </h4>
                              <h3>{proConatct.sourceLead}</h3>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
                      <Col span={24} style={{ padding: "24px" }}>
                        <Card
                          // className="mob-card"
                          className="ant-card-hoverable home-card-space-bw mob-card"
                          bordered={false}
                        >
                          <Row justify="space-between">
                            <Col span={5}>
                              <h4 style={{ color: "#aaa" }}>
                                Address(for all sellers & buyers once UAG) :
                              </h4>
                              <h3 style={{ textTransform: "capitalize" }}>
                                {proConatct.address
                                  ? proConatct["address"]["citySearch"]
                                  : "-"}
                              </h3>

                              <h4 style={{ color: "#aaa" }}>
                                Adjusted Income :
                              </h4>
                              <h3>
                                {proConatct &&
                                "listPrice" in proConatct === true &&
                                "buyPrice" in proConatct === true ? (
                                  <NumberFormat
                                    value={parseFloat(
                                      proConatct.buyPrice + proConatct.listPrice
                                    ).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                ) : (
                                  "-"
                                )}
                              </h3>
                              {/* <h4 style={{ color: "#aaa" }}>
                                Type of Client :
                              </h4>
                              <h3>
                                {proConatct?.property
                                  ? proConatct.property
                                  : "-"}
                              </h3> */}
                            </Col>
                            <Col span={5}>
                              <h4 style={{ color: "#aaa" }}>
                                List Side Price point or UAG :
                              </h4>
                              <h3>
                                {proConatct.listsidepricepoint ? (
                                  <>
                                    <NumberFormat
                                      value={proConatct.listsidepricepoint}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </>
                                ) : (
                                  "-"
                                )}
                              </h3>
                              <h4 style={{ color: "#aaa" }}>
                                Buy Side Price point or UAG :
                              </h4>
                              <h3>
                                {proConatct.buysidepricepoint ? (
                                  <>
                                    <NumberFormat
                                      value={proConatct.buysidepricepoint}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </>
                                ) : (
                                  "-"
                                )}
                              </h3>
                            </Col>
                            <Col span={5}>
                              <h4 style={{ color: "#aaa" }}>
                                Seller Side Commission :
                              </h4>
                              <h3 style={{ textTransform: "capitalize" }}>
                                {proConatct.sellerSideCommission
                                  ? `${proConatct.sellerSideCommission}%`
                                  : "-"}
                              </h3>

                              <h4 style={{ color: "#aaa" }}>
                                Buyer Side Commission :
                              </h4>
                              <h3>
                                {proConatct.buyerSideCommission
                                  ? `${proConatct.buyerSideCommission} %`
                                  : "-"}
                              </h3>
                            </Col>
                            <Col span={5}>
                              <h4 style={{ color: "#aaa" }}>
                                Seller Side Referral Fee/TM Fee :
                              </h4>
                              <h3>
                                {proConatct?.sellerSideReferralFee
                                  ? `${proConatct.sellerSideReferralFee}%`
                                  : "-"}
                              </h3>
                              <h4 style={{ color: "#aaa" }}>
                                Buyer Side Referral Fee/TM Fee :
                              </h4>
                              <h3>
                                {proConatct.buyerSideReferralFee
                                  ? `${proConatct.buyerSideReferralFee}%`
                                  : "-"}
                              </h3>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
                      <Col span={24} style={{ padding: "24px" }}>
                        <Card
                          // className="mob-card"
                          className="ant-card-hoverable home-card-space-bw mob-card"
                          bordered={false}
                        >
                          <Row justify="space-between">
                            <Col span={8}>
                              <h4 style={{ color: "#aaa" }}>
                                Notes on lead(public)
                              </h4>
                              <h3>
                                {proConatct?.notes ? proConatct.notes : "-"}
                              </h3>
                            </Col>
                            <Col span={8}>
                              {(loginUser?.id === proConatct.userId ||
                                proConatct?.agentDetails?.agent_id ===
                                  loginUser?.id) && (
                                <>
                                  <h4 style={{ color: "#aaa" }}>
                                    Private Notes :
                                  </h4>
                                  <h3>{proConatct.privateNotes}</h3>
                                </>
                              )}
                            </Col>
                            <Col span={8}>
                              {proConatct &&
                                "favoriteCity" in proConatct === true && (
                                  <h4 style={{ color: "#aaa" }}>
                                    Favorites Cities :
                                  </h4>
                                )}
                              <div>
                                <h3
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {proConatct &&
                                    "favoriteCity" in proConatct === true &&
                                    proConatct.favoriteCity.length > 0 &&
                                    proConatct.favoriteCity.map(
                                      (city, index) => (
                                        <>
                                          {city.cityName}, {city.stateName}
                                          {proConatct.favoriteCity.length ===
                                          index + 1
                                            ? " "
                                            : ", "}
                                          {typeof city !== "object" ? city : ""}
                                        </>
                                      )
                                    )}
                                </h3>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    {visitedPropertiesList.length > 0 && (
                      <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
                        <Col span={24}>
                          <Card
                            // className="mob-card"
                            className="ant-card-hoverable home-card-space-bw mob-card"
                            bordered={false}
                          >
                            <Row style={{ display: "block" }}>
                              <h3 style={{ textTransform: "capitalize" }}>
                                Properties visited
                              </h3>
                              {visitedPropertiesList.length > 0 ? (
                                visitedPropertiesList.map(
                                  (eachStats, index) => (
                                    <>
                                      <Collapse
                                        defaultActiveKey={"1"}
                                        // onChange={this.handleCollapseActive}
                                        key={eachStats.key}
                                      >
                                        <Panel
                                          header={eachStats.propertyTitle}
                                          key={eachStats.key}
                                        >
                                          {showPropertyStats(eachStats)}
                                        </Panel>
                                      </Collapse>
                                    </>
                                  )
                                )
                              ) : (
                                <>
                                  <h4>NO RECORD FOUND</h4>
                                </>
                              )}
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </>
                ) : (
                  <>
                    <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
                      <Col span={24} style={{ padding: "24px" }}>
                        <Card
                          // className="mob-card"
                          className="ant-card-hoverable home-card-space-bw"
                        >
                          <Row justify="space-between">
                            <Col span={5}>
                              <h4 style={{ color: "#aaa" }}>NAME :</h4>
                              <h3 style={{ textTransform: "capitalize" }}>
                                {proConatct?.firstName +
                                  " " +
                                  proConatct.lastName}
                              </h3>

                              <h4 style={{ color: "#aaa" }}>OPEN DATE :</h4>
                              <h3>
                                {moment(proConatct.createdAt).format(
                                  "MM-DD-YYYY H:M a"
                                )}
                              </h3>
                            </Col>

                            <Col span={5}>
                              <h4 style={{ color: "#aaa" }}>PHONE # :</h4>
                              <h3>{proConatct.phone}</h3>
                              <h4 style={{ color: "#aaa" }}>E-MAIL ID :</h4>
                              <h3>
                                {proConatct?.email ? proConatct.email : ""}
                              </h3>
                            </Col>
                            <Col span={5}>
                              <h4 style={{ color: "#aaa" }}>
                                SERVICE CATEGORY :
                              </h4>
                              <h3>
                                {proConatct?.serviceCategory
                                  ? proConatct?.serviceCategory
                                  : "-"}
                              </h3>
                              <h4 style={{ color: "#aaa" }}>COMPANY NAME</h4>
                              <h3>
                                {proConatct?.companyName
                                  ? proConatct?.companyName
                                  : "-"}
                              </h3>
                            </Col>
                            <Col span={5}>
                              <h4 style={{ color: "#aaa" }}>Address :</h4>
                              <h3 style={{ textTransform: "capitalize" }}>
                                {proConatct.address
                                  ? proConatct["address"][0]
                                  : "-"}
                              </h3>
                              <h4 style={{ color: "#aaa" }}>NOTES :</h4>
                              <h3>
                                {proConatct?.notes ? proConatct.notes : "-"}
                              </h3>
                            </Col>

                            <Col span={4}>
                              <h4 style={{ color: "#aaa" }}>TESTIMONIALS :</h4>
                              <h3>
                                {proConatct.testimonials
                                  ? `${proConatct.testimonials}`
                                  : "-"}
                              </h3>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}
              </TabPane>
              <TabPane key={2} tab="Stats">
                <div style={{ backgroundColor: "#FFF" }}>
                  <ViewcontactStats
                    backpageUrl={backpageUrl}
                    joinDate={proConatct.registered}
                  />
                </div>
              </TabPane>
              {backpageUrl !== "profissional" && (
                <TabPane key={3} tab="Listing Alerts">
                  <div style={{ backgroundColor: "#FFF" }}>
                    {/* <ViewcontactStats backpageUrl={backpageUrl} /> */}
                    {addListingAlert ? (
                      <AddBuyerprofile
                        handleToCancle={handleToCancleListingAlert}
                        editListingId={editListingId.id}
                        contactAgentInfo={proConatct}
                        editMode={editListingId.editMode}
                      />
                    ) : (
                      <Row gutter={[16, 16]}>
                        <Col span={24} style={{ padding: "0px" }}>
                          <Card
                            className="home-card-space-bw"
                            style={{ borderRadius: "5px" }}
                            bordered={false}
                          >
                            <BuyersListing
                              handleToEditListing={handleToSetListingAlert}
                            />
                          </Card>
                        </Col>
                      </Row>
                      // "Show listing "
                    )}
                  </div>
                </TabPane>
              )}
            </Tabs>
          </Card>
        </Col>
      </Row>

      {editModal && backpageUrl === "profissional"
        ? openModal(showModal, proConatct)
        : editModal &&
          backpageUrl !== "profissional" && (
            <AddContact
              showModal={showModal}
              proConatct={proConatct}
              loadig={loading}
              onCancel={handleCancel}
              editModal={editModal}
              getUserProfile={getRecordDetails}
              setLoading={setLoading}
            />
          )}
    </div>
  );
}

export default withAppContext(withRouter(ViewAllContact));
