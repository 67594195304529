import {
  Col,
  Row,
  Form,
  notification,
  Button,
  Spin,
  Select,
  Upload,
  Divider,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Storage } from "aws-amplify";
import uploadImg from "../../../images/upload.png";
import { withAppContext } from "../../../store/contextStore";
import { add } from "../../../api/Apis";
const { Dragger } = Upload;

class VendorPortFolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveDetailsFalse: false,
      showPortFolioImages: false,
      selectedCategoryList: [],
      imageLoading: false,
      fileList: [],
      imageKeys: [],
      createdPortfolioList: {},
      portFolioCategorylist: [],
      endableAddPortfolio: true,
      imageCount: 1,
      Category: [
        // { label: "Lenders", value: "Lenders" },
        { label: "Attorney Real Estate", value: "Attorney Real Estate" },
        { label: "Attorney Family Law", value: "Attorney Family Law" },
        { label: "Smoke Detector", value: "Smoke Detector" },
        { label: "Paving", value: "Paving" },
        { label: "Carpenter", value: "Carpenter" },
        { label: "Handy Person", value: "Handy Person" },
        { label: "Window Treatment", value: "Window Treatment" },
        { label: "Painter", value: "Painter" },
        { label: "Cleaner", value: "Cleaner" },
        { label: "Attorney", value: "Attorney" },
        { label: "Insurance", value: "Insurance" },
        { label: "Solar", value: "Solar" },
        { label: "Pest Control", value: "Pest Control" },
        { label: "Home Inspector", value: "Home Inspector" },
        { label: "Trash Out", value: "Trash Out" },
        { label: "Dumpsters", value: "Dumpsters" },
        { label: "Kitchen Remodel", value: "Kitchen Remodel" },
        { label: "Moving Company", value: "Moving Company" },
        { label: "Bath Remodel", value: "Bath Remodel" },
        {
          label: "Heating & Cooling (HVAC)",
          value: "Heating & Cooling (HVAC)",
        },
        { label: "Oil Companies", value: "Oil Companies" },
        { label: "Fencing", value: "Fencing" },
        { label: "Architect", value: "Architect" },
        { label: "Builder", value: "Builder" },
        {
          label: "Concrete/Foundation Repair",
          value: "Concrete/Foundation Repair",
        },
        { label: "Roofing Repair/Replace", value: "Roofing Repair/Replace" },
        { label: "Windows/Doors", value: "Windows/Doors" },
        { label: "Flooring", value: "Flooring" },
        { label: "Closet Systems", value: "Closet Systems" },
        { label: "Deck Builder", value: "Deck Builder" },
        { label: "Credit Repair", value: "Credit Repair" },
        { label: "Granite Counters", value: "Granite Counters" },
        { label: "Asbestos Removal", value: "Asbestos Removal" },
        { label: "Lead Paint Removal", value: "Lead Paint Removal" },
        { label: "Plowing", value: "Plowing" },
        { label: "Interior", value: "Interior" },
        { label: "Decorator", value: "Decorator" },
        { label: "Tree Removal", value: "Tree Removal" },
        { label: "Title V inspector", value: "Title V inspector" },
        { label: "Power Washing", value: "Power Washing" },
        { label: "Mosquito Control", value: "Mosquito Control" },
        { label: "Marketing", value: "Marketing" },
        { label: "Other", value: "Other" },
      ],
      opts: [
        { label: "New Construction", value: "New Construction" },
        { label: "FHA Loans", value: "FHA Loans" },
        { label: "Renovation Loans", value: "Renovation Loans" },
        { label: "Conventional loans", value: "Conventional loans" },
        { label: "First Time Buyers", value: "First Time Buyers" },
        { label: "Investment Loans", value: "Investment Loans" },
        { label: "1031 Exchanges", value: "1031 Exchanges" },
        { label: "Commercial Loans", value: "Commercial Loans" },
        { label: "USDA Loans", value: "USDA Loans" },
        { label: "VA Loans", value: "VA Loans" },
        { label: "Jumbo Loans", value: "Jumbo Loans" },
        { label: "Direct Lender", value: "Direct Lender" },
        { label: "Mass Housing", value: "Mass Housing" },
      ],
    };
  }

  componentDidMount() {
    this.setPreviousCategoryList();
  }
  setPreviousCategoryList = async () => {
    setTimeout(() => {
      let newCategoryListProps = [];
      for (let propsCategory of this.props.portfolioList) {
        newCategoryListProps.push(propsCategory.portfolioName);
      }
      const listOfCategories = this.state.Category.filter((removeOldCat) => {
        return !newCategoryListProps.includes(removeOldCat.value);
      });
      this.setState({
        Category: listOfCategories,
      });
    }, 3000);
  };
  onFinish = (e) => {
    this.setState({
      showPortFolioImages: false,
      fileList: [],
      selectedCategoryList: "",
    });
    this.handleToUploadPortfolio();
  };

  handlePortFolioImages = (selectedCategoryList) => {
    const newCategoryList = this.state.Category.filter(
      (removeItem) => removeItem.value !== selectedCategoryList
    );
    this.setState({
      showPortFolioImages: true,
      selectedCategoryList,
      Category: newCategoryList,
    });
  };
  openNotification = () => {
    notification["error"]({
      message:
        "You can't add more than 4 images... Please remove image to upload",
    });
  };
  uploadHomeImages = async (info, portFolio) => {
    this.setState({
      imageLoading: true,
    });
    let Images = [];
    const fileList = [];
    fileList.push(info);
    this.setState({
      fileList: info.fileList,
    });
    if (info.fileList && info.fileList.length >= 4) {
      this.openNotification();
    }
    if (info.fileList.length > 0 && info.file.status !== "removed") {
      info.fileList.map((file) => {
        try {
          Storage.put(file.uid, file.originFileObj, {
            contentType: file.type,
          }).then((result) => {
            Images.push(result.key);
            this.setState({
              imageKeys: Images,
              imageLoading: false,
              endableAddPortfolio: false,
              createdPortfolioList: {
                portfolioImages: Images,
                portfolioName: portFolio,
              },
            });
          });
        } catch (err) {
          console.log(err, "errr");
        }
        return file;
      });
    }
    if (info.file.status === "removed") {
      this.setState({
        imageLoading: false,
        imageCount: 1,
      });
    }
  };

  handleToUploadPortfolio = async () => {
    this.setState({
      saveDetailsFalse: true,
    });
    const { createdPortfolioList } = this.state;
    const { userDetails } = this.props.context.state;
    const id = userDetails.id;
    const data = {
      entityData: {
        userId: id,
        portfolioName: createdPortfolioList.portfolioName,
        portfolioImages: createdPortfolioList.portfolioImages,
        dataType: "portfolio",
        testimonialDesc: "Desc",
        postedBy: userDetails.firstName,
        rating: 1,
      },
    };
    try {
      const response = await add("testimonials", data);
      if (response.success) {
        notification["success"]({
          message: "Your portfolio has been updated",
        });
        this.setState({
          saveDetailsFalse: false,
        });
        this.props.getPortfoliosList();
        this.props.closePortfolioModal();
        //   this.resetToDefaults();
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  render() {
    const {
      saveDetailsFalse,
      showPortFolioImages,
      fileList,
      selectedCategoryList,
      endableAddPortfolio,
    } = this.state;
    const dataForLogin = JSON.parse(localStorage.getItem("userDetails"));
    // const props = {
    //   showUploadList: {
    //     showDownloadIcon: true,
    //     downloadIcon: 'download ',
    //     showRemoveIcon: true,
    //     removeIcon: <StarOutlined onClick={e =>} />,
    //   },
    // };

    return (
      <>
        <Form
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          size="large"
        >
          <Spin spinning={saveDetailsFalse}>
            <Row>
              <Col
                xl={{ span: 23, offset: 1 }}
                lg={{ span: 23, offset: 1 }}
                md={{ span: 23, offset: 1 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <div className="label-titles">
                  {dataForLogin.userProfile === "L" && "Specialties:"}
                  {dataForLogin.userProfile === "V" && "Service Category:"}
                </div>
                <Form.Item>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select..."
                    onChange={this.handlePortFolioImages}
                  >
                    {dataForLogin.userProfile === "L" &&
                      this.state.opts.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                    {dataForLogin.userProfile === "V" &&
                      this.state.Category.map((province) => (
                        <Select.Option
                          key={province.value}
                          value={province.value}
                        >
                          {province.label}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              {showPortFolioImages && (
                <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <Spin spinning={this.state.imageLoading}>
                    <h2>{selectedCategoryList}</h2>
                    <h3>Portfolio Images: (can upload upto 4 only) </h3>
                    <Form.Item>
                      <Dragger
                        name="file"
                        maxCount={4}
                        onChange={(info) =>
                          this.uploadHomeImages(info, selectedCategoryList)
                        }
                        listType="picture"
                        fileList={fileList && fileList}
                        beforeUpload={() => false}
                        disabled={
                          fileList && fileList.length >= 5 ? true : false
                        }
                        // {...props}
                      >
                        {fileList && fileList.length <= 5 && (
                          <>
                            <p className="ant-upload-drag-icon">
                              <img
                                src={uploadImg}
                                alt="upload"
                                width="50px"
                                height="33px"
                              />
                            </p>
                            <p className="ant-upload-text">Drag & Drop image</p>
                          </>
                        )}
                      </Dragger>
                    </Form.Item>
                    <Divider />
                  </Spin>
                </Col>
              )}
              <br />
              <Col
                xl={{ span: 8, offset: 1 }}
                lg={{ span: 8, offset: 1 }}
                md={{ span: 8, offset: 1 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={endableAddPortfolio}
                  className="update-btn"
                >
                  Add portfolio
                </Button>
              </Col>
            </Row>
          </Spin>
        </Form>
      </>
    );
  }
}
export default withAppContext(withRouter(VendorPortFolio));
