import React, { useState, useEffect } from "react";
import { UnorderedListOutlined, PieChartOutlined } from "@ant-design/icons";
import { Card, Table, Tag, Col, Button, Row } from "antd";
// import { list } from "../../../api/Apis";
import NumberFormat from "react-number-format";
import LeadSource from "./LeadSource";

export default function Dashboard(props) {
  const [loading, setLoading] = useState(false);
  const [activeLeads, setActiveLeads] = useState(false);
  const [selected, SetSelected] = useState(true);
  const [allBSRLeads, setAllBSRLeads] = useState([]);

  useEffect(() => {
    setAllBSRLeads([]);
    setLoading(true);
    if (props?.leads.length > 0) {
      let closeData = [];
      let listData = [];
      let underData = [];
      let buyerData = [];
      let prospectiveBuyerData = [];
      let onlyProspectiveSeller = [];
      let prospectiveSellerData = [];
      // let databaseData = [];
      // let wentData = [];

      props.leads.map((d) =>
        d.listingStatus &&
        "listPrice" in d === true &&
        "buyPrice" in d === true &&
        d.listingStatus === "Closed"
          ? closeData.push(d)
          : d.listingStatus === "Under Agreement"
          ? underData.push(d)
          : d.listingStatus === "Active Listing"
          ? listData.push(d)
          : d.listingStatus === "Active Buyer"
          ? buyerData.push(d)
          : d.listingStatus === "Prospective Buyer"
          ? prospectiveBuyerData.push(d)
          : d.listingStatus === "Prospective Seller"
          ? onlyProspectiveSeller.push(d)
          : d.listingStatus === "Prospective seller or buyer"
          ? prospectiveSellerData.push(d)
          : ""
      );

      if (props.leads.length > 0) {
        setAllBSRLeads(props.leads.filter((d) => d.listingStatus));
        setAllcolumnsData(
          closeData.filter(
            (d) => "listPrice" in d === true && "buyPrice" in d === true
          ),
          underData.filter(
            (d) => "listPrice" in d === true && "buyPrice" in d === true
          ),
          listData.filter(
            (d) => "listPrice" in d === true && "buyPrice" in d === true
          ),
          buyerData.filter(
            (d) => "listPrice" in d === true && "buyPrice" in d === true
          ),
          prospectiveBuyerData.filter(
            (d) => "listPrice" in d === true && "buyPrice" in d === true
          ),
          onlyProspectiveSeller.filter(
            (d) => "listPrice" in d === true && "buyPrice" in d === true
          ),
          prospectiveSellerData.filter(
            (d) => "listPrice" in d === true && "buyPrice" in d === true
          )
        );
        // setLoading(false);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [props.leads]);

  const handleStatusData = (record, clickType) => {
    let matchingValue = "";
    if (clickType === "row") {
      matchingValue = record.listingStatus.trim().toLowerCase();
    } else {
      matchingValue = record.trim().toLowerCase();
    }

    props.newCheck(matchingValue, "statusPage");
  };

  const setAllcolumnsData = (C, U, AL, AB, PB, pSeller, PS, WC, DB) => {
    let cListprice = C.reduce((a, v) => a + v.listPrice, 0);
    let cBuyprice = C.reduce((a, v) => a + v.buyPrice, 0);
    let uListprice = U.reduce((a, v) => a + v.listPrice, 0);
    let uBuyprice = U.reduce((a, v) => a + v.buyPrice, 0);
    let alListprice = AL.reduce((a, v) => a + v.listPrice, 0);
    let alBuyprice = AL.reduce((a, v) => a + v.buyPrice, 0);
    let abListprice = AB.reduce((a, v) => a + v.listPrice, 0);
    let abBuyprice = AB.reduce((a, v) => a + v.buyPrice, 0);
    let sellerBuyprice = pSeller.reduce((a, v) => a + v.buyPrice, 0);
    let sellerListprice = pSeller.reduce((a, v) => a + v.listPrice, 0);
    let pbListprice = PB.reduce((a, v) => a + v.listPrice, 0);
    let pbBuyprice = PB.reduce((a, v) => a + v.buyPrice, 0);
    let psListprice = PS.reduce((a, v) => a + v.listPrice, 0);
    let psBuyprice = PS.reduce((a, v) => a + v.buyPrice, 0);
    const totalColumnsData = [
      {
        listingStatus: "Closed",
        listPrice: parseFloat(cListprice + cBuyprice),
      },
      {
        listingStatus: "Under Agreement",
        listPrice: parseFloat(uListprice + uBuyprice),
      },
      {
        listingStatus: "Active Listing",
        listPrice: parseFloat(alListprice + alBuyprice),
      },
      {
        listingStatus: "Active Buyer",
        listPrice: parseFloat(abListprice + abBuyprice),
      },
      {
        listingStatus: "Prospective Buyer",
        listPrice: parseFloat(pbListprice + pbBuyprice),
      },
      {
        listingStatus: "Prospective Seller",
        listPrice: parseFloat(sellerListprice + sellerBuyprice),
      },
      {
        listingStatus: "Prospective seller or buyer",
        listPrice: parseFloat(psListprice + psBuyprice),
      },
      {
        listingStatus: "Total",
        listPrice: parseFloat(
          cListprice +
            cBuyprice +
            uListprice +
            uBuyprice +
            alListprice +
            alBuyprice +
            abListprice +
            abBuyprice +
            pbListprice +
            pbBuyprice +
            psListprice +
            psBuyprice +
            sellerListprice +
            sellerBuyprice
        ),
      },
    ];
    setActiveLeads(totalColumnsData);
  };

  const columns = [
    {
      title: "STATUS",
      key: "listingStatus",
      dataIndex: "listingStatus",
      onCell: (record) => ({
        onClick: () => handleStatusData(record, "row"),
      }),
      render: (tag) => (
        <Tag
          style={{
            border: "none",
            background:
              tag === "Closed"
                ? "red"
                : tag === "Under Agreement"
                ? "#DCF1FA"
                : tag === "Active Listing"
                ? "#E6E6F8"
                : tag === "Active Buyer"
                ? "#D5F7EF"
                : tag === "Prospective Buyer"
                ? "#FFE2E2"
                : tag === "Prospective Seller"
                ? "#E5CCFF"
                : tag === "Prospective seller or buyer"
                ? "#FFE8DE"
                : tag === "Prospective seller & buyer" && "#FFE8DE",

            color:
              tag === "Closed"
                ? "#FFF"
                : tag === "Under Agreement"
                ? "#34ACE0"
                : tag === "Active Listing"
                ? "#706FD3"
                : tag === "Active Buyer"
                ? "#33D9B2"
                : tag === "Prospective Buyer"
                ? "#FF5252"
                : tag === "Prospective Seller"
                ? "#9933FF"
                : tag === "Prospective seller or buyer"
                ? "#FF793F"
                : tag === "Prospective Seller & buyer" && "#FF793F",
          }}
          key={tag}
        >
          <span
            style={{
              fontSize: tag === "Total" ? "16px" : "13px",
              fontWeight: "bold",
              fontFamily: "Poppins",
            }}
          >
            {tag === "Prospective seller or buyer"
              ? "Prospective Seller and Buyer".toUpperCase()
              : tag?.toUpperCase()}
          </span>
        </Tag>
      ),
    },
    {
      title: "TOTAL ADJUSTED INCOME($)",
      dataIndex: "listPrice",
      key: "listPrice",
      onCell: (record) => ({
        onClick: () => handleStatusData(record, "row"),
      }),
      render: (listPrice, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
              fontWeight: "500",
              float: "right",
              fontSize: record.listingStatus === "Total" ? "18px" : "15px",
              padding: record.listingStatus === "Total" ? "5px 0 5px 0" : "0px",
            }}
          >
            <NumberFormat
              value={parseFloat(listPrice).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </span>
        );
      },
    },
  ];
  return (
    <div style={{ padding: "24px 24px 0px 0px" }}>
      <Card
        title="Prospective Business Report"
        className="card_virtual"
        hoverable={true}
        bodyStyle={{ padding: "0px", borderTop: "1px solid #ebebeb" }}
        extra={
          <Row style={{ justifyContent: "right" }}>
            <Col className="gridbtntool">
              <Button
                title="ListView"
                style={{
                  border: "none",
                  borderRadius: "0px",
                  padding: "5px 8px",
                  background: selected ? "#0e4d90" : "#eff1f3",
                  width: "50px",
                }}
                onClick={() => SetSelected(true)}
              >
                <UnorderedListOutlined
                  style={{
                    fontSize: "22px",
                    color: selected ? "#FFF" : "#0e4d90",
                  }}
                />
              </Button>
              <Button
                title="MapView"
                style={{
                  border: "none",
                  borderRadius: "0px",
                  padding: "5px 8px",
                  background: selected ? "#eff1f3" : "#0e4d90",
                  width: "50px",
                }}
                onClick={() => SetSelected(false)}
              >
                <PieChartOutlined
                  style={{
                    fontSize: "22px",
                    color: selected ? "#0e4d90" : "#FFF",
                  }}
                />
              </Button>
            </Col>
          </Row>
        }
      >
        {selected && activeLeads.length > 0 && (
          <Table
            className="prospectiveReport"
            columns={columns}
            dataSource={activeLeads}
            loading={loading}
            pagination={false}
          />
        )}
        {!selected && allBSRLeads.length > 0 && (
          <LeadSource
            loading={loading}
            leads={allBSRLeads}
            handleLeadPie={handleStatusData}
            prospectiveBSRLead
          />
        )}
      </Card>
    </div>
  );
}
