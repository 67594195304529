import React, { Component } from "react";
import { Row, Col, Spin, Card, Upload, Button, notification } from "antd";
import ImageCrop from "antd-img-crop";
import { Storage } from "aws-amplify";
import { ArrowLeftOutlined } from "@ant-design/icons";
import profileAvatar from "../../../images/profileAvatar.png";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import SocialMediaForm from "./SocialMediaForm";
import ChnagePassword from "./ChnagePassword";
import { update, getDetails } from "../../../api/Apis";
import VendorProfile from "./VendorProfile";

class VendorProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      imageLoading: false,
      image2Loading: false,
      profileImage: "",
      profileImage2: "",
      updateImage: true,
      portfoliosList: [],
    };
  }

  componentDidMount() {
    const { userDetails } = this.props.context.state;
    setTimeout(() => {
      if (userDetails && userDetails.profilePicture) {
        this.setState({
          profileImage:
            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            userDetails.profilePicture[0],
        });
      }
      if (userDetails && userDetails.profilePicture2) {
        this.setState({
          profileImage2:
            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            userDetails.profilePicture2[0],
        });
      }
    }, 3000);
  }

  savePic = async (data1, data2) => {
    const { userDetails } = this.props.context.state;
    const id = userDetails.id;

    const data = Object.assign(
      {},
      {
        entityData: {
          profilePicture: data2,
          profileUrl: data1,
        },
      }
    );
    if (data.entityData.profilePicture && data.entityData.profileUrl) {
      const updatingUserDetails = await update("users", id, data);
      if (updatingUserDetails.success) {
        this.setState({
          updateImage: false,
          imageLoading: false,
        });
        notification["success"]({
          message: "Your profile picture updated",
          // description: "Your profile picture updated successfully",
          duration: 3,
        });
      }
      const userProfileDetails = await getDetails("users", id);
      localStorage.removeItem("userDetails");
      localStorage.setItem(
        "userDetails",
        JSON.stringify(userProfileDetails.data)
      );
      this.props.context.setUserDetails(userProfileDetails.data);
    }
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  setProfileImage = () => {
    const { userDetails } = this.props.context.state;
    this.setState({
      profileImage:
        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
          userDetails &&
        userDetails.profilePicture &&
        userDetails.profilePicture[0],
    });
  };

  handleCroppedImage = async (crop) => {
    this.setState({
      imageLoading: true,
    });
    const Images = [];
    let image = "";
    Storage.put(crop.uid, crop, {
      contentType: crop.type,
    }).then(async (result) => {
      Images.push(result.key);
      image = await Storage.get(result.key);
      if (image && Images) {
        this.setState({
          profileImage: image,
        });
        this.savePic(image, Images);
      }
    });
  };

  handleCroppedImage2 = async (crop) => {
    this.setState({
      image2Loading: true,
    });
    const Images = [];
    let image = "";
    Storage.put(crop.uid, crop, {
      contentType: crop.type,
    }).then(async (result) => {
      Images.push(result.key);
      image = await Storage.get(result.key);
      if (image && Images) {
        this.setState({
          profileImage2: image,
        });
        this.savePic2(image, Images);
      }
    });
  };

  savePic2 = async (data1, data2) => {
    const { userDetails } = this.props.context.state;
    const id = userDetails.id;

    const data = Object.assign(
      {},
      {
        entityData: {
          profilePicture2: data2,
          profileUrl2: data1,
        },
      }
    );
    if (data.entityData.profilePicture2 && data.entityData.profileUrl2) {
      const updatingUserDetails = await update("users", id, data);
      if (updatingUserDetails.success) {
        this.setState({
          updateImage: false,
          image2Loading: false,
        });
        notification["success"]({
          message: "Your seller/buyer profile picture updated",
          // description: "Your profile picture updated successfully",
          duration: 3,
        });
      }
      const userProfileDetails = await getDetails("users", id);
      localStorage.removeItem("userDetails");
      localStorage.setItem(
        "userDetails",
        JSON.stringify(userProfileDetails.data)
      );
      this.props.context.setUserDetails(userProfileDetails.data);
    }
  };

  render() {
    const {
      fileList,
      profileImage,
      profileImage2,
      imageLoading,
      image2Loading,
    } = this.state;
    const { userDetails } = this.props.context.state;
    return (
      <div>
        <Row>
          <Col span={23} offset={1} style={{ marginTop: "1vw" }}>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              className="font-family-style font-medium-size"
              onClick={this.handleBack}
            >
              Return to profile page
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={22} offset={1}>
            <Card bodyStyle={{ padding: "0px" }}>
              <Row className="card-body-style">
                <Col
                  xl={{ span: 4, offset: 1 }}
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 4, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                >
                  {userDetails && userDetails.userProfile !== "V" ? (
                    <Spin
                      spinning={imageLoading}
                      size="large"
                      className="center-item"
                      style={{ marginTop: "10%" }}
                    >
                      <img
                        src={profileImage !== "" ? profileImage : profileAvatar}
                        alt="profile"
                        className="img-profile"
                        style={{ width: "100%", height: "100%" }}
                      />
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="update-profile-btn"
                        style={{
                          width: "100%",
                        }}
                      >
                        <ImageCrop
                          rotate={true}
                          onModalOk={this.handleCroppedImage}
                          modalOk="Save"
                        >
                          <Upload showUploadList={false}>
                            <span className="Btn-name">
                              Update Profile Picture
                            </span>
                          </Upload>
                        </ImageCrop>
                      </Button>
                    </Spin>
                  ) : (
                    <>
                      <h4>Logo :</h4>
                      <Spin
                        spinning={imageLoading}
                        size="large"
                        className="center-item"
                        style={{ marginTop: "10%" }}
                      >
                        <img
                          src={
                            profileImage !== "" ? profileImage : profileAvatar
                          }
                          alt="profile"
                          className="img-profile"
                          style={{ width: "100%", height: "100%" }}
                        />
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="update-profile-btn"
                          style={{
                            width: "100%",
                          }}
                        >
                          <ImageCrop
                            rotate={true}
                            onModalOk={this.handleCroppedImage}
                            modalOk="Save"
                          >
                            <Upload showUploadList={false}>
                              <span className="Btn-name">Update logo</span>
                            </Upload>
                          </ImageCrop>
                        </Button>
                      </Spin>

                      <h4 style={{ marginTop: "20px" }}>
                        Seller/Buyer Image :
                      </h4>
                      <Spin
                        spinning={image2Loading}
                        size="large"
                        className="center-item"
                        style={{ marginTop: "10%" }}
                      >
                        <img
                          src={
                            profileImage2 !== "" ? profileImage2 : profileAvatar
                          }
                          alt="profile"
                          className="img-profile"
                          style={{ width: "100%", height: "100%" }}
                        />
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="update-profile-btn"
                          style={{
                            width: "100%",
                          }}
                        >
                          <ImageCrop
                            rotate={true}
                            onModalOk={this.handleCroppedImage2}
                            modalOk="Save"
                          >
                            <Upload showUploadList={false}>
                              <span className="Btn-name">Update Image</span>
                            </Upload>
                          </ImageCrop>
                        </Button>
                      </Spin>
                    </>
                  )}
                </Col>
                <Col
                  xl={{ span: 17, offset: 1 }}
                  lg={{ span: 17, offset: 1 }}
                  md={{ span: 17, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                >
                  <VendorProfile
                    fileList={fileList}
                    profileImage={profileImage}
                    userDetails={userDetails}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {(userDetails && userDetails.userProfile === "B") ||
        (userDetails && userDetails.userProfile === "S")
          ? window.location.pathname ===
              `/vendorEditProfile/${userDetails.id}` && (
              <Row style={{ marginTop: "2vw" }}>
                <Col span={22} offset={1}>
                  <Card bodyStyle={{ padding: "0px" }}>
                    <Row className="card-body-style">
                      <Col
                        xl={{ span: 4, offset: 1 }}
                        lg={{ span: 4, offset: 1 }}
                        md={{ span: 4, offset: 1 }}
                        sm={{ span: 22, offset: 1 }}
                        xs={{ span: 22, offset: 1 }}
                        className="profile-titles"
                      >
                        <span style={{ fontSize: "20px", fontWeight: 600 }}>
                          Social Media
                        </span>
                      </Col>
                      <Col
                        xl={{ span: 17, offset: 1 }}
                        lg={{ span: 17, offset: 1 }}
                        md={{ span: 17, offset: 1 }}
                        sm={{ span: 22, offset: 1 }}
                        xs={{ span: 22, offset: 1 }}
                      >
                        <SocialMediaForm userDetails={userDetails} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )
          : ((userDetails && userDetails.userProfile === "V") ||
              (userDetails && userDetails.userProfile === "AG")) && (
              <Row style={{ marginTop: "2vw" }}>
                <Col span={22} offset={1}>
                  <Card bodyStyle={{ padding: "0px" }}>
                    <Row className="card-body-style">
                      <Col
                        xl={{ span: 4, offset: 1 }}
                        lg={{ span: 4, offset: 1 }}
                        md={{ span: 4, offset: 1 }}
                        sm={{ span: 22, offset: 1 }}
                        xs={{ span: 22, offset: 1 }}
                        className="profile-titles"
                      >
                        <span style={{ fontSize: "20px", fontWeight: 600 }}>
                          Social Media
                        </span>
                      </Col>
                      <Col
                        xl={{ span: 17, offset: 1 }}
                        lg={{ span: 17, offset: 1 }}
                        md={{ span: 17, offset: 1 }}
                        sm={{ span: 22, offset: 1 }}
                        xs={{ span: 22, offset: 1 }}
                      >
                        <SocialMediaForm userDetails={userDetails} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}

        <Row style={{ marginTop: "2vw" }}>
          <Col span={22} offset={1}>
            <Card bodyStyle={{ padding: "0px" }}>
              <Row className="card-body-style">
                <Col
                  xl={{ span: 4, offset: 1 }}
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 4, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  className="profile-titles"
                >
                  <span style={{ fontSize: "20px", fontWeight: 600 }}>
                    Change Password
                  </span>
                </Col>
                <Col
                  xl={{ span: 17, offset: 1 }}
                  lg={{ span: 17, offset: 1 }}
                  md={{ span: 17, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                >
                  <ChnagePassword />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withAppContext(withRouter(VendorProfileForm));
