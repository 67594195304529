import React, { Component } from "react";
import { noAuthgetDetails, noAuthGetInfo } from "../api/Apis";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../store/contextStore";
import NumberFormat from "react-number-format";
import { Row, Col, Button } from "antd";
class CreateUserName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileInformation: "",
    };
  }

  componentDidMount = async () => {
    let profileBasedUrl = JSON.parse(localStorage.getItem("profileBasedURL"));
    try {
      let userId = "";
      const checkUserApi = await noAuthGetInfo("checkUser/" + profileBasedUrl);
      if (checkUserApi.success) {
        userId =
          checkUserApi.data && checkUserApi.data[0] && checkUserApi.data[0].id;
      }
      noAuthgetDetails("getUsers", userId).then((res) =>
        this.setState({
          profileInformation: res.data,
        })
      );
    } catch (error) {
      alert("Something went wrong.!");
    }
  };
  render() {
    const { profileInformation } = this.state;
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    return (
      <>
        {profileInformation &&
          "id" in profileInformation === true &&
          profileInformation.firstName !== "" && (
            <div className="userInfo-header">
              <div
                className={
                  userLogin ? "grid__inline" : "grid__inline_non_login"
                }
              >
                <div>
                  {profileInformation.profilePicture?.length > 0 &&
                  profileInformation.profilePicture[0] ? (
                    <img
                      src={
                        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                        profileInformation.profilePicture[0]
                      }
                      className="agent-photo"
                      alt="image_header_bar"
                      style={{
                        width: "40px",
                      }}
                    />
                  ) : (
                    <Button
                      shape="circle"
                      size="big"
                      block={true}
                      style={{
                        backgroundColor: "#f9faff",
                        width: "40px",
                      }}
                      className="agent-photo"
                    >
                      {userDetails?.firstName &&
                        userDetails?.firstName.charAt(0).toUpperCase()}
                    </Button>
                  )}
                </div>
                <div className="agent-name">
                  {profileInformation?.firstName +
                    " " +
                    profileInformation?.lastName}
                </div>
                <div className="agent-phone">
                  <a
                    href={
                      profileInformation?.phone &&
                      "tel:" + profileInformation?.phone
                    }
                    style={{ color: "#e74c3c" }}
                  >
                    <NumberFormat
                      value={profileInformation?.phone}
                      format={"(###)-###-####"}
                      displayType="text"
                    />
                  </a>
                </div>
                <div className="agent-name">
                  Your Local Real Estate Resource
                </div>
              </div>
              <Row className="mobile_View">
                <Col sm={{ span: 3 }} xs={{ span: 4 }}>
                  {profileInformation.profilePicture?.length > 0 &&
                  profileInformation.profilePicture[0] ? (
                    <img
                      src={
                        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                        profileInformation.profilePicture[0]
                      }
                      className="img_header_styling"
                      alt="image_header_bar"
                    />
                  ) : (
                    <Button
                      shape="circle"
                      size="big"
                      block={true}
                      style={{
                        backgroundColor: "#f9faff",
                      }}
                      className="img_header_styling"
                    >
                      {userDetails?.firstName &&
                        userDetails?.firstName.charAt(0).toUpperCase()}
                    </Button>
                  )}
                </Col>

                <Col sm={{ span: 21 }} xs={{ span: 20 }}>
                  <Row>
                    <Col
                      span={24}
                      style={{ fontWeight: "500", fontSize: "16px" }}
                    >
                      <Row>
                        <Col>
                          {profileInformation?.firstName +
                            " " +
                            profileInformation?.lastName}
                        </Col>
                        <Col offset={1}>
                          <a
                            href={
                              profileInformation?.phone &&
                              "tel:" + profileInformation?.phone
                            }
                            style={{ color: "#e74c3c" }}
                          >
                            <NumberFormat
                              value={profileInformation?.phone}
                              format={"(###)-###-####"}
                              displayType="text"
                              style={{ color: "#e74c3c", fontWeight: "bold" }}
                            />
                          </a>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={24}
                      style={{ fontWeight: "500", fontSize: "16px" }}
                    >
                      Your Local Real Estate Resource
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
      </>
    );
  }
}

export default withAppContext(withRouter(CreateUserName));
