import styled from "styled-components";
const ModalTitle = styled.div`
  color: #000;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;

const ModalDesc = styled.div`
  text-align: left;
  font-size: 15px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  letter-spacing: 0;
  color: #131419;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ModalData = styled.div`
  color: #000;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  padding: 5px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const HeaderButton = styled.span`
  cursor: pointer;
  font-size: 0.93vw;
  font-family: "Poppins", sans-serif;
  padding: 4px 15px;
  background: ${(props) => props.color && props.color.buttonBackground};
  color: ${(props) => props.color && props.color.buttonColor};
  @media (max-width: 768px) {
    font-size: 3.5vw;
  }
  @media (min-width: 769px) and(max-width: 1024px) {
    font-size: 1.2vw;
  }
`;

const GoalsData = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  color: #131419;
  letter-spacing: 0px;
`;

const GoalsDesc = styled.div`
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  color: #131419;
  letter-spacing: 0px;
`;

export { HeaderButton, ModalTitle, ModalDesc, ModalData, GoalsData, GoalsDesc };
