import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../store/contextStore";
import { AgentPropertiesList, list } from "../api/Apis";
import HomeCard from "../components/Card/HomeCard";
// import Advertisement from "../common/Advertisement";
import moment from "moment";
import DataNotFound from "./DataNotFound";
import { Spin, notification } from "antd";
class MLShomes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newDetails: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });

    this.getMyMlsHomes();
  }

  getMyMlsHomes = async () => {
    const { userDetails } = this.props.context.state;
    let totalMlsData = [];
    const stateCodes = JSON.parse(localStorage.getItem("stateCodesFromAdmin"));
    let favoritedUserId = JSON.parse(localStorage.getItem("userDetails"));

    if (favoritedUserId && favoritedUserId.id) {
      try {
        const getFavorites = await list("userFavorites", {
          userId: favoritedUserId.id,
        });
        if (getFavorites.success) {
          this.props.context.handleFavoritesCount(
            getFavorites.data,
            getFavorites.data.length
          );
        }
      } catch (error) {
        notification["error"]({
          message: "Something went wrong",
        });
      }
    }
    userDetails &&
      userDetails.mlsSateList &&
      userDetails.mlsSateList.length > 0 &&
      userDetails.mlsSateList.map((data) => {
        if (stateCodes && stateCodes.length > 0) {
          let agentId = data.id;
          let vendor = data.state;
          let setVendor = stateCodes.filter(
            (restState) =>
              restState.stateType.toLowerCase() === vendor.toLowerCase()
          );
          let vendorType = setVendor && setVendor[0].vendorType;
          AgentPropertiesList(vendorType, agentId).then((res) => {
            if (res.success) {
              totalMlsData = totalMlsData.concat(res.data);
              this.setState({
                newDetails: totalMlsData,
                countName: "success",
                loading: false,
              });
            }
            this.setState({
              loading: false,
            });
          });
        }
        return true;
      });
    this.setState({
      loading: false,
    });
  };

  handleToLoadListings = () => {
    this.setState({
      loading: true,
    });
  };

  render() {
    const { listOfFavorites, user } = this.props.context.state;
    const { handleFavoritesCount } = this.props;
    const { newDetails, loading } = this.state;
    const data = [];
    newDetails &&
      newDetails.map((e) =>
        data.push({
          mls: true,
          id: e.mlsId,
          title:
            e.address.full +
            ", " +
            e.address.city +
            ", " +
            e.address.state +
            ", " +
            e.address.postalCode,
          baths:
            e.property.bathsFull + (e.property.bathsHalf === 0 ? "" : ".5"),
          beds: e.property.bedrooms,
          cost: e.listPrice,
          // type: e.property.subTypeText,
          agent: e.agent.firstName + " " + e.agent.lastName,
          area: e.property.area,
          photos: e.photos[0],
          createdAt: e.listDate,
          date: moment(e.listDate).format("Do MMM YYYY"),
          status: e.mls.status,
          favorites:
            listOfFavorites &&
            listOfFavorites.filter((o) => o.leadId === e.mlsId),
          userid: user && user.userid,
          agentProfile: false,
          mlsList: true,
          postType: "MLS",
          stateName: e.address && e.address.state,
        })
      );

    return (
      <div>
        <Spin spinning={loading}>
          {data && data.length === 0 && (
            <DataNotFound type="homes" title="No listings as yet." />
          )}
        </Spin>
        {
          data && data.length > 0 && (
            <>
              <HomeCard
                // value={val}
                valueId={user.userid}
                handleFavoritesCount={handleFavoritesCount}
                authenticatePage
                contextProps={this.props.context}
                handleItems={this.handleItems}
                getHomesList={this.getMyMlsHomes}
                allHomesList={data}
                handleToLoadListings={this.handleToLoadListings}
                otherAds={false}
                adType="Rectangle"
                adWhereToShow="Homes"
                adLimit="1"
                zipCode=""
              />
            </>
          )
          // data.map((val, index) => {
          //   if (authenticate && index % 4 === 0 && index > 1) {
          //     return (
          //       <>
          //         <Advertisement
          //           otherAds={false}
          //           adType="Rectangle"
          //           adWhereToShow="Homes"
          //           adLimit="1"
          //           zipCode=""
          //         />
          //         <HomeCard
          //           value={val}
          //           valueId={user.userid}
          //           handleFavoritesCount={handleFavoritesCount}
          //           authenticatePage
          //           contextProps={this.props.context}
          //           handleItems={this.handleItems}
          //           getHomesList={this.getMyMlsHomes}
          //         />
          //       </>
          //     );
          //   } else {
          //     return (
          //       <HomeCard
          //         value={val}
          //         valueId={user.userid}
          //         handleFavoritesCount={handleFavoritesCount}
          //         authenticatePage
          //         contextProps={this.props.context}
          //         handleItems={this.handleItems}
          //         getHomesList={this.getMyMlsHomes}
          //       />
          //     );
          //   }
          // }
          // )
        }
      </div>
    );
  }
}
export default withAppContext(withRouter(MLShomes));
