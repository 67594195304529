import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Select,
  Upload,
  Form,
  AutoComplete,
  DatePicker,
  message,
  Spin,
  Checkbox,
} from "antd";
import { Storage } from "aws-amplify";
import TimePicker from "rc-time-picker";

import {
  PlusOutlined,
  SearchOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import TrainingCardCom from "./TrainingCardCom";
import { add, list, update } from "../../../api/Apis";
import DataNotFound from "../../../common/DataNotFound";
import Loading from "../../../common/Loading";
import moment from "moment";
import { ModalTitle } from "../../../StyledComponents/StyledComponents";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import ImageGallery from "../../../common/ImageGallery";
import Avatar from "../../../images/profileAvatar.png";

const Search = Input;
const { TextArea } = Input;
const { Option } = Select;
const categoryList = [
  { label: "All", value: "All" },
  { label: "Business building", value: "businessBuilding" },
  { label: "Technology", value: "technology" },
  { label: "Listings", value: "listings" },
  { label: "Buyers", value: "buyers" },
  { label: "Lead generation", value: "leadGeneration" },
  { label: "Sphere of influence", value: "sphereOfinfluence" },
  { label: "Marketing", value: "marketing" },
];

export default function TraingsCom() {
  const [trainningDetails, addTraining] = useState({
    openTrainingPopup: false,
    trainingData: {},
    videoDuration: "",
    dataLoading: false,
    searchTrainingData: [],
    allKeywords: [],
  });
  const [files, uploadFiles] = useState({
    imageFile: [],
    imageS3Keys: [],
    additionalFiles: [],
    additionalS3keys: [],
  });

  const [keywords, addKeywords] = useState({
    inputKeywords: "",
    totalKeywords: [],
  });
  const [categoryVal, setCategory] = useState("All");
  const [searchVal, setSearch] = useState("");
  // const [attendeesVal, setAttendees] = useState("");
  const [meetingList, setMeetingList] = useState("");
  const [trainingForm, setTrainingForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [resourceImage, setResourceImage] = useState("");
  const [loading, setLoading] = useState(false);
  // const [allAgents, setallAgents] = useState([]);
  const [citySearch, setcitySearch] = useState("");
  const [selectedTime, setSelectedTime] = useState({
    startTime: "",
    endTime: "",
  });
  const [editData, setEditData] = useState(false);
  const [editModal, setEditModal] = useState([]);
  const [editInput, setEditInput] = useState(false);

  const searchData = (event, searchType) => {
    if (searchType === "category") {
      setCategory(event);
      setSearch("");
    } else {
      setCategory("");
      setSearch(event);
    }
    let trainingLists = [];
    let searchValue = "";
    let maxDu = 0;
    let minDu = 0;
    if (searchType === "text") {
      searchValue = event?.trim().toLowerCase();
    } else if (searchType === "number" && event !== "all") {
      maxDu =
        event < 30
          ? 20
          : event < 40
          ? 30
          : event < 50
          ? 40
          : event < 60
          ? 50
          : 60;
      minDu =
        event <= 20
          ? 0
          : event <= 30
          ? 20
          : event <= 40
          ? 30
          : event <= 50
          ? 40
          : event <= 60 && 50;
    } else if (searchType === "category") {
      searchValue = event?.trim().toLowerCase();
    } else {
      searchValue = event;
    }
    if (searchType === "text") {
      trainingLists =
        trainningDetails?.searchTrainingData?.length > 0 &&
        trainningDetails.searchTrainingData.filter(
          (user) =>
            (user["category"] &&
              user["category"].toLowerCase().indexOf(searchValue) > -1) ||
            (user["tainingName"] &&
              user["tainingName"].toLowerCase().indexOf(searchValue) > -1) ||
            (user["keywords"] &&
              user["keywords"].toString().indexOf(searchValue) > -1)
        );
    } else if (searchType === "category") {
      if (event !== "All") {
        trainingLists =
          trainningDetails?.searchTrainingData?.length > 0 &&
          trainningDetails.searchTrainingData.filter(
            (user) =>
              user["category"] &&
              user["category"].toLowerCase().indexOf(searchValue) > -1
          );
      } else {
        trainingLists = trainningDetails.searchTrainingData;
      }
    } else if (searchType === "number") {
      if (event !== "all") {
        trainingLists =
          trainningDetails?.searchTrainingData?.length > 0 &&
          trainningDetails.searchTrainingData.filter(
            (user) =>
              user["videoDuration"] &&
              parseInt(user["videoDuration"]) <= parseInt(maxDu) &&
              parseInt(user["videoDuration"]) >= parseInt(minDu)
          );
      } else {
        trainingLists = trainningDetails.searchTrainingData;
      }
    } else if (searchType === "schedule") {
      if (searchValue === null) {
        trainingLists = trainningDetails.searchTrainingData;
      } else {
        trainingLists =
          trainningDetails?.searchTrainingData?.length > 0 &&
          trainningDetails.searchTrainingData.filter(
            (user) =>
              user["createdAt"] &&
              moment(user["createdAt"]).format("L") ===
                moment(searchValue).format("L")
          );
      }
    } else if (searchType === "created") {
      if (searchValue === null) {
        trainingLists = trainningDetails.searchTrainingData;
      } else {
        trainingLists =
          trainningDetails?.searchTrainingData?.length > 0 &&
          trainningDetails.searchTrainingData.filter(
            (user) =>
              user["createdAt"] &&
              moment(user["createdAt"]).format("L") ===
                moment(searchValue).format("L")
          );
      }
    }
    addTraining({ ...trainningDetails, trainingData: trainingLists });
  };

  useEffect(() => {
    let mounted = true;
    getTrainingsData(mounted);
    getMeetingsData();
    getAllAgents();
    return () => (mounted = false);
  }, []);

  const getAllAgents = () => {
    setLoading(true);

    let orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));

    let query = {};
    query = {
      orgId: orgDetails.id,
      isActive: true,
    };

    list("users", query)
      .then((result) => {
        if (result.success) {
          let data = { firstName: "All", lastName: "Agents", id: "123" };
          let agentData = result.data.filter(
            (d) => d.userProfile === "AG" && d.id !== userDetails.id
          );
          agentData.push(data);
          // setallAgents(
          //   agentData.sort((a, b) =>
          //     a.firstName.charAt(0) > b.firstName.charAt(0) ? 1 : -1
          //   )
          // );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    addTraining({
      ...trainningDetails,
      openTrainingPopup: false,
    });
    addKeywords({
      ...keywords,
      totalKeywords: [],
    });
  };

  const getTrainingsData = (mounted) => {
    addTraining((t) => ({
      dataLoading: true,
    }));

    const getUser = JSON.parse(localStorage.getItem("orgDetails"));
    // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    // let userId = userDetails.id;
    let orgId = getUser.id;
    let query = {};

    // if (userDetails?.userProfile === "A") {
    query["orgId"] = orgId;
    // } else {
    //   query["userId"] = userId;
    // }

    list("virtualOfficeTrainings", query).then((items) => {
      if (mounted) {
        let duplicateKeywords = [];
        items.data.map((eachKey) =>
          eachKey.keywords?.map((singleItem) =>
            duplicateKeywords.push({
              label: singleItem,
              value: singleItem.toLowerCase(),
            })
          )
        );

        const uniqueKeywords = Array.from(
          new Set(duplicateKeywords.map((a) => a.value))
        ).map((item) => {
          return duplicateKeywords.find(
            (duplicate) => duplicate.value === item
          );
        });
        addTraining((t) => ({
          trainingData: items.data,
          dataLoading: false,
          searchTrainingData: items.data,
          allKeywords: uniqueKeywords,
        }));
      }
    });
  };

  const getMeetingsData = async () => {
    // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgnizationId = JSON.parse(localStorage.getItem("orgId"));

    let query = {};
    // let userId = userDetails.id;
    let orgId = orgnizationId;

    // if (userDetails?.userProfile === "A") {
    query["orgId"] = orgId;
    // } else {
    //   query["userId"] = userId;
    // }
    list("virtualOfficeMeeting", query).then((result) => {
      let today = moment(new Date()).format("L");
      let data = result.data.filter(
        (e) =>
          e.markAsTraine !== false && today <= moment(e.meetDate).format("L")
      );

      if (result.success) {
        setMeetingList((t) =>
          data.sort((a, b) => (a.meetDate < b.meetDate ? 1 : -1))
        );
      }
    }).catch = (error) => {
      console.log(error);
    };
  };

  const showModal = (e) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setEditForm(true);
    setEditModal(e);
    let data = [e].filter((user) => user.userId === userDetails.id);
    if (data.length > 0) {
      setEditData(false);
    } else {
      setEditData(true);
    }
  };

  const addNewTraining = (e) => {
    const getUser = JSON.parse(localStorage.getItem("userDetails"));
    const getOrgId = JSON.parse(localStorage.getItem("orgId"));

    let confirmData = e;

    function getId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    }

    const videoId = getId(e["videoUrl"]);
    const iframeMarkup = `//www.youtube.com/embed/${videoId}`;

    confirmData["videoUrl"] = iframeMarkup;
    confirmData["videoDuration"] = trainningDetails.videoDuration;
    // confirmData["imageS3keys"] = files.imageS3Keys;
    confirmData["additionalS3Keys"] = files.additionalS3keys;
    confirmData["userId"] = getUser.id;
    confirmData["orgId"] = getOrgId;
    confirmData["keywords"] = keywords.totalKeywords;
    confirmData["videoSeen"] = false;
    let data = {
      entityData: confirmData,
    };

    add("virtualOfficeTrainings", data).then((result) => {
      if (result.success) {
        addTraining({ ...trainningDetails, openTrainingPopup: false });
        getTrainingsData(true);
      }
    });
  };

  // const uploadImages = (info, additional) => {
  //   switch (info.file.status) {
  //     case "done":
  //       let Images = files.imageS3Keys;

  //       let imageFile = [];
  //       imageFile.push(info.file);
  //       // imageFile.push(info.fileList);

  //       uploadFiles({ ...files, imageFile });
  //       if (info.fileList.length > 0 && info.file.status !== "removed") {
  //         try {
  //           if (info.file.size > 0) {
  //             Storage.put(info.file.uid, info.file.originFileObj, {
  //               contentType: info.file.type,
  //             }).then((result) => {
  //               Images.push(result.key);
  //               uploadFiles({ ...files, imageS3Keys: Images });
  //             });
  //           } else {
  //           }
  //         } catch (err) {
  //           console.log(err, "errr");
  //         }
  //       }
  //       break;

  //     case "removed":
  //       let imageS3Keys = files.imageS3Keys.filter(
  //         (uidImage) => uidImage !== info.file.uid
  //       );
  //       let imageFile1 = files.imageFile.filter(
  //         (imgKey) => imgKey.uid !== info.file.uid
  //       );
  //       uploadFiles({
  //         ...files,
  //         imageFile: imageFile1,
  //         imageS3Keys,
  //       });

  //       break;
  //     default:
  //   }
  // };

  const uploadAdditionalFiles = (info) => {
    switch (info.file.status) {
      case "done":
        let Images = files.additionalS3keys;

        let additionalFiles = files.additionalFiles;
        additionalFiles.push(info.fileList);
        uploadFiles({ ...files, additionalFiles });

        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Images.push(result.key);
                uploadFiles({ ...files, additionalS3keys: Images });
              });
            } else {
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      case "removed":
        let additionalS3keys = files.additionalS3keys.filter(
          (uidImage) => uidImage !== info.file.uid
        );
        let imageFile1 = files.additionalFiles.filter(
          (imgKey) => imgKey.uid !== info.file.uid
        );
        uploadFiles({
          ...files,
          additionalFiles: imageFile1,
          additionalS3keys,
        });
        break;
      default:
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleToAddKeywords = () => {
    console.log(keywords, "key");
    if (keywords.inputKeywords !== "") {
      let newKeywords = keywords.totalKeywords;
      newKeywords.push(keywords.inputKeywords);
      addKeywords({
        ...keywords,
        totalKeywords: newKeywords,
        inputKeywords: "",
      });
    } else {
      message.error("Please Add Keyword");
    }
  };

  const onClose = (value) => {
    const newKeyWords = keywords.totalKeywords.filter(
      (eachKey) => eachKey !== value
    );

    addKeywords({ ...keywords, totalKeywords: newKeyWords });
  };

  const handleVideoWatchingDetails = (record) => {
    let watchedDate = new Date();
    let videoSeen = true;
    let data = {
      entityData: {
        watchedDate,
        videoSeen,
      },
    };

    update("virtualOfficeTrainings", record.id, data).then((result) => {
      if (result.success) {
        addTraining({ ...trainningDetails, openTrainingPopup: false });
        getTrainingsData(true);
      }
    });
  };

  // const closeAttendees = (e) => {
  //   console.log(e, "sganet", editModal);
  //   let data = editModal.filter((a) => a.id !== e.id);
  //   console.log(data);
  //   // setEditModal
  // };

  // const closeAttendees = (value) => {
  //   let updateAttendes = editModal.attendees.filter(
  //     (agent) => agent.id !== value
  //   );
  //   setEditModal({
  //     ...editModal,
  //     attendees: updateAttendes,
  //   });
  // };

  const addTrainingInfo = () => {
    const onSelect = (data) => {
      addKeywords({
        ...keywords,
        inputKeywords: data,
      });
    };
    const onSearch = (searchText) => {
      addKeywords({
        ...keywords,
        inputKeywords: searchText,
      });
    };
    return (
      <div>
        <Col span={24} style={{ padding: "16px" }}>
          <Form
            initialValues={{ remember: true }}
            onFinish={addNewTraining}
            id="form"
          >
            <Form.Item
              name="tainingName"
              rules={[
                {
                  required: true,
                  message: "Please input Training name",
                },
              ]}
            >
              <Input
                placeholder="Training Name"
                className="form-input-training"
                // value={sellerName}
              />
            </Form.Item>
            <Form.Item
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please select category !",
                },
              ]}
            >
              <Select
                placeholder="Category"
                className="form-input-training"
                // onSelect={(sortValue) => this.handleSort(sortValue)}
              >
                {categoryList.map((eachCat) => (
                  <Option value={eachCat.label}>{eachCat.label}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input description !",
                },
              ]}
            >
              <TextArea
                placeholder="Description"
                className="form-input-training-description"
              />
            </Form.Item>
            <Form.Item
              name="keywords"
              rules={[
                {
                  required: keywords.totalKeywords?.length > 0 ? false : true,
                  message: "Please input keywords",
                },
              ]}
            >
              <Row>
                <Col span={20}>
                  {/* <Input
                    placeholder="Keywords"
                    className="form-input-training"
                    value={keywords?.inputKeywords}
                    onChange={(e) =>
                      addKeywords({
                        ...keywords,
                        inputKeywords: e.target.value,
                      })
                    }
                  /> */}
                  <AutoComplete
                    options={trainningDetails?.allKeywords}
                    style={{ width: "100%" }}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    placeholder="Keywords"
                    className="form-input-training"
                    filterOption={true}
                    value={keywords?.inputKeywords}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    onClick={handleToAddKeywords}
                    style={{
                      width: "80px",
                      height: "40px",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                  >
                    Add
                  </Button>
                </Col>
                <Col span={24}>
                  {keywords?.totalKeywords.map((eachKey, index) => (
                    <div
                      key={index}
                      style={{
                        display: "inline-flex",
                        margin: "0.73vw",
                        padding: "0 7px",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                        border: "1px solid darkgrey",
                      }}
                    >
                      <div>{eachKey}</div>
                      <div
                        style={{
                          color: "grey",
                          cursor: "pointer",
                          marginLeft: "0.4vw",
                        }}
                        onClick={() => onClose(eachKey, index)}
                      >
                        x
                      </div>
                    </div>
                  ))}
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="videoUrl"
              rules={[
                {
                  required: true,
                  message: "Please input Video URL",
                },
              ]}
            >
              <Input
                placeholder="URL"
                className="form-input-training"
                // value={sellerName}
              />
            </Form.Item>
            <Row>
              <Col
                style={{
                  color: "#888888",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  display: "flex",
                }}
                span={24}
              >
                <span style={{ marginTop: "6px" }}> Duration</span>{" "}
                <Input
                  className="duration-training"
                  onChange={(e) =>
                    addTraining({
                      ...trainningDetails,
                      videoDuration: e.target.value,
                    })
                  }
                  type="number"
                />{" "}
                <span style={{ marginTop: "6px", marginLeft: "6px" }}>
                  mins
                </span>
              </Col>

              {/* DON'T DELETE  */}

              {/* <Col
                span={17}
                style={{
                  color: "#888888",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  paddingTop: "1vw",
                }}
              >
                <span>Image</span>
              </Col>
              <Col
                span={7}
                style={{
                  paddingTop: "1vw",
                }}
              >
                <span style={{ float: "right" }}>
                  <Upload
                    onChange={(info) => uploadImages(info)}
                    // fileList={files?.imageFile}
                    // beforeUpload={() => false}
                    style={{ borderRadius: "5px" }}
                    listType="text"
                    customRequest={dummyRequest}
                    maxCount={1}
                  >
                    <span
                      style={{
                        color: "#3045D2",
                        fontSize: "15px",
                        fontWeight: "500",
                        float: "right",
                      }}
                    >
                      Upload Image
                    </span>
                  </Upload>
                </span>
              </Col> */}
              <Col
                span={18}
                style={{
                  color: "#888888",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  paddingTop: "1vw",
                }}
              >
                <span>Additional file</span>
              </Col>
              <Col
                span={6}
                style={{
                  paddingTop: "1vw",
                }}
              >
                <span
                  style={{
                    float: "right",
                  }}
                >
                  <Upload
                    onChange={(info) =>
                      uploadAdditionalFiles(info, "additional")
                    }
                    // fileList={files?.additionalFiles}
                    // beforeUpload={() => false}
                    style={{ borderRadius: "5px",}}
                    customRequest={dummyRequest}
                    maxCount={1}
                    listType="text"
                  >
                    <span
                      style={{
                        color: "#3045D2",
                        fontSize: "15px",
                        fontWeight: "500",
                        float: "right",
                        cursor:"pointer" 
                      }}
                    >
                      Upload
                    </span>
                  </Upload>
                </span>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
    );
  };

  const handleTime = (type, time) => {
    let startTime = selectedTime.startTime ? selectedTime.startTime : "";
    let endTime = selectedTime.endTime ? selectedTime.endTime : "";
    if (type === "start") {
      startTime = moment(time).format("hh:mm A");
    } else {
      endTime = moment(time).format("hh:mm A");
    }
    setSelectedTime((t) => ({
      startTime,
      endTime,
    }));
  };

  const handleUploadPic = async (info) => {
    setLoading(true);
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                setResourceImage(result.key);
                setLoading(false);
              });
            } else {
              setLoading(false);
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  const handleSelect = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    setcitySearch(citySearch);
  };

  const onFinish = (values) => {
    setLoading(true);
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgnizationId = JSON.parse(localStorage.getItem("orgId"));
    values["meetDate"] = moment(values["meetDate"]).format("L");
    values["startTime"] = selectedTime.startTime
      ? selectedTime.startTime
      : editModal.startTime;
    values["endTime"] = selectedTime.endTime
      ? selectedTime.endTime
      : editModal.endTime;
    values["address"] = citySearch !== "" ? citySearch : editModal?.address;
    values["markAsTraine"] = true;
    // if (attendeesVal !== "") {
    //   let AgentData = allAgents.filter((a) => attendeesVal === a.id && a);
    //   AgentData.filter((e) => {
    //     if (e.id === "123") {
    //       values["attendees"] = allAgents.filter((a) => a.id !== "123");
    //     } else {
    //       values["attendees"] = [e];
    //     }
    //     return true;
    //   });
    // } else {
    //   values["attendees"] = editModal?.attendees;
    // }
    values["userId"] = userDetails.id;
    values["orgId"] = orgnizationId;
    values["creatorInfo"] = userDetails.firstName + " " + userDetails.lastName;
    values["uploadImage"] =
      resourceImage !== "" ? resourceImage : editModal?.uploadImage;
    let data = {
      entityData: values,
    };
    console.log(data, "data");
    if (editModal.length !== 0) {
      update("virtualOfficeMeeting", editModal.id, data)
        .then((result) => {
          if (result.success) {
            setEditModal("");
            setEditForm(false);
            setResourceImage("");
            setLoading(false);
            getMeetingsData();
            setEditInput(false);
          }
        })
        .catch((err) => {
          setEditForm(false);
          setResourceImage("");
          setLoading(false);
          setEditInput(false);
          console.log(err);
        });
    } else {
      add("virtualOfficeMeeting", data)
        .then((result) => {
          if (result.success) {
            setTrainingForm(false);
            setResourceImage("");
            setLoading(false);
            getMeetingsData();
            getMeetingsData();
          }
        })
        .catch((err) => {
          setTrainingForm(false);
          setResourceImage("");
          setLoading(false);
          console.log(err);
        });
    }
  };
  // console.log(editModal, "editModal", resourceImage, editModal.uploadImage);

  return (
    <div
      style={{
        padding: window.screen.width > 996 ? "24px" : "0px",
        marginBottom: "1vw",
      }}
    >
      <Loading enableLoading={trainningDetails.dataLoading} />
      <Row>
        <Col
          xl={{ span: 17, offset: 0 }}
          lg={{ span: 17, offset: 0 }}
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 12 }}
              className="mobile_view_margin_Top"
            >
              <span className="todo_title">Trainings</span>
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 12 }}
              style={{ marginBottom: "1vw" }}
            >
              <Button
                icon={<PlusOutlined style={{ color: "#3045d2" }} />}
                className="todo_button"
                style={{ float: "right", width: "100px" }}
                onClick={() =>
                  addTraining({ ...trainningDetails, openTrainingPopup: true })
                }
              >
                <span className="virtual_button_text btn-color">ADD</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 8, offset: 0 }}
              lg={{ span: 8, offset: 0 }}
              xs={{ span: 19, offset: 0 }}
              sm={{ span: 19, offset: 0 }}
              style={{ marginBottom: "1vw" }}
            >
              <Search
                value={searchVal || ""}
                size="large"
                placeholder="Search by training name, category, keywords..."
                onChange={(event) => searchData(event.target.value, "text")}
                prefix={<SearchOutlined className="site-form-item-icon" />}
              />
            </Col>
            {/* <Col
              xl={{ span: 5, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xs={{ span: 19, offset: 0 }}
              sm={{ span: 19, offset: 0 }}
              style={{ marginBottom: "1vw", marginLeft: "1vw" }}
            >
              <Select
                placeholder="Video Duration"
                onSelect={(event) => searchData(event, "number")}
                style={{ width: "100%" }}
              >
                {durationList.map((eachCat) => (
                  <Option value={eachCat.value}>{eachCat.label}</Option>
                ))}
              </Select>
            </Col> */}
            {/* <Col
              xl={{ span: 5, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xs={{ span: 19, offset: 0 }}
              sm={{ span: 19, offset: 0 }}
              style={{ marginLeft: "1vw" }}
            >
              <DatePicker
                size="large"
                onChange={(event) => searchData(event, "schedule")}
                placeholder="Search by scheduled date"
                style={{ width: "100%", height: "40px" }}
              />
            </Col> */}
            {/* <Col
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              xs={{ span: 19, offset: 0 }}
              sm={{ span: 19, offset: 0 }}
              style={{ marginLeft: "1vw" }}
            >
              <DatePicker
                style={{ width: "123%", height: "40px" }}
                size="large"
                onChange={(event) => searchData(event, "created")}
                placeholder="Search by created date"
              />
            </Col> */}
            <ModalTitle style={{ marginLeft: "10px", marginTop: "5px" }}>
              or
            </ModalTitle>
            <ModalTitle style={{ marginLeft: "10px", marginTop: "5px" }}>
              {" "}
              Category :{" "}
            </ModalTitle>
            <Col
              xl={{ span: 4, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              xs={{ span: 19, offset: 0 }}
              sm={{ span: 19, offset: 0 }}
              style={{ marginLeft: "1vw" }}
            >
              <Select
                placeholder="Category"
                className="form-input-training"
                value={categoryVal ? categoryVal : searchVal ? "" : "All"}
                style={{ width: "100%" }}
                onSelect={(sortValue) => searchData(sortValue, "category")}
              >
                {categoryList.map((eachCat) => (
                  <Option value={eachCat.label}>{eachCat.label}</Option>
                ))}
              </Select>
            </Col>
            {/* <Col
              xl={{ span: 2, offset: 0 }}
              lg={{ span: 2, offset: 0 }}
              xs={{ span: 3, offset: 2 }}
              sm={{ span: 3, offset: 2 }}
              style={{ marginLeft: "1vw" }}
            >
              <div
                className="training-filter-style"
                style={{
                  float: "right",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setFilterView({ ...filterView, openFilter: true })
                }
              >
                <img
                  src={trainingFilter}
                  alt="training_filter"
                  width="27px"
                  height="26px"
                  style={{
                    marginLeft: "8px",
                    marginTop: "6px",
                  }}
                />
              </div>
            </Col> */}
          </Row>

          <Row>
            <Col span={24}>
              {trainningDetails?.trainingData?.length > 0 ? (
                trainningDetails.trainingData.map((eachTraining) => (
                  <TrainingCardCom
                    cardData={eachTraining}
                    handleVideoWatchingDetails={handleVideoWatchingDetails}
                    getTrainingsData={getTrainingsData}
                  />
                ))
              ) : (
                <div style={{ padding: "4vw" }}>
                  <DataNotFound
                    type="training"
                    title="There is no training started yet."
                  />
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col
          xl={{ span: 6, offset: 1 }}
          lg={{ span: 6, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <Card
            title="Upcoming"
            className="card_hover"
            bodyStyle={{ padding: "0px", paddingTop: "10px" }}
            extra={
              <Button
                icon={<PlusOutlined style={{ color: "#3045d2" }} />}
                onClick={() => {
                  setTrainingForm(true);
                  setEditData(false);
                  setEditModal("");
                }}
                className="todo_button"
                style={{ float: "right", border: "1px solid #3045d2" }}
              >
                <span className="virtual_button_text btn-color">
                  ADD UPCOMING
                </span>
              </Button>
            }
          >
            {meetingList?.length > 0 &&
              meetingList.map((e) => (
                <Row style={{ marginBottom: "10px" }}>
                  <Col
                    xl={{ span: 4, offset: 1 }}
                    lg={{ span: 4, offset: 1 }}
                    sm={{ span: 4, offset: 1 }}
                    xs={{ span: 4, offset: 1 }}
                    onClick={() => showModal(e)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="circle_round">
                      <Row className="date_training">
                        {moment(e.meetDate).format("MMM")}
                      </Row>
                      <Row className="date_training">
                        {moment(e.meetDate).format("Do")}
                      </Row>
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 15, offset: 1 }}
                    lg={{ span: 15, offset: 1 }}
                    sm={{ span: 15, offset: 1 }}
                    xs={{ span: 15, offset: 1 }}
                    onClick={() => showModal(e)}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="upcoming_train_title">{e?.title}</span>
                    <p
                      style={{
                        color: "#99A2C3",
                        fontSize: "12px",
                      }}
                    >
                      {e?.meetingUrl ? e?.meetingUrl : e?.address}
                    </p>
                  </Col>
                  <Col xl={{ span: 2 }}>
                    {e?.uploadImage && (
                      <ImageGallery
                        photos={e?.uploadImage}
                        businessCard
                        noWidth
                      />
                    )}
                  </Col>
                </Row>
              ))}
          </Card>
        </Col>
      </Row>
      {/* {filterView.openFilter && filterViewForTraining()} */}
      <Modal
        className="modal-black-close"
        visible={trainningDetails.openTrainingPopup}
        title="Add Training"
        onCancel={() => handleCancel()}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={() => handleCancel()} htmlType="reset">
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            // onClick={() => this.update(true)}
            htmlType="submit"
            form="form"
          >
            Add
          </Button>,
        ]}
      >
        {addTrainingInfo()}
      </Modal>

      <Modal
        className="modal-black-close"
        visible={trainingForm}
        title="Create Training"
        style={{ marginTop: "2%" }}
        destroyOnClose={true}
        onCancel={() => {
          setTrainingForm(false);
          setResourceImage("");
          setEditData(false);
          setEditModal("");
        }}
        footer={
          <Col span={24}>
            {!editData ? (
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Button
                    onClick={() => {
                      setTrainingForm(false);
                      setResourceImage("");
                      setEditData(false);
                      setEditModal("");
                    }}
                    type="default"
                    style={{
                      width: "100%",
                      backgroundColor: "#bbb",
                      color: "#FFF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    htmlType="reset"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    htmlType="submit"
                    form="form"
                    type="primary"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    loading={loading}
                  >
                    Create Training
                  </Button>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>
        }
        width={"35%"}
      >
        <Form onFinish={onFinish} id="form" initialValues={false}>
          <Card
            style={{ marginTop: "10px" }}
            className="card_virtual"
            hoverable={true}
          >
            <Col span={24}>
              <ModalTitle>Date</ModalTitle>
              <Form.Item
                name="meetDate"
                rules={[
                  {
                    required: true,
                    message: "Please input date!",
                  },
                ]}
                initialValue={
                  editModal?.meetDate ? moment(editModal.meetDate) : moment()
                }
              >
                <DatePicker
                  format="MM/DD/YYYY"
                  style={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                  readOnly={editData ? true : false}
                />
              </Form.Item>
            </Col>
            <Row>
              <Col span={11}>
                <ModalTitle style={{ marginTop: "10px" }}>
                  Start Time
                </ModalTitle>
                <Form.Item
                  name="startTime"
                  rules={[
                    {
                      required: true,
                      message: "Please input start time!",
                    },
                  ]}
                  initialValue={editModal.startTime ? moment() : ""}
                >
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    minuteStep={15}
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    onChange={(e) => handleTime("start", e)}
                    className="time-picker"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    inputReadOnly={editData ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <ModalTitle style={{ marginTop: "10px" }}>End Time</ModalTitle>
                <Form.Item
                  name="endTime"
                  rules={[
                    {
                      required: true,
                      message: "Please input end time!",
                    },
                  ]}
                  initialValue={editModal.startTime ? moment() : ""}
                >
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    minuteStep={15}
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    onChange={(e) => handleTime("end", e)}
                    className="time-picker"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    inputReadOnly={editData ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col span={24}>
              <ModalTitle style={{ marginTop: "10px" }}>Title</ModalTitle>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input title !",
                  },
                ]}
                name="title"
                initialValue={editModal.title}
              >
                <Input
                  readOnly={editData ? true : false}
                  placeholder="Please input title"
                  // className="form-input"
                  style={{ height: "40px" }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
      <ModalTitle style={{ marginTop: "10px" }}>Attendees</ModalTitle>
      <Form.Item
        name="attendees"
        rules={[
          {
            // required: editModal?.attendees?.length > 0 ? false : true,
            message: "Please input your attendees!",
          },
        ]}
      >
        <Select
          allowClear
          readOnly={editData ? true : false}
          // defaultValue={"attendees.."}
          showSearch
          options={allAgents.firstName}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          style={{ borderRadius: "5px " }}
          placeholder="attendees.."
          onChange={(value) => {
            setAttendees(value);
          }}
        >
          {allAgents.map((province) => (
            <Select.Option key={province.id} value={province.id}>
              {province.firstName + " " + province.lastName}
            </Select.Option>
          ))}
        </Select>
        {!attendeesVal
          ? editModal?.attendees?.map((agent) => (
              <div
                key={agent.id}
                style={{
                  display: "inline-flex",
                  margin: "0.73vw",
                  padding: "0 7px",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  border: "1px solid darkgrey",
                }}
              >
                <div>{agent.firstName + " " + agent.lastName}</div>
                <div
                  style={{
                    // color: "grey",
                    cursor: "pointer",
                    marginLeft: "0.4vw",
                    color: "red",
                    fontWeight: "600",
                  }}
                  onClick={() =>
                    !editData ? closeAttendees(agent.id) : ""
                  }
                >
                  x
                </div>
              </div>
            ))
          : ""}
      </Form.Item>
    </Col> */}
            <Col span={24}>
              <ModalTitle style={{ marginTop: "10px" }}>Meeting Url</ModalTitle>
              <Form.Item
                name="meetingUrl"
                rules={[
                  {
                    required: false,
                    message: "Please input your meeting url!",
                  },
                ]}
                initialValue={editModal.meetingUrl}
              >
                <Input
                  placeholder="Please input meeting url"
                  // className="form-input"
                  style={{ height: "40px" }}
                  readOnly={editData ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <ModalTitle>Address</ModalTitle>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: false,
                    message: "Please input address",
                  },
                ]}
                readOnly={editData ? true : false}
                initialValue={editModal?.address}
                style={{ marginBottom: "1vw" }}
              >
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_MAPS_KEY}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["us"],
                    },
                  }}
                  selectProps={{
                    citySearch,
                    defaultInputValue: `${
                      editModal?.address ? editModal?.address : ""
                    }`,
                    placeholder: "Search Address...",
                    onChange: (address) => {
                      handleSelect(address);
                    },
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <ModalTitle>Upload</ModalTitle>
              <Form.Item
                name="upload"
                rules={[
                  {
                    required: false,
                    message: "Please input your images!",
                  },
                ]}
                // initialValue={}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                  }}
                  showUploadList={false}
                  customRequest={dummyRequest}
                  readOnly={editData ? true : false}
                  onChange={(e) => handleUploadPic(e)}
                >
                  <Spin spinning={loading}>
                    <Row>
                      {resourceImage ? (
                        <img
                          src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${resourceImage}`}
                          className="ant-icon"
                          alt="meetingIcon"
                          style={{ width: "100%", heighht: "100%" }}
                        />
                      ) : editModal.uploadImage ? (
                        <img
                          src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${editModal.uploadImage}`}
                          className="ant-icon"
                          alt="meetingIcon"
                          style={{ width: "100%", heighht: "100%" }}
                        />
                      ) : (
                        <CameraOutlined
                          className="camera-icon"
                          style={{ fontSize: "45px" }}
                        />
                      )}
                    </Row>
                  </Spin>
                </Upload>
              </Form.Item>
            </Col>

            <Checkbox checked={true}>Mark As Training</Checkbox>
          </Card>
        </Form>
      </Modal>

      <Modal
        className="modal-black-close"
        visible={editForm}
        title="Training Details"
        style={{ marginTop: "2%" }}
        destroyOnClose={true}
        onCancel={() => {
          setEditForm(false);
          setResourceImage("");
          setEditData(false);
          setEditInput(false);
          setEditModal("");
        }}
        footer={
          <Col span={24}>
            {!editData ? (
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Button
                    onClick={() => {
                      setEditForm(false);
                      setResourceImage("");
                      setEditData(false);
                      setEditModal("");
                      setEditInput(false);
                    }}
                    type="default"
                    style={{
                      width: "100%",
                      backgroundColor: "#bbb",
                      color: "#FFF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    htmlType="reset"
                  >
                    Cancel
                  </Button>
                </Col>
                {editInput && (
                  <Col span={12}>
                    <Button
                      htmlType="submit"
                      form="form"
                      type="primary"
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      loading={loading}
                    >
                      Update Training
                    </Button>
                  </Col>
                )}
                {!editInput && (
                  <Col span={12}>
                    <Button
                      type="primary"
                      onClick={() => {
                        // setEditForm(false);
                        // setResourceImage("");
                        // setEditData(false);
                        setEditInput(true);
                      }}
                      style={{
                        width: "100%",
                        color: "#FFF",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    >
                      Edit
                    </Button>
                  </Col>
                )}
              </Row>
            ) : (
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Button
                    onClick={() => {
                      setEditForm(false);
                      setResourceImage("");
                      setEditData(false);
                      setEditModal("");
                    }}
                    type="default"
                    style={{
                      width: "100%",
                      backgroundColor: "#bbb",
                      color: "#FFF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    htmlType="reset"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        }
        width={"35%"}
      >
        <Form onFinish={onFinish} id="form" initialValues={false}>
          <Card
            style={{ marginTop: "10px" }}
            className="card_virtual"
            hoverable={true}
            bodyStyle={{ padding: "10px" }}
          >
            <Row>
              <Col span={24} style={{ marginBottom: "1vw" }}>
                {editInput ? (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input title !",
                      },
                    ]}
                    name="title"
                    initialValue={editModal.title}
                  >
                    <Input
                      readOnly={editData ? true : false}
                      placeholder="Please input title"
                      // className="form-input"
                      style={{ height: "40px" }}
                    />
                  </Form.Item>
                ) : (
                  <div className="traing_modal_title">{editModal.title}</div>
                )}
              </Col>

              {editInput ? (
                <Col xl={{ span: 7 }}>
                  <Spin
                    spinning={loading}
                    size="large"
                    className="center-item"
                    style={{ marginTop: "10%" }}
                  >
                    {resourceImage !== "" ||
                    editModal?.uploadImage !== undefined ||
                    editModal?.uploadImage !== "" ? (
                      <img
                        src={`https://${
                          process.env.REACT_APP_S3_BUCKET
                        }.s3.amazonaws.com/public/${
                          resourceImage ? resourceImage : editModal?.uploadImage
                        }`}
                        alt="profile"
                        className="img-profile"
                        style={{
                          width: "180px",
                          height: "180px",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <img
                        src={Avatar}
                        alt="profile"
                        className="img-profile"
                        style={{
                          width: "180px",
                          height: "180px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    <Button
                      type="primary"
                      className="update-profile-btn"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Upload
                        showUploadList={false}
                        customRequest={dummyRequest}
                        readOnly={editData ? true : false}
                        onChange={(e) => this.handleUploadPic(e)}
                      >
                        <span className="Btn-name">Update Image</span>
                      </Upload>
                    </Button>
                  </Spin>
                </Col>
              ) : (
                editModal?.uploadImage && (
                  <Col xl={{ span: 7 }}>
                    <img
                      src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${editModal?.uploadImage}`}
                      className="ant-icon"
                      alt="meetingIcon"
                      style={{
                        width: "180px",
                        height: "180px",
                        objectFit: "contain",
                      }}
                    />
                  </Col>
                )
              )}

              <Col
                xl={{
                  span: editModal?.uploadImage ? 16 : editInput ? 16 : 24,
                  offset: editModal?.uploadImage ? 1 : editInput ? 1 : 0,
                  // span: editModal?.uploadImage ? 16 : 24,
                  // offset: editModal?.uploadImage ? 1 : 0,
                }}
              >
                {editInput ? (
                  <Form.Item
                    name="meetDate"
                    rules={[
                      {
                        required: true,
                        message: "Please input date!",
                      },
                    ]}
                    initialValue={
                      editModal?.meetDate
                        ? moment(editModal.meetDate)
                        : moment()
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <DatePicker
                      format="MM/DD/YYYY"
                      style={{
                        height: "40px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      readOnly={editData ? true : false}
                    />
                  </Form.Item>
                ) : (
                  <div className="traing_modal_title">
                    {moment(editModal?.meetDate).format("MMMM Do YYYY")}
                  </div>
                )}
                <div className="traing_modal_title" style={{ display: "flex" }}>
                  <div className="traing_modal_title">
                    {editInput ? (
                      <Form.Item
                        name="startTime"
                        rules={[
                          {
                            required: true,
                            message: "Please input start time!",
                          },
                        ]}
                        initialValue={editModal.startTime ? moment() : ""}
                        style={{ marginBottom: "10px" }}
                      >
                        <TimePicker
                          // defaultValue={editModal.startTime}
                          placeholder="Select Time"
                          use12Hours
                          minuteStep={15}
                          showSecond={false}
                          focusOnOpen={true}
                          format="hh:mm A"
                          onChange={(e) => handleTime("start", e)}
                          className="time-picker"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          inputReadOnly={editData ? true : false}
                        />
                      </Form.Item>
                    ) : (
                      editModal?.startTime
                    )}
                  </div>
                  <div
                    className="traing_modal_title"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    {" "}
                    to{" "}
                  </div>
                  <div className="traing_modal_title">
                    {editInput ? (
                      <Form.Item
                        name="endTime"
                        rules={[
                          {
                            required: true,
                            message: "Please input end time!",
                          },
                        ]}
                        initialValue={editModal.startTime ? moment() : ""}
                        style={{ marginBottom: "10px" }}
                      >
                        <TimePicker
                          placeholder="Select Time"
                          use12Hours
                          minuteStep={15}
                          showSecond={false}
                          focusOnOpen={true}
                          format="hh:mm A"
                          onChange={(e) => handleTime("end", e)}
                          className="time-picker"
                          style={{
                            width: "100%",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          inputReadOnly={editData ? true : false}
                        />
                      </Form.Item>
                    ) : (
                      editModal?.endTime
                    )}
                  </div>
                </div>
                <div className="traing_modal_title">
                  {editInput ? (
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: false,
                          message: "Please input address",
                        },
                      ]}
                      readOnly={editData ? true : false}
                      initialValue={editModal?.address}
                      style={{ marginBottom: "10px" }}
                    >
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_MAPS_KEY}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["us"],
                          },
                        }}
                        selectProps={{
                          citySearch,
                          defaultInputValue: `${
                            editModal?.address ? editModal?.address : ""
                          }`,
                          placeholder: "Search Address...",
                          onChange: (address) => {
                            handleSelect(address);
                          },
                        }}
                      />
                    </Form.Item>
                  ) : (
                    editModal.address
                  )}
                </div>
                <div className="traing_modal_title">
                  {" "}
                  {editInput ? (
                    <Form.Item
                      name="meetingUrl"
                      rules={[
                        {
                          required: false,
                          message: "Please input your meeting url!",
                        },
                      ]}
                      initialValue={editModal.meetingUrl}
                    >
                      <Input
                        placeholder="Please input meeting url"
                        // className="form-input"
                        style={{ height: "40px" }}
                        readOnly={editData ? true : false}
                      />
                    </Form.Item>
                  ) : (
                    editModal.meetingUrl
                  )}
                </div>
                {!editInput && (
                  <div className="traing_modal_title">
                    {editModal?.creatorInfo
                      ? "Created by " + editModal?.creatorInfo
                      : ""}
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Form>
      </Modal>
    </div>
  );
}
