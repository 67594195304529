import React, { Component } from "react";
import HomeTab from "../components/Tabs/HomeTab";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../src/store/contextStore";
import { noAuthgetDetails } from "../api/Apis";

class EmailShowMatchesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: "",
      callHomeTab: false,
    };
  }
  componentDidMount = async () => {
    localStorage.removeItem("selectedPropertyTypes");
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let buyerId = params.get("buyerId");

    let buyer = await noAuthgetDetails("getBuyers", buyerId);
    console.log(params, "llllllllllllll", buyer);
    buyer = buyer["data"];
    let matchingObject = {};
    let newList = [];
    if (
      "buyerPropertyDetails" in buyer !== false &&
      "buyerPropertyFeatures" in buyer !== false
    ) {
      console.log("comingg");
      if (buyer["buyerPropertyDetails"]["maxprice"]) {
        matchingObject["maxprice"] = parseFloat(
          buyer["buyerPropertyDetails"]["maxprice"]
        );
      }
      if (buyer["buyerPropertyDetails"]["minprice"]) {
        matchingObject["minprice"] = parseFloat(
          buyer["buyerPropertyDetails"]["minprice"]
        );
      }
      if (buyer["buyerPropertyFeatures"]["halfBaths"]) {
        matchingObject["halfBaths"] =
          buyer["buyerPropertyFeatures"]["halfBaths"];
      }

      if (buyer["buyerPropertyFeatures"]["openHouses"]) {
        matchingObject["openHouses"] =
          buyer["buyerPropertyFeatures"]["openHouses"];
      }
      
      if (buyer["buyerPropertyFeatures"]["beds"]) {
        matchingObject["minbeds"] = buyer["buyerPropertyFeatures"]["beds"];
      }

      if (buyer["buyerPropertyFeatures"]["beds"]) {
        matchingObject["minbeds"] = buyer["buyerPropertyFeatures"]["beds"];
      }
      if (buyer["buyerPropertyFeatures"]["baths"]) {
        matchingObject["minbaths"] = buyer["buyerPropertyFeatures"]["baths"];
      }
      if (buyer["buyerPropertyFeatures"]["maxArea"]) {
        matchingObject["maxarea"] = buyer["buyerPropertyFeatures"]["maxArea"];
        matchingObject["minarea"] = buyer["buyerPropertyFeatures"]["minArea"];
      }
      if (
        "updatedInterestedTowns" in buyer["buyerPropertyDetails"] !== false &&
        buyer["buyerPropertyDetails"]["updatedInterestedTowns"]?.length > 0
      ) {
        let searchLocation = [];
        let citiesList = [];
        buyer["buyerPropertyDetails"]["updatedInterestedTowns"].map(
          (address) => {
            citiesList.push(`${address.stateName}:${address.cityName}`);
            if (citiesList.length) {
              matchingObject["cities"] = citiesList.join(",");
            }
            address["locationType"] = "location";
            searchLocation.push(address);
            return true;
          }
        );
        let newSearchCitiesList = {
          type: "location",
          searchLocation,
          citiesList,
        };
        localStorage.setItem(
          "selectedCities",
          JSON.stringify(newSearchCitiesList)
        );
      }

      if (buyer["buyerPropertyDetails"]["propertyType"]) {
        if (
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Commercial Land"
          ) ||
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Residential Land"
          ) ||
          buyer["buyerPropertyDetails"]["propertyType"].includes("Land")
        ) {
          newList.push("Detached", "LND");
        }
        if (
          buyer["buyerPropertyDetails"]["propertyType"].includes("Mobile Homes")
        ) {
          newList.push("MBL");
        }

        if (
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Residential Single Family Home"
          ) ||
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Residential Studio"
          ) ||
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Residential Apartment"
          )
        ) {
          newList.push("RES");
        }
        if (
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Residential Rental"
          )
        ) {
          newList.push("RNT");
        }
        if (
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Residential Condo"
          ) ||
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Residential Condominium"
          ) ||
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Condominium/Co-op"
          )
        ) {
          newList.push("CND");
        }
        if (
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Commercial MLS"
          ) ||
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Commercial Office"
          ) ||
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Commerical/Industrial"
          ) ||
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Business Opportunity"
          )
        ) {
          newList.push("CRE");
        }
        if (
          buyer["buyerPropertyDetails"]["propertyType"].includes("Coopertive")
        ) {
          newList.push("Coopertive");
        }
        if (
          buyer["buyerPropertyDetails"]["propertyType"].includes(
            "Residential Multi Family Home"
          )
        ) {
          newList.push("MLF");
        }
        matchingObject["types"] = newList.join(",");
      }

      if (buyer["buyerPropertyDetails"]["propertyType"]?.length > 0) {
        let newPropertyList = [];
        matchingObject["types"] = buyer["buyerPropertyDetails"][
          "propertyType"
        ]?.forEach((e) => newPropertyList.push(e.value));
        matchingObject["types"] = newPropertyList.join(",");
      }

      if (buyer["buyerPropertyFeatures"]["status"]) {
        matchingObject["status"] = buyer["buyerPropertyFeatures"]["status"];
      }

      this.props.context.setFilterData(matchingObject);
      this.setState({
        callHomeTab: true,
      });
    }
  };
  render() {
    const { callHomeTab } = this.state;
    return (
      <div>
        {callHomeTab && (
          <HomeTab EmailShowMatchesList={true} showMatchesPage={true} />
        )}
      </div>
    );
  }
}
export default withAppContext(withRouter(EmailShowMatchesList));
