import React, { Component } from "react";
import BottomNavigation from "reactjs-bottom-navigation";
import "reactjs-bottom-navigation/dist/index.css";
import {
  HomeOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  ShopOutlined,
  MoreOutlined,
  CloseOutlined,
  FacebookFilled,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, Drawer } from "antd";
import { withAppContext } from "../store/contextStore";
import { withRouter } from "react-router-dom";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableMoreOptions: false,
      enableHomeOptions: false,
    };
  }

  onClickMenu = (menu) => {
    window.location.replace(menu);
  };

  render() {
    const { enableMoreOptions, enableHomeOptions } = this.state;
    const { footer } = this.props.context.state.websiteThemes;

    const bottomNavItems = [
      {
        title: (
          <span style={{ color: footer?.menuTabs?.textColor }}>Homes</span>
        ),
        icon: <HomeOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <HomeOutlined
            style={{
              fontSize: "24px",
              color: footer?.menuTabs?.textColor,
              cursor: "pointer",
            }}
          />
        ),
        onClick: () => {
          this.setState({
            enableHomeOptions: true,
          });
        },
      },
      {
        title: (
          <span style={{ color: footer?.menuTabs?.textColor }}>Buyers</span>
        ),
        icon: <TeamOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <TeamOutlined
            style={{
              fontSize: "24px",
              color: footer?.menuTabs?.textColor,
              cursor: "pointer",
            }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/buyers");
        },
      },
      {
        title: (
          <span style={{ color: footer?.menuTabs?.textColor }}>Agents</span>
        ),
        icon: (
          <UserSwitchOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
        ),
        activeIcon: (
          <UserSwitchOutlined
            style={{
              fontSize: "24px",
              color: footer?.menuTabs?.textColor,
              cursor: "pointer",
            }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/agent");
        },
      },

      {
        title: (
          <span
            style={{
              color: footer?.menuTabs?.textColor,
            }}
          >
            Lenders
          </span>
        ),
        icon: (
          <DollarCircleOutlined
            style={{ fontSize: "24px", cursor: "pointer" }}
          />
        ),
        activeIcon: (
          <DollarCircleOutlined
            style={{
              fontSize: "24px",
              color: footer?.menuTabs?.textColor,
              cursor: "pointer",
            }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/lenders");
        },
      },

      {
        title: (
          <span
            style={{
              color: footer?.menuTabs?.textColor,
            }}
          >
            Market
          </span>
        ),
        icon: <ShopOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <ShopOutlined
            style={{
              fontSize: "24px",
              color: footer?.menuTabs?.textColor,
              cursor: "pointer",
            }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/marketplace");
        },
      },
      {
        title: (
          <span
            style={{
              color: footer?.menuTabs?.textColor,
            }}
          >
            More
          </span>
        ),
        icon: <MoreOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <MoreOutlined
            style={{
              fontSize: "24px",
              color: footer?.menuTabs?.textColor,
              cursor: "pointer",
            }}
          />
        ),
        onClick: () =>
          this.setState({
            enableMoreOptions: true,
          }),
      },
    ];

    return (
      <div>
        {footer && (
          <>
            <Drawer
              title={null}
              placement="bottom"
              height={120}
              // mask={true}
              closable={false}
              getContainer={false}
              onClose={() => () =>
                this.setState({
                  enableHomeOptions: false,
                })}
              visible={enableHomeOptions}
              headerStyle={{ margin: "10%" }}
              bodyStyle={{
                paddingBottom: 100,
              }}
            >
              <Row
                align="middle"
                justify="space-between"
                style={{
                  padding: "10px",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <div>
                  <h4>Homes</h4>
                </div>
                <div
                  onClick={() => {
                    this.setState({
                      enableHomeOptions: false,
                    });
                  }}
                >
                  <CloseOutlined style={{ fontSize: "18px" }} />
                </div>
              </Row>

              <Col span={24} style={{ margin: "10px" }}>
                <Row>
                  <Col span={12}>
                    <Button
                      style={{
                        backgroundColor:
                          window.location.pathname === "/homes"
                            ? footer.menuTabs.activebgColor
                            : footer.menuTabs.bgColor,

                        color: footer.menuTabs.textColor,
                        width: "100%",
                      }}
                      onClick={() => {
                        this.onClickMenu("/homes");
                      }}
                    >
                      On & Off MLS Homes
                    </Button>
                  </Col>

                  <Col span={12}>
                    <Button
                      style={{
                        backgroundColor:
                          window.location.pathname === "/offMLSHomes"
                            ? footer.menuTabs.activebgColor
                            : footer.menuTabs.bgColor,

                        color: footer.menuTabs.textColor,
                        width: "100%",
                      }}
                      onClick={() => {
                        this.onClickMenu("/offMLSHomes");
                      }}
                    >
                      Off MLS Homes
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Drawer>

            <Drawer
              title={null}
              placement="bottom"
              height={160}
              // mask={true}
              closable={false}
              getContainer={false}
              onClose={() => () =>
                this.setState({
                  enableMoreOptions: false,
                })}
              visible={enableMoreOptions}
              headerStyle={{ margin: "10%" }}
              bodyStyle={{
                paddingBottom: 80,
              }}
            >
              <Row
                align="middle"
                justify="space-between"
                style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
              >
                <div>
                  <h4>Quick Menu</h4>
                </div>
                <div
                  onClick={() => {
                    this.setState({
                      enableMoreOptions: false,
                    });
                  }}
                >
                  <CloseOutlined style={{ fontSize: "18px" }} />
                </div>
              </Row>

              <Col span={24} style={{ margin: "10px" }}>
                <Row>
                  <Col span={8}>
                    <Button
                      style={{
                        backgroundColor:
                          window.location.pathname === "/about"
                            ? footer.menuTabs.activebgColor
                            : footer.menuTabs.bgColor,

                        color: footer.menuTabs.textColor,
                        width: "100%",
                      }}
                      onClick={() => {
                        this.onClickMenu("/about");
                      }}
                    >
                      About Us
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      style={{
                        backgroundColor:
                          window.location.pathname === "/whyJoinUs"
                            ? footer.menuTabs.activebgColor
                            : footer.menuTabs.bgColor,

                        color: footer.menuTabs.textColor,
                        width: "100%",
                      }}
                      onClick={() => {
                        this.onClickMenu("/whyJoinUs");
                      }}
                    >
                      Why Join Us
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      style={{
                        backgroundColor:
                          window.location.pathname === "/contactUs"
                            ? footer.menuTabs.activebgColor
                            : footer.menuTabs.bgColor,

                        color: footer.menuTabs.textColor,
                        width: "100%",
                      }}
                      onClick={() => {
                        this.onClickMenu("/contactUs");
                      }}
                    >
                      Contact Us
                    </Button>
                  </Col>
                </Row>
              </Col>

              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <Row justify="space-around">
                  <Col>
                    <a
                      target="_blank"
                      href={`https://www.facebook.com/Homes-Listed-By-101359968421070`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <FacebookFilled
                        style={{ color: "#2a2a2a", fontSize: "20px" }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      target="_blank"
                      href={`https://www.instagram.com/homeslistedby`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <InstagramOutlined
                        style={{ color: "#2a2a2a", fontSize: "20px" }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      target="_blank"
                      href={`https://twitter.com/homeslistedby`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <TwitterOutlined
                        style={{ color: "#2a2a2a", fontSize: "20px" }}
                      />
                    </a>
                  </Col>

                  <Col>
                    <a
                      target="_blank"
                      href={`https://www.youtube.com/channel/UC3NoEZFQ083fyZPeygAIYkw`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <YoutubeOutlined
                        style={{ color: "#2a2a2a", fontSize: "20px" }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      target="_blank"
                      href={` https://www.linkedin.com/`}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <LinkedinOutlined
                        style={{ color: "#2a2a2a", fontSize: "20px" }}
                      />
                    </a>
                  </Col>
                </Row>
                <Row
                  justify="center"
                  style={{
                    backgroundColor: "#2a2a2a",
                    marginTop: "10px",
                  }}
                >
                  <span
                    style={{
                      color: "#FFF",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    © Copyright HomesListedBy, Inc.
                  </span>
                </Row>
              </div>
            </Drawer>

            <div>
              <div
                className="bottom-nav"
                style={{
                  backgroundColor: footer?.menuTabs?.bgColor,
                  color: footer?.menuTabs?.textColor,
                }}
              >
                <BottomNavigation
                  items={bottomNavItems}
                  defaultSelected={
                    window.location.pathname === "/homes" ||
                    window.location.pathname === "/offMLSHomes"
                      ? 0
                      : window.location.pathname === "/buyers"
                      ? 1
                      : window.location.pathname === "/agent"
                      ? 2
                      : window.location.pathname === "/lenders"
                      ? 3
                      : window.location.pathname === "/marketplace"
                      ? 4
                      : window.location.pathname === "/about" ||
                        window.location.pathname === "/whyJoinUs" ||
                        window.location.pathname === "/contactUs"
                      ? 5
                      : "" || "/"
                      ? null
                      : 5
                  }
                  color={footer?.menuTabs?.textColor}
                  activeBgColor={footer?.menuTabs?.activebgColor}
                  // onItemClick={(item) => console.log(item)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withAppContext(withRouter(Navigation));
