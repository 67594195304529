import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import "antd/dist/antd.css";
import {
  Card,
  Row,
  Col,
  Popover,
  Divider,
  Space,
  Spin,
  message,
  notification,
  Button,
  Modal,
  Drawer,
  Skeleton,
} from "antd";
import {
  HeartOutlined,
  FacebookOutlined,
  WhatsAppOutlined,
  TwitterOutlined,
  ShareAltOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  LoadingOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Layout from "antd/lib/layout/layout";
import NumberFormat from "react-number-format";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import axios from "axios";
import { withAppContext } from "../../store/contextStore";
import Mortgage from "../../common/Mortgage";
import MapScore from "../../common/MapScore";
import {
  add,
  noAuthadd,
  noAuthlist,
  noAuthgetDetails,
  list,
  noAuthupdate,
} from "../../api/Apis";
import SendMessage from "../../common/SendMessage";
import Advertisement from "../../common/Advertisement";
import DetailCarsouel from "../../containers/HomeSearch/Detail/DetailCarsouel";
import MapComponent from "../../common/MapComponent";
import BuyerAgentProfile from "../../components/Card/BuyerAgentCard";
import QuestionAnswers from "../../common/QuestionAnswers";
import Schedule from "../../common/Schedule";
import ImageGallery from "../../common/ImageGallery";
import HomesCarousel from "../../common/HomesCarousel";
import Loading from "../../common/Loading";

import bedIcon from "../../images/awesome-bed-icon.png";
import profilePic from "../../images/profileAvatar.png";
import bathIcon from "../../images/awesome-bath-icon.png";
import reSize from "../../images/sq-feet-icon.png";
import SignupForm from "../../containers/RegisterForm";
import LoginForm from "../../containers/LoginForm";
import VedioLink from "../../images/vediolink.png";
import DegreeLink360 from "../../images/DegreeLink.png";
import DeleteRecord from "../../common/DeleteRecord";
export class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: "",
      longitude: "",
      country: "USA",
      enableSignupForm: false,
      agentsVisible: false,
      agentsList: [],
      contactAgentList: [],
      zipCode: "",
      loginUserInfo: "",
      validUser: false,

      /**** HomeSearchDetails2*****/
      properties: "",
      buyerHomeList: "",
      userProfile: "",
      homesData: [],
      similarHomesList: [],
      singleData: {},
      currentIndex: "",
      isPopup: false,
      beforeLoginLoading: false,
      favoritesFilterData: {},
      propertyDetailsId: "",
      setPropertyDetails: false,
      showSendMessagePopUp: false,
      openHouseReserve: false,
      setMlsVendor: "",
      questionAsked: "No",
      setSheduledTime: "No",
      filterAgents: [],
      favClickLoad: false,
    };
  }

  componentDidMount = () => {
    localStorage.setItem(
      "timeSpentonProperty",
      JSON.stringify(new Date().getTime())
    );
    this.init();
    this.getIpAddress();
    this.homeAnalytics("view");
  };

  handleTosaveBuyerLastActive = (email, title, mlsHomeId, userId) => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));

    let query = {};

    query["userId"] = userId;
    query["contactType"] = "Contact";
    query["emailAddress"] = email;

    noAuthlist("getMatchedVirtualOfficeContact", query).then((res) => {
      if (res.success) {
        if (res.data.length > 0) {
          let visitedPropertiesList = [];
          let isExists = false;
          let recordData = res.data[0];
          let listingViewdDate = moment(Date.now()).unix();
          let buyerRecordId = recordData["id"];
          let newPropertyObj = {
            lastVisited: moment(Date.now()).unix(),
            viewCount: 1,
            propertyId: mlsHomeId.toString(),
            userId: "",
            totalTimeSpentOnProperty: {
              Hours: 0,
              Days: 0,
              Min: 1,
            },
            propertyLink: window.location.origin + window.location.pathname,
            propertyTitle: title,
            contactId: buyerRecordId,
            setSheduledTime: "No",
            questionAsked: "No",
            isEmailPropertyVisited: true,
            orgId,
          };

          noAuthlist("propertyStatastics", {
            propertyId: newPropertyObj.propertyId,
            orgId,
          }).then((res) => {
            console.log(res, "stats", res.success, "ss", res.data);
            if (res.success) {
              if (res.data.length > 0) {
                isExists = true;
                let data = {
                  entityData: {
                    viewCount: res.data[0]["viewCount"] + 1,
                    lastVisited: moment(Date.now()).unix(),
                    isEmailPropertyVisited: true,
                  },
                };
                noAuthupdate("propertyStatastics", res.data[0]["id"], data)
                  .then((res) => console.log(res, "stats updated count"))
                  .catch((err) => console.log(err, "stats error"));
              } else {
                if (!isExists) {
                  visitedPropertiesList.push(newPropertyObj);
                }
                let payload = {
                  entityData: {
                    lastActiveDate: listingViewdDate,
                    visitedPropertiesList,
                    isVisitedContact: true,
                  },
                };
                noAuthupdate(
                  "virtualContactLeads",
                  buyerRecordId,
                  payload
                ).then((successResult) => {
                  if (successResult.success) {
                    return true;
                  }
                });
              }
            }
          });
        }
      }
    });
  };

  init = () => {
    this.setState({
      beforeLoginLoading: true,
      latitude: "",
    });

    this.getAllUsers();
    this.getLoginUserInfo();
    this.getAllRecords();
    this.getUserDetails(this.props?.match?.params?.id);

    const { isPopup, propertyId, stateType } = this.props;
    let propertyIdValue = "";
    let stateNameValue = "";

    if (isPopup && isPopup === true) {
      propertyIdValue = propertyId;
      stateNameValue = stateType;
      this.setState({
        isPopup: true,
      });
    } else {
      propertyIdValue = this.props.match.params.id;
      stateNameValue = this.props.match.params.stateName;
    }

    if (stateNameValue === "offMls") {
      const detailsObj = {
        isMls: false,
      };
      this.setState({
        favoritesFilterData: detailsObj,
        propertyDetailsId: propertyIdValue,
        propertyMlsType: "offMls",
      });
      this.handlePropertyDetails(propertyIdValue, detailsObj);
    } else {
      const stateCodes = JSON.parse(
        localStorage.getItem("stateCodesFromAdmin")
      );

      if (stateCodes && stateCodes.length > 0) {
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() ===
              stateNameValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateNameValue.toLowerCase()
        );
        let vendor = setVendor && setVendor[0].vendorType;
        const detailsObj = {
          isMls: true,
          vendor: vendor,
        };
        this.setState({
          favoritesFilterData: detailsObj,
          propertyDetailsId: propertyIdValue,
          propertyMlsType: "mls",
          setMlsVendor: vendor,
        });
        this.handlePropertyDetails(propertyIdValue, detailsObj, "mls");
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        if (userLogin) {
          this.getStatsOfProperty();
        }
      }
    }
  };

  getStatsOfProperty = () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.id !== "") {
      list("propertyStatastics", {
        propertyId: this.props.match.params.id,
        userId: userDetails.id,
      }).then((recordResults) => {
        if (recordResults.success) {
          if (recordResults.data.length > 0) {
            this.setState({
              questionAsked: recordResults.data[0].questionAsked,
              setSheduledTime: recordResults.data[0].setSheduledTime,
            });
          }
        }
      });
    }
  };

  getIpAddress = () => {
    axios
      .get(process.env.REACT_APP_IPADDRESS)
      .then((result) => {
        this.addIpAddress(result.data);
      })
      .catch((error) => console.log("Error :", error));
  };

  addIpAddress = async (data) => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    data["orgId"] = orgId;
    const payload = {
      entityData: data,
    };
    try {
      await noAuthadd("ipAddressAnalytics", payload);
    } catch (error) {
      console.log("Error :", error);
    }
  };

  homeAnalytics = async (type, isFav) => {
    let listingId = this.props.match.params.id;
    const viewData = {
      entityData: {
        listingId: listingId,
        listingViews: true,
      },
    };
    const favData = {
      entityData: {
        listingId: listingId,
        listingFavorites: isFav ? true : false,
      },
    };
    const postData = type === "view" ? viewData : favData;
    try {
      await noAuthadd("homesAnalytics", postData);
    } catch (error) {
      console.log("Error :", error);
    }
  };

  getAllRecords = async () => {
    this.setState({ currentIndex: "" });
    let filterData = JSON.parse(localStorage.getItem("filterSearchData"));
    if (
      window.location.pathname !==
        `/buyersHome/${this.props.match.params.id}` &&
      this.props.location &&
      this.props.location.state &&
      !this.props.location.state.agentPage
    ) {
      filterData["isActive"] = true;
      const HomesListData = await noAuthlist("getHomesList", filterData);
      try {
        if (HomesListData.success) {
          this.setState({
            homesData: HomesListData.data,
          });
          this.getCurrentIndex(HomesListData.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  getCurrentIndex = (totalRecords) => {
    let currentIndexValue = totalRecords.findIndex(
      (item) =>
        String(item.openHouseKey ? item.openHouseKey : item.id) ===
        this.props.match.params.id
    );
    this.setState({
      currentIndex: currentIndexValue,
      beforeLoginLoading: false,
    });
  };

  handleNext = async (pageType) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.userProfile === "B" || userDetails?.userProfile === "S") {
      this.savePropertyStatastics();
    }
    const { currentIndex, homesData } = this.state;
    let currentIndexValue = currentIndex;
    if (pageType === "Next") {
      currentIndexValue = currentIndex + 1;
    } else {
      currentIndexValue = currentIndex - 1;
    }
    this.setState({
      currentIndex: currentIndexValue,
      showSendMessagePopUp: false,
    });

    let totalRecords = homesData;

    let requiredItem = totalRecords[currentIndexValue];
    this.props.history.push(
      `/homeDetails/${requiredItem.stateName}/${
        requiredItem.openHouseKey ? requiredItem.openHouseKey : requiredItem.id
      }`,
      true
    );
    this.setState({
      beforeLoginLoading: true,
    });
    setTimeout(() => {
      this.setState({
        loginUserInfo: "",
      });
      this.init();
    }, 2000);
  };

  handlePropertyDetails = async (propertyId, detailsObj, mlsType) => {
    try {
      let userLogin = localStorage.getItem("userLogin");
      let response = {};
      if (userLogin) {
        const authResults = await list(
          `getPropertyDetails/${propertyId}?`,
          detailsObj
        );
        if (authResults.success) {
          response = authResults;
        }
      } else {
        const noAuthResults = await noAuthlist(
          `getPropertyDetails/${propertyId}?`,
          detailsObj
        );
        if (noAuthResults.success) {
          response = noAuthResults;
        }
      }

      if (response.success && mlsType !== "mls") {
        const photos = [];
        for (let o of response.data &&
          response.data.homePhotos &&
          response.data.homePhotos) {
          let newImage2 =
            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            o;
          photos.push(newImage2);
        }
        response.data["photos"] = photos;
        this.setState({
          singleData: response.data,
          beforeLoginLoading: false,
          setPropertyDetails: true,
        });
        setTimeout(() => {
          response.data &&
            response.data.address &&
            response.data.address[0] &&
            this.getFormattedAddress(
              response.data &&
                response.data.address[0] &&
                response.data.address[0]
            );
        }, 5000);
        setTimeout(() => {}, 2000);
      } else {
        if (response.success) {
          response.data["photos"] =
            response.data &&
            response.data.homePhotos &&
            response.data.homePhotos.length > 0
              ? response.data.homePhotos
              : [];
          this.setState({
            singleData: response.data,
            beforeLoginLoading: false,
            setPropertyDetails: true,
          });

          setTimeout(() => {
            response.data &&
              response.data.title &&
              this.getFormattedAddress(response.data.title);
          }, 5000);
          setTimeout(() => {}, 2000);

          // VIRTUAL OFFICE CONTACT LISTING --> SAVE LAST ACTIVE DATE AND TIME

          let search = window.location.search;
          let params = new URLSearchParams(search);
          let buyerEmailStats = params.get("buyerEmail");
          let userId = params.get("userId");
          buyerEmailStats = decodeURIComponent(buyerEmailStats);
          console.log(buyerEmailStats,"buyerEmailStats",typeof(buyerEmailStats));
          if (buyerEmailStats !== "" && buyerEmailStats !== "null" && typeof buyerEmailStats === "string") {
            this.handleTosaveBuyerLastActive(
              buyerEmailStats,
              response.data.title,
              response.data.homeId,
              userId
            );
          }
        }
      }
      if (response.success) {
        if (!response.data.favorites) {
          this.clearNotificationApi = setInterval(() => {
            // notification["info"]({
            //   message:
            //     "Favorite this property to get alerts when there is a status change. Click on the heart symbol below the listing pictures.",
            //   // duration:4.5
            // });
          }, 60000);
          setInterval(() => {
            clearInterval(this.clearNotificationApi);
          }, 70000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() {
    clearInterval(this.clearNotificationApi);
  }

  getUserDetails = async (id) => {
    const UserInfo = await noAuthgetDetails("getAgents", id);
    if (UserInfo.success) {
      this.setState({
        userProfile: UserInfo.data,
      });
    }
  };

  savePropertyStatastics = async (sheduledTime, questionary) => {
    let { singleData, questionAsked, setSheduledTime } = this.state;
    let userLoginTime = localStorage.getItem("timeSpentonProperty");
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let endTimeSpentOnProperty = new Date().getTime();

    let diffMs = endTimeSpentOnProperty - userLoginTime; // milliseconds between now & login time
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    let totalTime = { Days: diffDays, Hours: diffHrs, Min: diffMins };
    let newUpdateOnquestionAsked = questionAsked;
    let newUpdateOnsetSheduledTime = setSheduledTime;
    if (questionary) {
      newUpdateOnquestionAsked = "Yes";
    }
    if (sheduledTime) {
      newUpdateOnsetSheduledTime = "Yes";
    }

    let data = {
      entityData: {
        propertyId: this.props.match.params.id,
        userId: userDetails?.id,
        propertyLink: window.location.href,
        propertyTitle: singleData?.title,
        totalTimeSpentOnProperty: totalTime,
        lastVisited: moment(Date.now()).unix(),
        questionAsked: newUpdateOnquestionAsked,
        setSheduledTime: newUpdateOnsetSheduledTime,
        orgId,
      },
    };
    add("propertyStatastics", data)
      .then((res) => {
        this.getStatsOfProperty();
      })
      .catch((error) => console.log(error));
  };

  handleBack = () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.userProfile === "B" || userDetails?.userProfile === "S") {
      this.savePropertyStatastics();
    }
    if (
      window.location.pathname.includes("/buyersHome") &&
      this.props.location.state === true
    ) {
      this.props.history.push("/buyers");
    } else {
      if (this.props.location.state && this.props.location.state.favorited) {
        this.props.history.goBack();
      } else if (
        (this.props.location.state && this.props.location.state.agentPage) ||
        this.props.location.state === false
      ) {
        this.props.history.goBack();
      } else {
        if (this.props.match.params.stateName === "offMls") {
          this.props.history.push("/offMLSHomes");
        } else {
          this.props.history.push("/homes");
        }
      }
    }
  };

  getFormattedAddress = async (address) => {
    await geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        this.setState({
          latitude: lat,
          longitude: lng,
          beforeLoginLoading: false,
        })
      );
  };

  getLoginUserInfo = async () => {
    const loginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserDetails && loginUserDetails.id) {
      const loginUserId = loginUserDetails.id;
      const loginUserName =
        loginUserDetails.firstName + " " + loginUserDetails.lastName;
      const userInfo = {
        userId: loginUserId,
        userName: loginUserName,
        userProfile: loginUserDetails.userProfile,
        preferedAgent:
          loginUserDetails.agentInfo && loginUserDetails?.agentInfo.agentEmail,
        prefereAgentName:
          loginUserDetails.agentInfo && loginUserDetails?.agentInfo.agentName,
        preferedId:
          "agentInfo" in loginUserDetails === true &&
          "assignAgentId" in loginUserDetails.agentInfo === true
            ? loginUserDetails.agentInfo?.assignAgentId
            : "",
      };

      this.setState({
        loginUserInfo: userInfo,
        showSendMessagePopUp: true,
      });
      if (this.props && this.props.singleData && this.props.singleData.userId) {
        if (loginUserId === this.props.singleData.userId) {
          this.setState({
            validUser: true,
          });
        }
      }
    } else {
      this.setState({
        showSendMessagePopUp: true,
      });
    }
  };

  getAllUsers = async () => {
    try {
      const allUsers = await noAuthlist("getUsers", { isActive: true });
      // let filteredClientAgent =
      //   allUsers.data.length > 0
      //     ? allUsers.data.filter(
      //         (agent) =>
      //           agent.email === process.env.REACT_APP_CLIENT_AGENT_EMAIL
      //       )
      //     : [];
      // const clientAgentsDetails = [];
      // if (filteredClientAgent) {
      //   for (let singleItem of filteredClientAgent) {
      //     let newImage = "";
      //     if (singleItem.profilePicture) {
      //       let newImage2 =
      //         `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
      //         singleItem.profilePicture[0];
      //       newImage = newImage2;
      //       singleItem["AgnetProfileUrl"] = newImage;
      //       clientAgentsDetails.push({ ...singleItem });
      //     } else {
      //       singleItem["AgnetProfileUrl"] = "";
      //       clientAgentsDetails.push({ ...singleItem });
      //     }
      //   }

      //   this.setState({
      //     contactAgentList: clientAgentsDetails,
      //   });
      // }

      // const allFilterAgents = allUsers.data.filter(
      //   (o) =>
      //     o.subscriptionPack &&
      //     o.subscriptionPack &&
      //     o.subscriptionPack.premierStatus === "Y" &&
      //     o.email !== process.env.REACT_APP_CLIENT_AGENT_EMAIL
      // );

      // const filterAgents = allFilterAgents.sort((a, b) =>
      //   parseInt(a.subscriptionPack.price) > parseInt(b.subscriptionPack.price)
      //     ? -1
      //     : 1
      // );

      // const filterAgents = allUsers.data.filter(
      //   (o) =>
      //     (o.markAsManager && o.markAsManager) ||
      //     o.userProfile === "concierge" ||
      //     ("showAllZipCodes" in o === true && o.showAllZipCodes)
      // );
      const AgentsDetails = [];
      let filterAgents = [];
      allUsers.data.length >= 1 &&
        allUsers.data.forEach((singleItem) => {
          let newImage = "";
          if (
            !singleItem.markAsManager &&
            singleItem.userProfile !== "concierge" &&
            !singleItem.showAllZipCodes
          ) {
            if (singleItem.profilePicture) {
              let newImage2 =
                `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                singleItem.profilePicture[0];
              newImage = newImage2;
              singleItem["AgnetProfileUrl"] = newImage;
              AgentsDetails.push({ ...singleItem });
            } else {
              singleItem["AgnetProfileUrl"] = "";
              AgentsDetails.push({ ...singleItem });
            }
          } else {
            if (singleItem.profilePicture) {
              let newImage2 =
                `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                singleItem.profilePicture[0];
              newImage = newImage2;
              singleItem["AgnetProfileUrl"] = newImage;
              filterAgents.push({ ...singleItem });
            } else {
              singleItem["AgnetProfileUrl"] = "";
              filterAgents.push({ ...singleItem });
            }
          }
        });
      this.setState({ agentsList: AgentsDetails, filterAgents });
    } catch (error) {
      alert("Something went wrong...");
    }
  };

  handleFavorites = async (singleData) => {
    this.setState({
      favClickLoad: true,
    });
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let userLogin = localStorage.getItem("userLogin");
    if (userLogin) {
      const data = Object.assign(
        {},
        {
          entityData: {
            userId: userDetails.id,
            orgId: orgId,
            leadId: singleData && singleData.homeId,
            data: singleData,

            mls: this.props.match.params.stateName === "offMls" ? false : true,
            date:
              singleData && singleData.createdAt
                ? moment(singleData.createdAt).format("MMM Do YYYY")
                : "",
            stateType:
              this.props &&
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.stateName &&
              this.props.match.params.stateName,
            price:
              singleData && singleData.OriginalListprice
                ? singleData.OriginalListprice
                : singleData.minPrice !== null
                ? singleData.minPrice
                : 0,

            leadStatus: singleData && singleData.status,
          },
        }
      );
      try {
        const postFavorites = await add("userFavorites", data);
        if (postFavorites.success) {
          this.setState({
            favClickLoad: false,
          });
          message.success(
            <>
              <span>
                Listing added to favorites.<br></br>Click on the heart symbol in
                the top menu to see your favorite listings
              </span>
            </>
          );
          this.handlePropertyDetails(
            this.state.propertyDetailsId,
            this.state.favoritesFilterData,
            this.state.propertyMlsType
          );

          this.homeAnalytics("fav", true);
        }
      } catch (error) {
        alert("Something went wrong....");
      }
    } else {
      this.openNotification();
    }
  };

  handleDetailsAfterDeleteFav = async () => {
    await this.setState({
      deleteFavoriteData: [],
      dataLoading: false,
    });
    this.handlePropertyDetails(
      this.state.propertyDetailsId,
      this.state.favoritesFilterData,
      this.state.propertyMlsType
    );
    this.homeAnalytics("fav", false);
  };

  shareThisHome = (e) => {
    let stateName = this.props.match.params.stateName;
    let shareUrl = `${process.env.REACT_APP_API_GATEWAY}/noAuth/getHomes/${e}/${stateName}?website=${window.location.hostname}`;
    this.setState({
      shareUrl,
    });
  };

  openNotification = () => {
    notification.info({
      placement: "topLeft",
      message:
        "Please create your free account today to save your favorite listings and be notified of new listings",
      style: {
        fontWeight: "500",
      },
    });
    this.setState({
      enableSignupForm: true,
    });
  };

  handleonCancel = () => {
    this.setState({
      enableSignupForm: false,
    });
  };

  handleToshowLoginSider = () => {
    this.setState({
      showLoginSiderForm: true,
    });
  };

  handletoShowSignupsider = () => {
    this.setState({
      showLoginSiderForm: false,
    });
  };

  handleReset = () => {
    this.setState({
      enableSignupForm: false,
    });
  };
  handle_to_copy_property_url = () => {
    notification["success"]({
      message: "Link copied to clipboard",
    });
  };

  handleDeleteLoad = (resultsType) => {
    this.setState({
      favClickLoad: resultsType,
    });
  };

  render() {
    const {
      agentsList,
      contactAgentList,
      loginUserInfo,
      agentsVisible,
      showLoginSiderForm,
      userProfile,
      latitude,
      longitude,
      dataLoading,
      homesData,
      singleData,
      beforeLoginLoading,
      isPopup,
      showSendMessagePopUp,
      filterAgents,
    } = this.state;
    let paramsState =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.stateName;
    let card_content = [];
    singleData &&
      card_content.push(
        singleData && singleData.OriginalListprice
          ? {
              name: "List Price",
              value: singleData && singleData.OriginalListprice && (
                <NumberFormat
                  value={parseFloat(singleData.OriginalListprice).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              ),
            }
          : {
              name: "Price Range",
              value: (
                <div>
                  <NumberFormat
                    value={singleData.minPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />{" "}
                  -{" "}
                  <NumberFormat
                    value={singleData.maxPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </div>
              ),
            },
        {
          name: "Refered Agent",
          value:
            singleData &&
            singleData.referedAgentName &&
            singleData.referedAgentName &&
            singleData.referedAgentName,
        },
        {
          name: "Features",
          value:
            singleData && singleData.Features && singleData.Features !== false
              ? singleData.Features
              : "",
        },
        {
          name: "Living area",
          value: `${singleData.area ? singleData.area : "--"}`,
        },
        { name: "Beds", value: singleData.beds },
        { name: "Bath", value: singleData.baths },
        { name: "Half bath", value: singleData.halfBaths },
        { name: "Rooms", value: singleData.rooms },
        { name: "Style", value: singleData.HomeStyle },
        { name: "Cooling", value: singleData.colling },
        {
          name: "Heating",
          value:
            singleData && singleData.heating !== "" ? singleData.heating : "",
        },
        {
          name: "Fireplaces",
          value:
            singleData &&
            singleData.fireplaces &&
            singleData.fireplaces !== "" &&
            singleData.fireplaces >= 1
              ? "Yes"
              : "No",
        },
        { name: "Flooring", value: singleData.flooring },
        {
          name: "Interior Features",
          value:
            singleData && singleData.interiorFeatures !== ""
              ? singleData.interiorFeatures
              : "",
        },
        {
          name: "Lot Description",
          value: singleData.lotDescription,
        },
        { name: "Year Built", value: singleData.YearBuilt },
        {
          name: "Exterior",
          value:
            singleData && singleData.Exterior !== "" ? singleData.Exterior : "",
        },
        {
          name: "Parking Spaces",
          value: singleData.ParkingSpaces,
        },
        {
          name: "Garage Spaces",
          value: singleData.GarageSpaces,
        },
        {
          name: "Lot size",
          value:
            singleData && singleData.lotSize && singleData.lotSize.length > 0
              ? singleData.lotSize
              : "",
        },
        {
          name: "Basement",
          value:
            singleData && singleData.basement !== "" ? singleData.basement : "",
        },
        { name: "Condition", value: singleData.condition },
        { name: "Dwelling", value: singleData.dwelling },
        {
          name: "Utilities",
          value:
            singleData && singleData.utilities !== ""
              ? singleData.utilities
              : "",
        },
        {
          name: "Property Type",
          value: singleData.PropertyType,
        }
      );

    const content = (
      <div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            onClick={() => {
              window.FB.ui(
                {
                  display: "popup",
                  method: "share",
                  href: this.state.shareUrl,
                },
                function (response) {}
              );
            }}
            href={() => false}
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <FacebookOutlined style={{ fontSize: 18 }} />
            &nbsp;&nbsp; facebook
          </a>
        </div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://api.whatsapp.com/send?text= ${this.state.shareUrl}`}
            style={{ textDecoration: "none" }}
          >
            <WhatsAppOutlined style={{ fontSize: 18 }} />
            &nbsp;&nbsp; Whatsapp
          </a>
        </div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            href={`https://twitter.com/intent/tweet?text=${this.state.shareUrl}`}
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <TwitterOutlined style={{ fontSize: 18 }} /> &nbsp;&nbsp; Twitter
          </a>
        </div>
        <div className="share-to-social-media">
          <CopyToClipboard
            text={this.state.shareUrl}
            onCopy={this.handle_to_copy_property_url}
          >
            <span style={{ color: "#1890ff" }}>
              <CopyOutlined />
              &nbsp;&nbsp;&nbsp;Copy URL
            </span>
          </CopyToClipboard>
        </div>
      </div>
    );

    const userDetails = {
      homeAgentName:
        userProfile && userProfile.firstName + " " + userProfile.lastName,
      profileURL:
        userProfile && userProfile.profilePicture
          ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            userProfile.profilePicture[0]
          : "",
      userId: userProfile && userProfile.id,
      mlsId: userProfile && userProfile.mlsId,
      agentEmail: userProfile && userProfile.email,
      companyName:
        userProfile && userProfile.newdata && userProfile.newdata.companyName,
      userType: userProfile && userProfile.userProfile,
    };
    let details_data = [];

    details_data.push(
      {
        name: "Date and Time Added",
        value: moment(singleData && singleData.createdAt).format(
          "D  MMMM, YYYY"
        ),
      },
      {
        name: singleData && singleData.mlsData && "MLS ID",
        value:
          singleData && singleData.mlsData && singleData.homeId
            ? singleData.homeId
            : " ",
      },

      {
        name: "Status",
        value:
          singleData &&
          singleData.status &&
          typeof singleData.status === "object"
            ? singleData.status.includes("pending") &&
              singleData.status.includes("activeundercontract")
              ? "Under Agreement"
              : singleData.status.includes("comingsoon")
              ? "Coming Soon"
              : singleData.status.includes("active")
              ? "New Listing"
              : singleData.status
            : singleData.status,
      },
      { name: "Area", value: "" },
      {
        name: "Year Built",
        value: singleData && singleData.YearBuilt,
      },
      {
        name: "Cooperating Commission",
        value:
          singleData &&
          singleData.corporationCommission &&
          singleData.corporationCommission + "%",
      }
    );
    let userLogin = localStorage.getItem("userLogin");

    let speakToBuyerAgentsList = [];
    agentsList.length >= 1 &&
      agentsList.forEach((zip) => {
        if ("newdata" in zip) {
          if ("agentTowns" in zip.newdata) {
            let zipList =
              zip.newdata.agentTowns.length >= 1 &&
              zip.newdata.agentTowns.filter(
                (z) => z.zipCode === singleData.zipCode
              );
            if (zipList.length > 0) {
              speakToBuyerAgentsList.push(zip);
            }
          }
        }
      });
    return (
      <>
        {beforeLoginLoading ? (
          <Loading enableLoading={beforeLoginLoading} />
        ) : (
          <>
            <div>
              <Layout style={{ marginTop: "1vw" }}>
                <Row>
                  {isPopup !== true && (
                    <Col
                      span={17}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                      className="margin-body-agent"
                    >
                      <Button
                        type="text"
                        icon={<ArrowLeftOutlined />}
                        className="font-family-style font-medium-size"
                        onClick={this.handleBack}
                      >
                        {this.props.location.state &&
                        this.props.location.state.favorited &&
                        this.props.location.state.favorited
                          ? "Back to favorites"
                          : this.props.location.state &&
                            this.props.location.state.agentPage &&
                            this.props.location.state.agentPage
                          ? "Back to agent search"
                          : this.props.location.state === false
                          ? "Go Back"
                          : "Return to search result"}
                      </Button>
                    </Col>
                  )}

                  {/* DON'T DELETE     */}

                  {/* {this.state.recordNotFound && (
                    <Col span={24} style={{ alignContent: "center" }}>
                      <h2
                        className="font-family-style font-medium-size"
                        style={{ padding: "3%" }}
                      >
                        This listing is no longer available.
                      </h2>
                    </Col>
                  )} */}

                  {this.state.setPropertyDetails && (
                    <Col span={24}>
                      <div>
                        <Layout className="margin-body-agent">
                          <Row>
                            {this.state.currentIndex !== "" &&
                              this.props.location.state &&
                              this.props.location.state === true &&
                              this.props.location.state !== undefined && (
                                <Col
                                  xl={{ span: 24, offset: 0 }}
                                  lg={{ span: 24, offset: 0 }}
                                  sm={{ span: 22, offset: 1 }}
                                  xs={{ span: 22, offset: 1 }}
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {isPopup !== true && (
                                    <Row justify="space-between">
                                      <Col>
                                        {this.state.currentIndex !== 0 && (
                                          <Button
                                            type="primary"
                                            className="font-family-style font-medium-size"
                                            onClick={() =>
                                              this.handleNext("Previous")
                                            }
                                          >
                                            <Row>
                                              <Col>
                                                <ArrowLeftOutlined />
                                              </Col>
                                              <Col className="Next-Search-font">
                                                Prev
                                              </Col>
                                            </Row>
                                          </Button>
                                        )}
                                      </Col>

                                      <Col className="margin-body-agent-right">
                                        {homesData &&
                                        homesData.length ===
                                          this.state.currentIndex + 1 ? (
                                          ""
                                        ) : (
                                          <Button
                                            type="primary"
                                            className="font-family-style font-medium-size"
                                            onClick={() =>
                                              this.handleNext("Next")
                                            }
                                            style={{
                                              float: "right",
                                            }}
                                          >
                                            <Row>
                                              <Col className="Next-Search-font">
                                                Next
                                              </Col>
                                              <Col>
                                                <ArrowRightOutlined />
                                              </Col>
                                            </Row>
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              )}

                            <Col
                              xl={{
                                span: isPopup !== true ? 17 : 24,
                                offset: 0,
                              }}
                              lg={{
                                span: isPopup !== true ? 17 : 24,
                                offset: 0,
                              }}
                              md={{
                                span: isPopup !== true ? 22 : 24,
                                offset: 1,
                              }}
                              sm={{
                                span: isPopup !== true ? 22 : 24,
                                offset: 1,
                              }}
                              xs={{
                                span: isPopup !== true ? 22 : 24,
                                offset: 1,
                              }}
                            >
                              {singleData && (
                                <Row
                                  justify="end"
                                  style={{
                                    marginBottom:
                                      singleData.photos &&
                                      singleData.photos.length > 0
                                        ? "-40px"
                                        : "0px",
                                  }}
                                >
                                  <div>
                                    {singleData &&
                                    // singleData &&
                                    singleData.photos &&
                                    singleData.photos.length > 0 ? (
                                      <ImageGallery
                                        photos={singleData && singleData.photos}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </Row>
                              )}

                              <Row className="row-padding">
                                <Card
                                  cover={
                                    singleData && (
                                      <DetailCarsouel
                                        photos={singleData && singleData.photos}
                                        favorited={
                                          singleData && singleData.favorites
                                        }
                                      />
                                    )
                                  }
                                >
                                  <div className="homeSearch">
                                    <Layout
                                      style={{ backgroundColor: "white" }}
                                    >
                                      <Row>
                                        <Col
                                          xl={{ span: 16 }}
                                          lg={{ span: 16 }}
                                          md={{ span: 18 }}
                                          sm={{ span: 18 }}
                                          xs={{ span: 18 }}
                                        ></Col>
                                        <Col
                                          xl={{ span: 4, offset: 4 }}
                                          lg={{ span: 5, offset: 3 }}
                                          md={{ span: 5, offset: 1 }}
                                          sm={{ span: 5, offset: 1 }}
                                          xs={{ span: 5, offset: 1 }}
                                        >
                                          <div className="row">
                                            {singleData &&
                                              singleData.vedioLink && (
                                                <div
                                                  className="column all center-item"
                                                  onClick={singleData.vedioLink}
                                                >
                                                  <Popover
                                                    content={content}
                                                    trigger="click"
                                                  >
                                                    <div
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <a
                                                        href={
                                                          singleData &&
                                                          singleData.vedioLink &&
                                                          singleData.vedioLink
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        <img
                                                          src={VedioLink}
                                                          className="vedioLink"
                                                          alt=""
                                                        />
                                                      </a>
                                                    </div>
                                                  </Popover>
                                                </div>
                                              )}

                                            {singleData &&
                                              singleData.DgressLink360 && (
                                                <div
                                                  className="column all center-item"
                                                  onClick={
                                                    singleData.DgressLink360
                                                  }
                                                >
                                                  <Popover
                                                    content={content}
                                                    trigger="click"
                                                  >
                                                    <div
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <a
                                                        href={
                                                          singleData &&
                                                          singleData.DgressLink360 &&
                                                          singleData.DgressLink360
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        <img
                                                          src={DegreeLink360}
                                                          className="vedioLink"
                                                          alt=""
                                                        />
                                                      </a>
                                                    </div>
                                                  </Popover>
                                                </div>
                                              )}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col
                                          xl={{ span: 16 }}
                                          lg={{ span: 16 }}
                                          md={{ span: 18 }}
                                          sm={{ span: 18 }}
                                          xs={{ span: 18 }}
                                        >
                                          <Skeleton
                                            title={false}
                                            loading={dataLoading}
                                            active
                                          >
                                            <p className="searchHomes-titles">
                                              {singleData && singleData.title}
                                            </p>
                                          </Skeleton>
                                        </Col>
                                        <Col
                                          xl={{ span: 4, offset: 4 }}
                                          lg={{ span: 5, offset: 3 }}
                                          md={{ span: 5, offset: 1 }}
                                          sm={{ span: 5, offset: 1 }}
                                          xs={{ span: 5, offset: 1 }}
                                        >
                                          <div className="row">
                                            {singleData && (
                                              <div
                                                className="column all center-item"
                                                onClick={() =>
                                                  this.shareThisHome(
                                                    singleData &&
                                                      singleData.homeId &&
                                                      singleData.homeId
                                                  )
                                                }
                                              >
                                                <Popover
                                                  content={content}
                                                  title="Share this Home"
                                                  trigger="click"
                                                >
                                                  <div
                                                    style={{
                                                      marginLeft: "2px",
                                                      marginTop: "4px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <ShareAltOutlined className="share" />
                                                  </div>
                                                </Popover>
                                              </div>
                                            )}
                                            {singleData &&
                                            userLogin &&
                                            singleData.favorites ? (
                                              <div className="column all center-item">
                                                {this.state.favClickLoad ||
                                                dataLoading ? (
                                                  <LoadingOutlined />
                                                ) : (
                                                  <DeleteRecord
                                                    recordId={
                                                      singleData.favorites
                                                    }
                                                    apiPath={"userFavorites"}
                                                    getHomesList={
                                                      this
                                                        .handleDetailsAfterDeleteFav
                                                    }
                                                    deletionType={"HeartFilled"}
                                                    deletionSuccessMessage={
                                                      "Removed from favorites list"
                                                    }
                                                    deleteLoadingMethod={
                                                      this.handleDeleteLoad
                                                    }
                                                  />
                                                )}
                                              </div>
                                            ) : (
                                              singleData && (
                                                <div className="column all center-item">
                                                  {this.state.favClickLoad ||
                                                  dataLoading ? (
                                                    <LoadingOutlined />
                                                  ) : (
                                                    <HeartOutlined
                                                      className="heart"
                                                      onClick={() =>
                                                        userLogin
                                                          ? this.handleFavorites(
                                                              singleData
                                                            )
                                                          : this.openNotification()
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                      {singleData &&
                                      singleData.makePrivateAddress ? (
                                        <>
                                          {singleData &&
                                            singleData.homeAddress && (
                                              <Row>
                                                <Col span={24}>
                                                  <Skeleton
                                                    title={false}
                                                    loading={dataLoading}
                                                    active
                                                  >
                                                    <p className="searchHomes-titles">
                                                      {singleData &&
                                                        singleData.homeAddress}
                                                    </p>
                                                  </Skeleton>
                                                </Col>
                                              </Row>
                                            )}
                                        </>
                                      ) : (
                                        <>
                                          {singleData && singleData.address && (
                                            <Row>
                                              <Col span={24}>
                                                <Skeleton
                                                  title={false}
                                                  loading={dataLoading}
                                                  active
                                                >
                                                  <p className="searchHomes-titles">
                                                    {singleData &&
                                                      singleData.address}
                                                  </p>
                                                </Skeleton>
                                              </Col>
                                            </Row>
                                          )}
                                        </>
                                      )}
                                      {singleData && singleData.Labels && (
                                        <Row>
                                          <Col span={24}>
                                            <Skeleton
                                              title={false}
                                              loading={dataLoading}
                                              active
                                            >
                                              <p className="font-family-style font-medium-size text-size2 color-grey">
                                                {singleData &&
                                                  singleData.Labels}
                                              </p>
                                            </Skeleton>
                                          </Col>
                                        </Row>
                                      )}
                                      <Row>
                                        <Skeleton
                                          title={false}
                                          loading={dataLoading}
                                          active
                                        >
                                          <p className="font-family-style font-medium-size text-size2 color-grey">
                                            {singleData &&
                                            singleData.OriginalListprice &&
                                            singleData.OriginalListprice ? (
                                              <Row>
                                                <NumberFormat
                                                  value={parseFloat(
                                                    singleData &&
                                                      singleData.OriginalListprice
                                                  ).toFixed(2)}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"$"}
                                                />
                                              </Row>
                                            ) : (
                                              <Row>
                                                <NumberFormat
                                                  value={parseFloat(
                                                    singleData &&
                                                      singleData.minPrice
                                                  ).toFixed(2)}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"$"}
                                                />{" "}
                                                -
                                                <NumberFormat
                                                  value={parseFloat(
                                                    singleData &&
                                                      singleData.maxPrice
                                                  ).toFixed(2)}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"$"}
                                                />{" "}
                                              </Row>
                                            )}
                                          </p>
                                        </Skeleton>
                                      </Row>
                                      <Row>
                                        <Col style={{ marginRight: "20px" }}>
                                          <img
                                            src={bedIcon}
                                            alt="space"
                                            className="beds-img"
                                          />
                                          <span className="searchHomes-subtitles">
                                            {singleData && singleData.beds
                                              ? singleData.beds
                                              : 0}{" "}
                                            Beds
                                          </span>
                                        </Col>
                                        <Col style={{ marginRight: "20px" }}>
                                          <img
                                            src={bathIcon}
                                            alt="space"
                                            className="beds-img"
                                          />
                                          <span className="searchHomes-subtitles">
                                            {singleData && singleData.baths
                                              ? singleData.baths
                                              : 0}{" "}
                                            Baths
                                          </span>
                                        </Col>
                                        <Col
                                          className="details-area-home"
                                          style={{ marginRight: "20px" }}
                                        >
                                          <img
                                            src={reSize}
                                            alt="space"
                                            className="beds-img"
                                          />
                                          <Skeleton
                                            title={false}
                                            loading={dataLoading}
                                            active
                                            className="searchHomes-subtitles"
                                          >
                                            <span className="searchHomes-subtitles">
                                              {singleData &&
                                              (singleData.area === null ||
                                                singleData.area === undefined ||
                                                singleData.area >= 99999)
                                                ? "NA"
                                                : `${
                                                    singleData &&
                                                    singleData.area
                                                  }`}
                                            </span>
                                          </Skeleton>
                                        </Col>
                                        <Col>
                                          <EyeOutlined
                                            className="searchHomes-subtitles"
                                            style={{ fontSize: "17px" }}
                                          />
                                          <span className="searchHomes-subtitles">
                                            {singleData.homeAnalyticsData
                                              .length &&
                                            singleData.homeAnalyticsData[0]
                                              .listingViews
                                              ? singleData.homeAnalyticsData[0]
                                                  .listingViews
                                              : 0}{" "}
                                            Views
                                          </span>
                                        </Col>
                                      </Row>
                                    </Layout>
                                  </div>
                                  <Divider
                                    style={{
                                      margin: "1%",
                                      marginBottom: "20px",
                                    }}
                                  ></Divider>
                                  <Row>
                                    <Space
                                      size="large"
                                      className="font-family-style font-medium-size size1"
                                    >
                                      <a href="#description">Description</a>
                                      {singleData && (
                                        <a href="#property">Property Details</a>
                                      )}
                                      {singleData && (
                                        <>
                                          <a href="#schedule">
                                            Schedule a Showing
                                          </a>
                                          <a href="#questions">Q&A</a>
                                          <a href="#map">Map</a>
                                        </>
                                      )}

                                      {singleData &&
                                        window.screen.width >= 500 && (
                                          <>
                                            {((singleData &&
                                              singleData.OriginalListprice &&
                                              singleData.OriginalListprice !==
                                                "") ||
                                              (singleData &&
                                                singleData.maxPrice &&
                                                singleData.maxPrice !==
                                                  "")) && (
                                              <div className="desktop-display-detail">
                                                <Mortgage
                                                  homePrice={
                                                    singleData &&
                                                    singleData.OriginalListprice &&
                                                    singleData.OriginalListprice !==
                                                      ""
                                                      ? singleData.OriginalListprice
                                                      : singleData &&
                                                        singleData.maxPrice &&
                                                        singleData.maxPrice !==
                                                          ""
                                                      ? singleData.maxPrice
                                                      : ""
                                                  }
                                                  cityName={
                                                    singleData &&
                                                    singleData.city
                                                  }
                                                  postalCode={
                                                    singleData &&
                                                    singleData.zipCode
                                                  }
                                                  stateName={
                                                    singleData &&
                                                    singleData.state
                                                  }
                                                  detailsPageAds={true}
                                                />
                                              </div>
                                            )}
                                          </>
                                        )}
                                    </Space>
                                  </Row>

                                  {singleData && (
                                    <Row
                                      style={{
                                        marginTop: "10px",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {((singleData &&
                                        singleData.OriginalListprice &&
                                        singleData.OriginalListprice !== "") ||
                                        (singleData &&
                                          singleData.maxPrice &&
                                          singleData.maxPrice !== "")) && (
                                        <div className="mobile-display">
                                          <Mortgage
                                            homePrice={
                                              singleData &&
                                              singleData.OriginalListprice &&
                                              singleData.OriginalListprice !==
                                                ""
                                                ? singleData.OriginalListprice
                                                : singleData &&
                                                  singleData.maxPrice &&
                                                  singleData.maxPrice !== ""
                                                ? singleData.maxPrice
                                                : ""
                                            }
                                            cityName={
                                              singleData && singleData.city
                                            }
                                            postalCode={
                                              singleData && singleData.zipCode
                                            }
                                            stateName={
                                              singleData && singleData.state
                                            }
                                            detailsPageAds={true}
                                          />
                                        </div>
                                      )}
                                    </Row>
                                  )}
                                </Card>
                              </Row>
                              {singleData && singleData.openHouseStartTime && (
                                <div
                                  className="achoreTagClick"
                                  id="description"
                                >
                                  {/* <Row className="row-padding">
                                    <Card
                                      title="Open House"
                                      className="detailPage_title"
                                      extra={openHouseDate}
                                      // headStyle={{width:"24%"}}
                                    >
                                      <Skeleton
                                        title={false}
                                        loading={dataLoading}
                                        active
                                      >
                                        <p className="property-description font-family-style font-normal-size">
                                          {singleData &&
                                            singleData.openHouseDescription}
                                        </p>
                                      </Skeleton>
                                    </Card>
                                  </Row> */}
                                  <Schedule
                                    propertyId={singleData.homeId}
                                    propertyTitle={
                                      singleData && singleData.title
                                    }
                                    propertyImages={
                                      singleData && singleData.photos
                                    }
                                    agentId={singleData.userId}
                                    agentName={
                                      singleData && singleData.homeAgentName
                                        ? singleData.homeAgentName
                                        : userDetails.agentName
                                    }
                                    agentEmail={
                                      singleData && singleData.agentEmail
                                        ? singleData.agentEmail
                                        : userDetails.agentEmail !== "" &&
                                          userDetails.agentEmail !==
                                            undefined &&
                                          userDetails.agentEmail !== null
                                        ? userDetails.agentEmail
                                        : process.env.REACT_APP_AGENT_EMAIL
                                    }
                                    mlsAgentId={singleData.agentId}
                                    preferedAgent={
                                      loginUserInfo.preferedAgent &&
                                      loginUserInfo.preferedAgent
                                    }
                                    prefereAgentName={
                                      loginUserInfo.prefereAgentName &&
                                      loginUserInfo.prefereAgentName
                                    }
                                    getLoginUserInfo={this.getLoginUserInfo}
                                    buyerLoginSendMessage={
                                      loginUserInfo &&
                                      (loginUserInfo.userProfile === "B" ||
                                        loginUserInfo.userProfile === "S")
                                        ? true
                                        : false
                                    }
                                    showOpenHouseTitle={true}
                                    openHouseStartTime={
                                      singleData.openHouseStartTime
                                    }
                                    openHouseDesc={
                                      singleData.openHouseDescription
                                    }
                                    openHouseEndTime={
                                      singleData.openHouseEndTime
                                    }
                                    listingId={singleData.listingId}
                                    setMlsVendor={this.state.setMlsVendor}
                                    propertyStatstics={
                                      this.savePropertyStatastics
                                    }
                                    prefereAgentDetails={loginUserInfo}
                                  />
                                </div>
                              )}
                              <div className="achoreTagClick" id="description">
                                <Row className="row-padding">
                                  <Card
                                    title="Description / Notes"
                                    className="detailPage_title"
                                  >
                                    <Skeleton
                                      title={false}
                                      loading={dataLoading}
                                      active
                                    >
                                      <p className="property-description font-family-style font-normal-size">
                                        {singleData && singleData.description}
                                      </p>
                                    </Skeleton>
                                  </Card>
                                </Row>
                              </div>

                              <div id="property" className="achoreTagClick">
                                <Row className="row-padding">
                                  <Card
                                    title="Property Details"
                                    className="detailPage_title"
                                  >
                                    {card_content.length > 0 &&
                                      card_content
                                        .filter(
                                          (item) =>
                                            item.value !== undefined &&
                                            item.value !== null &&
                                            item.value !== ""
                                        )
                                        .map((e) => (
                                          <Row
                                            style={{
                                              marginBottom: "0.73vw",
                                              marginLeft: "0.73vw",
                                            }}
                                          >
                                            <Col
                                              md={10}
                                              lg={10}
                                              xl={8}
                                              sm={12}
                                              xs={12}
                                            >
                                              <Skeleton
                                                title={false}
                                                loading={dataLoading}
                                                active
                                              >
                                                <span className=" font-family-style font-normal-size property-detail-value">
                                                  {e.name}
                                                </span>
                                              </Skeleton>
                                            </Col>
                                            <Col
                                              md={14}
                                              lg={14}
                                              xl={10}
                                              sm={12}
                                              xs={12}
                                            >
                                              <Skeleton
                                                title={false}
                                                loading={dataLoading}
                                                active
                                              >
                                                <span className=" font-family-style font-medium-size property-detail-name">
                                                  {e.value}
                                                </span>
                                              </Skeleton>
                                            </Col>
                                          </Row>
                                        ))}
                                  </Card>
                                </Row>
                              </div>

                              {!beforeLoginLoading && singleData && (
                                <>
                                  {singleData.homeId && (
                                    <Schedule
                                      propertyId={singleData.homeId}
                                      propertyTitle={
                                        singleData && singleData.title
                                      }
                                      propertyImages={
                                        singleData && singleData.photos
                                      }
                                      agentId={singleData.userId}
                                      agentName={
                                        singleData && singleData.homeAgentName
                                          ? singleData.homeAgentName
                                          : userDetails.agentName
                                      }
                                      agentEmail={
                                        singleData && singleData.agentEmail
                                          ? singleData.agentEmail
                                          : userDetails.agentEmail !== "" &&
                                            userDetails.agentEmail !==
                                              undefined &&
                                            userDetails.agentEmail !== null
                                          ? userDetails.agentEmail
                                          : process.env.REACT_APP_AGENT_EMAIL
                                      }
                                      mlsAgentId={singleData.agentId}
                                      preferedAgent={
                                        loginUserInfo.preferedAgent &&
                                        loginUserInfo.preferedAgent
                                      }
                                      prefereAgentName={
                                        loginUserInfo.prefereAgentName &&
                                        loginUserInfo.prefereAgentName
                                      }
                                      getLoginUserInfo={this.getLoginUserInfo}
                                      buyerLoginSendMessage={
                                        loginUserInfo &&
                                        (loginUserInfo.userProfile === "B" ||
                                          loginUserInfo.userProfile === "S")
                                          ? true
                                          : false
                                      }
                                      showOpenHouseTitle={false}
                                      openHouseStartTime={""}
                                      openHouseEndTime={""}
                                      propertyStatstics={
                                        this.savePropertyStatastics
                                      }
                                      prefereAgentDetails={loginUserInfo}
                                    />
                                  )}

                                  <QuestionAnswers
                                    propertyId={singleData.homeId}
                                    propertyTitle={
                                      singleData && singleData.title
                                    }
                                    propertyImages={
                                      singleData &&
                                      singleData.photos &&
                                      singleData.photos.length > 0
                                        ? singleData.photos[0]
                                        : []
                                    }
                                    agentId={singleData?.userId}
                                    agentName={
                                      singleData && singleData.homeAgentName
                                        ? singleData.homeAgentName
                                        : userDetails.agentName
                                    }
                                    agentEmail={
                                      singleData && singleData.agentEmail
                                        ? singleData.agentEmail
                                        : userDetails.agentEmail !== "" &&
                                          userDetails.agentEmail !==
                                            undefined &&
                                          userDetails.agentEmail !== null
                                        ? userDetails.agentEmail
                                        : process.env.REACT_APP_AGENT_EMAIL
                                    }
                                    mlsAgentId={singleData.agentId}
                                    prefereAgentName={
                                      loginUserInfo.prefereAgentName &&
                                      loginUserInfo.prefereAgentName
                                    }
                                    preferedAgent={
                                      loginUserInfo.preferedAgent &&
                                      loginUserInfo.preferedAgent
                                    }
                                    getLoginUserInfo={this.getLoginUserInfo}
                                    buyerLoginSendMessage={
                                      loginUserInfo &&
                                      (loginUserInfo.userProfile === "B" ||
                                        loginUserInfo.userProfile === "S")
                                        ? true
                                        : false
                                    }
                                    propertyStatstics={
                                      this.savePropertyStatastics
                                    }
                                    prefereAgentDetails={loginUserInfo}
                                  />
                                  {!beforeLoginLoading && (
                                    <div>
                                      <div id="map" className="achoreTagClick">
                                        <Row>
                                          <Card
                                            title="Map"
                                            className="detailPage_title"
                                          >
                                            <Spin
                                              spinning={latitude ? false : true}
                                            >
                                              {singleData &&
                                              singleData.makePrivateAddress ? (
                                                <MapComponent
                                                  // isMarkerShown
                                                  lat={parseInt("42.4326041")}
                                                  lng={parseInt("-71.0557196")}
                                                  text={this.state.country}
                                                />
                                              ) : (
                                                <MapComponent
                                                  isMarkerShown
                                                  lat={latitude && latitude}
                                                  lng={longitude && longitude}
                                                  text={this.state.country}
                                                />
                                              )}
                                            </Spin>
                                          </Card>
                                        </Row>
                                      </div>
                                      <Card
                                        title={
                                          "Schools in and around " +
                                          singleData.city
                                        }
                                        className="detailPage_title"
                                        style={{ marginTop: "2vw" }}
                                      >
                                        <Spin
                                          spinning={latitude ? false : true}
                                        >
                                          {singleData &&
                                            latitude &&
                                            longitude && (
                                              <MapScore
                                                lat={latitude && latitude}
                                                lng={longitude && longitude}
                                              />
                                            )}
                                        </Spin>
                                      </Card>
                                    </div>
                                  )}
                                </>
                              )}
                            </Col>

                            {isPopup !== true && (
                              <Col
                                xl={{ span: 6, offset: 0 }}
                                lg={{ span: 6, offset: 0 }}
                                md={{ span: 22, offset: 1 }}
                                sm={{ span: 22, offset: 1 }}
                                xs={{ span: 22, offset: 1 }}
                                className="margin-left-agent"
                              >
                                <div>
                                  <>
                                    <Row className="row-padding">
                                      <Card>
                                        <Row
                                          className="center-item"
                                          style={{ padding: "1%" }}
                                        >
                                          <Col>
                                            {singleData &&
                                            singleData.homeAgentProfileUrl &&
                                            singleData.homeAgentProfileUrl
                                              .length > 0 ? (
                                              <img
                                                className="img-detail-page"
                                                src={
                                                  singleData.homeAgentProfileUrl &&
                                                  singleData.homeAgentProfileUrl
                                                    .length > 0
                                                    ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                                      singleData
                                                        .homeAgentProfileUrl[0]
                                                    : ""
                                                }
                                                alt="profileURL"
                                              />
                                            ) : singleData.userRegisterd &&
                                              singleData.userRegisterd.length >
                                                0 ? (
                                              singleData.userRegisterd[0] && (
                                                <img
                                                  className="img-detail-page"
                                                  src={
                                                    singleData.userRegisterd[0]
                                                      .profileUrl
                                                      ? singleData
                                                          .userRegisterd[0]
                                                          .profileUrl
                                                      : profilePic
                                                  }
                                                  alt="profileURL"
                                                />
                                              )
                                            ) : (
                                              <img
                                                className="img-detail-page"
                                                src={profilePic}
                                                alt="logo"
                                              />
                                            )}
                                          </Col>
                                        </Row>

                                        <Row className="center-item font-family-style font-normal-size profile-name">
                                          <Col className="padding-text-name">
                                            <span className="text-size-name">
                                              {singleData &&
                                              singleData &&
                                              singleData.userRegisterd &&
                                              singleData.userRegisterd.length >
                                                0
                                                ? singleData.userRegisterd[0]
                                                    .firstName +
                                                  "" +
                                                  singleData.userRegisterd[0]
                                                    .lastName
                                                : singleData.homeAgentName}
                                            </span>
                                          </Col>
                                        </Row>

                                        <Row
                                          className="center-item font-family-style"
                                          style={{
                                            paddingTop: "0%",
                                            paddingBottom: "1%",
                                            marginTop: "-10px",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: "0px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {singleData &&
                                              singleData.companyName}
                                          </p>
                                        </Row>

                                        <Row className="center-item">
                                          <Col>
                                            {singleData &&
                                              singleData.servingName && (
                                                <Row className="center-item font-family-style">
                                                  <p>
                                                    {singleData &&
                                                      singleData.servingName}
                                                  </p>
                                                </Row>
                                              )}

                                            {singleData && singleData.userId ? (
                                              <Link
                                                style={{
                                                  textDecoration: "none",
                                                  paddingLeft:
                                                    singleData &&
                                                    singleData.mlsData & 7,
                                                }}
                                                to={{
                                                  pathname: `/viewProfile/${
                                                    singleData &&
                                                    singleData.userid
                                                      ? singleData.userid
                                                      : singleData.userId
                                                  }`,
                                                  state: "DetailPage",
                                                }}
                                                className="profile-view-link"
                                              >
                                                View Profile
                                              </Link>
                                            ) : (
                                              singleData &&
                                              singleData.userRegisterd &&
                                              singleData.userRegisterd.length >
                                                0 && (
                                                <Link
                                                  style={{
                                                    textDecoration: "none",
                                                    paddingLeft: "36px",
                                                  }}
                                                  to={{
                                                    pathname: `/viewProfile/${
                                                      singleData &&
                                                      singleData.userRegisterd &&
                                                      singleData.userRegisterd
                                                        .length > 0 &&
                                                      singleData
                                                        .userRegisterd[0].id
                                                    }`,
                                                    state: "DetailPage",
                                                  }}
                                                  className="profile-view-link"
                                                >
                                                  View Profile
                                                </Link>
                                              )
                                            )}
                                            <Row className="center-item">
                                              <p
                                                className="button-inner-text-size"
                                                style={{}}
                                              ></p>
                                            </Row>
                                          </Col>
                                        </Row>

                                        <Divider className="divider-detail"></Divider>
                                        {singleData &&
                                          showSendMessagePopUp &&
                                          !singleData.mls && (
                                            <Row
                                              className="center-item font-family-style"
                                              style={{ padding: "3%" }}
                                            >
                                              <SendMessage
                                                msgButtonTitle={
                                                  loginUserInfo &&
                                                  (loginUserInfo.userProfile ===
                                                    "B" ||
                                                    loginUserInfo.userProfile ===
                                                      "S")
                                                    ? "Share with my Agent"
                                                    : "Send Message"
                                                }
                                                buyerLoginSendMessage={
                                                  loginUserInfo &&
                                                  (loginUserInfo.userProfile ===
                                                    "B" ||
                                                    loginUserInfo.userProfile ===
                                                      "S")
                                                    ? true
                                                    : false
                                                }
                                                toName={
                                                  singleData &&
                                                  singleData.homeAgentName
                                                }
                                                propertyId={
                                                  singleData &&
                                                  singleData.homeId &&
                                                  singleData.homeId
                                                }
                                                propertyTitle={
                                                  singleData && singleData.title
                                                    ? singleData.title
                                                    : "enter your message"
                                                }
                                                propertyImages={
                                                  singleData &&
                                                  singleData.photos
                                                }
                                                buttonAlign="right"
                                                mlsId={
                                                  userDetails &&
                                                  userDetails.mlsId
                                                }
                                                agentEmail={
                                                  singleData &&
                                                  singleData.agentEmail
                                                }
                                                messageType="potential"
                                                isRegistredUser={
                                                  this.props.match.params
                                                    .stateName === "offMls"
                                                    ? true
                                                    : false
                                                }
                                                stateType={
                                                  this.props.match.params
                                                    .stateName
                                                }
                                                mlsAgentId={
                                                  singleData &&
                                                  singleData.agentId
                                                }
                                                agentId={singleData.userId}
                                                preferedAgent={
                                                  loginUserInfo.preferedAgent &&
                                                  loginUserInfo.preferedAgent
                                                }
                                                prefereAgentName={
                                                  loginUserInfo.prefereAgentName &&
                                                  loginUserInfo.prefereAgentName
                                                }
                                                getLoginUserInfo={
                                                  this.getLoginUserInfo
                                                }
                                                prefereAgentDetails={
                                                  loginUserInfo
                                                }
                                              />
                                            </Row>
                                          )}
                                        {singleData &&
                                          showSendMessagePopUp &&
                                          singleData.mls && (
                                            <Row
                                              className="center-item font-family-style"
                                              style={{ padding: "3%" }}
                                            >
                                              <SendMessage
                                                msgButtonTitle={
                                                  loginUserInfo &&
                                                  (loginUserInfo.userProfile ===
                                                    "B" ||
                                                    loginUserInfo.userProfile ===
                                                      "S")
                                                    ? "Share with my Agent"
                                                    : "Send Message"
                                                }
                                                buyerLoginSendMessage={
                                                  loginUserInfo &&
                                                  (loginUserInfo.userProfile ===
                                                    "B" ||
                                                    loginUserInfo.userProfile ===
                                                      "S")
                                                    ? true
                                                    : false
                                                }
                                                toName={
                                                  singleData &&
                                                  singleData.homeAgentName &&
                                                  singleData.homeAgentName
                                                }
                                                propertyId={
                                                  singleData &&
                                                  singleData.homeId &&
                                                  singleData.homeId
                                                }
                                                propertyTitle={
                                                  singleData && singleData.title
                                                    ? singleData.title
                                                    : "enter your message"
                                                }
                                                propertyImages={
                                                  singleData &&
                                                  singleData.photos
                                                }
                                                buttonAlign="right"
                                                mlsId={
                                                  singleData &&
                                                  singleData.homeId
                                                }
                                                agentEmail={
                                                  singleData &&
                                                  singleData.agentEmail
                                                }
                                                messageType="potential"
                                                isRegistredUser={
                                                  this.props.match.params
                                                    .stateName === "offMls"
                                                    ? true
                                                    : false
                                                }
                                                stateType={
                                                  this.props.match.params
                                                    .stateName
                                                }
                                                mlsAgentId={
                                                  singleData &&
                                                  singleData.agentId
                                                }
                                                agentId={singleData.userId}
                                                preferedAgent={
                                                  loginUserInfo.preferedAgent &&
                                                  loginUserInfo.preferedAgent
                                                }
                                                prefereAgentName={
                                                  loginUserInfo.prefereAgentName &&
                                                  loginUserInfo.prefereAgentName
                                                }
                                                getLoginUserInfo={
                                                  this.getLoginUserInfo
                                                }
                                                prefereAgentDetails={
                                                  loginUserInfo
                                                }
                                              />
                                            </Row>
                                          )}

                                        {singleData && (
                                          <Row
                                            className="center-item font-family-style"
                                            style={{ padding: "3%" }}
                                          >
                                            <div
                                              style={{
                                                border: "1px solid #FC572B",
                                              }}
                                            >
                                              <Button
                                                type="link"
                                                onClick={() =>
                                                  this.setState({
                                                    agentsVisible: true,
                                                  })
                                                }
                                                className="button-inner-text-size"
                                                style={{ color: "#FC572B" }}
                                              >
                                                Speak to a Buyer's Agent
                                              </Button>
                                            </div>
                                          </Row>
                                        )}
                                      </Card>
                                    </Row>

                                    {userLogin &&
                                      loginUserInfo &&
                                      singleData &&
                                      loginUserInfo.userId ===
                                        singleData.userId && (
                                        <Row className="row-padding">
                                          <Card
                                            bordered={false}
                                            headStyle={{
                                              backgroundColor: "darkgray",
                                              color: "white",
                                            }}
                                            bodyStyle={{
                                              backgroundColor: "orange",
                                              color: "white",
                                              padding: "5px",
                                            }}
                                            title={"Private Note"}
                                            className="detailPage_title"
                                          >
                                            <p>
                                              {singleData.privateNotes &&
                                                singleData.privateNotes}
                                            </p>
                                          </Card>
                                        </Row>
                                      )}

                                    {singleData && (
                                      <Row className="row-padding">
                                        <Card
                                          title="Details"
                                          className="detailPage_title"
                                        >
                                          {details_data.length > 0 &&
                                            details_data.map((e) => (
                                              <>
                                                {e.value !== undefined &&
                                                  e.value !== null &&
                                                  e.value !== "" && (
                                                    <>
                                                      <Row
                                                        style={{
                                                          marginBottom:
                                                            "0.73vw",
                                                          marginLeft: "0.73vw",
                                                        }}
                                                      >
                                                        <Col
                                                          md={12}
                                                          lg={24}
                                                          xl={12}
                                                          sm={12}
                                                          xs={12}
                                                        >
                                                          <span className="font-family-style font-normal-size property-detail-name">
                                                            {e.name}
                                                          </span>
                                                        </Col>
                                                        <Col
                                                          md={12}
                                                          lg={24}
                                                          xl={12}
                                                          sm={12}
                                                          xs={12}
                                                        >
                                                          <span
                                                            className="font-family-style font-normal-size property-detail-value"
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            {e.value}
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                    </>
                                                  )}
                                              </>
                                            ))}
                                        </Card>
                                      </Row>
                                    )}

                                    {singleData && singleData && (
                                      <Advertisement
                                        otherAds={true}
                                        adType="Square"
                                        adWhereToShow="Detail"
                                        adLimit="10"
                                        showMatchesPage={
                                          this.props.showMatchesPage
                                        }
                                        cityName={singleData && singleData.city}
                                        postalCode={
                                          singleData && singleData.zipCode
                                        }
                                        stateName={paramsState}
                                        detailsPageAds={true}
                                      />
                                    )}
                                  </>
                                </div>
                              </Col>
                            )}
                          </Row>

                          {singleData && singleData.homePhotos && (
                            <Row style={{ marginTop: "2vw" }}>
                              {isPopup !== true && (
                                <Card
                                  title="Similar Properties"
                                  className="detailPage_title"
                                >
                                  <Col
                                    span={24}
                                    style={{
                                      background: " #F9FAFF",
                                      paddingBottom: "1vw",
                                    }}
                                  >
                                    {singleData && singleData.homePhotos && (
                                      <HomesCarousel
                                        whereToShow="details"
                                        limit="20"
                                        filterInfo={singleData && singleData}
                                      />
                                    )}
                                  </Col>
                                </Card>
                              )}
                            </Row>
                          )}
                        </Layout>

                        <Drawer
                          placement="right"
                          title=""
                          width={
                            window.screen.width >= "768"
                              ? "40%"
                              : window.screen.width > "501" &&
                                window.screen.width <= "767"
                              ? "80%"
                              : "100%"
                          }
                          mask={true}
                          closable={true}
                          getContainer={false}
                          visible={this.state.enableSignupForm}
                          onClose={() => this.handleReset()}
                          headerStyle={{ textAlign: "center" }}
                          bodyStyle={{
                            paddingBottom: 80,
                            backgroundColor: "#eff2f5",
                            padding: "0px",
                          }}
                          className="homecard-drawer"
                        >
                          <Row>
                            <Col span={24}>
                              {showLoginSiderForm ? (
                                <LoginForm
                                  showAdd={false}
                                  modalTrue
                                  goTopreviousPage={true}
                                  handleToshowLoginSider={
                                    this.handletoShowSignupsider
                                  }
                                  showLoginSiderForm={showLoginSiderForm}
                                  questionPage
                                />
                              ) : (
                                <SignupForm
                                  questionPage
                                  showAdd={false}
                                  modalTrue
                                  goTopreviousPage={true}
                                  handleToshowLoginSider={
                                    this.handleToshowLoginSider
                                  }
                                  showLoginSiderForm={showLoginSiderForm}
                                />
                              )}
                            </Col>
                          </Row>
                        </Drawer>
                      </div>
                    </Col>
                  )}
                </Row>
              </Layout>
            </div>
            <Modal
              className="modal-black-close"
              visible={agentsVisible}
              style={{ marginTop: "2%" }}
              onCancel={() =>
                this.setState({
                  agentsVisible: false,
                })
              }
              footer={null}
            >
              <div>
                <Row>
                  <div style={{ marginTop: "20px" }}>
                    <h4>Buyer's Agent</h4>
                    <p style={{ color: "#99A2C3", marginTop: "10px" }}>
                      These local buyers' agents would be happy to represent
                      your interests instead of the seller's interests. Click on
                      profile links to find the best fit for you and send them a
                      message. If no agents are listed or you don't see a good
                      match send a message to our client concierge below and our
                      team of experts will help you find an experienced agent in
                      your area to represent your interests.
                    </p>
                  </div>
                </Row>
                {filterAgents &&
                  filterAgents.length >= 1 &&
                  filterAgents.map((agentsInfo) => (
                    <>
                      <BuyerAgentProfile
                        agentInfo={agentsInfo}
                        viewProfileAuth={userLogin ? true : false}
                        propertyId={singleData && singleData.homeId}
                        propertyTitle={singleData && singleData.title}
                        propertyImages={singleData && singleData.photos}
                      />
                      <hr></hr>
                    </>
                  ))}
                {speakToBuyerAgentsList &&
                  speakToBuyerAgentsList.length > 0 &&
                  speakToBuyerAgentsList.map((ownerInfo) => (
                    <>
                      <BuyerAgentProfile
                        agentInfo={ownerInfo}
                        viewProfileAuth={userLogin ? true : false}
                        propertyId={singleData && singleData.homeId}
                        propertyTitle={singleData && singleData.title}
                        propertyImages={singleData && singleData.photos}
                      />
                      <hr></hr>
                    </>
                  ))}

                {contactAgentList &&
                  contactAgentList.length > 0 &&
                  contactAgentList.slice(0, 1).map((agentsInfo) => (
                    <>
                      <BuyerAgentProfile
                        agentInfo={agentsInfo}
                        viewProfileAuth={userLogin ? true : false}
                        propertyId={singleData && singleData.homeId}
                        propertyTitle={singleData && singleData.title}
                        propertyImages={singleData && singleData.photos}
                      />
                      <hr></hr>
                    </>
                  ))}

                {singleData && singleData && (
                  <Row>
                    <Advertisement
                      otherAds={false}
                      adType="Rectangle"
                      adWhereToShow="BuyersAgentPopup"
                      adLimit="1"
                      zipCode={singleData && singleData.zipCode}
                    />
                  </Row>
                )}
              </div>
            </Modal>

            {/* <Modal
              className="modal-black-close"
              visible={openHouseReserve}
              title={"Reserver Attendance"}
              style={{ marginTop: "2%" }}
              onCancel={() =>
                this.setState({
                  openHouseReserve: false,
                })
              }
              footer={
                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <Button
                        onClick={() =>
                          this.setState({
                            openHouseReserve: false,
                          })
                        }
                        type="default"
                        style={{
                          width: "100%",
                          backgroundColor: "#bbb",
                          color: "#FFF",
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        // onClick={() =>
                        //   selectedSchedule.id && selectedSchedule.id !== ""
                        //     ? this.updateQuery("R")
                        //     : this.submitQuery()
                        // }
                        type="primary"
                        style={{ width: "100%" }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              }
            >
              {this.openHouseModal()}
            </Modal> */}
          </>
        )}
      </>
    );
  }
}

export default withAppContext(withRouter(Details));
