import React, { Component } from "react";
import "antd/dist/antd.css";
import Login from "./Login";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

import {
  Form,
  Input,
  Popover,
  Button,
  Row,
  Col,
  Checkbox,
  message,
  Modal,
  Divider,
  Drawer,
  notification,
  Select,
} from "antd";
import Amplify, { Auth } from "aws-amplify";
import AwsConfig from "../config/AwsConfig";
import AppConfig from "../config/AppConfig";
import { withRouter, Link } from "react-router-dom";
import { getDetails, noAuthadd, noAuthlist } from "../api/Apis";
import NumberFormat from "react-number-format";
import { withAppContext } from "../store/contextStore";
import SignupModal from "../common/SignupModal";
// import logo from "../images/logo.png";
import { CloseOutlined } from "@ant-design/icons";
import Loading from "../common/Loading";
import GoogleButton from "./GoogleButton";
import SignInWithFacebook from "./SignInWithFacebook";

Amplify.configure(AwsConfig);

const { Option } = Select;
export class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {
        userProfile: "B",
      },
      errors: "",
      loading: false,
      validatePassword: "",
      signUpResponseData: "",
      visible: false,
      password: "",
      email: "",
      loginPopupTrue: false,
      visibleTerms: false,
      allUsersList: [],
      referedById: "",
      referedByName: "",
      userProfile: "",
      orgId: "",
      captchStore: "",
      captchaMatchSuccess: false,
    };
  }

  componentDidMount() {
    this.getAllUsers();
    loadCaptchaEnginge(6, "rgb(239, 242, 245)", "#000000");
  }
  getAllUsers = async () => {
    try {
      const usersList = await noAuthlist("getUsers", { isActive: true });
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
        };
      });
      if (usersList.success) {
        this.setState({
          allUsersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  showDrawer = () => {
    this.setState({
      visibleTerms: true,
    });
  };

  onClose = () => {
    this.setState({
      visibleTerms: false,
    });
  };

  handleOnChange = (element, value) => {
    if (element === "password") {
      const valid =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
          value
        );
      if (valid) {
        this.setState({ validatePassword: true });
      } else if (value === "") {
        this.setState({ validatePassword: "" });
      } else {
        this.setState({ validatePassword: false });
      }
    }
    if (element === "mlsId") {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          [element]: value.toUpperCase(),
        },
      });
    }
    if (element !== "mlsId" && element !== "email") {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          [element]: value,
        },
      });
    }
    if (element === "email") {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          [element]: value.toLowerCase(),
        },
      });
    }
  };

  handlePhone = (e) => {
    // console.log(e.target.value);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = async (e) => {
    if (e.remember === false) {
      notification["error"]({
        message: "Warning.!",
        description: "Please accept our Terms and Conditions",
      });
      return;
    }
    console.log(
      this.state.captchStore,
      "this.state.captchStore",
      this.state.captchaMatchSuccess
    );
    if (
      this.state.captchStore.split("").length >= 6 ||
      !this.state.captchaMatchSuccess
    ) {
      if (this.state.captchStore.split("").length) {
        notification["error"]({
          message: "Warning.!",
          description: "Invalid Captcha",
        });
        this.setState({
          captchStore: "",
          captchaMatchSuccess: false,
        });
      } else if (this.state.captchStore === "") {
        notification["error"]({
          message: "Warning.!",
          description: "Please Enter captcha...",
        });
        this.setState({
          captchStore: "",
          captchaMatchSuccess: false,
        });
      }

      return;
    }

    if (
      this.state.userProfile === "" ||
      this.state.userProfile === undefined ||
      this.state.userProfile === null
    ) {
      notification["error"]({
        message: "Warning.!",
        description: "Please select the user type",
      });
      return;
    }

    this.setState({
      loading: true,
    });
    let lowerEmail = e.email.toLowerCase();
    this.setState({
      email: lowerEmail,
      password: e.password,
    });
    try {
      this.saveUser();
      // await Auth.signUp(lowerEmail, e.password).then((data) => {

      // this.setState({
      //   loginPopupTrue: false,
      //   visible: true,
      //   signUpResponseData: data,
      //   email: lowerEmail,
      //   password: e.password,
      //   loading: false,
      // });
      // });
    } catch (error) {
      message.error(error.message);
      this.setState({
        visible: false,
        loading: false,
      });
    }
  };

  handleOk = async (e) => {
    try {
      this.setState({
        loading: true,
      });
      await Auth.confirmSignUp(this.state.email, e.code).then(
        async (result) => {
          this.doLogin();
        }
      );
    } catch (error) {
      this.setState({
        loading: false,
      });
      message.error("Invalid Confirm Code");
    }
  };

  doLogin = async () => {
    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      const userDetails = Object.assign(
        {},
        {
          token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      this.props.context.setUser(userDetails, false);
      // this.saveUser();
      this.gotoApp(user.username);
    } catch (err) {
      message.error("Error: unable to login");
      this.setState({
        loading: false,
        errors: err.message,
      });
    }
  };

  saveUser = async () => {
    // const checkBuyerProfile = await usersList("buyerProfileSignup");

    // let buyerAgentInfo =
    //   checkBuyerProfile.data &&
    //   checkBuyerProfile.data.filter(
    //     (eachBuyer) => eachBuyer.buyerEmail === this.state.userDetails.email
    //   )[0];
    this.setState({
      loading: true,
    });
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let contactPhone = this.state.userDetails.phone.replace(/[^+0-9]/g, "");

    const data = {
      entityData: {
        // id: this.state.signUpResponseData.userSub,
        firstName: this.state.userDetails.firstName,
        lastName: this.state.userDetails.lastName,
        phone: parseInt(contactPhone),
        email: this.state.userDetails.email,
        userProfile: this.state.userProfile, //this.state.userDetails.userProfile,
        recordStatus: true,
        mlsId: this.state.userDetails.mlsId,
        referedByName: this.state.referedByName,
        referedById: this.state.referedById,
        orgId: orgId,
        password: this.state.password,
        isActive: true,
        // agentInfo: {
        //   agentEmail: buyerAgentInfo.agentEmail,
        //   agentName: buyerAgentInfo.agentName,
        // },
        // addUserName: buyerAgentInfo.agentName,
      },
    };
    noAuthadd("confirmaUserSingup", data)
      .then((result) => {
        console.log(result, "resultresult");
        if (result.success) {
          this.props.context.setUserDetails(result.data);

          this.doLogin();
          message.success("User created successfully.");
        } else {
          this.setState({
            loading: false,
          });
          notification["error"]({
            message: result.errors.message.message.message,
          });
          console.log(result.errors, result.errors.message.message.message);
        }
      })
      .catch((err) => {
        console.log(err, "errr");
      });
  };

  gotoApp = (id) => {
    getDetails(AppConfig["entityNames"]["users"], id).then((result) => {
      if (result.success) {
        this.setState({
          loading: false,
        });
        this.props.context.setUserDetails(result.data);
        localStorage.setItem("userDetails", JSON.stringify(result.data));
        localStorage.setItem("userLogin", JSON.stringify(true));
        localStorage.setItem(
          "userLoginTime",
          JSON.stringify(new Date().getTime())
        );
        if (result.data.userProfile === "B") {
          if (this.props.goTopreviousPage) {
            window.location.reload();
          } else {
            this.props.history.push(`/viewProfile/${result.data.id}`);
          }
        } else {
          if (this.props.goTopreviousPage) {
            window.location.reload();
          } else {
            this.props.history.push("/homes");
          }
        }
      } else {
        message.error(result.errors);
        this.setState({
          loading: false,
        });
      }
    });
  };

  content = (
    <div>
      <p>-Minimum password length: 6</p>
      <p>-Required at least one uppercase letter</p>
      <p>-Required at least one lowercase letter</p>
      <p>-Required at least one number</p>
      <p>-Required at least one nonalphanumeric character</p>
    </div>
  );

  hanleLoginPopUp = () => {
    if (window.location.pathname === "/register") {
      this.props.history.push("/login");
    } else {
      if (!this.props.showLoginSiderForm && this.props.questionPage) {
        this.props.handleToshowLoginSider();
      } else {
        this.setState({
          loginPopupTrue: true,
        });
        this.props.closePreviouspopUp();
      }
    }
  };

  handleonCancel = () => {
    this.setState({
      loginPopupTrue: false,
    });
  };

  onSelect = (value, option) => {
    this.setState({
      referedById: option.value,
      referedByName: option.label,
    });
  };

  handleUserType = (option) => {
    this.setState({
      userProfile: option,
    });
  };

  handleToConfirmCaptcha = (e) => {
    this.setState({
      captchStore: e.target.value,
    });
  };

  handleToSubmitCaptch = () => {
    if (validateCaptcha(this.state.captchStore) === true) {
      loadCaptchaEnginge(6, "rgb(239, 242, 245)", "#000000");
      this.setState({
        captchStore: "",
        captchaMatchSuccess: true,
      });
      notification["success"]({
        message: "Captcha Matched",
      });
    } else {
      notification["error"]({
        message: "Invalid Captcha...",
      });
      this.setState({
        captchStore: "",
        captchaMatchSuccess: false,
      });
    }
  };

  handleToRedirectPath = () => {
    this.props.history.push("/homes");
  };

  signupForm = () => {
    const { loginScreen } = this.props.context.state.websiteThemes;
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    const { captchStore, captchaMatchSuccess } = this.state;
    return (
      <>
        {loginScreen && (
          <>
            <Row className="register" style={{ padding: "0.5%" }}>
              <Col
                xl={{ span: 18, offset: 5 }}
                lg={{ span: 18, offset: 5 }}
                md={{ span: 18, offset: 5 }}
                xs={{ span: 17, offset: 5 }}
                sm={{ span: 17, offset: 5 }}
              >
                <Button
                  type="text"
                  className="register-text"
                  onClick={this.hanleLoginPopUp}
                >
                  Already have an account? Login here
                </Button>
              </Col>
              {window.location.pathname === "/register" ? (
                <Col
                  xl={{ span: 1 }}
                  lg={{ span: 1 }}
                  md={{ span: 1 }}
                  xs={{ span: 1 }}
                  sm={{ span: 1 }}
                  style={{ textAlign: "left" }}
                >
                  <Link to="/">
                    <CloseOutlined className="close-btn" />
                  </Link>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                className="mobile-view-display"
              >
                <Link to="/">
                  <img
                    src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${orgDetails.Header.headerLogo}`}
                    alt="logo"
                    className="logo-header-login"
                    style={{ marginBottom: "0px" }}
                  />
                </Link>
              </Col>
            </Row>
            <Row className="register_MarginTop">
              <Col
                xxl={{ span: 12, offset: 6 }}
                xl={{ span: 18, offset: 4 }}
                lg={{ span: 21, offset: 2 }}
                md={{ span: 21, offset: 2 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <div className="login-form-center">
                  <h1 className="register-form-center-weight login-margin-top">
                    {loginScreen?.registerTitle}
                  </h1>
                  <Row>
                    <Col
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      lg={{ span: 21, offset: 2 }}
                      md={{ span: 21, offset: 2 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <SignInWithFacebook
                        gotoApp={this.gotoApp}
                        onLogin={this.handleLoginForm}
                        buttonText={"Sign Up"}
                        handleToRedirectPath={this.handleToRedirectPath}
                        pr1={this.props}
                      />
                    </Col>
                    <Col
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      lg={{ span: 21, offset: 2 }}
                      md={{ span: 21, offset: 2 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                    >
                      <GoogleButton buttonText={"Sign Up"} />
                    </Col>
                  </Row>
                </div>
                {/* <Form.Item>
                    <h1 className="login-form-center register-form-center-weight login-margin-top">
                      OR
                    </h1>
                  </Form.Item> */}
                <Divider> or </Divider>

                {/* <Form.Item className="remove-bottom-space">
              <p
                className="register-iam"
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                I am :
              </p>
            </Form.Item> */}
                {/* <Form.Item>
              <div
                className="display-flex-between checking font-family-style font-normal-size"
                style={{ justifyContent: "center" }}
              >
                <Radio.Group
                  defaultValue={
                    this.state.userDetails.userProfile
                      ? this.state.userDetails.userProfile
                      : "B"
                  }
                  onChange={(e) =>
                    this.handleOnChange("userProfile", e.target.value)
                  }
                  buttonStyle="solid"
                >
                  <Space size="small">
                    <Radio.Button value="B">
                      <p className="user-text-size">Buyer</p>
                    </Radio.Button>
                    <Radio.Button value="S">
                      <p className="user-text-size">Seller</p>
                    </Radio.Button>
                    <Radio.Button value="AG">
                      <p className="user-text-size">Agent</p>
                    </Radio.Button>
                    <Radio.Button value="V">
                      <p className="user-text-size">Vendor</p>
                    </Radio.Button>
                  </Space>
                </Radio.Group>
              </div>
            </Form.Item> */}
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={this.onFinish}
                >
                  <div className="register-form">
                    <Form.Item style={{ marginBottom: "24px" }}>
                      <Select
                        style={{
                          width: "100%",
                        }}
                        placeholder="I am "
                        onChange={this.handleUserType}
                        className="select_height"
                      >
                        <Option value="B">Buyer</Option>
                        <Option value="S">Seller</Option>
                        {/* <Option value="AG">Agent</Option> */}
                        <Option value="L">Lender</Option>
                        <Option value="V">Vendor</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                      style={{ marginBottom: "24px" }}
                    >
                      <Input
                        placeholder="First Name"
                        value={this.state.userDetails.firstName}
                        onChange={(e) =>
                          this.handleOnChange("firstName", e.target.value)
                        }
                        className="form-input"
                      />
                    </Form.Item>
                    <Form.Item
                      name="username1"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                        },
                      ]}
                      style={{ marginBottom: "24px" }}
                    >
                      <Input
                        placeholder="Last Name"
                        value={this.state.userDetails.lastName}
                        onChange={(e) =>
                          this.handleOnChange("lastName", e.target.value)
                        }
                        className="form-input"
                      />
                    </Form.Item>

                    {/*
                    Don't Delete
                    <Form.Item name="referedBy">
                      <Select
                          className="form-input"
                        showSearch
                        optionFilterProp="children"
                        options={this.state.allUsersList}
                        onSelect={this.onSelect}
                        placeholder="Refered by"
                        filterOption={(inputValue, option) =>
                          option.label
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        size="large"
                      ></Select>
                    </Form.Item>
                    Don't Delete
                    */}

                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                      ]}
                      style={{ marginBottom: "24px" }}
                    >
                      <NumberFormat
                        className="ant-input-number"
                        placeholder="Phone"
                        value={this.state.userDetails.phone}
                        onChange={(e) =>
                          this.handleOnChange("phone", e.target.value)
                        }
                        format="(###) ###-####"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your e-mail!",
                        },
                      ]}
                      style={{ marginBottom: "24px" }}
                    >
                      <Input
                        type="email"
                        placeholder="Email"
                        value={this.state.userDetails.email}
                        onChange={(e) =>
                          this.handleOnChange("email", e.target.value)
                        }
                        style={{ textTransform: "lowercase" }}
                        className="form-input"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      // hasFeedback
                      // validateStatus={
                      //   this.state.validatePassword === "" &&
                      //   this.state.validatePassword === true
                      //     ? "success"
                      //     : "warning"
                      // }
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      style={{ marginBottom: "24px" }}
                    >
                      <Input.Password
                        placeholder="Password"
                        value={this.state.userDetails.Password}
                        onChange={(e) =>
                          this.handleOnChange("password", e.target.value)
                        }
                        className="form-input"
                      />
                    </Form.Item>

                    <Form.Item
                      className="password-criteria"
                      style={{ marginBottom: "24px" }}
                    >
                      <Popover content={this.content} title="Password Criteria">
                        <Button
                          className="criteria"
                          type="text"
                          style={{
                            color: "#39acda",
                            marginLeft: "-15px",
                            background: "transparent",
                          }}
                        >
                          Password Criteria
                        </Button>
                      </Popover>
                    </Form.Item>

                    {this.state.userDetails.userProfile === "AG" && (
                      <Form.Item
                        name="mlsId"
                        rules={[
                          {
                            required: true,
                            message: "Please input your MLSID!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="MLSID"
                          value={this.state.userDetails.mlsId}
                          onChange={(e) =>
                            this.handleOnChange("mlsId", e.target.value)
                          }
                          style={{ width: "100%", textTransform: "uppercase" }}
                          className="form-input"
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      name="reCaptchConfirm"
                      style={{ display: "flex" }}
                    >
                      <Row style={{ padding: "0px" }}>
                        <Col
                          xxl={{ span: 9, offset: 0 }}
                          xl={{ span: 8, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <LoadCanvasTemplate
                            reloadText="Reload"
                            reloadColor="red"
                          />
                        </Col>
                        <Col
                          xxl={{ span: 14, offset: 1 }}
                          xl={{ span: 15, offset: 1 }}
                          lg={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <Input
                            className="form-input-reCaptch"
                            placeholder="Enter Captcha..."
                            onChange={this.handleToConfirmCaptcha}
                            // id="user_captcha_input"
                            value={captchStore}
                            addonAfter={
                              <Button
                                type="primary"
                                onClick={this.handleToSubmitCaptch}
                                htmlType="button"
                                disabled={captchaMatchSuccess}
                                style={{
                                  height: "38px",
                                  cursor: !captchaMatchSuccess
                                    ? "pointer"
                                    : "not-allowed",
                                  width: "auto",
                                }}
                              >
                                Submit
                              </Button>
                            }
                            disabled={captchaMatchSuccess}
                          />
                        </Col>
                      </Row>
                      {/* <ReCaptcha /> */}
                    </Form.Item>
                    <Form.Item name="remember" valuePropName="checked">
                      <Checkbox checked>
                        <span className="terms">
                          I agree to receive email alerts related to property
                          listings from PoweredByAgents.{" "}
                        </span>
                        <Button
                          type="text"
                          onClick={() =>
                            this.setState({
                              visibleTerms: true,
                            })
                          }
                          style={{
                            color: "#39acda",
                            marginLeft: "-15px",
                          }}
                        >
                          Terms & Conditions
                        </Button>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        loading={this.state.loading}
                        className="login-form-button"
                        style={{
                          background:
                            loginScreen?.registerButton?.regbuttonBackground,
                          color: loginScreen?.registerButton?.regButtonColor,
                          cursor:
                            this.state.captchaMatchSuccess === false
                              ? "not-allowed"
                              : "pointer",
                        }}
                        disabled={
                          this.state.captchaMatchSuccess === false
                            ? true
                            : false
                        }
                      >
                        {loginScreen?.registerButton?.regbuttonText}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                {this.state.visible ? (
                  <div>
                    <Modal
                      visible={this.state.visible}
                      title={
                        <p
                          className="font-family-style font-medium-size"
                          style={{ color: "blue", margin: "0px" }}
                        >
                          Confirm SignUp
                        </p>
                      }
                      onCancel={this.handleCancel}
                      maskClosable={false}
                      className="modal-black-close"
                      footer={null}
                    >
                      <div style={{ padding: "5px 10px" }}>
                        <span className="font-family-style text-size padding register-confirm">
                          We have sent you a confirmation code to your email.
                          Please enter the code below to complete your signup.
                          Thank you.
                        </span>
                      </div>
                      <Form
                        className="padding font-family-style text-size"
                        onFinish={this.handleOk}
                        style={{ padding: "5px 10px" }}
                      >
                        <Form.Item
                          name="code"
                          rules={[
                            {
                              required: true,
                              message: "Please input the code.",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder="Please input the code."
                            className="form-input"
                            maxLength="6"
                          />
                        </Form.Item>
                        <Divider></Divider>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.loading}
                          >
                            <span className="align">Submit</span>
                          </Button>
                        </Form.Item>
                      </Form>
                    </Modal>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Drawer
              title="Terms & Conditions"
              placement="bottom"
              height={"100%"}
              mask={true}
              closable={true}
              getContainer={false}
              visible={this.state.visibleTerms}
              onClose={this.onClose}
              headerStyle={{ textAlign: "center" }}
              bodyStyle={{ paddingBottom: 80, backgroundColor: "#eff2f5" }}
              style={{ position: "absolute" }}
            >
              <Row>
                <Col span={24} style={{ padding: "20px" }}>
                  <Row>
                    <p style={{ textAlign: "justify" }}>
                      I understand that by creating an account and adding
                      property listings it will not mean it has been listed in
                      MLS and will only be listed on PoweredByAgents.com I
                      understand that as a consumer creating an account and
                      submitting listings on the network that this does not
                      allow for the property to be syndicated to thousands of
                      search sites and it will only be listed on
                      PoweredByAgents.com I also understand that in order to
                      maximize exposure on my posted listings it may be in my
                      best interests to consider listing it on MLS with a
                      Realtor or seek an entry-only service.
                    </p>
                  </Row>
                  <Row>
                    <p style={{ textAlign: "justify" }}>
                      As a licensed real estate agent/broker entering listings
                      on this portal I understand that I am subject to all MLS
                      and Realtor board rules and regulations which may require
                      me to list this property in MLS. By entering listings on
                      this site I am attesting that I am in full compliance with
                      all MLS & Realtor board rules and regulations and have any
                      required contracts in place at the time of posting
                      listings which may include but not limited to a listing
                      contract, non-mls listing agreement, delayed listing
                      forms, etc.
                    </p>
                  </Row>
                  <Row>
                    <p style={{ textAlign: "justify" }}>
                      Powered By Agents will not be responsible for any
                      violations by an agent or consumer due to false
                      advertising or lack of required contracts, disclosures,
                      etc.
                    </p>
                  </Row>
                </Col>
              </Row>
            </Drawer>
          </>
        )}
      </>
    );
  };

  render() {
    const { showAdd } = this.props;
    const { loginScreen } = this.props.context.state.websiteThemes;
    return (
      <div
        style={{
          overflow: "hidden",
          background: loginScreen?.loginbgColor || "#eff2f5",
        }}
      >
        <Loading enableLoading={this.state.loading} />

        {showAdd === false ? (
          <Row>
            <Col xs={24}>{this.signupForm()}</Col>
          </Row>
        ) : (
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Login />
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div
                class="fb-login-button"
                data-width=""
                data-size="large"
                data-button-type="login_with"
                data-layout="default"
                data-auto-logout-link="false"
                data-use-continue-as="false"
              ></div>

              {this.signupForm()}
            </Col>
          </Row>
        )}

        <SignupModal
          enableSignupForm={
            !this.props.comingFromDrawer && this.state.loginPopupTrue
          }
          handleonCancel={this.handleonCancel}
          showLoginPopup
          goTopreviousPage={this.props.goTopreviousPage}
        />
      </div>
    );
  }
}

export default withAppContext(withRouter(RegisterForm));
