import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../store/contextStore";
import {
  Card,
  Row,
  Col,
  Divider,
  Collapse,
  Button,
  Menu,
  Form,
  Upload,
  Dropdown,
  Input,
  Modal,
  Spin,
  notification,
  Select,
} from "antd";
import { Storage } from "aws-amplify";
import { update, noAuthadd } from "../../api/Apis";
import { EditTwoTone, CameraOutlined } from "@ant-design/icons";
import RichText from "../../common/RichText";
// import SendMessage from "../../common/SendMessage";
import Loading from "../../common/Loading";
// import { HeaderButton } from "../../StyledComponents/StyledComponents";
import { ModalTitle } from "../../StyledComponents/StyledComponents.js";
import { Panel as ColorPickerPanel } from "rc-color-picker";
// import tracyPic from "../../images/tracy1.jpeg";
import NumberFormat from "react-number-format";

const Panel = Collapse.Panel;
const { TextArea } = Input;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
class WhyJoinUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItemKey: "1",
      initialLoading: true,
      richtTextBiofield: "",
      titleOftheTab: "",
      contactImage: "",
      loading: false,
      showImageModal: false,
      backgroundColor: "",
      buttonColor: "",
      buttonbgColor: "",
      showEditModalText: "",
      imageLoading: false,
      contactPopup: false,
      to: "",
      from: "",
      email: "",
      phone: "",
      subject: "New contact form submitted on HomesListedBy",
      message: "",
      selectedEmail: "",
      contactName: "",
      contactName2: "",
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        initialLoading: false,
      });
    }, 2000);
  };

  handleMailForm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSendMail = async () => {
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));

    if (this.state.email === "") {
      alert("Please enter email");
      return;
    } else if (this.state.subject === "") {
      alert("Please enter subject");
      return;
    } else if (this.state.message === "") {
      alert("Please enter message");
      return;
    } else {
      const data = {
        entityData: {
          // to: process.env.REACT_APP_AGENT_EMAIL,
          to: this.state.selectedEmail
            ? this.state.selectedEmail ===
              orgDetails.contactUsPage.contact1.name
              ? orgDetails.contactUsPage.contact1.email
              : orgDetails.contactUsPage.contact2.email
            : this.state.contactName === orgDetails.contactUsPage.contact1.name
            ? orgDetails.contactUsPage.contact1.email
            : orgDetails.contactUsPage.contact2.email,
          toName: this.state.selectedEmail || this.state.contactName,
          from: this.state.from,
          email: this.state.email,
          phone: this.state.phone,
          subject: this.state.subject,
          message: this.state.message,
          orgId: orgDetails.id,
        },
      };
      console.log(data, "dtaa");
      const response = await noAuthadd("submitContactForm", data);
      if (response.success) {
        this.handleCancel();
        notification["success"]({
          message: "You have successfully sent your message. Thank you.",
        });
      } else {
        notification["error"]({
          message: "Message sent failed.",
        });
        this.setState({ contactPopup: false });
      }
    }
  };

  handleCancel = () => {
    this.setState({
      contactPopup: false,
      to: "",
      from: "",
      email: "",
      phone: "",
      subject: "New contact form submitted on HomesListedBy",
      message: "",
      loading: false,
    });
  };

  handleMenuItems = (e) => {
    if (e.key) {
      this.setState({
        activeItemKey: e.key,
        viderTabs: [],
      });
    }
  };

  handleRichtextBio = (bio) => {
    this.setState({
      richtTextBiofield: bio,
    });
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  contactImage: result.key,
                  imageLoading: false,
                });
              });
            } else {
              this.setState({
                imageLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default: {
        this.setState({
          imageLoading: false,
        });
      }
    }
  };

  saveWhyJoinUspage = (newData) => {
    const {
      landingScreen,
      Header,
      footer,
      WhyJoinUsPage,
      aboutUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;
    const { titleOftheTab, richtTextBiofield } = this.state;

    WhyJoinUsPage.profile_tabs?.map((s) => {
      if (s.tab_key === newData.tab_key) {
        s["description"] = richtTextBiofield;
        s["title_name"] =
          titleOftheTab !== "" ? titleOftheTab : s["title_name"];
      }
      return true;
    });

    let data = {
      entityData: {
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        contactUsPage,
        WhyJoinUsPage,
      },
    };

    this.onFinish(data);
  };

  handleVideoLinkTitle = (e, tab, video_tab) => {
    const { WhyJoinUsPage } = this.props.context.state.websiteThemes;
    let newTabTitle = e.target.value;
    WhyJoinUsPage.profile_tabs?.map((v) => {
      if (v.tab_key === tab.tab_key) {
        v.videoStream?.map((videoLink) => {
          if (videoLink.tab_key === video_tab) {
            videoLink["tab_title"] = newTabTitle;
          }
          return true;
        });
      }
      return true;
    });
  };

  handleVideoUrl = (e, tab, video_tab) => {
    const { WhyJoinUsPage } = this.props.context.state.websiteThemes;

    WhyJoinUsPage.profile_tabs?.map((v) => {
      if (v.tab_key === tab.tab_key) {
        v.videoStream?.map((videoLink) => {
          if (videoLink.tab_key === video_tab) {
            function getId(url) {
              const regExp =
                /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
              const match = url.match(regExp);

              return match && match[2].length === 11 ? match[2] : null;
            }

            const videoId = getId(e.target.value);
            const iframeVideoLink = `//www.youtube.com/embed/${videoId}`;

            videoLink["video_link"] = iframeVideoLink;
          }
          return true;
        });
      }
      return true;
    });
  };

  editwhyJoinUsPage = () => {
    const { WhyJoinUsPage } = this.props.context.state.websiteThemes;
    const { loading } = this.state;
    return (
      <div style={{ padding: "10px" }}>
        <Collapse bordered={false} defaultActiveKey={["1"]} accordion>
          {WhyJoinUsPage?.profile_tabs?.map((editCard) => (
            <>
              <Panel
                header={editCard.title_name}
                key={editCard.tab_key}
                className="customPanelStyle"
              >
                <Row style={{ padding: "10px" }}>
                  <Col span={24}>
                    <Form
                      initialValues={{ remember: true }}
                      onFinish={() => this.saveWhyJoinUspage(editCard)}
                      id="form"
                    >
                      <div className="form-titles">Title</div>
                      <Form.Item
                        name="title_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input First name",
                          },
                        ]}
                        initialValue={editCard.title_name}
                      >
                        <Input
                          placeholder="First Name"
                          className="form-input"
                          onChange={(e) =>
                            this.setState({
                              titleOftheTab: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                      <div className="form-titles">video links</div>
                      <Collapse
                        bordered={false}
                        defaultActiveKey={["1"]}
                        accordion
                      >
                        {editCard.videoStream?.map((videos) => (
                          <>
                            <Panel
                              header={videos.tab_title}
                              key={videos.tab_key}
                              className="customPanelStyle"
                            >
                              <Form
                                initialValues={{ remember: true }}
                                // onFinish={() => this.onFinish(editCard)}
                                id="form"
                              >
                                <div className="form-titles">Title</div>

                                <Form.Item
                                  name="tab_title"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input First name",
                                    },
                                  ]}
                                  initialValue={videos.tab_title}
                                >
                                  <Input
                                    placeholder="First Name"
                                    className="form-input"
                                    onChange={(e) =>
                                      this.handleVideoLinkTitle(
                                        e,
                                        editCard,
                                        videos.tab_key
                                      )
                                    }
                                  />
                                </Form.Item>
                                <div className="form-titles">URL</div>

                                <Form.Item
                                  name="vider_link"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input First name",
                                    },
                                  ]}
                                  initialValue={videos.video_link}
                                >
                                  <Input
                                    placeholder="First Name"
                                    className="form-input"
                                    onChange={(e) =>
                                      this.handleVideoUrl(
                                        e,
                                        editCard,
                                        videos.tab_key
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Form>
                            </Panel>
                          </>
                        ))}
                      </Collapse>
                      <div className="form-titles">Description</div>
                      <Form.Item
                        name="title_des"
                        rules={[
                          {
                            required: true,
                            message: "Please input Last name!",
                          },
                        ]}
                        initialValue={editCard.des1}
                      >
                        <RichText
                          handleRichtextBio={this.handleRichtextBio}
                          richtTextBiofieldProp={editCard.description}
                        />
                      </Form.Item>
                      <Button
                        key="back"
                        onClick={() =>
                          this.setState({
                            showImageModal: false,
                          })
                        }
                      >
                        Cancel
                      </Button>{" "}
                      &nbsp; &nbsp;
                      <Button
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Save
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Panel>
            </>
          ))}
        </Collapse>
      </div>
    );
  };

  contactDetails = () => {
    const {
      backgroundColor,
      contactImage,
      buttonColor,
      buttonbgColor,
      loading,
      imageLoading,
    } = this.state;
    const { WhyJoinUsPage } = this.props.context.state.websiteThemes;

    const bgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={backgroundColor}
          onChange={(e) =>
            this.setState({
              backgroundColor: e.color,
            })
          }
        />
      </div>
    );

    const ButtonColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={buttonColor}
          onChange={(e) =>
            this.setState({
              buttonColor: e.color,
            })
          }
        />
      </div>
    );

    const ButtonbgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={buttonbgColor}
          onChange={(e) =>
            this.setState({
              buttonbgColor: e.color,
            })
          }
        />
      </div>
    );

    return (
      <div>
        {WhyJoinUsPage && (
          <Row style={{ padding: "10px" }}>
            <Col span={24}>
              <Form id="form" onFinish={this.submitDetails}>
                <ModalTitle>Image</ModalTitle>
                <Form.Item
                  name="contactImage"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Card Logo!",
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    style={{
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    showUploadList={false}
                    customRequest={dummyRequest}
                    onChange={(e) => this.handleUploadPic(e)}
                  >
                    <Spin spinning={imageLoading}>
                      {contactImage ? (
                        <img
                          src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactImage}`}
                          alt="avatar"
                          style={{
                            width: "100px",
                            height: "100%",
                          }}
                        />
                      ) : WhyJoinUsPage.WhyJoinUs_contactDetails
                          .profile_picture ? (
                        <img
                          src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${WhyJoinUsPage.WhyJoinUs_contactDetails.profile_picture}`}
                          alt="avatar"
                          style={{
                            width: "100px",
                            height: "100%",
                          }}
                        />
                      ) : (
                        <CameraOutlined className="camera-icon" />
                      )}
                    </Spin>
                  </Upload>
                </Form.Item>
                <Row>
                  <Col span={11}>
                    <ModalTitle>Name</ModalTitle>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input title",
                        },
                      ]}
                      initialValue={
                        WhyJoinUsPage?.WhyJoinUs_contactDetails.founder_name
                      }
                      style={{ marginBottom: "1vw" }}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={11} offset={1}>
                    <ModalTitle>Email</ModalTitle>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input title",
                        },
                        {
                          type: "email",
                        },
                      ]}
                      initialValue={
                        WhyJoinUsPage?.WhyJoinUs_contactDetails.email
                      }
                      style={{ marginBottom: "1vw" }}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={11}>
                    <ModalTitle>Website Name</ModalTitle>
                    <Form.Item
                      name="webSiteName"
                      rules={[
                        {
                          required: true,
                          message: "Please input website name",
                        },
                      ]}
                      initialValue={
                        WhyJoinUsPage?.WhyJoinUs_contactDetails.website_name
                      }
                      style={{ marginBottom: "1vw" }}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={11} offset={1}>
                    <ModalTitle>Address</ModalTitle>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please input website address",
                        },
                      ]}
                      initialValue={
                        WhyJoinUsPage?.WhyJoinUs_contactDetails.address
                      }
                      style={{ marginBottom: "1vw" }}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={11}>
                    <ModalTitle>Phone</ModalTitle>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input number",
                        },
                      ]}
                      initialValue={
                        WhyJoinUsPage?.WhyJoinUs_contactDetails.phone_number
                      }
                      style={{ marginBottom: "1vw" }}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11} offset={1}>
                    <ModalTitle>Card Background Color</ModalTitle>

                    <Input
                      value={
                        backgroundColor
                          ? backgroundColor
                          : WhyJoinUsPage?.WhyJoinUs_contactDetails
                              .backgroundColor
                      }
                      onChange={(e) =>
                        this.setState({
                          backgroundColor: e.target.value,
                        })
                      }
                      style={{ height: "50px" }}
                      suffix={
                        <Dropdown trigger={["click"]} overlay={bgColor}>
                          <Button
                            style={{
                              background:
                                backgroundColor ||
                                WhyJoinUsPage.backgroundColor,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1vw" }}>
                  <Col span={7}>
                    <ModalTitle>Button Text</ModalTitle>
                    <Form.Item
                      name="buttonText"
                      rules={[
                        {
                          required: true,
                          message: "Please input button text!",
                        },
                      ]}
                      initialValue={WhyJoinUsPage?.WhyJoinUs_button?.buttonText}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Button Text Color</ModalTitle>

                    <Input
                      value={
                        buttonColor
                          ? buttonColor
                          : WhyJoinUsPage?.WhyJoinUs_button?.buttonColor
                      }
                      style={{ height: "50px" }}
                      onChange={(e) =>
                        this.setState({
                          buttonColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={ButtonColor}>
                          <Button
                            style={{
                              background:
                                buttonColor ||
                                WhyJoinUsPage?.WhyJoinUs_button?.buttonColor,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Button Background Color</ModalTitle>

                    <Input
                      value={
                        buttonbgColor
                          ? buttonbgColor
                          : WhyJoinUsPage?.WhyJoinUs_button?.buttonBackground
                      }
                      style={{ height: "50px" }}
                      onChange={(e) =>
                        this.setState({
                          buttonbgColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={ButtonbgColor}>
                          <Button
                            style={{
                              background:
                                buttonbgColor ||
                                WhyJoinUsPage?.WhyJoinUs_button
                                  ?.buttonBackground,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ float: "right" }}>
                  <Button
                    key="back"
                    onClick={() =>
                      this.setState({
                        showImageModal: false,
                      })
                    }
                  >
                    Cancel
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form="form"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  submitDetails = (values) => {
    this.setState({
      loading: true,
    });

    const {
      landingScreen,
      Header,
      footer,
      WhyJoinUsPage,
      aboutUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;
    const { contactImage, backgroundColor, buttonColor, buttonbgColor } =
      this.state;

    console.log(contactImage);

    let ButtonData = {
      buttonText: values["buttonText"]
        ? values["buttonText"]
        : WhyJoinUsPage["WhyJoinUs_button"]["buttonText"],
      buttonColor: buttonColor
        ? buttonColor
        : WhyJoinUsPage["WhyJoinUs_button"]["buttonColor"],
      buttonBackground: buttonbgColor
        ? buttonbgColor
        : WhyJoinUsPage["WhyJoinUs_button"]["buttonBackground"],
    };

    let contactData = {
      founder_name: values["name"]
        ? values["name"]
        : WhyJoinUsPage["founder_name"],
      email: values["email"] ? values["email"] : WhyJoinUsPage["email"],
      website_name: values["webSiteName"]
        ? values["webSiteName"]
        : WhyJoinUsPage["website_name"],
      phone_number: values["phone"]
        ? values["phone"]
        : WhyJoinUsPage["phone_number"],
      address: values["address"] ? values["address"] : WhyJoinUsPage["address"],
      profile_picture: contactImage
        ? contactImage
        : WhyJoinUsPage["profile_picture"],
      backgroundColor: backgroundColor
        ? backgroundColor
        : WhyJoinUsPage["WhyJoinUs_contactDetails"]["backgroundColor"],
    };

    WhyJoinUsPage["WhyJoinUs_contactDetails"] = contactData;
    WhyJoinUsPage["WhyJoinUs_button"] = ButtonData;

    let data = {
      entityData: {
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        contactUsPage,
        WhyJoinUsPage,
      },
    };

    this.onFinish(data);
  };

  onFinish = async (data) => {
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserProfile && loginUserProfile.orgId) {
      let websiteId = await loginUserProfile.orgId;
      data["entityData"]["isNew"] = false;
      let userData = {
        entityData: {
          isNew: false,
        },
      };

      update("saveWebsitesData", websiteId, data)
        .then((result) => {
          if (result.success) {
            update("users", loginUserProfile?.id, userData);
            this.setState({
              showTitle: "",
              showImageModal: false,
              loading: false,
            });
            return true;
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    }
  };

  render() {
    const {
      activeItemKey,
      showImageModal,
      showEditModalText,
      contactPopup,
      contactName,
      selectedEmail,
    } = this.state;
    const { WhyJoinUsPage } = this.props.context.state.websiteThemes;
    const { contactUsPage } = this.props.context.state.websiteThemes;
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    return (
      <div>
        {WhyJoinUsPage && (
          <div>
            <Loading enableLoading={this.state.initialLoading} />
            <Row>
              <Col span={20} offset={2}>
                <Divider>
                  <h1>{WhyJoinUsPage.title_name}</h1>
                </Divider>
              </Col>
            </Row>

            <Row>
              <Col
                xl={{ span: 20, offset: 2 }}
                lg={{ span: 20, offset: 2 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Card
                  bordered
                  title={null}
                  bodyStyle={{
                    padding: "0px",
                  }}
                  className="padding-card-whyjoin"
                >
                  <Row>
                    <Menu
                      mode={
                        window.screen.width >= "501" ? "horizontal" : "vertical"
                      }
                      defaultSelectedKeys={["1"]}
                      onClick={this.handleMenuItems}
                      style={{ width: "100%" }}
                    >
                      <Col
                        onClick={() =>
                          this.setState({
                            showImageModal: true,
                            showEditModalText: "Tabs",
                            contactImage: "",
                          })
                        }
                        style={{ cursor: "pointer", float: "right" }}
                      >
                        {loginUserProfile &&
                          loginUserProfile?.userProfile === "A" && (
                            <EditTwoTone style={{ fontSize: "20px" }} />
                          )}
                      </Col>
                      {WhyJoinUsPage.profile_tabs?.map((tabs) => (
                        <Menu.Item key={tabs.tab_key}>
                          {tabs.title_name}
                        </Menu.Item>
                      ))}
                    </Menu>
                    {WhyJoinUsPage.profile_tabs?.map((eachDes) => (
                      <>
                        {activeItemKey === `${eachDes.tab_key}` && (
                          <>
                            <p
                              style={{
                                textAlign: "justify",
                                lineHeight: 2,
                                fontSize: "16px",
                                letterSpacing: "0.5px",
                                marginTop: "1vw",
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${eachDes.description}`,
                                }}
                              />
                            </p>
                            <Row className="width-whyjoinus">
                              <Col
                                span={24}
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  backgroundColor: "#fafafa",
                                }}
                              >
                                <Collapse
                                  accordion
                                  bordered={false}
                                  defaultActiveKey={["1"]}
                                >
                                  {eachDes.videoStream?.map((eachTab) => (
                                    <>
                                      <Panel
                                        header={eachTab.tab_title}
                                        key={eachTab.tab_key}
                                        className="customPanelStyle"
                                      >
                                        <div class="video-container">
                                          <iframe
                                            src={eachTab.video_link}
                                            frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                            title="freeAccount"
                                          ></iframe>
                                        </div>
                                      </Panel>
                                    </>
                                  ))}
                                </Collapse>
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    ))}
                    {/* {activeItemKey === "2" && (
                      <>
                        <p
                          style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: "16px",
                            letterSpacing: "0.5px",
                            marginTop: "1vw",
                          }}
                        >
                          <p>{WhyJoinUsPage.profile_tabs.agent_tab.des1}</p>

                          <p>{WhyJoinUsPage.profile_tabs.agent_tab.des2}</p>
                        </p>
                        <Row className="width-whyjoinus">
                          <Col
                            span={24}
                            style={{
                              marginTop: "20px",
                              marginBottom: "20px",
                              backgroundColor: "#fafafa",
                            }}
                          >
                            <Collapse
                              accordion
                              bordered={false}
                              defaultActiveKey={["1"]}
                            >
                              {WhyJoinUsPage.profile_tabs.agent_tab.videoStream?.map(
                                (eachTab) => (
                                  <>
                                    <Panel
                                      header={eachTab.tab_title}
                                      key={eachTab.tab_key}
                                      className="customPanelStyle"
                                    >
                                      <div class="video-container">
                                        <iframe
                                          src={eachTab.video_link}
                                          frameborder="0"
                                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                          allowfullscreen
                                          title="freeAccount"
                                        ></iframe>
                                      </div>
                                    </Panel>
                                  </>
                                )
                              )}
                            </Collapse>
                          </Col>
                        </Row>
                      </>
                    )}
                    {activeItemKey === "3" && (
                      <>
                        <p
                          style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: "16px",
                            letterSpacing: "0.5px",
                            marginTop: "1vw",
                          }}
                        >
                          {WhyJoinUsPage.profile_tabs.lender_tab.des1}
                        </p>
                        <Row className="width-whyjoinus">
                          <Col
                            span={24}
                            style={{
                              marginTop: "20px",
                              marginBottom: "20px",
                              backgroundColor: "#fafafa",
                            }}
                          >
                            <Collapse bordered={false} defaultActiveKey={["1"]}>
                              {WhyJoinUsPage.profile_tabs.lender_tab.videoStream?.map(
                                (eachTab) => (
                                  <Panel
                                    header={eachTab.tab_title}
                                    key={eachTab.tab_key}
                                    className="customPanelStyle"
                                  >
                                    <div class="video-container">
                                      <iframe
                                        src={eachTab.video_link}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        title="customPanel"
                                      ></iframe>
                                    </div>
                                  </Panel>
                                )
                              )}
                            </Collapse>
                          </Col>
                        </Row>
                      </>
                    )}
                    {activeItemKey === "4" && (
                      <>
                        <p
                          style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: "16px",
                            letterSpacing: "0.5px",
                            marginTop: "1vw",
                          }}
                        >
                          {WhyJoinUsPage.profile_tabs.vendor_tab.des1}
                        </p>
                        <Row className="width-whyjoinus">
                          <Col
                            span={24}
                            style={{
                              marginTop: "20px",
                              marginBottom: "20px",
                              backgroundColor: "#fafafa",
                            }}
                          >
                            <Collapse bordered={false} defaultActiveKey={["1"]}>
                              {WhyJoinUsPage.profile_tabs.vendor_tab.videoStream?.map(
                                (eachTab) => (
                                  <Panel
                                    header={eachTab.tab_title}
                                    key={eachTab.tab_key}
                                    className="customPanelStyle"
                                  >
                                    <div class="video-container">
                                      <iframe
                                        src={eachTab.video_link}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        title="learnMore"
                                      ></iframe>
                                    </div>
                                  </Panel>
                                )
                              )}
                            </Collapse>
                          </Col>
                        </Row>
                      </>
                    )} */}
                  </Row>
                  {/* <Row style={{ float: "right", padding: "10px" }}>
                    <HeaderButton
                      color={WhyJoinUsPage.WhyJoinUs_button}
                      onClick={() => {
                        this.props.history.push("/register");
                      }}
                    >
                      {WhyJoinUsPage.WhyJoinUs_button.buttonText}
                    </HeaderButton>
                  </Row> */}
                </Card>
              </Col>
            </Row>

            <Row style={{ marginBottom: "1vw" }}>
              <Col
                xl={{ span: 20, offset: 2 }}
                lg={{ span: 20, offset: 2 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Row style={{ marginTop: "10px", marginBottom: "-10px" }}>
                  <h2>{WhyJoinUsPage.WhyJoinUs_contactDetails.des}</h2>
                </Row>

                <Row gutter={16}>
                  <Col
                    xl={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    className="card-description1"
                  >
                    {/* {userLogin && (
                      <Col
                        // onClick={() =>
                        //   this.setState({
                        //     showImageModal: true,
                        //     showEditModalText: "card",
                        //   })
                        // }
                        onClick={() => window.location("/contactUs")}
                        style={{ cursor: "pointer", float: "right" }}
                      >
                        {loginUserProfile &&
                          loginUserProfile?.userProfile === "A" && (
                            <EditTwoTone style={{ fontSize: "20px" }} />
                          )}
                      </Col>
                    )} */}
                    <Card
                      hoverable
                      bordered={false}
                      title={null}
                      bodyStyle={{ padding: "0px" }}
                      className="padding-card1-whyjoinUs"
                      style={{
                        background:
                          WhyJoinUsPage.WhyJoinUs_contactDetails
                            .backgroundColor,
                        borderColor: "black",
                        marginTop: "1vw",
                      }}
                    >
                      <Row>
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <img
                            alt="Tracy"
                            src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactUsPage.contact1?.profile_picture}`}
                            style={{
                              boxShadow: "0px 0px 10px 0px",
                              border: "6px solid rgb(28, 45, 65)",
                              width: "100%",
                              // height: "100%",
                              height: "250px",
                              objectFit: "contain",
                            }}
                          />
                        </Col>
                        <Col
                          xl={{ span: 14, offset: 2 }}
                          lg={{ span: 14, offset: 2 }}
                          md={{ span: 14, offset: 2 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                          className="card-description"
                        >
                          <h3 style={{ fontWeight: "bold" }}>
                            {contactUsPage.contact1.name +
                              ", " +
                              contactUsPage?.contact1?.designation}
                          </h3>
                          <h3>{contactUsPage?.contact1?.website_name}</h3>
                          <h3>{contactUsPage?.contact1?.address}</h3>
                          <h3>Email : {contactUsPage?.contact1?.email}</h3>
                          <h3>
                            Phone :{" "}
                            {
                              <NumberFormat
                                format="(###) ###-####"
                                value={contactUsPage?.contact1?.phone_number}
                                displayType={"text"}
                              />
                            }
                          </h3>
                          <Button
                            className="button_data"
                            style={{ background: "#3045d2", Color: "#FFF" }}
                            onClick={() =>
                              this.setState({
                                contactPopup: true,
                                contactName: contactUsPage?.contact1?.name,
                              })
                            }
                          >
                            Contact me
                          </Button>
                          {/* <SendMessage
                            toName={
                              WhyJoinUsPage?.WhyJoinUs_contactDetails
                                .founder_name
                            }
                            msgButtonTitle={
                              WhyJoinUsPage?.WhyJoinUs_button.buttonText
                            }
                            buttonAlign="Contact-Me"
                            mlsId={false}
                            agentEmail={contactUsPage?.contact1?.email}
                            messageType=""
                            propertyTitle="PoweredBy"
                            bgColor={
                              WhyJoinUsPage?.WhyJoinUs_button.buttonBackground
                            }
                            color={WhyJoinUsPage?.WhyJoinUs_button.buttonColor}
                          /> */}
                        </Col>
                      </Row>
                      {/* </Col> */}
                    </Card>
                  </Col>

                  <Col
                    xl={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    className="card-description1"
                  >
                    {/* {userLogin && (
                      <Col
                        onClick={() => window.location("/contactUs")}
                        style={{ cursor: "pointer", float: "right" }}
                      >
                        {loginUserProfile &&
                          loginUserProfile?.userProfile === "A" && (
                            <EditTwoTone style={{ fontSize: "20px" }} />
                          )}
                      </Col>
                    )} */}
                    <Card
                      hoverable
                      bordered={false}
                      title={null}
                      bodyStyle={{ padding: "0px" }}
                      className="padding-card1-whyjoinUs"
                      style={{
                        background:
                          WhyJoinUsPage.WhyJoinUs_contactDetails
                            .backgroundColor,
                        borderColor: "black",
                        marginTop: "1vw",
                      }}
                    >
                      <Row>
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <img
                            alt="Tracy"
                            src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactUsPage.contact2?.profile_picture}`}
                            style={{
                              boxShadow: "0px 0px 10px 0px",
                              border: "6px solid rgb(28, 45, 65)",
                              width: "100%",
                              height: "250px",
                              objectFit: "contain",
                            }}
                          />
                        </Col>
                        <Col
                          xl={{ span: 14, offset: 2 }}
                          lg={{ span: 14, offset: 2 }}
                          md={{ span: 14, offset: 2 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                          className="card-description"
                        >
                          <h3 style={{ fontWeight: "bold" }}>
                            {contactUsPage.contact2.name +
                              ", " +
                              contactUsPage?.contact2?.designation}
                          </h3>
                          <h3>{contactUsPage?.contact2?.website_name}</h3>
                          <h3>{contactUsPage?.contact2?.address}</h3>
                          <h3>Email : {contactUsPage?.contact2?.email}</h3>
                          <h3>
                            Phone :{" "}
                            {
                              <NumberFormat
                                format="(###) ###-####"
                                value={contactUsPage?.contact2?.phone_number}
                                displayType={"text"}
                              />
                            }
                          </h3>

                          <Button
                            className="button_data"
                            style={{ background: "#3045d2", Color: "#FFF" }}
                            onClick={() =>
                              this.setState({
                                contactName: contactUsPage?.contact2?.name,
                                contactPopup: true,
                              })
                            }
                          >
                            Contact me
                          </Button>
                          {/* <SendMessage
                            toName={
                              WhyJoinUsPage?.WhyJoinUs_contactDetails
                                .founder_name
                            }
                            msgButtonTitle={
                              WhyJoinUsPage?.WhyJoinUs_button.buttonText
                            }
                            buttonAlign="Contact-Me"
                            mlsId={false}
                            agentEmail={contactUsPage?.contact2?.email}
                            messageType=""
                            propertyTitle="PoweredBy"
                            bgColor={
                              WhyJoinUsPage?.WhyJoinUs_button.buttonBackground
                            }
                            color={WhyJoinUsPage?.WhyJoinUs_button.buttonColor}
                          /> */}
                        </Col>
                      </Row>
                      {/* </Col> */}
                    </Card>
                  </Col>
                  {/* <Col
                    xl={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    className="card-description1"
                  >
                    <Card
                      hoverable
                      bordered={false}
                      title={null}
                      bodyStyle={{ padding: "0px" }}
                      className="padding-card1-whyjoin"
                      style={{
                        background: "#FFF",
                        // padding: "30px",
                        borderColor: "black",
                        marginTop: "1vw",
                      }}
                    >
                      <Row>
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <img
                            alt="Kristin"
                            // src={kristinPic}
                            style={{
                              boxShadow: "0px 0px 10px 0px",
                              border: "6px solid rgb(28, 45, 65)",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </Col>
                        <Col
                          xl={{ span: 14, offset: 2 }}
                          lg={{ span: 14, offset: 2 }}
                          md={{ span: 14, offset: 2 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                          className="card-description"
                        >
                          <h3 style={{ fontWeight: "bold" }}>
                            Kristin Barber, Marketing Director
                          </h3>
                          <h3>Powered By Agents, Inc.</h3>
                          <h3>14 Linda Drive, Westminster, MA 01473</h3>
                          <h3>Email : kristin@homesListedby.com</h3>
                          <h3>Phone : (978) 407-4070</h3>
                          <SendMessage
                            toName={"Kristin Barber"}
                            msgButtonTitle={"Contact Me"}
                            buttonAlign="Contact-Me"
                            mlsId={false}
                            agentEmail="kristin@homesListedby.com"
                            messageType=""
                            propertyTitle=" HomesListedBy"
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </div>
        )}

        <Modal
          className="modal-black-close"
          visible={showImageModal}
          title={
            showEditModalText === "Tabs" ? "Tab Changes" : "Contact Changes"
          }
          onCancel={() =>
            this.setState({
              showImageModal: false,
              showEditModalText: "",
            })
          }
          footer={null}
          width="45%"
        >
          {showEditModalText === "Tabs"
            ? this.editwhyJoinUsPage()
            : this.contactDetails()}
        </Modal>

        <Modal
          className="modal-black-close"
          visible={contactPopup}
          title={"Contact us anytime for questions, suggestions, etc"}
          onCancel={
            (() => {
              this.setState({
                contactPopup: false,
                contactName: "",
              });
            },
            this.handleCancel)
          }
          footer={null}
          maskClosable={false}
          width="45%"
        >
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            <Card
              bordered={false}
              title={null}
              bodyStyle={{ padding: "15px" }}
              style={{
                borderColor: "black",
                marginTop: "1vw",
                marginBottom: "1vw",
                height: "fit-Content",
              }}
              className="padding-card1-whyjoin"
            >
              <Row className="padding">
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                  md={{ span: 4 }}
                  xs={{ span: 5 }}
                  sm={{ span: 5 }}
                >
                  <span className="color-grey  terms">To :</span>
                </Col>
                <Col
                  xl={{ span: 18, offset: 2 }}
                  lg={{ span: 18, offset: 2 }}
                  md={{ span: 18, offset: 2 }}
                  xs={{ span: 18, offset: 1 }}
                  sm={{ span: 18, offset: 1 }}
                >
                  <Select
                    style={{ width: "100%" }}
                    bordered={false}
                    value={selectedEmail || contactName}
                    onChange={(value) => {
                      this.setState({
                        selectedEmail: value,
                      });
                    }}
                  >
                    <option value={contactUsPage?.contact1?.name} label="data">
                      {contactUsPage?.contact1?.name}
                    </option>
                    <option value={contactUsPage?.contact2?.name}>
                      {contactUsPage?.contact2?.name}
                    </option>
                  </Select>
                </Col>
              </Row>
              <Divider style={{ margin: "5px" }}></Divider>

              <Row className="padding">
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                  md={{ span: 4 }}
                  xs={{ span: 5 }}
                  sm={{ span: 5 }}
                >
                  <span className="color-grey  terms">Name :</span>
                </Col>
                <Col
                  xl={{ span: 18, offset: 2 }}
                  lg={{ span: 18, offset: 2 }}
                  md={{ span: 18, offset: 2 }}
                  xs={{ span: 18, offset: 1 }}
                  sm={{ span: 18, offset: 1 }}
                >
                  <Input
                    disabled={this.state.disableEdit}
                    name="from"
                    placeholder="Enter Your Name"
                    className="form-input"
                    value={this.state.from}
                    onChange={this.handleMailForm}
                    style={{
                      border: "1px solid #fff",
                      color: "black",
                      background: "white",
                    }}
                  />
                </Col>
              </Row>
              <Divider style={{ margin: "5px" }}></Divider>

              <Row className="padding">
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                  md={{ span: 4 }}
                  xs={{ span: 5 }}
                  sm={{ span: 5 }}
                >
                  <span className="color-grey terms">Email :</span>
                </Col>
                <Col
                  xl={{ span: 18, offset: 2 }}
                  lg={{ span: 18, offset: 2 }}
                  md={{ span: 18, offset: 2 }}
                  xs={{ span: 18, offset: 1 }}
                  sm={{ span: 18, offset: 1 }}
                >
                  <Input
                    disabled={this.state.disableEdit}
                    name="email"
                    placeholder="Enter Your Email"
                    className="form-input"
                    value={this.state.email}
                    onChange={this.handleMailForm}
                    style={{
                      border: "1px solid #fff",
                      color: "black",
                      background: "white",
                    }}
                  />
                </Col>
              </Row>

              <Divider style={{ margin: "5px" }}></Divider>

              <Row className="padding">
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 4 }}
                  md={{ span: 4 }}
                  xs={{ span: 5 }}
                  sm={{ span: 5 }}
                >
                  <span className="color-grey terms">Phone :</span>
                </Col>
                <Col
                  xl={{ span: 18, offset: 2 }}
                  lg={{ span: 18, offset: 2 }}
                  md={{ span: 18, offset: 2 }}
                  xs={{ span: 18, offset: 1 }}
                  sm={{ span: 18, offset: 1 }}
                >
                  <Input
                    disabled={this.state.disableEdit}
                    name="phone"
                    placeholder="Enter Your Phone"
                    className="form-input"
                    value={this.state.phone}
                    onChange={this.handleMailForm}
                    style={{
                      border: "1px solid #fff",
                      color: "black",
                      background: "white",
                    }}
                  />
                </Col>
              </Row>

              <Divider style={{ margin: "5px" }}></Divider>

              <Row className="padding">
                <Col
                  xl={{ span: 4 }}
                  lg={{ span: 5 }}
                  md={{ span: 4 }}
                  xs={{ span: 5 }}
                  sm={{ span: 5 }}
                >
                  <span className="color-grey terms">Message :</span>
                </Col>
                <Col
                  xl={{ span: 18, offset: 2 }}
                  lg={{ span: 17, offset: 2 }}
                  md={{ span: 18, offset: 2 }}
                  xs={{ span: 18, offset: 1 }}
                  sm={{ span: 18, offset: 1 }}
                >
                  <TextArea
                    placeholder="Start Typing.."
                    rows={3}
                    name="message"
                    value={this.state.message}
                    onChange={this.handleMailForm}
                    style={{ border: "1px solid #fff" }}
                  />
                </Col>
              </Row>

              <Divider style={{ margin: "5px" }}></Divider>
              <div style={{ float: "right" }}>
                <Button
                  key="submit"
                  type="primary"
                  loading={this.state.loading}
                  onClick={this.handleSendMail}
                  className="send-button-mobile-view"
                >
                  <h6
                    style={{
                      color: "#FFF",
                      fontWeight: "bold",
                      fontSize: "14px",
                      marginTop: "5px",
                    }}
                  >
                    Send
                  </h6>
                </Button>
              </div>
            </Card>
          </Col>
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(WhyJoinUs));
