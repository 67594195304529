import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Divider,
  Button,
  Menu,
  notification,
  Rate,
  Input,
  Modal,
} from "antd";
import {
  EditTwoTone,
  ArrowLeftOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  InstagramOutlined,
  GooglePlusOutlined,
  YoutubeOutlined,
  SkypeOutlined,
  GoogleOutlined,
  CheckCircleTwoTone,
  SearchOutlined,
  ShareAltOutlined,
  CopyOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
// import { Scrollbars } from "react-custom-scrollbars";
import { withAppContext } from "../../store/contextStore";
import NumberFormat from "react-number-format";
import { noAuthgetDetails, noAuthGetInfo, update } from "../../api/Apis";
import SendMessage from "../../common/SendMessage";
import Subscriptions from "../Subscriptions/Subscriptions";
import Testmonials from "../../common/Testmonials";
import MLShomes from "../../common/MLShomes";
import LocalHomes from "../../common/LocalHomes";
import BuyersListing from "../../common/BuyersListing";
import PortfoliosList from "../../common/PortfoliosList";
import Loading from "../../common/Loading";
import DataNotFound from "../../common/DataNotFound";
import Lenders from "./Lenders";
// import Missleads from "../MissLeads/Missleads";
import Invitation from "../../common/Invitation";
class ViewProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      imageLoading: true,
      profileExperience: "",
      agentMlsId: "",
      profileName: "",
      profilePhone: "",
      profilleSpecialtyName: "",
      profilleEmail: "",
      reviewerName: "",
      reviewDate: "",
      reviewDiscription: "",
      addReviewerName: "",
      addReviewDate: "",
      addReviewDiscription: "",
      imageKeys: [],
      saveDetailsFalse: false,
      editingBio: false,
      editTestmonials: false,
      userBio: "",
      selectedProps: "updatinggg",
      setFalse: true,
      addTestmonialFalse: false,
      testmonialsList: [],
      testimonialSpinning: false,
      editTestimonial: false,
      editTestimonialIndex: "",
      countName: "false",
      profilePicture: "",
      userDetails: "",
      activeItemKey: "",
      homesList: [],
      id: "",
      buyerHomesList: [],
      menuForDesktopViewTrue: false,
      userLogin: false,
      EditIcon: false,
      dataLoading: false,
      requiredUserId: "",
      userName: "",
      tabs: {
        login: {
          V: ["Testimonials", "Portfolio", "Subscriptions"],
          L: ["Testimonials", "Portfolio", "Subscriptions"],
          AG: [
            "MLS Homes",
            "Homes",
            "Buyers",
            // "Leads",
            "Testimonials",
            "Portfolio",
            // "Subscriptions",
          ],
          concierge: [
            "MLS Homes",
            "Homes",
            "Buyers",
            // "Leads",
            "Testimonials",
            "Portfolio",
            // "Subscriptions",
          ],
          S: ["Sell", "Buy"],
          B: ["Sell", "Buy"],
          SB: ["Sell", "Buy"],
        },
        "non-login": {
          V: ["Testimonials", "Portfolio"],
          L: ["Testimonials", "Portfolio"],
          AG: ["Homes", "Buyers", "Testimonials", "Portfolio"],
          concierge: ["Homes", "Buyers", "Testimonials", "Portfolio"],
          S: ["Sell", "Buy"],
          B: ["Sell", "Buy"],
        },
      },
      visibleTabs: [],
      lendersEmails: [],
      agentMlsList: [],

      enableInviteLenersPopUp: false,
    };
  }

  componentDidMount() {
    this.profileInit();
  }

  profileInit = async () => {
    const data12 = "";
    this.props.context.setHomeDetails(data12, "EMPTY_DATA");
    this.props.context.setBuyerHomeDetails(data12, "BUYER_EMPTY_DATA");
    const { userDetails } = this.props.context.state;
    let userDetailsId = JSON.parse(await localStorage.getItem("userDetails"));

    let validUserId =
      userDetails && userDetails.id
        ? userDetails.id
        : userDetailsId && userDetailsId.id;

    if (window.screen.width >= 1023) {
      this.setState({
        menuForDesktopViewTrue: true,
      });
    }

    let validateUsername =
      this.props &&
      this.props.match.params &&
      this.props.match.params.userName &&
      this.props.match.params.userName !== "" &&
      this.props.match.params.userName !== null &&
      this.props.match.params.userName !== undefined
        ? this.props.match.params.userName
        : "";

    let userId = "";
    // retrieve userId
    if (validateUsername !== "") {
      localStorage.setItem("profileBasedURL", JSON.stringify(validateUsername));
      try {
        const checkUserApi = await noAuthGetInfo(
          "checkUser/" + validateUsername
        );
        if (checkUserApi.success) {
          userId =
            checkUserApi.data &&
            checkUserApi.data[0] &&
            checkUserApi.data[0].id;
        }
      } catch (error) {
        alert("Something went wrong.!");
      }
    } else {
      userId =
        this.props &&
        this.props.match.params &&
        this.props.match.params.id &&
        this.props.match.params.id;
    }

    let agentId =
      validUserId &&
      validUserId !== null &&
      validUserId !== undefined &&
      validUserId !== ""
        ? validUserId
        : "";

    if (agentId === userId) {
      this.getUserProfile(userId, true);
    } else {
      this.getUserProfile(userId, false);
    }
  };

  /******* GETTING USER INFO BASED ON PARAMS ID *******/
  getUserProfile = async (userId, editPermissions) => {
    this.setState({
      EditIcon: editPermissions,
      requiredUserId: userId,
    });
    try {
      const userProfileDetails = await noAuthgetDetails("getUsers", userId);
      if (userProfileDetails.success) {
        let userType = userProfileDetails.data.userProfile;

        // let lendersEmailsInfo = [];
        // userProfileDetails &&
        //   userProfileDetails.data &&
        //   userProfileDetails.data.lenderInfo &&
        //   userProfileDetails.data.lenderInfo.map((lenderEmail) =>
        //     lendersEmailsInfo.push(lenderEmail.value)
        //   );
        if (window.location.pathname === `/sellerBuyer/viewProfile/${userId}`) {
          this.setState({
            imageLoading: false,
            userDetails: userProfileDetails.data,
            agentMlsId: userProfileDetails.data.mlsId,
            agentMlsList: userProfileDetails.data.mlsSateList,
            profilePicture:
              userProfileDetails.data && userProfileDetails.data.profilePicture2
                ? userProfileDetails.data.profilePicture2[0]
                  ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                    userProfileDetails.data.profilePicture2[0]
                  : ""
                : "",

            visibleTabs: this.state.tabs["login"]["SB"],
            activeItemKey: "Sell",
            lendersEmails: userProfileDetails.data.lenderInfo,
          });
        } else {
          this.setState({
            imageLoading: false,
            userDetails: userProfileDetails.data,
            agentMlsId: userProfileDetails.data.mlsId,
            agentMlsList: userProfileDetails.data.mlsSateList,
            profilePicture:
              userProfileDetails.data &&
              userProfileDetails.data.profilePicture &&
              userProfileDetails.data.profilePicture[0]
                ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                  userProfileDetails.data.profilePicture[0]
                : "",
            visibleTabs:
              this.state.tabs[editPermissions ? "login" : "non-login"][
                userType
              ],
            activeItemKey:
              editPermissions && (userType === "AG" || userType === "concierge")
                ? "MLS Homes"
                : userType === "V" || userType === "L"
                ? "Testimonials"
                : userType === "S" || userType === "B"
                ? "Sell"
                : "Homes",
            lendersEmails: userProfileDetails.data.lenderInfo,
          });
        }
      }
      this.props.context.handlePagePathname(window.location.href);
    } catch (error) {
      console.log(error.response, "error");
    }
  };

  /******* TABS HANDLING ********/
  handleMenuItems = (e) => {
    if (e.key) {
      this.setState({
        activeItemKey: e.key,
      });
    }
  };

  /********** REDIRECT TO ADD HOME  ***********/
  handleAddhome = async () => {
    localStorage.removeItem("buyerData", "");
    const { activeItemKey } = this.state;
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.props.context.setLoading(true);
    if (
      userDetails &&
      userDetails.userProfile !== "AG" &&
      userDetails.userProfile !== "concierge"
    ) {
      if (activeItemKey === "Sell") {
        await this.props.history.push("/add/userHomeForm");
      } else {
        await this.props.history.push("/add/buyerform");
      }
    } else {
      if (activeItemKey === "Homes") {
        await this.props.history.push(`/add/sellerProfile`);
      } else {
        await this.props.history.push("/add/buyerProfile");
      }
    }
  };

  /*********** STRING FAVORITE DATA TO CONTEXT STATE ***********/
  handleFavoritesCount = (favoritesData, favoritesDataLength) => {
    this.props.context.handleFavoritesCount(favoritesData, favoritesDataLength);
  };

  /***** EDIT USER PROFILE ******/
  handleEditProfile = (e) => {
    this.setState({
      setFalse: false,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleBack = () => {
    const { requiredUserId } = this.state;
    this.props.location.state === "DetailPage" ||
    this.props.location.state === "speakToBuyerAgent" ||
    this.props.location.state === "getPreApprovedForaLoan"
      ? this.props.history.goBack()
      : window.location.pathname === `/agent/viewProfile/${requiredUserId}` ||
        this.props.location.state === "Agents"
      ? this.props.location.state === "buyersAgent" ? this.props.history.push("/buyers") 
      : this.props.history.push("/agent")
      : window.location.pathname === `/lenders/viewProfile/${requiredUserId}` ||
        this.props.location.state === "Lender"
      ? this.props.history.push("/lenders")
      : window.location.pathname ===
          `/marketplace/viewProfile/${requiredUserId}` ||
        this.props.location.state === "Vendor"
      ? this.props.history.push("/marketplace")
      : this.props.history.goBack();
  };

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  /*****  Sharing user profile Start  ***/

  copyUrl = () => {
    var textBox = document.getElementById("shareProfileUrl");
    textBox.select();
    document.execCommand("copy");
    notification["info"]({
      message: "URL copied successfully",
    });
  };

  handleUserName = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  checkUserName = async () => {
    if (this.state.userName === "") {
      notification["error"]({
        message: "Please enter username",
      });
      return;
    }
    const checkAvailability = await noAuthGetInfo(
      "checkUser/" + this.state.userName
    );
    if (checkAvailability.data.length > 0) {
      notification["error"]({
        message: "Username already exist!",
        description: "Try again",
      });
    } else {
      this.setState({
        previewProfileUrl: true,
      });
    }
  };

  updateUsername = async () => {
    this.setState({ dataLoading: true });
    const { userDetails, userName } = this.state;
    const data = {
      entityData: {
        userName: userName,
      },
    };
    const updatingUserDetails = await update("users", userDetails.id, data);
    if (updatingUserDetails.success) {
      this.profileInit();
      this.resetUserInfo();
      notification["success"]({
        message: "Username saved successfully. Thank you.",
      });
    } else {
      this.resetUserInfo();
      notification["error"]({
        message: "Somethig Went Wrong",
        description: "Please try again",
      });
    }
  };

  resetUserInfo = () => {
    this.setState({
      userName: "",
      dataLoading: false,
      previewProfileUrl: false,
      enableShareProfileUrl: false,
    });
  };

  /*****  Sharing user profile End  ***/

  /***** LEFT SIDE BIO ***/
  handleEditBio = () => {
    this.setState({
      editingBio: true,
      editing: true,
    });
  };

  showBioData = () => {
    const { editing, userDetails } = this.state;
    return (
      <>
        {!editing && (
          <>
            <Card bordered={false} style={{ marginTop: "3vw" }}>
              {userDetails &&
                (userDetails.userProfile === "AG" ||
                  userDetails.userProfile !== "concierge") && (
                  <>
                    <Row>
                      <Col span={12}>
                        <span className="profile-view-name">Bio</span>
                      </Col>
                      <Col span={2} offset={10} style={{ cursor: "pointer" }}>
                        {window.location.pathname === "viewProfile" && (
                          <Link
                            to={`/myProfile/${userDetails && userDetails.id}`}
                          >
                            <EditTwoTone style={{ fontSize: "28px" }} />
                          </Link>
                        )}
                      </Col>
                    </Row>
                    <Divider style={{ backgroundColor: "#41455534" }}></Divider>
                    <Col>
                      <span className="bio-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${
                              userDetails.userBio ? userDetails.userBio : ""
                            }`,
                          }}
                        />
                      </span>
                    </Col>
                  </>
                )}
              {userDetails &&
                "newdata" in userDetails === true &&
                "hobbies" in userDetails.newdata === true &&
                userDetails?.newdata?.hobbies !== "" && (
                  <Col
                    xl={{ span: 23 }}
                    lg={{ span: 23 }}
                    md={{ span: 23 }}
                    sm={{ span: 23 }}
                    xs={{ span: 23 }}
                    className="profile-details-bio"
                  >
                    <Row>
                      <span style={{ color: "gray" }}>Hobbies : </span>
                    </Row>
                    <Row>
                      <span style={{ textAlign: "justify" }}>
                        {userDetails
                          ? userDetails.newdata
                            ? userDetails?.newdata?.hobbies
                            : ""
                          : ""}
                      </span>
                    </Row>
                  </Col>
                )}
            </Card>
          </>
        )}
      </>
    );
  };
  handleCloseInvitationModal = () => {
    this.setState({
      enableInviteLenersPopUp: false,
    });
  };
  render() {
    const {
      activeItemKey,
      menuForDesktopViewTrue,
      EditIcon,
      profilePicture,
      userDetails,
      imageLoading,
      agentMlsId,
      enableShareProfileUrl,
      userName,
      previewProfileUrl,
      dataLoading,
      requiredUserId,
      lendersEmails,
      agentMlsList,
      enableInviteLenersPopUp,
    } = this.state;
    const specialties = (
      <>
        <div style={{ textAlign: "left" }}>
          {userDetails &&
            userDetails.newdata &&
            typeof userDetails.newdata.Specialties === "object" &&
            userDetails.newdata.Specialties.map((o, index) => (
              <>
                {o}
                {userDetails.newdata.Specialties.length - 1 !== index && ", "}
              </>
            ))}
        </div>
      </>
    );
    const inviteLender = (
      <div>
        <Button
          type="primary"
          onClick={() => {
            this.setState({ enableInviteLenersPopUp: true });
          }}
        >
          Invite Lender
        </Button>
      </div>
    );
    return (
      <div style={{ backgroundColor: "#F9FAFF" }}>
        <Row className="margin-body-agent">
          <Col>
            <Button
              type="text"
              icon={
                this.props &&
                this.props.match.params &&
                this.props.match.params.userName &&
                this.props.match.params.userName !== "" &&
                this.props.match.params.userName !== null &&
                this.props.match.params.userName !== undefined ? (
                  ""
                ) : (
                  <ArrowLeftOutlined />
                )
              }
              className="font-family-style font-medium-size"
              onClick={this.handleBack}
              style={{
                marginTop: "1vw",
                marginBottom: "1vw",
              }}
            >
              {window.location.pathname ===
                `/agent/viewProfile/${requiredUserId}` ||
              this.props.location.state === "Agents"
                ? this.props.location.state === "buyersAgent" ? "Back to Buyer Search" :
                  "Back to agent search"
                : window.location.pathname ===
                    `/lenders/viewProfile/${requiredUserId}` ||
                  this.props.location.state === "Lender"
                ? "Back to lenders search"
                : window.location.pathname ===
                    `/marketplace/viewProfile/${requiredUserId}` ||
                  this.props.location.state === "Vendor"
                ? "Back to marketplace search"
                : this.props.location.state === "DetailPage" ||
                  this.props.location.state === "speakToBuyerAgent" ||
                  this.props.location.state === "getPreApprovedForaLoan"
                ? "Back to detail page"
                : this.props &&
                  this.props.match.params &&
                  this.props.match.params.userName &&
                  this.props.match.params.userName !== "" &&
                  this.props.match.params.userName !== null &&
                  this.props.match.params.userName !== undefined
                ? ""
                : "Return to search result"}
            </Button>
          </Col>
        </Row>
        <Layout
          className="margin-body-agent"
          style={{ backgroundColor: "#F9FAFF" }}
        >
          <Row>
            <Col
              xl={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              style={{ marginBottom: "2vw" }}
            >
              <Loading enableLoading={imageLoading} />
              <>
                {userDetails && userDetails && (
                  <Card bordered={false} bodyStyle={{ padding: "5px" }}>
                    <Row justify="end">
                      {EditIcon && EditIcon ? (
                        <>
                          <Col style={{ cursor: "pointer" }}>
                            <a href="#About">
                              <Link
                                to={`/myProfile/${
                                  userDetails && userDetails.id
                                }`}
                              >
                                <EditTwoTone
                                  style={{
                                    fontSize: "28px",
                                    padding: "10px",
                                  }}
                                />
                              </Link>
                            </a>
                          </Col>

                          {/*  || userDetails.userProfile === "L" */}
                          {userDetails &&
                            userDetails.userProfile &&
                            (userDetails.userProfile === "AG" ||
                              userDetails.userProfile === "concierge") && (
                              <Col
                                style={{ cursor: "pointer", padding: "10px" }}
                                onClick={() =>
                                  this.setState({
                                    enableShareProfileUrl:
                                      !enableShareProfileUrl,
                                  })
                                }
                              >
                                <ShareAltOutlined
                                  style={{ fontSize: "28px", color: "green" }}
                                />
                              </Col>
                            )}
                        </>
                      ) : (
                        ""
                      )}
                    </Row>

                    {enableShareProfileUrl && (
                      <div style={{ backgroundColor: "#f0f0f0" }}>
                        <Row style={{ padding: "20px" }}>
                          <Col span={16}>
                            {userDetails &&
                            userDetails.userName &&
                            userDetails.userName !== "" ? (
                              <>
                                <Input
                                  // disabled
                                  readOnly
                                  name="shareProfileUrl"
                                  id="shareProfileUrl"
                                  value={
                                    window.location.origin +
                                    "/" +
                                    userDetails.userName
                                  }
                                  style={{ cursor: "not-allowed" }}
                                />
                              </>
                            ) : (
                              <>
                                <Input
                                  id="userName"
                                  name="userName"
                                  allowClear
                                  value={userName}
                                  onChange={this.handleUserName}
                                  placeholder="Enter username"
                                />
                              </>
                            )}
                          </Col>

                          <Col span={8}>
                            {userDetails &&
                            userDetails.userName &&
                            userDetails.userName !== "" ? (
                              <Button
                                type="primary"
                                icon={<CopyOutlined />}
                                onClick={() => this.copyUrl()}
                                style={{ width: "100%" }}
                              >
                                Copy URL
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                onClick={() => this.checkUserName()}
                                style={{ width: "100%" }}
                              >
                                Check URL
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    )}

                    <Row
                      style={{
                        padding: "1%",
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      <Col>
                        {profilePicture === "" ||
                        profilePicture === undefined ? (
                          <Button
                            shape="circle"
                            style={{
                              backgroundColor: "#d4d8f1fa",
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          >
                            <span style={{ fontSize: "30px" }}>
                              {userDetails.firstName &&
                                userDetails.firstName.charAt(0).toUpperCase()}
                            </span>
                          </Button>
                        ) : (
                          <img
                            src={profilePicture}
                            style={{
                              backgroundColor: "#d4d8f1fa",
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                            alt="profile"
                          />
                        )}
                      </Col>
                    </Row>
                    {userDetails && userDetails.avgRating > 0 && (
                      <Row justify="center">
                        <Rate disabled defaultValue={userDetails.avgRating} />
                        {"  "}
                        <span style={{ marginTop: "7px" }}>
                          ({userDetails && userDetails.testimonialCount})
                        </span>
                      </Row>
                    )}
                    <Row style={{ display: "block" }}>
                      <Col style={{ marginTop: "1vw" }}>
                        <p
                          className="profile-view-name"
                          style={{ textAlign: "center" }}
                        >
                          {userDetails &&
                            userDetails.firstName &&
                            userDetails.firstName + " " + userDetails.lastName}
                        </p>
                      </Col>
                      {userDetails && userDetails.userProfile !== "B" && (
                        <Col>
                          <p
                            className="profile-company-name"
                            style={{
                              textAlign: "center",
                              marginBottom: "0",
                            }}
                          >
                            {userDetails &&
                              userDetails.newdata &&
                              userDetails.newdata.companyName &&
                              userDetails.newdata.companyName}
                          </p>
                        </Col>
                      )}
                      {userDetails &&
                        (userDetails.userProfile === "AG" ||
                          userDetails.userProfile !== "concierge") && (
                          <>
                            {userDetails.firstName + userDetails.lastName ===
                            this.props.context.state.userDetails.firstName +
                              this.props.context.state.userDetails.lastName ? (
                              ""
                            ) : (
                              <Col
                                style={{
                                  textAlign: "center",
                                  fontSize: "18px",
                                }}
                                className="profile-company-name"
                              >
                                {userDetails &&
                                  userDetails.firstName !== "undefined" && (
                                    <SendMessage
                                      msgButtonTitle="Contact"
                                      buttonAlign="listing"
                                      toName={
                                        userDetails &&
                                        userDetails.firstName &&
                                        userDetails.firstName +
                                          " " +
                                          userDetails.lastName
                                      }
                                      propertyTitle=""
                                      propertyImages={null}
                                      mlsId={
                                        userDetails && userDetails.mlsId
                                          ? userDetails.mlsId
                                          : ""
                                      }
                                      agentEmail={
                                        userDetails && userDetails.email
                                      }
                                      isRegistred={true}
                                      messageType="general"
                                      isRegistredUser={true}
                                    />
                                  )}
                              </Col>
                            )}
                          </>
                        )}
                    </Row>
                    <Row style={{ marginTop: "0.5vw" }}>
                      <Col
                        xl={{ span: 23 }}
                        lg={{ span: 23 }}
                        md={{ span: 23 }}
                        sm={{ span: 23 }}
                        xs={{ span: 23 }}
                        className="profile-details"
                      >
                        <Row>
                          <span style={{ color: "gray" }}>Email : </span>
                        </Row>
                        <Row>
                          <span>{userDetails && userDetails.email}</span>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xl={{ span: 23 }}
                        lg={{ span: 23 }}
                        md={{ span: 23 }}
                        sm={{ span: 23 }}
                        xs={{ span: 23 }}
                        className="profile-details"
                      >
                        <Row>
                          <span style={{ color: "gray" }}>Phone : </span>
                        </Row>
                        <Row>
                          <a
                            href={
                              userDetails.phone && "tel:" + userDetails.phone
                            }
                            style={{ color: "black" }}
                          >
                            <span>
                              <NumberFormat
                                format="(###) ###-####"
                                value={userDetails && userDetails.phone}
                                displayType={"text"}
                              />
                            </span>
                          </a>
                        </Row>
                      </Col>
                    </Row>
                    {(userDetails.userProfile === "AG" ||
                      userDetails.userProfile !== "concierge") && (
                      <Row>
                        {userDetails &&
                          userDetails.newdata &&
                          userDetails.newdata.website && (
                            <Col
                              xl={{ span: 23 }}
                              lg={{ span: 23 }}
                              md={{ span: 23 }}
                              sm={{ span: 23 }}
                              xs={{ span: 23 }}
                              className="profile-details"
                            >
                              <Row>
                                <span style={{ color: "gray" }}>
                                  Website :{" "}
                                </span>
                              </Row>
                              <Row>
                                <span>
                                  {userDetails &&
                                    userDetails.newdata &&
                                    userDetails.newdata.website}
                                </span>
                              </Row>
                            </Col>
                          )}
                      </Row>
                    )}
                    {userDetails &&
                      (userDetails.userProfile === "L" ||
                        userDetails.userProfile === "V") && (
                        <Row>
                          <Col
                            xl={{ span: 23 }}
                            lg={{ span: 23 }}
                            md={{ span: 23 }}
                            sm={{ span: 23 }}
                            xs={{ span: 23 }}
                            className="profile-details"
                          >
                            <Row>
                              <span style={{ color: "gray" }}>Website : </span>
                            </Row>
                            <Row>
                              <span>{userDetails && userDetails.Website}</span>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    {userDetails &&
                      userDetails.userProfile === "B" &&
                      userDetails &&
                      userDetails.addUserName && (
                        <Row>
                          <Col
                            xl={{ span: 23 }}
                            lg={{ span: 23 }}
                            md={{ span: 23 }}
                            sm={{ span: 23 }}
                            xs={{ span: 23 }}
                            className="profile-details"
                          >
                            <Row>
                              <span style={{ color: "gray" }}>
                                Agent Name :{" "}
                              </span>
                            </Row>
                            <Row>
                              <span>
                                {userDetails && userDetails.addUserName}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    {userDetails &&
                      userDetails.newdata &&
                      userDetails.newdata.companyAddress && (
                        <Row style={{ marginTop: "0.5vw" }}>
                          <Col
                            xl={{ span: 23 }}
                            lg={{ span: 23 }}
                            md={{ span: 23 }}
                            sm={{ span: 23 }}
                            xs={{ span: 23 }}
                            className="profile-details"
                          >
                            <Row>
                              <span style={{ color: "gray" }}>
                                Company Address :{" "}
                              </span>
                            </Row>
                            <Row>
                              <span>
                                {userDetails &&
                                  userDetails.newdata &&
                                  userDetails.newdata.companyAddress &&
                                  userDetails.newdata.companyAddress}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    {userDetails &&
                      userDetails.newdata &&
                      userDetails.newdata.Specialties !== "" &&
                      userDetails.newdata.Specialties !== undefined &&
                      userDetails.newdata.Specialties !== "undefined" &&
                      userDetails.newdata.Specialties.length > 0 && (
                        <Row>
                          <Col
                            xl={{ span: 23 }}
                            lg={{ span: 23 }}
                            md={{ span: 23 }}
                            sm={{ span: 23 }}
                            xs={{ span: 23 }}
                            className="profile-details"
                          >
                            <Row>
                              <span style={{ color: "gray" }}>
                                Specialties :{" "}
                              </span>
                            </Row>
                            <Row>
                              <span style={{ textAlign: "justify" }}>
                                {specialties}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    {userDetails &&
                      userDetails.newdata &&
                      userDetails.newdata.serviceAreas !== "" &&
                      userDetails.newdata.serviceAreas !== undefined &&
                      userDetails.newdata.serviceAreas !== "undefined" &&
                      userDetails.newdata.serviceAreas.length > 0 && (
                        <Row>
                          {userDetails && userDetails.userProfile !== "B" && (
                            <Col
                              xl={{ span: 23 }}
                              lg={{ span: 23 }}
                              md={{ span: 23 }}
                              sm={{ span: 23 }}
                              xs={{ span: 23 }}
                              className="profile-details"
                            >
                              <Row>
                                <span style={{ color: "gray" }}>
                                  I am a local expert in these towns:
                                </span>
                              </Row>
                              <Row>
                                <span>
                                  {userDetails &&
                                    userDetails.newdata &&
                                    userDetails.newdata.serviceAreas &&
                                    userDetails.newdata.serviceAreas.map(
                                      (city, index) => (
                                        <>
                                          {typeof city === "object" &&
                                            city.cityName +
                                              " " +
                                              city.stateName}
                                          {userDetails.newdata.serviceAreas
                                            .length > 0 &&
                                          typeof city === "object"
                                            ? userDetails.newdata.serviceAreas
                                                .length -
                                                1 !==
                                                index && ", "
                                            : ""}
                                        </>
                                      )
                                    )}
                                </span>
                              </Row>
                            </Col>
                          )}
                        </Row>
                      )}
                    {userDetails &&
                      userDetails.newdata &&
                      userDetails.newdata.language && (
                        <Row>
                          <Col
                            xl={{ span: 23 }}
                            lg={{ span: 23 }}
                            md={{ span: 23 }}
                            sm={{ span: 23 }}
                            xs={{ span: 23 }}
                            className="profile-details"
                          >
                            <Row>
                              <span style={{ color: "gray" }}>
                                Languages spoken :{" "}
                              </span>
                            </Row>
                            <Row>
                              <span style={{ textAlign: "justify" }}>
                                {userDetails
                                  ? userDetails.newdata
                                    ? userDetails.newdata.language
                                      ? userDetails.newdata.language
                                      : ""
                                    : ""
                                  : ""}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    {userDetails && userDetails.mlsId && (
                      <Row>
                        <Col
                          xl={{ span: 23 }}
                          lg={{ span: 23 }}
                          md={{ span: 23 }}
                          sm={{ span: 23 }}
                          xs={{ span: 23 }}
                          className="profile-details"
                        >
                          <Row>
                            <span style={{ color: "gray" }}>MLS ID : </span>
                          </Row>
                          <Row>
                            <span style={{ textAlign: "justify" }}>
                              {userDetails
                                ? userDetails.mlsId
                                  ? userDetails.mlsId
                                  : ""
                                : ""}
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {userDetails && userDetails.licenseIds && (
                      <Row>
                        <Col
                          xl={{ span: 23 }}
                          lg={{ span: 23 }}
                          md={{ span: 23 }}
                          sm={{ span: 23 }}
                          xs={{ span: 23 }}
                          className="profile-details"
                        >
                          <Row>
                            <span style={{ color: "gray" }}>
                              License ID(s) :{" "}
                            </span>
                          </Row>
                          <Row>
                            <span style={{ textAlign: "justify" }}>
                              {userDetails
                                ? userDetails.licenseIds
                                  ? userDetails.licenseIds
                                  : ""
                                : ""}
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    )}

                    {userDetails.socialMedia && (
                      <>
                        <Divider></Divider>
                        <Col span={24} className="profile-details">
                          <Row>
                            {userDetails.socialMedia.facebook && (
                              <Col span={3}>
                                <FacebookOutlined
                                  onClick={() =>
                                    this.openInNewTab(
                                      userDetails.socialMedia.facebook
                                    )
                                  }
                                  style={{
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            )}

                            {userDetails.socialMedia.twitter && (
                              <Col span={3}>
                                <TwitterOutlined
                                  onClick={() =>
                                    this.openInNewTab(
                                      userDetails.socialMedia.twitter
                                    )
                                  }
                                  style={{
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            )}

                            {userDetails.socialMedia.Linkedin && (
                              <Col span={3}>
                                <LinkedinOutlined
                                  onClick={() =>
                                    this.openInNewTab(
                                      userDetails.socialMedia.Linkedin
                                    )
                                  }
                                  style={{
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            )}

                            {userDetails.socialMedia.instagram && (
                              <Col span={3}>
                                <InstagramOutlined
                                  onClick={() =>
                                    this.openInNewTab(
                                      userDetails.socialMedia.instagram
                                    )
                                  }
                                  style={{
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            )}

                            {userDetails.socialMedia.GooglePlus && (
                              <Col span={3}>
                                <GooglePlusOutlined
                                  onClick={() =>
                                    this.openInNewTab(
                                      userDetails.socialMedia.GooglePlus
                                    )
                                  }
                                  style={{
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            )}

                            {userDetails.socialMedia.youtube && (
                              <Col span={3}>
                                <YoutubeOutlined
                                  onClick={() =>
                                    this.openInNewTab(
                                      userDetails.socialMedia.youtube
                                    )
                                  }
                                  style={{
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            )}

                            {userDetails.socialMedia.skype && (
                              <Col span={3}>
                                <SkypeOutlined
                                  onClick={() =>
                                    this.openInNewTab(
                                      userDetails.socialMedia.skype
                                    )
                                  }
                                  style={{
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            )}

                            {userDetails.socialMedia.website && (
                              <Col span={3}>
                                <GoogleOutlined
                                  onClick={() =>
                                    this.openInNewTab(
                                      userDetails.socialMedia.website
                                    )
                                  }
                                  style={{
                                    fontSize: "22px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </>
                    )}
                  </Card>
                )}

                {this.showBioData()}
              </>
            </Col>
            <Col
              xl={{ span: 17, offset: 0 }}
              lg={{ span: 17, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="margin-left-agent"
            >
              <div style={{ marginBottom: "2vw" }}>
                <Card
                  className="homes-card"
                  bordered={false}
                  style={{ borderRadius: 10 }}
                >
                  <Menu
                    mode={menuForDesktopViewTrue ? "horizontal" : "inline"}
                    defaultSelectedKeys={activeItemKey}
                    onClick={this.handleMenuItems}
                  >
                    {this.state.visibleTabs &&
                      this.state.visibleTabs.map((tab) => (
                        <Menu.Item
                          key={tab}
                          className={
                            activeItemKey === tab
                              ? "menu-items1"
                              : "menu-items2"
                          }
                        >
                          {tab}
                        </Menu.Item>
                      ))}
                  </Menu>
                </Card>
                <Layout>
                  {(activeItemKey === "MLS Homes" ||
                    activeItemKey === "Homes" ||
                    activeItemKey === "Sell" ||
                    activeItemKey === "Buyers" ||
                    activeItemKey === "Buy") && (
                    <Card
                      bordered={false}
                      className="profile-details-mobile-view"
                    >
                      <Row>
                        <Col
                          xl={{ span: 12 }}
                          lg={{ span: 12 }}
                          md={{ span: 12 }}
                          sm={{ span: 12 }}
                          xs={{ span: 12 }}
                          className="homes-clsname"
                        >
                          <span>{activeItemKey}</span>
                        </Col>
                        <Col
                          xl={{ span: 3, offset: 9 }}
                          lg={{ span: 3, offset: 9 }}
                          md={{ span: 4, offset: 8 }}
                          sm={{ span: 4, offset: 8 }}
                          xs={{ span: 10, offset: 2 }}
                          className="homes-clsname"
                        >
                          {EditIcon && !(activeItemKey === "MLS Homes") && (
                            <Button
                              icon={<PlusOutlined style={{ color: "#FFF" }} />}
                              className="home-add-btn"
                              onClick={this.handleAddhome}
                            >
                              <span className="home-text">
                                {activeItemKey === "Homes" ||
                                activeItemKey === "Sell"
                                  ? "Home"
                                  : "Buyer"}
                              </span>
                            </Button>
                          )}
                        </Col>
                      </Row>

                      <Divider></Divider>
                    </Card>
                  )}

                  <Row>
                    <Card
                      bordered={false}
                      className="profile-details-mobile-view"
                    >
                      <Row>
                        {activeItemKey === "MLS Homes" && (
                          <Col
                            span={24}
                            className="homeSearch"
                            style={{ background: "white", marginTop: "-1vw" }}
                          >
                            <MLShomes
                              authenticate
                              handleFavoritesCount={this.handleFavoritesCount}
                            />
                          </Col>
                        )}
                        {(activeItemKey === "Homes" ||
                          activeItemKey === "Sell") && (
                          <Col
                            span={24}
                            className="homeSearch"
                            style={{ background: "white", marginTop: "-1vw" }}
                          >
                            <LocalHomes
                              authenticate
                              handleFavoritesCount={this.handleFavoritesCount}
                              agentMLSId={agentMlsId && agentMlsId}
                              agentMlsList={agentMlsList && agentMlsList}
                              agentProfilePicture={profilePicture}
                              agentProfileName={
                                userDetails &&
                                userDetails.firstName +
                                  " " +
                                  userDetails.lastName
                              }
                              sharingUserParamsId={requiredUserId}
                              onlyHomes={EditIcon}
                            />
                          </Col>
                        )}
                        {(activeItemKey === "Buyers" ||
                          activeItemKey === "Buy") && (
                          <Col
                            span={24}
                            className="homeSearch"
                            style={{ background: "white", marginTop: "-1vw" }}
                          >
                            <BuyersListing
                              authenticate
                              sharingUserParamsId={requiredUserId}
                            />
                          </Col>
                        )}
                        {activeItemKey === "Testimonials" && (
                          <Col
                            span={24}
                            className="homeSearch"
                            style={{ background: "white", marginTop: "-1vw" }}
                          >
                            <Testmonials
                              userId={
                                requiredUserId ? requiredUserId : userDetails.id
                              }
                            />
                          </Col>
                        )}
                        {activeItemKey === "Portfolio" && (
                          <Col
                            span={24}
                            className="homeSearch"
                            style={{ background: "white", marginTop: "-1vw" }}
                          >
                            <PortfoliosList
                              portFolioTabDisabled={
                                userDetails &&
                                (userDetails.userProfile === "AG" ||
                                  userDetails.userProfile !== "concierge")
                                  ? "portFolioTabDisabled"
                                  : "open"
                              }
                              sharingUserParamsId={requiredUserId}
                            />
                          </Col>
                        )}
                        {activeItemKey === "Subscriptions" && (
                          <Col
                            span={24}
                            className="homeSearch"
                            style={{ background: "white", marginTop: "-1vw" }}
                          >
                            <Subscriptions
                            />
                          </Col>
                        )}
                        {/* {activeItemKey === "Leads" && (
                          <Col
                            span={24}
                            className="homeSearch"
                            style={{ background: "white", marginTop: "-1vw" }}
                          >
                            <Missleads />
                          </Col>
                        )} */}
                      </Row>
                    </Card>
                  </Row>
                </Layout>
              </div>

              {userDetails &&
                userDetails.userProfile &&
                userDetails.userProfile === "AG" && (
                  <>
                    <Divider></Divider>
                    <Col
                      span={24}
                      style={{ background: "white", marginBottom: "2vw" }}
                    >
                      <Card
                        className="homes-clsname"
                        bordered={false}
                        extra={
                          EditIcon &&
                          userDetails.userProfile === "AG" &&
                          inviteLender
                        }
                        title="My Preferred Lender(s)"
                        style={{ padding: "0px" }}
                      ></Card>
                    </Col>
                    {/* <Scrollbars
                      style={{
                        height: window.screen.height / 2,
                        backgroundColor: "#FFF",
                      }}
                    > */}
                    <Col
                      span={24}
                      style={{ background: "white", marginBottom: "2vw" }}
                    >
                      {lendersEmails && lendersEmails.length > 0 ? (
                        <Lenders
                          showOnlyLenderCards={true}
                          lendersEmailsList={lendersEmails}
                          ViewProfileSpinLoading
                        />
                      ) : (
                        <div style={{ padding: "4vw" }}>
                          <DataNotFound
                            type="lenders"
                            title="No lenders added as yet."
                          />
                        </div>
                      )}
                    </Col>
                    {/* </Scrollbars> */}
                  </>
                )}
            </Col>
          </Row>
        </Layout>

        <Modal
          centered
          className="modal-black-close"
          visible={previewProfileUrl}
          title={null}
          closable={false}
          afterClose={() => this.resetUserInfo()}
          onCancel={() => this.resetUserInfo()}
          footer={[
            <Button
              style={{
                cursor: "pointer",
              }}
              size="large"
              type="primary"
              loading={dataLoading}
              onClick={() => this.updateUsername()}
            >
              Proceed
            </Button>,
            <Button
              style={{
                cursor: "pointer",
              }}
              size="large"
              type="default"
              onClick={() => this.resetUserInfo()}
            >
              Cancel
            </Button>,
          ]}
        >
          <div style={{ padding: "40px" }}>
            <h1 style={{ color: "green", textAlign: "center" }}>
              <span style={{ color: "green" }}>
                <CheckCircleTwoTone
                  style={{
                    fontSize: "120px",
                    color: "green",
                    textAlign: "center",
                  }}
                />
              </span>
              <br />
              {userName + " is available!"}
            </h1>

            <h2 style={{ textAlign: "center" }}>Please confirm to proceed</h2>

            <div style={{ backgroundColor: "#f0f0f0" }}>
              <Row style={{ padding: "20px" }}>
                <Input
                  prefix={
                    <SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  // disabled
                  readOnly
                  name="previewProfileUrl"
                  id="previewProfileUrl"
                  value={window.location.origin + "/" + userName}
                  style={{ cursor: "not-allowed" }}
                />
              </Row>
            </div>
          </div>
        </Modal>

        {enableInviteLenersPopUp && (
          <Invitation
            enableInvitationModal={enableInviteLenersPopUp}
            handleCloseInvitationModal={this.handleCloseInvitationModal}
            invitationTitle={"Lendor"}
          />
        )}
      </div>
    );
  }
}
export default withAppContext(withRouter(ViewProfile));
