import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import ProspectiveBusinessReport from "./ProspectiveBusinessReport";
import PaidLeadSource from "./PaidLeadSource";
import ActivePipelineLeads from "./ActivePipelineLeads";
import LeadSource from "./LeadSource";
import { withAppContext } from "../../../store/contextStore";
import { list } from "../../../api/Apis";
import Loading from "../../../common/Loading";

function MyPipeline() {
  const [loading, setLoading] = useState(false);
  const [openPageLoading, pageLoading] = useState(false);

  const [leadFilterData, setLeadFilterData] = useState({
    filteringData: [],
    totalUnchangedData: [],
    leaderId: "all",
    sourceToCheck: "all",
    statusToCheck: "all",
    statusTotalPrices: [],
  });

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    getUserProfile();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const getUserProfile = () => {
    pageLoading(true);
    setLeadFilterData((t) => ({
      ...t,
      filteringData: [],
      totalUnchangedData: [],
      leaderId: "all",
      sourceToCheck: "all",
      statusToCheck: "all",
    }));
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let orgId = JSON.parse(localStorage.getItem("orgId"));

    let id = userDetails && userDetails?.id;
    let query = {};

    if (userDetails?.userProfile === "AG") {
      query["userId"] = id;
    } else {
      query["orgId"] = orgId;
    }
    query["listingStatus"] = [
      "Active Listing",
      "Active Buyer",
      "Prospective Buyer",
      "Prospective seller or buyer",
      "Under Agreement",
      "Closed",
      "Prospective Seller"
    ];
    query = {
      ...query,
      contactType: "Contact",
    };
    setLoading(true);
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          setLeadFilterData((t) => ({
            ...t,
            filteringData: result.data.contactList.filter((d) => d.listingStatus),
            totalUnchangedData: result.data.contactList.filter((d) => d.listingStatus),
            statusTotalPrices: result.data.contactList.filter((d) => d.listingStatus),
          }));
          pageLoading(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        pageLoading(false);
        setLoading(false);
      });
  };

  const newCheck = (matchingValue) => {
    setLoading(true);
    let filterStatusData = [];
    if (
      leadFilterData.leaderId !== "all" &&
      leadFilterData.sourceToCheck !== "all"
    ) {
      filterStatusData =
        leadFilterData?.totalUnchangedData.length > 0 &&
        leadFilterData?.totalUnchangedData.filter(
          (selectedAgent) =>
            selectedAgent.listingStatus.trim().toLowerCase() ===
              matchingValue &&
            selectedAgent.userId === leadFilterData.leaderId &&
            selectedAgent.sourceLead
              .toLowerCase()
              .indexOf(leadFilterData.sourceToCheck) > -1
        );
    } else if (leadFilterData.leaderId !== "all") {
      filterStatusData =
        leadFilterData?.totalUnchangedData.length > 0 &&
        leadFilterData?.totalUnchangedData.filter(
          (selectedAgent) =>
            selectedAgent.listingStatus.trim().toLowerCase() ===
              matchingValue && selectedAgent.userId === leadFilterData.leaderId
        );
    } else if (
      leadFilterData.sourceToCheck === "all" &&
      leadFilterData.leaderId === "all"
    ) {
      filterStatusData =
        leadFilterData?.totalUnchangedData.length > 0 &&
        leadFilterData?.totalUnchangedData.filter(
          (selectedAgent) =>
            selectedAgent.listingStatus.trim().toLowerCase() === matchingValue
        );
    } else if (leadFilterData.sourceToCheck !== "all") {
      filterStatusData =
        leadFilterData?.totalUnchangedData.length > 0 &&
        leadFilterData?.totalUnchangedData.filter(
          (selectedAgent) =>
            selectedAgent.listingStatus.trim().toLowerCase() ===
              matchingValue &&
            selectedAgent.sourceLead
              .toLowerCase()
              .indexOf(leadFilterData.sourceToCheck) > -1
        );
    }
    setLeadFilterData((t) => ({
      ...t,
      filteringData: filterStatusData,
      statusToCheck: matchingValue,
    }));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleAgents = (leaderId) => {
    setLoading(true);
    let selectedAgentsData =
      leadFilterData?.totalUnchangedData.length > 0 &&
      leadFilterData?.totalUnchangedData.filter(
        (selectedAgent) => selectedAgent.userId === leaderId
      );
    setLeadFilterData((t) => ({
      ...t,
      filteringData: selectedAgentsData,
      leaderId: leaderId,
      sourceToCheck: "all",
      statusToCheck: "all",
      statusTotalPrices: selectedAgentsData,
    }));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleLeadPie = (value) => {
    setLoading(true);

    let searchingValue = value.toLowerCase();

    let FilterLeads = [];
    if (
      leadFilterData.leaderId !== "all" &&
      leadFilterData.statusToCheck !== "all"
    ) {
      FilterLeads =
        leadFilterData?.totalUnchangedData.length > 0 &&
        leadFilterData?.totalUnchangedData.filter(
          (selectedAgent) =>
            selectedAgent.listingStatus.trim().toLowerCase() ===
              leadFilterData.statusToCheck &&
            selectedAgent.userId === leadFilterData.leaderId &&
            selectedAgent.sourceLead.toLowerCase().indexOf(searchingValue) > -1
        );
    } else if (leadFilterData.leaderId !== "all") {
      FilterLeads =
        leadFilterData?.totalUnchangedData.length > 0 &&
        leadFilterData?.totalUnchangedData.filter(
          (selectedAgent) =>
            selectedAgent.userId === leadFilterData.leaderId &&
            selectedAgent.sourceLead.toLowerCase().indexOf(searchingValue) > -1
        );
    } else if (
      leadFilterData.statusToCheck === "all" &&
      leadFilterData.leaderId === "all"
    ) {
      FilterLeads =
        leadFilterData?.totalUnchangedData.length > 0 &&
        leadFilterData?.totalUnchangedData.filter(
          (selectedAgent) =>
            selectedAgent.sourceLead.toLowerCase().indexOf(searchingValue) > -1
        );
    } else if (leadFilterData.statusToCheck !== "all") {
      FilterLeads =
        leadFilterData?.totalUnchangedData.length > 0 &&
        leadFilterData?.totalUnchangedData.filter(
          (selectedAgent) =>
            selectedAgent.listingStatus.trim().toLowerCase() ===
              leadFilterData.statusToCheck &&
            selectedAgent.sourceLead.toLowerCase().indexOf(searchingValue) > -1
        );
    }

    setLeadFilterData((t) => ({
      ...t,
      filteringData: FilterLeads,
      sourceToCheck: searchingValue,
    }));

    if (searchingValue === "all") {
      getUserProfile();
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div style={{ background: "#F9FAFF" }}>
      <Loading enableLoading={openPageLoading} />
      <Row>
        <Col
          xl={{ span: 17 }}
          lg={{ span: 24 }}
          md={{ span: 17 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          {userDetails?.userProfile === "AG" && (
            <Col span={24}>
              <ActivePipelineLeads
                getUserProfile={getUserProfile}
                loading={loading}
                handleAgents={handleAgents}
                leads={leadFilterData?.filteringData}
                allLeadsData={leadFilterData?.totalUnchangedData}
              />
            </Col>
          )}
          {userDetails?.userProfile !== "AG" && (
            <Col span={24}>
              {/* {statusData?.showPipeLineData !== "" && ( */}
              <PaidLeadSource
                loading={loading}
                handleAgents={handleAgents}
                leads={leadFilterData?.filteringData}
                allLeadsData={leadFilterData?.totalUnchangedData}
                handleShowAll={getUserProfile}
              />
              {/* )} */}
            </Col>
          )}
        </Col>
        <Col
          xl={{ span: 7 }}
          lg={{ span: 24 }}
          md={{ span: 7 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Col span={24}>
            <ProspectiveBusinessReport
              newCheck={newCheck}
              leads={
                leadFilterData.leaderId !== "all"
                  ? leadFilterData?.statusTotalPrices
                  : leadFilterData.leaderId === "all" &&
                    (leadFilterData.sourceToCheck !== "all" ||
                      leadFilterData.statusToCheck !== "all")
                  ? leadFilterData?.statusTotalPrices
                  : leadFilterData.filteringData
              }
              allLeadsData={leadFilterData?.totalUnchangedData}
            />
          </Col>

          <Col span={24}>
            {leadFilterData?.filteringData && (
              <LeadSource
                loading={loading}
                prospectiveBSRLead={false}
                leads={
                  leadFilterData.leaderId !== "all"
                    ? leadFilterData?.statusTotalPrices
                    : leadFilterData.leaderId === "all" &&
                      (leadFilterData.sourceToCheck !== "all" ||
                        leadFilterData.statusToCheck !== "all")
                    ? leadFilterData?.statusTotalPrices
                    : leadFilterData.filteringData
                }
                allLeadsData={leadFilterData?.totalUnchangedData}
                handleLeadPie={handleLeadPie}
              />
            )}
          </Col>
        </Col>
      </Row>
    </div>
  );
}
export default withAppContext(withRouter(MyPipeline));
