import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Button, Space,notification } from "antd";
import { FaFileDownload } from "react-icons/fa";
import { Storage } from "aws-amplify";

import { EyeFilled, DeleteOutlined } from "@ant-design/icons";
import Layout from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import moment from "moment";
import { deleteRecord } from "../../../api/Apis";

export default function TrainingCardCom({
  cardData,
  handleVideoWatchingDetails,
  getTrainingsData,
}) {
  const fileDownload = async (fileName, downloadFileName, companyName) => {
    let savedFileName = downloadFileName + "_" + companyName;
    Storage.get(fileName, { download: true }).then((res) =>
      downloadBlob(res.Body, savedFileName)
    );
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();

    return a;
  };
  const deleteTodo = async (item) => {
    deleteRecord("virtualOfficeTrainings", item.id)
      .then((response) => {
        if (response.success) {
          notification["success"]({
            message: `${item.tainingName} Removed Successfully`,
            description: "Thank you.",
          });
          getTrainingsData(true)
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <div>
      <Layout
        style={{
          backgroundColor: "white",
          marginBottom: "1vw",
        }}
      >
        {/* <Loading
            enableLoading={this.state.loading}
            heart={this.state.loading}
          /> */}

        <Row
          className="home-card-space-bw-trainings"
          style={{ background: "rgb(240 242 245)" }}
        >
          <Col
            xl={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className="ant-card-hoverable"
            style={{ borderRadius: "5px" }}
          >
            <Row style={{ background: "white", borderRadius: "5px" }}>
              <Col
                xl={{
                  span: 5,
                  offset: 0,
                }}
                lg={{
                  span: 5,
                  offset: 0,
                }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                style={{ backgroundColor: "#d4d8f1fa", borderRadius: "5px" }}
              >
                <iframe
                  src={cardData?.videoUrl}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                  width="100%"
                  height="100%"
                  title="Trainings"
                ></iframe>
              </Col>
              <Col
                xl={{
                  span: 19,
                }}
                lg={{
                  span: 19,
                }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ padding: "5px", paddingTop: "10px" }}
              >
                <Row>
                  <Col
                    xl={{
                      span: 24,
                    }}
                    lg={{
                      span: 24,
                    }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Row>
                      <Col
                        xl={{ span: 17, offset: 0 }}
                        lg={{ span: 17, offset: 0 }}
                        md={{ span: 16, offset: 1 }}
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                      >
                        <Link className="no-lines">
                          <Row style={{ marginLeft: "10px" }}>
                            <Col
                              xl={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 1 }}
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                            >
                              <span
                                className="searchHomes-titles-trainigs"
                                style={{ textTransform: "capitalize" }}
                              >
                                {cardData?.tainingName}
                              </span>
                            </Col>
                          </Row>

                          <Row
                            style={{
                              marginLeft: "10px",
                              paddingTop: "1%",
                              paddingBottom: "1%",
                            }}
                            className="hide-post-type-mobile"
                          >
                            <Col
                              xl={{ span: 6, offset: 0 }}
                              lg={{ span: 6, offset: 0 }}
                              md={{ span: 6, offset: 1 }}
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                            >
                              <p
                                style={{
                                  color: "#99A2C3",
                                  fontSize: "12px",
                                }}
                              >
                                {cardData?.category?.toUpperCase()}
                              </p>
                            </Col>
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 4, offset: 0 }}
                              md={{ span: 4, offset: 1 }}
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                            >
                              <p
                                style={{
                                  color: "#99A2C3",
                                  fontSize: "12px",
                                }}
                              >
                                {cardData?.videoDuration} mins
                              </p>
                            </Col>
                          </Row>
                        </Link>
                      </Col>
                      <Col
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 6 }}
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                      >
                        <Row style={{ float: "right" }}>
                          <Col style={{ marginLeft: "10px" }}>
                            {cardData?.videoSeen ? (
                              <span style={{ color: "#2CC337" }}>
                                {" "}
                                Watched on{" "}
                                {moment(cardData.watchedDate).format("L")}
                              </span>
                            ) : (
                              <Button
                                icon={<EyeFilled />}
                                type="primary"
                                onClick={() =>
                                  handleVideoWatchingDetails(cardData)
                                }
                              >
                                Mark as watched
                              </Button>
                            )}
                          </Col>
                          <Col style={{ marginLeft: "10px" }}>
                            {userDetails && userDetails.userProfile === "A" && (
                              <span>
                                <DeleteOutlined
                                  style={{
                                    color: "red",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  className="todo-delete-option"
                                  onClick={() => deleteTodo(cardData)}
                                />
                              </span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    xl={{
                      span: 24,
                    }}
                    lg={{
                      span: 24,
                    }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Row>
                      <Col
                        xl={{ span: 17, offset: 0 }}
                        lg={{ span: 17, offset: 0 }}
                        md={{ span: 16, offset: 1 }}
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                      >
                        <Link className="no-lines">
                          <Row
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            <div
                              className="layout-last-step"
                              style={{
                                marginBottom: "10px",
                                minHeight: "70px",
                              }}
                            >
                              <span style={{ color: "#606060" }}>
                                {cardData?.description}
                              </span>
                            </div>
                          </Row>
                        </Link>
                        <Link className="no-lines">
                          <Row style={{ marginBottom: "5px" }}>
                            <Space size={"middle"}>
                              {cardData?.keywords.length > 0 &&
                                cardData?.keywords.map((k) => (
                                  <Col
                                    span={24}
                                    style={{
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        borderRadius: "5px",
                                        background: "#E9F0FD",
                                        border: "1px solid #E9F0FD",
                                        height: "28px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#7E8291",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {k.toUpperCase()}
                                      </span>
                                    </Button>
                                  </Col>
                                ))}
                            </Space>
                          </Row>
                        </Link>
                      </Col>

                      <Col
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 6 }}
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                      >
                        <Link className="no-lines">
                          <Row
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            <div
                              className="layout-last-step"
                              style={{
                                marginBottom: "10px",
                                minHeight: "70px",
                              }}
                            >
                              <span style={{ color: "#606060" }}>
                                {/* {cardData?.description} */}
                              </span>
                            </div>
                          </Row>
                        </Link>
                        <Row style={{ float: "right" }}>
                          {cardData.additionalS3Keys &&
                            cardData.additionalS3Keys?.length > 0 && (
                              <Col
                                style={{
                                  marginLeft: "10px",
                                  color: "#7E8291",
                                  fontWeight: "600",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    fileDownload(
                                      cardData.additionalS3Keys[0],
                                      "Additional_File",
                                      "training"
                                    );
                                  }}
                                >
                                  <FaFileDownload
                                    style={{ width: "25px", height: "30px" }}
                                  />
                                </span>
                                <h4>Additional file</h4>
                              </Col>
                            )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}
