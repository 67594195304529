import React, { Component } from "react";
import { Provider } from "react-redux";
import { Auth } from "aws-amplify";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Authenticated from "./config/Authenticated";
import Application from "./components/VirtualOffice/Application";
import "./stylesheets/_virtualoffice.scss";
import AwsConfig from "./config/AwsConfig";

import store from "./redux/store";
import { withAppContext } from "./store/contextStore";
import "rc-color-picker/assets/index.css";
class App extends Component {


  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    this.loadFacebookSDK();
  
    try {
      await Auth.currentAuthenticatedUser();
      this.userHasAuthenticated(true);
    } catch (e) {
      if (e !== "not authenticated") {
        console.log(e,"alert console");
        // alert(e);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }
  
  loadFacebookSDK() {
    console.log("scoail JSD calling function from ");
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : AwsConfig.social.FB,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.1'
      });
    };
  
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/VirtualOffice" component={Application} />
            <Route path="/" component={Authenticated} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default withAppContext(App);
