import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  notification,
  Popover,
  Divider,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Upload,
  Spin,
} from "antd";
import TimePicker from "rc-time-picker";
import "antd/dist/antd.css";
import moment from "moment";
import {
  MoreOutlined,
  CheckSquareOutlined,
  PlusOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { list, add, update } from "../../api/Apis";
import { withAppContext } from "../../store/contextStore";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ModalTitle } from "../../StyledComponents/StyledComponents.js";
// import location from "../../images/location.png";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { Storage } from "aws-amplify";
import Avatar from "../../images/profileAvatar.png";
import DeleteRecord from "../../common/DeleteRecord";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
export class Meetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      meetingTitle: "",
      meetingDate: "",
      meetingTime: "",
      meetingMessage: "",
      meetingList: [],
      weeekView: false,
      monthView: true,
      monValue: "",
      tueValue: "",
      wedValue: "",
      thuValue: "",
      friValue: "",
      satValue: "",
      sunValue: "",
      monActive: false,
      tueActive: false,
      wedActive: false,
      thuActive: false,
      friActive: false,
      satActive: false,
      sunActive: false,
      todoActiveDates: [],
      todoColorDates: [],
      selectedDate: [],
      selectedtraining: [],
      trainingList: [],
      traningColorDates: [],
      weekFirstDate: "",
      weekEndDate: "",
      meetForm: false,
      cityValue: "",
      allAgents: [],
      markAsTraine: false,
      meetColorDates: [],
      selectedMeetDate: [],
      tainingWeekDates: [],
      meetingWeekDates: [],
      resourceImage: "",
      imageLoading: false,
      editData: false,
      trainingModal: false,
      traingModalData: [],
      editInput: false,
      meetType: "",
    };
  }

  componentDidMount = () => {
    this.init();
  };

  init = () => {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 7; // last day is the first day + 7
    var firstday = new Date(new Date().setDate(first)).toUTCString();
    var lastday = new Date(new Date().setDate(last)).toUTCString();

    const TodayDate = new Date(firstday);
    TodayDate.setDate(TodayDate.getDate() + 1);
    this.setState({
      weekFirstDate: moment(TodayDate).format("L"),
      weekEndDate: moment(lastday).format("L"),
    });

    let monthFirstDay = new Date(curr.getFullYear(), curr.getMonth(), 1);
    let monthlastDay = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

    this.getAllMeetings(
      moment(TodayDate).format("L"),
      moment(lastday).format("L"),
      moment(monthFirstDay).format("L"),
      moment(monthlastDay).format("L")
    );
    this.nextweek();
    this.getAllTodos(
      moment(TodayDate).format("L"),
      moment(lastday).format("L"),
      moment(monthFirstDay).format("L"),
      moment(monthlastDay).format("L")
    );
    this.getTrainingsData(
      moment(TodayDate).format("L"),
      moment(lastday).format("L"),
      moment(monthFirstDay).format("L"),
      moment(monthlastDay).format("L")
    );
    this.getAllAgents();
  };

  getAllTodos = async (
    weekFirstDate,
    weekEndDate,
    monthFirstDay,
    monthlastDay
  ) => {
    this.setState({
      loading: true,
    });
    let ActiveDates = [];
    let todoColorDates = [];
    let ActiveMonthDates = [];
    try {
      const response = this.props.allTodoData;
      if (response.length) {
        response.map((e) => {
          if (e.done !== true) {
            if (
              weekEndDate >= moment(e.dueDate).format("L") &&
              weekFirstDate <= moment(e.dueDate).format("L")
            ) {
              ActiveDates.push(e);
            }
            if (
              monthlastDay >= moment(e.dueDate).format("L") &&
              monthFirstDay <= moment(e.dueDate).format("L")
            ) {
              ActiveMonthDates.push(e);
            }
          }
          return true;
        });
        ActiveMonthDates.map((d) =>
          todoColorDates.push(moment(d.dueDate).format("L"))
        );
        this.setState({
          todoList: ActiveMonthDates.sort(
            (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
          ),
          todoActiveDates: ActiveDates.sort(
            (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
          ),
          todoColorDates,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      this.setState({
        loading: false,
      });
    }
  };

  getTrainingsData = (
    weekFirstDate,
    weekEndDate,
    monthFirstDay,
    monthlastDay
  ) => {
    this.setState({
      loading: true,
    });
    const getUser = JSON.parse(localStorage.getItem("orgDetails"));
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    let query = {};

    if (userDetails?.userProfile === "A") {
      query["orgId"] = getUser.id;
    } else {
      query["userId"] = userDetails.id;
      query["orgId"] = getUser.id;
    }
    list("getMeetings", query)
      .then((result) => {
        if (result.success) {
          let ActiveWeekDates = [];
          let ActiveMonthDates = [];
          let traningColorDates = [];
          result.data.map((e) => {
            if (e.markAsTraine !== false) {
              if (weekEndDate >= e.meetDate && weekFirstDate <= e.meetDate) {
                ActiveWeekDates.push(e);
              }
            }
            return true;
          });
          result.data.map((e) => {
            if (e.markAsTraine !== false) {
              if (monthlastDay >= e.meetDate && monthFirstDay <= e.meetDate) {
                ActiveMonthDates.push(e);
              }
            }
            return true;
          });

          ActiveMonthDates.map((d) =>
            traningColorDates.push(moment(d.meetDate).format("L"))
          );
          this.setState({
            trainingList: ActiveMonthDates,
            tainingWeekDates: ActiveWeekDates,
            loading: false,
            traningColorDates,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  getAllMeetings = async (
    weekFirstDate,
    weekEndDate,
    monthFirstDay,
    monthlastDay
  ) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    let query = {};
    let userId = userDetails.id;

    query["userId"] = userId;

    list("getMeetings", query).then((result) => {
      let ActiveWeekDates = [];
      let meetColorDates = [];
      let ActiveMonthDates = [];
      result.data.map((e) => {
        if (e.markAsTraine !== true) {
          if (weekEndDate >= e.meetDate && weekFirstDate <= e.meetDate) {
            ActiveWeekDates.push(e);
          }
        }
        return true;
      });
      result.data.map((e) => {
        if (e.markAsTraine !== true) {
          if (monthlastDay >= e.meetDate && monthFirstDay <= e.meetDate) {
            ActiveMonthDates.push(e);
          }
        }
        return true;
      });

      ActiveMonthDates.map((d) =>
        meetColorDates.push(moment(d.meetDate).format("L"))
      );
      if (result.success) {
        this.setState({
          meetingList: ActiveMonthDates,
          meetingWeekDates: ActiveWeekDates,
          meetColorDates,
        });
      } else {
        notification["error"]({
          message: "Something went wrong",
        });
      }
    }).catch = (error) => {
      notification["error"]({
        message: "Something went wrong",
      });
    };
  };

  getAllAgents = () => {
    this.setState({
      loading: true,
    });
    let orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));

    let query = {};
    query = {
      orgId: orgDetails.id,
      isActive: true,
    };

    list("users", query)
      .then((result) => {
        let data = { firstName: "All", lastName: "Agents", id: "123" };
        if (result.success) {
          let agentData = result.data.filter(
            (d) => d.userProfile === "AG" && d.id !== userDetails.id
          );
          agentData.push(data);
          this.setState({
            loading: false,
            allAgents: agentData.sort((a, b) =>
              a.firstName.charAt(0) > b.firstName.charAt(0) ? 1 : -1
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  handleDate = (e) => {
    let checkDate = moment(e).format("MM/DD/YYYY");
    this.setState({
      meetingDate: checkDate,
    });
  };

  handleTime = (time) => {
    let selectedTime = time;
    this.setState({
      startTime: selectedTime,
      endTime: selectedTime,
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  resourceImage: result.key,
                  imageLoading: false,
                  loading: false,
                });
              });
            } else {
              this.setState({
                imageLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  handleSelect = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  handleReset = () => {
    this.setState({
      openForm: false,
      meetingTitle: "",
      meetingDate: "",
      meetingTime: "",
      meetingMessage: "",
    });
  };

  onChange = (value, mode) => {
    let changeDate = moment(value).format("L");
    const selectedDate =
      this.state.todoList &&
      this.state.todoList.filter(
        (d) => moment(d.dueDate).format("L") === changeDate
      );
    const selectedtraining =
      this.state.trainingList &&
      this.state.trainingList.filter((d) => d.meetDate === changeDate);
    const selectedMeetDate =
      this.state.meetingList &&
      this.state.meetingList.filter((d) => d.meetDate === changeDate);
    this.setState({
      selectedDate,
      selectedtraining,
      selectedMeetDate,
    });
  };

  moreChange = () => {
    console.log("data");
  };

  nextweek = () => {
    let date = new Date();
    let dayOfWeekNumber = date.getDay();
    let nameOfDay;

    let today = new Date();
    let tomorrow = new Date();
    let tomorrow_2 = new Date();
    let tomorrow_3 = new Date();
    let tomorrow_4 = new Date();
    let tomorrow_5 = new Date();
    let tomorrow_6 = new Date();

    let yesterday = new Date();
    let yesterday_2 = new Date();
    let yesterday_3 = new Date();
    let yesterday_4 = new Date();
    let yesterday_5 = new Date();
    let yesterday_6 = new Date();

    tomorrow.setDate(new Date().getDate() + 1);
    tomorrow_2.setDate(new Date().getDate() + 2);
    tomorrow_3.setDate(new Date().getDate() + 3);
    tomorrow_4.setDate(new Date().getDate() + 4);
    tomorrow_5.setDate(new Date().getDate() + 5);
    tomorrow_6.setDate(new Date().getDate() + 6);

    yesterday.setDate(new Date().getDate() - 1);
    yesterday_2.setDate(new Date().getDate() - 2);
    yesterday_3.setDate(new Date().getDate() - 3);
    yesterday_4.setDate(new Date().getDate() - 4);
    yesterday_5.setDate(new Date().getDate() - 5);
    yesterday_6.setDate(new Date().getDate() - 6);

    switch (dayOfWeekNumber) {
      case 0:
        nameOfDay = "Sunday";
        break;
      case 1:
        nameOfDay = "Monday";
        break;
      case 2:
        nameOfDay = "Tuesday";
        break;
      case 3:
        nameOfDay = "Wednesday";
        break;
      case 4:
        nameOfDay = "Thursday";
        break;
      case 5:
        nameOfDay = "Friday";
        break;
      case 6:
        nameOfDay = "Saturday";
        break;

      default: {
        break;
      }
    }

    if (nameOfDay === "Monday") {
      this.setState({
        monValue: today,
        tueValue: tomorrow,
        wedValue: tomorrow_2,
        thuValue: tomorrow_3,
        friValue: tomorrow_4,
        satValue: tomorrow_5,
        sunValue: tomorrow_6,
        monActive: true,
      });
    } else if (nameOfDay === "Tuesday") {
      this.setState({
        monValue: yesterday,
        tueValue: today,
        wedValue: tomorrow,
        thuValue: tomorrow_2,
        friValue: tomorrow_3,
        satValue: tomorrow_4,
        sunValue: tomorrow_5,
        tueActive: true,
      });
    } else if (nameOfDay === "Wednesday") {
      this.setState({
        monValue: yesterday_2,
        tueValue: yesterday,
        wedValue: today,
        thuValue: tomorrow,
        friValue: tomorrow_2,
        satValue: tomorrow_3,
        sunValue: tomorrow_4,
        wedActive: true,
      });
    } else if (nameOfDay === "Thursday") {
      this.setState({
        monValue: yesterday_3,
        tueValue: yesterday_2,
        wedValue: yesterday,
        thuValue: today,
        friValue: tomorrow,
        satValue: tomorrow_2,
        sunValue: tomorrow_3,
        thuActive: true,
      });
    } else if (nameOfDay === "Friday") {
      this.setState({
        monValue: yesterday_4,
        tueValue: yesterday_3,
        wedValue: yesterday_2,
        thuValue: yesterday,
        friValue: today,
        satValue: tomorrow,
        sunValue: tomorrow_2,
        friActive: true,
      });
    } else if (nameOfDay === "Saturday") {
      this.setState({
        monValue: yesterday_5,
        tueValue: yesterday_4,
        wedValue: yesterday_3,
        thuValue: yesterday_2,
        friValue: yesterday,
        satValue: today,
        sunValue: tomorrow,
        satActive: true,
      });
    } else if (nameOfDay === "Sunday") {
      this.setState({
        monValue: yesterday_6,
        tueValue: yesterday_5,
        wedValue: yesterday_4,
        thuValue: yesterday_3,
        friValue: yesterday_2,
        satValue: yesterday,
        sunValue: today,
        sunActive: true,
      });
    }
  };

  onFinish = (values) => {
    this.setState({
      loading: true,
    });
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgnizationId = JSON.parse(localStorage.getItem("orgId"));
    const {
      citySearch,
      markAsTraine,
      allAgents,
      traingModalData,
      editInput,
      meetType,
      resourceImage,
    } = this.state;
    values["meetDate"] = moment(values["meetDate"]).format("L");
    values["startTime"] = moment(values["startTime"]).format("hh:mm A");
    values["endTime"] = moment(values["endTime"]).format("hh:mm A");
    values["address"] =
      citySearch !== undefined ? citySearch : traingModalData?.address;
    values["creatorInfo"] = userDetails.firstName + " " + userDetails.lastName;
    values["markAsTraine"] = editInput
      ? meetType === "meeting"
        ? false
        : true
      : markAsTraine;
    let AgentData = allAgents.filter((a) => values["attendees"] === a.id && a);
    let attendeesVal = [];
    AgentData.filter((e) => {
      if (e.id === "123") {
        attendeesVal = allAgents.filter((a) => a.id !== "123");
        attendeesVal.push(userDetails);
      } else {
        attendeesVal = [e];
        attendeesVal.push(userDetails);
      }
      return true;
    });
    values["attendees"] =
      attendeesVal.length > 0 ? attendeesVal : traingModalData.attendees;
    values["userId"] = userDetails.id;
    values["orgId"] = orgnizationId;
    values["uploadImage"] =
      resourceImage !== "" ? resourceImage : traingModalData?.uploadImage;
    let data = {
      entityData: values,
    };
    if (traingModalData.length !== 0) {
      update("virtualOfficeMeeting", traingModalData.id, data)
        .then((result) => {
          if (result.success) {
            this.setState({
              trainingModal: false,
              loading: false,
              resourceImage: "",
              traingModalData: "",
            });
            this.init();
          }
        })
        .catch((err) => {
          this.setState({
            trainingModal: false,
            loading: false,
            resourceImage: "",
            traingModalData: "",
          });
          console.log(err);
        });
    } else {
      add("virtualOfficeMeeting", data)
        .then((result) => {
          if (result.success) {
            this.setState({
              meetForm: false,
              loading: false,
              resourceImage: "",
            });
            this.init();
          }
        })
        .catch((err) => {
          this.setState({
            meetForm: false,
            loading: false,
            resourceImage: "",
          });
          console.log(err);
        });
    }
  };

  showModal = (e, type) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.setState({
      trainingModal: true,
      traingModalData: e,
      meetType: type,
    });
    let data = [e].filter((user) => user.userId === userDetails.id);
    if (data.length > 0) {
      this.setState({
        editData: false,
      });
    } else {
      this.setState({
        editData: true,
      });
    }
  };

  render() {
    const {
      resourceImage,
      weeekView,
      monthView,
      monValue,
      tueValue,
      wedValue,
      thuValue,
      friValue,
      satValue,
      sunValue,

      monActive,
      tueActive,
      wedActive,
      thuActive,
      friActive,
      satActive,
      sunActive,
      todoColorDates,
      todoActiveDates,
      selectedDate,
      selectedtraining,
      traningColorDates,
      citySearch,
      allAgents,
      meetColorDates,
      selectedMeetDate,
      tainingWeekDates,
      meetingWeekDates,
      loading,
      trainingModal,
      traingModalData,
      editData,
      editInput,
    } = this.state;
    const content = (
      <Row style={{ width: "100px" }}>
        <Col
          span={24}
          onClick={() => {
            this.setState({ weeekView: true, monthView: false });
          }}
          style={{
            background: weeekView ? "#99a2c3" : "white",
            cursor: "pointer",
            marginBottom: "10px",
          }}
        >
          <span className="calender_view">Week view</span>
        </Col>
        <Col
          span={24}
          onClick={() => {
            this.setState({ monthView: true, weeekView: false });
          }}
          style={{
            background: monthView ? "#99a2c3" : "white",
            cursor: "pointer",
          }}
        >
          <span className="calender_view">Month view</span>
        </Col>
      </Row>
    );

    let loginDetails = JSON.parse(localStorage.getItem("userDetails"));
    return (
      <div>
        <Card
          title="Calendar"
          className="card_virtual"
          hoverable={true}
          extra={
            <Button
              icon={<PlusOutlined style={{ color: "#3045d2" }} />}
              onClick={() => {
                this.setState({
                  meetForm: true,
                });
              }}
              className="todo_button"
              style={{ float: "right", border: "1px solid #3045d2" }}
            >
              <span className="virtual_button_text btn-color">ADD MEETING</span>
            </Button>
          }
        >
          <Popover content={content} placement="bottomLeft">
            <MoreOutlined
              style={{ fontSize: "25px", color: "#99a2c3" }}
              onClick={this.moreChange}
            />
          </Popover>
          {monthView ? (
            <>
              <Calendar
                onChange={this.onChange}
                // value={new Date()}
                tileContent={({ date, view }) =>
                  view === "month" && (
                    <Row style={{ justifyContent: "space-evenly" }}>
                      {todoColorDates.includes(moment(date).format("L")) ? (
                        <Col
                          style={{
                            background: "#FC572B",
                            borderRadius: "50%",
                            width: "7px",
                            height: "7px",
                            marginTop: "10px",
                          }}
                        />
                      ) : null}
                      {traningColorDates.includes(moment(date).format("L")) ? (
                        <Col
                          style={{
                            background: "#DB17C2",
                            borderRadius: "50%",
                            width: "7px",
                            height: "7px",
                            marginTop: "10px",
                          }}
                        />
                      ) : null}
                      {meetColorDates.includes(moment(date).format("L")) ? (
                        <Col
                          style={{
                            background: "#a52a2a",
                            borderRadius: "50%",
                            width: "7px",
                            height: "7px",
                            marginTop: "10px",
                          }}
                        />
                      ) : null}
                    </Row>
                  )
                }
              />
              {selectedDate?.length > 0 && (
                <Col span={23} offset={1}>
                  <span
                    className="dot_metting"
                    style={{
                      backgroundColor: "#FC572B",
                    }}
                  />
                  <span className="metting_tiltle">Todo</span>
                  <Col style={{ marginLeft: "20px", marginTop: "5px" }}>
                    {selectedDate.map((d) => (
                      <Row>
                        <Col span={2}>
                          <CheckSquareOutlined style={{ color: "#99a2c3" }} />
                        </Col>
                        <Col span={19} className="metting_subtext">
                          {d.title}
                        </Col>
                        <Col span={3} className="metting_subtext">
                          {moment(d.dueDate).format("DD MMM")}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Col>
              )}

              {selectedtraining?.length > 0 && (
                <Col span={23} offset={1}>
                  <span
                    className="dot_metting"
                    style={{
                      backgroundColor: "#DB17C2",
                    }}
                  />
                  <span className="metting_tiltle">Trainings</span>
                  <Col style={{ marginLeft: "20px", marginTop: "5px" }}>
                    {selectedtraining.map((d) => (
                      <Row onClick={() => this.showModal(d, "training")}>
                        <Col span={2}>
                          <i
                            className="fa fa-graduation-cap  fa-lg icon_dashboard"
                            aria-hidden="true"
                            style={{ color: "#99a2c3" }}
                          />
                        </Col>
                        <Col span={19} className="metting_subtext">
                          <Row>{d.title}</Row>
                          {d.meetingUrl ? (
                            // <a
                            //   target="_blank"
                            //   rel="noreferrer"
                            //   href={`https://${d.meetingUrl}`}
                            //   style={{ textDecoration: "none" }}
                            // >
                            d.meetingUrl
                          ) : (
                            // </a>
                            <Row>{d.address}</Row>
                          )}
                        </Col>
                        <Col span={3} className="metting_subtext">
                          {moment(d.meetDate).format("DD MMM")}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Col>
              )}

              {selectedMeetDate?.length > 0 && (
                <Col span={23} offset={1}>
                  <span
                    className="dot_metting"
                    style={{
                      backgroundColor: "#a52a2a",
                    }}
                  />
                  <span className="metting_tiltle">Meetings</span>
                  <Col style={{ marginLeft: "20px", marginTop: "5px" }}>
                    {selectedMeetDate.map((d) => (
                      <Row>
                        <Col
                          span={2}
                          onClick={() => this.showModal(d, "meeting")}
                        >
                          <i
                            className="fa fa-calendar  fa-lg icon_dashboard"
                            aria-hidden="true"
                            style={{ color: "#99a2c3", lineHeight: "0" }}
                          />
                        </Col>
                        <Col
                          span={19}
                          className="metting_subtext"
                          onClick={() => this.showModal(d, "meeting")}
                        >
                          <Row>{d.title}</Row>
                          {d.meetingUrl ? (
                            // <a
                            //   target="_blank"
                            //   rel="noreferrer"
                            //   href={`https://${d.meetingUrl}`}
                            //   style={{ textDecoration: "none" }}
                            // >
                            d.meetingUrl
                          ) : (
                            // </a>
                            <Row>{d.address}</Row>
                          )}
                        </Col>
                        <Col span={3} className="metting_subtext">
                          {moment(d.meetDate).format("DD MMM")}
                          {d.userId === loginDetails.id && (
                            <DeleteRecord
                              recordId={d.id}
                              apiPath={"virtualOfficeMeeting"}
                              getHomesList={this.props.getAllTodos}
                              deletionType={"DeleteOutlined"}
                              deletionSuccessMessage={
                                "Removed from Meetings list"
                              }
                            />
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Col>
              )}
            </>
          ) : (
            <>
              <Calendar
                onChange={this.onChange}
                value={new Date()}
                className="weekView"
              />
              <div
                class="react-calendar__month-view__days"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <button
                  class="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth"
                  type="button"
                  style={{
                    flex: " 0 0 14.2857%",
                    overflow: "hidden",
                    background: monActive && "#1087ff",
                  }}
                >
                  <span
                    style={{
                      color: monActive ? "#FFF" : "#757575",
                    }}
                  >
                    {moment(monValue).format("DD")}
                  </span>
                </button>
                <button
                  class="react-calendar__tile react-calendar__month-view__days__day"
                  type="button"
                  style={{
                    flex: " 0 0 14.2857%",
                    overflow: "hidden",
                    background: tueActive && "#1087ff",
                  }}
                >
                  <span
                    style={{
                      color: tueActive ? "#FFF" : "#000",
                    }}
                  >
                    {moment(tueValue).format("DD")}
                  </span>
                </button>
                <button
                  class="react-calendar__tile react-calendar__month-view__days__day"
                  type="button"
                  style={{
                    flex: " 0 0 14.2857%",
                    overflow: "hidden",
                    background: wedActive && "#1087ff",
                  }}
                >
                  <span
                    style={{
                      color: wedActive ? "#FFF" : "#000",
                    }}
                  >
                    {moment(wedValue).format("DD")}
                  </span>
                </button>
                <button
                  class="react-calendar__tile react-calendar__month-view__days__day"
                  type="button"
                  style={{
                    flex: " 0 0 14.2857%",
                    overflow: "hidden",
                    background: thuActive && "#1087ff",
                  }}
                >
                  <span
                    style={{
                      color: thuActive ? "#FFF" : "#000",
                    }}
                  >
                    {moment(thuValue).format("DD")}
                  </span>
                </button>
                <button
                  class="react-calendar__tile react-calendar__month-view__days__day"
                  type="button"
                  style={{
                    flex: " 0 0 14.2857%",
                    overflow: "hidden",
                    background: friActive && "#1087ff",
                  }}
                >
                  <span
                    style={{
                      color: friActive ? "#FFF" : "#000",
                    }}
                  >
                    {moment(friValue).format("DD")}
                  </span>
                </button>
                <button
                  class="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend"
                  type="button"
                  style={{
                    flex: " 0 0 14.2857%",
                    overflow: "hidden",
                    background: satActive && "#1087ff",
                  }}
                >
                  <span
                    style={{
                      color: satActive ? "#FFF" : "#d10000",
                    }}
                  >
                    {moment(satValue).format("DD")}
                  </span>
                </button>
                <button
                  class="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend"
                  type="button"
                  style={{
                    flex: " 0 0 14.2857%",
                    overflow: "hidden",
                    background: sunActive && "#1087ff",
                  }}
                >
                  <span
                    style={{
                      color: sunActive ? "#FFF" : "#d10000",
                    }}
                  >
                    {moment(sunValue).format("DD")}
                  </span>
                </button>
              </div>

              <Divider />
              <Row>
                <Col span={24}>
                  <span
                    className="dot_metting"
                    style={{
                      backgroundColor: "#FC572B",
                    }}
                  />
                  <span className="metting_tiltle">Weekly Todo's</span>
                  <Col style={{ marginLeft: "20px" }}>
                    {todoActiveDates.map((d) => (
                      <Row>
                        <Col span={2}>
                          <CheckSquareOutlined style={{ color: "#99a2c3" }} />
                        </Col>
                        <Col span={19} className="metting_subtext">
                          {d.title}
                        </Col>
                        <Col span={3} className="metting_subtext">
                          {moment(d.dueDate).format("DD MMM")}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Col>

                <Col span={24}>
                  <span
                    className="dot_metting"
                    style={{
                      backgroundColor: "#DB17C2",
                    }}
                  />
                  <span className="metting_tiltle">Weekly Training's</span>
                  <Col style={{ marginLeft: "20px" }}>
                    {tainingWeekDates.map((d) => (
                      <Row>
                        <Col span={2}>
                          <i
                            className="fa fa-graduation-cap  fa-lg icon_dashboard"
                            aria-hidden="true"
                            style={{ color: "#99a2c3" }}
                          />
                        </Col>
                        <Col span={19} className="metting_subtext">
                          <Row>
                            {d.attendees[0].firstName +
                              " " +
                              d.attendees[0].lastName}
                          </Row>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://${d.meetingUrl}`}
                            style={{ textDecoration: "none" }}
                          >
                            {d.meetingUrl}
                          </a>
                        </Col>
                        <Col span={3} className="metting_subtext">
                          {moment(d.meetDate).format("DD MMM")}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Col>

                <Col span={24}>
                  <span
                    className="dot_metting"
                    style={{
                      backgroundColor: "#a52a2a",
                    }}
                  />
                  <span className="metting_tiltle">Weekly Meeting's</span>
                  <Col style={{ marginLeft: "20px" }}>
                    {meetingWeekDates.map((d) => (
                      <Row>
                        <Col span={2}>
                          <i
                            className="fa fa-calendar  fa-lg icon_dashboard"
                            aria-hidden="true"
                            style={{ color: "#99a2c3", lineHeight: "0" }}
                          />
                        </Col>
                        <Col span={19} className="metting_subtext">
                          <Row>
                            {d.attendees[0].firstName +
                              " " +
                              d.attendees[0].lastName}
                          </Row>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://${d.videoUrl}`}
                            style={{ textDecoration: "none" }}
                          >
                            {d.meetingUrl}
                          </a>
                        </Col>
                        <Col span={3} className="metting_subtext">
                          {moment(d.meetDate).format("DD MMM")}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Card>

        <Modal
          className="modal-black-close"
          visible={this.state.meetForm}
          title="Create Meeting/Training"
          style={{ marginTop: "2%" }}
          destroyOnClose={true}
          onCancel={() =>
            this.setState({
              meetForm: false,
              editModal: false,
              resourceImage: "",
            })
          }
          footer={
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Button
                    onClick={() =>
                      this.setState({
                        meetForm: false,
                        editModal: false,
                        resourceImage: "",
                      })
                    }
                    type="default"
                    style={{
                      width: "100%",
                      backgroundColor: "#bbb",
                      color: "#FFF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    htmlType="reset"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    htmlType="submit"
                    form="form"
                    type="primary"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    loading={loading}
                  >
                    Create Meeting
                  </Button>
                </Col>
              </Row>
            </Col>
          }
          width={"35%"}
        >
          <Form onFinish={this.onFinish} id="form" initialValues={false}>
            <Card
              style={{ marginTop: "10px" }}
              className="card_virtual"
              hoverable={true}
            >
              <Col span={24}>
                <ModalTitle>Date</ModalTitle>
                <Form.Item
                  name="meetDate"
                  rules={[
                    {
                      required: true,
                      message: "Please input date!",
                    },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    format="MM/DD/YYYY"
                    // defaultValue={
                    //   leads.followDate !== null &&
                    //   moment(leads.followDate, "MM/DD/YYYY")
                    // }
                    style={{
                      height: "40px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Row>
                <Col span={11}>
                  <ModalTitle style={{ marginTop: "10px" }}>
                    Start Time
                  </ModalTitle>
                  <Form.Item
                    name="startTime"
                    rules={[
                      {
                        required: true,
                        message: "Please input start time!",
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      minuteStep={15}
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      onChange={() => this.handleTime("start")}
                      className="time-picker"
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={11} offset={2}>
                  <ModalTitle style={{ marginTop: "10px" }}>
                    End Time
                  </ModalTitle>
                  <Form.Item
                    name="endTime"
                    rules={[
                      {
                        required: true,
                        message: "Please input end time!",
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      minuteStep={15}
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      onChange={() => this.handleTime("end")}
                      className="time-picker"
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Col span={24}>
                <ModalTitle style={{ marginTop: "10px" }}>Title</ModalTitle>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input title !",
                    },
                  ]}
                  name="title"
                >
                  <Input
                    placeholder="Please input title"
                    // className="form-input"
                    style={{ height: "40px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <ModalTitle style={{ marginTop: "10px" }}>Attendees</ModalTitle>
                <Form.Item
                  name="attendees"
                  rules={[
                    {
                      required: true,
                      message: "Please input your attendees!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    // defaultValue={"attendees.."}
                    showSearch
                    options={allAgents.firstName}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ borderRadius: "5px " }}
                    placeholder="attendees.."
                  >
                    {allAgents.map((province) => (
                      <Select.Option key={province.id} value={province.id}>
                        {province.firstName + " " + province.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <ModalTitle style={{ marginTop: "10px" }}>
                  Meeting Url
                </ModalTitle>
                <Form.Item
                  name="meetingUrl"
                  rules={[
                    {
                      required: false,
                      message: "Please input your meeting url!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please input meeting url"
                    // className="form-input"
                    style={{ height: "40px" }}
                    onChange={(e) => {
                      this.setState({ meetingUrl: e.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <ModalTitle>Address</ModalTitle>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: "Please input address",
                    },
                  ]}
                  // initialValue={proConatct?.address?.citySearch}
                  style={{ marginBottom: "1vw" }}
                >
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_MAPS_KEY}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                    }}
                    selectProps={{
                      citySearch,
                      // defaultInputValue: `${
                      //   proConatct?.address?.citySearch || ""
                      // }`,
                      placeholder: "Search Address...",
                      onChange: (address) => {
                        this.handleSelect(address);
                      },
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <ModalTitle>Upload</ModalTitle>
                <Form.Item
                  name="upload"
                  rules={[
                    {
                      required: false,
                      message: "Please input your images!",
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    style={{
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    showUploadList={false}
                    customRequest={dummyRequest}
                    onChange={(e) => this.handleUploadPic(e)}
                  >
                    <Spin spinning={this.state.imageLoading}>
                      <Row>
                        {resourceImage ? (
                          <img
                            src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${resourceImage}`}
                            className="ant-icon"
                            alt="meetingIcon"
                            style={{ width: "100%", heighht: "100%" }}
                          />
                        ) : (
                          <CameraOutlined
                            className="camera-icon"
                            style={{ fontSize: "45px" }}
                          />
                        )}
                      </Row>
                    </Spin>
                  </Upload>
                </Form.Item>
              </Col>

              <Checkbox
                onChange={(e) =>
                  this.setState({ markAsTraine: e.target.checked })
                }
              >
                Mark As Training
              </Checkbox>
            </Card>
          </Form>
        </Modal>

        <Modal
          className="modal-black-close"
          visible={trainingModal}
          title="Meeting/Training Details"
          style={{ marginTop: "2%" }}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({
              trainingModal: false,
              traingModalData: "",
              editData: false,
              resourceImage: "",
            });
          }}
          footer={
            <Col span={24}>
              {!editData ? (
                <Row gutter={[24, 24]}>
                  <Col span={12}>
                    <Button
                      onClick={() => {
                        this.setState({
                          trainingModal: false,
                          traingModalData: "",
                          editData: false,
                          resourceImage: "",
                        });
                      }}
                      type="default"
                      style={{
                        width: "100%",
                        backgroundColor: "#bbb",
                        color: "#FFF",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      htmlType="reset"
                    >
                      Cancel
                    </Button>
                  </Col>
                  {editInput && (
                    <Col span={12}>
                      <Button
                        htmlType="submit"
                        form="form"
                        type="primary"
                        style={{
                          width: "100%",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                        loading={loading}
                      >
                        Update Training
                      </Button>
                    </Col>
                  )}
                  {!editInput && (
                    <Col span={12}>
                      <Button
                        type="primary"
                        onClick={() => {
                          moment(traingModalData?.meetDate).format("L") >=
                          moment(new Date()).format("L")
                            ? this.setState({
                                editInput: true,
                              })
                            : notification["error"]({
                                message: "Date must not be in the past.",
                              });
                        }}
                        style={{
                          width: "100%",
                          color: "#FFF",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      >
                        Edit
                      </Button>
                    </Col>
                  )}
                </Row>
              ) : (
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Button
                      onClick={() => {
                        this.setState({
                          trainingModal: false,
                          traingModalData: "",
                          editData: false,
                          resourceImage: "",
                        });
                      }}
                      type="default"
                      style={{
                        width: "100%",
                        backgroundColor: "#bbb",
                        color: "#FFF",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      htmlType="reset"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          }
          width={"35%"}
        >
          <Form onFinish={this.onFinish} id="form" initialValues={false}>
            <Card
              style={{ marginTop: "10px" }}
              className="card_virtual"
              hoverable={true}
              bodyStyle={{ padding: "10px" }}
            >
              <Row>
                <Col span={24} style={{ marginBottom: "1vw" }}>
                  {editInput ? (
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input title !",
                        },
                      ]}
                      name="title"
                      initialValue={traingModalData.title}
                    >
                      <Input
                        placeholder="Please input title"
                        style={{ height: "40px" }}
                      />
                    </Form.Item>
                  ) : (
                    <div className="traing_modal_title">
                      {traingModalData.title}
                    </div>
                  )}
                </Col>
                {editInput ? (
                  <Col xl={{ span: 7 }}>
                    <Spin
                      spinning={loading}
                      size="large"
                      className="center-item"
                      style={{ marginTop: "10%" }}
                    >
                      {resourceImage !== "" ||
                      traingModalData?.uploadImage !== undefined ||
                      traingModalData?.uploadImage !== "" ? (
                        <img
                          src={`https://${
                            process.env.REACT_APP_S3_BUCKET
                          }.s3.amazonaws.com/public/${
                            resourceImage
                              ? resourceImage
                              : traingModalData?.uploadImage
                          }`}
                          alt="profile"
                          className="img-profile"
                          style={{
                            width: "180px",
                            height: "180px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <img
                          src={Avatar}
                          alt="profile"
                          className="img-profile"
                          style={{
                            width: "180px",
                            height: "180px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                      <Button
                        type="primary"
                        className="update-profile-btn"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Upload
                          showUploadList={false}
                          customRequest={dummyRequest}
                          readOnly={editData ? true : false}
                          onChange={(e) => this.handleUploadPic(e)}
                        >
                          <span className="Btn-name">Update Image</span>
                        </Upload>
                      </Button>
                    </Spin>
                  </Col>
                ) : (
                  traingModalData?.uploadImage && (
                    <Col xl={{ span: 7 }}>
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${traingModalData?.uploadImage}`}
                        className="ant-icon"
                        alt="meetingIcon"
                        style={{
                          width: "180px",
                          height: "180px",
                          objectFit: "contain",
                        }}
                      />
                    </Col>
                  )
                )}
                <Col
                  xl={{
                    span: traingModalData?.uploadImage
                      ? 16
                      : editInput
                      ? 16
                      : 24,
                    offset: traingModalData?.uploadImage
                      ? 1
                      : editInput
                      ? 1
                      : 0,
                  }}
                >
                  {editInput ? (
                    <Form.Item
                      name="meetDate"
                      rules={[
                        {
                          required: true,
                          message: "Please input date!",
                        },
                      ]}
                      initialValue={
                        traingModalData?.meetDate
                          ? moment(traingModalData.meetDate)
                          : moment()
                      }
                      style={{ marginBottom: "10px" }}
                    >
                      <DatePicker
                        format="MM/DD/YYYY"
                        style={{
                          height: "40px",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                        readOnly={editData ? true : false}
                      />
                    </Form.Item>
                  ) : (
                    <div className="traing_modal_title">
                      {moment(traingModalData?.meetDate).format("MMMM Do YYYY")}
                    </div>
                  )}
                  <div
                    className="traing_modal_title"
                    style={{ display: "flex" }}
                  >
                    <div className="traing_modal_title">
                      {editInput ? (
                        <Form.Item
                          name="startTime"
                          rules={[
                            {
                              required: true,
                              message: "Please input start time!",
                            },
                          ]}
                          initialValue={
                            traingModalData.startTime ? moment() : ""
                          }
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            // defaultValue={traingModalData.startTime}
                            placeholder="Select Time"
                            use12Hours
                            minuteStep={15}
                            showSecond={false}
                            focusOnOpen={true}
                            format="hh:mm A"
                            onChange={(e) => this.handleTime("start", e)}
                            className="time-picker"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                            inputReadOnly={editData ? true : false}
                          />
                        </Form.Item>
                      ) : (
                        traingModalData?.startTime
                      )}
                    </div>
                    <div
                      className="traing_modal_title"
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      {" "}
                      to{" "}
                    </div>
                    <div className="traing_modal_title">
                      {editInput ? (
                        <Form.Item
                          name="endTime"
                          rules={[
                            {
                              required: true,
                              message: "Please input end time!",
                            },
                          ]}
                          initialValue={
                            traingModalData.startTime ? moment() : ""
                          }
                          style={{ marginBottom: "10px" }}
                        >
                          <TimePicker
                            placeholder="Select Time"
                            use12Hours
                            minuteStep={15}
                            showSecond={false}
                            focusOnOpen={true}
                            format="hh:mm A"
                            onChange={(e) => this.handleTime("end", e)}
                            className="time-picker"
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                            inputReadOnly={editData ? true : false}
                          />
                        </Form.Item>
                      ) : (
                        traingModalData?.endTime
                      )}
                    </div>
                  </div>
                  <div className="traing_modal_title">
                    {editInput ? (
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: false,
                            message: "Please input address",
                          },
                        ]}
                        readOnly={editData ? true : false}
                        initialValue={traingModalData?.address}
                        style={{ marginBottom: "10px" }}
                      >
                        <GooglePlacesAutocomplete
                          apiKey={process.env.REACT_APP_MAPS_KEY}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["us"],
                            },
                          }}
                          selectProps={{
                            citySearch,
                            defaultInputValue: `${
                              traingModalData?.address
                                ? traingModalData?.address
                                : ""
                            }`,
                            placeholder: "Search Address...",
                            onChange: (address) => {
                              this.handleSelect(address);
                            },
                          }}
                        />
                      </Form.Item>
                    ) : (
                      traingModalData.address
                    )}
                  </div>
                  <div className="traing_modal_title">
                    {" "}
                    {editInput ? (
                      <Form.Item
                        name="meetingUrl"
                        rules={[
                          {
                            required: false,
                            message: "Please input your meeting url!",
                          },
                        ]}
                        initialValue={traingModalData.meetingUrl}
                      >
                        <Input
                          placeholder="Please input meeting url"
                          // className="form-input"
                          style={{ height: "40px" }}
                          readOnly={editData ? true : false}
                        />
                      </Form.Item>
                    ) : (
                      traingModalData.meetingUrl
                    )}
                  </div>
                  {!editInput && (
                    <div className="traing_modal_title">
                      {traingModalData?.creatorInfo
                        ? "Created by " + traingModalData?.creatorInfo
                        : ""}
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default withAppContext(withRouter(Meetings));
