import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Table, Card, Row, Col, Modal, Collapse, Button, Tag } from "antd";
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { list } from "../../../api/Apis";
import { withAppContext } from "../../../store/contextStore";
import moment from "moment";
import AddContact from "./AddContact";

const { Panel } = Collapse;
class ActiveDashboardLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completedLoading: false,
      pendingLeads: [],
      completedLeads: [],
      modalVisible: false,
      viewProperty: "",
      activeTabKey: 1,
      totalLeads: [],
      openAddContactForm: false,
    };
  }

  componentDidMount() {
    this.getLeads();
  }

  getLeads = async () => {
    this.setState({
      completedLoading: true,
    });
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgnizationId = JSON.parse(localStorage.getItem("orgId"));

    let userId = userDetails.id;
    let orgId = orgnizationId;
    let query = {};

    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
    } else {
      query["userId"] = userId;
    }
    query = {
      ...query,
      contactType: "Contact",
      listingStatus: [
        "Active Listing",
        "Active Buyer",
        "Prospective Buyer",
        "Prospective seller or buyer",
        "Prospective Seller"
      ],
    };
    list("getVirtualContacts", query)
      .then((result) => {
        if (result.success) {
          this.setState({
            totalLeads: result.data.contactList.sort((a, b) =>
              a.createdAt < b.createdAt ? 1 : -1
            ),
            completedLoading: false,
          });
        } else {
          this.setState({
            completedLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error, "error");
        this.setState({
          completedLoading: false,
        });
      });
  };

  handleRowClick = (record) => {
    this.props.history.push(
      `/VirtualOffice/ViewAllContacts/${record.id}`,
      "dashboard"
    );
  };

  handleLoading = (completedLoading) => {
    this.setState({
      completedLoading,
    });
  };
  handleCancel = () => {
    this.setState({
      openAddContactForm: false,
    });
  };
  render() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const {
      modalVisible,
      viewProperty,
      totalLeads,
      openAddContactForm,
      completedLoading,
    } = this.state;

    let webColumns = [
      {
        title: "AGENT NAME",
        dataIndex: "assignAgent",
        key: "assignAgent",
        onCell: (record) => ({
          onClick: () => this.handleRowClick(record),
        }),
        adminCol: userDetails?.userProfile !== "AG" ? true : false,
        render: (assignAgent, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {assignAgent?.length > 0 &&
                assignAgent[0]?.firstName + " " + assignAgent[0]?.lastName}
            </span>
          );
        },
      },
      {
        title: "DATE",
        dataIndex: "registered",
        key: "registered",
        // defaultSortOrder: "ascend",
        sorter:
          // userDetails?.userProfile !== "AG"
          //   ? 
            (a, b) => new Date(a.registered) - new Date(b.registered),
            // : false,
        onCell: (record) => ({
          onClick: () => this.handleRowClick(record),
        }),
        adminCol: true,

        render: (registered) => {
          return (
            <span>
              {registered !== "" && moment(registered).format("MM/DD/YYYY")}
            </span>
          );
        },
      },
      {
        title: "NAME",
        dataIndex: "firstName",
        key: "firstName",
        onCell: (record) => ({
          onClick: () => this.handleRowClick(record),
        }),
        adminCol: true,

        render: (firstName, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {`${firstName} ${record.lastName}`}
            </span>
          );
        },
      },
      {
        title: "LAST ACTIVE DATE",
        dataIndex: "lastActiveDate",
        key: "lastActiveDate",
        // defaultSortOrder: "ascend",
        adminCol: true,
        sorter:false,
          // userDetails?.userProfile !== "AG"
          //   ? 
            // (a, b) => new Date(a.lastActiveDate) - new Date(b.lastActiveDate),
            // : false,
        onCell: (record) => ({
          onClick: () => this.handleRowClick(record),
        }),
        render: (lastActiveDate) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {lastActiveDate ? moment.unix(lastActiveDate).format("MM/DD/YYYY hh:mm A") : ""}
            </span>
          );
        },
      },
      {
        title: "FOLLOW UP DATE",
        dataIndex: "followDate",
        key: "followDate",
        // defaultSortOrder: "ascend",
        adminCol: true,
        // showSorterTooltip: <Tooltip title="prompt text"/>,

        sorter:
          // userDetails?.userProfile !== "AG"
          //   ? 
            (a, b) => new Date(a.followDate) - new Date(b.followDate),
            // : false,

        onCell: (record) => ({
          onClick: () => this.handleRowClick(record),
        }),
        render: (followDate) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {followDate ? moment(followDate).format("MM/DD/YYYY") : ""}
            </span>
          );
        },
      },
      {
        title: "SOURCE",
        dataIndex: "sourceLead",
        key: "sourceLead",
        sorter: false,
        // defaultSortOrder:"ascend",
        adminCol: true,

        onCell: (record) => ({
          onClick: () => this.handleRowClick(record),
        }),
        render: (sourceLead) => {
          return <span>{sourceLead}</span>;
        },
      },
      {
        title: "STATUS",
        dataIndex: "listingStatus",
        key: "listingStatus",
        sorter: false,
        adminCol: true,

        onCell: (record) => ({
          onClick: () => this.handleRowClick(record),
        }),
        render: (tag, record) => {
          return (
            <Tag
              style={{
                border: "none",
                background:
                  tag === "Closed"
                    ? "red"
                    : tag === "Under Agreement"
                    ? "#DCF1FA"
                    : tag === "Active Listing"
                    ? "#E6E6F8"
                    : tag === "Active Buyer"
                    ? "#D5F7EF"
                    : tag === "Prospective Buyer"
                    ? "#FFE2E2"
                    : tag === "Prospective Seller"
                    ? "#E5CCFF"
                    : tag === "Prospective seller or buyer"
                    && "#FFE8DE",

                color:
                  tag === "Closed"
                    ? "#FFF"
                    : tag === "Under Agreement"
                    ? "#34ACE0"
                    : tag === "Active Listing"
                    ? "#706FD3"
                    : tag === "Active Buyer"
                    ? "#33D9B2"
                    : tag === "Prospective Buyer"
                    ? "#FF5252"
                    : tag === "Prospective Seller"
                    ? "#9933FF"
                    : tag === "Prospective seller or buyer"
                    && "#9933FF",
              }}
              key={tag}
            >
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                }}
              >
                {tag === "Prospective seller or buyer"
                  ? "Prospective Seller and Buyer".toUpperCase()
                  : tag?.toUpperCase()}
              </span>
            </Tag>
          );
        },
      },
    ];

    webColumns = webColumns.filter((col) => col.adminCol);

    const mobColumns = [
      {
        title: "Add Clients/Prospective Business",
        dataIndex: "productOriginDetails",
        key: "productOriginDetails",
        render: (productOriginDetails, record) => (
          <Collapse collapsible="true">
            <Panel
              header={record.firstName}
              key="1"
              extra={
                <ArrowRightOutlined
                  onClick={() => {
                    window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
                  }}
                />
              }
            >
              <Row
                className="row-margin-table"
                onClick={() => {
                  window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
                }}
              >
                <Col className="col-tilte">Date</Col>
                <Col className="col-desc">{record.registered}</Col>
              </Row>
              <Row
                className="row-margin-table"
                onClick={() => {
                  window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
                }}
              >
                <Col className="col-tilte">Name</Col>
                <Col className="col-desc">
                  {record.firstName + " " + record.lastName}
                </Col>
              </Row>
              <Row
                className="row-margin-table"
                onClick={() => {
                  window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
                }}
              >
                <Col className="col-tilte">Phone #</Col>
                <Col className="col-desc">{record.cellPhone}</Col>
              </Row>

              <Row
                className="row-margin-table"
                onClick={() => {
                  window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
                }}
              >
                <Col className="col-tilte">Email</Col>
                <Col className="col-desc" style={{ marginLeft: "0px" }}>
                  {record.emailAddress}
                </Col>
              </Row>

              <Row
                className="row-margin-table"
                onClick={() => {
                  window.location = `/VirtualOffice/ViewAllContacts/${record.id}`;
                }}
              >
                <Col className="col-tilte">Status</Col>
                <Col className="col-desc">{record.listingStatus}</Col>
              </Row>
            </Panel>
          </Collapse>
        ),
      },
    ];

    return (
      <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
        {/* <Loading enableLoading={completedLoading} /> */}
        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
        >
          <Card
            title={
              <Link to="/VirtualOffice/MyPipeline" style={{ color: "#000" }}>
                Leads
              </Link>
            }
            className="card_virtual"
            hoverable={true}
            bodyStyle={{
              padding: "0px",
              borderTop: "1px solid #ebebeb",
              overflow: "auto",
            }}
            extra={
              <Button
                icon={<PlusOutlined style={{ color: "#3045d2" }} />}
                onClick={() => {
                  this.setState({
                    openAddContactForm: true,
                    flagPriority: "",
                  });
                }}
                className="todo_button"
                style={{ float: "right", border: "1px solid #3045d2" }}
              >
                <span className="virtual_button_text btn-color">ADD LEAD</span>
              </Button>
            }
          >
            <Table
              columns={window.screen.width > 996 ? webColumns : mobColumns}
              dataSource={totalLeads}
              rowKey={(record) => record.id}
              onScroll={true}
              locale={{
                triggerDesc: "Sort By New Date",
                triggerAsc: "Sort By Old Date",
                cancelSort: "Reset",
              }}
            />
          </Card>
        </Col>

        {openAddContactForm && (
          <AddContact
            showModal={openAddContactForm}
            proConatct={""}
            loadig={completedLoading}
            onCancel={this.handleCancel}
            // editModal={editModal}
            getUserProfile={this.getLeads}
            setLoading={this.handleLoading}
          />
        )}

        <Modal
          className="modal-black-close"
          visible={modalVisible}
          title={
            viewProperty && viewProperty.notificationType === "schedule"
              ? "Schedule a Meeting"
              : viewProperty && viewProperty.notificationType === "question"
              ? "Q & A"
              : "Buyer Interest"
          }
          width="80vw"
          style={{ marginTop: "2%" }}
          onCancel={() =>
            this.setState({
              modalVisible: false,
              viewProperty: "",
            })
          }
          footer={null}
        >
          {viewProperty && viewProperty.propertyTitle && (
            <>
              <div
                style={{
                  backgroundColor: "#f9fafb",
                  padding: "20px 20px 30px",
                }}
              >
                <Row gutter={[24, 24]}>
                  <Col span={24} style={{ padding: "20px 20px 0px 20px" }}>
                    <h4 style={{ color: "#aaa" }}>Property title :</h4>
                    <a
                      target="_blank"
                      href={`/homeDetails/${viewProperty.stateType}/${viewProperty.propertyId}`}
                      rel="noreferrer"
                    >
                      <h2
                        style={{
                          cursor: "pointer",
                          color: "#3045d2",
                          textDecoration: "underline",
                        }}
                      >
                        {viewProperty.propertyTitle}
                      </h2>
                    </a>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={24} style={{ padding: "20px" }}>
                    <h4 style={{ color: "#aaa" }}>Message :</h4>
                    <h3>{viewProperty.message}</h3>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={12} style={{ padding: "10px 10px 10px 20px" }}>
                    {viewProperty && viewProperty.requestorDetails && (
                      <Card>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Requester Name :{" "}
                          </h4>
                          <h3>
                            {viewProperty.requestorDetails.name !== "" &&
                            viewProperty.requestorDetails.name !== null &&
                            viewProperty.requestorDetails.name !== undefined
                              ? viewProperty.requestorDetails.name
                              : "N/A"}
                          </h3>
                        </Row>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Requester E-Mail :{" "}
                          </h4>
                          {viewProperty.requestorDetails.email !== "" &&
                          viewProperty.requestorDetails.email !== null &&
                          viewProperty.requestorDetails.email !== undefined ? (
                            <a
                              href={`mailto:${viewProperty.requestorDetails.email}`}
                            >
                              <h3>
                                {viewProperty.requestorDetails.email !== "" &&
                                viewProperty.requestorDetails.email !== null &&
                                viewProperty.requestorDetails.email !==
                                  undefined
                                  ? viewProperty.requestorDetails.email
                                  : "N/A"}
                              </h3>
                            </a>
                          ) : (
                            <h3>N/A</h3>
                          )}
                        </Row>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Requester Phone :{" "}
                          </h4>
                          <h3>
                            {viewProperty.requestorDetails.phone !== "" &&
                            viewProperty.requestorDetails.phone !== null &&
                            viewProperty.requestorDetails.phone !== undefined
                              ? viewProperty.requestorDetails.phone
                              : "N/A"}
                          </h3>
                        </Row>
                      </Card>
                    )}
                  </Col>
                  <Col span={12} style={{ padding: "10px 20px 10px 10px" }}>
                    {viewProperty && viewProperty.agentDetails && (
                      <Card>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Requested Date :{" "}
                          </h4>
                          <h3>
                            {moment(viewProperty.createdAt).format(
                              "MM-DD-YYYY H:M a"
                            )}
                          </h3>
                        </Row>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Listed Agent Name :{" "}
                          </h4>
                          <h3>
                            {viewProperty.agentDetails.name !== "" &&
                            viewProperty.agentDetails.name !== null &&
                            viewProperty.agentDetails.name !== undefined
                              ? viewProperty.agentDetails.name
                              : "N/A"}
                          </h3>
                        </Row>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Listed Agent E-Mail :
                          </h4>
                          <h3>
                            {viewProperty.agentDetails.email !== "" &&
                            viewProperty.agentDetails.email !== null &&
                            viewProperty.agentDetails.email !== undefined
                              ? viewProperty.agentDetails.email
                              : "N/A"}
                          </h3>
                        </Row>
                      </Card>
                    )}
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(ActiveDashboardLeads));
