import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import {
  Table,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Button,
  notification,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditTwoTone,
  EyeOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { add, list, deleteRecord, update } from "../../../api/Apis";
import { withAppContext } from "../../../store/contextStore";
import RichText from "../../../common/RichText";
import Loading from "../../../common/Loading";
import moment from "moment";

function Templates() {
  const [tempFormFields] = Form.useForm();
  const initialState = {
    templateDescription: "",
    openTemplatePopup: false,
    totalTemplatesData: [],
    templateLoading: false,
    editTemp: false,
    editRecordId: "",
  };
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const orgId = JSON.parse(localStorage.getItem("orgId"));
  const [templateDetails, setTemplateDetails] = useState(initialState);
  const [showPreivew, setShowPreivew] = useState(false);
  const [previewInfo, setPreviewInfo] = useState("");

  let webColumns = [
    {
      title: "TEMPLATE NAME",
      dataIndex: "templateName",
      key: "templateName",
      render: (templateName) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {templateName}
          </span>
        );
      },
    },

    {
      title: "SUBJECT",
      dataIndex: "templateSubject",
      key: "templateSubject",
      sorter: false,
      render: (templateSubject) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {templateSubject}
          </span>
        );
      },
    },
    {
      title: "LAST USED DATE",
      dataIndex: "templateLastUse",
      key: "templateLastUse",
      render: (templateLastUse) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {templateLastUse ? moment(templateLastUse).format("L") : ""}
          </span>
        );
      },
    },
    {
      title: "CREATED BY",
      dataIndex: "templateCreatedUser",
      key: "templateCreatedUser",
      sorter: false,
      // defaultSortOrder:"ascend",
      adminCol: true,
      render: (templateCreatedUser) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {templateCreatedUser.userFirstName +
              " " +
              templateCreatedUser.userLastName}
          </span>
        );
      },
    },
    // {
    //   title: "DESCRIPTION",
    //   dataIndex: "templateDescription",
    //   key: "templateDescription",
    //   sorter: false,
    //   // defaultSortOrder:"ascend",
    //   adminCol: true,
    //   render: (templateDescription) => {
    //     return (
    //       <div
    //         dangerouslySetInnerHTML={{
    //           __html: `${templateDescription}`,
    //         }}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: "PREVIEW",
    //   dataIndex: "templateDescription",
    //   key: "templateDescription",
    //   sorter: false,
    //   // defaultSortOrder:"ascend",
    //   adminCol: true,
    //   render: (templateDescription, record) => {
    //     return (
    //       <Button
    //         style={{
    //           border: "1px solid #3045d2",
    //           background: "#FFF",
    //           borderRadius: "5px",
    //         }}
    //         onClick={() => handleShowPreview(record)}
    //       >
    //         <span style={{ fontSize: "16px", color: "#3045d2" }}>Preview</span>
    //       </Button>
    //     );
    //   },
    // },
    {
      title: "ACTION",
      dataIndex: "id",
      key: "id",
      sorter: false,
      className: "col_styling",
      render: (id, record) => {
        return (
          <Row>
            <span>
              <EyeOutlined
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "18px",
                }}
                onClick={() => handleShowPreview(record)}
              />
            </span>
            {userDetails?.userProfile === "A" &&
            record.userId === userDetails?.id &&
            record.showTemplate === true ? (
              <Tooltip placement="topLeft" title="Shared With All Agents.">
                <ShareAltOutlined
                  style={{
                    fontSize: "20px",
                    marginRight: "18px",
                    color: "#1890ff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleToShareTemplate(id, false)}
                />
              </Tooltip>
            ) : (
              userDetails?.userProfile === "A" &&
              record.userId === userDetails?.id && (
                <span>
                  <Tooltip placement="topLeft" title="Sharing Disabled.">
                    <ShareAltOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "18px",
                        color: "#858181",
                        cursor: "pointer",
                      }}
                      onClick={() => handleToShareTemplate(id, true)}
                    />
                  </Tooltip>
                </span>
              )
            )}
            {record.userId === userDetails?.id && (
              <>
                <span>
                  <EditTwoTone
                    style={{ fontSize: "20px" }}
                    onClick={() => handleToEditTemplate(record)}
                  />
                </span>
                <span>
                  <DeleteOutlined
                    style={{
                      fontSize: "18px",
                      color: "red",
                      marginLeft: "18px",
                    }}
                    onClick={() => handleToRemoveTemp(id)}
                  />
                </span>
              </>
            )}
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    handleTogetTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTogetTemplates = () => {
    setTemplateDetails((st) => ({
      ...st,
      templateLoading: true,
      editTemp: false,
      openTemplatePopup: false,
    }));

    let query = {};

    // if (userDetails?.userProfile === "A") {
    query["orgId"] = orgId;
    // } else {
    //   query["userId"] = userDetails.id;
    // }
    query = {
      ...query,
      typeOfData: "templates",
    };

    list("virtualOfficeResources", query).then((res) => {
      if (res.success) {
        let templateData = [];
        if (userDetails?.userProfile === "A") {
          templateData = res.data;
        } else {
          templateData = res.data.filter(
            (e) => e.userId === userDetails?.id || e.showTemplate === true
          );
        }
        setTemplateDetails((st) => ({
          ...st,
          totalTemplatesData: templateData,
          templateLoading: false,
          editTemp: false,
          openTemplatePopup: false,
        }));
      }
    });
  };

  const handleToSubmitNewTemplate = (values) => {
    setTemplateDetails({ ...templateDetails, templateLoading: true });

    values["templateDescription"] = templateDetails.templateDescription;

    values["templateCreatedUser"] = {
      userFirstName: userDetails.firstName,
      userLastName: userDetails.lastName,
      userEmail: userDetails.email,
      userId: userDetails.id,
    };
    values["sharedDetails"] = [];
    values["templateLastUse"] = "";
    values["typeOfData"] = "templates";
    values["userId"] = userDetails.id;
    values["orgId"] = orgId;
    let payLoad = {
      entityData: values,
    };
    try {
      add("virtualOfficeResources", payLoad).then((tempRes) => {
        if (tempRes.success) {
          setTemplateDetails({ ...templateDetails, templateLoading: false });
          handleTogetTemplates();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleToUpdateTemplate = (updatePayload) => {
    setTemplateDetails({ ...templateDetails, templateLoading: true });
    updatePayload["userId"] = userDetails.id;
    updatePayload["orgId"] = orgId;
    updatePayload["templateDescription"] = templateDetails.templateDescription;

    let payLoad = {
      entityData: updatePayload,
    };

    if (templateDetails.editRecordId !== "") {
      update(
        "virtualOfficeResources",
        templateDetails.editRecordId,
        payLoad
      ).then((updateRes) => {
        if (updateRes.success) {
          setTemplateDetails({
            ...templateDetails,
            editTemp: false,
            editRecordId: "",
            templateLoading: false,
            openTemplatePopup: false,
          });
          handleTogetTemplates();
        }
      });
    }
  };

  const handleRichtextBio = (data) => {
    setTemplateDetails({ ...templateDetails, templateDescription: data });
  };

  const handleToRemoveTemp = (id) => {
    setTemplateDetails({
      ...templateDetails,
      templateLoading: true,
    });
    deleteRecord("virtualOfficeResources", id)
      .then((succesResult) => {
        if (succesResult.success) {
          setTemplateDetails({
            ...templateDetails,
            templateLoading: false,
          });
          handleTogetTemplates();
          notification["success"]({
            message: "Templated deleted successfully.",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleToEditTemplate = (payLoad) => {
    setTemplateDetails({
      ...templateDetails,
      openTemplatePopup: true,
      templateDescription: payLoad.templateDescription,
      editTemp: true,
      editRecordId: payLoad.id,
    });
    setPreviewInfo("");

    tempFormFields.setFieldsValue({
      templateName: payLoad["templateName"],
      templateSubject: payLoad["templateSubject"],
      templateDescription: payLoad["templateDescription"],
    });
  };

  const handleToShareTemplate = (id, val) => {
    setTemplateDetails({
      ...templateDetails,
      templateLoading: true,
    });

    let data = {
      entityData: {
        showTemplate: val,
      },
    };
    update("virtualOfficeResources", id, data)
      .then((succesResult) => {
        if (succesResult.success) {
          setTemplateDetails({
            ...templateDetails,
            templateLoading: false,
          });
          handleTogetTemplates();
          if (val === true) {
            notification["success"]({
              message: "Shared With All Agents.",
            });
          } else {
            notification["success"]({
              message: "Sharing Disabled.",
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const createNewTemp = () => {
    setTemplateDetails({
      ...templateDetails,
      openTemplatePopup: true,
      templateDescription: "",
    });
    setPreviewInfo("");
    tempFormFields.setFieldsValue({
      templateName: "",
      templateSubject: "",
      templateDescription: "",
    });
  };

  const handleShowPreview = (record) => {
    setShowPreivew(true);
    setPreviewInfo(record);
  };

  const addNewTemplate = () => {
    return (
      <div>
        <Col span={24} style={{ padding: "16px" }}>
          <Form
            initialValues={{ remember: true }}
            onFinish={
              templateDetails.editTemp
                ? handleToUpdateTemplate
                : handleToSubmitNewTemplate
            }
            id="newTemplated"
            form={tempFormFields}
          >
            <div className="label-titles">Template Name</div>
            <Form.Item
              name="templateName"
              rules={[
                {
                  required: true,
                  message: "Please input Template Name",
                },
              ]}
            >
              <Input
                placeholder="Template Name"
                className="form-input-training"
                // value={sellerName}
              />
            </Form.Item>
            <div className="label-titles">Subject</div>

            <Form.Item
              name="templateSubject"
              rules={[
                {
                  required: true,
                  message: "Please input Subject",
                },
              ]}
            >
              <Input
                placeholder="Subject"
                className="form-input-training"
                // value={sellerName}
              />
            </Form.Item>
            <div className="label-titles">Description</div>

            <Form.Item
              name="templateDescription"
              rules={[
                {
                  required:
                    templateDetails.templateDescription !== "" ? false : true,
                  message: "Please input description !",
                },
              ]}
            >
              <RichText
                handleRichtextBio={handleRichtextBio}
                richtTextBiofieldProp={templateDetails.templateDescription}
              />
            </Form.Item>
          </Form>
        </Col>
      </div>
    );
  };

  const showTemplate = (data) => {
    return (
      <div>
        <Col span={24} style={{ padding: "16px" }}>
          <div className="label-titles">Template Name</div>

          <div className="label-description">{data?.templateName}</div>

          <div className="label-titles">Subject</div>
          <div className="label-description">{data?.templateSubject}</div>

          <div className="label-titles">Description</div>
          <div
            className="label-description"
            dangerouslySetInnerHTML={{
              __html: `${data?.templateDescription}`,
            }}
          />
        </Col>
      </div>
    );
  };

  console.log(showPreivew, "showPreivew");
  return (
    <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
      <Loading enableLoading={templateDetails.templateLoading} />
      <Col
        xl={{ span: 24, offset: 0 }}
        lg={{ span: 24, offset: 0 }}
        xs={{ span: 22, offset: 1 }}
        sm={{ span: 22, offset: 1 }}
        className="mobile_view_margin_Top"
      >
        <Card
          title={
            <Link to="/VirtualOffice/emailTemplates" style={{ color: "#000" }}>
              Email Templates
            </Link>
          }
          className="card_virtual"
          bodyStyle={{
            padding: "0px",
            borderTop: "1px solid #ebebeb",
            overflow: "auto",
          }}
          extra={
            <Button
              icon={<PlusOutlined style={{ color: "#3045d2" }} />}
              onClick={createNewTemp}
              className="todo_button"
              style={{ float: "right", border: "1px solid #3045d2" }}
            >
              <span className="virtual_button_text btn-color">
                ADD Template{" "}
              </span>
            </Button>
          }
        >
          <Table
            columns={webColumns}
            dataSource={templateDetails.totalTemplatesData}
            rowKey={(record) => record.id}
            onScroll={true}
          />
        </Card>
      </Col>

      {(templateDetails.openTemplatePopup || showPreivew) && (
        <Modal
          className="modal-black-close"
          visible={templateDetails.openTemplatePopup || showPreivew}
          title="Email Template"
          onCancel={() => {
            setTemplateDetails({
              ...templateDetails,
              openTemplatePopup: false,
            });
            setShowPreivew(false);
          }}
          destroyOnClose={true}
          footer={
            previewInfo
              ? ""
              : [
                  <Button
                    key="back"
                    onClick={() => {
                      setTemplateDetails({
                        ...templateDetails,
                        openTemplatePopup: false,
                        editTemp: false,
                      });
                      setShowPreivew(false);
                    }}
                    htmlType="reset"
                  >
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form="newTemplated"
                    loading={templateDetails.templateLoading}
                  >
                    {templateDetails.editTemp ? "Update" : "Add"}
                  </Button>,
                ]
          }
          width={"40%"}
        >
          {showPreivew ? showTemplate(previewInfo) : addNewTemplate()}
        </Modal>
      )}
    </div>
  );
}

export default withAppContext(withRouter(Templates));
