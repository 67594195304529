import React, { Component } from "react";
import {
  Row,
  Col,
  Collapse,
  Popconfirm,
  notification,
  Tabs,
  Button,
  Card,
  Upload,
  Modal,
  Spin,
  Image,
} from "antd";
import { withRouter } from "react-router";
import Carousel from "react-elastic-carousel";
import "antd/dist/antd.css";
import { DeleteOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import uploadImg from "../images/upload.png";
import VendorPortFolio from "../containers/Modules/Myprofile/VendorPortFolio";
import { deleteRecord, noAuthlist, update, add } from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import addImage from "../images/addImage.png";
import DataNotFound from "./DataNotFound";

const { Panel } = Collapse;
const { Dragger } = Upload;
const { TabPane } = Tabs;

class PortfoliosList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioList: [],
      userLogin: false,
      userDetails: "",
      enableAddPortfolio: false,
      uploadImageTrue: false,
      imageLoading: false,
      selectedImageList: [],
      saveImages: false,
      showUploadButtonForImages: false,
      removeblePortfolioImages: [],
      actualPortfolioImages: 0,
      portfolioImagesCount: 0,
      fileList: [],
      addPortfolioForAgent: false,
      updatingPortFolioImage: false,

      dataLoading: false,
    };
  }

  componentDidMount() {
    this.checkUserLogin();
    this.getPortfolioList();
    this.setState({
      dataLoading: true,
    });
  }

  checkUserLogin = async () => {
    let userLogin = await localStorage.getItem("userLogin");
    let userLoginDetails = await JSON.parse(
      localStorage.getItem("userDetails")
    );
    this.setState({
      userLogin: userLogin,
      userDetails: userLoginDetails,
    });
  };

  getPortfolioList = async () => {
    const { sharingUserParamsId } = this.props;
    if (
      this.props &&
      this.props.portfolioList &&
      (window.location.pathname ===
        `/lenders/viewProfile/${this.props.match.params.id}` ||
        window.location.pathname ===
          `/marketplace/viewProfile/${this.props.match.params.id}` ||
        window.location.pathname === "/marketplace" ||
        window.location.pathname === "/lenders" ||
        this.props.agentLenders)
    ) {
      this.setState({
        portfolioList: this.props.portfolioList,
        dataLoading: false,
      });
    } else {
      let portFolioUserId = sharingUserParamsId
        ? sharingUserParamsId
        : this.props.match.params.id;
      try {
        noAuthlist(
          "testimonials?userId=" + portFolioUserId + "&dataType=portfolio"
        )
          .then((res) => {
            this.setPortfolioListing(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        alert("Something went wrong...");
      }
    }
  };
  setPortfolioListing = (portfolioList) => {
    this.setState({
      portfolioList,
      dataLoading: false,
    });
  };
  /********* CONFIRM TO DELETE PORTFOLIO ********/
  confirm = async (id) => {
    try {
      const response = await deleteRecord("testimonials", id);
      if (response.success) {
        this.getPortfolioList();
        notification["success"]({
          message: "Your record deleted successfully",
        });
      } else {
        notification["error"]({
          message: response.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  cancel = (e) => {
    console.log(e);
  };
  /*********** PERMISSION TO OPEN ADD IMAGE MODAL **************/
  addImage = (selectedImageList, numberOfImg, updatimgImage) => {
    this.setState({
      uploadImageTrue: true,
      selectedImageList,
      showUploadButtonForImages: false,
      actualPortfolioImages: numberOfImg,
      portfolioImagesCount: numberOfImg,
      fileList: [],
    });
  };

  closeUploadModal = () => {
    this.setState({
      uploadImageTrue: false,
    });
  };

  openNotification = () => {
    notification["error"]({
      message:
        "You can't add more than 4 images... Please remove image to upload",
    });
  };
  /*********** CLICK TO UPLOAD IMAGE *******************/
  uploadHomeImages = async (info) => {
    if (
      this.state.selectedImageList &&
      info.fileList.length &&
      this.state.selectedImageList.portfolioImages &&
      this.state.portfolioImagesCount + info.fileList.length > 4 &&
      this.props.portFolioTabDisabled === "open"
    ) {
      notification["error"]({
        message:
          "You can't add more than 4 images... Please remove image to upload",
      });
      return;
    }
    this.setState({
      imageLoading: true,
    });
    let Images = [];
    if (info.fileList.length > 0 && info.file.status !== "removed") {
      const fileList = [];
      fileList.push(info);
      this.setState({
        fileList: info.fileList,
      });
      info.fileList.map((file) => {
        if (file.type) {
          try {
            Storage.put(file.uid, file.originFileObj, {
              contentType: file.type,
            }).then((result) => {
              Images.push(result.key);
              let newImagesList = this.state.selectedImageList.portfolioImages;
              if (!newImagesList.includes(result.key)) {
                newImagesList.push(result.key);
              }
              this.setState({
                imageKeys: Images,
                imageLoading: false,
                showUploadButtonForImages: true,
                selectedImageList: {
                  ...this.state.selectedImageList,
                  portfolioImages: newImagesList,
                },
              });
            });
          } catch (err) {
            console.log(err, "errr");
          }
        }
        return true;
      });
    } else {
      this.setState({
        imageLoading: false,
      });
    }
    if (info.file.status === "removed") {
      const newPortFolioImages =
        this.state.selectedImageList.portfolioImages.filter(
          (removeId) => removeId !== info.file.uid
        );
      this.setState({
        showUploadButtonForImages: true,
        imageLoading: false,
        fileList: info.fileList,
        selectedImageList: {
          ...this.state.selectedImageList,
          portfolioImages: newPortFolioImages,
        },
      });
    }
  };
  /********** CLICK TO UPLOAD IMAGE AT AGENT LOGIN FOR NEW AGENT ***************/
  handleAgentPortfolioImages = (info) => {
    let agentPortfolioUserid = JSON.parse(localStorage.getItem("userDetails"));
    let Images = [];
    this.setState({
      imageLoading: true,
    });
    if (info.fileList.length > 0 && info.file.status !== "removed") {
      const fileList = [];
      fileList.push(info);
      this.setState({
        fileList: info.fileList,
      });
      info.fileList.map((file) => {
        if (file.type) {
          try {
            Storage.put(file.uid, file.originFileObj, {
              contentType: file.type,
            }).then((result) => {
              Images.push(result.key);
              let newImagesList = this.state.selectedImageList
                ? this.state.selectedImageList.portfolioImages &&
                  this.state.selectedImageList.portfolioImages
                : [];
              if (!newImagesList.includes(result.key)) {
                newImagesList.push(result.key);
              }
              const selectedImageList = {};
              selectedImageList["userId"] = agentPortfolioUserid.id;
              selectedImageList["portfolioName"] = "My portfolio";
              selectedImageList["portfolioImages"] = newImagesList;
              selectedImageList["postedBy"] = agentPortfolioUserid.firstName;
              this.setState({
                imageKeys: Images,
                imageLoading: false,
                showUploadButtonForImages: true,
                selectedImageList,
              });
            });
          } catch (err) {
            console.log(err, "errr");
          }
        }
        return true;
      });
    } else {
      this.setState({
        imageLoading: false,
      });
    }
    if (info.file.status === "removed") {
      const newPortFolioImages =
        this.state.selectedImageList.portfolioImages.filter(
          (removeId) => removeId !== info.file.uid
        );
      this.setState({
        showUploadButtonForImages: true,
        imageLoading: false,
        fileList: info.fileList,
        selectedImageList: {
          ...this.state.selectedImageList,
          portfolioImages: newPortFolioImages,
        },
      });
    }
  };
  /********** NEW AGENT PORTFOLIO CREATION ***************/
  handleToCraeteAgentPortfolio = async () => {
    this.setState({
      saveImages: true,
    });
    let orgId = JSON.parse(localStorage.getItem("orgId"))
    const { selectedImageList } = this.state;
    const data = {
      entityData: {
        userId: selectedImageList.userId,
        portfolioName: selectedImageList.portfolioName,
        portfolioImages: selectedImageList.portfolioImages,
        dataType: "portfolio",
        testimonialDesc: "Desc",
        postedBy: selectedImageList.postedBy,
        rating: 1,
        orgId
      },
    };

    try {
      const response = await add("testimonials", data);
      if (response.success) {
        notification["success"]({
          message: "Your portfolio has been updated",
        });
        this.setState({
          uploadImageTrue: false,
          saveImages: false,
        });
        this.getPortfolioList();
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };
  /********** PORTFOLIO CREATION FOR OTHER USERS ***************/
  handleToUploadPortfolio = async (deleteItem) => {
    const { selectedImageList } = this.state;
    await this.setState({
      saveImages: true,
    });
    const data = {
      entityData: {
        userId: selectedImageList.userId,
        portfolioName: selectedImageList.portfolioName,
        portfolioImages: selectedImageList.portfolioImages,
        dataType: "portfolio",
        testimonialDesc: "Desc",
        postedBy: selectedImageList.postedBy,
        rating: 1,
      },
    };
    try {
      const response = await update("testimonials", selectedImageList.id, data);
      if (response.success) {
        if (deleteItem === "deleteItem") {
          notification["success"]({
            message: "Your image deleted successfully",
          });
        } else {
          notification["success"]({
            message: "Your image added successfully",
          });
        }
        this.getPortfolioList();

        this.setState({
          uploadImageTrue: false,
          saveImages: false,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  uploadportfolioImage = () => {
    const { fileList, portfolioImagesCount } = this.state;
    return (
      <Modal
        visible={this.state.uploadImageTrue}
        title="ADD IMAGE"
        width={window.screen.width >= "768" ? "45vw" : "90vw"}
        style={{ marginTop: "2%" }}
        onCancel={this.closeUploadModal}
        className="buyer-agent-modal modal-black-close"
        footer={null}
      >
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{ padding: "10px", marginTop: "20px" }}
          >
            <Spin spinning={this.state.imageLoading}>
              <Dragger
                name="file"
                onChange={(info) =>
                  !this.state.addPortfolioForAgent
                    ? this.uploadHomeImages(info)
                    : this.handleAgentPortfolioImages(info)
                }
                listType="picture"
                beforeUpload={() => false}
                maxCount={this.props.portFolioTabDisabled === "open" && 5}
                fileList={fileList && fileList}
              >
                <>
                  <p className="ant-upload-drag-icon">
                    <img
                      src={uploadImg}
                      alt="upload"
                      width="50px"
                      height="33px"
                    />
                  </p>
                  <p className="ant-upload-text">Drag & Drop image</p>
                </>
              </Dragger>
            </Spin>
          </Col>
          <Col
            xl={{ span: 12, offset: 9 }}
            lg={{ span: 12, offset: 9 }}
            md={{ span: 12, offset: 9 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            {this.state.showUploadButtonForImages && (
              <Spin spinning={this.state.saveImages}>
                {this.state.fileList && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="update-btn"
                    onClick={
                      !this.state.addPortfolioForAgent
                        ? this.handleToUploadPortfolio
                        : this.handleToCraeteAgentPortfolio
                    }
                  >
                    {portfolioImagesCount > 0
                      ? "Save Changes"
                      : "Upload images"}
                  </Button>
                )}
              </Spin>
            )}
          </Col>
        </Row>
      </Modal>
    );
  };
  removeImage = async (removeId, removeblePortfolioImages) => {
    const newImagesList = removeblePortfolioImages.portfolioImages.filter(
      (removeItem) => removeItem !== removeId
    );
    removeblePortfolioImages.portfolioImages = newImagesList;
    const selectedImageList = removeblePortfolioImages;
    await this.setState(
      {
        selectedImageList,
      },
      () => this.handleToUploadPortfolio("deleteItem")
    );
  };

  showAgentPortfolioImages = (portfolio) => {
    const { userLogin, userDetails } = this.state;
    return (
      <div>
        <Image.PreviewGroup>
          <Row
            gutter={[16, 16]}
            style={{
              background: "#F9FAFF",
            }}
          >
            {userLogin && userDetails.id === portfolio.userId && portfolio && (
              <Col>
                <div
                  onClick={() => {
                    this.setState({
                      addPortfolioForAgent: false,
                    });
                    this.addImage(portfolio, portfolio.portfolioImages.length);
                  }}
                  style={{
                    cursor: "pointer",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <img
                    src={addImage}
                    alt="add-homes"
                    className="marketplace-homes"
                  />
                </div>
              </Col>
            )}

            {portfolio &&
              portfolio.portfolioImages &&
              portfolio.portfolioImages.length > 0 &&
              portfolio.portfolioImages.map((portfolioImage, index) => (
                <Col
                // xl={{ span: 8 }}
                // lg={{ span: 8 }}
                // md={{ span: 8 }}
                // sm={{ span: 12 }}
                // xs={{ span: 24 }}
                >
                  <Col>
                    <div style={{ position: "relative" }}>
                      {userDetails &&
                        portfolio &&
                        userDetails.id === portfolio.userId && (
                          <Popconfirm
                            title="Are you sure to remove this image?"
                            onConfirm={() =>
                              this.removeImage(portfolioImage, portfolio)
                            }
                            onCancel={() => this.cancel(portfolio.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div
                              style={{
                                position: "absolute",
                                right: 0,
                                margin: "5px",
                                zIndex: 1,
                              }}
                            >
                              <Button
                                type="link"
                                shape="circle"
                                icon={
                                  <CloseSquareOutlined
                                    style={{
                                      color: "red",
                                      fontSize: "20px",
                                      padding: "2px",
                                    }}
                                  />
                                }
                              />
                            </div>
                          </Popconfirm>
                        )}

                      <div>
                        <Image
                          src={
                            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                            portfolioImage
                          }
                          alt={portfolio.portfolioName + index}
                          className="marketplace-homes"
                          style={{ backgroundColor: "#d4d8f1fa" }}
                        />
                      </div>
                    </div>
                  </Col>
                </Col>
              ))}
          </Row>
        </Image.PreviewGroup>
        {this.state.uploadImageTrue && this.uploadportfolioImage()}
      </div>
    );
  };

  showPortfolioImages = (portfolio) => {
    const { userLogin, userDetails } = this.state;
    let enableRemove =
      userLogin && userDetails.id === portfolio.userId ? true : false;
    return portfolio &&
      portfolio.portfolioImages &&
      portfolio.portfolioImages.length &&
      portfolio.portfolioImages.length > 0 ? (
      <div
        className={
          this.props.portFolioTabDisabled === "portFolioTabDisabled"
            ? "portfolio-carousel"
            : "marketplace-carousel"
        }
      >
        <Carousel
          enableSwipe={true}
          enableMouseSwipe={true}
          enableAutoPlay
          // autoPlaySpeed={2000}
          itemsToShow={
            window.screen.width >= "993"
              ? this.props.portFolioTabDisabled === "portFolioTabDisabled"
                ? 3
                : 4
              : window.screen.width > "501" && window.screen.width <= "992"
              ? 2
              : 1
          }
        >
          {userLogin &&
            userDetails &&
            userDetails.id === portfolio.userId &&
            window.location.pathname !== `/lenders` &&
            window.location.pathname !== `/marketplace` &&
            portfolio &&
            portfolio.portfolioImages &&
            portfolio.portfolioImages.length &&
            portfolio.portfolioImages.length <= 3 && (
              <Col>
                <div
                  onClick={() => {
                    this.addImage(
                      portfolio,
                      portfolio.portfolioImages.length,
                      "updatingImage"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={addImage}
                    alt="add-homes"
                    className="marketplace-homes"
                    style={{ backgroundColor: "#d4d8f1fa" }}
                  />
                </div>
              </Col>
            )}

          {portfolio &&
            portfolio.portfolioImages &&
            portfolio.portfolioImages.length &&
            portfolio.portfolioImages.length > 0 &&
            portfolio.portfolioImages.map((portfolioImage, index) => (
              <Col>
                <div style={{ position: "relative" }}>
                  {window.location.pathname !== `/lenders` &&
                    window.location.pathname !== `/marketplace` &&
                    enableRemove && (
                      <Popconfirm
                        title="Are you sure to remove this image?"
                        onConfirm={() =>
                          this.removeImage(portfolioImage, portfolio)
                        }
                        onCancel={() => this.cancel(portfolio.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div
                          style={{
                            position: "absolute",
                            right: 0,
                            margin: "5px",
                          }}
                        >
                          <Button
                            type="link"
                            shape="circle"
                            icon={
                              <CloseSquareOutlined
                                style={{
                                  color: "red",
                                  fontSize: "20px",
                                  padding: "2px",
                                  backgroundColor: "#33333380",
                                }}
                              />
                            }
                          />
                        </div>
                      </Popconfirm>
                    )}

                  <div>
                    <img
                      src={
                        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                        portfolioImage
                      }
                      alt={portfolio.portfolioName + index}
                      className="marketplace-homes"
                      style={{ backgroundColor: "#d4d8f1fa" }}
                    />
                  </div>
                </div>
              </Col>
            ))}
        </Carousel>
        {this.state.uploadImageTrue && this.uploadportfolioImage()}
      </div>
    ) : (
      <div>
        <Col>
          {(window.location.pathname ===
            `/lenders/viewProfile/${this.props.match.params.id}` ||
            window.location.pathname ===
              `/marketplace/viewProfile/${this.props.match.params.id}` ||
            window.location.pathname === "/myProfile" ||
            window.location.pathname ===
              `/viewProfile/${this.props.match.params.id}`) &&
          enableRemove ? (
            <div
              onClick={() => {
                this.addImage(portfolio, portfolio.portfolioImages.length);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                src={addImage}
                alt="add-homes"
                className="marketplace-homes"
                style={{ backgroundColor: "#d4d8f1fa" }}
              />
            </div>
          ) : (
            <div style={{ cursor: "pointer", padding: "20px" }}>
              {/* <img
                src={defaultImage}
                alt="add-homes"
                className="marketplace-homes"
                style={{ backgroundColor: "#d4d8f1fa" }}
              /> */}
            </div>
          )}
        </Col>
        {this.state.uploadImageTrue && this.uploadportfolioImage()}
      </div>
    );
  };

  closePortfolioModal = () => {
    this.setState({
      enableAddPortfolio: false,
    });
  };

  render() {
    const {
      portfolioList,
      userLogin,
      userDetails,
      dataLoading,
      enableAddPortfolio,
    } = this.state;

    return window.location.pathname === "/marketplace" ||
      window.location.pathname === "/lenders" ||
      this.props.agentLenders ? (
      <Tabs
        animated
        useOnPan
        swipeable
        size="small"
        type="card"
        tabPosition="top"
        defaultActiveKey="1"
      >
        {portfolioList &&
          portfolioList.map((portfolio, index) => (
            <TabPane tab={portfolio && portfolio.portfolioName} key={index}>
              {this.showPortfolioImages(portfolio)}
            </TabPane>
          ))}
      </Tabs>
    ) : (
      <>
        <Card
          bordered={false}
          title={
            this.props.portFolioTabDisabled === "open"
              ? portfolioList.length + " Portfolio(s)"
              : "Portfolio"
          }
          extra={
            <Col
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.setState({
                  enableAddPortfolio: true,
                })
              }
            ></Col>
          }
          style={{ paddingLeft: "0px" }}
        >
          <Spin spinning={dataLoading}>
            <Collapse accordion bordered={false} defaultActiveKey={["0"]}>
              {portfolioList &&
                portfolioList.map((portfolio, index) => (
                  <Panel
                    header={
                      this.props.portFolioTabDisabled === "open" &&
                      portfolio.portfolioName
                    }
                    key={index}
                    extra={
                      (window.location.pathname ===
                        `/lenders/viewProfile/${this.props.match.params.id}` ||
                        window.location.pathname ===
                          `/marketplace/viewProfile/${this.props.match.params.id}`) &&
                      userLogin &&
                      userDetails.id === portfolio.userId ? (
                        <Popconfirm
                          title="Are you sure to delete this record?"
                          onConfirm={() => this.confirm(portfolio.id)}
                          onCancel={() => this.cancel(portfolio.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined style={{ color: "red" }} />
                        </Popconfirm>
                      ) : null
                    }
                    className={
                      this.props.portFolioTabDisabled === "open"
                        ? ""
                        : "agent-portfolio-disabled"
                    }
                  >
                    {(this.props.portFolioTabDisabled ===
                      "portFolioTabDisabled" ||
                      window.location.pathname ===
                        `/agent/viewProfile/${this.props.match.params.id}`) &&
                    !this.props.agentLenders
                      ? this.showAgentPortfolioImages(portfolio)
                      : this.showPortfolioImages(portfolio)}
                  </Panel>
                ))}
            </Collapse>

            {portfolioList &&
              portfolioList.length === 0 &&
              userLogin &&
              userDetails.id !== this.props.match.params.id && (
                <DataNotFound type="portFolio" title="No portfolios as yet." />
              )}
            {portfolioList && portfolioList.length === 0 && !userLogin && (
              <DataNotFound type="portFolio" title="No portfolios as yet...." />
            )}
          </Spin>
          {portfolioList &&
            portfolioList.length === 0 &&
            userLogin &&
            window.location.pathname ===
              `/viewProfile/${this.props.match.params.id}` &&
            userDetails.id === this.props.match.params.id &&
            userDetails.userProfile === "AG" && (
              <Col>
                <div
                  onClick={() => {
                    this.setState({
                      addPortfolioForAgent: true,
                    });
                    this.addImage();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={addImage}
                    alt="add-homes"
                    className="marketplace-homes"
                    style={{ backgroundColor: "#d4d8f1fa" }}
                  />
                </div>
                {this.state.uploadImageTrue && this.uploadportfolioImage()}
              </Col>
            )}
        </Card>
        <Modal
          visible={enableAddPortfolio}
          title="ADD PORTFOLIO"
          width={window.screen.width >= "768" ? "45vw" : "90vw"}
          style={{ marginTop: "2%" }}
          onCancel={this.closePortfolioModal}
          className="buyer-agent-modal modal-black-close "
          // className="modal-black-close"
          footer={null}
        >
          <Row>
            <Col span={24} style={{ padding: "10px", marginTop: "20px" }}>
              <VendorPortFolio
                // fileList={fileList}
                // profileImage={profileImage}
                userDetails={userDetails}
                portfolioList={portfolioList}
                getPortfoliosList={this.getPortfolioList}
                closePortfolioModal={this.closePortfolioModal}
                emptyInitialValue="Select Category"
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}
// const NewPortfolioList = withAppContext(withRouter(PortfoliosList))
// export default NewPortfolioList
export default withAppContext(withRouter(PortfoliosList));
