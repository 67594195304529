import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";
import Advertisement from "./Advertisement";
import { withAppContext } from "../store/contextStore";
class AdditionalContent extends Component {
  render() {
    const { additionalData } = this.props.context.state.websiteThemes;
    return (
      <>
        {additionalData && (
          <div>
            <Row>
              <Col span="24" style={{ background: "white" }}>
                <div style={{ width: "100%", padding: "0 3% 0 3%" }}>
                  <Advertisement
                    otherAds={false}
                    adType="Rectangle"
                    adWhereToShow="Footer"
                    adLimit="1"
                    zipCode=""
                    showMatchesPage={true}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ background: "white" }}>
              {additionalData.map((data) => (
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ padding: "3%" }}
                >
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={20} xs={20}>
                      <Row>
                        <Col
                          xl={{ span: 20, offset: 4 }}
                          lg={{ span: 20, offset: 4 }}
                          md={{ span: 20, offset: 4 }}
                          sm={{ span: 23, offset: 1 }}
                          xs={{ span: 23, offset: 1 }}
                        >
                          <span className="font-family-style font-medium-size  question-title">
                            {data.title}
                          </span>
                        </Col>
                        <Col
                          xl={{ span: 20, offset: 4 }}
                          lg={{ span: 20, offset: 4 }}
                          md={{ span: 20, offset: 4 }}
                          sm={{ span: 23, offset: 1 }}
                          xs={{ span: 23, offset: 1 }}
                        >
                          <span className="font-family-style font-normal-size  answer-title">
                            {data.description}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xl={{ span: 8, offset: 4 }}
                      lg={{ span: 8, offset: 4 }}
                      md={{ span: 8, offset: 4 }}
                      sm={{ span: 4, offset: 0 }}
                      xs={{ span: 4, offset: 0 }}
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                        }}
                        to={{
                          pathname: `/register`,
                        }}
                      >
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          size="large"
                        />
                      </Link>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </>
    );
  }
}

export default withAppContext(withRouter(AdditionalContent));
