import { Col, Row, Form, Input, notification, Button } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Auth } from "aws-amplify";
import { withAppContext } from "../../../store/contextStore";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // privateDetails: {
      sellerName: "",
      sellerPhone: "",
      sellerAddress: "",
      citySearch: [],
      // }
    };
  }
  handleSellereProfile = async (name, value) => {
    await this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onFinish = (e) => {
    this.updatePassword(e);
  };
  handleSingleSearch = (values) => {
    let address = values.label.split(",");
    const citySearch = address;
    this.setState({ citySearch });
  };
  componentDidMount() {}
  updatePassword = (e) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, e.oldPassword, e.newPassword);
      })
      .then((data) => (
        <>
          {data === "SUCCESS" &&
            notification["success"]({
              message: "Your password updated",
              description: "Your password updated successfully",
            })}
        </>
      ))
      .catch((err) => console.log(err));
  };
  render() {
    const { sellerHomeDetails } = this.props.context.state;

    return (
      <div>
        <Form
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          size="large"
          id="form"
        >
          <Row>
            <Col
              xl={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              md={{ span: 11, offset: 1 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="label-titles">Old Password</div>
              <Form.Item
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your Old Password!",
                  },
                ]}
              >
                <Input.Password
                  className="form-input"
                  placeholder="Enter your old Password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 11, offset: 1 }}
              lg={{ span: 11, offset: 1 }}
              md={{ span: 11, offset: 1 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="label-titles">New Password</div>
              <Form.Item
                name="newPassword"
                initialValue={
                  sellerHomeDetails &&
                  sellerHomeDetails.privateDetails.sellerName
                }
              >
                <Input.Password
                  className="form-input"
                  placeholder="Enter your new password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 8, offset: 1 }}
              lg={{ span: 8, offset: 1 }}
              md={{ span: 8, offset: 1 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="update-btn btn-success"
              >
                Update Password
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
export default withAppContext(withRouter(ChangePassword));
