import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "antd/dist/antd.css";
import { Card, Row, Col, Divider, Space, Button, Drawer, Skeleton } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Layout from "antd/lib/layout/layout";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import { withAppContext } from "../../store/contextStore";

import { noAuthadd, noAuthgetDetails } from "../../api/Apis";
import SendMessage from "../../common/SendMessage";
import Advertisement from "../../common/Advertisement";

import Loading from "../../common/Loading";
import bedIcon from "../../images/awesome-bed-icon.png";
import profilePic from "../../images/profileAvatar.png";
import bathIcon from "../../images/awesome-bath-icon.png";
import reSize from "../../images/sq-feet-icon.png";
import SignupForm from "../../containers/RegisterForm";
import LoginForm from "../../containers/LoginForm";
export class BuyerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSignupForm: false,

      /**** HomeSearchDetails2*****/
      buyerHomeList: "",
      userProfile: "",
      singleData: {},
      isPopup: false,
      beforeLoginLoading: false,
      setPropertyDetails: false,
    };
  }

  componentDidMount = () => {
    this.init();
  };

  init = () => {
    this.setState({
      beforeLoginLoading: true,
    });

    this.getUserDetails(this.props.match.params.id);
    this.getBuyerHomeDetails();
    this.getIpAddress();
  };

  getIpAddress = () => {
    axios
      .get(process.env.REACT_APP_IPADDRESS)
      .then((result) => {
        this.addIpAddress(result.data);
      })
      .catch((error) => console.log("Error :", error));
  };

  addIpAddress = async (data) => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    data["orgId"] = orgId
    const payload = {
      entityData: data,
    };
    try {
      const response = await noAuthadd("ipAddressAnalytics", payload);
      console.log(response);
    } catch (error) {
      console.log("Error :", error);
    }
  };

  getUserDetails = async (id) => {
    const UserInfo = await noAuthgetDetails("getAgents", id);
    if (UserInfo.success) {
      this.setState({
        userProfile: UserInfo.data,
      });
    }
  };

  getBuyerHomeDetails = async () => {
    const id = this.props.match.params.id;
    const buyerHomeList = [];
    const newApi = await noAuthgetDetails("getBuyers", id);
    buyerHomeList.push({ ...newApi.data });

    const data = [];
    buyerHomeList &&
      buyerHomeList.map(
        (e) =>
          e.buyerPropertyFeatures &&
          e.buyerPropertyDetails &&
          data.push({
            homeId: e.id,
            userId: e.userId,
            title:
              (e &&
                e.buyerPropertyDetails2 &&
                e.buyerPropertyDetails2.title === undefined) ||
              e.buyerPropertyDetails2.title === ""
                ? e.buyerPrivateDetails &&
                  e.buyerPrivateDetails.buyerAddress &&
                  e.buyerPrivateDetails.buyerAddress.join(", ")
                : e.buyerPropertyDetails2.title,
            area:
              (e.buyerPropertyFeatures &&
                e.buyerPropertyFeatures.maxArea !== null) ||
              e.buyerPropertyFeatures.minArea !== null ||
              (e.buyerPropertyFeatures &&
                e.buyerPropertyFeatures.maxArea <= 99999)
                ? `${e.buyerPropertyFeatures.minArea} sq.ft` +
                  " - " +
                  `${e.buyerPropertyFeatures.maxArea} sq.ft`
                : "NA",
            beds:
              e.buyerPropertyFeatures && e.buyerPropertyFeatures.beds !== null
                ? e.buyerPropertyFeatures.beds
                : 0,
            baths:
              e.buyerPropertyFeatures && e.buyerPropertyFeatures.baths !== null
                ? e.buyerPropertyFeatures.baths
                : 0,
            cooling: e.buyerPropertyFeatures && e.buyerPropertyFeatures.colling,
            description:
              e.buyerPropertyDetails2 && e.buyerPropertyDetails2.description,
            exteriorFeatures: e.buyerPropertyFeatures.exteriorFeatures,
            heating:
              e.buyerPropertyFeatures &&
              e.buyerPropertyFeatures.heating &&
              e.buyerPropertyFeatures.heating.length > 0
                ? e.buyerPropertyFeatures.heating
                : "",
            HomeStyle:
              e.buyerPropertyFeatures && e.buyerPropertyFeatures.style
                ? e.buyerPropertyFeatures.style
                : "",
            interiorFeatures:
              e.buyerPropertyFeatures &&
              e.buyerPropertyFeatures.interiorFeatures &&
              e.buyerPropertyFeatures.interiorFeatures.length > 0
                ? e.buyerPropertyFeatures.interiorFeatures
                : "",
            lotSize:
              e.buyerPropertyFeatures &&
              e.buyerPropertyFeatures.LotSize &&
              e.buyerPropertyFeatures.LotSize.length > 0 &&
              typeof e.buyerPropertyFeatures.LotSize == "object"
                ? e.buyerPropertyFeatures.LotSize.join(", ")
                : e.buyerPropertyFeatures.LotSize,
            OriginalListprice: "",
            YearBuilt:
              e.buyerPropertyFeatures && e.buyerPropertyFeatures.yearBuilt
                ? typeof e.buyerPropertyFeatures.yearBuilt != "string"
                  ? e.buyerPropertyFeatures.yearBuilt.join(",")
                  : e.buyerPropertyFeatures.yearBuilt
                : "",
            Exterior:
              e.buyerPropertyFeatures &&
              e.buyerPropertyFeatures.exteriorFeatures &&
              e.buyerPropertyFeatures.exteriorFeatures.length > 0
                ? e.buyerPropertyFeatures.exteriorFeatures
                : "",
            GarageSpaces:
              e.buyerPropertyFeatures && e.buyerPropertyFeatures.garageSpace,
            PropertyType: e.sellerVsBuyerVsVendor
              ? e.buyerPropertyFeatures.propertyType.join(", ")
              : e.buyerPropertyDetails &&
                e.buyerPropertyDetails.propertyType &&
                e.buyerPropertyDetails.propertyType.length > 0 &&
                e.buyerPropertyDetails.propertyType.join(", "),
            minPrice: e.buyerPropertyDetails && e.buyerPropertyDetails.minprice,
            maxPrice: e.buyerPropertyDetails && e.buyerPropertyDetails.maxprice,
            Features:
              e.buyerPropertyFeatures &&
              e.buyerPropertyFeatures.featuresOfchecked &&
              e.buyerPropertyFeatures.featuresOfchecked.length > 0 &&
              e.buyerPropertyFeatures.featuresOfchecked.join(", "),
            basement:
              e.buyerPropertyFeatures &&
              e.buyerPropertyFeatures.basement &&
              e.buyerPropertyFeatures.basement.length > 0
                ? e.buyerPropertyFeatures.basement
                : "",
            condition:
              e.buyerPropertyFeatures &&
              e.buyerPropertyFeatures.condition &&
              typeof e.buyerPropertyFeatures.condition != "string"
                ? e.buyerPropertyFeatures.condition.join(", ")
                : e.buyerPropertyFeatures.condition,
            dwelling:
              e.buyerPropertyFeatures &&
              e.buyerPropertyFeatures.dwelling &&
              typeof e.buyerPropertyFeatures.dwelling != "string"
                ? e.buyerPropertyFeatures.dwelling.join(", ")
                : e.buyerPropertyFeatures.dwelling,
            utilities:
              e.buyerPropertyFeatures &&
              e.buyerPropertyFeatures.utilities &&
              e.buyerPropertyFeatures.utilities.length > 0
                ? e.buyerPropertyFeatures.utilities
                : "",
            buyersDetails: true,
            createdAt: e.createdAt,
          })
      );

    const singleData = data && data[0];

    this.setState({
      buyerHomeList,
      beforeLoginLoading: false,
      setPropertyDetails: true,
      singleData,
    });

    if (buyerHomeList && buyerHomeList.length > 0) {
      let id = buyerHomeList[0].userId;
      if("buyerAgentId" in buyerHomeList[0] === true && buyerHomeList[0]["buyerAgentId"]){
        id = buyerHomeList[0]["buyerAgentId"]
      }
      this.getUserDetails(id);
    }
  };

  handleBack = () => {
    if (
      window.location.pathname.includes("/buyersHome") &&
      this.props.location.state === true
    ) {
      this.props.history.push("/buyers");
    } else {
      if (this.props.location.state && this.props.location.state.favorited) {
        this.props.history.goBack();
      } else if (
        (this.props.location.state && this.props.location.state.agentPage) ||
        this.props.location.state === false
      ) {
        this.props.history.goBack();
      } else {
        this.props.history.goBack();
      }
    }
  };

  handleToshowLoginSider = () => {
    this.setState({
      showLoginSiderForm: true,
    });
  };

  handletoShowSignupsider = () => {
    this.setState({
      showLoginSiderForm: false,
    });
  };

  handleReset = () => {
    this.setState({
      enableSignupForm: false,
    });
  };

  render() {
    const {
      showLoginSiderForm,
      userProfile,
      dataLoading,
      singleData,
      beforeLoginLoading,
      isPopup,
    } = this.state;
    let paramsState =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.stateName;
    let card_content = [];
    singleData &&
      card_content.push(
        singleData && singleData.OriginalListprice
          ? {
              name: "List Price",
              value: singleData && singleData.OriginalListprice && (
                <NumberFormat
                  value={parseFloat(singleData.OriginalListprice).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              ),
            }
          : {
              name: "Price Range",
              value: (
                <div>
                  <NumberFormat
                    value={singleData.minPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />{" "}
                  -{" "}
                  <NumberFormat
                    value={singleData.maxPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </div>
              ),
            },
        {
          name: "Refered Agent",
          value:
            singleData &&
            singleData.referedAgentName &&
            singleData.referedAgentName &&
            singleData.referedAgentName,
        },
        {
          name: "Features",
          value:
            singleData && singleData.Features && singleData.Features !== false
              ? singleData.Features
              : "",
        },
        {
          name: "Living area",
          value: `${singleData.area ? singleData.area : "--"}`,
        },
        { name: "Beds", value: singleData.beds },
        { name: "Bath", value: singleData.baths },
        { name: "Half bath", value: singleData.halfBaths },
        { name: "Rooms", value: singleData.rooms },
        { name: "Style", value: singleData.HomeStyle },
        { name: "Cooling", value: singleData.colling },
        {
          name: "Heating",
          value:
            singleData && singleData.heating !== "" ? singleData.heating : "",
        },
        {
          name: "Fireplaces",
          value:
            singleData &&
            singleData.fireplaces &&
            singleData.fireplaces !== "" &&
            singleData.fireplaces >= 1
              ? "Yes"
              : "No",
        },
        { name: "Flooring", value: singleData.flooring },
        {
          name: "Interior Features",
          value:
            singleData && singleData.interiorFeatures !== ""
              ? singleData.interiorFeatures
              : "",
        },
        {
          name: "Lot Description",
          value: singleData.lotDescription,
        },
        { name: "Year Built", value: singleData.YearBuilt },
        {
          name: "Exterior",
          value:
            singleData && singleData.Exterior !== "" ? singleData.Exterior : "",
        },
        {
          name: "Parking Spaces",
          value: singleData.ParkingSpaces,
        },
        {
          name: "Garage Spaces",
          value: singleData.GarageSpaces,
        },
        {
          name: "Lot size",
          value:
            singleData && singleData.lotSize && singleData.lotSize.length > 0
              ? singleData.lotSize
              : "",
        },
        {
          name: "Basement",
          value:
            singleData && singleData.basement !== "" ? singleData.basement : "",
        },
        { name: "Condition", value: singleData.condition },
        { name: "Dwelling", value: singleData.dwelling },
        {
          name: "Utilities",
          value:
            singleData && singleData.utilities !== ""
              ? singleData.utilities
              : "",
        },
        {
          name: "Property Type",
          value: singleData.PropertyType,
        }
      );
      let userDetails = {}
      if(Object.keys(userProfile).length > 0){
        userDetails = {
          homeAgentName:
            userProfile && userProfile.firstName + " " + userProfile.lastName,
          profileURL:
            userProfile && userProfile.profilePicture
              ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                userProfile.profilePicture[0]
              : "",
          userId: userProfile && userProfile.id,
          mlsId: userProfile && userProfile.mlsId,
          agentEmail: userProfile && userProfile.email,
          companyName:
            userProfile && userProfile.newdata && userProfile.newdata.companyName,
          userType: userProfile && userProfile.userProfile,
        };
      }
    let details_data = [];

    details_data.push(
      {
        name: "Date and Time Added",
        value: moment(singleData && singleData.createdAt).format(
          "D  MMMM, YYYY"
        ),
      },
      {
        name: singleData && singleData.mlsData && "MLS ID",
        value:
          singleData && singleData.mlsData && singleData.homeId
            ? singleData.homeId
            : " ",
      },

      {
        name: "Status",
        value:
          singleData &&
          singleData.status &&
          typeof singleData.status === "object"
            ? singleData.status.includes("pending") &&
              singleData.status.includes("activeundercontract")
              ? "Under Agreement"
              : singleData.status.includes("comingsoon")
              ? "Coming Soon"
              : singleData.status.includes("active")
              ? "New Listing"
              : singleData.status
            : singleData.status,
      },
      { name: "Area", value: "" },
      {
        name: "Year Built",
        value: singleData && singleData.YearBuilt,
      },
      {
        name: "Cooperating Commission",
        value:
          singleData &&
          singleData.corporationCommission &&
          singleData.corporationCommission + "%",
      }
    );
    return (
      <>
        {beforeLoginLoading ? (
          <Loading enableLoading={beforeLoginLoading} />
        ) : (
          <>
            <div>
              <Layout style={{ marginTop: "1vw" }}>
                <Row>
                  {isPopup !== true && (
                    <Col
                      span={17}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                      className="margin-body-agent"
                    >
                      <Button
                        type="text"
                        icon={<ArrowLeftOutlined />}
                        className="font-family-style font-medium-size"
                        onClick={this.handleBack}
                      >
                        {this.props.location.state &&
                        this.props.location.state.favorited &&
                        this.props.location.state.favorited
                          ? "Back to favorites"
                          : this.props.location.state &&
                            this.props.location.state.agentPage &&
                            this.props.location.state.agentPage
                          ? "Back to agent search"
                          : this.props.location.state === false
                          ? "Go Back"
                          : "Return to search result"}
                      </Button>
                    </Col>
                  )}

                  {/* DON'T DELETE     */}

                  {/* {this.state.recordNotFound && (
                    <Col span={24} style={{ alignContent: "center" }}>
                      <h2
                        className="font-family-style font-medium-size"
                        style={{ padding: "3%" }}
                      >
                        This listing is no longer available.
                      </h2>
                    </Col>
                  )} */}

                  {this.state.setPropertyDetails && (
                    <Col span={24}>
                      <div>
                        <Layout className="margin-body-agent">
                          <Row>
                            <Col
                              xl={{
                                span: isPopup !== true ? 17 : 24,
                                offset: 0,
                              }}
                              lg={{
                                span: isPopup !== true ? 17 : 24,
                                offset: 0,
                              }}
                              md={{
                                span: isPopup !== true ? 22 : 24,
                                offset: 1,
                              }}
                              sm={{
                                span: isPopup !== true ? 22 : 24,
                                offset: 1,
                              }}
                              xs={{
                                span: isPopup !== true ? 22 : 24,
                                offset: 1,
                              }}
                            >
                              <Row className="row-padding">
                                <Card>
                                  <div className="homeSearch">
                                    <Layout
                                      style={{ backgroundColor: "white" }}
                                    >
                                      <Row>
                                        <Col
                                          xl={{ span: 16 }}
                                          lg={{ span: 16 }}
                                          md={{ span: 18 }}
                                          sm={{ span: 18 }}
                                          xs={{ span: 18 }}
                                        >
                                          <Skeleton
                                            title={false}
                                            loading={dataLoading}
                                            active
                                          >
                                            <p className="searchHomes-titles">
                                              {singleData && singleData.title}
                                            </p>
                                          </Skeleton>
                                        </Col>
                                      </Row>
                                      {singleData &&
                                      singleData.makePrivateAddress ? (
                                        <>
                                          {singleData &&
                                            singleData.homeAddress && (
                                              <Row>
                                                <Col span={24}>
                                                  <Skeleton
                                                    title={false}
                                                    loading={dataLoading}
                                                    active
                                                  >
                                                    <p className="searchHomes-titles">
                                                      {singleData &&
                                                        singleData.homeAddress}
                                                    </p>
                                                  </Skeleton>
                                                </Col>
                                              </Row>
                                            )}
                                        </>
                                      ) : (
                                        <>
                                          {singleData && singleData.address && (
                                            <Row>
                                              <Col span={24}>
                                                <Skeleton
                                                  title={false}
                                                  loading={dataLoading}
                                                  active
                                                >
                                                  <p className="searchHomes-titles">
                                                    {singleData &&
                                                      singleData.address}
                                                  </p>
                                                </Skeleton>
                                              </Col>
                                            </Row>
                                          )}
                                        </>
                                      )}
                                      <Row>
                                        <Skeleton
                                          title={false}
                                          loading={dataLoading}
                                          active
                                        >
                                          <p className="font-family-style font-medium-size text-size2 color-grey">
                                            {singleData &&
                                            singleData.OriginalListprice &&
                                            singleData.OriginalListprice ? (
                                              <Row>
                                                <NumberFormat
                                                  value={parseFloat(
                                                    singleData &&
                                                      singleData.OriginalListprice
                                                  ).toFixed(2)}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"$"}
                                                />
                                              </Row>
                                            ) : (
                                              <Row>
                                                <NumberFormat
                                                  value={parseFloat(
                                                    singleData &&
                                                      singleData.minPrice
                                                  ).toFixed(2)}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"$"}
                                                />{" "}
                                                -
                                                <NumberFormat
                                                  value={parseFloat(
                                                    singleData &&
                                                      singleData.maxPrice
                                                  ).toFixed(2)}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"$"}
                                                />{" "}
                                              </Row>
                                            )}
                                          </p>
                                        </Skeleton>
                                      </Row>
                                      <Row>
                                        <Col style={{ marginRight: "20px" }}>
                                          <img
                                            src={bedIcon}
                                            alt="space"
                                            className="beds-img"
                                          />
                                          <span className="searchHomes-subtitles">
                                            {singleData && singleData.beds
                                              ? singleData.beds
                                              : 0}{" "}
                                            Beds
                                          </span>
                                        </Col>
                                        <Col style={{ marginRight: "20px" }}>
                                          <img
                                            src={bathIcon}
                                            alt="space"
                                            className="beds-img"
                                          />
                                          <span className="searchHomes-subtitles">
                                            {singleData && singleData.baths
                                              ? singleData.baths
                                              : 0}{" "}
                                            Baths
                                          </span>
                                        </Col>
                                        <Col className={"details-area"}>
                                          <img
                                            src={reSize}
                                            alt="space"
                                            className="beds-img"
                                          />
                                          <Skeleton
                                            title={false}
                                            loading={dataLoading}
                                            active
                                            className="searchHomes-subtitles"
                                          >
                                            <span className="searchHomes-subtitles">
                                              {singleData &&
                                              (singleData.area === null ||
                                                singleData.area === undefined ||
                                                singleData.area >= 99999)
                                                ? "NA"
                                                : `${
                                                    singleData &&
                                                    singleData.area
                                                  }`}
                                            </span>
                                          </Skeleton>
                                        </Col>
                                      </Row>
                                    </Layout>
                                  </div>
                                  <Divider
                                    style={{
                                      margin: "1%",
                                      marginBottom: "20px",
                                    }}
                                  ></Divider>
                                  <Row>
                                    <Space
                                      size="large"
                                      className="font-family-style font-medium-size size1"
                                    >
                                      <a href="#description">Description</a>

                                      <a href="#Preferences">
                                        Buyer Preferences
                                      </a>
                                    </Space>
                                  </Row>
                                </Card>
                              </Row>

                              <div className="achoreTagClick" id="description">
                                <Row className="row-padding">
                                  <Card title="Description / Notes">
                                    <Skeleton
                                      title={false}
                                      loading={dataLoading}
                                      active
                                    >
                                      <p className="property-description font-family-style font-normal-size">
                                        {singleData && singleData.description}
                                      </p>
                                    </Skeleton>
                                  </Card>
                                </Row>
                              </div>

                              <div
                                id={"Preferences"}
                                className="achoreTagClick"
                              >
                                <Row className="row-padding">
                                  <Card title={"Buyer Preferences"}>
                                    {card_content.length > 0 &&
                                      card_content
                                        .filter(
                                          (item) =>
                                            item.value !== undefined &&
                                            item.value !== null &&
                                            item.value !== ""
                                        )
                                        .map((e) => (
                                          <Row
                                            style={{
                                              marginBottom: "0.73vw",
                                              marginLeft: "0.73vw",
                                            }}
                                          >
                                            <Col
                                              md={10}
                                              lg={10}
                                              xl={8}
                                              sm={12}
                                              xs={12}
                                            >
                                              <Skeleton
                                                title={false}
                                                loading={dataLoading}
                                                active
                                              >
                                                <span className=" font-family-style font-normal-size property-detail-value">
                                                  {e.name}
                                                </span>
                                              </Skeleton>
                                            </Col>
                                            <Col
                                              md={14}
                                              lg={14}
                                              xl={10}
                                              sm={12}
                                              xs={12}
                                            >
                                              <Skeleton
                                                title={false}
                                                loading={dataLoading}
                                                active
                                              >
                                                <span className=" font-family-style font-medium-size property-detail-name">
                                                  {e.value}
                                                </span>
                                              </Skeleton>
                                            </Col>
                                          </Row>
                                        ))}
                                  </Card>
                                </Row>
                              </div>
                            </Col>

                            {isPopup !== true && (
                              <Col
                                xl={{ span: 6, offset: 0 }}
                                lg={{ span: 6, offset: 0 }}
                                md={{ span: 22, offset: 1 }}
                                sm={{ span: 22, offset: 1 }}
                                xs={{ span: 22, offset: 1 }}
                                className="margin-left-agent"
                              >
                                <div>
                                  <>
                                    <Row className="row-padding">
                                      <Card>
                                        <Row
                                          className="center-item"
                                          style={{ padding: "1%" }}
                                        >
                                          <Col>
                                            {userDetails &&
                                            userDetails.profileURL ? (
                                              <img
                                                className="img-detail-page"
                                                src={userDetails.profileURL}
                                                alt="profileURL"
                                              />
                                            ) : (
                                              <img
                                                className="img-detail-page"
                                                src={profilePic}
                                                alt="logo"
                                              />
                                            )}
                                          </Col>
                                        </Row>

                                        <Row className="center-item font-family-style font-normal-size profile-name">
                                          <Col className="padding-text-name">
                                            <span className="text-size-name">
                                              {singleData &&
                                                userDetails.homeAgentName}
                                            </span>
                                          </Col>
                                        </Row>

                                        <Row
                                          className="center-item font-family-style"
                                          style={{
                                            paddingTop: "0%",
                                            paddingBottom: "1%",
                                            marginTop: "-10px",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: "0px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {singleData &&
                                              singleData.companyName}
                                          </p>
                                        </Row>
                                        <Row
                                          className="center-item"
                                          style={{ marginTop: "-20px" }}
                                        >
                                          <Col>
                                            <h6
                                              className="buyer-agent"
                                              style={{ color: "grey" }}
                                            >
                                              Buyer's Agent
                                            </h6>
                                          </Col>
                                        </Row>

                                        <Row className="center-item">
                                          <Col>
                                            {singleData &&
                                              singleData.servingName && (
                                                <Row className="center-item font-family-style">
                                                  <p>
                                                    {singleData &&
                                                      singleData.servingName}
                                                  </p>
                                                </Row>
                                              )}

                                            {singleData && singleData.userId && (
                                              <Link
                                                style={{
                                                  textDecoration: "none",
                                                  paddingLeft:
                                                    singleData &&
                                                    singleData.mlsData & 7,
                                                }}
                                                to={{
                                                  pathname: `/viewProfile/${
                                                    userDetails &&
                                                    userDetails.userId
                                                      ? userDetails.userId
                                                      : userDetails.userId
                                                  }`,
                                                  state: "DetailPage",
                                                }}
                                                className="profile-view-link"
                                              >
                                                View Profile
                                              </Link>
                                            )}
                                            <Row className="center-item">
                                              <p
                                                className="button-inner-text-size"
                                                style={{}}
                                              ></p>
                                            </Row>
                                          </Col>
                                        </Row>

                                        <Divider className="divider-detail"></Divider>

                                        <Row
                                          className="center-item font-family-style"
                                          style={{ padding: "3%" }}
                                        >
                                          {Object.keys(userDetails).length > 0 && singleData &&
                                            <SendMessage
                                              msgButtonTitle={"Message Buyer"}
                                              toName={
                                                userDetails &&
                                                userDetails?.homeAgentName
                                              }
                                              propertyId={
                                                singleData &&
                                                singleData.homeId &&
                                                singleData.homeId
                                              }
                                              propertyTitle={
                                                singleData && singleData.title
                                                  ? singleData.title
                                                  : "enter your message"
                                              }
                                              propertyImages={
                                                singleData && singleData.photos
                                              }
                                              buttonAlign="right"
                                              mlsId={
                                                userDetails && userDetails.mlsId
                                              }
                                              agentEmail={
                                                userDetails &&
                                                userDetails.agentEmail
                                              }
                                              messageType="potential"
                                              isRegistredUser={
                                                this.props.match.params
                                                  .stateName === "offMls"
                                                  ? true
                                                  : false
                                              }
                                              stateType={
                                                this.props.match.params.stateName
                                              }
                                              mlsAgentId={
                                                singleData && singleData.agentId
                                              }
                                              agentId={singleData.userId}
                                            />
                                          }
                                        </Row>
                                      </Card>
                                    </Row>
                                    {singleData && singleData && (
                                      <Advertisement
                                        otherAds={true}
                                        adType="Square"
                                        adWhereToShow="Detail"
                                        adLimit="10"
                                        showMatchesPage={
                                          this.props.showMatchesPage
                                        }
                                        cityName={singleData && singleData.city}
                                        postalCode={
                                          singleData && singleData.zipCode
                                        }
                                        stateName={paramsState}
                                        detailsPageAds={true}
                                      />
                                    )}
                                  </>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </Layout>

                        <Drawer
                          placement="right"
                          title=""
                          width={
                            window.screen.width >= "768"
                              ? "40%"
                              : window.screen.width > "501" &&
                                window.screen.width <= "767"
                              ? "80%"
                              : "100%"
                          }
                          mask={true}
                          closable={true}
                          getContainer={false}
                          visible={this.state.enableSignupForm}
                          onClose={() => this.handleReset()}
                          headerStyle={{ textAlign: "center" }}
                          bodyStyle={{
                            paddingBottom: 80,
                            backgroundColor: "#eff2f5",
                            padding: "0px",
                          }}
                          className="homecard-drawer"
                        >
                          <Row>
                            <Col span={24}>
                              {showLoginSiderForm ? (
                                <LoginForm
                                  showAdd={false}
                                  modalTrue
                                  goTopreviousPage={true}
                                  handleToshowLoginSider={
                                    this.handletoShowSignupsider
                                  }
                                  showLoginSiderForm={showLoginSiderForm}
                                  questionPage
                                />
                              ) : (
                                <SignupForm
                                  questionPage
                                  showAdd={false}
                                  modalTrue
                                  goTopreviousPage={true}
                                  handleToshowLoginSider={
                                    this.handleToshowLoginSider
                                  }
                                  showLoginSiderForm={showLoginSiderForm}
                                />
                              )}
                            </Col>
                          </Row>
                        </Drawer>
                      </div>
                    </Col>
                  )}
                </Row>
              </Layout>
            </div>
          </>
        )}
      </>
    );
  }
}

export default withAppContext(withRouter(BuyerDetails));
