import React, { Component } from "react";
import { Row, Col, Divider } from "antd";
import ExternalTestimonials from "../../common/ExternalTestimonials";
export default class Testimonials extends Component {
  render() {
    return (
      <div style={{ marginTop: "1vw", marginBottom: "3vw" }}>
        <Row>
          <Col
            xl={{ span: 8, offset: 8 }}
            lg={{ span: 8, offset: 8 }}
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
          >
            <Divider>
              <h1>Testimonials</h1>
            </Divider>
            <ExternalTestimonials />
          </Col>
        </Row>
      </div>
    );
  }
}
