import React, { Component } from "react";
import utils from "../utils/Utils";
import { update, noAuthgetDetails,noAuthlist } from "../api/Apis";
import { message } from "antd";
export const AppContext = React.createContext("app");

const initialState = {
  user: "",
  userDetails: "",
  publicProperties: "",
  city: "",
  address: "",
  listOfFavorites: [],
  favorites: 0,
  filterSearchData: "",
  currentPage: 1,
  pageSize: 10,
  sellerHomeDetails: {
    privateDetails: "",
    propertyDetails: "",
    propertyFeatures: "",
    propertyDetails2: {
      agent: "",
      commission: "",
      description: "",
      homePhotos: "",
      title: "",
      videoLink: "",
      sellerInterestedTown: "",
    },
  },
  buyerHomeDetails: {
    buyerPrivateDetails: "",
    buyerPropertyDetails: "",
    buyerPropertyFeatures: "",
    buyerPropertyDetails2: "",
  },
  loading: true,
  agentProfile: false,
  offMLSuserId: "",
  homeAgentProfileUrl: "",
  homeAgentName: "",
  authenticateOffMLS: false,
  locationState: {},
  userLogin: false,

  displayCityState: "",
  filtersearch: {},
  page_pathname: "",
  websiteThemes: {},
  showEditModal: false,
  updateLoading: false,
};

export class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    // if (utils.checkCookie("user")) {
    //   this.setUser(JSON.parse(utils.getCookie("user")), false);
    // }
    // if(utils.checkCookie("userDetails")){
    // this.setUserDetails(JSON.parse(utils.getCookie("userDetails")));
    // }
  }

  getWebsites = (websiteHost) => {
    noAuthgetDetails("getWebsitesData", websiteHost)
      .then((result) => {
        if (result.success) {
          let websiteThemes = result.data.data;
          this.setState({
            websiteThemes,
          });
          localStorage.setItem("orgId", JSON.stringify(result.data.data.id));
          localStorage.setItem("orgDetails",JSON.stringify(result.data.data))
          const dataObj = {
            dataType: "apiConfig",
            orgId: result.data.data.id
          };
          noAuthlist("getConfigData", dataObj)
            .then((stateCodes) => {
              if (stateCodes.success) {
                localStorage.setItem(
                  "stateCodesFromAdmin",
                  JSON.stringify(stateCodes.data)
                );
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateWebsite = (websiteId, data) => {
    let adminDetails = JSON.parse(localStorage.getItem("orgDetails"));
    update("saveWebsitesData", websiteId, data)
      .then((result) => {
        if (result.success) {
          message.success("Updated successfully!");
          this.setState({
            showEditModal: false,
            updateLoading: false,
          });
          this.getWebsites(adminDetails.websiteName);
          return true;
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  openShowEditModal = (showEditModal) => {
    this.setState({
      showEditModal,
    });
  };
  openupdateLoading = (updateLoading) => {
    this.setState({
      updateLoading,
    });
  };
  setUserLogin = (userLogin) => {
    this.setState({
      userLogin,
    });
  };

  handleToSetCurrentPage = (currentPage, pageSize) => {
    this.setState({
      currentPage,
      pageSize,
    });
  };

  setFilterData = (filterSearchData) => {
    this.setState({
      filterSearchData,
    });
  };

  setAgentFilterSearch = (filtersearch) => {
    this.setState({
      filtersearch,
    });
  };

  setOffMLSuserId = (
    offMLSuserId,
    homeAgentName,
    homeAgentProfileUrl,
    authenticateOffMLS,
    locationState
  ) => {
    this.setState({
      offMLSuserId,
      homeAgentName,
      homeAgentProfileUrl,
      authenticateOffMLS,
      locationState,
    });
  };

  setUser = (user, remember) => {
    this.setState({ user }, () => {
      localStorage.setItem("user", JSON.stringify(this.state.user), remember);
    });
  };

  setUserDetails = (userDetails) => {
    this.setState({ userDetails }, () => {
      localStorage.setItem(
        "userDetails",
        JSON.stringify(this.state.userDetails)
      );
    });
  };

  setPublicProperties = (publicProperties) => {
    this.setState({ publicProperties });
  };

  setCity = (city, displayCityState) => {
    this.setState({ city, displayCityState });
  };

  setAddress = (address) => {
    this.setState({ address }, () => {
      utils.setCookie("address", JSON.stringify(this.state.address));
    });
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  handleFavoritesCount = (newfavorites, count) => {
    this.setState({
      favorites: count,
      listOfFavorites: newfavorites,
    });
  };

  setProperty2 = (value) => {
    // console.log(value,"vale");
  };

  setAgentProfileFalse = (newFalse) => {
    this.setState({
      agentProfile: newFalse,
    });
  };

  setHomeDetails = (data, type) => {
    if (type === "PRIVATE_DETAILS") {
      this.setState({
        sellerHomeDetails: {
          ...this.state.sellerHomeDetails,
          privateDetails: data,
        },
      });
    } else if (type === "PROPERTY_DETAILS") {
      this.setState({
        sellerHomeDetails: {
          ...this.state.sellerHomeDetails,
          propertyDetails: data,
        },
      });
    } else if (type === "PROPERTY_FEATURES") {
      this.setState({
        sellerHomeDetails: {
          ...this.state.sellerHomeDetails,
          propertyFeatures: data,
        },
      });
    } else if (type === "PROPERTY_DETAILS2") {
      this.setState({
        sellerHomeDetails: {
          ...this.state.sellerHomeDetails,
          propertyDetails2: data,
        },
      });
    } else if (type === "EMPTY_DATA") {
      this.setState({
        sellerHomeDetails: {
          ...this.state.sellerHomeDetails,
          privateDetails: "",
          propertyDetails: "",
          propertyFeatures: "",
          propertyDetails2: "",
        },
      });
    }
  };

  checkForm = (e) => {
    // console.log(e,"eeeeeeeee");
  };

  setBuyerHomeDetails = (data, type) => {
    if (type === "BUYER_PRIVATE_DETAILS") {
      this.setState({
        buyerHomeDetails: {
          ...this.state.buyerHomeDetails,
          buyerPrivateDetails: data,
        },
      });
    } else if (type === "BUYER_PROPERTY_DETAILS") {
      this.setState({
        buyerHomeDetails: {
          ...this.state.buyerHomeDetails,
          buyerPropertyDetails: data,
        },
      });
    } else if (type === "BUYER_PROPERTY_FEATURES") {
      this.setState({
        buyerHomeDetails: {
          ...this.state.buyerHomeDetails,
          buyerPropertyFeatures: data,
        },
      });
    } else if (type === "BUYER_PROPERTY_DETAILS2") {
      let newData = this.state.buyerHomeDetails
      newData["buyerPropertyDetails2"]= data
      this.setState({
        buyerHomeDetails: newData,
      });
    } else if (type === "BUYER_EMPTY_DATA") {
      this.setState({
        buyerHomeDetails: {
          ...this.state.buyerHomeDetails,
          buyerPrivateDetails: "",
          buyerPropertyDetails: "",
          buyerPropertyFeatures: "",
          buyerPropertyDetails2: "",
        },
      });
    } else if (type === "ADD_BUYER_GENERAL_FORM") {
      console.log(data,"contexct",this.state.buyerHomeDetails);
      let newData = this.state.buyerHomeDetails
      newData["buyerPropertyDetails"]= data
      this.setState({
        buyerHomeDetails: newData,
      });
    } else if (type === "ADD_BUYER_FEATURE_DETAIL_FORM") {
      this.setState({
        buyerHomeDetails: {
          ...this.state.buyerHomeDetails,
          buyerPropertyFeatures: data,
        },
      });
    } else if (type === "ALL_DATA"){
      this.setState({
        buyerHomeDetails:data
      })
    }
  };

  setPagePathName = (page_pathname) => {
    this.setState({
      page_pathname,
    });
  };

  handleWebTheme = (websiteThemes) => {
    this.setState({
      websiteThemes,
    });
  };
  render() {
    const {
      favorites,
      offMLSuserId,
      homeAgentProfileUrl,
      homeAgentName,
      addBuyerformDetails,
      authenticateOffMLS,
      locationState,
      filterSearchData,
      filtersearch,
      currentPage,
      pageSize,
      userLogin,
      user,
      userDetails,
      publicProperties,
      agentProfile,
      city,
      sellerHomeDetails,
      buyerHomeDetails,
      loading,
      listOfFavorites,
      displayCityState,
      page_pathname,
      websiteThemes,
      showEditModal,
      updateLoading,
    } = this.state;
    return (
      <AppContext.Provider
        value={{
          state: {
            loading,
            user,
            userDetails,
            publicProperties,
            city,
            sellerHomeDetails,
            buyerHomeDetails,
            favorites,
            listOfFavorites,
            agentProfile,
            offMLSuserId,
            homeAgentProfileUrl,
            homeAgentName,
            authenticateOffMLS,
            locationState,
            filterSearchData,
            currentPage,
            pageSize,
            userLogin,
            addBuyerformDetails,
            displayCityState,
            filtersearch,
            page_pathname,
            websiteThemes,
            showEditModal,
            updateLoading,
          },
          setUser: this.setUser,
          setUserDetails: this.setUserDetails,
          setPublicProperties: this.setPublicProperties,
          setCity: this.setCity,
          setAddress: this.setAddress,
          setHomeDetails: this.setHomeDetails,
          checkForm: this.checkForm,
          setBuyerHomeDetails: this.setBuyerHomeDetails,
          setProperty2: this.setProperty2,
          setLoading: this.setLoading,
          handleFavoritesCount: this.handleFavoritesCount,
          setAgentProfileFalse: this.setAgentProfileFalse,
          setOffMLSuserId: this.setOffMLSuserId,
          setFilterData: this.setFilterData,
          handleToSetCurrentPage: this.handleToSetCurrentPage,
          setUserLogin: this.setUserLogin,
          setAgentFilterSearch: this.setAgentFilterSearch,
          handlePagePathname: this.setPagePathName,
          handleWebTheme: this.handleWebTheme,
          getWebsites: this.getWebsites,
          updateWebsite: this.updateWebsite,
          openShowEditModal: this.openShowEditModal,
          openupdateLoading: this.openupdateLoading,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export function withAppContext(GivenComponent) {
  return function WrapperComponent(props) {
    return (
      <AppContext.Consumer>
        {(state) => <GivenComponent {...props} context={state} />}
      </AppContext.Consumer>
    );
  };
}
