import React, { Component } from "react";
import { Row, Spin } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import moment from "moment";
import Carousel from "react-elastic-carousel";
import NumberFormat from "react-number-format";
import Item from "../containers/homePage/Item";
import bedIcon from "../images/awesome-bed-icon.png";
import bathIcon from "../images/awesome-bath-icon.png";
import reSize from "../images/sq-feet-icon.png";
import defaultImage from "../images/defaultImage.jpg";

const queryString = require("query-string");

export default class HomesCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allHomesList: [],
      dataLoading: false,
    };
  }

  componentDidMount = () => {
    const { whereToShow, filterInfo } = this.props;
    if (whereToShow === "homes") {
      this.getHomesList();
    } else {
      this.checkShowMatches(filterInfo);
    }
  };

  checkShowMatches = (similarHome) => {
    const filter = [];
    if (similarHome.OriginalListprice && similarHome.OriginalListprice) {
      filter.push({
        minprice: 25000,
        maxprice: similarHome.OriginalListprice,
        type: "price",
      });
    }

    if (similarHome.area && similarHome.area) {
      filter.push({
        minarea: 0,
        maxarea: parseInt(similarHome.area),
        type: "area",
      });
    }

    if (similarHome.beds && similarHome.beds) {
      filter.push({ minbeds: similarHome.beds, type: "beds" });
    }

    if (similarHome.baths && similarHome.baths) {
      filter.push({ minbaths: similarHome.baths, type: "baths" });
    }

    if (similarHome.PropertyType && similarHome.PropertyType) {
      const types = [];
      let propertyType = similarHome.PropertyType;
      if (
        propertyType === "Commercial Land" ||
        propertyType === "Residential Land" ||
        propertyType === "Land"
      ) {
        types.push("LND");
      }

      if (propertyType === "Mobile Homes") {
        types.push("MBL");
      }

      if (propertyType === "Residential Multi Family Home") {
        types.push("MLF");
      }

      if (propertyType === "Residential Single Family Home") {
        types.push("Detached", "Attached");
      }

      if (
        propertyType === "Residential Single Family Home" ||
        propertyType === "Residential Studio" ||
        propertyType === "Residential Apartment" ||
        propertyType === "Residential"
      ) {
        types.push("RES");
      }

      if (propertyType === "Residential Rental") {
        types.push("RNT");
      }

      if (
        propertyType === "Residential Condo" ||
        propertyType === "Residential Condominium" ||
        propertyType === "Condominium" ||
        propertyType === "Condominium/Co-op"
      ) {
        types.push("CND");
      }

      if (
        propertyType === "Commercial MLS" ||
        propertyType === "Commercial Office" ||
        propertyType === "Commerical/Industrial" ||
        propertyType === "Business Opportunity"
      ) {
        types.push("CRE");
      }

      if (propertyType === "Coopertive") {
        types.push("Coopertive");
      }

      filter.push({
        subTypeText: types,
        type: "subtype",
        showPropertyType: similarHome.PropertyType,
      });
    }
    if (similarHome.city && similarHome.state) {
      const stateCodes = JSON.parse(
        localStorage.getItem("stateCodesFromAdmin")
      );
      let setVendor = stateCodes.filter(
        (restState) =>
          restState.stateName.toLowerCase() ===
            similarHome.state.toLowerCase() ||
          restState.stateType.toLowerCase() === similarHome.state.toLowerCase()
      );
      let stateName =
        setVendor &&
        setVendor.length > 0 &&
        setVendor[0].stateType &&
        setVendor[0].stateType;
      // if (similarHome.state.toLowerCase() === "connecticut") {
      //   stateName = "CT";
      // } else {
      //   stateName = similarHome.state;
      // }
      if (stateName) {
        filter.push({
          cities: `${stateName}:${similarHome.city}`,
          type: "cities",
        });
      }
    }
    this.handleShowMatchData(filter);
  };

  handleShowMatchData = (FilterData) => {
    let filterObject = {};
    for (let filterOption of FilterData) {
      // if (filterOption.type === "beds" && filterOption.minbeds !== 0) {
      //   filterObject["minbeds"] = filterOption.minbeds;
      // }

      // if (filterOption.type === "baths" && filterOption.minbaths !== 0) {
      //   filterObject["minbaths"] = filterOption.minbaths;
      // }

      // if (filterOption.type === "area") {
      //   filterObject["maxarea"] = filterOption.maxarea;
      //   filterObject["minarea"] = filterOption.minarea;
      // }

      if (filterOption.type === "price") {
        filterObject["maxprice"] = filterOption.maxprice;
        // filterObject["minprice"] = filterOption.minprice;
      }

      if (
        filterOption.type === "subtype" &&
        filterOption.subTypeText.length > 0
      ) {
        filterObject["types"] =
          filterOption.subTypeText && filterOption.subTypeText.join(",");
      }

      if (
        filterOption.type === "status" &&
        filterOption.status &&
        filterOption.status.length > 0
      ) {
        filterObject["status"] = filterOption.status;
      }

      if (filterOption.type === "cities" && filterOption.cities) {
        filterObject["cities"] = filterOption.cities;
      }
    }
    filterObject["filter"] = true;
    this.getHomesList(filterObject);
  };

  getHomesList = async (filterItems) => {
    this.setState({ dataLoading: true });
    const orgId = JSON.parse(localStorage.getItem("orgId"));

    let url = "";
    let apiUrl = `${process.env.REACT_APP_API_GATEWAY}/noAuth/getHomesList?mls=both&limit=20&orgId=${orgId}&`;
    if (filterItems) {
      url = queryString.stringifyUrl({ url: apiUrl, query: filterItems });
    } else {
      url = queryString.stringifyUrl({ url: apiUrl, query: {} });
    }
    const method = "get";
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    const data = {};
    axios({
      method,
      url,
      headers,
      data,
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            dataLoading: false,
            allHomesList: response.data,
          });
          let copyRightDates = "rets_last_updated_date" in response.data[0] === true ? response.data[0].rets_last_updated_date : ""
          localStorage.setItem(
            "rets_data_last_updated_date",
            JSON.stringify(copyRightDates)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  handleClick = (state, id) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.location.replace(`/homeDetails/${state}/${id}`);
  };

  render() {
    const { allHomesList, dataLoading } = this.state;
    return (
      <div className="carsole">
        <Spin spinning={dataLoading}>
          {allHomesList && allHomesList.length > 0 ? (
            <Carousel
              itemPosition="CENTER"
              autoPlaySpeed={4000}
              enableAutoPlay
              enableMouseSwipe
              itemsToShow={
                window.screen.width >= "1025"
                  ? 3
                  : window.screen.width > "992" && window.screen.width <= "1024"
                  ? 2
                  : 1
              }
              itemsToScroll={1}
              style={{ marginBottom: "3vw" }}
            >
              {allHomesList.map((home) => (
                <div className="checking-carsouel-hover" key={home.id}>
                  <Item className="carousel-height">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none !important",
                      }}
                      onClick={() => this.handleClick(home.stateName, home.id)}
                    >
                      <div className="profilecard-head">
                        <img
                          style={{ backgroundColor: "#d4d8f1fa" }}
                          className="object-Fit"
                          alt={home.id}
                          src={home.photos ? home.photos : defaultImage}
                        />
                        <div className="profilecard-day">
                          {"Added " +
                            moment(
                              home.listDate ? home.listDate : home.modified
                            ).format("Do MMM YYYY")}
                        </div>
                      </div>

                      <div className="profilecard-body">
                        <div>
                          <p className="size1-homes font-medium-size">
                            {home.title}
                          </p>
                        </div>
                        <div className="display-flex-between">
                          <div
                            className="text-size2-homes font-medium-size"
                            style={{ color: "lightslategrey" }}
                          >
                            <NumberFormat
                              value={parseFloat(home.cost).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </div>
                        </div>

                        <Row className="beds-homes-evenly text-size1 font-family-style font-medium-size color-grey">
                          <div className="beds-home">
                            <img
                              src={bedIcon}
                              alt="space"
                              className="beds-img-homes"
                            />
                            <span className="searchHomes-subtitles-homes">
                              {home.beds} Beds
                            </span>
                          </div>

                          <div className="beds-home">
                            <img
                              src={bathIcon}
                              alt="space"
                              className="beds-img-homes"
                            />
                            <span className="searchHomes-subtitles-homes">
                              {home.baths} Baths
                            </span>
                          </div>
                          <div className="beds-home">
                            <img
                              src={reSize}
                              alt="space"
                              className="beds-img-homes"
                            />
                            <span className="searchHomes-subtitles-homes">
                              {home.area} sq.ft
                            </span>
                          </div>
                        </Row>
                        <div className="text-size3 font-family-style font-medium-size">
                          <p style={{ marginBottom: "0" }}>
                            {home && home.type && home.type === "LND"
                              ? "Land"
                              : home.type === "MBL"
                              ? "Mobile Homes"
                              : home.type === "MLF"
                              ? "Residential Multi Family Home"
                              : home.type === "RES"
                              ? "Residential"
                              : home.type === "RNT"
                              ? "Residential Rental"
                              : home.type === "CND"
                              ? "Condominium"
                              : home.type === "CRE"
                              ? "Commercial"
                              : home.type}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Item>
                </div>
              ))}
            </Carousel>
          ) : (
            <h2 style={{ textAlign: "center", marginBottom: "0px" }}>
              There is no listing found to show
            </h2>
          )}
        </Spin>
      </div>
    );
  }
}
