import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Divider,
  Button,
  Modal,
  Input,
  notification,
  Tooltip,
  Drawer,
} from "antd";
import { noAuthadd } from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import { withRouter } from "react-router";
import { MessageOutlined } from "@ant-design/icons";
import SignupForm from "../containers/RegisterForm";
import LoginForm from "../containers/LoginForm";
import BuyerPreferedAgentPopup from "./BuyerPreferedAgentPopup";
const { TextArea } = Input;
export class SendMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      contactVisible: false,
      loading: false,
      loanVisible: false,
      value: 3,
      to: "",
      from: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      title: "",
      buttonAlignment: "right",
      mlsId: "",
      agentEmail: "",
      disableEdit: false,
      pageType: "",
      isRegistredUser: false,
      stateName: "offMls",
      propertyId: "",
      showLoginSiderForm: false,
      enableSignupForm: false,
      visiblePreferedAgent: false,
      agentsList: [],
      addUserName: "",
      addUserEmail: "",
      newPreferedAgent: "",
      assignAgentId:""
    };
  }

  componentDidMount() {
    if (this.props && this.props.isRegistredUser) {
      const isRegistredUserValue = this.props.isRegistredUser;
      this.setState({
        isRegistredUser: isRegistredUserValue,
      });
    }

    if (this.props && this.props.stateType) {
      this.setState({
        stateName: this.props.stateType,
      });
    }

    if (this.props && this.props.messageType) {
      const pageType = this.props.messageType;
      this.setState({
        pageType: pageType !== "" ? pageType : "",
      });
    }

    if (this.props && this.props.buttonAlign) {
      const buttonAlign = this.props.buttonAlign;
      this.setState({
        buttonAlignment: buttonAlign !== "" ? buttonAlign : "",
      });
    }

    if (this.props && this.props.msgButtonTitle) {
      const msgButtonTitle = this.props.msgButtonTitle;
      this.setState({
        title: msgButtonTitle !== "" ? msgButtonTitle : "",
      });
    }

    if (this.props && this.props.toName) {
      const receiverName = this.props.toName;
      this.setState({
        to: receiverName !== "" ? receiverName : "",
      });
    }
    if (this.props && this.props.prefereAgentName) {
      const receiverName = this.props.prefereAgentName;
      this.setState({
        to: receiverName !== "" ? receiverName : "",
      });
    }

    if (this.props && this.props.mlsId) {
      let mlsIdInfo = this.props.mlsId;
      this.setState({
        mlsId: mlsIdInfo !== "" ? mlsIdInfo : "",
      });
    }
    if (this.props && this.props.agentEmail) {
      let agentEmailInfo = this.props.agentEmail;
      this.setState({
        agentEmail: agentEmailInfo !== "" ? agentEmailInfo : "",
      });
    }
    if (this.props && this.props.preferedAgent) {
      let agentEmailInfo = this.props.preferedAgent;
      this.setState({
        agentEmail: agentEmailInfo !== "" ? agentEmailInfo : "",
      });
    }
    if (this.props && this.props.propertyId) {
      const propertyId = this.props.propertyId;
      this.setState({
        propertyId: propertyId,
      });
    }

    if (this.props && this.props.propertyTitle) {
      const propertyTitle = this.props.propertyTitle;
      this.setState({
        subject: propertyTitle,
      });

      let msg =
        this.props &&
        !this.props.AgentMessage &&
        "I am interested in knowing more about " +
          propertyTitle +
          ".\n" +
          "Please contact me at my details above.\nThank you.\n";

      this.handleMessage(msg);
    }

    if (this.props && this.props.context && this.props.context.state) {
      if (
        this.props.context.state.userDetails &&
        this.props.context.state.userDetails
      ) {
        const userDetails = this.props.context.state.userDetails;
        this.setState({
          from: userDetails.firstName + " " + userDetails.lastName,
          email: userDetails.email,
          phone: userDetails.phone,
          disableEdit: true,
        });
      }
      //Don't delete.
      // if (
      //   this.props.context.state.authenticateOffMLS &&
      //   this.props.context.state.authenticateOffMLS
      // ) {
      //   const agentDetails = this.props.context.state.authenticateOffMLS;
      //   // this.setState({
      //   //   subject: agentDetails.title !== "" ? agentDetails.title : "",
      //   // });
      //   let msg =
      //     this.props &&
      //     !this.props.AgentMessage &&
      //     "I am interested in knowing more about " +
      //       agentDetails.title +
      //       ".\n" +
      //       "Please contact me at my details below.\nThank you.\n ";
      //   this.handleMessage(msg);
      // }
    }

    // this.getAllAgents();
  }

  handleToshowLoginSider = () => {
    this.setState({
      showLoginSiderForm: true,
    });
  };

  handletoShowSignupsider = () => {
    this.setState({
      showLoginSiderForm: false,
    });
  };
  handleMessage = (msg) => {
    let agentMsg =
      "I am interested in hiring you as my agent.\nPlease contact me at my details above.\nThank you.\n";
    if (this.props.page && this.props.page === "buyerAgent") {
      this.setState({
        message: agentMsg !== "" ? agentMsg : "",
      });
    } else {
      this.setState({
        message: msg !== "" ? msg : "",
      });
    }
  };

  showModal = () => {
    const { to, agentEmail } = this.state;
    const { preferedAgent } = this.props;
    let userLogin = localStorage.getItem("userLogin");
    if (preferedAgent && preferedAgent !== "") {
      this.setState({
        visible: true,
        enableSignupForm: userLogin ? false : true,
      });
    } else {
      if (userLogin) {
        if (to !== "" && agentEmail !== "") {
          this.setState({
            visiblePreferedAgent: false,
            visible: true,
          });
        } else {
          // if (!buyerLoginSendMessage) {
          this.setState({
            visiblePreferedAgent: false,
            visible: true,
          });
          // } else {
          //   this.setState({
          //     visiblePreferedAgent: true,
          //   });
          //   notification["warning"]({
          //     message: "You don't have an agent to share",
          //   });
          // }
        }
      } else {
        this.setState({
          enableSignupForm: true,
        });
      }
    }
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      // to: "",
      // from: "",
      // email: "",
      // phone: "",
      // subject: "",
      // message: "",
      visiblePreferedAgent: false,
    });
  };
  handleReset = () => {
    this.setState({
      enableSignupForm: false,
    });
  };

  handleMailForm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSendMail = async () => {
    // else if (this.state.subject == "") {
    //   alert("Please enter subject");
    //   return;
    // }
    this.setState({
      loading:true
    })
    const { userDetails } = this.props.context.state;

    let orgId = JSON.parse(localStorage.getItem("orgId"));

    if (this.state.to === "") {
      alert("Please enter to address");
      return;
    } else if (this.state.from === "") {
      alert("Please enter from address");
      return;
    } else if (this.state.email === "") {
      alert("Please enter email");
      return;
    } else if (this.state.message === "") {
      alert("Please enter message");
      return;
    } else {
      // const allUsers = await noAuthlist("getAgents");

      // let validateAgent = false;
      const {
        propertyImages,
        mlsAgentId,
        agentId,
        prefereAgentName,
        preferedAgent,
        buyerLoginSendMessage,
        prefereAgentDetails,
      } = this.props;

      const { assignAgentId } = this.state
      // if (
      //   this.state.mlsId !== "" &&
      //   this.state.mlsId !== null &&
      //   this.state.mlsId !== undefined
      // ) {
      //   validateAgent =
      //     allUsers.data.findIndex((i) => i.mlsId === this.state.mlsId) > -1;
      // }
      const data = {
        entityData: {
          orgId,
          userId:userDetails.id,
          to:
            buyerLoginSendMessage && buyerLoginSendMessage
              ? this.state.to
                ? this.state.to
                : prefereAgentName
              : this.state.to,
          from: this.state.from,
          email: this.state.email,
          // agentEmail: process.env.REACT_APP_AGENT_EMAIL,
          agentEmail:
            buyerLoginSendMessage && buyerLoginSendMessage
              ? this.state.agentEmail
                ? this.state.agentEmail
                : preferedAgent
              : this.state.agentEmail && this.state.agentEmail !== ""
              ? this.state.agentEmail
              : null,

          propertyId: this.state.propertyId,
          // propertyTitle={singleData && singleData.title}
          propertyImgSrc:
            propertyImages && propertyImages.length > 0
              ? typeof propertyImages === "string"
                ? propertyImages
                : propertyImages[0]
              : null,
          phone: this.state.phone,
          subject: this.state.subject,
          message: this.state.message,
          isRegistred:
            agentId !== null && agentId !== undefined && agentId !== ""
              ? true
              : false,
          messageType: this.state.pageType !== "" ? this.state.pageType : null,
          stateName: this.state.stateName,
          // isRegistredUser: this.state.isRegistredUser
          relatedTo:
            agentId !== null && agentId !== undefined && agentId !== ""
              ? agentId
              : mlsAgentId,
          reqFirstName:userDetails.firstName,
          reqLastName:userDetails.lastName
        },
      };
      let response = "";
      data["entityData"]["assignedStatus"] = "unassigned";

      // console.log(data,"dataa");
      // return

      let orgnizationDetails = JSON.parse(localStorage.getItem("orgDetails"));
    
      let senderConfig = process.env.REACT_APP_API_GATEWAY.indexOf("dev") > -1 ? "poweredbyagents.com" :orgnizationDetails["websiteName"] 
  
      if((prefereAgentDetails && prefereAgentDetails?.preferedId !== "" ) || assignAgentId !== ""){

        data["entityData"]["relatedTo"] = prefereAgentDetails.preferedId;
        data["entityData"]["isRegistred"] = true;
        data["entityData"]["agentId"] = prefereAgentDetails.preferedId;
        data["entityData"]["isAssigned"] = true;
        data["entityData"]["assignedStatus"] = "pending";
      }

      // AGENT WELCOM EMAIL DATA
      data["entityData"]["webDomainName"]= window.location.hostname;
      data["entityData"]["orgnizationLogo"]= `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${orgnizationDetails?.Header?.headerLogo}`;
      data["entityData"]["footerBackgrounndColor"]= orgnizationDetails["footer"]["copyRight"]["backgroundColor"];
      data["entityData"]["footerTextColor"]= orgnizationDetails["footer"]["copyRight"]["lastFootertextColor"];
      data["entityData"]["faceBookUrl"]= orgnizationDetails["footer"]["tabs"]["fbUrl"];
      data["entityData"]["twitterUrl"]= orgnizationDetails["footer"]["tabs"]["twitterUrl"];
      data["entityData"]["youtubeUrl"]= orgnizationDetails["footer"]["tabs"]["youtubeUrl"];
      data["entityData"]["peterestUrl"]= "https://in.pinterest.com/";
      data["entityData"]["instgramUrl"]= orgnizationDetails["footer"]["tabs"]["instaUrl"];

      data["entityData"]["orgnizationName"] = orgnizationDetails["orgName"];
      data["entityData"]["websiteUrl"] ="https://" + orgnizationDetails["websiteName"];
      data["entityData"]["senderEmail"] = orgnizationDetails["orgEmailAddress"]+ "@" + senderConfig;

      if (this.state.buttonAlignment === "Contact-Me") {
        response = await noAuthadd("submitContactForm", data);
      } else {
        response = await noAuthadd("sendMail", data);
      }
      if (response.success) {
        this.setState({
          loading: false
        })
        this.handleCancel();
        if (this.props.buyerLoginSendMessage && !this.props.homeCardMsg) {
          this.props.getLoginUserInfo();
        }
        notification["success"]({
          message: response.data.message,
        });
       
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    }
  };
  openSendMessage = (agentInfo) => {
    if (agentInfo && agentInfo.agentName && agentInfo.agentEmail) {
      this.setState({
        to: agentInfo.agentName,
        agentEmail: agentInfo.agentEmail,
        assignAgentId: agentInfo.assignAgentId
      });
    }
    this.setState({
      visible: true,
      visiblePreferedAgent: false,
    });
  };
  render() {
    const {
      visible,
      loading,
      title,
      buttonAlignment,
      showLoginSiderForm,
      visiblePreferedAgent,
    } = this.state;
    let userLogin = localStorage.getItem("userLogin");
    // let userLoginInfo =JSON.parse(localStorage.getItem("userDetails"));
    return (
      <div>
        {buttonAlignment === "right" ? (
          <Button
            type="danger"
            className="button-inner-text-size"
            onClick={this.showModal}
          >
            {title}
          </Button>
        ) : buttonAlignment === "agents" ? (
          <Tooltip title="Message">
            <Button
              shape="circle"
              size="large"
              style={{
                backgroundColor: "#fc572b",
                borderColor: "#fc572b",
                float: "right",
                // cursor: userLoginInfo?.userProfile === "AG" ? "not-allowed" : "pointer"
                // pointerEvents:userLoginInfo?.userProfile === "AG" ? "none" : "auto"

              }}
              onClick={this.showModal}
            >
              <MessageOutlined style={{ color: "white" }} />
            </Button>
          </Tooltip>
        ) : buttonAlignment === "agents-desktop" ? (
          <Button
            className="btn-message-width"
            style={{
              // height: "60%",
              background: "#FC572B",
              borderColor: "#fc572b",
              color: "white",
              // cursor: userLoginInfo?.userProfile === "AG" ? "not-allowed" : "pointer",
              // pointerEvents:userLoginInfo?.userProfile === "AG" ? "none" : "auto"
            }}
            onClick={this.showModal}
          >
            {title}
          </Button>
        ) : buttonAlignment === "Contact-Me" ? (
          <Button
            style={{
              // height: "60%",
              background: this.props.bgColor,
              borderColor: this.props.bgColor,
              color: this.props.color,
              fontWeight: "bold",
              marginTop: "10px",
            }}
            onClick={this.showModal}
          >
            {title}
          </Button>
        ) : (
          <div onClick={this.showModal} style={{ padding: "2px" }}>
            <p
              style={{
                color: "#FC572B",
                cursor: "pointer",
                marginBottom: "0px",
              }}
            >
              {title}
            </p>
          </div>
        )}

        <Modal
          visible={
            userLogin && visiblePreferedAgent ? visiblePreferedAgent : visible
          }
          width={
            window.screen.width >= "1025"
              ? "50vw"
              : window.screen.width > "768" && window.screen.width <= "1024"
              ? "65vw"
              : "90vw"
          }
          onCancel={this.handleCancel}
          className="buyer-agent-modal send-message-modal"
          footer={null}
          title={
            userLogin && visiblePreferedAgent ? "Add your Agent to share" : null
          }
        >
          {!visiblePreferedAgent ? (
            <>
              <div
                style={{
                  backgroundColor: "#414555",
                  padding: "5px",
                }}
                className="messege-box-header-margin"
              >
                <p
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                    fontSize: "14px",
                    marginTop: "10px",
                  }}
                >
                  {title}
                </p>
              </div>

              <div className="messege-for-mobile-view">
                <Row className="padding">
                  <Col
                    // span="4"
                    xl={{ span: 4 }}
                    lg={{ span: 4 }}
                    md={{ span: 4 }}
                    xs={{ span: 5 }}
                    sm={{ span: 5 }}
                  >
                    <span className="color-grey terms">To:</span>
                  </Col>
                  <Col
                    // span="18"
                    //  push={2}
                    xl={{ span: 18, offset: 2 }}
                    lg={{ span: 18, offset: 2 }}
                    md={{ span: 18, offset: 2 }}
                    xs={{ span: 18, offset: 1 }}
                    sm={{ span: 18, offset: 1 }}
                  >
                    <Input
                      disabled
                      name="to"
                      placeholder="To"
                      className="form-input"
                      value={this.state.to}
                      onChange={this.handleMailForm}
                      style={{
                        border: "1px solid #fff",
                        color: "black",
                        background: "white",
                      }}
                    />
                  </Col>
                </Row>

                <Divider style={{ margin: "5px" }}></Divider>

                <Row className="padding">
                  <Col
                    // span="4"
                    xl={{ span: 4 }}
                    lg={{ span: 4 }}
                    md={{ span: 4 }}
                    xs={{ span: 5 }}
                    sm={{ span: 5 }}
                  >
                    <span className="color-grey  terms">From:</span>
                  </Col>
                  <Col
                    xl={{ span: 18, offset: 2 }}
                    lg={{ span: 18, offset: 2 }}
                    md={{ span: 18, offset: 2 }}
                    xs={{ span: 18, offset: 1 }}
                    sm={{ span: 18, offset: 1 }}
                    // span="18" push={2}
                  >
                    <Input
                      disabled={this.state.disableEdit}
                      name="from"
                      placeholder="From"
                      className="form-input"
                      value={this.state.from}
                      onChange={this.handleMailForm}
                      style={{
                        border: "1px solid #fff",
                        color: "black",
                        background: "white",
                      }}
                    />
                  </Col>
                </Row>

                <Divider style={{ margin: "5px" }}></Divider>

                <Row className="padding">
                  <Col
                    //  span="4"
                    xl={{ span: 4 }}
                    lg={{ span: 4 }}
                    md={{ span: 4 }}
                    xs={{ span: 5 }}
                    sm={{ span: 5 }}
                  >
                    <span className="color-grey terms">Email:</span>
                  </Col>
                  <Col
                    // span="18" push={2}
                    xl={{ span: 18, offset: 2 }}
                    lg={{ span: 18, offset: 2 }}
                    md={{ span: 18, offset: 2 }}
                    xs={{ span: 18, offset: 1 }}
                    sm={{ span: 18, offset: 1 }}
                  >
                    <Input
                      disabled={this.state.disableEdit}
                      name="email"
                      placeholder="Enter Email"
                      className="form-input"
                      value={this.state.email}
                      onChange={this.handleMailForm}
                      style={{
                        border: "1px solid #fff",
                        color: "black",
                        background: "white",
                      }}
                    />
                  </Col>
                </Row>

                <Divider style={{ margin: "5px" }}></Divider>

                <Row className="padding">
                  <Col
                    // span="4"
                    xl={{ span: 4 }}
                    lg={{ span: 4 }}
                    md={{ span: 4 }}
                    xs={{ span: 5 }}
                    sm={{ span: 5 }}
                  >
                    <span className="color-grey terms">Phone:</span>
                  </Col>
                  <Col
                    // span="18" push={2}
                    xl={{ span: 18, offset: 2 }}
                    lg={{ span: 18, offset: 2 }}
                    md={{ span: 18, offset: 2 }}
                    xs={{ span: 18, offset: 1 }}
                    sm={{ span: 18, offset: 1 }}
                  >
                    <Input
                      disabled={this.state.disableEdit}
                      name="phone"
                      placeholder="Enter phone"
                      className="form-input"
                      value={this.state.phone}
                      onChange={this.handleMailForm}
                      style={{
                        border: "1px solid #fff",
                        color: "black",
                        background: "white",
                      }}
                    />
                  </Col>
                </Row>

                <Divider style={{ margin: "5px" }}></Divider>

                <Row className="padding">
                  <Col
                    // span="4"
                    xl={{ span: 4 }}
                    lg={{ span: 4 }}
                    md={{ span: 4 }}
                    xs={{ span: 5 }}
                    sm={{ span: 5 }}
                  >
                    <span className="color-grey terms">Message:</span>
                  </Col>
                  <Col
                    //  span="18" push={2}
                    xl={{ span: 18, offset: 2 }}
                    lg={{ span: 18, offset: 2 }}
                    md={{ span: 18, offset: 2 }}
                    xs={{ span: 18, offset: 1 }}
                    sm={{ span: 18, offset: 1 }}
                  >
                    <TextArea
                      placeholder="Start typing"
                      rows={4}
                      name="message"
                      value={this.state.message}
                      onChange={this.handleMailForm}
                      style={{ border: "1px solid #fff" }}
                    />
                  </Col>
                </Row>

                <Divider style={{ margin: "5px" }}></Divider>
                <div style={{ float: "right" }}>
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={this.handleSendMail}
                    className="send-button-mobile-view"
                  >
                    <h6
                      style={{
                        color: "#FFF",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                    >
                      Send
                    </h6>
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <BuyerPreferedAgentPopup openSendMessage={this.openSendMessage} />
          )}
        </Modal>
        {this.state.enableSignupForm && (
          <Drawer
            className="homecard-drawer"
            title=""
            placement="right"
            width={window.screen.width >= "768" ? "40%" : "100%"}
            mask={true}
            closable={true}
            visible={this.state.enableSignupForm}
            onClose={() => this.handleReset()}
            headerStyle={{ textAlign: "center" }}
            bodyStyle={{
              paddingBottom: 80,
              backgroundColor: "#eff2f5",
              padding: "0px",
            }}
          >
            <Row>
              <Col span={24}>
                {showLoginSiderForm ? (
                  <LoginForm
                    showAdd={false}
                    modalTrue
                    goTopreviousPage={true}
                    handleToshowLoginSider={this.handletoShowSignupsider}
                    showLoginSiderForm={showLoginSiderForm}
                    questionPage
                  />
                ) : (
                  <SignupForm
                    questionPage
                    showAdd={false}
                    modalTrue
                    goTopreviousPage={true}
                    handleToshowLoginSider={this.handleToshowLoginSider}
                    showLoginSiderForm={showLoginSiderForm}
                  />
                )}
              </Col>
            </Row>
          </Drawer>
        )}
      </div>
    );
  }
}

export default withAppContext(withRouter(SendMessage));
