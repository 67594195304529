import React, { useState } from "react";
import { Card, Row, Table, Tag, Col, Button } from "antd";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../../store/contextStore";
import moment from "moment";
import { EditTwoTone, PlusOutlined } from "@ant-design/icons";
import AddContact from "../contactLeads/AddContact";
import NumberFormat from "react-number-format";

function ActivePipelineLeads(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [leads, setLeads] = useState("");
  const [addForm, setAddForm] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleClickRow = (record) => {
    props.history.push(
      `/VirtualOffice/ViewAllContacts/${record.id}`,
      "mypipeLine"
    );
  };

  const showData = (id, record) => {
    setLeads(record);
    setShowModal(true);
  };

  let columns = [
    {
      title: "FOLLOW UP DATE",
      dataIndex: "followDate",
      key: "followDate",
      hide: false,

      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (followDate) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {followDate ? moment(followDate).format("MM/DD/YYYY") : ""}
          </span>
        );
      },
    },
    {
      title: "NAME",
      dataIndex: "firstName",
      key: "firstName",
      hide: false,

      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (firstName, record) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {`${firstName} ${record.lastName}`}
          </span>
        );
      },
    },
    {
      title: "STATUS",
      key: "listingStatus",
      dataIndex: "listingStatus",
      hide: false,

      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (tag) => (
        <Tag
          style={{
            border: "none",
            background:
              tag === "Closed"
                ? "red"
                : tag === "Under Agreement"
                ? "#DCF1FA"
                : tag === "Active Listing"
                ? "#E6E6F8"
                : tag === "Active Buyer"
                ? "#D5F7EF"
                : tag === "Prospective Buyer"
                ? "#FFE2E2"
                : tag === "Prospective Seller"
                ? "#E5CCFF"
                : tag === "Prospective seller or buyer"
                ? "#FFE8DE"
                : tag === "Went cold but didn't tell me no"
                ? "#FFF3E0"
                : tag === "Database or Past Clients" && "#ECECEB",

            color:
              tag === "Closed"
                ? "#FFF"
                : tag === "Under Agreement"
                ? "#34ACE0"
                : tag === "Active Listing"
                ? "#706FD3"
                : tag === "Active Buyer"
                ? "#33D9B2"
                : tag === "Prospective Buyer"
                ? "#FF5252"
                : tag === "Prospective Seller"
                ? "#9933FF"
                : tag === "Prospective seller or buyer"
                ? "#FF793F"
                : tag === "Went cold but didn't tell me no"
                ? "#FFB142"
                : tag === "Database or Past Clients" && "#84817A",
          }}
          key={tag}
        >
          <span
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              fontFamily: "Poppins",
            }}
          >
            {tag === "Prospective seller or buyer"
              ? "Prospective Seller and Buyer".toUpperCase()
              : tag?.toUpperCase()}
          </span>
        </Tag>
      ),
    },

    {
      title: "LEAD SOURCE",
      dataIndex: "sourceLead",
      key: "sourceLead",
      hide: false,

      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (sourceLead) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {sourceLead}
          </span>
        );
      },
    },
    {
      title: "JOINING DATE",
      dataIndex: "registered",
      key: "registered",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (registered) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {registered ? moment(registered).format("L") : ""}
          </span>
        );
      },
    },
    {
      title: "LAST ACTIVE DATE",
      dataIndex: "lastActiveDate",
      key: "registered",
      onCell: (record) => ({
        onClick: () => handleClickRow(record),
      }),
      render: (lastActiveDate) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {/* {lastActiveDate ? lastActiveDate : ""} */}
            {lastActiveDate
              ? moment.unix(lastActiveDate).format("MM/DD/YYYY hh:mm A")
              : ""}
          </span>
        );
      },
    },

    {
      title: "EDIT",
      dataIndex: "id",
      key: "id",
      sorter: false,
      hide: userDetails?.userProfile === "AG" ? false : true,
      render: (id, record) => {
        return (
          <span onClick={() => showData(id, record)}>
            <EditTwoTone style={{ fontSize: "20px" }} />
          </span>
        );
      },
    },
  ];
  columns = columns.filter((eachRecord) => !eachRecord.hide);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleFooterTotal = (leadRecords) => {
    let totalInc = 0;
    // console.log("leadRecords==>", leadRecords);
    let priceCountData = leadRecords.filter(oo=>"listPrice" in oo === true && "buyPrice" in oo === true)
    let listPriceInc = priceCountData.reduce((a, v) => a + v.listPrice, 0);
    let buyerPriceInc = priceCountData.reduce((a, v) => a + v.buyPrice, 0);
    totalInc = parseFloat(listPriceInc + buyerPriceInc)
       
    // console.log(listPriceInc, buyerPriceInc, "tota=>", totalInc);

    return (
      <Row justify="end" style={{ padding: "0px", marginRight: "5px" }}>
        <span
          style={{
            textTransform: "capitalize",
            fontWeight: "500",
            float: "right",
            fontSize: "18px",
            padding: "5px 0 5px 0",
          }}
        >
          <span>Total: </span>
          <NumberFormat
            value={parseFloat(totalInc).toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </span>
      </Row>
    );
  };

  let pipelineLeads = props?.leads;
  return (
    <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
      <Row gutter={window.screen.width > 996 && [24, 24]}>
        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          className="mobile_view_margin_Top"
          style={{ marginBottom: "1vw" }}
        >
          <Card
            title="Pipeline Leads"
            className="card_virtual"
            hoverable={true}
            bodyStyle={{ padding: "0px", borderTop: "1px solid #ebebeb" }}
            extra={
              <Row gutter={[20, 0]}>
                <Col>
                  <Button
                    onClick={() => props.getUserProfile()}
                    className="todo_button"
                    style={{ border: "1px solid #3045d2" }}
                  >
                    <span className="virtual_button_text btn-color">
                      Show All
                    </span>
                  </Button>
                </Col>
                <Col>
                  <Button
                    icon={<PlusOutlined style={{ color: "#3045d2" }} />}
                    onClick={() => {
                      setAddForm(true);
                      setShowModal(true);
                    }}
                    className="todo_button"
                    style={{ border: "1px solid #3045d2" }}
                  >
                    <span className="virtual_button_text btn-color">
                      ADD LEAD
                    </span>
                  </Button>
                </Col>
              </Row>
            }
          >
            <Table
              className="table_conatcts"
              columns={columns}
              dataSource={pipelineLeads}
              loading={loading}
              pagination={{
                total: pipelineLeads,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                position: ["bottomLeft"],
              }}
              style={{ overflow: "auto" }}
              onScroll={true}
              footer={() => handleFooterTotal(pipelineLeads)}
            />
          </Card>
        </Col>
      </Row>

      {
        showModal && (
          <AddContact
            showModal={showModal}
            proConatct={leads}
            loadig={loading}
            onCancel={handleCancel}
            editModal={addForm ? false : showModal}
            getUserProfile={props.getUserProfile}
            setLoading={setLoading}
          />
        )
        // openModal(showModal, loading, leads)
      }
    </div>
  );
}
export default withAppContext(withRouter(ActivePipelineLeads));
