import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Table,
  Card,
  Row,
  Col,
  Button,
  // Menu,
  Modal,
  Select,
  Input,
  Divider,
} from "antd";
import moment from "moment";
import { add, list, update } from "../../../api/Apis";
import Loading from "../../../common/Loading";
const { Option } = Select;
const Search = Input.Search;

class MissedLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      activeTabKey: 1,
      referedAgentId: "",
      referedAgentName: "",
      selectedProperty: "",
      referedAgentMail: "",
      dataLoadingStatus: false,
      enableViewLead: false,
      enableAssignLead: false,

      pendingLeads: [],
      inProgressLeads: [],
      completedLeads: [],
      agentsList: [],
      totalMissedLeads: [],
      defaultPageNum: 1
    };
  }

  componentDidMount = () => {
    this.getAllAgents();
    this.getAllLeads();
  };

  getAllAgents = async () => {
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));

    let query = {
      userProfile: "AG",
      orgId: orgDetails?.id,
      isActive: true,
    };
    list("users", query).then((result) => {
      if (result.success) {
        let singleAgent = result.data.map((user) => {
          return {
            value: user.id,
            label: user.firstName + " " + user.lastName,
            email: user.email,
            userData: user,
          };
        });
        this.setState({
          dataLoadingStatus: false,
          agentsList: singleAgent,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  getAllLeads = () => {
    this.setState({
      dataLoadingStatus: true,
      isLoading: true,
    });
    const orgId = JSON.parse(localStorage.getItem("orgId"));

    let query = {};
    query = {
      orgId,
      assignedStatus: ["pending"],
      isAssigned: false,
      isMissedLead: true,
    };
    list(`getMissedLeads`, query).then((result) => {
      if (result.success) {
        // let pendingList = [];
        let inProgressList = [];
        let completedList = [];
        // console.log(result);
        // result.data.filter((lead) => {

        //   if ((!lead.isAssigned || lead.assignedStatus === "pending") && lead.notificationType !== "contactLead") {
        //     pendingList.push(lead);
        //     // } else if (
        //     //   lead.isAssigned === true &&
        //     //   lead.assignedStatus === "pending"
        //     // ) {
        //     //   inProgressList.push(lead);
        //     // } else {
        //     //   completedList.push(lead);
        //     // }
        //   }
        //   return true;
        // });
        // console.log(pendingList,"pendingList");
        this.setState({
          pendingLeads: result.data,
          totalMissedLeads: result.data,
          inProgressLeads: inProgressList,
          completedLeads: completedList,
          dataLoadingStatus: false,
          isLoading: false,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  onReferedBySelectAgent = (id) => {
    const selectedAgentDetails = this.state.agentsList.filter(
      (agent) => agent.value === id
    );

    this.setState({
      referedAgentName: selectedAgentDetails[0].label,
      referedAgentId: selectedAgentDetails[0].value,
      referedAgentMail: selectedAgentDetails[0].email,
    });
  };

  assignAgent = () => {
    const {
      referedAgentId,
      selectedProperty,
      referedAgentName,
      referedAgentMail,
    } = this.state;
    // console.log(selectedProperty,"_______");
    // return
    this.setState({
      dataLoadingStatus: true,
    });
    let orgnizationDetails = JSON.parse(localStorage.getItem("orgDetails"));

    let senderConfig =
      process.env.REACT_APP_API_GATEWAY.indexOf("dev") > -1
        ? "poweredbyagents.com"
        : orgnizationDetails["websiteName"];

    const reAssginTime = moment(selectedProperty.schedule_date_time).format();
    let submitData = {
      entityData: {
        propertyId: selectedProperty.propertyId,
        propertyTitle: selectedProperty.propertyTitle,
        propertyImgSrc: selectedProperty.propertyImgSrc,

        userName: selectedProperty.requestorDetails.name,
        userPhone: selectedProperty.requestorDetails.phone,
        userEmail: selectedProperty.requestorDetails.email,

        isRegistred: true,

        agentId: referedAgentId,
        agentName: referedAgentName,

        agentEmail: referedAgentMail,

        relatedTo: referedAgentId,
        stateType: selectedProperty.stateType,
        orgId: selectedProperty.orgId,

        // TEMPLATE DATA
        webDomainName: window.location.hostname,
        orgnizationLogo: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${orgnizationDetails?.Header?.headerLogo}`,
        footerBackgrounndColor:
          orgnizationDetails["footer"]["copyRight"]["backgroundColor"],
        footerTextColor:
          orgnizationDetails["footer"]["copyRight"]["lastFootertextColor"],
        faceBookUrl: orgnizationDetails["footer"]["tabs"]["fbUrl"],
        twitterUrl: orgnizationDetails["footer"]["tabs"]["twitterUrl"],
        youtubeUrl: orgnizationDetails["footer"]["tabs"]["youtubeUrl"],
        peterestUrl: "https://in.pinterest.com/",
        instgramUrl: orgnizationDetails["footer"]["tabs"]["instaUrl"],

        orgnizationName: orgnizationDetails["orgName"],
        websiteUrl: "https://" + orgnizationDetails["websiteName"],
        senderEmail: orgnizationDetails["orgEmailAddress"] + "@" + senderConfig,
      },
    };
    if (selectedProperty.notificationType === "schedule") {
      submitData["entityData"]["createdBy"] = "user";
      submitData["entityData"]["scheduleStatus"] = "P";
      submitData["entityData"]["scheduleDate"] = moment(
        selectedProperty.schedule_date_time
      ).unix();
      submitData["entityData"]["scheduleTime"] = moment(reAssginTime).unix();
      submitData["entityData"]["formatedDateAndTime"] =
        selectedProperty.schedule_date_time;
      submitData["entityData"]["message"] = selectedProperty.message;
      submitData["entityData"]["scheduleHistory"] = [];
      submitData["entityData"]["typeOfSchedule"] = "listingSchedule";
      submitData["entityData"]["notificationType"] =
        selectedProperty.notificationType;
    } else if (selectedProperty.notificationType === "question") {
      submitData["entityData"]["message"] = selectedProperty.message;
      submitData["entityData"]["notificationType"] =
        selectedProperty.notificationType;
    } else {
      submitData["entityData"]["message"] = selectedProperty.message;
      submitData["entityData"]["notificationType"] =
        selectedProperty.notificationType;
    }
    // console.log(submitData,"jjjjjj");
    // return
    this.handleAssignLeadToContact(submitData, selectedProperty);
  };

  handleAssignLeadToContact = (assignData, requesterData) => {
    assignData = assignData["entityData"];
    let contactPhone =
      typeof requesterData["requestorDetails"]["phone"] === "number"
        ? requesterData["requestorDetails"]["phone"]
        : requesterData["requestorDetails"]["phone"].replace(/[^+0-9]/g, "");
    let leadContactObj = {
      firstName: requesterData["requestorDetails"]["name"],
      listingStatus: "Active Listing",
      timeframe: "",
      lastName: requesterData["requestorDetails"]["lastName"],
      registered: requesterData["createdAt"],
      typeofProperty: "",
      favoriteCity: [],
      emailAddress: requesterData["requestorDetails"]["email"],
      secondaryEmail: requesterData["requestorDetails"]["email"],
      cellPhone: parseInt(contactPhone),
      referredByClient: assignData["agentName"],
      sellerSideCommission: 2.25,
      sellerSideReferralFee: 0,
      closeDate: "",
      WorkPhone: "",
      sourceLead: "Office Lead",
      buyerSideCommission: 2.25,
      buyerSideReferralFee: 0,
      followDate: "",
      picture: "",
      address: {
        citySearch: "",
        city: "",
        addressState: "",
        zipcode: "",
      },
      homeAddress: {
        city: "",
        homeAddressState: "",
        homezipcode: "",
        homeCitySearch: [],
      },
      privateNotes: "",
      notes: assignData["message"],
      contactType: "Contact",
      businessCard: "",
      userId: assignData["agentId"],
      buysidepricepoint: "",
      listsidepricepoint: "",
      orgId: assignData["orgId"],
      agentDetails: {
        full_name: assignData["agentName"],
        agent_id: assignData["agentId"],
        email: assignData["agentEmail"],
        orgId: assignData["orgId"],
      },
      id: requesterData["id"],
    };
    let agentInfo = {
      agentName: assignData["agentName"],
      agentEmail: assignData["agentEmail"],
      assignAgentId: assignData["agentId"],
    };
    let payLoad = {
      entityData: leadContactObj,
    };
    //     console.log(assignData,"paytloadd");
    // return
    add("virtualContactLeads", payLoad)
      .then((result) => {
        if (result.success) {
          let assignPayLoad = {
            entityData: assignData,
          };
          if (result.data.message === "Failure") {
            alert(result.data.data);
          }
          update("assignProperty", requesterData.id, assignPayLoad).then(
            (result) => {
              if (result.success) {
                this.resetValues();
                this.getAllLeads();

                let userPayload = {
                  entityData: {
                    addUserName: assignData["agentName"],
                    agentInfo,
                  },
                };
                update("users", requesterData["userId"], userPayload).then(
                  (r) => {
                    if (r.success) {
                      this.resetValues();
                      this.getAllLeads();
                      this.setState({
                        dataLoadingStatus: false,
                      });
                    }
                  }
                );
              } else {
                this.resetValues();
                alert("Something went wrong!");
                console.log(result.errors);
                this.setState({
                  dataLoadingStatus: false,
                });
              }
            }
          );
          // }
        }
      })
      .catch((err) => {
        this.resetValues();

        console.log(err);
      });
  };

  resetValues = () => {
    this.setState({
      referedAgentId: "",
      referedAgentName: "",
      selectedProperty: "",
      referedAgentMail: "",
      enableViewLead: false,
      enableAssignLead: false,
      dataLoadingStatus: false,
    });
  };

  leadStatus = () => {
    return (
      <span
        style={{
          textTransform: "capitalize",
          backgroundColor: "#f05150",
          // this.state.activeTabKey === "1"
          //   ? "#f05150"
          //   : this.state.activeTabKey === "2"
          //   ? "#5bc0de"
          //   : "#27c24c",
          fontWeight: "bold",
          padding: "5px 10px",
          color: "#fff",
        }}
      >
        {/* {assignedStatus === "pending" && "Un-Assigned"} */}
        {/* // : this.state.activeTabKey === "2"
          // ? "Assigned"
          // : "Completed"} */}
        Un-Assigned
      </span>
    );
  };

  selectedPropertyInfo = () => {
    const { selectedProperty } = this.state;
    return (
      <div style={{ backgroundColor: "#f9fafb", padding: "20px 20px 30px" }}>
        <Row>
          <Col span={24} style={{ padding: "20px 20px 0px 20px" }}>
            <h4 style={{ color: "#aaa" }}>Property title :</h4>

            <a
              target="_blank"
              href={`${window.location.origin}homeDetails/${selectedProperty.stateType}/${selectedProperty.propertyId}`}
              rel="noopener noreferrer"
            >
              <h2
                style={{
                  cursor: "pointer",
                  color: "#3045d2",
                  textDecoration: "underline",
                }}
              >
                {selectedProperty.propertyTitle}
              </h2>
            </a>
          </Col>
        </Row>

        <Row style={{ display: "block" }}>
          <Col span={24} style={{ padding: "20px" }}>
            <h4 style={{ color: "#aaa" }}>Message :</h4>
            <h3>{selectedProperty.message}</h3>
          </Col>
        </Row>

        {selectedProperty.notificationType === "schedule" && (
          <Row style={{ display: "block" }}>
            <Col span={24} style={{ padding: "20px" }}>
              <h4 style={{ color: "#aaa" }}>Schedule date {"&"} time :</h4>
              <h3>{selectedProperty.schedule_date_time}</h3>
            </Col>
          </Row>
        )}
        <Row gutter={[24, 24]}>
          <Col span={12} style={{ padding: "10px 10px 10px 20px" }}>
            {selectedProperty && selectedProperty.requestorDetails && (
              <Card>
                <Row style={{ display: "block" }}>
                  <h4 style={{ color: "#aaa" }}>Requester Name</h4>
                  <h3>
                    {selectedProperty.requestorDetails.name !== "" &&
                    selectedProperty.requestorDetails.name !== null &&
                    selectedProperty.requestorDetails.name !== undefined
                      ? selectedProperty.requestorDetails.name
                      : "N/A"}
                  </h3>
                </Row>
                <Row style={{ display: "block" }}>
                  <h4 style={{ color: "#aaa" }}>Requester E-Mail</h4>
                  <h3>
                    {selectedProperty.requestorDetails.email !== "" &&
                    selectedProperty.requestorDetails.email !== null &&
                    selectedProperty.requestorDetails.email !== undefined
                      ? selectedProperty.requestorDetails.email
                      : "N/A"}
                  </h3>
                </Row>
                <Row style={{ display: "block" }}>
                  <h4 style={{ color: "#aaa" }}>Requester Phone</h4>
                  <h3>
                    {selectedProperty.requestorDetails.phone !== "" &&
                    selectedProperty.requestorDetails.phone !== null &&
                    selectedProperty.requestorDetails.phone !== undefined
                      ? selectedProperty.requestorDetails.phone
                      : "N/A"}
                  </h3>
                </Row>
              </Card>
            )}
          </Col>
          <Col span={12} style={{ padding: "10px 20px 10px 10px" }}>
            {selectedProperty && selectedProperty.agentDetails && (
              <Card>
                <Row style={{ display: "block" }}>
                  <h4 style={{ color: "#aaa" }}>Requested Date</h4>
                  <h3>
                    {moment(selectedProperty.createdAt).format(
                      "MM-DD-YYYY H:M a"
                    )}
                  </h3>
                </Row>
                <Row style={{ display: "block" }}>
                  <h4 style={{ color: "#aaa" }}>Listed Agent Name</h4>
                  <h3>
                    {selectedProperty.agentDetails.name !== "" &&
                    selectedProperty.agentDetails.name !== null &&
                    selectedProperty.agentDetails.name !== undefined
                      ? selectedProperty.agentDetails.name
                      : "N/A"}
                  </h3>
                </Row>
                <Row style={{ display: "block" }}>
                  <h4 style={{ color: "#aaa" }}>Listed Agent E-Mail</h4>
                  <h3>
                    {selectedProperty.agentDetails.email !== "" &&
                    selectedProperty.agentDetails.email !== null &&
                    selectedProperty.agentDetails.email !== undefined
                      ? selectedProperty.agentDetails.email
                      : "N/A"}
                  </h3>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  agentsListView = () => {
    const { agentsList, referedAgentName } = this.state;
    return (
      <Row style={{ display: "block" }}>
        <h4 style={{ color: "#aaa", marginTop: "20px" }}>
          Assign to the Agent
        </h4>
        <Select
          style={{ width: "100%" }}
          // className="input-width"
          showSearch
          optionFilterProp="children"
          options={agentsList}
          onSelect={(e) => this.onReferedBySelectAgent(e)}
          filterOption={(inputValue, option) =>
            option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
          }
          value={referedAgentName}
        >
          {agentsList.map((agent) => (
            <Option key={agent.value} value={agent.value}>
              {agent.label}
            </Option>
          ))}
        </Select>
      </Row>
    );
  };

  resultView = (type, icon, color, title, result) => {
    return (
      <Card className="mob-card" hoverable>
        <Row justify="space-around">
          <Col
            span={8}
            style={{
              backgroundColor: type === "ads" ? "#dce3e9" : "#ecf0f3",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            <i
              class={`fa ${icon}`}
              style={{
                fontSize: "80px",
                color: color,
              }}
            ></i>
          </Col>
          <Col span={14} offset={2}>
            <div>
              <h4 className={type === "ads" ? "mid-title" : "sub-title"}>
                {title}
              </h4>
              <h1 className="result-small">{result}</h1>
            </div>
          </Col>
        </Row>
      </Card>
    );
  };

  searchData = (event, searchType) => {
    let totalMissedLeads = [];
    let searchValue = "";

    searchValue = event.target.value?.trim().toLowerCase();
    if (searchType === "text") {
      totalMissedLeads = this.state.totalMissedLeads?.filter(
        (lead) =>
          lead?.notificationType?.toLowerCase().indexOf(searchValue) > -1 ||
          lead?.agentDetails?.name?.toLowerCase().indexOf(searchValue) > -1 ||
          lead?.requestorDetails?.lastName?.toLowerCase().indexOf(searchValue) >
            -1 ||
          lead?.requestorDetails?.name?.toLowerCase().indexOf(searchValue) >
            -1 ||
          lead?.propertyTitle?.toLowerCase().indexOf(searchValue) > -1 ||
          (lead?.notificationType?.toLowerCase() === "schedule" &&
            "requestashowing".indexOf(searchValue) > -1) ||
          (lead?.notificationType === "buyer_interest" &&
            "buyerinterest".indexOf(searchValue) > -1) ||
          (lead?.notificationType === "question" &&
            "question".indexOf(searchValue) > -1)
      );
    } else {
      totalMissedLeads = this.state.totalMissedLeads;
    }
    // console.log(totalMissedLeads,"ttttttt");
    this.setState({
      pendingLeads: totalMissedLeads,
      defaultPageNum: 1
    });
  };
  handleleadType = (value,option)=>{
    let totalLeads = []
    if(value !== "All"){
      totalLeads = this.state.totalMissedLeads.filter(lead=>lead.notificationType === value)
    }else{
      totalLeads = this.state.totalMissedLeads
    }
    this.setState({
      pendingLeads: totalLeads
    })
  }

  render() {
    const columns = [
      {
        title: "LEAD TYPE",
        dataIndex: "notificationType",
        key: "notificationType",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              selectedProperty: record,
              enableViewLead: true,
            });
          },
        }),
        render: (notificationType) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color:
                  notificationType === "schedule"
                    ? "#3045d2"
                    : notificationType === "question"
                    ? "#f7d131"
                    : "#3abe15",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {notificationType === "schedule"
                ? "Request a Showing"
                : notificationType === "question"
                ? "Q & A"
                : "Buyer Interest"}
            </span>
          );
        },
      },
      {
        title: "PROPERTY TITLE",
        dataIndex: "propertyTitle",
        key: "propertyTitle",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              selectedProperty: record,
              enableViewLead: true,
            });
          },
        }),
        render: (propertyTitle) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {propertyTitle}
            </span>
          );
        },
      },
      {
        title: "AGENT",
        dataIndex: "agentDetails",
        key: "agentDetails",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              selectedProperty: record,
              enableViewLead: true,
            });
          },
        }),
        render: (agentDetails) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {agentDetails && agentDetails.name ? agentDetails.name : ""}
            </span>
          );
        },
      },

      {
        title: "CREATED DATE",
        dataIndex: "createdAt",
        key: "createdAt",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              selectedProperty: record,
              enableViewLead: true,
            });
          },
        }),
        render: (createdAt) => {
          return <span>{moment(createdAt).format("MM-DD-YYYY H:M a")}</span>;
        },
      },
      {
        title: "REQUESTORE NAME",
        dataIndex: "requestorDetails",
        key: "requestorDetails",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              selectedProperty: record,
              enableViewLead: true,
            });
          },
        }),
        render: (requestorDetails) => {
          return <span>{requestorDetails?.name}</span>;
        },
      },
      {
        title: "STATUS",
        dataIndex: "assignedStatus",
        key: "assignedStatus",
        onCell: (record) => ({
          onClick: () => {
            this.setState({
              selectedProperty: record,
              enableViewLead: true,
            });
          },
        }),
        render: this.leadStatus,
      },
      // {
      //   title: "Details",
      //   key: "id",
      //   dataIndex: "id",
      //   show: this.state.show,
      //   render: (id, record) => (
      //     <Dropdown
      //       overlay={menu(id, record)}
      //       placement="bottomRight"
      //       className="orders-dropdown"
      //     >
      //       <Button>...</Button>
      //     </Dropdown>
      //   ),
      // },
    ];

    const {
      dataLoadingStatus,
      enableViewLead,
      pendingLeads,
      selectedProperty,
      isLoading,
      defaultPageNum,
    } = this.state;
    return (
      <>
        <div style={{ padding: window.screen.width > 996 ? "24px" : "0px" }}>
          <Loading enableLoading={isLoading} />
          <Row gutter={window.screen.width > 996 && [24, 24]}>
            <Col
              xl={{ span: 7, offset: 0 }}
              lg={{ span: 7, offset: 0 }}
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              className="mobile_view_margin_Top"
            >
              <Search
                className="search"
                size="large"
                placeholder="Search"
                onChange={(event) => this.searchData(event, "text")}
              />
            </Col>
            <Col
              xl={{ span: 5, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              className="mobile_view_margin_Top"
            >
              <Select
                style={{ width: "100%",}}
                onChange={this.handleleadType}
                placeholder="Select Lead Type"
                className="select_height"
              >
                  <Option key="1" value="All">
                    All
                  </Option>
                  <Option key="2" value="schedule">
                    Request A Showing
                  </Option>
                  <Option key="3" value="question">
                   Q {"&"} A
                  </Option>
                  <Option key="4" value="buyer_interest">
                    Buyer Interest
                  </Option>
              </Select>
            </Col>

            <Col
              xl={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="mobile_view_margin_Top"
            >
              <Card
                title={window.screen.width > 996 ? "Missed Leads" : ""}
                className="card_virtual"
                hoverable={true}
                bodyStyle={{ padding: "0px" }}
                style={{ overflow: "auto" }}
                // extra={
                //   sendEmailContacts?.selectedContacts?.length > 0 && (
                //     <EmailPopupCom
                //       selectedContacts={sendEmailContacts?.selectedContacts}
                //     />
                //   )
                // }
              >
                <Table
                  columns={columns}
                  dataSource={pendingLeads}
                  loading={dataLoadingStatus}
                  rowKey={(record) => record.id}
                  style={{ cursor: "pointer" }}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100"],
                    total: pendingLeads.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                    current: defaultPageNum,
                    onChange:(e)=>this.setState({defaultPageNum:e})
                  }}
                />
              </Card>
            </Col>

            <Modal
              visible={enableViewLead}
              title="Assign Lead"
              width="50vw"
              style={{ marginTop: "2%" }}
              onCancel={() => this.resetValues()}
              footer={null}
            >
              {this.selectedPropertyInfo()}
              {this.agentsListView()}
              <Row>
                <Divider></Divider>
              </Row>

              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Button
                    type="default"
                    style={{ width: "100%" }}
                    onClick={() => {
                      this.resetValues();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>

                <Col span={12}>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={() => this.assignAgent()}
                    loading={dataLoadingStatus}
                  >
                    Assign
                  </Button>
                </Col>
              </Row>
              {selectedProperty && selectedProperty.userDetails && (
                <div
                  style={{
                    backgroundColor: "#f9fafb",
                    padding: "0px 20px 20px",
                  }}
                >
                  <Row gutter={[24, 24]}>
                    <Col span={12} style={{ padding: "20px 10px 20px 20px" }}>
                      <Card>
                        {selectedProperty && selectedProperty.updatedAt && (
                          <Row>
                            <h4 style={{ color: "#aaa" }}>Re-Assigned Date</h4>
                            <h3>
                              {selectedProperty.updatedAt !== "" &&
                              selectedProperty.updatedAt !== null &&
                              selectedProperty.updatedAt !== undefined
                                ? moment(selectedProperty.updatedAt).format(
                                    "MM-DD-YYYY H:M a"
                                  )
                                : "N/A"}
                            </h3>
                          </Row>
                        )}

                        <Row>
                          <h4 style={{ color: "#aaa" }}>
                            Re-Assigned Agent Name
                          </h4>
                          <h3>
                            {selectedProperty.userDetails.firstName !== "" &&
                            selectedProperty.userDetails.firstName !== null &&
                            selectedProperty.userDetails.firstName !== undefined
                              ? selectedProperty.userDetails.firstName +
                                " " +
                                selectedProperty.userDetails.lastName
                              : "N/A"}
                          </h3>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={12} style={{ padding: "20px 20px 20px 10px" }}>
                      <Card>
                        <Row>
                          <h4 style={{ color: "#aaa" }}>
                            Re-Assigned Agent E-Mail
                          </h4>
                          <h3>
                            {selectedProperty.userDetails.email !== "" &&
                            selectedProperty.userDetails.email !== null &&
                            selectedProperty.userDetails.email !== undefined
                              ? selectedProperty.userDetails.email
                              : "N/A"}
                          </h3>
                        </Row>
                        <Row>
                          <h4 style={{ color: "#aaa" }}>
                            Re-Assigned Agent Phone
                          </h4>
                          <h3>
                            {selectedProperty.userDetails.phone !== "" &&
                            selectedProperty.userDetails.phone !== null &&
                            selectedProperty.userDetails.phone !== undefined
                              ? selectedProperty.userDetails.phone
                              : "N/A"}
                          </h3>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )}
            </Modal>
          </Row>
        </div>
      </>
    );
  }
}
export default withRouter(MissedLeads);
