import React, { Component } from "react";
import {
  Layout,
  Button,
  Row,
  Select,
  Col,
  Card,
  Avatar,
  Modal,
  Form,
  Input,
  Upload,
  Spin,
  Dropdown,
  notification,
  Collapse,
} from "antd";
import "antd/dist/antd.css";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { withRouter, Link } from "react-router-dom";
import {
  CheckCircleOutlined,
  EditOutlined,
  CameraOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import axios from "axios/index";

import BuyerProfileContext from "./BuyerProfileContext";
import { withAppContext } from "../../store/contextStore";
import { noAuthadd, update } from "../../api/Apis";
import HomesCarousel from "../../common/HomesCarousel";
import RichText from "../../common/RichText";
import arrowIcon from "../../../src/images/ArrowIcon.svg";
import arroworangeIcon from "../../../src/images/ArrowOrangeIcon.svg";
import { HeaderButton } from "../../StyledComponents/StyledComponents";
import { ModalTitle } from "../../StyledComponents/StyledComponents.js";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import { Storage } from "aws-amplify";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      citySearch: "",
      displayCityState: "",
      // initialLoading: true,

      cities: {
        searchLocation: [],
        type: "",
        citiesList: [],
      },
      buttonColor: "",
      buttonbgColor: "",
      headerLogo: "",
      showEditModalText: "",
      homeCardImageId: "",
      richtTextBiofield: "",
      titleOfTheCard: "",
      titleOfAboutWebsite: "",
      aboutWebsiteDes: "",
      editImagesLoading: false,
      titleOftheListings: "",
      titleOftheListingsLink: "",
      loading: false,
      localModalTest: false,
    };
  }

  componentDidMount = () => {
    this.getIpAddress();
    // this.getWebsiteData();
  };

  getIpAddress = () => {
      setTimeout(() => {
        this.setState({
          initialLoading: false,
        });
      }, 3500);

    axios
      .get(process.env.REACT_APP_IPADDRESS)
      .then((result) => {
        this.addIpAddress(result.data);
      })
      .catch((error) => console.log("Error :", error));
  };

  addIpAddress = async (data) => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    data["orgId"] = orgId
    const payload = {
      entityData: data,
    };
    try {
      const response = await noAuthadd("ipAddressAnalytics", payload);
      console.log(response);
    } catch (error) {
      console.log("Error :", error);
    }
  };

  goTo = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.history.push(`/register`);
  };

  handleSearches = (values) => {
    this.setState({ search: values });
  };

  handleSingleSearch = (address) => {
    let cityValue = "";
    let stateValue = "";
    let newLocations = [];
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
        }
        const stateCodes = JSON.parse(
          localStorage.getItem("stateCodesFromAdmin")
        );
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateValue.toLowerCase()
        );
        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateValue,
            locationType: "location",
          };
          newLocations.push(newSearchLocation);
          this.setState({
            cities: {
              searchLocation: newLocations,
              type: "location",
              citiesList: [
                ...this.state.cities.citiesList,
                `${stateValue}:${cityValue}`,
              ],
            },
          });
        } else {
          notification["error"]({
            message: "Please enter valid city...",
            description: "You can search cities from MA, CT, RI, NH states",
            placement: "topLeft",
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  enterSeacheHomePage = () => {
    this.props.history.push("/homes");
  };

  enterSeacheBuyerPage = () => {
    this.props.history.push("/buyers");
  };

  handleSearchNow = () => {
    const { search, citySearch, displayCityState, cities } = this.state;
    if (cities && cities.citiesList && cities.citiesList.length > 0) {
      localStorage.setItem("selectedCities", JSON.stringify(cities));
    }
    const filterCitySearch = {
      cities: citySearch,
    };
    // console.log(filterCitySearch, "iii");
    if (filterCitySearch) {
      if (search === "Off MLS Homes") {
        this.props.history.push("/offMLSHomes");
        this.props.context.setCity(citySearch, displayCityState);
      } else if (search === "Buyers") {
        this.props.history.push("/buyers");
      } else {
        this.props.context.setCity(citySearch, displayCityState);
        this.props.history.push("/homes");
      }
      if (filterCitySearch && filterCitySearch.cities !== "") {
        this.props.context.setFilterData(filterCitySearch);
      }
    }
  };

  handleUploadPic = async (info) => {
    this.setState({
      editImagesLoading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                console.log(result.key, "result.key");
                this.setState({
                  homeCardImageId: result.key,
                  editImagesLoading: false,
                });
              });
            } else {
              this.setState({
                editImagesLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  handleRichtextBio = (bio) => {
    this.setState({
      richtTextBiofield: bio,
    });
  };

  handleAboutWebsiteDes = (aboutWebsiteDes) => {
    this.setState({
      aboutWebsiteDes,
    });
  };

  onImageCard = () => {
    const {
      buttonColor,
      buttonbgColor,
      homeCardImageId,
      editImagesLoading,
      textColor,
    } = this.state;
    const { landingScreen } = this.props.context.state.websiteThemes;
    const TextColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={textColor}
          onChange={(e) =>
            this.setState({
              textColor: e.color,
            })
          }
        />
      </div>
    );
    const ButtonColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={buttonColor}
          onChange={(e) =>
            this.setState({
              buttonColor: e.color,
            })
          }
        />
      </div>
    );
    const bgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={buttonbgColor}
          onChange={(e) =>
            this.setState({
              buttonbgColor: e.color,
            })
          }
        />
      </div>
    );
    return (
      <div>
        {landingScreen && (
          <Row style={{ padding: "10px" }}>
            <Col span={24}>
              <Form id="form" onFinish={this.submitImageCard}>
                <Form.Item
                  name="bgImage"
                  rules={[
                    {
                      required: false,
                      message: "Please input your image!",
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    style={{
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    showUploadList={false}
                    customRequest={dummyRequest}
                    onChange={(e) => this.handleUploadPic(e)}
                  >
                    <Spin spinning={editImagesLoading}>
                      {homeCardImageId ? (
                        <img
                          src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${homeCardImageId}`}
                          alt="avatar"
                          style={{
                            width: "100px",
                            height: "100%",
                          }}
                        />
                      ) : landingScreen.backgroundHomeImage ? (
                        <img
                          src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${landingScreen.backgroundHomeImage}`}
                          alt="avatar"
                          style={{
                            width: "100px",
                            height: "100%",
                          }}
                        />
                      ) : (
                        <CameraOutlined className="camera-icon" />
                      )}
                    </Spin>
                  </Upload>
                </Form.Item>
                <ModalTitle>On Image Text</ModalTitle>
                <Form.Item
                  name="onImageTitle"
                  rules={[
                    {
                      required: false,
                      message: "Please input title",
                    },
                  ]}
                  initialValue={landingScreen?.onImageText}
                  style={{ marginBottom: "1vw" }}
                >
                  <Input placeholder="Start typing" className="form-input" />
                </Form.Item>
                <Row style={{ marginBottom: "1vw" }}>
                  <Col span={11}>
                    <ModalTitle>Sub Text1</ModalTitle>
                    <Form.Item
                      name="subtext1"
                      rules={[
                        {
                          required: false,
                          message: "Please input text!",
                        },
                      ]}
                      initialValue={landingScreen?.subText?.text1}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={11} offset={1}>
                    <ModalTitle>Sub Text2</ModalTitle>
                    <Form.Item
                      name="subtext2"
                      rules={[
                        {
                          required: false,
                          message: "Please input text!",
                        },
                      ]}
                      initialValue={landingScreen?.subText?.text2}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "1vw" }}>
                  <Col span={11}>
                    <ModalTitle>Sub Text3</ModalTitle>
                    <Form.Item
                      name="subtext3"
                      rules={[
                        {
                          required: false,
                          message: "Please input text!",
                        },
                      ]}
                      initialValue={landingScreen?.subText?.text3}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={11} offset={1}>
                    <ModalTitle>Sub Text4</ModalTitle>
                    <Form.Item
                      name="subtext4"
                      rules={[
                        {
                          required: false,
                          message: "Please input text!",
                        },
                      ]}
                      initialValue={landingScreen?.subText?.text4}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Col span={23} style={{ marginBottom: "1vw" }}>
                  <ModalTitle>Text Color</ModalTitle>

                  <Input
                    value={
                      textColor
                        ? textColor
                        : landingScreen?.onImageButton?.textColor
                    }
                    onChange={(e) =>
                      this.setState({
                        textColor: e.target.value,
                      })
                    }
                    suffix={
                      <Dropdown trigger={["click"]} overlay={TextColor}>
                        <Button
                          style={{
                            background:
                              textColor ||
                              landingScreen?.onImageButton?.textColor,
                          }}
                        >
                          {" "}
                        </Button>
                      </Dropdown>
                    }
                  />
                </Col>

                <Row style={{ marginBottom: "1vw" }}>
                  <Col span={7}>
                    <ModalTitle>Button Text</ModalTitle>
                    <Form.Item
                      name="buttonText"
                      rules={[
                        {
                          required: false,
                          message: "Please input button text!",
                        },
                      ]}
                      initialValue={landingScreen?.onImageButton?.buttonText}
                    >
                      <Input
                        placeholder="Start typing"
                        className="form-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Button Color</ModalTitle>

                    <Input
                      value={
                        buttonColor
                          ? buttonColor
                          : landingScreen?.onImageButton?.buttonColor
                      }
                      onChange={(e) =>
                        this.setState({
                          buttonColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={ButtonColor}>
                          <Button
                            style={{
                              background:
                                buttonColor ||
                                landingScreen?.onImageButton?.buttonColor,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>

                  <Col span={7} offset={1}>
                    <ModalTitle>Button Background Color</ModalTitle>
                    <Input
                      value={
                        buttonbgColor
                          ? buttonbgColor
                          : landingScreen?.onImageButton?.buttonBackground
                      }
                      onChange={(e) =>
                        this.setState({
                          buttonbgColor: e.target.value,
                        })
                      }
                      suffix={
                        <Dropdown trigger={["click"]} overlay={bgColor}>
                          <Button
                            style={{
                              background:
                                buttonbgColor ||
                                landingScreen?.onImageButton?.buttonBackground,
                            }}
                          >
                            {" "}
                          </Button>
                        </Dropdown>
                      }
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  submitImageCard = (values) => {
    this.setState({
      loading: true,
    });

    this.props.context.openupdateLoading(true);

    const {
      landingScreen,
      Header,
      footer,
      aboutUsPage,
      WhyJoinUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;
    const { buttonColor, buttonbgColor, homeCardImageId, textColor } =
      this.state;
    let buttonData = {
      buttonText: values["buttonText"],
      buttonColor: buttonColor
        ? buttonColor
        : landingScreen["onImageButton"]["buttonColor"],
      textColor: textColor
        ? textColor
        : landingScreen["onImageButton"]["textColor"],
      buttonBackground: buttonbgColor
        ? buttonbgColor
        : landingScreen["onImageButton"]["buttonBackground"],
    };

    let subTextData = {
      text1: values["subtext1"],
      text2: values["subtext2"],
      text3: values["subtext3"],
      text4: values["subtext4"],
    };

    landingScreen["onImageText"] = values["onImageTitle"];
    landingScreen["onImageButton"] = buttonData;
    landingScreen["subText"] = subTextData;
    landingScreen["backgroundHomeImage"] =
      homeCardImageId !== ""
        ? homeCardImageId
        : landingScreen["backgroundHomeImage"];

    let data = {
      entityData: {
        Header,
        landingScreen,
        footer,
        aboutUsPage,
        contactUsPage,
        WhyJoinUsPage,
      },
    };

    this.onFinish(data);
  };

  cardsData = () => {
    const { editImagesLoading, homeCardImageId } = this.state;
    const { landingScreen } = this.props.context.state.websiteThemes;
    const { updateLoading } = this.props.context.state;

    return (
      <div>
        <Row style={{ padding: "10px" }}>
          <Col span={24}>
            <Collapse bordered={false} defaultActiveKey={["1"]}>
              {landingScreen &&
                landingScreen.homepageCards?.map((editCard) => (
                  <Panel
                    header={editCard.title}
                    key={editCard.tab_key}
                    className="customPanelStyle"
                  >
                    <Row style={{ padding: "10px" }}>
                      <Col span={24}>
                        <Form
                          initialValues={{ remember: true }}
                          id="form"
                          onFinish={() => this.submitCardsData(editCard)}
                        >
                          <Form.Item
                            name="cardImage"
                            rules={[
                              {
                                required: false,
                                message: "Please input your Card Logo!",
                              },
                            ]}
                          >
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              style={{
                                borderRadius: "50%",
                                width: "100%",
                                height: "100%",
                              }}
                              showUploadList={false}
                              customRequest={dummyRequest}
                              onChange={(e) => this.handleUploadPic(e)}
                            >
                              <Spin spinning={editImagesLoading}>
                                {homeCardImageId ? (
                                  <img
                                    src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${homeCardImageId}`}
                                    alt="avatar"
                                    style={{
                                      width: "100px",
                                      height: "100%",
                                    }}
                                  />
                                ) : editCard.cardImage ? (
                                  <img
                                    src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${editCard.cardImage}`}
                                    alt="avatar"
                                    style={{
                                      width: "100px",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  <CameraOutlined className="camera-icon" />
                                )}
                              </Spin>
                            </Upload>
                          </Form.Item>
                          <div className="form-titles">Title</div>
                          <Form.Item
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: "Please input title",
                              },
                            ]}
                            initialValue={editCard.title}
                          >
                            <Input
                              placeholder="First Name"
                              className="form-input"
                              onChange={(e) =>
                                this.setState({
                                  titleOfTheCard: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                          <div className="form-titles">Description</div>
                          <Form.Item
                            name="description"
                            rules={[
                              {
                                required: true,
                                message: "Please input Last name!",
                              },
                            ]}
                            initialValue={editCard.description}
                          >
                            <RichText
                              handleRichtextBio={this.handleRichtextBio}
                              richtTextBiofieldProp={editCard.description}
                            />
                          </Form.Item>
                          <Button
                            key="back"
                            onClick={() => this.handleTocloseModal()}
                          >
                            Cancel
                          </Button>
                          &nbsp; &nbsp;
                          <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={updateLoading}
                          >
                            Save
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Panel>
                ))}
            </Collapse>
          </Col>
        </Row>
      </div>
    );
  };

  submitCardsData = (values) => {
    this.setState({
      loading: true,
    });

    this.props.context.openupdateLoading(true);

    const {
      landingScreen,
      Header,
      footer,
      aboutUsPage,
      WhyJoinUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;
    const { richtTextBiofield, titleOfTheCard } = this.state;
    const { homeCardImageId } = this.state;

    landingScreen.homepageCards?.forEach((element) => {
      if (element["tab_key"] === values["tab_key"]) {
        element["cardImage"] = homeCardImageId
          ? homeCardImageId
          : element["cardImage"];
        element["description"] = richtTextBiofield
          ? richtTextBiofield
          : element["description"];
        element["title"] = titleOfTheCard ? titleOfTheCard : element["title"];
      }
    });

    let data = {
      entityData: {
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        contactUsPage,
        WhyJoinUsPage,
      },
    };
    this.onFinish(data);
  };

  editListingCard = () => {
    const { landingScreen } = this.props.context.state.websiteThemes;
    const { titleOftheListings, titleOftheListingsLink } = this.state;
    const { updateLoading } = this.props.context.state;

    return (
      <div>
        <Row style={{ padding: "10px" }}>
          <Col span={24}>
            <div className="form-titles">Title</div>
            <Input
              placeholder="First Name"
              className="form-input"
              onChange={(e) =>
                this.setState({
                  titleOftheListings: e.target.value,
                })
              }
              value={titleOftheListings}
            />
            <div className="form-titles">Link name</div>
            <Input
              placeholder="First Name"
              className="form-input"
              onChange={(e) =>
                this.setState({
                  titleOftheListingsLink: e.target.value,
                })
              }
              value={titleOftheListingsLink}
            />
            <div className="form-titles">Description</div>
            <RichText
              handleRichtextBio={this.handleRichtextBio}
              richtTextBiofieldProp={landingScreen.listing.description}
            />
            <div style={{ float: "right", paddingTop: "3%" }}>
              <Button key="back" onClick={() => this.handleTocloseModal()}>
                Cancel
              </Button>
              &nbsp; &nbsp; &nbsp;
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={updateLoading}
                onClick={this.submitListingCard}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  submitListingCard = () => {
    this.setState({
      loading: true,
    });

    this.props.context.openupdateLoading(true);

    const {
      landingScreen,
      Header,
      footer,
      aboutUsPage,
      WhyJoinUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;
    const { richtTextBiofield, titleOftheListings, titleOftheListingsLink } =
      this.state;

    landingScreen.listing["description"] = richtTextBiofield;
    landingScreen.listing["title"] = titleOftheListings;
    landingScreen.listing["linkTextName"] = titleOftheListingsLink;

    let data = {
      entityData: {
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        contactUsPage,
        WhyJoinUsPage,
      },
    };
    this.onFinish(data);
  };

  editAboutWebsiteCard = () => {
    const { landingScreen } = this.props.context.state.websiteThemes;
    const { titleOfAboutWebsite, editImagesLoading } = this.state;
    const { updateLoading } = this.props.context.state;

    return (
      <div>
        <Row style={{ padding: "10px" }}>
          <Col span={24}>
            <div className="form-titles">Title</div>

            <Input
              placeholder="First Name"
              className="form-input"
              onChange={(e) =>
                this.setState({
                  titleOfAboutWebsite: e.target.value,
                })
              }
              value={titleOfAboutWebsite}
            />
            <div className="form-titles">Description</div>

            <RichText
              handleRichtextBio={this.handleAboutWebsiteDes}
              richtTextBiofieldProp={landingScreen.whyhomesListedBy.description}
            />
            <Collapse bordered={false} defaultActiveKey={["1"]}>
              {landingScreen &&
                landingScreen.whyhomesListedBy.info?.map((editCard) => (
                  <Panel
                    header={editCard.title}
                    key={editCard.tab_key}
                    className="customPanelStyle"
                  >
                    <Row style={{ padding: "10px" }}>
                      <Col span={24}>
                        <Form
                          initialValues={{ remember: true }}
                          id="form"
                          onFinish={() =>
                            this.submitAboutWebsiteCardData(editCard)
                          }
                        >
                          <Form.Item
                            name="image"
                            rules={[
                              {
                                required: false,
                                message: "Please input your Card Logo!",
                              },
                            ]}
                          >
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              style={{
                                borderRadius: "50%",
                                width: "100%",
                                height: "100%",
                              }}
                              showUploadList={false}
                              customRequest={dummyRequest}
                              onChange={(e) => this.handleUploadPic(e)}
                            >
                              <Spin spinning={editImagesLoading}>
                                {editCard.image ? (
                                  <img
                                    src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${editCard.image}`}
                                    // src={editCard.image}
                                    alt="avatar"
                                    style={{
                                      width: "100px",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  <CameraOutlined className="camera-icon" />
                                )}
                              </Spin>
                            </Upload>
                          </Form.Item>
                          <div className="form-titles">Title</div>
                          <Form.Item
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: "Please input title",
                              },
                            ]}
                            initialValue={editCard.title}
                          >
                            <Input
                              placeholder="First Name"
                              className="form-input"
                              onChange={(e) =>
                                this.setState({
                                  titleOfTheCard: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                          <div className="form-titles">Description</div>
                          <Form.Item
                            name="description"
                            rules={[
                              {
                                required: true,
                                message: "Please input Last name!",
                              },
                            ]}
                            initialValue={editCard.description}
                          >
                            <RichText
                              handleRichtextBio={this.handleRichtextBio}
                              richtTextBiofieldProp={editCard.description}
                            />
                          </Form.Item>
                          <Button
                            key="back"
                            onClick={() => this.handleTocloseModal()}
                          >
                            Cancel
                          </Button>
                          &nbsp; &nbsp;
                          <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={updateLoading}
                          >
                            Save
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Panel>
                ))}
            </Collapse>
          </Col>
        </Row>
      </div>
    );
  };

  submitAboutWebsiteCardData = (values) => {
    this.setState({
      loading: true,
    });

    this.props.context.openupdateLoading(true);

    const {
      landingScreen,
      Header,
      footer,
      aboutUsPage,
      WhyJoinUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;
    const {
      richtTextBiofield,
      titleOfAboutWebsite,
      titleOfTheCard,
      aboutWebsiteDes,
    } = this.state;
    const { homeCardImageId } = this.state;
    landingScreen.whyhomesListedBy.info?.forEach((element, index) => {
      if (element["tab_key"] === values["tab_key"]) {
        element["image"] =
          homeCardImageId !== "" ? homeCardImageId : element["image"];
        element["description"] =
          richtTextBiofield !== "" ? richtTextBiofield : element["description"];
        element["title"] =
          titleOfTheCard !== "" ? titleOfTheCard : element["title"];
      }
    });
    landingScreen.whyhomesListedBy["title"] =
      titleOfAboutWebsite !== ""
        ? titleOfAboutWebsite
        : landingScreen.whyhomesListedBy["title"];
    landingScreen.whyhomesListedBy["description"] =
      aboutWebsiteDes !== ""
        ? aboutWebsiteDes
        : landingScreen.whyhomesListedBy["description"];

    let data = {
      entityData: {
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        contactUsPage,
        WhyJoinUsPage,
      },
    };

    this.onFinish(data);
  };

  onFinish = async (data) => {
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserProfile && loginUserProfile.orgId) {
      let websiteId = await loginUserProfile.orgId;
      data["entityData"]["isNew"] = false;

      let userData = {
        entityData: {
          isNew: false,
        },
      };

      //FROM CONTEXT STORE UPDATING ORG INFORMATION
      this.props.context.updateWebsite(websiteId, data);

      update("users", loginUserProfile?.id, userData);
      // update("saveWebsitesData", websiteId, data)
      //   .then((result) => {
      //     if (result.success) {
      //       message.success("Updated successfully!");
      //       this.setState({
      //         showImageModal: false,
      //         showEditModalText: "",
      //         loading: false,
      //         homeCardImageId: "",
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     this.setState({
      //       showImageModal: false,
      //       showEditModalText: "",
      //       loading: false,
      //     });
      //     console.log(error);
      //   });
    }
  };
  handleToOpenModal = (text) => {
    this.props.context.openShowEditModal(true);

    this.setState({
      showEditModalText: text,
      homeCardImageId: "",
      localModalTest: true,
    });
  };
  handleListingCard = (text, title, linkText) => {
    this.props.context.openShowEditModal(true);

    this.setState({
      showEditModalText: text,
      titleOftheListings: title,
      titleOftheListingsLink: linkText,
      localModalTest: true,
    });
  };
  handleWhyAboutWebsite = (text, title) => {
    this.props.context.openShowEditModal(true);

    this.setState({
      titleOfAboutWebsite: title,
      showEditModalText: text,
      localModalTest: true,
    });
  };
  handleTocloseModal = () => {
    this.props.context.openShowEditModal(false);
    this.setState({
      showEditModalText: "",
      localModalTest: false,
    });
  };
  render() {
    const {
      landingScreen,
      Header,
      footer,
      aboutUsPage,
      WhyJoinUsPage,
      contactUsPage,
    } = this.props.context.state.websiteThemes;
    const { localModalTest, showEditModalText } = this.state;
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    const { showEditModal, updateLoading } = this.props.context.state;
    const profileBasedURL = JSON.parse(localStorage.getItem("profileBasedURL"));
    return (
      <div style={{ marginTop: profileBasedURL?.profileBasedURL && "54px" }}>
        {landingScreen ? (
          <Layout style={{ width: "100%", height: "100%" }}>
            <Content style={{ backgroundColor: "white" }}>
              <Row style={{ height: "50vw" }} className="img-width">
                <Card
                  style={{
                    position: "relative",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${landingScreen.backgroundHomeImage})`,
                    border: "none",
                  }}
                  // className="img-bg"
                >
                  {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                    <EditOutlined
                      style={{
                        color: "white",
                        float: "right",
                        fontSize: "24px",
                      }}
                      onClick={() => this.handleToOpenModal("onImageEdit")}
                    />
                  )}
                  <div className="img-text">
                    <Col span={24}>
                      <Row>
                        <h1
                          className="home-text"
                          style={{
                            color: landingScreen?.onImageButton?.textColor,
                            // textShadow: "#575856 5px 5px 2px",
                            textShadow: "#575856 2px 2px 5px",
                          }}
                        >
                          {landingScreen?.onImageText !== "" &&
                            landingScreen.onImageText}
                        </h1>
                      </Row>

                      <Row className="home-margin-row">
                        <Col xl={12} lg={12} md={24} xs={24} sm={24}>
                          <Row>
                            <Col
                              xl={{ span: 2 }}
                              lg={{ span: 2 }}
                              md={{ span: 2 }}
                              sm={{ span: 2 }}
                              xs={{ span: 2 }}
                              className="check-icon-home"
                            >
                              {landingScreen.subText?.text1 !== "" && (
                                <CheckCircleOutlined
                                  style={{
                                    color:
                                      landingScreen?.onImageButton?.textColor,
                                  }}
                                />
                              )}
                            </Col>
                            <Col
                              xl={{ span: 21, offset: 0 }}
                              lg={{ span: 21, offset: 0 }}
                              md={{ span: 21, offset: 0 }}
                              sm={{ span: 21, offset: 0 }}
                              xs={{ span: 21, offset: 1 }}
                            >
                              <span
                                className="home-text-check"
                                style={{
                                  color:
                                    landingScreen?.onImageButton?.textColor,
                                }}
                              >
                                {landingScreen.subText.text1}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={12} lg={12} md={24} xs={24} sm={24}>
                          <Row>
                            <Col
                              xl={{ span: 2 }}
                              lg={{ span: 2 }}
                              md={{ span: 2 }}
                              sm={{ span: 2 }}
                              xs={{ span: 2 }}
                              className="check-icon-home"
                            >
                              {landingScreen.subText?.text2 !== "" && (
                                <CheckCircleOutlined
                                  style={{
                                    color:
                                      landingScreen?.onImageButton?.textColor,
                                  }}
                                />
                              )}
                            </Col>
                            <Col
                              xl={{ span: 21, offset: 0 }}
                              lg={{ span: 21, offset: 0 }}
                              md={{ span: 21, offset: 0 }}
                              sm={{ span: 21, offset: 0 }}
                              xs={{ span: 21, offset: 1 }}
                            >
                              <span
                                className="home-text-check"
                                style={{
                                  color:
                                    landingScreen?.onImageButton?.textColor,
                                }}
                              >
                                {landingScreen.subText.text2}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="home-margin-row">
                        <Col xl={12} lg={12} md={24} xs={24} sm={24}>
                          <Row>
                            <Col
                              xl={{ span: 2 }}
                              lg={{ span: 2 }}
                              md={{ span: 2 }}
                              sm={{ span: 2 }}
                              xs={{ span: 2 }}
                              className="check-icon-home"
                            >
                              {landingScreen.subText?.text3 !== "" && (
                                <CheckCircleOutlined
                                  style={{
                                    color:
                                      landingScreen?.onImageButton?.textColor,
                                  }}
                                />
                              )}
                            </Col>
                            <Col
                              xl={{ span: 21, offset: 0 }}
                              lg={{ span: 21, offset: 0 }}
                              md={{ span: 21, offset: 0 }}
                              sm={{ span: 21, offset: 0 }}
                              xs={{ span: 21, offset: 1 }}
                            >
                              <span
                                className="home-text-check"
                                style={{
                                  color:
                                    landingScreen?.onImageButton?.textColor,
                                }}
                              >
                                {landingScreen.subText.text3}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={12} lg={12} md={24} xs={24} sm={24}>
                          <Row>
                            <Col
                              xl={{ span: 2 }}
                              lg={{ span: 2 }}
                              md={{ span: 2 }}
                              sm={{ span: 2 }}
                              xs={{ span: 2 }}
                              className="check-icon-home"
                            >
                              {landingScreen.subText?.text4 !== "" && (
                                <CheckCircleOutlined
                                  style={{
                                    color:
                                      landingScreen?.onImageButton?.textColor,
                                  }}
                                />
                              )}
                            </Col>
                            <Col
                              xl={{ span: 21, offset: 0 }}
                              lg={{ span: 21, offset: 0 }}
                              md={{ span: 21, offset: 0 }}
                              sm={{ span: 21, offset: 0 }}
                              xs={{ span: 21, offset: 1 }}
                            >
                              <span
                                className="home-text-check"
                                style={{
                                  color:
                                    landingScreen?.onImageButton?.textColor,
                                }}
                              >
                                {landingScreen.subText.text4}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {landingScreen.onImageButton?.buttonText !== "" && (
                        <Row style={{ justifyContent: "center" }}>
                          <div className="button_landingScreen">
                            <HeaderButton
                              color={landingScreen.onImageButton}
                              onClick={() => this.goTo()}
                              size="large"
                            >
                              <span className="footer-buttonText">
                                {landingScreen.onImageButton.buttonText}
                              </span>
                            </HeaderButton>
                            {/* <Button
                          onClick={() => this.goTo()}
                          type="primary"
                          danger
                          className="footer-button"
                          size="large"
                        >
                          <span className="footer-buttonText">
                            JOIN FOR FREE
                          </span>
                        </Button> */}
                          </div>
                        </Row>
                      )}

                      <Row
                        style={{
                          backgroundColor: "#FFF",
                          padding: "10px 20px",
                          marginTop: "100px",
                        }}
                      >
                        <Col
                          xl={{ span: 7, offset: 0 }}
                          lg={{ span: 7, offset: 0 }}
                          md={{ span: 7, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                          className="home-margin-btn"
                        >
                          <Select
                            onChange={this.handleSearches}
                            className="home-page-search"
                            placeholder="I am looking for"
                            bordered={false}
                          >
                            <Option value="Buyers">Buyers</Option>
                            <Option value="Homes">Homes</Option>
                            <Option value="Off MLS Homes">Off MLS Homes</Option>
                          </Select>
                        </Col>
                        <Col
                          xl={{ span: 10, offset: 1 }}
                          lg={{ span: 10, offset: 1 }}
                          md={{ span: 8, offset: 1 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                          className="home-margin-btn"
                        >
                          <div className="openHeader-Address">
                            <GooglePlacesAutocomplete
                              apiKey={process.env.REACT_APP_MAPS_KEY}
                              autocompletionRequest={{
                                types: ["(cities)"],
                                componentRestrictions: {
                                  country: ["us"],
                                },
                              }}
                              selectProps={{
                                placeholder: "Enter city name",
                                onChange: (result) => {
                                  this.handleSingleSearch(result);
                                },
                              }}
                            />
                          </div>
                        </Col>
                        <Col
                          xl={{ span: 5, offset: 1 }}
                          lg={{ span: 5, offset: 1 }}
                          md={{ span: 7, offset: 1 }}
                          sm={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                          className="home-margin-btn"
                        >
                          <Button
                            type="primary"
                            onClick={this.handleSearchNow}
                            className="home-Btn-primary"
                          >
                            Search Now{" "}
                            {
                              <img
                                src={arrowIcon}
                                alt={arrowIcon}
                                style={{ marginLeft: "15px" }}
                              />
                            }
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </Card>
              </Row>

              <Row
                gutter={[16, 16]}
                justify="center"
                style={{
                  marginLeft: "0px",
                  marginRight: "0px",
                  background: "#F9FAFF",
                }}
              >
                <Col span={24}>
                  {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                    <EditTwoTone
                      style={{
                        float: "right",
                        fontSize: "24px",
                        marginRight: "16px",
                        marginTop: "10px",
                      }}
                      onClick={() => this.handleToOpenModal("homeCardEdit")}
                    />
                  )}
                </Col>
                {landingScreen.homepageCards.map((c) => (
                  <Col
                    xl={{ span: 6 }}
                    lg={{ span: 6 }}
                    md={{ span: 6 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <Card bordered={false} className="card-BuyHome" hoverable>
                      <Row justify="center">
                        <Avatar
                          shape="square"
                          size={80}
                          icon={
                            <img
                              src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${c.cardImage}`}
                              alt="buyHome"
                            />
                          }
                          style={{ background: "#F9FAFF" }}
                        />
                      </Row>
                      <Row justify="center">
                        <h2
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            marginTop: "5px",
                          }}
                        >
                          {c.title}
                        </h2>
                      </Row>
                      <Row justify="center">
                        <p
                          style={{
                            textAlign: "justify",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${c.description}`,
                            }}
                          />
                        </p>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
              {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                <Row
                  style={{
                    justifyContent: "end",
                    fontSize: "24px",
                    background: " #fff",
                  }}
                >
                  <EditTwoTone
                    style={{
                      float: "right",
                      fontSize: "24px",
                      marginRight: "16px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.handleListingCard(
                        "mlsListingCard",
                        landingScreen.listing.title,
                        landingScreen.listing.linkTextName
                      )
                    }
                  />
                </Row>
              )}
              <Row className="New-listing-margin" style={{ padding: "20px" }}>
                <Col
                  xl={{ span: 8, offset: 1 }}
                  lg={{ span: 8, offset: 1 }}
                  md={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                >
                  <p class="sale-heading">{landingScreen.listing.title}</p>
                </Col>
                <Col
                  xl={{ span: 13, offset: 1 }}
                  lg={{ span: 13, offset: 1 }}
                  md={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                >
                  <p class="sale-content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${landingScreen.listing.description}`,
                      }}
                    />
                  </p>
                  <Link onClick={() => this.goTo()}>
                    <p style={{ color: "#F75B47", fontSize: "20px" }}>
                      {landingScreen.listing.linkTextName}
                      <img
                        src={arroworangeIcon}
                        alt=""
                        style={{ marginLeft: "5px" }}
                      />
                    </p>
                  </Link>
                </Col>
              </Row>

              <Col
                span={24}
                style={{ background: " #F9FAFF", paddingBottom: "1vw" }}
              >
                <HomesCarousel whereToShow="homes" limit="20" />
              </Col>

              <Row justify="center">
                <Col span={22} style={{ marginTop: "2vw" }}>
                  <h3 className="title">
                    {landingScreen.whyhomesListedBy.title}
                  </h3>
                  <Col
                    xl={{ span: 22, offset: 1 }}
                    lg={{ span: 22, offset: 1 }}
                    md={{ span: 22, offset: 1 }}
                    sm={{ span: 22, offset: 1 }}
                    xs={{ span: 22, offset: 1 }}
                  >
                    <p className="content">
                      {/* {landingScreen.whyhomesListedBy.description} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${landingScreen.whyhomesListedBy.description}`,
                        }}
                      />
                    </p>
                  </Col>
                </Col>
                <Col span={2}>
                  {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                    <EditTwoTone
                      style={{
                        float: "right",
                        fontSize: "24px",
                        marginRight: "16px",
                        marginTop: "10px",
                      }}
                      onClick={() =>
                        this.handleWhyAboutWebsite(
                          "aboutWebsite",
                          landingScreen.whyhomesListedBy.title
                        )
                      }
                    />
                  )}
                </Col>
              </Row>

              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row className="side-row-top">
                  {landingScreen.whyhomesListedBy.info.map((h) => (
                    <>
                      <Col
                        xl={{ span: 1, offset: 2 }}
                        lg={{ span: 1, offset: 1 }}
                        md={{ span: 1, offset: 1 }}
                        sm={{ span: 1, offset: 1 }}
                        xs={{ span: 1, offset: 1 }}
                      >
                        <div className="background-color">
                          <img
                            src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${h.image}`}
                            alt="space"
                            className="homes-img-width"
                          />
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 1 }}
                        md={{ span: 20, offset: 1 }}
                        sm={{ span: 20, offset: 1 }}
                        xs={{ span: 20, offset: 1 }}
                      >
                        <Row>
                          <p className="side-title">{h.title}</p>
                        </Row>
                        <Row>
                          <p className="side-content">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${h.description}`,
                              }}
                            />
                          </p>
                        </Row>
                      </Col>
                    </>
                  ))}
                </Row>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <BuyerProfileContext
                  buyerProfile={landingScreen.buyerProfile}
                  landingScreenData={landingScreen}
                  footer={footer}
                  aboutUsPage={aboutUsPage}
                  contactUsPage={contactUsPage}
                  WhyJoinUsPage={WhyJoinUsPage}
                  Header={Header}
                />
              </Col>
            </Content>
          </Layout>
        ) : (
          ""
        )}
        <Modal
          className="modal-black-close"
          visible={showEditModal && localModalTest}
          title="Change text"
          onOk={() => this.changeProfile()}
          onCancel={() => this.handleTocloseModal()}
          footer={
            showEditModalText === "onImageEdit" && [
              <Button key="back" onClick={() => this.handleTocloseModal()}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                form="form"
                loading={updateLoading}
              >
                Save
              </Button>,
            ]
          }
          width={
            window.screen.width >= "768"
              ? "40%"
              : window.screen.width > "501" && window.screen.width <= "767"
              ? "50%"
              : "100%"
          }
        >
          {showEditModalText === "onImageEdit"
            ? this.onImageCard()
            : showEditModalText === "homeCardEdit"
            ? this.cardsData()
            : showEditModalText === "mlsListingCard"
            ? this.editListingCard()
            : showEditModalText === "aboutWebsite" &&
              this.editAboutWebsiteCard()}
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(Home));
