import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import Meetings from "../../components/VirtualOffice/Meetings";
import TodoList from "../../components/VirtualOffice/Todo";
// import ResourcesLinks from "../../components/VirtualOffice/ResourcesLinks";
import { list } from "../../api/Apis";
import CarouselCom from "./CarouselCom";
import ActiveDashboardLeads from "./contactLeads/ActiveDashboardLeads";

export default function Dashboard() {
  // const announcements = ["1", "2", "3", "4", "5"];
  const [announcements, setannouncements] = useState([]);
  const [allTodoData, setAlltodoData] = useState({
    totalData: [],
    showMeetingCard: false,
  });
  useEffect(() => {
    const orgId = JSON.parse(localStorage.getItem("orgId"));
    setAlltodoData((t) => ({
      totalData: [],
      showMeetingCard: false,
    }));
    list("virtualOfficeAnnouncement", { orgId })
      .then((res) => setannouncements(res.data))
      .catch((error) => console.log(error));
    let mounted = true;
    getAllTodos(mounted);
    return () => (mounted = false);
  }, []);

  const getAllTodos = (mounted) => {
    setAlltodoData((t) => ({
      totalData: [],
      showMeetingCard: false,
    }));
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgId = JSON.parse(localStorage.getItem("orgId"));
    let query = {};
    if (userDetails?.userProfile === "A") {
      query["orgId"] = orgId;
    } else {
      query["userId"] = userDetails.id;
      query["orgId"] = orgId;
    }
    try {
      if (mounted) {
        list("getTodos", query)
          .then((res) => {
            setAlltodoData((t) => ({
              totalData: res.data,
              showMeetingCard: true,
            }));
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row>
      <Col
        xxl={{ span: 16 }}
        xl={{ span: 24 }}
        lg={{ span: 24 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <ActiveDashboardLeads />
        </Col>
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <TodoList showData getAllTodosonDashboard={getAllTodos} />
          </Col>

          {/* <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <ResourcesLinks />
          </Col> */}
        </Row>
      </Col>
      <Col
        xxl={{ span: 8 }}
        xl={{ span: 24 }}
        lg={{ span: 24 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        style={{
          padding: window.location.width > 1600 ? "0px" : "24px 24px 24px 0px",
        }}
      >
        <Row>
          <Col
            xxl={{ span: 24, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            style={{
              padding:
                window.location.width > 1600 ? "0px 0px 24px 24px" : "0px",
            }}
          >
            <Card className="card_virtual" hoverable={true}>
              <CarouselCom announcements={announcements} />
            </Card>
          </Col>
          <Col
            xxl={{ span: 24, offset: 0 }}
            xl={{ span: 11, offset: 1 }}
            md={{ span: 11, offset: 1 }}
            sm={{ span: 11, offset: 1 }}
            style={{
              padding:
                window.location.width > 1600 ? "0px 24px 24px 0px" : "24px 0px",
            }}
          >
            {allTodoData.showMeetingCard && (
              <Meetings allTodoData={allTodoData.totalData} getAllTodos={getAllTodos}  />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
