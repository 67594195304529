import {
  Row,
  Col,
  Divider,
  Button,
  Form,
  Input,
  Modal,
  Upload,
  DatePicker,
  Spin,
  notification,
} from "antd";
import moment from "moment";
import { add, deleteRecord, list, update } from "../../api/Apis";
import React, { Component } from "react";
import Carousel from "react-elastic-carousel";

import leftArrow from "../../images/announcementLeftArrow.png";
import rightArrow from "../../images/announcementRightArrow.png";
import {
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Storage } from "aws-amplify";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

class CarouselCom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openAnnouncementForm: false,
      startDate: "",
      endDate: "",
      imageLoading: false,
      imageKeys: "",
      fileList: [],
      announcements: [],
      editModal: false,
      loading: false,
    };
  }
  componentDidMount() {
    this.getAnnouncements();
  }
  getAnnouncements = () => {
    const orgId = JSON.parse(localStorage.getItem("orgId"));
    this.setState({ loading: true });
    list("virtualOfficeAnnouncement", { orgId })
      .then((res) => {
        if (res.success) {
          this.setState({
            announcements: res.data,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  handleAnnouncementDetails = (e) => {
    this.setState({
      loading: true,
    });
    const { imageKeys, startDate, endDate, carouselData, editModal } =
      this.state;
    const organizationId = JSON.parse(localStorage.getItem("orgId"));

    let values = e;
    const data = Object.assign({}, { entityData: {} });
    values["profileImageUpload"] =
      imageKeys !== "" ? imageKeys : carouselData["profileImageUpload"];
    values["start_and_end_date"] =
      startDate !== ""
        ? [startDate, endDate]
        : carouselData["start_and_end_date"];
    values["orgId"] = organizationId;
    data["entityData"] = values;

    if (editModal) {
      update("virtualOfficeAnnouncement", carouselData.id, data)
        .then((res) => {
          if (res.success) {
            this.setState({
              openAnnouncementForm: false,
              fileList: "",
              editModal: false,
              loading: false,
            });
            notification["success"]({
              message: "Announcement updated successfully",
            });
            this.getAnnouncements();
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            openAnnouncementForm: false,
            loading: false,
            editModal: false,
          });
        });
    } else {
      try {
        add("virtualOfficeAnnouncement", data).then((res) => {
          if (res.success) {
            this.setState({
              openAnnouncementForm: false,
              fileList: "",
              loading: false,
            });
            notification["success"]({
              message: "New announcement added successfully",
            });
            this.getAnnouncements();
          }
        });
      } catch (error) {
        console.log(error);
        this.setState({ openAnnouncementForm: false, loading: false });
      }
    }
  };

  handleStartAndEndDate = (date) => {
    this.setState({
      startDate: moment(date[0]).format("MM/DD/YYYY"),
      endDate: moment(date[1]).format("MM/DD/YYYY"),
    });
  };

  imageUpload = (info) => {
    this.setState({
      imageLoading: true,
    });
    let Images = "";
    const fileList = [];
    fileList.push(info);
    this.setState({
      fileList: info.fileList,
    });
    if (info.fileList.length > 0 && info.file.status !== "removed") {
      info.fileList.map((file) => {
        try {
          Storage.put(file.uid, file.originFileObj, {
            contentType: file.type,
          }).then((result) => {
            Images = result.key;
            this.setState({
              imageKeys: Images,
              imageLoading: false,
            });
          });
        } catch (err) {
          console.log(err, "errr");
        }
        return file;
      });
    }
    if (info.file.status === "removed") {
      this.setState({
        imageLoading: false,
      });
    }
  };

  showModal = (record) => {
    this.setState({
      carouselData: record,
      openAnnouncementForm: true,
      editModal: true,
    });
  };

  deleteModal = (record) => {
    this.setState({ laoding: true });
    deleteRecord("virtualOfficeAnnouncement", record.id)
      .then((res) => {
        if (res.success) {
          this.setState({ openAnnouncementForm: false, fileList: "" });
          notification["success"]({
            message: "Announcement deleted successfully",
          });
          this.getAnnouncements();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openAnnouncementForm: false });
      });
  };

  render() {
    const {
      fileList,
      openAnnouncementForm,
      announcements,
      carouselData,
      imageKeys,
      imageLoading,
    } = this.state;
    const userLoginDetails = JSON.parse(localStorage.getItem("userDetails"));
    return (
      <div>
        <Row>
          <Col span={20}>
            <h3 style={{ fontSize: "22px" }}>
              Announcements
              {userLoginDetails &&
                (userLoginDetails.userProfile === "A" ||
                  userLoginDetails.userProfile === "concierge" ||
                  userLoginDetails?.markAsManager) && (
                  <i
                    className="fa fa-plus-circle"
                    style={{ paddingLeft: "10px", color: "#1890ff" }}
                    onClick={() =>
                      this.setState({ openAnnouncementForm: true })
                    }
                  ></i>
                )}
            </h3>
          </Col>
          <Col span={4}>
            <img
              src={rightArrow}
              onClick={() => this.carousel.slidePrev()}
              alt="rightArrow"
              width="10px"
              style={{ marginRight: "20px" }}
            />
            <img
              src={leftArrow}
              onClick={() => this.carousel.slideNext()}
              alt="leftArrow"
              width="10px"
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: "0px", marginBottom: "10px" }} />

        {/* <div className="agent-carousel"> */}
        <Carousel ref={(ref) => (this.carousel = ref)}>
          {announcements &&
            announcements.length > 0 &&
            announcements?.map((eachAnnouncement, index) => (
              <div>
                {userLoginDetails.userProfile === "A" && (
                  <>
                    <DeleteOutlined
                      style={{
                        float: "right",
                        marginBottom: "10px",
                        color: "red",
                        fontSize: "18px",
                        marginLeft: "10px",
                      }}
                      onClick={() => this.deleteModal(eachAnnouncement)}
                    />
                    <EditOutlined
                      style={{
                        float: "right",
                        marginBottom: "10px",
                        color: "#1890ff",
                        fontSize: "18px",
                      }}
                      onClick={() => this.showModal(eachAnnouncement)}
                    />
                  </>
                )}
                <div>
                  <img
                    src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${eachAnnouncement.profileImageUpload}`}
                    alt="announcement"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>{" "}
                <div>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#131419",
                    }}
                  >
                    {eachAnnouncement?.title}
                  </span>
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#99A2C3",
                      }}
                    >
                      {eachAnnouncement?.description}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#99A2C3",
                      }}
                    >
                      {eachAnnouncement.start_and_end_date[0]}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
        {/* </div> */}
        <Modal
          destroyOnClose={true}
          className="modal-black-close"
          visible={openAnnouncementForm}
          title={"Add Announcement Details"}
          onCancel={() =>
            this.setState({
              openAnnouncementForm: false,
              fileList: "",
              imageKeys: "",
              editModal: false,
            })
          }
          footer={[
            <Button
              key="back"
              onClick={() =>
                this.setState({
                  openAnnouncementForm: false,
                  fileList: "",
                  imageKeys: "",
                  editModal: false,
                })
              }
              htmlType="reset"
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              // onClick={() => this.update(true)}
              htmlType="submit"
              form="announcement"
              loading={imageLoading}
            >
              Add
            </Button>,
          ]}
        >
          <Row style={{ padding: "10px" }}>
            <Col span={24}>
              <Form
                initialValues={{ remember: true }}
                onFinish={this.handleAnnouncementDetails}
                id="announcement"
              >
                <Row>
                  <Col
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xl={{ span: 24 }}
                  >
                    <div className="form-titles">Title</div>

                    <Form.Item
                      className="Formitem"
                      rules={[
                        {
                          required: true,
                          message: "Please input announcement title!",
                        },
                      ]}
                      name="title"
                      initialValue={carouselData?.title}
                    >
                      <Input className="height_form" />
                    </Form.Item>
                  </Col>

                  <Col
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xl={{ span: 24 }}
                  >
                    <div className="form-titles">Date</div>

                    {/* <Form.Item
                      className="Formitem"
                      rules={[
                        {
                          required: true,
                          message: "Please input description !",
                        },
                      ]}
                    > */}
                    <RangePicker
                      defaultValue={
                        carouselData?.start_and_end_date && [
                          moment(
                            carouselData?.start_and_end_date[0],
                            "MM/DD/YYYY"
                          ),
                          moment(
                            carouselData?.start_and_end_date[1],
                            "MM/DD/YYYY"
                          ),
                        ]
                      }
                      size={"middle"}
                      format="MM/DD/YYYY"
                      onChange={this.handleStartAndEndDate}
                      style={{ width: "100%" }}
                    />
                    {/* </Form.Item> */}
                  </Col>
                  <Col
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xl={{ span: 24 }}
                  >
                    <div className="form-titles">Description</div>

                    <Form.Item
                      className="Formitem"
                      rules={[
                        {
                          required: true,
                          message: "Please input description !",
                        },
                      ]}
                      name="description"
                      initialValue={carouselData?.description}
                    >
                      <TextArea />
                    </Form.Item>
                  </Col>
                  <Col
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xl={{ span: 24 }}
                  >
                    <div className="form-titles">Upload Image</div>

                    <Form.Item
                      className="Formitem"
                      rules={[
                        {
                          required: true,
                          message: "Please upload profile !",
                        },
                      ]}
                    >
                      <Spin spinning={imageLoading}>
                        <Upload
                          onChange={this.imageUpload}
                          listType="picture"
                          fileList={fileList}
                          beforeUpload={() => false}
                        >
                          {carouselData?.profileImageUpload &&
                          imageKeys === "" ? (
                            <img
                              src={
                                `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                carouselData?.profileImageUpload
                              }
                              alt="carousel"
                              style={{ height: "100px", width: "100%" }}
                            />
                          ) : (
                            fileList.length < 1 && (
                              <Button>
                                <UploadOutlined />
                                {fileList.length < 1 && "Upload Image"}
                              </Button>
                            )
                          )}
                        </Upload>
                      </Spin>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default CarouselCom;
