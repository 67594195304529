import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import { Col, Row, Form, InputNumber, Select, notification } from "antd";
import Advertisement from "../../../common/Advertisement";
class PropertyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      publicAddress: "",
      city: "",
      state: "",
      zipCode: "",
      clint: "",
      listPrice: "",
      maxPrice: this.props.context.state.sellerHomeDetails.propertyDetails
        ? this.props.context.state.sellerHomeDetails.propertyDetails.listPrice
        : "",
      minPrice: this.props.context.state.sellerHomeDetails.propertyDetails
        ? this.props.context.state.sellerHomeDetails.propertyDetails.minPrice
        : "",
      startTime: this.props.context.state.sellerHomeDetails.propertyDetails
        .timeFrame
        ? this.props.context.state.sellerHomeDetails.propertyDetails
            .timeFrame[0]
        : "",
      // moment().format("YYYY/MM/DD HH:mm"),
      endTime: this.props.context.state.sellerHomeDetails.propertyDetails
        .timeFrame
        ? this.props.context.state.sellerHomeDetails.propertyDetails
            .timeFrame[1]
        : "",
      //  moment().format("YYYY/MM/DD HH:mm"),
      citySearch: "",
      usersTypeData: [],
      visibleStatus: "",
      initialValueTrue: false,
    };
  }

  componentDidMount() {
    const { sellerHomeDetails } = this.props;
    const offMlsStatus =
      sellerHomeDetails &&
      sellerHomeDetails.propertyDetails &&
      sellerHomeDetails.propertyDetails.status &&
      sellerHomeDetails.propertyDetails.status.length > 0 &&
      sellerHomeDetails.propertyDetails.status;
    let visibleStatus = "";
    if (offMlsStatus) {
      if (offMlsStatus.includes("comingsoon")) {
        visibleStatus = "Coming Soon";
      }
      if (offMlsStatus.includes("active")) {
        visibleStatus = "New Listing";
      }
      if (
        offMlsStatus.includes("pending") &&
        offMlsStatus.includes("activeundercontract")
      ) {
        visibleStatus = "Under Agreement";
      }
      if (offMlsStatus.includes("Temp off MLS")) {
        visibleStatus = "Temp off MLS";
      }
      if (offMlsStatus.includes("For Sale by Owner")) {
        visibleStatus = "For Sale by Owner";
      }
      if (offMlsStatus.includes("For Sale (Off MLS)")) {
        visibleStatus = "For Sale (Off MLS)";
      }
      if (
        offMlsStatus.includes("active") &&
        offMlsStatus.includes("comingsoon") &&
        offMlsStatus.includes("activeundercontract")
      ) {
        visibleStatus = "For Sale (On MLS)";
      }
      this.setState({
        visibleStatus,
      });
    }
    if (sellerHomeDetails && sellerHomeDetails.propertyDetails) {
      this.setState({
        maxPrice:
          sellerHomeDetails && sellerHomeDetails.propertyDetails.maxPrice,
        minPrice:
          sellerHomeDetails && sellerHomeDetails.propertyDetails.minPrice,
        listPrice:
          sellerHomeDetails && sellerHomeDetails.propertyDetails.listPrice,
      });
    }
    this.setState({
      initialValueTrue: true,
    });
  }
  onFinish = (e) => {
    const { listPrice, maxPrice, minPrice } = this.state;
    let priceValid = false;
    if (
      listPrice ||
      (minPrice && maxPrice && maxPrice >= minPrice && !this.props.doBackPage)
    ) {
      e["listPrice"] = listPrice;
      e["maxPrice"] = maxPrice;
      e["minPrice"] = minPrice;
      priceValid = true;
    } else if (
      maxPrice &&
      minPrice &&
      maxPrice < minPrice &&
      !this.props.doBackPage
    ) {
      notification["error"]({
        message: "Invalid price range",
        description: "Max price should be greater than min price",
      });
    } else {
      if (!this.props.doBackPage) {
        notification["error"]({
          message: "Price is required ",
          description: "Please enter list price or price range",
        });
      }
    }
    const status = [];
    const offMlsStatus = this.state.visibleStatus;
    if (offMlsStatus) {
      if (offMlsStatus.includes("Coming Soon")) {
        status.push("comingsoon");
      }
      if (offMlsStatus.includes("New Listing")) {
        status.push("active");
      }
      if (offMlsStatus.includes("Under Agreement")) {
        status.push("pending");
        status.push("activeundercontract");
      }
      if (offMlsStatus.includes("Temp off MLS")) {
        status.push("Temp off MLS");
      }
      if (offMlsStatus.includes("For Sale by Owner")) {
        status.push("For Sale by Owner");
      }
      if (offMlsStatus.includes("For Sale (Off MLS)")) {
        status.push("For Sale (Off MLS)");
      }
      if (offMlsStatus.includes("For Sale (On MLS)")) {
        status.push("active");
        status.push("comingsoon");
        status.push("activeundercontract");
      }
    }

    e["status"] = status;
    if (this.props.doBackPage) {
      this.props.handleForm(e, "PROPERTY_DETAILS");
    } else {
      if (priceValid) {
        this.props.handleForm(e, "PROPERTY_DETAILS");
      }
    }
  };

  onChangeMin = (value) => {
    // if (this.state.maxPrice > value) {
    this.setState({ minPrice: value });
    // }
  };
  onChangeMax = (value) => {
    // if (this.state.minPrice < value) {
    this.setState({ maxPrice: value });
    // }
  };
  handleTimeFrame = (value, dateFormate) => {
    this.setState({
      startTime: dateFormate[0],
      endTime: dateFormate[1],
    });
  };
  handleListPrice = (e) => {
    this.setState({
      listPrice: e,
    });
  };
  changeCity = (e) => {
    this.setState({
      city: e,
    });
  };

  handleProvinceChange = (visibleStatus) => {
    this.setState({
      visibleStatus,
    });
  };
  render() {
    const { minPrice, maxPrice, listPrice, visibleStatus, initialValueTrue } =
      this.state;
    // const { sellerHomeDetails } = this.props.context.state;
    const { sellerHomeDetails, doBackPage } = this.props;

    const statusData = [
      "Coming Soon",
      "New Listing",
      "Under Agreement",
      "Temp off MLS",
      "For Sale by Owner",
      "For Sale (Off MLS)",
      "For Sale (On MLS)",
    ];

    const lablesTags = [
      "1031 Exchange",
      "As Is Sale",
      "Available via kick-out clause",
      "Cash cow",
      "Downsize seller",
      "Hot offer",
      "Like New",
      "Need to find suitable housing",
      "New construction",
      "New listing",
      "Temp Off MLS",
      "Open House",
      "Portfolio",
      "Ready to Move",
      "To be built",
      "Under Agreement",
      "upsize",
      "Cash Only",
      "Investor Special",
    ];

    const property1 = [
      { label: "Land", value: "Land" },
      { label: "Mobile Homes", value: "Mobile Homes" },
      {
        label: "Residential Multi Family Home",
        value: "Residential Multi Family Home",
      },
      {
        label: "Residential Single Family Home",
        value: "Residential Single Family Home",
      },
      { label: "Residential Rental ", value: "Residential Rental" },
      { label: "Residential Condominium", value: "Residential Condominium" },
      { label: "Coopertive", value: "Coopertive" },
      { label: "Commercial Office", value: "Commercial Office" },
      { label: "Commercial MLS", value: "Commercial MLS" },
      { label: "Business Opportunity", value: "Business Opportunity" },
    ];

    const timeFrame = [
      "1-4 months",
      "4-6 months",
      "6-12 months",
      "12-16 months",
      "16+ months",
    ];

    return (
      <div>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="privateD-title">Property Details</div>
            <div style={{ marginBottom: 20 }}>
              <span className="privateD-des">
                Provide details about the property below. In pricing fields you
                can enter a listing price if you are listing by owner or if you
                are a licensed agent and have a contract with the seller. If you
                do not then it is best to offer a price range to potential
                buyers.
              </span>
              <br />
            </div>
          </Col>
          <Col
            xl={{ span: 16, offset: 0 }}
            lg={{ span: 16, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Form
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              size="large"
              id="form"
            >
              <Row>
                {initialValueTrue && (
                  <Col
                    xl={{ span: 11, offset: 0 }}
                    lg={{ span: 11, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                  >
                    <div className="form-titles">Property Type</div>
                    <Form.Item
                      name="propertyType"
                      // rules={[{ required: true, message: 'Please input your garage space!' }]}
                      initialValue={
                        sellerHomeDetails.propertyDetails &&
                        sellerHomeDetails.propertyDetails.propertyType
                      }
                    >
                      <Select
                        className="form-input"
                        placeholder="Select"
                        // onChange={this.handleProvinceChange}>
                      >
                        {property1.map((province) => (
                          <Select.Option
                            key={province.value}
                            value={province.value}
                          >
                            {province.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <div className="form-titles">Status</div>
                    <Form.Item
                      name="status"
                      rules={[
                        {
                          required: doBackPage ? false : true,
                          message: "Please input your status!",
                        },
                      ]}
                      initialValue={visibleStatus && visibleStatus}
                    >
                      <Select
                        className="form-input"
                        placeholder="Select"
                        onChange={this.handleProvinceChange}
                      >
                        {statusData.map((province) => (
                          <Select.Option key={province} value={province}>
                            {province}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <div className="form-titles">
                      Labels (Will show on the listing)
                    </div>
                    <Form.Item
                      name="lables"
                      initialValue={
                        sellerHomeDetails &&
                        sellerHomeDetails.propertyDetails &&
                        sellerHomeDetails.propertyDetails.lables
                          ? sellerHomeDetails.propertyDetails.lables
                          : []
                      }
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="You can select multiple"
                        showSearch={window.screen.width >= "992" ? true : false}
                      >
                        {lablesTags.map((province) => (
                          <Select.Option key={province} value={province}>
                            {province}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">Desired Sale Time Frame</div>
                  <Form.Item
                    name="timeFrame"
                    initialValue={
                      sellerHomeDetails &&
                      sellerHomeDetails.propertyDetails &&
                      sellerHomeDetails.propertyDetails.timeFrame
                    }
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select Desired Time Frame "
                    >
                      {timeFrame.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <div className="form-titles">
                    {" "}
                    List Price (Only use if contracted with seller or you are
                    seller and have decided on a list price otherwise use a
                    range below)
                  </div>
                  <InputNumber
                    name="listPrice"
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    // prefix="$"
                    className="form-input-number"
                    value={`${
                      listPrice && listPrice !== "undefined" ? listPrice : ""
                    }`}
                    onChange={this.handleListPrice}
                  />
                  <Row>
                    <Col span={11} className="style-price">
                      <div
                        style={{
                          fontSize: 15,
                          fontWeight: 600,
                          fontFamily: "Poppins,sans-serif",
                        }}
                      >
                        Min Price
                      </div>

                      <InputNumber
                        formatter={(value) =>
                          ` $ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        // className="form-input-for-media1"
                        value={`${minPrice ? minPrice : ""}`}
                        onChange={this.onChangeMin}
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Col>
                    <Col span={11} offset={2}>
                      <div
                        style={{
                          fontSize: 15,
                          fontWeight: 600,
                          fontFamily: "Poppins,sans-serif",
                        }}
                      >
                        Max Price
                      </div>
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        value={`${
                          maxPrice && maxPrice !== "undefined" ? maxPrice : ""
                        }`}
                        onChange={this.onChangeMax}
                        // className="form-input-for-media2"
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </Col>
                  </Row>
                  <div>
                    <span
                      style={{
                        fontSize: 15,
                        fontWeight: 600,
                        fontFamily: "Poppins,sans-serif",
                      }}
                    >
                      NOTE:
                    </span>
                    <span
                      style={{
                        fontSize: 15,
                        fontWeight: "normal",
                        fontFamily: "Poppins,sans-serif",
                      }}
                    >
                      {" "}
                      &nbsp; If you don't have an exact price add a sale price
                      range.
                    </span>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Advertisement
              otherAds={false}
              adType="Square"
              adWhereToShow="Homes"
              adLimit="1"
              zipCode=""
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withAppContext(withRouter(PropertyDetails));
