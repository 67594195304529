import { get_listings } from "../Types/types";

const initialState = {
  properties_list: [],
};

const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case get_listings:
      return {
        ...state,
        properties_list: action.payload,
      };
    default:
      return state;
  }
};

export default stateReducer;
