import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  notification,
  Modal,
  Collapse,
  Form,
  Input,
  Button,
} from "antd";
import Carousel from "react-elastic-carousel";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { EditTwoTone } from "@ant-design/icons";
import Item from "./Item";
import { noAuthlist, update } from "../../api/Apis";
import RichText from "../../common/RichText";
import arroworangeIcon from "../../../src/images/ArrowOrangeIcon.svg";
const { Panel } = Collapse;

class BuyerProfileContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyersList: [],
      testimonialsList: [],
      dataLoading: false,
      showEditModalText: "",
      showImageModal: false,
      richtTextBiofield: "",
      subTitleOfTheReviewer: "",
      titleOfTheReviewer: "",
      reviewTitle: "",
      // window.location.assign("/pageNotFound/newtWork");
      loading: false,
    };
  }

  componentDidMount() {
    this.getUserTestmonials();
    this.getBuyers();
  }

  getUserTestmonials = async () => {
    this.setState({
      dataLoading: true,
    });
    try {
      const response = await noAuthlist(
        "testimonials?limit=4&sortBy=updatedAt"
      );
      // .slice(0, 4)
      if (response.success) {
        this.setState({
          testimonialsList: response.data,
          dataLoading: false,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
        this.setState({
          dataLoading: false,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
      this.setState({
        dataLoading: false,
      });
    }
  };

  getBuyers = async () => {
    this.setState({
      dataLoading: true,
    });
    try {
      const response = await noAuthlist("getBuyers?limit=10&sortBy=updatedAt&isActive=true");
      if (response.success) {
        let publicBuyers = response.data.filter((buyer) => {
          return buyer.buyerPropertyDetails2.showOnBuyersPage && buyer;
        });

        this.setState({
          buyersList: publicBuyers.slice(0, 4),
          dataLoading: false,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
        this.setState({
          dataLoading: false,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      this.setState({
        dataLoading: false,
      });
    }
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleRichtextBio = (bio) => {
    this.setState({
      richtTextBiofield: bio,
    });
  };

  openBuyerProfileCard = () => {
    const { buyerProfile } = this.props;
    return (
      <>
        <Row style={{ padding: "10px" }}>
          <Col span={24}>
            <Form
              initialValues={{ remember: true }}
              id="form"
              onFinish={this.submitBuyerCardData}
            >
              <div className="form-titles">title</div>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input title",
                  },
                ]}
                initialValue={buyerProfile.title}
              >
                <Input
                  placeholder="First Name"
                  className="form-input"
                  onChange={(e) =>
                    this.setState({
                      subTitleOfTheReviewer: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <div className="form-titles">Description</div>
              <Form.Item
                name="descripition"
                rules={[
                  {
                    required: true,
                    message: "Please input Last name!",
                  },
                ]}
                initialValue={buyerProfile?.descripition}
              >
                <RichText
                  handleRichtextBio={this.handleRichtextBio}
                  richtTextBiofieldProp={buyerProfile?.descripition}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  submitBuyerCardData = (cardData) => {
    this.setState({
      loading: true,
    });

    const {
      landingScreenData,
      Header,
      footer,
      aboutUsPage,
      WhyJoinUsPage,
      contactUsPage,
    } = this.props;
    const { richtTextBiofield } = this.state;
    landingScreenData.buyerProfile["title"] = cardData["title"];
    landingScreenData.buyerProfile["descripition"] =
      richtTextBiofield !== ""
        ? richtTextBiofield
        : landingScreenData.buyerProfile["descripition"];
    let landingScreen = landingScreenData;

    let data = {
      entityData: {
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        WhyJoinUsPage,
        contactUsPage,
      },
    };
    this.onFinish(data);
  };

  openReviewFolder = () => {
    const { buyerProfile } = this.props;
    const { reviewTitle, loading } = this.state;
    return (
      <div>
        <Row style={{ padding: "10px" }}>
          <Col span={24}>
            <div className="form-titles">Title</div>

            <Input
              placeholder="First Name"
              className="form-input"
              onChange={(e) =>
                this.setState({
                  reviewTitle: e.target.value,
                })
              }
              value={reviewTitle}
            />
            <Collapse bordered={false} defaultActiveKey={["1"]}>
              {buyerProfile &&
                buyerProfile.recentViews?.carouselInfo?.map((editCard) => (
                  <Panel
                    header={editCard.title}
                    key={editCard.tab_key}
                    className="customPanelStyle"
                  >
                    <Row style={{ padding: "10px" }}>
                      <Col span={24}>
                        <Form
                          initialValues={{ remember: true }}
                          // id="form"
                          onFinish={() => this.editReviewCardData(editCard)}
                        >
                          <div className="form-titles">Title</div>
                          <Form.Item
                            name="title"
                            rules={[
                              {
                                required: true,
                                message: "Please input title",
                              },
                            ]}
                            initialValue={editCard.title}
                          >
                            <Input
                              placeholder="First Name"
                              className="form-input"
                              onChange={(e) =>
                                this.setState({
                                  titleOfTheReviewer: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                          <div className="form-titles">Sub title</div>
                          <Form.Item
                            name="subtext"
                            rules={[
                              {
                                required: true,
                                message: "Please input title",
                              },
                            ]}
                            initialValue={editCard.subtext}
                          >
                            <Input
                              placeholder="First Name"
                              className="form-input"
                              onChange={(e) =>
                                this.setState({
                                  subTitleOfTheReviewer: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                          <div className="form-titles">Description</div>
                          <Form.Item
                            name="description"
                            rules={[
                              {
                                required: true,
                                message: "Please input Last name!",
                              },
                            ]}
                            initialValue={editCard.descripition}
                          >
                            <RichText
                              handleRichtextBio={this.handleRichtextBio}
                              richtTextBiofieldProp={editCard.descripition}
                            />
                          </Form.Item>
                          <Button
                            key="back"
                            onClick={() =>
                              this.setState({
                                showImageModal: false,
                                showEditModalText: "",
                              })
                            }
                          >
                            Cancel
                          </Button>
                          &nbsp; &nbsp;
                          <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            Save
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Panel>
                ))}
            </Collapse>
          </Col>
        </Row>
      </div>
    );
  };

  editReviewCardData = (cardData) => {
    this.setState({
      loading: true,
    });
    const {
      landingScreenData,
      Header,
      footer,
      aboutUsPage,
      WhyJoinUsPage,
      contactUsPage,
    } = this.props;

    const {
      richtTextBiofield,
      reviewTitle,
      subTitleOfTheReviewer,
      titleOfTheReviewer,
    } = this.state;

    landingScreenData?.buyerProfile.recentViews?.carouselInfo?.forEach(
      (element, index) => {
        if (element["tab_key"] === cardData["tab_key"]) {
          element["title"] =
            titleOfTheReviewer !== "" ? titleOfTheReviewer : element["title"];
          element["descripition"] =
            richtTextBiofield !== ""
              ? richtTextBiofield
              : element["descripition"];
          element["subtext"] =
            subTitleOfTheReviewer !== ""
              ? subTitleOfTheReviewer
              : element["subtext"];
        }
      }
    );
    landingScreenData.buyerProfile.recentViews["title"] =
      reviewTitle !== ""
        ? reviewTitle
        : landingScreenData?.buyerProfile.recentViews["title"];

    let landingScreen = landingScreenData;

    let data = {
      entityData: {
        landingScreen,
        Header,
        footer,
        aboutUsPage,
        contactUsPage,
        WhyJoinUsPage,
      },
    };

    this.onFinish(data);
  };

  onFinish = (data) => {
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserProfile && loginUserProfile.orgId) {
      let websiteId = loginUserProfile && loginUserProfile?.orgId;

      update("saveWebsitesData", websiteId, data)
        .then((result) => {
          if (result.success) {
            this.setState({
              showImageModal: false,
              loading: false,
            });
            // this.getWebsiteData();
            return true;
          }
        })
        .catch((error) => console.log(error));
    }
  };

  render() {
    const { buyerProfile } = this.props;
    const {
      buyersList,
      dataLoading,
      showEditModalText,
      showImageModal,
      loading,
    } = this.state;
    const userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));

    return (
      <div>
        {buyerProfile && (
          <div>
            {userLogin && (
              <Row
                style={{
                  justifyContent: "end",
                  fontSize: "24px",
                  background: " #F9FAFF",
                }}
              >
                {loginUserProfile && loginUserProfile?.userProfile === "A" && (
                  <EditTwoTone
                    style={{
                      float: "right",
                      fontSize: "24px",
                      marginRight: "16px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({
                        showImageModal: true,
                        showEditModalText: "buyerProfile",
                        reviewTitle: buyerProfile.recentViews.title,
                      });
                    }}
                  />
                )}
              </Row>
            )}
            <Row className="padding-top" style={{ background: " #F9FAFF" }}>
              <Col
                xl={{ span: 8, offset: 2 }}
                lg={{ span: 8, offset: 2 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
              >
                <div>
                  <p className="buyers-text">{buyerProfile.title}</p>
                  <p className="buyers-content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${buyerProfile.descripition}`,
                      }}
                    />
                  </p>
                  <Link to="/buyers" onClick={this.handleLink}>
                    <p style={{ color: "#F75B47", fontSize: "20px" }}>
                      Learn more{" "}
                      {
                        <img
                          src={arroworangeIcon}
                          style={{ marginLeft: "10px" }}
                          alt="arrow-icon"
                        />
                      }
                    </p>
                  </Link>
                </div>
              </Col>

              <Col
                xl={{ span: 10, offset: 2 }}
                lg={{ span: 12, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
              >
                <Row gutter={[30, 30]} className="row-gap-home">
                  {buyersList.map((buyer) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} key={buyer.id}>
                      <Link
                        to={`/buyersHome/${buyer.id}`}
                        style={{ textDecoration: "none" }}
                        onClick={this.handleLink}
                      >
                        <Card
                          hoverable
                          loading={dataLoading}
                          className="card-minHeight"
                        >
                          <p className="private-color">
                            <b className="card-title-homes">
                              {buyer.buyerPropertyDetails2.title}
                            </b>
                          </p>

                          {buyer.buyerPropertyDetails.stateTypeAdded &&
                            buyer.buyerPropertyDetails.updatedInterestedTowns &&
                            buyer.buyerPropertyDetails.updatedInterestedTowns
                              .length > 0 && (
                              <Row className="card-description-homes">
                                <p className="private-color-towns">
                                  Towns of Interest :{" "}
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {buyer.buyerPropertyDetails.updatedInterestedTowns.map(
                                      (city, index) => (
                                        <>
                                          {city.cityName + ", "}
                                          {/* {city.stateName.toLowerCase() ===
                                          "massachusetts"
                                            ? "MA"
                                            : city.stateName.toLowerCase() ===
                                              "connecticut"
                                            ? "CT"
                                            : "RI"} */}
                                          {city.stateName}
                                          {index !==
                                          buyer.buyerPropertyDetails
                                            .updatedInterestedTowns.length -
                                            1
                                            ? ". "
                                            : "."}
                                        </>
                                      )
                                    )}
                                  </span>
                                </p>
                              </Row>
                            )}

                          <Row>
                            <p className="private-color-budget">
                              {"Budget :"}&nbsp;
                            </p>
                            <p className="private-color-budget">
                              <NumberFormat
                                value={buyer.buyerPropertyDetails.minprice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                              {" - "}
                              <NumberFormat
                                value={buyer.buyerPropertyDetails.maxprice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </p>
                          </Row>
                        </Card>
                      </Link>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            {loginUserProfile && loginUserProfile?.userProfile === "A" && (
              <Row
                style={{
                  justifyContent: "end",
                  fontSize: "24px",
                  background: " #fff",
                }}
              >
                <EditTwoTone
                  style={{
                    float: "right",
                    fontSize: "24px",
                    marginRight: "16px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      showImageModal: true,
                      showEditModalText: "reviewCard",
                      reviewTitle: buyerProfile.recentViews.title,
                    });
                  }}
                />
              </Row>
            )}
            <Row className="padding-row-home">
              <Col
                xl={{ span: 8, offset: 1 }}
                lg={{ span: 8, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
              >
                <p className="contentTitle">{buyerProfile.recentViews.title}</p>
              </Col>
              <Col
                xl={{ span: 13, offset: 1 }}
                lg={{ span: 13, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
              >
                <div className="home-carousel">
                  {/* 
                Don't delete
                <Carousel itemsToShow={1} itemsToScroll={1}>
                  {testimonialsList.map((testimonial) => (
                    <Col span={24}>
                      <Row style={{ padding: "3vw" }}>
                        <Col span={24}>
                          <List.Item>
                            <Skeleton
                              avatar
                              title={false}
                              loading={dataLoading}
                              active
                            >
                              <List.Item.Meta
                                avatar={
                                  <Button
                                    type="ghost"
                                    shape="circle"
                                    size="large"
                                    block={true}
                                  >
                                    {testimonial.postedBy !== null
                                      ? testimonial.postedBy.firstName
                                          .charAt(0)
                                          .toUpperCase()
                                      : testimonial.firstName
                                          .charAt(0)
                                          .toUpperCase()}
                                  </Button>
                                }
                                title={
                                  testimonial.postedBy !== null
                                    ? testimonial.postedBy.firstName +
                                      " " +
                                      testimonial.postedBy.lastName
                                    : testimonial.firstName +
                                      " " +
                                      testimonial.lastName
                                }
                                description={
                                  <Col span={24}>
                                    <h5>
                                      <Rate
                                        disabled
                                        defaultValue={testimonial.rating}
                                      />
                                      <span
                                        style={{
                                          fontWeight: "300",
                                          color: "grey",
                                          float: "right",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {moment(testimonial.updatedAt).format(
                                          "lll"
                                        )}
                                      </span>
                                    </h5>
                                  </Col>
                                }
                              />
                            </Skeleton>
                          </List.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: "-10px" }}>
                          <h5>{testimonial.testimonialDesc}</h5>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Carousel> 
                Don't delete
                */}

                  <Carousel itemsToShow={1} itemsToScroll={1}>
                    {buyerProfile.recentViews.carouselInfo.map((data) => (
                      <Item>
                        <div
                          className="contentStyle"
                          style={{ marginTop: "0px" }}
                        >
                          <p
                            style={{
                              marginBottom: "0.5rem",
                              fontWeight: "bold",
                              fontSize: "22px",
                              color: "black",
                            }}
                          >
                            {data.title}
                          </p>
                          <p
                            style={{
                              marginBottom: "0.5rem",
                              fontWeight: "bold",
                              color: "#727272",
                              fontSize: "16px",
                            }}
                          >
                            {data.subtext}
                          </p>
                          <p
                            className="private-color"
                            style={{ color: "#414555", fontSize: "18px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${data.descripition}`,
                              }}
                            />
                          </p>
                        </div>
                      </Item>
                    ))}
                  </Carousel>
                </div>
              </Col>
            </Row>
          </div>
        )}
        <Modal
          className="modal-black-close"
          visible={showImageModal}
          title="Change text"
          // onOk={() => this.changeProfile()}
          onCancel={() =>
            this.setState({
              showImageModal: false,
              showEditModalText: "",
            })
          }
          footer={
            showEditModalText !== "reviewCard" && [
              <Button
                key="back"
                onClick={() =>
                  this.setState({
                    showImageModal: false,
                    showEditModalText: "",
                  })
                }
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                form="form"
                loading={loading}
              >
                Save
              </Button>,
            ]
          }
          width={
            window.screen.width >= "768"
              ? "40%"
              : window.screen.width > "501" && window.screen.width <= "767"
              ? "50%"
              : "100%"
          }
        >
          {showEditModalText !== "reviewCard"
            ? this.openBuyerProfileCard()
            : this.openReviewFolder()}
        </Modal>
      </div>
    );
  }
}

export default BuyerProfileContext;
