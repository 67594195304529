import React from "react";
import { Row, Col, Avatar, Card } from "antd";
import { withRouter } from "react-router";
import "antd/dist/antd.css";
import axios from "axios/index";
import { withAppContext } from "../store/contextStore";
export class MapScore extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scoreInfo: "",
    };
  }

  componentDidMount = () => {
    this.getScore();
  };

  getScore = async () => {
    const { lat, lng } = this.props && this.props;

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_SCORE_KEY,
    };

    const method = "get";
    const url = `https://gs-api.greatschools.org/nearby-schools?lat=${lat}&lon=${lng}&limit=4&distance=5`;
    const data = {};

    const dataObj = {
      method,
      url,
      data,
      headers,
    };
    axios(dataObj)
      .then((response) => {
        this.setState({
          scoreInfo: response.data.schools,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  render() {
    const { scoreInfo } = this.state;

    return (
      <Col span="24">
        <Row>
          {scoreInfo &&
            scoreInfo.length > 0 &&
            scoreInfo.map((school) => (
              <Col
                xl={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ padding: "0.5vw" }}
              >
                <Card style={{ minHeight: "200px" }}>
                  <Row>
                    <Col span={8}>
                      <Avatar
                        size={window.screen.width > 992 ? 120 : 60}
                        style={{
                          backgroundColor: "#3045d2",
                          verticalAlign: "middle",
                        }}
                      >
                        {school && school.rating ? school.rating : 0}/10
                      </Avatar>
                    </Col>
                    <Col span={16}>
                      <h2>{school.name}</h2>
                      <h5>{school["school-summary"]}</h5>
                      {/* <h5>{school["rating-description"]}</h5> */}
                      <Row justify="space-between">
                        <h4>Grades :{school.level}</h4>
                        <h4>
                          Distance :{parseFloat(school.distance).toFixed(2)}
                        </h4>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
        </Row>
        <p style={{ textAlign: "left",color:"black" }}>
          This data is not being sourced from NEREN
        </p>
      </Col>
    );
  }
}

export default withAppContext(withRouter(MapScore));
