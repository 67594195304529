import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row, Form, Input, Checkbox } from "antd";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { withAppContext } from "../../../store/contextStore";
import Advertisement from "../../../common/Advertisement";
class GeneralDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      citySearch: [],
      city: "",
      addressState: "",
      zipCode: "",
      makePrivateAddress: "",
      address: "",
    };
  }

  componentDidMount() {
    this.setState({
      adverCount: 1,
    });
    const { sellerHomeDetails } = this.props;
    const address =
      sellerHomeDetails.privateDetails &&
      sellerHomeDetails.privateDetails.address &&
      sellerHomeDetails.privateDetails.address;

    this.setState({
      citySearch: address,
      city:
        sellerHomeDetails.privateDetails &&
        sellerHomeDetails.privateDetails.city,
      addressState:
        sellerHomeDetails.privateDetails &&
        sellerHomeDetails.privateDetails.state,
      zipCode:
        sellerHomeDetails.privateDetails &&
        sellerHomeDetails.privateDetails.postalCode,
    });
  }

  hanldeCheck = (e) => {
    this.setState({ makePrivateAddress: e.target.checked });
  };

  onFinish = (e) => {
    const { zipCode, city, addressState, makePrivateAddress, citySearch } =
      this.state;
    e["city"] = city;
    e["state"] = addressState;
    e["postalCode"] = zipCode;
    e["makePrivateAddress"] = makePrivateAddress;
    e["address"] = citySearch;
    this.props.handleForm(e, "PRIVATE_DETAILS");
  };

  handleSelect = (address) => {
    this.setState(
      {
        city: "",
        addressState: "",
        zipCode: "",
      },
      () => {
        setTimeout(() => {
          this.getFormattedAddress(address);
        }, 600);
      }
    );
  };

  getFormattedAddress = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          city: cityValue,
          addressState: stateValue,
          zipCode: zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const { city, addressState, zipCode } = this.state;
    const { sellerHomeDetails } = this.props;

    return (
      <div>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="privateD-title"></div>
          </Col>
          <Col
            xl={{ span: 16, offset: 0 }}
            lg={{ span: 16, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Form
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              size="large"
              id="form"
            >
              <Row style={{ backgroundColor: "#F9FAFF" }}>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">Address</div>
                  <Form.Item className="google-form-search-add-home">
                    <GooglePlacesAutocomplete
                      className="form-input"
                      apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        // citySearch,
                        defaultInputValue: `${
                          sellerHomeDetails &&
                          sellerHomeDetails.privateDetails &&
                          sellerHomeDetails.privateDetails.address
                        }`,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address);
                        },
                      }}
                    />
                    <Checkbox
                      onChange={this.hanldeCheck}
                      defaultChecked={
                        sellerHomeDetails &&
                        sellerHomeDetails.privateDetails &&
                        sellerHomeDetails.privateDetails.makePrivateAddress &&
                        sellerHomeDetails.privateDetails.makePrivateAddress
                      }
                    >
                      Make private
                    </Checkbox>
                  </Form.Item>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                      fontWeight: " 600",
                      letterSpacing: "0px",
                      color: "#131419",
                      marginTop: "-5px",
                      marginBottom: "5px",
                    }}
                  >
                    City / Town
                  </div>
                  <Input className="form-input" value={city} />
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">State</div>
                  <Input className="form-input" value={addressState} />

                  <div className="form-titles">Zip Code</div>
                  <Input
                    className="form-input"
                    value={zipCode}
                    style={{ marginTop: "12px" }}
                  />
                  <Form.Item
                    name="postalCode"
                    // initialValue={
                    //   sellerHomeDetails &&
                    //   sellerHomeDetails.privateDetails.postalCode
                    // }
                  ></Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Advertisement
              otherAds={false}
              adType="Square"
              adWhereToShow="Homes"
              adLimit="1"
              zipCode=""
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default withAppContext(withRouter(GeneralDetails));
