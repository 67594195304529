import React, { Component } from "react";
import { Row, Col, notification, Button, Form, Input, Modal } from "antd";
import { add } from "../api/Apis";
export default class Invitation extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  sendLenderInivitaion = (value) => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const data = {
      entityData: {
        firstName: value.lenderFirstName,
        lastName: value.lenderLastName,
        email: value.lenderEmail,
        agentName: userDetails.firstName + " " + userDetails.lastName,
        agentId: userDetails.id,
        typeOfUser: this.props.invitationTitle,
      },
    };
    try {
      add("sendInvitation", data)
        .then((result) => {
          if (result.success) {
            notification["success"]({
              message: "Invitation sent successfully..",
            });
            this.props.handleCloseInvitationModal();
            if (this.props.buyerInvitaion) {
              const agentName =
                value.lenderFirstName + " " + value.lenderLastName;
              this.props.submitPreferedAgent(agentName, value.lenderEmail);
            }
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
      this.props.handleCloseInvitationModal();
      notification["error"]({
        message: "Something went wrong... please try again",
      });
    }
  };

  handleInvitaionpPop = () => {
    return (
      <>
        <Row style={{ padding: "10px" }}>
          <Col span={24}>
            <Form
              initialValues={{ remember: true }}
              onFinish={this.sendLenderInivitaion}
              id="form"
            >
              <div className="form-titles">First Name</div>
              <Form.Item
                name="lenderFirstName"
                rules={[
                  {
                    required: true,
                    message: "Please input First name",
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  className="form-input"
                  // value={sellerName}
                />
              </Form.Item>
              <div className="form-titles">Last Name</div>
              <Form.Item
                name="lenderLastName"
                rules={[
                  {
                    required: true,
                    message: "Please input Last name!",
                  },
                ]}
              >
                <Input
                  placeholder="Last Name"
                  className="form-input"
                  // value={sellerName}
                />
              </Form.Item>
              <div className="form-titles">Email</div>
              <Form.Item
                name="lenderEmail"
                rules={[
                  {
                    required: true,
                    message: "Please input email!",
                  },
                ]}
              >
                <Input placeholder="Email" className="form-input" />
              </Form.Item>
            </Form>
          </Col>
          {this.props.buyerInvitaion && (
            <Col span={24}>
              <Button
                key="back"
                onClick={() => this.props.handleCloseInvitationModal()}
                style={{ paddingRight: "10%" }}
              >
              &nbsp;&nbsp; Cancel  
              </Button>
              &nbsp;&nbsp;
              <Button
                key="submit"
                type="primary"
                // onClick={() => this.props.handleCloseInvitationModal()}
                htmlType="submit"
                form="form"
              >
                Invite and Continue
              </Button>
            </Col>
          )}
        </Row>
      </>
    );
  };
  render() {
    const { enableInvitationModal, invitationTitle, buyerInvitaion } =
      this.props;
    return (
      <div>
        {buyerInvitaion && buyerInvitaion ? (
          this.handleInvitaionpPop()
        ) : (
          <Modal
            className="modal-black-close"
            visible={enableInvitationModal}
            title={`Send invitation to ${invitationTitle}`}
            onCancel={() => this.props.handleCloseInvitationModal()}
            footer={[
              <Button
                key="back"
                onClick={() => this.props.handleCloseInvitationModal()}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                // onClick={() => this.update(true)}
                htmlType="submit"
                form="form"
              >
                Invite
              </Button>,
            ]}
          >
            {this.handleInvitaionpPop()}
            {/* <Row style={{ padding: "10px" }}>
            <Col span={24}>
              <Form
                initialValues={{ remember: true }}
                onFinish={this.sendLenderInivitaion}
                id="form"
              >
                <div className="form-titles">First Name</div>
                <Form.Item
                  name="lenderFirstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input First name",
                    },
                  ]}
                >
                  <Input
                    placeholder="First Name"
                    className="form-input"
                    // value={sellerName}
                  />
                </Form.Item>
                <div className="form-titles">Last Name</div>
                <Form.Item
                  name="lenderLastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Last name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Last Name"
                    className="form-input"
                    // value={sellerName}
                  />
                </Form.Item>
                <div className="form-titles">Email</div>
                <Form.Item
                  name="lenderEmail"
                  rules={[
                    {
                      required: true,
                      message: "Please input email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" className="form-input" />
                </Form.Item>
              </Form>
            </Col>
          </Row> */}
          </Modal>
        )}
      </div>
    );
  }
}
