import React, { Component } from "react";
import "antd/dist/antd.css";
import { Row, Col, notification, Button, Popover } from "antd";
import {
  EditTwoTone,
  ShareAltOutlined,
  FacebookOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import Layout from "antd/lib/layout/layout";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";
import NumberFormat from "react-number-format";
import { withAppContext } from "../../store/contextStore";
import { update } from "../../api/Apis";
import bedIcon from "../../images/awesome-bed-icon.png";
import bathIcon from "../../images/awesome-bath-icon.png";
import reSize from "../../images/sq-feet-icon.png";
import agentPic from "../../images/profileAvatar.png";
import privatePost from "../../images/protection.png";
import arrowIcon from "../../images/arrowIcon.png";
import DeleteRecord from "../../common/DeleteRecord";
class BuyerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minValue: 0,
      maxValue: 10,
      showFavoriteFalse: false,
      favorites: [],
      currentPage: 1,
      enableSignupForm: false,
      validUser: false,
      EditIcon: false,
    };
  }

  componentDidMount() {
    AOS.init({
      duration: 1500,
    });
    this.checkUserValid();
    let agentId = JSON.parse(localStorage.getItem("userDetails"));
    let userId = this.props.match.params.id;
    if (
      (agentId && userId === agentId.id) ||
      (agentId && agentId.userProfile === "A")
    ) {
      this.setState({
        EditIcon: true,
      });
    } else {
      this.setState({
        EditIcon: false,
      });
    }

    if (
      window.location.pathname ===
      "/VirtualOffice/ViewAllContacts/" + this.props.match.params.id
    ) {
      this.setState({
        EditIcon: true,
      });
    }
  }

  checkUserValid = () => {
    const { value } = this.props;
    let itemUserId = value.userid;
    let buyerAgentId = value.buyerAgentId;
    const { userDetails } = this.props.context.state;
    if (itemUserId === userDetails.id || buyerAgentId === userDetails.id) {
      this.setState({ validUser: true });
    } else {
      this.setState({ validUser: false });
    }
  };

  handlePagination = async (val) => {
    if (val <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 10,
      });
    } else {
      this.setState({
        minValue: this.state.maxValue,
        maxValue: val * 10,
      });
    }
    await this.setState({
      currentPage: val,
    });
  };

  openNotification = () => {
    notification.info({
      message:
        "Please create your free account today to save your favorite listings and be notified of new listings",
      style: {
        fontWeight: "500",
      },
    });
    this.setState({
      enableSignupForm: true,
    });
  };

  handleEditHome = async () => {
    const {
      buyerPrivateDetails,
      buyerPropertyDetails,
      buyerPropertyFeatures,
      buyerPropertyDetails2,
    } = this.props.value.editBuyersData;
    this.setState({
      homeEditFalse: true,
    });
    localStorage.setItem(
      "buyerData",
      JSON.stringify(this.props.value.editBuyersData)
    );
    if (buyerPrivateDetails) {
      await this.props.context.setBuyerHomeDetails(
        buyerPrivateDetails,
        "BUYER_PRIVATE_DETAILS"
      );
    }

    if (buyerPropertyDetails) {
      await this.props.context.setBuyerHomeDetails(
        buyerPropertyDetails,
        "BUYER_PROPERTY_DETAILS"
      );
    }

    if (buyerPropertyFeatures) {
      await this.props.context.setBuyerHomeDetails(
        buyerPropertyFeatures,
        "BUYER_PROPERTY_FEATURES"
      );
    }

    if (buyerPropertyDetails2) {
      await this.props.context.setBuyerHomeDetails(
        buyerPropertyDetails2,
        "BUYER_PROPERTY_DETAILS2"
      );
    }
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  updateSavedSearchWithBuyerPref = async (searchListData, id, userId) => {
    const updateSearch = searchListData.filter(
      (deleteId) => deleteId.preferenceId === id
    );

    let remainingList = searchListData.filter(
      (deleteId) => deleteId.preferenceId !== id
    );
    if (updateSearch && updateSearch.length > 0) {
      let removePrefId = updateSearch[0];
      delete removePrefId.preferenceId;
      removePrefId["savedToBuyerPref"] = false;
      if (remainingList && remainingList.length > 0) {
        remainingList.push(removePrefId);
      }
    }
    const updateSavedSearch = await Object.assign(
      {},
      {
        entityData: {
          userSavedSearch: remainingList,
        },
      }
    );
    try {
      update("users", userId, updateSavedSearch).then((result) => {
        if (result.success) {
          this.props.context.setUserDetails(result.data.data);
        }
      });
    } catch (error) {
      notification["error"]({
        message: "Something went wrong!",
        description: "Please try again",
      });
    }
  };

  cancel = (e) => {
    console.log(e);
  };

  checkShowMatches = (buyerNeed) => {
    const filter = [];
    if (
      buyerNeed.editBuyersData &&
      buyerNeed.editBuyersData.buyerPropertyDetails
    ) {
      filter.push({
        minprice: buyerNeed.editBuyersData.buyerPropertyDetails.minprice,
        maxprice: buyerNeed.editBuyersData.buyerPropertyDetails.maxprice,
        type: "price",
      });
    }

    if (
      buyerNeed.minArea &&
      buyerNeed.minArea &&
      buyerNeed.maxArea &&
      buyerNeed.maxArea
    ) {
      filter.push({
        minarea: parseInt(buyerNeed.minArea),
        maxarea: parseInt(buyerNeed.maxArea),
        type: "area",
      });
    }

    if (buyerNeed.beds && buyerNeed.beds) {
      filter.push({ minbeds: buyerNeed.beds, type: "beds" });
    }

    if (buyerNeed.baths && buyerNeed.baths) {
      filter.push({ minbaths: buyerNeed.baths, type: "baths" });
    }

    if (
      buyerNeed.editBuyersData &&
      buyerNeed.editBuyersData.buyerPropertyFeatures &&
      buyerNeed.editBuyersData.buyerPropertyFeatures.propertyType
    ) {
      const types = [];
      let newList = buyerNeed.editBuyersData.buyerPropertyFeatures.propertyType;
      if (newList) {
        if (
          newList.includes("Commercial Land") ||
          newList.includes("Residential Land") ||
          newList.includes("Land")
        ) {
          newList.indexOf("Residential Land");
          types.push("LND");
        }

        if (newList.includes("Mobile Homes")) {
          newList.indexOf("Mobile Homes");
          types.push("MBL");
        }

        if (newList.includes("Residential Multi Family Home")) {
          newList.indexOf("Residential Multi Family Home");
          types.push("MLF");
        }

        if (newList.includes("Residential Single Family Home")) {
          const index = newList.indexOf("Residential Single Family Home");
          newList.splice(index, 1);
          newList.push("Detached", "Attached");
          types.push("RES");
        }
        if (newList.includes("Residential Studio")) {
          const index = newList.indexOf("Residential Studio");
          newList.splice(index, 1);
          newList.push("Studio");
          types.push("RES");
        }
        if (newList.includes("Residential Apartment")) {
          const index = newList.indexOf("Residential Apartment");
          newList.splice(index, 1);
          newList.push("Apartment");
          types.push("RES");
        }

        if (newList.includes("Residential Rental")) {
          newList.indexOf("Residential Rental");
          types.push("RNT");
        }

        if (newList.includes("Residential Condo")) {
          const index = newList.indexOf("Residential Condo");
          newList.splice(index, 1);
          types.push("CND");
          newList.push("Condominium", "Condo");
        }
        if (newList.includes("Residential Condominium")) {
          const index = newList.indexOf("Residential Condominium");
          newList.splice(index, 1);
          types.push("CND");
          newList.push("Condominium", "Condo");
        }
        if (newList.includes("Condominium/Co-op")) {
          const index = newList.indexOf("Condominium/Co-op");
          newList.splice(index, 1);
          types.push("CND");
          newList.push("Condominium", "Condo");
        }

        if (
          newList.includes("Commercial MLS") ||
          newList.includes("Commercial Office") ||
          newList.includes("Commerical/Industrial") ||
          newList.includes("Business Opportunity")
        ) {
          types.push("CRE");
        }

        if (newList.includes("Coopertive")) {
          types.push("Coopertive");
        }
      }

      filter.push({
        subTypeText: types,
        type: "subtype",
        showPropertyType:
          buyerNeed.editBuyersData.buyerPropertyFeatures.propertyType,
      });
    }

    if (
      buyerNeed.editBuyersData &&
      buyerNeed.editBuyersData.buyerPropertyDetails &&
      buyerNeed.editBuyersData.buyerPropertyDetails.propertyType
    ) {
      const types = [];
      let newList = buyerNeed.editBuyersData.buyerPropertyDetails.propertyType;
      if (newList) {
        if (
          newList.includes("Commercial Land") ||
          newList.includes("Residential Land") ||
          newList.includes("Land")
        ) {
          newList.indexOf("Residential Land");
          types.push("LND");
        }

        if (newList.includes("Mobile Homes")) {
          newList.indexOf("Mobile Homes");
          types.push("MBL");
        }

        if (newList.includes("Residential Multi Family Home")) {
          newList.indexOf("Residential Multi Family Home");
          types.push("MLF");
        }

        if (newList.includes("Residential Single Family Home")) {
          const index = newList.indexOf("Residential Single Family Home");
          newList.splice(index, 1);
          newList.push("Detached", "Attached");
          types.push("RES");
        }
        if (newList.includes("Residential Studio")) {
          const index = newList.indexOf("Residential Studio");
          newList.splice(index, 1);
          newList.push("Studio");
          types.push("RES");
        }
        if (newList.includes("Residential Apartment")) {
          const index = newList.indexOf("Residential Apartment");
          newList.splice(index, 1);
          newList.push("Apartment");
          types.push("RES");
        }

        if (newList.includes("Residential Rental")) {
          newList.indexOf("Residential Rental");
          types.push("RNT");
        }

        if (newList.includes("Residential Condo")) {
          const index = newList.indexOf("Residential Condo");
          newList.splice(index, 1);
          types.push("CND");
          newList.push("Condominium", "Condo");
        }
        if (newList.includes("Residential Condominium")) {
          const index = newList.indexOf("Residential Condominium");
          newList.splice(index, 1);
          types.push("CND");
          newList.push("Condominium", "Condo");
        }
        if (newList.includes("Condominium/Co-op")) {
          const index = newList.indexOf("Condominium/Co-op");
          newList.splice(index, 1);
          types.push("CND");
          newList.push("Condominium", "Condo");
        }

        if (
          newList.includes("Commercial MLS") ||
          newList.includes("Commercial Office") ||
          newList.includes("Commerical/Industrial") ||
          newList.includes("Business Opportunity")
        ) {
          types.push("CRE");
        }

        if (newList.includes("Coopertive")) {
          types.push("Coopertive");
        }
      }
      filter.push({
        subTypeText: types,
        type: "subtype",
        showPropertyType:
          buyerNeed.editBuyersData.buyerPropertyDetails.propertyType,
      });
    }

    if (
      buyerNeed.editBuyersData &&
      buyerNeed.editBuyersData.buyerPropertyDetails &&
      buyerNeed.editBuyersData.buyerPropertyDetails.updatedInterestedTowns
    ) {
      filter.push({
        multiLocations:
          buyerNeed.editBuyersData.buyerPropertyDetails.updatedInterestedTowns,
        type: "location",
      });
    }
    this.props.buyerTitleInfo(buyerNeed.title);
    this.props.showMatches();
    this.props.handleMatches(filter);
  };

  shareThisHome = (e) => {
    if (this.props.value.showOnBuyersPage) {
      const shareUrl = `${process.env.REACT_APP_API_GATEWAY}/noAuth/shareBuyersHome/${e.id}`;
      this.setState({
        shareUrl,
      });
    }
  };

  showPrivate = () => {
    notification["error"]({
      message: "this home make private",
    });
  };

  render() {
    const { value } = this.props;
    const { EditIcon } = this.state;
    const contentMsg = <div>Only public buyer profiles can be shared </div>;

    const content = (
      <div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            onClick={() => {
              window.FB.ui(
                {
                  display: "popup",
                  method: "share",
                  href: this.state.shareUrl,
                },
                function (response) {}
              );
            }}
            style={{ textDecoration: "none" }}
            href={() => false}
            rel="noreferrer"
          >
            <FacebookOutlined style={{ fontSize: 18 }} />
            &nbsp;&nbsp; facebook
          </a>
        </div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://api.whatsapp.com/send?text=${this.state.shareUrl}`}
            style={{ textDecoration: "none" }}
          >
            <WhatsAppOutlined style={{ fontSize: 18 }} />
            &nbsp;&nbsp; Whatsapp
          </a>
        </div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://twitter.com/intent/tweet?text=${this.state.shareUrl}`}
            style={{ textDecoration: "none" }}
          >
            <TwitterOutlined style={{ fontSize: 18 }} /> &nbsp;&nbsp; Twitter
          </a>
        </div>
      </div>
    );
    return (
      <div
        key={value.id}
        data-aos-offset="100"
        data-aos-delay="200"
        data-aos-once="true"
        data-aos="fade-up"
        data-aos-easing="ease-in"
      >
        <Layout
          style={{
            backgroundColor: value.buyersList ? "white" : "",
          }}
        >
          <Row
            className="home-card-space-bw mob-card"
            style={{ background: "rgb(240 242 245)", borderRadius: "5px" }}
          >
            <Col
              xl={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              className="ant-card-hoverable"
              style={{ borderRadius: "5px" }}
            >
              <Row
                style={{ background: "white", borderRadius: "5px" }}
                className="mobile-buyer-card-padding"
              >
                <Col
                  xl={{ span: 16, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 16, offset: 0 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Link
                    className="no-lines"
                    to={{
                      pathname: `/buyersHome/${value.id}`,
                      state: this.props.match.path === "/buyers" && true,
                    }}
                    onClick={this.handleLink}
                  >
                    <Row>
                      <span
                        className="searchHomes-titles"
                        style={{ textTransform: "capitalize" }}
                      >
                        {value.title}
                      </span>
                    </Row>

                    {value && !value.showOnBuyersPage ? (
                      <Row
                        className="hide-post-type-mobile"
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                        }}
                      >
                        <img
                          src={privatePost}
                          alt="postType"
                          width="12px"
                          height="14px"
                          style={{ marginTop: 2 }}
                        />
                        <p
                          style={{
                            marginLeft: "8px",
                            color: "#414555",
                            fontSize: "12px",
                          }}
                        >
                          PRIVATE POST
                        </p>
                        {value.buyersList && (
                          <Col offset={1}>
                            <span className="when-to-added-buyerhome">
                              Added {moment(value.createdAt).fromNow()}
                            </span>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      <Row
                        className="hide-post-type-mobile"
                        style={{
                          paddingTop: "1%",
                          paddingBottom: "1%",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "8px",
                            color: "#414555",
                            fontSize: "12px",
                          }}
                        ></p>
                      </Row>
                    )}
                  </Link>
                </Col>
                <Col
                  xl={{ span: 6, offset: 2 }}
                  md={{ span: 24 }}
                  lg={{ span: 8, offset: 0 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {this.props.value.buyersList ? (
                    <Col
                      span={8}
                      offset={16}
                      onClick={this.handleEditHome}
                      style={{ marginTop: "5px", marginBottom: "0px" }}
                      className="hide-desktop-version-none"
                    >
                      {EditIcon && (
                        <>
                          <Row>
                            <Col
                              span={8}
                              onClick={() =>
                                this.shareThisHome(this.props.value)
                              }
                            >
                              <Popover
                                trigger="click"
                                content={
                                  this.props.value.showOnBuyersPage
                                    ? content
                                    : contentMsg
                                }
                                title="Share this Buyer Profile"
                              >
                                <ShareAltOutlined
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    color: "green",
                                  }}
                                />
                              </Popover>
                            </Col>
                            <Col span={8}>
                              {window.location.pathname ===
                              "/VirtualOffice/ViewAllContacts/" +
                                this.props.match.params.id ? (
                                <EditTwoTone
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.props.handleToEditListing(
                                      this.props.value.id
                                    )
                                  }
                                />
                              ) : (
                                <Link
                                  to={
                                    this.props.value.sellerVsBuyerVsVendor
                                      ? {
                                          pathname: `/add/buyerform/${this.props.value.id}`,
                                          state:
                                            this.props.value &&
                                            this.props.value,
                                        }
                                      : {
                                          pathname: `/add/buyerProfile/${this.props.value.id}`,
                                          state:
                                            this.props.value &&
                                            this.props.value,
                                        }
                                  }
                                >
                                  <EditTwoTone
                                    style={{
                                      fontSize: "20px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Link>
                              )}
                            </Col>

                            <Col span={8}>
                              <DeleteRecord
                                recordId={value.id}
                                getHomesList={this.props.getBuyerHomeDetails}
                                updateSavedSearchWithBuyerPref={
                                  this.updateSavedSearchWithBuyerPref
                                }
                                apiPath={"buyers"}
                                fontSizeProp={"18px"}
                                deletionType={"DeleteOutlined"}
                                deletionSuccessMessage={
                                  "Your record deleted successfully"
                                }
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  ) : (
                    <Row>
                      <Col
                        xs={{ span: 14 }}
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                        sm={{ span: 14 }}
                        md={{ span: 14 }}
                      >
                        <Link
                          className="no-lines"
                          to={{
                            pathname: `/buyersHome/${value.id}`,
                            state: this.props.match.path === "/buyers" && true,
                          }}
                          onClick={this.handleLink}
                        >
                          <Row className="buyer-price-range">
                            <span
                              className="searchHomes-titles"
                              style={{ textTransform: "capitalize" }}
                            >
                              <NumberFormat
                                value={value.minprice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />{" "}
                              -&nbsp;
                              <NumberFormat
                                value={value.maxprice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </span>
                          </Row>
                        </Link>
                      </Col>
                      <Col
                        xs={{ span: 10 }}
                        lg={{ span: 24 }}
                        md={{ span: 10 }}
                        xl={{ span: 24 }}
                        sm={{ span: 10 }}
                      >
                        <Row className="home-card-date">
                          <div className="layout-last-step">
                            <span className="layout-subtitles">
                              <p
                                className="mobile-view-padding-down"
                                style={{
                                  fontSize: "14px",
                                  marginRight: "10px",
                                  marginBottom: "0",
                                }}
                              >
                                {value.date}
                              </p>
                            </span>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col
                  xl={{ span: 16 }}
                  lg={{ span: 16 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Link
                    className="no-lines"
                    to={{
                      pathname: `/buyersHome/${value.id}`,
                      state: this.props.match.path === "/buyers" && true,
                    }}
                    onClick={this.handleLink}
                  >
                    <Row>
                      <div className="layout-last-step">
                        <span className="layout-subtitles">
                          <p
                            className="mobile-view-padding-down"
                            style={{
                              fontSize: "14px",
                              color: "#000",
                              marginRight: "22px",
                              marginBottom: "0px",
                            }}
                          >
                            {value.type && typeof value.type !== "string"
                              ? value.type.join(", ")
                              : value.type}
                          </p>
                        </span>
                      </div>
                    </Row>
                    {value.stateTypeAdded &&
                      value.updatedInterestedTowns &&
                      value.updatedInterestedTowns.length > 0 && (
                        <Row className="mobile-view-padding-down">
                          <div className="layout-last-step">
                            <span className="layout-subtitles">
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginRight: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                Towns of Interest:{" "}
                              </p>
                            </span>
                            <span>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  marginBottom: "0px",
                                }}
                              >
                                {value.stateTypeAdded &&
                                value.updatedInterestedTowns
                                  ? value.updatedInterestedTowns.length > 0 &&
                                    value.updatedInterestedTowns.map(
                                      (city, index) => (
                                        <>
                                          {city.cityName +
                                            ", " +
                                            city.stateName}
                                          {index !==
                                          value.updatedInterestedTowns.length -
                                            1
                                            ? ". "
                                            : ". "}
                                        </>
                                      )
                                    )
                                  : ""}
                              </p>
                            </span>
                          </div>
                        </Row>
                      )}
                    {value.editBuyersData &&
                      value.editBuyersData.buyerPropertyDetails2 &&
                      value.editBuyersData.buyerPropertyDetails2
                        .description && (
                        <Row style={{ marginLeft: "10px" }}>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#000",
                              marginRight: "22px",
                            }}
                          >
                            {value.editBuyersData &&
                              value.editBuyersData.buyerPropertyDetails2 &&
                              value.editBuyersData.buyerPropertyDetails2.description.substring(
                                0,
                                value.editBuyersData.buyerPropertyDetails2
                                  .description.length >= 75
                                  ? 75
                                  : value.editBuyersData.buyerPropertyDetails2
                                      .description.length
                              ) + "..."}
                          </p>
                        </Row>
                      )}
                    <Row>
                      <div className="layout-last-step">
                        <span>
                          <img
                            src={bedIcon}
                            alt="space"
                            width="18px"
                            height="10px"
                          />
                        </span>
                        <span className="layout-subtitles">
                          <p className="beds-size-mobile">{value.beds} Beds</p>
                        </span>
                      </div>
                      <div
                        className="layout-last-step"
                        style={{ marginLeft: "20px" }}
                      >
                        <span>
                          <img
                            src={bathIcon}
                            alt="space"
                            width="18px"
                            height="10px"
                          />
                        </span>
                        <span className="layout-subtitles">
                          <p className="beds-size-mobile">
                            {value.baths} Baths
                          </p>
                        </span>
                      </div>
                      <div className="desktop-display">
                        <span>
                          <img
                            src={reSize}
                            alt="space"
                            width="16px"
                            height="10px"
                          />
                        </span>
                        <span className="layout-subtitles">
                          <p className="beds-size-mobile">
                            {value.minArea} sq.ft - {value.maxArea} sq.ft
                          </p>
                        </span>
                      </div>
                    </Row>
                    <div className="bath-left-margin">
                      <span>
                        <img
                          src={reSize}
                          alt="space"
                          width="16px"
                          height="10px"
                        />
                      </span>
                      <span className="layout-subtitles">
                        <p className="beds-size-mobile">
                          {value.minArea} sq.ft - {value.maxArea} sq.ft
                        </p>
                      </span>
                    </div>
                  </Link>
                </Col>

                <Col
                  xl={{ span: 6, offset: 2 }}
                  md={{ span: 24 }}
                  lg={{ span: 8, offset: 0 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {EditIcon && (
                    <Row>
                      <Col
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                        className="edit-delete-option-mobile"
                        style={{ paddingTop: "8px" }}
                        onClick={this.handleEditHome}
                      >
                        {EditIcon && (
                          <>
                            <Row>
                              <Col span={12}>
                                <Link
                                  to={
                                    this.props.value.sellerVsBuyerVsVendor
                                      ? {
                                          pathname: `/add/buyerform/${this.props.value.id}`,
                                          state:
                                            this.props.value &&
                                            this.props.value,
                                        }
                                      : {
                                          pathname: `/add/buyerProfile/${this.props.value.id}`,
                                          state:
                                            this.props.value &&
                                            this.props.value,
                                        }
                                  }
                                >
                                  <EditTwoTone
                                    style={{
                                      fontSize: "20px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Link>
                              </Col>
                              <Col span={12}>
                                <DeleteRecord
                                  recordId={value.id}
                                  getHomesList={this.props.getBuyerHomeDetails}
                                  updateSavedSearchWithBuyerPref={
                                    this.updateSavedSearchWithBuyerPref
                                  }
                                  apiPath={"buyers"}
                                  fontSizeProp={"18px"}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                      <Col
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                      >
                        {this.props.value.buyersList && (
                          <Row style={{ paddingTop: "1%", float: "right" }}>
                            {this.state.validUser && (
                              <div className="layout-last-step">
                                <Button
                                  className="show-matches"
                                  style={{ padding: "0" }}
                                  onClick={() => this.checkShowMatches(value)}
                                >
                                  Show matches
                                  <img
                                    src={arrowIcon}
                                    alt="next-page"
                                    style={{
                                      marginLeft: "25px",
                                      width: "20px",
                                      height: "12px",
                                    }}
                                  />
                                </Button>
                              </div>
                            )}
                          </Row>
                        )}
                      </Col>
                    </Row>
                  )}
                  {!this.props.value.buyersList && (
                    <>
                      <Row className="profile-image-mobile">
                        <div className="layout-last-step">
                          <span>
                            <img
                              src={
                                value.homeAgentProfileUrl
                                  ? value.homeAgentProfileUrl
                                  : agentPic
                              }
                              alt="space"
                              width="40px"
                              height="40px"
                              style={{
                                borderRadius: "50%",
                                marginRight: "8px",
                              }}
                            />
                          </span>

                          <span className="layout-subtitles">
                            <Link
                              // to={}
                              style={{ textDecoration: "none" }}
                              onClick={this.handleLink}
                              to={{
                                pathname:`/agent/viewProfile/${value && value.userid}`,
                                state: "buyersAgent",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "16px",
                                  marginTop: "8px",
                                  color: "#3045D2",
                                  fontWeight: 600,
                                  marginBottom: "0px",
                                }}
                              >
                                {value.agent}
                              </p>
                            </Link>
                          </span>
                        </div>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>
      </div>
    );
  }
}

export default withAppContext(withRouter(BuyerCard));
