import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  notification,
  Button,
  Input,
  Modal,
  Table,
  Form,
  Select,
  DatePicker,
  Upload,
  Spin,
} from "antd";
import "antd/dist/antd.css";
import {
  PlusOutlined,
  CameraOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
// import { list, add, update, deleteRecord, noAuthlist } from "../../../api/Apis";
import { withAppContext } from "../../../store/contextStore";
import Loading from "../../../common/Loading";
import { ModalTitle } from "../../../StyledComponents/StyledComponents";
import moment from "moment";
// import TimePicker from "rc-time-picker";
import NumberFormat from "react-number-format";
import { Storage } from "aws-amplify";
import { add, deleteRecord, list, update } from "../../../api/Apis";
import ImageGallery from "../../../common/ImageGallery";

const { TextArea } = Input;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export class Expenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      loading: false,
      allAgents: [],
      selectedYear: "",
      imageLoading: false,
      uploadImage: "",
      descriptionData: "",
      yearlyBudgets: [],
      filterYearlyBudgets: [],
      expCategory: "All",
      totalExpensesData: [],
      filterExpData: [],
      budgetYear: "",
      budgetCat: "",
      exitsBudget: {},
      totalBudget: 0,
      totalExpenseValue: 0,
      budgetCardData: [],
      expenseCardData: [],
      budgetData: [],
      editModal: false,
      expenseData: [],
      expSubCategory: "All",
      expSelectyear: "All",
      expDateSelect: "",
   
    };
  }

  handleReset = () => {
    this.setState({
      openForm: false,
      flagPriority: "",
      notes: "",
    });
  };

  componentDidMount() {
    this.getBudgets();
    this.getExpenses();
  }

  getBudgets = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    let userId = userDetails.id;
    let query = {};

    query["userId"] = userId;

    query["budgets"] = "yes";

    list("virtualOfficeExpenses", query)
      .then((list) => {
        let budgetCardData = [];

        list.data.reduce(function (prevVal, item) {
          if (!prevVal[item.category]) {
            prevVal[item.category] = parseFloat(item.totalBudget);
            budgetCardData.push({
              title: item.category,
              budget: parseFloat(item.totalBudget),
            });
          } else {
            // prevVal[item.category] =
            //   parseFloat(prevVal[item.category]) + parseFloat(item.totalBudget);
            budgetCardData.map((e) => {
              if (e.title === item.category) {
                e["budget"] =
                  parseFloat(e["budget"]) + parseFloat(item.totalBudget);
              }
              return true;
            });
          }
          return prevVal;
        }, {});

        let totalBudget = 0;

        list.data.map((e) => {
          return (totalBudget = totalBudget + parseInt(e.totalBudget));
        });

        if (list.success) {
          this.setState({
            yearlyBudgets: list.data,
            filterYearlyBudgets: list.data,
            budgetCardData,
            totalBudget,
          });
        }
      })
      .catch((Error) => console.log(Error));
  };

  getExpenses = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    let userId = userDetails.id;
    let query = {};

    query["userId"] = userId;

    query["expenses"] = "yes";

    list("virtualOfficeExpenses", query)
      .then((list) => {
        let expenseCardData = [];

        list.data.reduce(function (prevVal, item) {
          if (!prevVal[item.expenseCategory]) {
            prevVal[item.expenseCategory] = parseFloat(item.totalExpense);
            expenseCardData.push({
              title: item.expenseCategory,
              expenses: parseFloat(item.totalExpense),
            });
          } else {
            expenseCardData.map((e) => {
              if (e.title === item.expenseCategory) {
                e["expenses"] =
                  parseFloat(e["expenses"]) + parseFloat(item.totalExpense);
              }
              return true;
            });
          }
          return prevVal;
        }, {});

        let totalExpenseValue = 0;
        list.data.map((e) => {
          return (totalExpenseValue =
            totalExpenseValue + parseInt(e.totalExpense));
        });

        if (list.success) {
          this.setState({
            totalExpensesData: list.data,
            filterExpData: list.data,
            expenseCardData,
            totalExpenseValue,
          });
        }
      })
      .catch((Error) => console.log(Error));
  };

  // SEARCH BY CATEGORY, SUB CATEGORY

  searchData = (event, searchType) => {
    const { totalExpensesData } = this.state;
    let filterExpData = [];
    let searchValue = "";
    if (searchType === "text") {
      searchValue = event?.trim().toLowerCase();
    } else {
      searchValue = event;
    }
    if (searchType === "text") {
      filterExpData =
        totalExpensesData?.length > 0 &&
        totalExpensesData.filter(
          (user) =>
            (user["category"] &&
              user["category"].toLowerCase().indexOf(searchValue) > -1) ||
            (user["subCategory"] &&
              user["subCategory"].toLowerCase().indexOf(searchValue) > -1) ||
            (user["whereCharged"] &&
              user["whereCharged"].toLowerCase().indexOf(searchValue) > -1)
        );
    } else if (searchType === "date") {
      if (searchValue === null) {
        filterExpData = totalExpensesData;
      } else {
        filterExpData =
          totalExpensesData?.length > 0 &&
          totalExpensesData.filter(
            (user) =>
              user["date"] &&
              moment(user["date"]).format("L") ===
                moment(searchValue).format("L")
          );
        this.setState({
          expDateSelect: moment(searchValue).format("L"),
        });
      }
    }
    this.setState({
      filterExpData,
    });
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
      loading: true,
    });
    switch (info.file.status) {
      case "done":
        // this.setState({
        //   fileList: info.fileList,
        // });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  uploadImage: result.key,
                  imageLoading: false,
                  loading: false,
                });
                // this.props.enableSaveButton();
              });
            } else {
              this.setState({
                imageLoading: false,
                loading: false,
              });
              // this.props.enableSaveButton();
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  onFinish = (values) => {
    const { exitsBudget, budgetData, editModal } = this.state;
    this.setState({
      loading: true,
    });
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    values["totalBudget"] = values["totalBudget"].replace(/[^+0-9]/g, "");
    if ("id" in exitsBudget === true && exitsBudget?.id !== "") {
      let data = {
        entityData: values,
      };
      update("virtualOfficeExpenses", exitsBudget.id, data)
        .then((succesResult) => {
          if (succesResult.success) {
            notification["success"]({
              message: "Budget created successfully.",
            });
            this.getBudgets();
            this.setState({
              openForm: false,
              loading: false,
              exitsBudget: {},
            });
            return;
          }
        })
        .catch((err) => console.log(err));
      return;
    }
    values["userId"] = userDetails.id;
    values["orgId"] = orgDetails.id;
    values["budgets"] = "yes";
    let data = {
      entityData: values,
    };

    if (editModal) {
      update("virtualOfficeExpenses", budgetData.id, data)
        .then((succesResult) => {
          if (succesResult.success) {
            notification["success"]({
              message: "Budget updated successfully.",
            });
            this.getBudgets();
            this.setState({
              openForm: false,
              editModal: false,
              loading: false,
              budgetData: [],
            });
            return;
          }
        })
        .catch((err) => console.log(err));
    } else {
      add("virtualOfficeExpenses", data)
        .then((succesResult) => {
          if (succesResult.success) {
            notification["success"]({
              message: "Budget created successfully.",
            });
            this.getBudgets();
            this.setState({
              openForm: false,
              loading: false,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  handleExpConditon = (expCat) => {
    let expenseCategory = "";
    if (expCat === "AdminAndSupport") {
      expenseCategory = "Admin/Support";
    } else if (expCat === "DuesAndSubscriptions") {
      expenseCategory = "Dues/Subscriptions";
    } else if (expCat === "MealsAndEntertainment") {
      expenseCategory = "Meals & Entertainment";
    } else if (expCat === "OfficeSupplies") {
      expenseCategory = "Office Supplies";
    } else if (expCat === "LeadGenAndAdvertising") {
      expenseCategory = "Lead Gen/Advertising";
    } else if (expCat === "TrainingAndEducation") {
      expenseCategory = "Training/Education";
    } else if (expCat === "ClientAppreciation") {
      expenseCategory = "Client Appreciation";
    } else if (expCat === "RentAndUtilities") {
      expenseCategory = "Rent/Utilities";
    } else {
      expenseCategory = expCat;
    }

    return expenseCategory;
  };
  onSubmit = (e) => {
    const { editModal, expenseData } = this.state;
    this.setState({ loading: true });
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgnizationId = JSON.parse(localStorage.getItem("orgId"));

    let expenseCategory = this.handleExpConditon(e["expenseCategory"]);

    let data = {
      entityData: {
        userId: userDetails.id,
        expenseCategory,
        expenseSubCategory: e["expenseSubCategory"],
        totalExpense: e["totalExpense"].replace(/[^+0-9]/g, ""),
        expenseDate: moment(e["expenseDate"]).format("L"),
        // expenseTime: moment(e["expenseTime"]).format("hh:mm"),
        whereCharged: e["whereCharged"],
        description: e["description"],
        uploadImage: expenseData["uploadImage"] || this.state.uploadImage,
        orgId: orgnizationId,
        expenses: "yes",
      },
    };
    if (editModal) {
      update("virtualOfficeExpenses", expenseData.id, data)
        .then((succesResult) => {
          if (succesResult.success) {
            notification["success"]({
              message: "Expense updated successfully.",
            });
            this.getExpenses();
            this.setState({
              openExpense: false,
              uploadImage: "",
              loading: false,
              editModal: false,
              expenseData: [],
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      add("virtualOfficeExpenses", data)
        .then((succesResult) => {
          if (succesResult.success) {
            notification["success"]({
              message: "Expense logged successfully.",
            });
            this.getExpenses();
            this.setState({
              openExpense: false,
              uploadImage: "",
              loading: false,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  handleChange = (e) => {
    this.setState({
      dueDate: e,
    });
  };

  handleExpCategory = (e) => {
    this.setState({
      expCategory: e,
    });
  };
  handleExpCategoryFilter = (e) => {
    const { totalExpensesData } = this.state;

    const newExpCat = this.handleExpConditon(e);

    this.setState({
      expCategory: e,
      expSubCategory:"All"
    });

    let newFilterData = totalExpensesData.filter(
      (ex) =>
        ex.expenseCategory &&
        ex.expenseCategory.toLowerCase().indexOf(newExpCat.toLowerCase()) > -1
    );
    this.setState({
      filterExpData: newFilterData,

    });
  };
  handleBudgetCategory = (cat) => {
    console.log(cat, "cat");
    this.setState({
      budgetCat: cat,
    });
  };

  handleBudgetYear = (year) => {
    const { budgetCat, yearlyBudgets } = this.state;
    console.log(year);
    this.setState({
      budgetYear: year,
    });

    let EditYearlyBudgets = yearlyBudgets.filter(
      (eachBud) =>
        eachBud.category.toLowerCase() === budgetCat.toLowerCase() &&
        parseInt(eachBud.budgetYear) === parseInt(year)
    );
    if (EditYearlyBudgets?.length > 0) {
      this.setState({
        exitsBudget: EditYearlyBudgets[0],
      });
    }
  };

  showModal = (record, type) => {
    if (type === "expense") {
      this.setState({
        expenseData: record,
        openExpense: true,
        editModal: true,
      });
    } else {
      this.setState({
        budgetData: record,
        openForm: true,
        editModal: true,
      });
    }
  };

  deleteExpense = (record) => {
    this.setState({
      loading: true,
    });
    deleteRecord("virtualOfficeExpenses", record.id)
      .then((succesResult) => {
        if (succesResult.success) {
          notification["success"]({
            message: "Expenses record deleted successfully.",
          });
          this.getExpenses();
          this.setState({
            loading: false,
            editModal: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleResetExpFilter = () => {
    this.setState({
      expCategory: "All",
      expSubCategory: "All",
      expSelectyear: "All",
      expDateSelect: "",
    });
    this.getExpenses()
  };
  handleSubCategoryFilter = (e) => {
    const { totalExpensesData } = this.state;

    this.setState({
      expSubCategory: e,
    });

    let newFilterData = totalExpensesData.filter(
      (ex) =>
        ex.expenseSubCategory &&
        ex.expenseSubCategory.toLowerCase().indexOf(e.toLowerCase()) > -1
    );

    this.setState({
      filterExpData: newFilterData,
    });
  };
  render() {
    const {
      openForm,
      budgetData,
      loading,
      openExpense,
      uploadImage,
      imageLoading,
      expCategory,
      filterExpData,
      filterYearlyBudgets,
      totalBudget,
      totalExpenseValue,
      expenseCardData,
      budgetCardData,
      expenseData,
      expSubCategory,
      expSelectyear,
      expDateSelect,
    } = this.state;

    const columns = [
      {
        title: "BUDGET YEAR",
        dataIndex: "budgetYear",
        key: "budgetYear",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "budget"),
        // }),
        render: (budgetYear) => {
          return (
            <span
              //   className="table_desc"
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#99A2C3",
                fontWeight: "400",
              }}
            >
              {budgetYear}
            </span>
          );
        },
      },
      {
        title: "BUDGET CATEGORY",
        dataIndex: "category",
        key: "category",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "budget"),
        // }),
        render: (category) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#131419",
                fontWeight: "400",
              }}
            >
              {category}
            </span>
          );
        },
      },
      {
        title: "TOTAL BUDGET",
        dataIndex: "totalBudget",
        key: "totalBudget",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "budget"),
        // }),
        render: (totalBudget) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#131419",
                fontWeight: "500",
              }}
            >
              <NumberFormat
                value={parseFloat(totalBudget).toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>
          );
        },
      },
      {
        title: "ACTION",
        dataIndex: "id",
        key: "id",
        sorter: false,
        className: "col_styling",
        render: (id, record) => {
          return (
            <Row>
              <span>
                <EditOutlined
                  // className="todo-delete-option"
                  style={{ fontSize: "18px" }}
                  onClick={() => this.showModal(record)}
                />
              </span>
            </Row>
          );
        },
      },
    ];

    const expenseColumns = [
      {
        title: "DATE",
        dataIndex: "expenseDate",
        key: "expenseDate",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "expense"),
        // }),
        render: (expenseDate) => {
          return (
            <span
              //   className="table_desc"
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#99A2C3",
                fontWeight: "400",
              }}
            >
              {moment(expenseDate).format("L")}
            </span>
          );
        },
      },
      {
        title: "CATEGORY",
        dataIndex: "expenseCategory",
        key: "expenseCategory",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "expense"),
        // }),
        render: (expenseCategory) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#131419",
                fontWeight: "400",
              }}
            >
              {expenseCategory}
            </span>
          );
        },
      },
      {
        title: "SUB CATEGORY",
        dataIndex: "expenseSubCategory",
        key: "expenseSubCategory",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "expense"),
        // }),
        render: (expenseSubCategory) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#131419",
                fontWeight: "500",
              }}
            >
              {expenseSubCategory}
            </span>
          );
        },
      },
      {
        title: "WHERE CHARGED",
        dataIndex: "whereCharged",
        key: "whereCharged",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "expense"),
        // }),
        render: (whereCharged) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#131419",
                fontWeight: "500",
              }}
            >
              {whereCharged}
            </span>
          );
        },
      },
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "description",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "expense"),
        // }),
        render: (description) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#131419",
                fontWeight: "500",
              }}
            >
              {description}
            </span>
          );
        },
      },
      {
        title: "Receipt",
        dataIndex: "uploadImage",
        key: "uploadImage",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "expense"),
        // }),
        render: (uploadImage) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {uploadImage && (
                <ImageGallery photos={uploadImage} businessCard />
              )}
            </span>
          );
        },
      },
      {
        title: "TOTAL",
        dataIndex: "totalExpense",
        key: "totalExpense",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.showModal(record, "expense"),
        // }),
        render: (totalExpense) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#131419",
                fontWeight: "500",
              }}
            >
              <NumberFormat
                value={parseFloat(totalExpense).toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>
          );
        },
      },
      {
        title: "ACTION",
        dataIndex: "id",
        key: "id",
        sorter: false,
        className: "col_styling",
        render: (id, record) => {
          return (
            <Row>
              <span>
                <EditOutlined
                  // className="todo-delete-option"
                  style={{ fontSize: "18px" }}
                  onClick={() => this.showModal(record, "expense")}
                />
              </span>
              <span>
                <DeleteOutlined
                  style={{ fontSize: "18px", color: "red", marginLeft: "18px" }}
                  // className="todo-delete-option"
                  onClick={() => this.deleteExpense(record)}
                />
              </span>
            </Row>
          );
        },
      },
    ];

    const budgetAndExpensesCategory = [
      { label: "Admin/Support", value: "AdminAndSupport" },
      { label: "Marketing", value: "Marketing" },
      { label: "Dues/Subscriptions", value: "DuesAndSubscriptions" },
      // { label: "Auto", value: "Auto" },
      { label: "Meals & Entertainment", value: "MealsAndEntertainment" },
      { label: "Office Supplies", value: "OfficeSupplies" },
      // { label: "Health", value: "Health" },
      { label: "Lead Gen/Advertising", value: "LeadGenAndAdvertising" },
      { label: "Training/Education", value: "TrainingAndEducation" },
      { label: "Client Appreciation", value: "ClientAppreciation" },
      { label: "Insurance", value: "Insurance" },
      { label: "Brokerage fees", value: "BrokerageFees" },
      { label: "Rent/Utilities", value: "RentAndUtilities" },
      { label: "Vehicle", value: "Vehicle" },
      { label: "Charity", value: "Charity" },
    ];

    const expenseSubCategory = {
      category: {
        AdminAndSupport: [
          "Admin",
          "Team Member",
          "Marketing",
          "Manager",
          "Accounting",
          "Other",
        ],
        Marketing: [
          "Lead Ads",
          "Realtor Ads",
          "Google Ads",
          "Facebook Ads",
          "Print Materials",
          "Gifts",
          "Client Appreciation",
          "Direct Mail",
          "Branding",
          "Photos",
          "Business Cards",

          "Postcards",
          "Fliers",
          "Apparel",
          "Yard signs",
          "Listing photos",
          "Other",
        ],
        DuesAndSubscriptions: [
          "MLS",
          "AWS",
          "Realtor Board",
          "Website",
          "CRM",
          "Other",
        ],
        // Auto: ["Gas", "Mileage", "Repair", "Maintenance", "Other"],
        MealsAndEntertainment: [
          "Networking",
          "Client",
          "Business travel",
          "Restaurant",
          "Event",
          "Air travel",
          "Hotel",
          "Other",
        ],
        OfficeSupplies: [
          "Postage",
          "Paper",
          "Ink/Toner",
          "Furniture",
          "Supplies",
          "Other",
        ],
        // Health: [" Gym Membership", "Insurance", "Other"],
        LeadGenAndAdvertising: [
          "Zillow",
          "Realtor.com",
          "Facebook",
          "Google",
          "Billboard",
          "Mailers",
          "Postage",
          "Other",
        ],
        TrainingAndEducation: [
          "Coaching",
          "Training event",
          "Software",
          "Other",
        ],
        ClientAppreciation: [
          "Client gift",
          "Client event",
          "Non-reimbursable",
          "Other",
        ],
        Insurance: ["Health", "Car", "Life", "E&O", "Other"],
        BrokerageFees: ["Monthly fees", "Trans fees", "E&O fees", "Other"],
        RentAndUtilities: [
          "Office Rent",
          "Phone",
          "Internet",
          "Heat",
          "Electric",
          "Other",
        ],
        Vehicle: ["Parking/tolls", "Gas", "Maintenance", "Lease", "Other"],
        Charity: ["Charity"],
      },
    };

    let currentYear = new Date().getFullYear();
    const yearData = [
      "All",
      currentYear - 2,
      currentYear - 1,
      currentYear,
      currentYear + 1,
      currentYear + 2,
    ];

    let TotalData = [];
    TotalData = budgetCardData.concat(expenseCardData);
    let cardData = [];
    TotalData.reduce(function (prevVal, item) {
      if (!prevVal[item.title]) {
        prevVal[item.title] = 1;
        cardData.push({
          title: item.title,
          budget: item.budget,
          expenses: item.expenses,
        });
      } else {
        prevVal[item.title] += 1;
        cardData.map((e) => {
          if (e.title === item.title && e.budget === undefined) {
            e["budget"] = item.budget;
          } else if (e.title === item.title && e.expenses === undefined) {
            e["expenses"] = item.expenses;
          }
          return true;
        });
      }
      return prevVal;
    }, {});
    return (
      <div style={{ padding: "24px" }}>
        <Loading enableLoading={loading} />
        <Row gutter={[24, 24]}>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <span className="todo_title">Expenses</span>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <Row>
              <Col span={14} style={{ textAlign: "right" }}>
                <span className="total_budget">TOTAL BUDGET:</span>
                <span className="total_amount" style={{ color: "#26a521" }}>
                  <NumberFormat
                    value={parseFloat(totalBudget).toFixed(2)}
                    thousandSeparator={true}
                    prefix={"$"}
                    displayType="text"
                  />
                </span>
              </Col>

              <Col span={8} offset={2} style={{ textAlign: "right" }}>
                <span className="total_budget">TOTAL EXPENSES:</span>
                <span className="total_amount" style={{ color: "#DB0000" }}>
                  <NumberFormat
                    value={parseFloat(totalExpenseValue).toFixed(2)}
                    thousandSeparator={true}
                    prefix={"$"}
                    displayType="text"
                  />
                </span>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Card
              className="card_virtual"
              title={
                <Row>
                  <Col
                    xl={{ span: 3, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    sm={{ span: 11, offset: 1 }}
                    xs={{ span: 11, offset: 1 }}
                  >
                    <span>
                      Expenses
                      <Button
                        icon={<PlusOutlined style={{ marginTop: "1px" }} />}
                        style={{
                          borderRadius: "50px",
                          background: "#3045D2",
                          color: "#FFF",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({ openExpense: true });
                        }}
                      ></Button>
                    </span>
                  </Col>

                  <Col
                    xl={{ span: 3, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    sm={{ span: 11, offset: 1 }}
                    xs={{ span: 11, offset: 1 }}
                    // style={{paddingTop:"10px"}}
                  >
                    <div className="loads-select">
                      <span
                        style={{
                          fontSize: "17px",
                          color: "#131419",
                          marginRight: "10px",
                          // marginLeft: "12px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Category:{" "}
                      </span>
                      <Select
                        onChange={this.handleExpCategoryFilter}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        style={{
                          borderRadius: "5px",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        notFoundContent={"Not found?!"}
                        placeholder="Category"
                        defaultValue={"All"}
                        value={expCategory}
                      >
                        {budgetAndExpensesCategory.map((c) => (
                          <Select.Option value={c.value}>
                            {c.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 4, offset: 2 }}
                    lg={{ span: 7, offset: 0 }}
                    sm={{ span: 11, offset: 1 }}
                    xs={{ span: 11, offset: 1 }}
                    // className="filter-pipline-contact"
                  >
                    <div className="loads-select">
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#131419",
                          marginRight: "10px",
                          // marginLeft: "12px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Sub Category:{" "}
                      </span>

                      <Select
                        onChange={(event) =>
                          this.handleSubCategoryFilter(event)
                        }
                        // options={sourceLead.label}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        style={{
                          borderRadius: "5px",
                          width: "75%",
                          cursor: "pointer",
                        }}
                        notFoundContent={"Not found?!"}
                        placeholder="Sub Category"
                        value={expSubCategory}
                      >
                        {expCategory !== "" &&
                          expenseSubCategory?.category[expCategory]?.map(
                            (c) => <Select.Option value={c}>{c}</Select.Option>
                          )}
                      </Select>
                    </div>
                  </Col>

                  <Col
                    xl={{ span: 3, offset: 2 }}
                    lg={{ span: 4, offset: 3 }}
                    sm={{ span: 11, offset: 1 }}
                    xs={{ span: 11, offset: 1 }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#131419",
                          marginRight: "10px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Year{" "}
                      </span>
                      <Select
                        defaultValue={"All"}
                        onChange={(e) => {
                          this.setState({
                            filterExpData:
                              e !== "All"
                                ? this.state.totalExpensesData.filter(
                                    (eachYear) =>
                                      parseInt(
                                        moment(
                                          eachYear.expenseDate,
                                          "MM/DD/YYYY"
                                        ).format("YYYY")
                                      ) === e
                                  )
                                : this.state.totalExpensesData,
                            expSelectyear: e,
                          });
                        }}
                        className="select_height_exepense"
                        style={{ width: "120px" }}
                        placeholder="Year"
                        value={expSelectyear}
                      >
                        {yearData.map((y) => (
                          <Select.Option value={y}>{y}</Select.Option>
                        ))}
                      </Select>
                    </div>
                  </Col>

                  <Col
                    xl={{ span: 3, offset: 1 }}
                    lg={{ span: 4, offset: 3 }}
                    sm={{ span: 11, offset: 1 }}
                    xs={{ span: 11, offset: 1 }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#131419",
                          marginRight: "10px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Date{" "}
                      </span>
                      <DatePicker
                        // defaultValue={moment()}
                        format="MM/DD/YYYY"
                        // disabledDate={(current) => {
                        //   return current && current < moment().subtract(1, "days");
                        // }}
                        // style={{
                        //   borderRadius: "5px",
                        // }}
                        value={
                          expDateSelect !== "" ? moment(expDateSelect) : ""
                        }
                        onChange={(event) => this.searchData(event, "date")}
                        className="date-expenses-filter"
                      />
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 2, offset: 1 }}
                    lg={{ span: 4, offset: 3 }}
                    sm={{ span: 11, offset: 1 }}
                    xs={{ span: 11, offset: 1 }}
                  >
                    <Button type="danger" onClick={this.handleResetExpFilter}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              }
              // extra={

              // }
              bodyStyle={{ padding: "0px" }}
            >
              <Table
                className="table_crm"
                columns={expenseColumns}
                dataSource={filterExpData}
                loading={loading}
                rowKey={(record) => record.id}
                pagination={false}
                style={{ cursor: "pointer" }}
                rowClassName="ant-card-hoverable"
              />
            </Card>
          </Col>

          <Col
            xl={{ span: 10 }}
            lg={{ span: 10 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card
              className="card_virtual"
              title={
                <span>
                  Budgets
                  <Button
                    icon={<PlusOutlined style={{ marginTop: "1px" }} />}
                    style={{
                      borderRadius: "50px",
                      background: "#3045D2",
                      color: "#FFF",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({ openForm: true });
                    }}
                  ></Button>
                </span>
              }
              extra={
                <div>
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#131419",
                      marginRight: "10px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Year{" "}
                  </span>
                  <Select
                    defaultValue={"All"}
                    onChange={(e) => {
                      this.setState({
                        filterYearlyBudgets:
                          e !== "All"
                            ? this.state.yearlyBudgets.filter(
                                (eachYear) => eachYear.budgetYear === e
                              )
                            : this.state.yearlyBudgets,
                      });
                    }}
                    className="select_height_budget"
                    style={{ width: "100px" }}
                    placeholder="Year"
                  >
                    {yearData.map((y) => (
                      <Select.Option value={y}>{y}</Select.Option>
                    ))}
                  </Select>
                </div>
              }
              bodyStyle={{ padding: "0px" }}
            >
              <Table
                className="table_crm"
                columns={columns}
                dataSource={filterYearlyBudgets}
                loading={loading}
                rowKey={(record) => record.id}
                pagination={false}
                style={{ cursor: "pointer" }}
                rowClassName="ant-card-hoverable"
              />
            </Card>
          </Col>

          <Col
            xl={{ span: 14 }}
            lg={{ span: 14 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row gutter={[12, 12]}>
              {cardData.map((c) => (
                <Col span={6}>
                  <Card
                    className="card_virtual_data"
                    style={{
                      background: "#3045D2",
                      textAlign: "center",
                      color: "#FFF",
                      height: "160px",
                    }}
                    hoverable={true}
                    bodyStyle={{ padding: "10px" }}
                  >
                    <div
                      className="account_virtual"
                      style={{ height: "70px", marginTop: "20px" }}
                    >
                      {c.title}
                    </div>

                    <Row>
                      <Col span={12}>
                        <Row>
                          {/* <ArrowDownOutlined
                            style={{ color: "#26A521", marginTop: "3px" }}
                          /> */}
                          <span
                            style={{
                              color: "#99A2C3",
                              fontSize: "14px",
                            }}
                          >
                            Budget
                          </span>
                        </Row>
                        <Row>
                          {c.budget && (
                            <NumberFormat
                              value={parseFloat(c.budget).toFixed(2)}
                              thousandSeparator={true}
                              prefix={"$"}
                              displayType="text"
                            />
                          )}
                        </Row>
                      </Col>

                      <Col span={12} style={{ float: "right" }}>
                        <Row style={{ justifyContent: "flex-end" }}>
                          {/* <ArrowUpOutlined
                            style={{ color: "#DB0000", marginTop: "3px" }}
                          /> */}
                          <span
                            style={{
                              color: "#99A2C3",
                              fontSize: "14px",
                            }}
                          >
                            Expenses
                          </span>
                        </Row>
                        <Row style={{ justifyContent: "flex-end" }}>
                          {c.expenses && (
                            <NumberFormat
                              value={parseFloat(c.expenses).toFixed(2)}
                              thousandSeparator={true}
                              prefix={"$"}
                              displayType="text"
                            />
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Modal
          className="modal-black-close virtual_modal"
          visible={openForm}
          title="Add a budget"
          style={{ marginTop: "2%" }}
          onCancel={() =>
            this.setState({
              openForm: false,
              editModal: false,
              budgetData: [],
            })
          }
          footer={
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Button
                    onClick={() =>
                      this.setState({
                        openForm: false,
                        editModal: false,
                        budgetData: [],
                      })
                    }
                    htmlType="reset"
                    style={{
                      width: "100%",
                      backgroundColor: "#bbb",
                      color: "#FFF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    htmlType="submit"
                    form="form"
                    type="primary"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    loading={loading}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          }
          width={"35%"}
          destroyOnClose={true}
        >
          <Form onFinish={this.onFinish} id="form" initialValues={false}>
            <Card
              style={{ marginTop: "10px" }}
              className="card_virtual"
              hoverable={true}
            >
              <Row>
                <Col span={24}>
                  <ModalTitle>Budget Category</ModalTitle>

                  <Form.Item
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please input budget category!",
                      },
                    ]}
                    initialValue={budgetData.category}
                  >
                    <Select
                      placeholder="Budget category"
                      className="select_height"
                      onChange={this.handleBudgetCategory}
                    >
                      {budgetAndExpensesCategory.map((c) => (
                        <Select.Option value={c.label} key={c.label}>
                          {c.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <ModalTitle>Budget year</ModalTitle>

                  <Form.Item
                    name="budgetYear"
                    rules={[
                      {
                        required: true,
                        message: "Please input total budget!",
                      },
                    ]}
                    initialValue={budgetData.budgetYear}
                  >
                    <Select
                      placeholder="Select year"
                      onChange={this.handleBudgetYear}
                    >
                      {yearData.map((eachYear) => (
                        <Select.Option value={eachYear}>
                          {eachYear}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <ModalTitle>Total Budget</ModalTitle>

                  <Form.Item
                    name="totalBudget"
                    rules={[
                      {
                        required: true,
                        message: "Please input total budget!",
                      },
                    ]}
                    initialValue={budgetData.totalBudget}
                  >
                    <NumberFormat
                      thousandSeparator={true}
                      prefix={"$"}
                      placeholder="$"
                      className="form-input-height"
                      style={{ border: "1px solid #d9d9d9" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </Modal>

        <Modal
          className="modal-black-close virtual_modal"
          visible={openExpense}
          title="Add an expense"
          style={{ marginTop: "2%" }}
          destroyOnClose={true}
          onCancel={() =>
            this.setState({
              openExpense: false,
              editModal: false,
              expenseData: [],
              uploadImage: "",
            })
          }
          footer={
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Button
                    onClick={() =>
                      this.setState({
                        openExpense: false,
                        uploadImage: "",
                        editModal: false,
                        expenseData: [],
                      })
                    }
                    htmlType="reset"
                    style={{
                      width: "100%",
                      backgroundColor: "#bbb",
                      color: "#FFF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    htmlType="submit"
                    form="form1"
                    type="primary"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    loading={loading}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          }
          width={"35%"}
        >
          <Form onFinish={this.onSubmit} id="form1">
            <Card
              style={{ marginTop: "10px" }}
              className="card_virtual"
              hoverable={true}
            >
              <Row>
                <Col span={24}>
                  <ModalTitle>Expense Category</ModalTitle>

                  <Form.Item
                    name="expenseCategory"
                    rules={[
                      {
                        required: true,
                        message: "Please input expense category!",
                      },
                    ]}
                    initialValue={expenseData.category}
                  >
                    <Select
                      placeholder="Expense Category"
                      className="select_height"
                      onChange={this.handleExpCategory}
                    >
                      {budgetAndExpensesCategory.map((c) => (
                        <Select.Option value={c.value}>{c.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <ModalTitle>Expense Sub-Category</ModalTitle>

                  <Form.Item
                    name="expenseSubCategory"
                    rules={[
                      {
                        required: true,
                        message: "Please input expense sub-Category!",
                      },
                    ]}
                    initialValue={expenseData.expenseSubCategory}
                  >
                    <Select
                      placeholder="Expense Category"
                      className="select_height"
                    >
                      {expCategory !== "" &&
                        expenseSubCategory?.category[expCategory]?.map((c) => (
                          <Select.Option value={c}>{c}</Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <ModalTitle>Total Expense</ModalTitle>

                  <Form.Item
                    name="totalExpense"
                    rules={[
                      {
                        required: true,
                        message: "Please input total expense!",
                      },
                    ]}
                    initialValue={expenseData.totalExpense}
                  >
                    <NumberFormat
                      thousandSeparator={true}
                      prefix={"$"}
                      placeholder="$"
                      className="form-input-height"
                      style={{ border: "1px solid #d9d9d9" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <ModalTitle>Date</ModalTitle>

                  <Form.Item
                    name="expenseDate"
                    rules={[
                      {
                        required: false,
                        message: "Please input date!",
                      },
                    ]}
                  >
                    <DatePicker
                      defaultValue={
                        expenseData.expenseDate
                          ? moment(expenseData.expenseDate, "MM/DD/YYYY")
                          : moment()
                      }
                      format="MM/DD/YYYY"
                      style={{
                        height: "40px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={11} offset={2}>
                  <ModalTitle>Time</ModalTitle>

                  <Form.Item
                    name="expenseTime"
                    rules={[
                      {
                        required: true,
                        message: "Please input time!",
                      },
                    ]}
                  >
                    <TimePicker
                      className="time-picker"
                      placeholder="Select Time"
                      use12Hours
                      minuteStep={15}
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      onChange={(e) => {
                        this.setState({ selectedTime: e });
                      }}
                      defaultValue={moment().format("hh:mm")}
                    />
                  </Form.Item>
                </Col> */}

                <Col span={12} offset={1}>
                  <ModalTitle>Where charged</ModalTitle>

                  <Form.Item
                    name="whereCharged"
                    rules={[
                      {
                        required: true,
                        message: "Please input where charged!",
                      },
                    ]}
                    initialValue={expenseData.whereCharged}
                  >
                    <Select
                      placeholder="Select Where charged"
                      className="select_height"
                    >
                      <Select.Option value="Business Checking">
                        Business Checking
                      </Select.Option>
                      <Select.Option value="Personal Checking">
                        Personal Checking
                      </Select.Option>
                      <Select.Option value="Credit Card">
                        Credit Card
                      </Select.Option>
                      <Select.Option value="Cash">Cash</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <ModalTitle>Description</ModalTitle>

                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: false,
                        message: "Please input Description!",
                      },
                    ]}
                    initialValue={expenseData.description}
                  >
                    <TextArea
                      placeholder="Description"
                      rows={4}
                      style={{ borderRadius: "5px" }}
                      // value={testimonialData}
                      // onChange={(e) => {
                      //   this.setState({ descriptionData: e.target.value });
                      // }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <ModalTitle>Upload Receipt Image</ModalTitle>

                  <Form.Item
                    name="images"
                    rules={[
                      {
                        required: false,
                        message: "Please input image!",
                      },
                    ]}
                    initialValue={
                      uploadImage ? uploadImage : expenseData.uploadImage
                    }
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      style={{
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                      }}
                      showUploadList={false}
                      customRequest={dummyRequest}
                      onChange={(e) => this.handleUploadPic(e)}
                    >
                      <Spin spinning={imageLoading}>
                        {uploadImage ? (
                          <img
                            src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${uploadImage}`}
                            alt="avatar"
                            style={{ width: "100px", height: "100%" }}
                          />
                        ) : expenseData.uploadImage ? (
                          <img
                            src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${expenseData.uploadImage}`}
                            alt="avatar"
                            style={{ width: "100px", height: "100%" }}
                          />
                        ) : (
                          <CameraOutlined className="camera-icon" />
                        )}
                      </Spin>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default withAppContext(withRouter(Expenses));
