import React, { Component } from "react";
import { Button, Pagination } from "antd";

export class Paginations extends Component {
  handleChange = (page, pageSize) => {
    this.props.handlePagination(page, pageSize);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleNextButton = (total, range) => {
    return (
      <div>
        {range[1] === this.props.total &&
          this.props.total >= 100 &&
          window.location.pathname === "/homes" && (
            <Button
              type="primary"
              onClick={() =>
                this.props.handleLoadMoreButton(total, this.props.currentPage)
              }
            >
              Load more
            </Button>
          )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Pagination
          current={this.props.currentPage}
          // defaultCurrent={ 1 }
          defaultPageSize={this.props.perpageSize}
          onChange={this.handleChange}
          total={this.props.total}
          showTotal={(total, range) => this.handleNextButton(total, range)}
        />
      </div>
    );
  }
}

export default Paginations;
