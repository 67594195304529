import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "antd/dist/antd.css";
import { IdcardOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";

export default class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      businessCardOpen: false,
      images: [],
    };
  }

  componentDidMount() {
    if (this.props && this.props.photos) {
      this.setState({
        images: this.props.photos,
      });
    }
  }

  render() {
    const { photoIndex, isOpen, images, businessCardOpen } = this.state;
    return (
      <div>
        {this.props.noWidth && this.props.businessCard ? (
          <img
            src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${images}`}
            style={{
              width: "40px",
              cursor: "pointer",
            }}
            alt="nowidth"
            onClick={() => this.setState({ businessCardOpen: true })}
          />
        ) : !this.props.noWidth && this.props.businessCard ? (
          <IdcardOutlined
            style={{
              fontSize: "30px",
              width: "100px",
              cursor: "pointer",
            }}
            onClick={() => this.setState({ businessCardOpen: true })}
          />
        ) : (
          <Button
            //   className="btn-message-width"
            style={{
              height: "40px",
              background: "#FC572B",
              borderColor: "#fc572b",
              color: "white",
              zIndex: 1,
            }}
            onClick={() => this.setState({ isOpen: true })}
          >
            <FullscreenOutlined style={{ fontSize: "30px" }} />
          </Button>
        )}
        {businessCardOpen && (
          <Lightbox
            mainSrc={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${images}`}
            onCloseRequest={() => this.setState({ businessCardOpen: false })}
          />
        )}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
