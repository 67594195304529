import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import privatePost from "../../../images/protection.png";
import defaultImage2 from "../../../images/defaultImage2.jpg";

const ProfileCardAgent = ({ name, authenticate, handleItems }) => {
  const handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    await handleItems(
      name.userId,
      name.homeAgentName,
      name.homeAgentProfileUrl,
      name
    );
  };
  return (
    <>
      <Row>
        <div>
          <Link
            to={{
              pathname: `/homeDetails/offMls/${name.id && name.id}`,
              state: name && name,
            }}
            style={{ textDecoration: "none" }}
            onClick={handleLink}
          >
            <Col>
              <img
                src={
                  name.propertyDetails2 &&
                  name.propertyDetails2.homePhotos &&
                  name.propertyDetails2.homePhotos[0]
                    ? `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                      name.propertyDetails2.homePhotos[0]
                    : defaultImage2
                }
                alt={name.id}
                className="agent-homes"
                style={{ backgroundColor: "#d4d8f1fa" }}
              />
            </Col>

            <Col className="width-text-agent" style={{ marginTop: "10px" }}>
              <span className="agent-home-text">
                {name.propertyDetails2 && name.propertyDetails2.title
                  ? typeof name.propertyDetails2.title === "string"
                    ? name.propertyDetails2.title
                    : name.propertyDetails2.title.join(", ")
                  : name.privateDetails && name.privateDetails.address}
              </span>
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <img src={privatePost} style={{ height: "18px" }} alt="" />
              <span className="private-host">Off MLS</span>
            </Col>
          </Link>
        </div>
      </Row>
    </>
  );
};
export default ProfileCardAgent;
