import React, { Component } from "react";
import {
  Button,
  Layout,
  Row,
  Col,
  Card,
  Divider,
  Modal,
  notification,
  Popover,
  Popconfirm,
  Spin,
} from "antd";
import { withRouter } from "react-router";
import "antd/dist/antd.css";
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from "react-stripe-elements";
import { PrinterOutlined } from "@ant-design/icons";
import { list, update, getDetails, add } from "../../api/Apis";
import { withAppContext } from "../../store/contextStore";
import creditIcon from "../../images/plans/credit/credit.png";
import redStarIcon from "../../images/plans/stars/red/red.png";
import blueStarIcon from "../../images/plans/stars/blue/blue.png";
import greenStarIcon from "../../images/plans/stars/green/green.png";
import yellowStarIcon from "../../images/plans/stars/yellow/yellow.png";
import checkIcon from "../../images/plans/check/check.png";
import moment from "moment";
// import Loading from "../../common/Loading";

class _CardForm extends Component {
  state = {
    errorMessage: "",
    saveCard: false,
    card: false,
    code: false,
    successPayment: false,
    enablePayment: false,

    zipCode: "",
    zip: "",
    Ziplist: [],
    zipCount: 0,
    handleZipErrorMsg: false,

    serviceAreas: [],
    speclist: [],
    spec: 0,
    Addmore: false,
    optionsSelected: [],
    userCardList: [],
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  createOptions = () => {
    return {
      style: {
        base: {
          fontSize: "16px",
          color: "#424770",
          fontFamily: "Open Sans, sans-serif",
          letterSpacing: "0.025em",
          border: "1px solid red",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#c23d4b",
        },
      },
    };
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    } else {
      this.setState({ errorMessage: "" });
    }
  };

  handlePaymentSubmit = () => {
    this.setState({
      successPayment: true,
    });
    if (this.props && this.props.stripe) {
      this.props.stripe.createToken().then((data) => {
        if (!("error" in data)) {
          this.savePaymentInfo(data);
        } else {
          if (data["error"]["decline_code"] === "live_mode_test_card") {
            alert(
              "You are in live mode don't use test card, please use live card only."
            );
          }
        }
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  savePaymentInfo = async (stripeToken) => {
    const { userCards, userInfo, currentPlan, upgradePlan } = this.props;
    const data = {
      entityData: {
        amount: upgradePlan.price,
        userId: userInfo.id,
        oldSubscriptionId: currentPlan.id,
        newSubscriptionId: upgradePlan.id,
        saveCard: true,
        email: userInfo.email,

        serviceType: userCards && userCards.length > 0 ? "upgrade" : "new",
        tokenId:
          userCards && userCards.length > 0 ? null : stripeToken.token.id,
        customerId:
          userCards && userCards.length > 0 ? userCards[0].customerId : null,
        cardDetails:
          userCards && userCards.length > 0
            ? null
            : {
                id: stripeToken.token.card.id,
                lastDigits: stripeToken.token.card.last4,
                cardType: stripeToken.token.card.brand,
              },
      },
    };

    const clearCardDetails = this.props.elements.getElement("card");

    const response = await add("upgradePlan", data);

    if (response.success) {
      this.updateProfileInfo();
      clearCardDetails.clear();
    } else {
      this.setState({
        successPayment: false,
      });
      notification["error"]({
        message: response.errors.message,
      });
      clearCardDetails.clear();
    }
  };

  updateProfileInfo = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const newUpdatedData = await getDetails("users", userDetails.id);

    if (newUpdatedData && newUpdatedData.data && newUpdatedData.data.newdata) {
      const newdata = newUpdatedData.data.newdata;
      newdata["zipCodes"] = [];
      newdata["Specialties"] = [];
      const data = {
        entityData: {
          newdata: newdata,
        },
      };
      const { userInfo } = this.props;
      const updatingUserDetails = await update("users", userInfo.id, data);
      if (updatingUserDetails.success) {
        this.cancelPaymentMethod();
        this.setState({
          successPayment: false,
          enablePayment: false,
          Ziplist: [],
          optionsSelected: [],
        });
        notification["success"]({
          message:
            "You have successfully changed your subscription plan. Thank you.",
        });
      } else {
        notification["error"]({
          message: "Somethig Went Wrong",
          description: "Please try again",
        });
        this.setState({
          successPayment: false,
          enablePayment: false,
          Ziplist: [],
          optionsSelected: [],
        });
      }
    } else {
      this.cancelPaymentMethod();
      notification["success"]({
        message:
          "You have successfully changed your subscription plan. Thank you.",
      });
    }
  };

  cancelPaymentMethod = () => {
    this.setState({
      successPayment: false,
      enablePayment: false,
      Ziplist: [],
      optionsSelected: [],
    });
    this.props && this.props.cancelPayment();
  };

  cancel = (e) => {
    console.log(e);
  };

  handleZip = (value) => {
    this.setState({ zip: value.target.value, multiZip: value.target.value });
    if (this.state.Ziplist.includes(value.target.value)) {
      this.setState({
        handleZipErrorMsg: true,
      });
      notification["error"]({
        message:
          "You have already added " +
          value.target.value +
          " zip code. Please add another",
      });
    }
  };

  handleChangeToAddZip = (value) => {
    if (this.state.Ziplist.includes(this.state.zip)) {
      notification["error"]({
        message:
          "You have already added " +
          this.state.zip +
          " zip code. Please add another",
      });
    } else {
      const Ziplist = [...this.state.Ziplist];
      Ziplist.push(this.state.zip);
      this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    }
  };

  handleDeselect = (value) => {
    const deleteItem = this.state.Ziplist.indexOf(value);
    let presentItems = this.state.Ziplist.splice(deleteItem, 1);
    this.setState({ zipCount: presentItems.length });
  };

  handlespecialties = (value) => {
    this.setState({ optionsSelected: value });

    if (value.length === this.props.upgradePlan.specialites) {
      notification.info({
        message: "Please upgrade to add more specialites",
      });
    }
  };

  render() {
    const { successPayment } = this.state;
    return (
      <Card
        style={{ marginTop: "15px" }}
        bodyStyle={{ backgroundColor: "#f9faff" }}
      >
        <Card>
          <CardElement onChange={this.handleChange} {...this.createOptions()} />
          <p style={{ color: "red" }}>{this.state.errorMessage}</p>
          <Row>
            <Divider></Divider>
          </Row>

          <Row>
            <Col
              xl={{ span: 11 }}
              lg={{ span: 11 }}
              md={{ span: 11 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Spin spinning={successPayment}>
                <h5
                  style={{
                    padding: "10px",
                    backgroundColor: "#3045D2",
                    borderRadius: "5px",
                    color: "#FFF",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => this.handlePaymentSubmit()}
                >
                  Pay ${this.props.upgradePlan.price}
                </h5>
              </Spin>
            </Col>
            <Col
              xl={{ span: 11, offset: 2 }}
              lg={{ span: 11, offset: 2 }}
              md={{ span: 11, offset: 2 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <h5
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  color: "#3045D2",
                  border: "1px solid #3045D2",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => this.cancelPaymentMethod()}
              >
                Cancel
              </h5>
            </Col>
          </Row>
        </Card>
      </Card>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentVisible: false,
      planVisible: false,
      cardholderName: "",
      cardNumber: "",
      cardYear: "",
      cardCode: "",
      plansList: [],
      userInfo: "",
      userCards: [],
      userPlanHistory: [],
      userPlan: "",
      selectedPlan: "",
      enableOptions: false,

      zipCode: "",
      zip: "",
      Ziplist: [],
      zipCount: 0,
      handleZipErrorMsg: false,

      serviceAreas: [],
      speclist: [],
      spec: 0,
      Addmore: false,
      optionsSelected: [],
      successPayment: false,
      latestPlanHistory: [],
      subscriptionSuccess: false,
      subscriptionHistory: [],
      stripeCardDetails: {}
    };
  }

  componentDidMount() {
    this.getPlans();
    this.getUserPlan();
    this.getSripeKeysDetails()
  }

  getSripeKeysDetails = async () =>{

    // const userDetails = JSON.parse(localStorage.getItem("userDetails"))

      // let query = {
      //   orgId: userDetails.orgId
      // }

    list("masterKeysDetails",{})
    .then(res=>{
      if(res.success){
        this.setState({
          stripeCardDetails:res.data[0]
        })
      }
    })
    .catch(err=>console.log(err,"errr"))
  }

  getPlans = async () => {
    const { userDetails } = await this.props.context.state;
    let userProfile =
      userDetails?.userProfile === "V" ||
      userDetails?.userProfile === "B" ||
      userDetails?.userProfile === "S"
        ? "V"
        : userDetails?.userProfile;

    const response = await list(
      "subscriptionPacks?userType=" +
        userProfile +
        "&orgId=" +
        userDetails.orgId
    );

    if (response.success) {
      let sortPlans = response.data.sort((a, b) =>
        parseInt(a["price"]) > parseInt(b["price"]) ? 1 : -1
      );
      this.setState({
        plansList: sortPlans,
      });
      // this.props.setLoadingfalse();
    }
  };

  getUserPlan = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const agentInfo = await getDetails(
      "users",
      userDetails.id && userDetails.id + "/?totalDetails=true"
    );
    if (agentInfo.success) {
      this.props.context.setUserDetails(agentInfo.data);
      this.setState({
        userInfo: agentInfo.data,
        userCards: agentInfo.data.cards,
        userPlanHistory: agentInfo.data.paymentHistory,
        userPlan: agentInfo.data.subscriptionPack
          ? agentInfo.data.subscriptionPack
          : "",
        latestPlanHistory: agentInfo.data.latestPlanHistory,
        subscriptionHistory: agentInfo.data.subscriptionHistory
          ? agentInfo.data.subscriptionHistory
          : [],
      });
    }
  };

  cancel = (e) => {
    console.log(e);
  };

  baseSubscription = async (action, plan) => {
    console.log(action,plan,"heroo");
    this.setState({
      subscriptionSuccess: true,
    });
    const { plansList, userInfo, userPlan,userCards } = this.state;
    let basePlan = plansList.filter(
      (plan) => plan.price === "0" || plan.price === 0
    );
    let planType = null;
    let planId = null;
    let planPrice = "0";
    let upgrade_or_downgrade = "";

    if (action === "cancelPlan") {
      planId = basePlan[0].id;
      planPrice = basePlan[0].price;
      planType = "cancel";
      upgrade_or_downgrade = "Downgrade";
      // this.updateSubscription("741b8a70-da7a-11ec-8e62-237afbb488cd");
    } else if (action === "upgradePlan") {
      planId = plan.id;
      planPrice = plan.price;
      planType = "upgrade";
      upgrade_or_downgrade =
        parseInt(plan.price) > parseInt(userPlan.price)
          ? "Upgrade"
          : "Downgrade";
      // this.updateSubscription(plan.id);
    }

    const data = {
      entityData: {
        saveCard: true,
        email: userInfo.email,
        userId: userInfo.id,
        oldSubscriptionId: userPlan.id,

        newSubscriptionId: planId,
        amount: planPrice,
        serviceType: planType,

        customerId: userCards[0].customerId,
        // customerId: null,
        cardDetails: {},
        tokenId: null,

        // savedCardId: userCards[0].id,
        savedCardId: null,
        upgrade_or_downgrade,
        old_plan_title: userPlan.planTitle,
        new_plan_title: plan.planTitle,
      },
    };
    console.log(data,"return,...");

    const response = await add("upgradePlan", data);
    if (response.success) {
      // notification["success"]({
      //   message:
      //     "You have successfully changed your subscription plan. Thank you.",
      // });
      this.updateProfileInfo();
    } else {
      notification["error"]({
        message: "Somethig Went Wrong",
        description: "Please try again",
      });
      this.cancelPayment();
    }
  };

  cancelPayment = () => {
    this.setState({
      paymentVisible: false,
      planVisible: false,
      enableOptions: false,
      zipCode: "",
      zip: "",
      Ziplist: [],
      zipCount: 0,
      handleZipErrorMsg: false,

      serviceAreas: [],
      speclist: [],
      spec: 0,
      Addmore: false,
      optionsSelected: [],
      successPayment: false,
    });
    this.getPlans();
    this.getUserPlan();
  };

  cardView = (payment) => {
    return (
      <Card hoverable className="subscription-history">
        <Row className="subscription-history-card-paddingTop">
          <Col
            xl={{ span: 5 }}
            lg={{ span: 5 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <h5
              style={{
                textAlign: "center",
                textTransform: "capitalize",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {moment(payment.createdSubDate * 1000).format("lll")}
            </h5>
          </Col>
          {/* <Col
            xl={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            md={{ span: 6, offset: 1 }}
            sm={{ span: 6, offset: 1 }}
            xs={{ span: 6, offset: 1 }}
          >
            <h5
              style={{
                textAlign: "center",
                textTransform: "capitalize",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {moment(payment.endDate * 1000).format("lll")}
            </h5>
          </Col> */}
          <Col
            xl={{ span: 7, offset: 0 }}
            lg={{ span: 7, offset: 0 }}
            md={{ span: 11, offset: 1 }}
            sm={{ span: 11, offset: 1 }}
            xs={{ span: 11, offset: 1 }}
          >
            <h5
              style={{
                textTransform: "capitalize",
                color:
                  payment.price && parseInt(payment.price) === 100
                    ? "#db0001"
                    : payment.price && parseInt(payment.price) === 50
                    ? "#3045d2"
                    : payment.price && parseInt(payment.price) === 25
                    ? "#f7d131"
                    : "#3abe15",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
              className="plan-card-view-mobile"
            >
              {/* {payment.amount && parseInt(payment.amount) == 100
                ? "Professional Plan"
                : payment.amount && parseInt(payment.amount) == 50
                ? "Advanced Plan"
                : payment.amount && parseInt(payment.amount) == 25
                ? "Basic Plan"
                : "Free Plan"} */}
              {payment.old_plan_title} {"Plan"} {" to "}{" "}
              {payment.new_plan_title} {"Plan"}
            </h5>
          </Col>
          <Col
            xl={{ span: 3 }}
            lg={{ span: 3 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
            xs={{ span: 6 }}
          >
            <h5
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
              className="plan-card-view-mobile"
            >
              {payment.upgrade_or_downgrade}
            </h5>
          </Col>
          <Col
            xl={{ span: 3 }}
            lg={{ span: 3 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
            xs={{ span: 6 }}
          >
            <h5
              style={{
                textAlign: "center",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              ${payment.price}
            </h5>
          </Col>
          <Col
            xl={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            md={{ span: 6, offset: 1 }}
            sm={{ span: 6, offset: 1 }}
            xs={{ span: 6, offset: 1 }}
          >
            <h5
              style={{
                backgroundColor:
                  payment.paymentStatus === "success" ? "#AAE8B6" : "tomato",
                color: "#FFF",
                padding: "2px 5px",
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {payment.paymentStatus}
            </h5>
          </Col>
          {/* <Col
            xl={{ span: 1, offset: 1 }}
            lg={{ span: 1, offset: 1 }}
            md={{ span: 3, offset: 2 }}
            sm={{ span: 3, offset: 2 }}
            xs={{ span: 3, offset: 2 }}
          >
            <Popover
              content={<span style={{ color: "red" }}>Print Report</span>}
              title={null}
              trigger="hover"
            >
              <PrinterOutlined style={{ color: "red" }} />
            </Popover>
          </Col> */}
        </Row>
      </Card>
    );
  };

  updateSubscription = async (values) => {
    const { userInfo } = this.state;
    console.log(values,"upgrade sup=>");
    let data = {
      entityData: {
        subscriptionPack: values,
      },
    };
    const updatingUserDetails = await update("users", userInfo.id, data);
    if (updatingUserDetails.success) {
      this.cancelPayment();
      notification["success"]({
        message:
          "You have successfully changed your subscription plan. Thank you.",
      });
      this.setState({
        subscriptionSuccess: false,
      });
    } else {
      this.cancelPayment();
      notification["error"]({
        message: "Somethig Went Wrong",
        description: "Please try again",
      });
    }
  };

  updateProfileInfo = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const newUpdatedData = await getDetails("users", userDetails.id);
    if (
      newUpdatedData &&
      newUpdatedData.data &&
      newUpdatedData.data.subscriptionPack
    ) {
      const newdata = newUpdatedData.data.subscriptionPack;
      newdata["zipCodes"] = [];
      newdata["Specialties"] = [];
      const data = {
        entityData: {
          newdata: newdata,
        },
      };
      const { userInfo } = this.state;
      const updatingUserDetails = await update("users", userInfo.id, data);
      if (updatingUserDetails.success) {
        this.cancelPayment();
        notification["success"]({
          message:
            "You have successfully changed your subscription plan. Thank you.",
        });
        this.setState({
          subscriptionSuccess: false,
        });
      } else {
        this.cancelPayment();
        notification["error"]({
          message: "Somethig Went Wrong",
          description: "Please try again",
        });
      }
    } else {
      this.cancelPayment();
      notification["success"]({
        message:
          "You have successfully changed your subscription plan. Thank you.",
      });
    }
  };

  handleZip = (value) => {
    this.setState({ zip: value.target.value, multiZip: value.target.value });
    if (this.state.Ziplist.includes(value.target.value)) {
      this.setState({
        handleZipErrorMsg: true,
      });
      notification["error"]({
        message:
          "You have already added " +
          value.target.value +
          " zip code. Please add another",
      });
    }
  };

  handleChangeToAddZip = (value) => {
    if (this.state.Ziplist.includes(this.state.zip)) {
      notification["error"]({
        message:
          "You have already added " +
          this.state.zip +
          " zip code. Please add another",
      });
    } else {
      const Ziplist = [...this.state.Ziplist];
      Ziplist.push(this.state.zip);
      this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    }
  };

  handleDeselect = (value) => {
    const deleteItem = this.state.Ziplist.indexOf(value);
    let presentItems = this.state.Ziplist.splice(deleteItem, 1);
    this.setState({ zipCount: presentItems.length });
  };

  handlespecialties = (value) => {
    this.setState({ optionsSelected: value });

    if (
      value.length === parseInt(this.state.plansList[0]) &&
      this.state.plansList[0].specialites
    ) {
      notification.info({
        message: "Please upgrade to add more specialites",
      });
    }
  };

  render() {
    const {
      plansList,
      userCards,
      userInfo,
      userPlan,
      selectedPlan,
      // subscriptionHistory,
      stripeCardDetails
    } = this.state;

    console.log(userPlan,"oooo");
    return (
      <Layout style={{ backgroundColor: "#FFF" }}>
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row style={{ marginTop: "-10px", marginBottom: "-30px" }}>
              <div class="ant-card-head-wrapper">
                <div class="ant-card-head-title">Subscriptions123</div>
              </div>
            </Row>
            <Row>
              <Divider></Divider>
            </Row>
            <Row style={{ marginTop: "-10px", marginBottom: "-30px" }}>
              <Col
                xl={{ span: 9 }}
                lg={{ span: 9 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ padding: "10px" }}
              >
                <h4>
                  <span style={{ fontWeight: "bold" }}>Plan Active : </span>{" "}
                  {moment(1641562004).format("lll")}
                </h4>
              </Col>
              <Col
                xl={{ span: 9 }}
                lg={{ span: 9 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ padding: "10px" }}
              >
                <h4>
                  <span style={{ fontWeight: "bold" }}>Next Payment : </span>
                  {moment(userInfo.validTo).format("lll")}
                </h4>
              </Col>
              <Col
                xl={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ padding: "10px" }}
              >
                <h4>
                  <span style={{ fontWeight: "bold" }}>Expire : </span>
                  {moment(userInfo.validTo).fromNow()}
                </h4>
              </Col>
              <Col
                xl={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Popover
                  content={<span style={{ color: "red" }}>Print Report</span>}
                  title={null}
                  trigger="hover"
                >
                  <PrinterOutlined
                    style={{
                      color: "red",
                      fontSize: "19px",
                      marginTop: "10px",
                    }}
                  />
                </Popover>
              </Col>
            </Row>

            <Row>
              <Divider></Divider>
            </Row>

            <Row>
              <Col
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ padding: "10px" }}
              >
                <h4>Current Subscription Plan</h4>
                <Card style={{ backgroundColor: "#F9FAFF" }}>
                  <Row>
                    <Col span={16}>
                      <Row>
                        <Col span={8}>
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "25px",
                              backgroundColor: "white",
                              boxShadow: "0vw 0.2vw 0.4vw #d4d8f1fa",
                              padding: "7px",
                            }}
                          >
                            <img
                              src={
                                userPlan.price &&
                                parseInt(userPlan.price) === 100
                                  ? redStarIcon
                                  : userPlan.price &&
                                    parseInt(userPlan.price) === 50
                                  ? blueStarIcon
                                  : userPlan.price &&
                                    parseInt(userPlan.price) >= 20
                                  ? yellowStarIcon
                                  : greenStarIcon
                              }
                              alt={
                                userPlan.price &&
                                parseInt(userPlan.price) === 100
                                  ? "redStarIcon"
                                  : userPlan.price &&
                                    parseInt(userPlan.price) === 50
                                  ? "blueStarIcon"
                                  : userPlan.price &&
                                    parseInt(userPlan.price) >= 20
                                  ? "yellowStarIcon"
                                  : "greenStarIcon"
                              }
                              style={{
                                width: "35px",
                                height: "35px",
                              }}
                            />
                          </div>
                        </Col>
                        <Col span={16}>
                          <h4> {userPlan?.planTitle} Plan</h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <h4>{"$" + userPlan.price + "/Month"}</h4>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "-20px" }}>
                    <Col span={16}>
                      <Row>
                        <Col offset={8} span={16}>
                          <h5>
                            {userPlan.zipcodes ? userPlan.zipcodes : "1"} Zip
                            Code,{" "}
                            {userPlan.specialites ? userPlan.specialites : "2"}{" "}
                            specialities
                          </h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "15px" }}>
                    <Col span={12}>
                      <Button
                        style={{
                          padding: "10px",
                          backgroundColor: "#3045D2",
                          borderRadius: "5px",
                          color: "#FFF",
                          width: "95%",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          this.setState({
                            planVisible: true,
                          })
                        }
                      >
                        Upgrade Plan
                      </Button>
                    </Col>
                    {userPlan.price && parseInt(userPlan.price) !== 0 && (
                      <Col span={12}>
                        <Popconfirm
                          title="Do you want to change your plan?"
                          onConfirm={() =>
                            this.baseSubscription("cancelPlan", null)
                          }
                          onCancel={() => this.cancel()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              color: "#3045D2",
                              borderColor: "1px solid #3045D2",
                              width: "95%",
                            }}
                            className="Cancel-Subscription"
                          >
                            Cancel Subscription
                          </Button>
                        </Popconfirm>
                      </Col>
                    )}
                  </Row>
                </Card>
              </Col>

              {userPlan.price &&
                parseInt(userPlan.price) !== 0 &&
                userCards &&
                userCards.length > 0 && (
                  <Col
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ padding: "10px" }}
                  >
                    <h4>Payment</h4>
                    {userCards.slice(0, 1).map((card, index) => (
                      <Card
                        key={index}
                        style={{ backgroundColor: "#F9FAFF", height: "150px" }}
                      >
                        <Row>
                          <Col span={24}>
                            <Row>
                              <Col span={6}>
                                <div
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "25px",
                                    backgroundColor: "white",
                                    boxShadow: "0vw 0.2vw 0.4vw #d4d8f1fa",
                                    padding: "5px",
                                  }}
                                >
                                  <img
                                    src={creditIcon}
                                    alt="creditIcon"
                                    style={{
                                      width: "35px",
                                      height: "35px",
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col span={18}>
                                <h5>{card.cardType} Card</h5>
                                <h3>**** **** **** {card.lastDigits}</h3>
                              </Col>
                            </Row>

                            {/* <Row>
                              <Col
                                xl={{ span: 9 }}
                                lg={{ span: 9 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ padding: "10px" }}
                              >
                                <h5 style={{ fontWeight: "bold" }}>
                                  Plan Activated :
                                </h5>
                                <h5>
                                  {moment(userInfo.validFrom).format("lll")}
                                </h5>
                              </Col>
                              <Col
                                xl={{ span: 9 }}
                                lg={{ span: 9 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ padding: "10px" }}
                              >
                                <h5 style={{ fontWeight: "bold" }}>
                                  Next Payment :{" "}
                                </h5>
                                <h5 style={{ fontWeight: "bold" }}>
                                  {moment(userInfo.validTo).format("lll")}
                                </h5>
                              </Col>
                              <Col
                                xl={{ span: 6 }}
                                lg={{ span: 6 }}
                                md={{ span: 24 }}
                                sm={{ span: 24 }}
                                xs={{ span: 24 }}
                                style={{ padding: "10px" }}
                              >
                                <h5 style={{ fontWeight: "bold" }}>Expire :</h5>
                                <h5>{moment(userInfo.validTo).fromNow()}</h5>
                              </Col>
                            </Row> */}
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </Col>
                )}
            </Row>

            {/* {subscriptionHistory && subscriptionHistory.length > 0 && (
              <Row>
                <Col
                  span={24}
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ padding: "10px" }}
                >
                  <Row>
                    <h4>Invoices</h4>
                  </Row>
                  <Row>
                    <h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
                      LAST INVOICE
                    </h6>
                    {subscriptionHistory &&
                      subscriptionHistory.length > 0 &&
                      subscriptionHistory
                        .slice(0, 1)
                        .map((payment) => this.cardView(payment))}
                  </Row>

                  {subscriptionHistory && subscriptionHistory.length > 0 && (
                    <Row>
                      <h6 style={{ marginTop: "10px", fontWeight: "bold" }}>
                        HISTORY ({subscriptionHistory.length})
                      </h6>
                      {subscriptionHistory &&
                        subscriptionHistory.length > 1 &&
                        subscriptionHistory
                          .slice(1, subscriptionHistory.length)
                          .map((payment) => this.cardView(payment))}
                    </Row>
                  )}
                </Col>
              </Row>
            )} */}
          </Col>
        </Row>

         <Modal
          title="Choose your plan123"
          className="buyer-agent-modal payment-view modal-black-close"
          footer={null}
          visible={this.state.paymentVisible}
          onCancel={() =>
            this.setState({
              paymentVisible: false,
            })
          }
          width={window.screen.width >= "768" ? "40vw" : "90vw"}
        >
          <StripeProvider 
            // apiKey={process.env.REACT_APP_STRIPE}
            apiKey={stripeCardDetails?.publishableKey}
          >
            <Elements>
              <CardForm
                userInfo={userInfo}
                userCards={userCards}
                upgradePlan={selectedPlan}
                currentPlan={userPlan}
                cancelPayment={() => this.cancelPayment()}
                onRef={(ref) => (this.child = ref)}
              />
            </Elements>
          </StripeProvider>
        </Modal> 

        <Modal
          title="Upgrade Your Plan"
          className="buyer-agent-modal plans-view modal-black-close"
          footer={null}
          visible={this.state.planVisible}
          onCancel={() =>
            this.setState({
              planVisible: false,
            })
          }
          width={
            plansList && plansList.length && plansList.length >= 4
              ? "100vw"
              : "60vw"
          }
        >
          {/* <Loading enableLoading={this.state.subscriptionSuccess} /> */}
          <div className="subscription-plans-modal-view">
            <Row className="row-margin-subscription">
              {userPlan.price !== "" &&
                plansList &&
                plansList.length > 0 &&
                plansList.map((plan, index) => (
                  <Col
                    xl={{
                      span:
                        plansList && plansList.length && plansList.length >= 4
                          ? 5
                          : 7,
                      offset: 1,
                    }}
                    lg={{
                      span:
                        plansList && plansList.length && plansList.length >= 4
                          ? 5
                          : 7,
                      offset: 1,
                    }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    key={index}
                    className="plan-card-margin-set"
                  >
                    <Card
                      hoverable={
                        parseInt(userPlan.price) === parseInt(plan.price)
                          ? false
                          : true
                      }
                      style={{
                        borderRadius: "5px",
                        backgroundColor:
                          parseInt(userPlan.price) === parseInt(plan.price)
                            ? "#f9fafe"
                            : "white",
                        padding: "5px",
                      }}
                      bodyStyle={{ padding: "10px" }}
                      className="subscription-card"
                    >
                      <div className="subscrptioncard-width">
                        <Row style={{ marginBottom: "20px" }}>
                          <Col span={8}>
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "25px",
                                backgroundColor: "white",
                                boxShadow: "0vw 0.2vw 0.4vw #d4d8f1fa",
                                padding: "7px",
                              }}
                            >
                              <img
                                src={
                                  plan.price && parseInt(plan.price) === 100
                                    ? redStarIcon
                                    : plan.price && parseInt(plan.price) === 50
                                    ? blueStarIcon
                                    : plan.price && parseInt(plan.price) >= 20
                                    ? yellowStarIcon
                                    : greenStarIcon
                                }
                                alt={
                                  plan.price && parseInt(plan.price) === 100
                                    ? "redStarIcon"
                                    : plan.price && parseInt(plan.price) === 50
                                    ? "blueStarIcon"
                                    : plan.price && parseInt(plan.price) === 25
                                    ? "yellowStarIcon"
                                    : "greenStarIcon"
                                }
                                style={{
                                  width: "35px",
                                  height: "35px",
                                }}
                              />
                            </div>
                          </Col>
                          <Col span={4}>{/* <h4></h4> */}</Col>
                          <Col span={12}>
                            {plan.price && parseInt(plan.price) === 50 ? (
                              <h6
                                style={{
                                  padding: "10px",
                                  backgroundColor: "#FC572B",
                                  borderRadius: "5px",
                                  color: "#FFF",
                                  marginTop: "10px",
                                  textAlign: "center",
                                }}
                              >
                                Most Popular
                              </h6>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <h3 style={{ fontWeight: "bold" }}>
                            {plan.planTitle} {"Plan"}
                          </h3>
                        </Row>
                        <Row>
                          <h4 style={{ color: "#848484" }}>
                            <span style={{ fontWeight: "bold", color: "#000" }}>
                              {plan.zipcodes && plan.zipcodes}
                            </span>{" "}
                            Zip Code
                          </h4>
                        </Row>
                        {(plan.userType === "AG" || plan.userType === "L") && (
                          <Row>
                            <h4 style={{ color: "#848484" }}>
                              <span
                                style={{ fontWeight: "bold", color: "#000" }}
                              >
                                {plan.specialites && plan.specialites}
                              </span>{" "}
                              Specialities
                            </h4>
                          </Row>
                        )}

                        {plan.showOnAgentSearchPage &&
                          plan.showOnAgentSearchPage === "P" && (
                            <Row>
                              <h4 style={{ marginTop: "5px" }}>
                                <img
                                  src={checkIcon}
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    marginRight: "5px",
                                  }}
                                  alt=""
                                />
                                {plan.userType === "AG"
                                  ? "Show on Agent Search Page"
                                  : "Premier Placement at top of town/zip on Market place Page"}
                              </h4>
                            </Row>
                          )}

                        {plan.showOnAgentSearchPage &&
                          plan.showOnAgentSearchPage === "Y" && (
                            <Row>
                              <h4 style={{ marginTop: "5px" }}>
                                <img
                                  src={checkIcon}
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    marginRight: "5px",
                                  }}
                                  alt=""
                                />
                                {plan.userType === "AG"
                                  ? "Show on Agent Search Page"
                                  : "Show on market place Page"}
                              </h4>
                            </Row>
                          )}

                        {plan.showOnListAgentPage &&
                          plan.showOnListAgentPage === "P" && (
                            <Row>
                              <h4 style={{ marginTop: "5px" }}>
                                <img
                                  src={checkIcon}
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    marginRight: "5px",
                                  }}
                                  alt=""
                                />
                                Premier Placement at top of town/zip on Buyers
                                Search Page
                              </h4>
                            </Row>
                          )}

                        {plan.showOnListAgentPage &&
                          plan.showOnListAgentPage === "Y" && (
                            <Row>
                              <h4 style={{ marginTop: "5px" }}>
                                <img
                                  src={checkIcon}
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    marginRight: "5px",
                                  }}
                                  alt=""
                                />
                                Show on Speak to Buyers Agent Popup
                              </h4>
                            </Row>
                          )}

                        {plan.premierStatus && plan.premierStatus === "Y" && (
                          <Row>
                            <h4
                              style={{ marginTop: "5px", marginBottom: "10px" }}
                            >
                              <img
                                src={checkIcon}
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  marginRight: "5px",
                                }}
                                alt=""
                              />
                              Premier Status
                            </h4>
                          </Row>
                        )}

                        {plan.planTitle &&
                          plan.userType === "AG" &&
                          plan.planTitle === "Professional" && (
                            <Row>
                              <h4 style={{ marginTop: "5px" }}>
                                <img
                                  src={checkIcon}
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    marginRight: "5px",
                                  }}
                                  alt=""
                                />
                                Earn revenue and stock opportunities when you
                                help us grow
                              </h4>
                            </Row>
                          )}
                        {plan.planTitle &&
                          plan.userType === "AG" &&
                          (plan.planTitle === "Advanced" ||
                            plan.planTitle === "Professional") && (
                            <Row>
                              <h4 style={{ marginTop: "5px" }}>
                                <img
                                  src={checkIcon}
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    marginRight: "5px",
                                  }}
                                  alt=""
                                />
                                Buyer lead referrals for unclaimed leads
                              </h4>
                            </Row>
                          )}
                      </div>

                      <div>
                        <Row>
                          <h2 style={{ fontWeight: "bold", marginTop: "15px" }}>
                            {plan.price && parseInt(plan.price) > 0
                              ? `$${plan.price}/month`
                              : "Free"}
                          </h2>
                        </Row>

                        <Row>
                          {(Object.keys(userPlan).length === 0 && parseInt(plan.price) === 0) || parseInt(userPlan.price) === parseInt(plan.price) ? (
                            <h5
                              style={{
                                padding: "10px",
                                backgroundColor: "#3045D2",
                                borderRadius: "5px",
                                color: "#FFF",
                                textAlign: "center",
                                cursor: "pointer",
                                width: "100%",
                                opacity: "50%",
                              }}
                            >
                              Current Plan
                            </h5>
                          ) : (
                            <Popconfirm
                              title="Do you want to change your plan?"
                              onConfirm={() => {
                                if (userCards && userCards.length > 0) {
                                if (plan.price && parseInt(plan.price) === 0) {
                                  this.baseSubscription("cancelPlan", plan);
                                } else {
                                  this.baseSubscription("upgradePlan", plan);
                                }
                                } else {
                                  this.setState({
                                    selectedPlan: plan,
                                    paymentVisible: true,
                                  });
                                }
                              }}
                              onCancel={() => this.cancel()}
                              okText="Yes"
                              cancelText="No"
                            >
                              <h5
                                style={{
                                  padding: "10px",
                                  backgroundColor: "#3045D2",
                                  borderRadius: "5px",
                                  color: "#FFF",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                              >
                                Choose {plan.planTitle}
                              </h5>
                            </Popconfirm>
                          )}
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
        </Modal>
      </Layout>
    );
  }
}

export default withAppContext(withRouter(Subscriptions));
