import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Modal,
  Avatar,
  Card,
  notification,
  Button,
  Popover,
  Divider,
  Switch,
  Form,
  Upload,
  Input,
  Dropdown,
  Drawer,
  Collapse,
  Spin,
  Typography,
  Checkbox,
} from "antd";
import Amplify, { Auth, Storage } from "aws-amplify";
import AwsConfig from "../config/AwsConfig";
import {
  update,
  getDetails,
  list,
  noAuthgetDetails,
} from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import {
  LoginOutlined,
  LogoutOutlined,
  HeartFilled,
  UserOutlined,
  ShopOutlined,
  SettingOutlined,
  // CameraOutlined,
} from "@ant-design/icons";
import Notifications from "./Notifications";
import moment from "moment";
import {
  HeaderButton,
  ModalTitle,
} from "../StyledComponents/StyledComponents.js";
import { Panel as ColorPickerPanel } from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import iconAdmin from "../images/icon_admin.png";
import logo1 from "../images/logo1.png";
import CreateUserName from "../containers/CreateUserName";
import ImageCrop from "antd-img-crop";
import "antd/es/modal/style";
import "antd/es/slider/style";

const { Meta } = Card;
const { Panel } = Collapse;
const { Paragraph } = Typography;
Amplify.configure(AwsConfig);

// const dummyRequest = ({ file, onSuccess }) => {
//   setTimeout(() => {
//     onSuccess("ok");
//   }, 0);
// };

class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      searchType:
        localStorage.getItem("searchType") != null
          ? localStorage.getItem("searchType")
          : "Homes",
      newUserDetails: "",
      Favorites: [],
      showVendorConfirmation: false,
      headerLogo: "",
      tabSelectedColor: "",
      headerbgSelectedColor: "",
      buttonColor: "",
      buttonbgColor: "",
      imageLoading: false,
      localModalTest: false,
      tokenGenerated: "",
      profileImage2: "",
      headerTabsDetails: {},
    };
  }

  componentDidMount() {
    this.getWebsiteData();
    this.props.context.setLoading(true);
    let localToken = "";
    localToken = JSON.parse(localStorage.getItem("userToken"));
    let userIdDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (!localToken) {
      Auth.currentUserInfo()
        .then((user) => {
          if (user) {
            let userId = user.username;
            if (user.username.indexOf("Facebook") > -1) {
              userId = user.attributes?.sub;
            }
            if (user.username.indexOf("Google") > -1) {
              userId = user.attributes?.sub;
            }
            if (user.username !== undefined && user.username !== null) {
              this.getUserProfile(userId);
            }
          }
        })
        .catch((err) => console.log(err, "erorr"));
      Auth.currentSession()
        .then((response) => {
          this.setState({ tokenGenerated: response.idToken.jwtToken });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ tokenGenerated: localToken });
      this.getUserProfile(userIdDetails?.id);
    }
  }

  getWebsiteData = () => {
    this.props.context.getWebsites(window.location.hostname);
  };

  getUserProfile = async (id) => {
    let newUserDetails = {};
    noAuthgetDetails("getUsers", id).then(async (result) => {
      if (result.success) {
        if (result.data.profilePicture) {
          const profileUrl =
            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            result.data.profilePicture[0];
          result.data["profileUrl"] = profileUrl;
          newUserDetails = { ...result.data };
          this.props.context.setUserDetails(newUserDetails);
          this.setState({ userDetails: newUserDetails });
        } else {
          this.props.context.setUserDetails(result.data);
          this.setState({ userDetails: result.data });
        }
        this.props.context.setLoading(false);
      } else {
        console.log(result.errors);
      }
    });

    const userId = id;
    try {
      const getFavorites = await list("userFavorites", { userId: userId });
      if (getFavorites.success) {
        this.setState({
          Favorites: getFavorites.data,
        });
        this.props.context.handleFavoritesCount(
          this.state.Favorites,
          this.state.Favorites.length
        );
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
    }

    this.setState({
      loading: false,
      showVendorConfirmation: false,
    });
  };

  handleClear = () => {
    window.location.reload();
  };

  handleLogout = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let currentDate = moment(new Date()).format("MM/DD/YYYY");

    let userLoginTime = localStorage.getItem("userLoginTime");
    let userLogoutTime = new Date().getTime();

    var diffMs = userLogoutTime - userLoginTime; // milliseconds between now & login time
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    let diffTime = { Days: diffDays, Hours: diffHrs, Mins: diffMins };
    let totalVisits =
      userDetails && userDetails.totalVisits ? userDetails.totalVisits : [];
    totalVisits?.map((p) => {
      let totalTime = p.totalTime ? p.totalTime : [];
      if (p.check_today_date === currentDate) {
        totalTime.push(diffTime);
        p["totalTime"] = totalTime;
      }
      return p;
    });

    let data = {
      entityData: {
        totalVisits,
        lastActive: moment(new Date()).format("MM/DD/YYYY HH:MM"),
      },
    };

    await update("users", userDetails.id, data);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    try {
      await Auth.signOut()
        .then((res) => {
          localStorage.setItem("userDetails", JSON.stringify(""));
          localStorage.removeItem("userLogin");
          localStorage.removeItem("userLoginTime");
          localStorage.clear();
          this.props.context.setUserDetails("");
          this.props.context.setUser("");
          this.props.context.setUserLogin(false);
          window.location.replace("/login");
        })
        .catch((error) => {
          console.log("error signing out: ", error);
          window.location.replace("/login");
        });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  setLoading = () => {
    this.props.context.setLoading(true);
  };

  setFilterSearchEmpty = () => {
    const filterdata = [];
    this.props.context.setFilterData(filterdata);
    this.props.context.setAgentFilterSearch(filterdata);
    localStorage.removeItem("selectedCities");
    localStorage.removeItem("filterSearchData");
    this.props.context.handleToSetCurrentPage(1, 10);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleMyProfile = () => {
    const { userDetails } = this.state;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (window.location.href === this.props.context?.state?.page_pathname) {
      window.location.assign(`/viewProfile/${userDetails.id}`);
    }
  };

  handleCancel = () => {
    this.setState({
      loading: false,
      showVendorConfirmation: false,
    });
  };

  changeProfile = async () => {
    this.props.context.openupdateLoading(true);

    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const newUpdatedData = await getDetails("users", userDetails.id);
    if (newUpdatedData && newUpdatedData.data && newUpdatedData.data.newdata) {
      const newdata = newUpdatedData.data.newdata;
      newdata["zipCodes"] = [];
      newdata["Specialties"] = [];
      const data = {
        entityData: {
          userProfile: "V",
          newdata: newdata,
        },
      };
      this.updateProfileData(data, userDetails.id);
    } else {
      const data = {
        entityData: {
          userProfile: "V",
        },
      };
      this.updateProfileData(data, userDetails.id);
    }
  };

  updateProfileData = async (data, userId) => {
    const updatingUserDetails = await update("users", userId, data);
    if (updatingUserDetails.success) {
      this.getUserProfile(userId);
      notification["success"]({
        message: "You have successfully changed your profile. Thank you.",
      });
    } else {
      this.getUserProfile(userId);
      notification["error"]({
        message: "Somethig Went Wrong",
        description: "Please try again",
      });
    }
  };

  // >>>> #SAVE HEADER CUSTOMIZATION CHANGES

  onFinish = async (e) => {
    this.props.context.openupdateLoading(true);
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));

    const { tabSelectedColor, headerbgSelectedColor } = this.state;
    const { landingScreen, footer, WhyJoinUsPage, aboutUsPage, contactUsPage } =
      this.props.context.state.websiteThemes;

    let contextData = this.props.context.state.websiteThemes;

    let headerData = contextData.Header;
    headerData.tabColor = this.state.tabSelectedColor
      ? tabSelectedColor
      : headerData.tabColor;
    headerData.headerbackground = headerbgSelectedColor
      ? headerbgSelectedColor
      : headerData.headerbackground;

    headerData.headerLogo = this.state.headerLogo
      ? this.state.headerLogo
      : orgDetails.logo;

    orgDetails["logo"] = this.state.headerLogo
      ? this.state.headerLogo
      : orgDetails.logo;

    localStorage.setItem("orgDetails", JSON.stringify(orgDetails));

    let userData = {
      entityData: {
        logo: this.state.headerLogo ? this.state.headerLogo : orgDetails.logo,
      },
    };

    let headerTabs = [
      {
        label: "Homes",
        ActiveStatus: true,
        tabPathname: "/homes",
      },
      {
        label: "Off MLS Homes",
        ActiveStatus: true,
        tabPathname: "/offMLSHomes",
      },
      {
        label: "Buyers",
        ActiveStatus: true,
        tabPathname: "/buyers",
      },
      {
        label: "Agents",
        ActiveStatus: true,
        tabPathname: "/agent",
      },
      {
        label: "Lenders",
        ActiveStatus: true,
        tabPathname: "/lenders",
      },
      {
        label: "Marketplace",
        ActiveStatus: true,
        tabPathname: "/marketplace",
      },
      {
        label: "About Us",
        ActiveStatus: true,
        tabPathname: "/about",
      },
      {
        label: "Why Join Us",
        ActiveStatus: true,
        tabPathname: "/whyJoinUs",
      },
      {
        label: "Contact Us",
        ActiveStatus: true,
        tabPathname: "/contactUs",
      },
    ];
    if ("headerTabs" in headerData === false) {
      headerData["headerTabs"] = headerTabs;
    }

    let data = {
      entityData: {
        Header: headerData,
        landingScreen,
        footer,
        WhyJoinUsPage,
        aboutUsPage,
        contactUsPage,
        logo: this.state.headerLogo ? this.state.headerLogo : orgDetails.logo,
      },
    };
    update("users", loginUserProfile?.id, userData);
    if (loginUserProfile && loginUserProfile?.orgId) {
      let websiteId = loginUserProfile?.orgId;
      this.props.context.updateWebsite(websiteId, data);
      this.setState({
        localModalTest: false,
      });
    }
  };

  updateHeaderTabsInfo = () => {
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    const {
      landingScreen,
      footer,
      WhyJoinUsPage,
      aboutUsPage,
      contactUsPage,
      Header,
    } = this.props.context.state.websiteThemes;

    Header["headerTabs"] = this.state.headerTabsDetails;

    let data = {
      entityData: {
        Header,
        landingScreen,
        footer,
        WhyJoinUsPage,
        aboutUsPage,
        contactUsPage,
      },
    };
    let websiteId = loginUserProfile?.orgId;
    this.props.context.updateWebsite(websiteId, data);
    this.setState({
      localModalTest: false,
    });
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
    });
    switch (info.file.status) {
      case "done":
        // this.setState({
        //   fileList: info.fileList,
        // });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                this.setState({
                  headerLogo: result.key,
                  imageLoading: false,
                });
                // this.props.enableSaveButton();
              });
            } else {
              this.setState({
                imageLoading: false,
              });
              // this.props.enableSaveButton();
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  handleCroppedImage = async (crop) => {
    this.setState({
      imageLoading: true,
    });
    Storage.put(crop.uid, crop, {
      contentType: crop.type,
    }).then(async (result) => {
      this.setState({
        headerLogo: result.key,
        imageLoading: false,
      });
    });
  };

  headerMenu = () => {
    const {
      tabSelectedColor,
      headerbgSelectedColor,
      imageLoading,
      // buttonColor,
      // buttonbgColor,
      headerLogo,
    } = this.state;
    const { Header } = this.props.context.state.websiteThemes;

    const tabColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={tabSelectedColor}
          onChange={(e) =>
            this.setState({
              tabSelectedColor: e.color,
            })
          }
        />
      </div>
    );

    const bgColor = (
      <div>
        <ColorPickerPanel
          enableAlpha={false}
          color={headerbgSelectedColor}
          onChange={(e) =>
            this.setState({
              headerbgSelectedColor: e.color,
            })
          }
        />
      </div>
    );

    // const ButtonColor = (
    //   <div>
    //     <ColorPickerPanel
    //       enableAlpha={false}
    //       color={buttonColor}
    //       onChange={(e) =>
    //         this.setState({
    //           buttonColor: e.color,
    //         })
    //       }
    //     />
    //   </div>
    // );

    // const ButtonbgColor = (
    //   <div>
    //     <ColorPickerPanel
    //       enableAlpha={false}
    //       color={buttonbgColor}
    //       onChange={(e) =>
    //         this.setState({
    //           buttonbgColor: e.color,
    //         })
    //       }
    //     />
    //   </div>
    // );
    return (
      <div>
        <Row style={{ paddingLeft: "1vw" }}>
          <Col span={24} style={{ marginTop: "1vw" }}>
            <ModalTitle>Header Logo</ModalTitle>
          </Col>
          <Col span={24}>
            <Form.Item
              name="headerLogo"
              rules={[
                {
                  required: true,
                  message: "Please input your Header Logo!",
                },
              ]}
            >
              <Spin
                spinning={imageLoading}
                size="large"
                className="center-item"
                style={{ marginTop: "10%" }}
              >
                <Row>
                  {headerLogo ? (
                    <img
                      src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${headerLogo}`}
                      alt="avatar"
                      style={{ width: "100px", height: "100%" }}
                    />
                  ) : (
                    Header?.headerLogo && (
                      <img
                        src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${Header?.headerLogo}`}
                        alt="avatar"
                        style={{ width: "100px", height: "100%" }}
                      />
                    )
                  )}
                </Row>
                <Row>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100px",
                      padding: "0px",
                      marginTop: "10px",
                      height: "30px",
                    }}
                  >
                    <ImageCrop
                      aspect={3.3 / 1.3}
                      rotate={true}
                      onModalOk={this.handleCroppedImage}
                      modalOk="Save"
                      fillColor="transparent"
                    >
                      <Upload showUploadList={false}>
                        <span className="Btn-name">Update Logo</span>
                      </Upload>
                    </ImageCrop>
                  </Button>
                </Row>
              </Spin>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: "1vw", paddingLeft: "1vw" }}>
          <Col span={24} style={{ marginTop: "1vw" }}>
            <ModalTitle>Text Color</ModalTitle>
          </Col>
          <Col span={24}>
            <Input
              value={tabSelectedColor || Header.tabColor}
              onChange={(e) =>
                this.setState({
                  tabSelectedColor: e.target.value,
                })
              }
              suffix={
                <Dropdown trigger={["click"]} overlay={tabColor}>
                  <Button
                    style={{
                      background: tabSelectedColor || Header.tabColor,
                    }}
                  >
                    {" "}
                  </Button>
                </Dropdown>
              }
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "1vw", paddingLeft: "1vw" }}>
          <Col span={24}>
            <ModalTitle>Header Background Color</ModalTitle>
          </Col>

          <Col span={24}>
            <Input
              value={headerbgSelectedColor || Header.headerbackground}
              onChange={(e) =>
                this.setState({
                  headerbgSelectedColor: e.target.value,
                })
              }
              suffix={
                <Dropdown trigger={["click"]} overlay={bgColor}>
                  <Button
                    style={{
                      background:
                        headerbgSelectedColor || Header.headerbackground,
                    }}
                  >
                    {" "}
                  </Button>
                </Dropdown>
              }
            />
          </Col>
        </Row>

        {/* {!userLogin && (
          <>
            <Row>
              <Col span={24}>
                <ModalTitle>Button Text</ModalTitle>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="buttonText"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Button Text!",
                    },
                  ]}
                >
                  <Input
                    name="title"
                    placeholder="Start Typing"
                    className="form-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1vw" }}>
              <Col span={24}>
                <ModalTitle>Button Color</ModalTitle>
              </Col>
              <Col span={24}>
                <Input
                  value={buttonColor || ""}
                  onChange={(e) =>
                    this.setState({
                      buttonColor: e.target.value,
                    })
                  }
                  suffix={
                    <Dropdown trigger={["click"]} overlay={ButtonColor}>
                      <Button style={{ background: buttonColor }}> </Button>
                    </Dropdown>
                  }
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "1vw" }}>
              <Col span={24}>
                <ModalTitle>Button Background Color</ModalTitle>
              </Col>
              <Col span={24}>
                <Input
                  value={buttonbgColor || ""}
                  onChange={(e) =>
                    this.setState({
                      buttonbgColor: e.target.value,
                    })
                  }
                  suffix={
                    <Dropdown trigger={["click"]} overlay={ButtonbgColor}>
                      <Button style={{ background: buttonbgColor }}> </Button>
                    </Dropdown>
                  }
                />
              </Col>
            </Row>
          </>
        )} */}
        <Row style={{ textAlign: "right" }}>
          <Col span={5} offset={13}>
            <Button
              key="back"
              onClick={() =>
                this.setState(
                  { headerLogo: "" },
                  this.props.context.openShowEditModal(false)
                )
              }
              style={{ width: "100%", fontWeight: "500" }}
            >
              Cancel
            </Button>
          </Col>
          <Col span={5} offset={1}>
            <Button
              key="submit"
              type="primary"
              loading={this.props.context.state.updateLoading}
              style={{ width: "100%", fontWeight: "500" }}
              onClick={this.onFinish}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  handleTabActivation = (e, tab) => {
    const { Header } = this.props.context.state.websiteThemes;
    let headerTabsDetails =
      Header?.headerTabs?.length > 0 ? Header.headerTabs : [];

    headerTabsDetails.map((f) => {
      if (f.label === tab) {
        f["ActiveStatus"] = e.target.checked;
      }
      return true;
    });
    this.setState({
      headerTabsDetails,
    });
  };

  handleEditMenuText = (e, oldTab) => {
    console.log("lll", e, oldTab);
    const { Header } = this.props.context.state.websiteThemes;
    let headerTabsDetails =
      Header?.headerTabs?.length > 0 ? Header.headerTabs : [];

    headerTabsDetails.map((f) => {
      if (f.label === oldTab) {
        f["label"] = e;
      }
      return true;
    });
    this.setState({
      headerTabsDetails,
    });
  };

  handleHeaderMenuTabs = () => {
    const { headerTabsDetails } = this.state;
    return (
      <>
        <Row style={{ paddingLeft: "1vw" }}>
          {headerTabsDetails &&
            headerTabsDetails.length > 0 &&
            headerTabsDetails.map((eachMenu) => (
              <Col span={12} style={{ display: "flex" }}>
                <Checkbox
                  checked={eachMenu.ActiveStatus}
                  onChange={(e) => this.handleTabActivation(e, eachMenu.label)}
                />

                <Paragraph
                  editable={{
                    onChange: (e) => this.handleEditMenuText(e, eachMenu.label),
                  }}
                  style={{ fontSize: "18px", marginLeft: "1vw" }}
                >
                  {eachMenu.label}
                </Paragraph>
              </Col>
            ))}
        </Row>
        <Row style={{ textAlign: "right" }}>
          <Col span={5} offset={13}>
            <Button
              key="back"
              onClick={() => this.props.context.openShowEditModal(false)}
              style={{ width: "100%", fontWeight: "500" }}
            >
              Cancel
            </Button>
          </Col>
          <Col span={5} offset={1}>
            <Button
              key="submit"
              type="primary"
              loading={this.props.context.state.updateLoading}
              style={{ width: "100%", fontWeight: "500" }}
              onClick={this.updateHeaderTabsInfo}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  handleHeaderInfo = (header) => {
    this.setState({
      headerTabsDetails: header.headerTabs,
    });
  };

  render() {
    const {
      userDetails,
      showVendorConfirmation,
      localModalTest,
      tokenGenerated,
      headerLogo,
    } = this.state;

    const { data1, authenticate, count, currentPage } = this.props;
    let userLogin = localStorage.getItem("userLogin");
    const { Header } = this.props.context.state.websiteThemes;
    const { showEditModal, updateLoading } = this.props.context.state;
    let header = "";
    header = Header;
    const loginUserProfile = JSON.parse(localStorage.getItem("userDetails"));
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    const profileBasedURL = JSON.parse(localStorage.getItem("profileBasedURL"));
    // let header?.headerLogo = "";
    // header?.headerLogo = orgDetails && header.headerLogo;

    let fbUserProfile = JSON.parse(localStorage.getItem("fbUserProfilePic"));

    return (
      <>
        <Row>{profileBasedURL && orgDetails && <CreateUserName />}</Row>
        {header && (
          <div className="header">
            <div
              className="header-sidebar"
              style={{ background: header.headerbackground }}
            >
              <header class="header navbar-fixed-top">
                <nav class="navbar" role="navigation">
                  {userLogin && userLogin ? (
                    <Row justify="space-between">
                      <Col span={12}>
                        <Link to="/">
                          {header?.headerLogo ? (
                            <img
                              src={`https://${
                                process.env.REACT_APP_S3_BUCKET
                              }.s3.amazonaws.com/public/${
                                headerLogo !== ""
                                  ? headerLogo
                                  : header?.headerLogo
                              }`}
                              alt="logo"
                              className="logo-header"
                            />
                          ) : (
                            <img
                              src={logo1}
                              alt="logo"
                              className="logo-header"
                            />
                          )}
                        </Link>
                      </Col>
                      <Col span={12} style={{ padding: "10px" }}>
                        <Row justify="end" style={{ marginTop: "-10px" }}>
                          <Col className="notify-col">
                            <Link
                              to={`/favourties/${userDetails.id}`}
                              onClick={this.handleLink}
                              className="heart-col"
                              style={{ margin: "0px" }}
                            >
                              {/* <a href="#" className="head-example" /> */}
                              <HeartFilled
                                className="heart-header"
                                style={{ color: header.tabColor }}
                              />
                            </Link>
                          </Col>
                          <Col className="notify-col">
                            {userDetails && userDetails.id && (
                              <Notifications
                                userId={userDetails.id}
                                color={header.tabColor}
                              />
                            )}
                          </Col>
                          <Col className="profile-height">
                            <Popover
                              placement="bottomRight"
                              content={
                                <div style={{ width: "200px" }}>
                                  <Meta
                                    avatar={
                                      <Avatar
                                        src={
                                          fbUserProfile !== null ? (
                                            fbUserProfile
                                          ) : this.props.context.state
                                              .userDetails &&
                                            this.props.context.state.userDetails
                                              .profilePicture ? (
                                            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                            this.props.context.state.userDetails
                                              .profilePicture[0]
                                          ) : (
                                            <Button
                                              shape="circle"
                                              size="big"
                                              block={true}
                                              style={{
                                                backgroundColor: "#f9faff",
                                              }}
                                            >
                                              {userDetails.firstName &&
                                                userDetails.firstName
                                                  .charAt(0)
                                                  .toUpperCase()}
                                            </Button>
                                          )
                                        }
                                      />
                                    }
                                    title={
                                      userDetails.firstName
                                        ? userDetails.firstName +
                                          " " +
                                          userDetails.lastName
                                        : userDetails.fullName
                                    }
                                    description={
                                      userDetails.userProfile === "B"
                                        ? "Buyer"
                                        : userDetails.userProfile === "S"
                                        ? "Seller"
                                        : userDetails.userProfile === "AG"
                                        ? "Agent"
                                        : userDetails.userProfile === "V"
                                        ? "vendor"
                                        : userDetails.userProfile === "L"
                                        ? "Lender"
                                        : userDetails.userProfile === "A"
                                        ? "Admin"
                                        : userDetails.userProfile ===
                                            "concierge" && "Concierge"
                                    }
                                  />
                                  <Divider
                                    style={{
                                      marginBottom: "1vw",
                                      marginTop: "1vw",
                                    }}
                                  />
                                  <p>
                                    <Link
                                      to={{
                                        pathname: `/viewProfile/${userDetails.id}`,
                                        state:
                                          userDetails.userProfile === "AG"
                                            ? "Agents"
                                            : userDetails.userProfile === "V"
                                            ? "Vendor"
                                            : userDetails.userProfile === "L"
                                            ? "Lender"
                                            : userDetails.userProfile === "B"
                                            ? "Buyer"
                                            : "Seller",
                                      }}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                        marginLeft: "1vw",
                                      }}
                                      onClick={this.handleMyProfile}
                                    >
                                      My Profile
                                    </Link>
                                  </p>
                                  <p>
                                    <Link
                                      to={{
                                        pathname: `/login`,
                                        state:
                                          userDetails.userProfile === "AG"
                                            ? "Agents"
                                            : userDetails.userProfile === "V"
                                            ? "Vendor"
                                            : userDetails.userProfile === "L"
                                            ? "Lender"
                                            : userDetails.userProfile === "B"
                                            ? "Buyer"
                                            : "Seller",
                                      }}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                        marginLeft: "1vw",
                                      }}
                                    >
                                      Edit Login page
                                    </Link>
                                  </p>

                                  {userDetails.userProfile === "B" ||
                                  userDetails.userProfile === "S" ? (
                                    <Row>
                                      <p
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                          marginLeft: "1vw",
                                        }}
                                      >
                                        Enable Vendor
                                      </p>

                                      <div style={{ marginLeft: "5px" }}>
                                        <Switch
                                          checked={showVendorConfirmation}
                                          checkedChildren="Yes"
                                          unCheckedChildren="No"
                                          onClick={() => {
                                            this.setState({
                                              showVendorConfirmation:
                                                !showVendorConfirmation,
                                            });
                                          }}
                                        />
                                      </div>
                                    </Row>
                                  ) : userDetails.userProfile === "L" ||
                                    userDetails.userProfile === "V" ? (
                                    <p>
                                      <Link
                                        to={{
                                          pathname: `/sellerBuyer/viewProfile/${userDetails.id}`,
                                          state: {
                                            userDetails: userDetails,
                                            data: data1,
                                            authenticate: authenticate,
                                            currentPage: currentPage,
                                            count: count,
                                          },
                                        }}
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                          marginLeft: "1vw",
                                        }}
                                        onClick={this.handleLink}
                                      >
                                        Seller/Buyer Profile
                                      </Link>
                                    </p>
                                  ) : (
                                    <p></p>
                                  )}

                                  <p>
                                    <Link
                                      to={{
                                        pathname: `/myProfile/${
                                          userDetails && userDetails.id
                                        }`,
                                        state: {
                                          userDetails: userDetails,
                                          data: data1,
                                          authenticate: authenticate,
                                          currentPage: currentPage,
                                          count: count,
                                        },
                                      }}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                        marginLeft: "1vw",
                                      }}
                                      onClick={this.handleLink}
                                    >
                                      Profile Settings
                                    </Link>
                                  </p>
                                  {userDetails.userProfile === "A" && (
                                    <Row
                                      style={{
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Col>
                                        <img
                                          src={iconAdmin}
                                          alt="adminIcon"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "10px",
                                          }}
                                        />
                                      </Col>
                                      <Col>
                                        <a
                                          href={`https://${orgDetails?.adminWebsite}/login?jwtCode=${tokenGenerated}`}
                                          target="_blank"
                                          style={{
                                            textDecoration: "none",
                                            color: "black",
                                            fontSize: "16px",
                                          }}
                                          rel="noopener noreferrer"
                                        >
                                          Admin Panel
                                        </a>
                                      </Col>
                                    </Row>
                                  )}

                                  {userDetails &&
                                    (userDetails.userProfile === "AG" ||
                                      userDetails.userProfile === "A") && (
                                      <p>
                                        <Link
                                          to={{
                                            pathname: `/VirtualOffice/Dashboard`,
                                          }}
                                          style={{
                                            textDecoration: "none",
                                            color: "black",
                                            marginLeft: "1vw",
                                          }}
                                          onClick={this.handleLink}
                                        >
                                          Virtual Office
                                        </Link>
                                      </p>
                                    )}
                                  <Divider
                                    style={{
                                      marginBottom: "1vw",
                                      marginTop: "1vw",
                                    }}
                                  />

                                  <Row
                                    onClick={this.handleLogout}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Col>
                                      <LogoutOutlined
                                        style={{
                                          color: "red",
                                          fontSize: "18px",
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      style={{
                                        color: "red",
                                        fontSize: "16px",
                                        marginLeft: "10px",
                                        marginTop: "-5px",
                                      }}
                                    >
                                      Logout
                                    </Col>
                                  </Row>
                                </div>
                              }
                              title={null}
                              trigger="hover"
                            >
                              {userDetails &&
                              userDetails.userProfile === "AG" ? (
                                <Link
                                  to={{
                                    pathname: `/viewProfile/${
                                      userDetails && userDetails.id
                                    }`,
                                    state: {
                                      userDetails: userDetails,
                                      data: data1,
                                      authenticate: authenticate,
                                      currentPage: currentPage,
                                      count: count,
                                    },
                                  }}
                                  style={{ textDecoration: "none" }}
                                  onClick={this.handleLink}
                                >
                                  <Avatar
                                    className="avatar-img-header"
                                    src={
                                      fbUserProfile !== null ? (
                                        fbUserProfile
                                      ) : this.props.context.state
                                          .userDetails &&
                                        this.props.context.state.userDetails
                                          .profilePicture ? (
                                        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                        this.props.context.state.userDetails
                                          .profilePicture[0]
                                      ) : (
                                        <Button
                                          shape="circle"
                                          size="big"
                                          block={true}
                                          style={{ backgroundColor: "#f9faff" }}
                                        >
                                          {userDetails.firstName &&
                                            userDetails.firstName
                                              .charAt(0)
                                              .toUpperCase()}
                                        </Button>
                                      )
                                    }
                                  />
                                </Link>
                              ) : (
                                <Link
                                  to={{
                                    pathname: `/myProfile/${
                                      userDetails && userDetails.id
                                    }`,
                                    state: {
                                      userDetails: userDetails,
                                      data: data1,
                                      authenticate: authenticate,
                                      currentPage: currentPage,
                                      count: count,
                                    },
                                  }}
                                  style={{ textDecoration: "none" }}
                                >
                                  <Avatar
                                    className="avatar-img-header"
                                    src={
                                      fbUserProfile !== null ? (
                                        fbUserProfile
                                      ) : this.props.context.state
                                          .userDetails &&
                                        this.props.context.state.userDetails
                                          .profilePicture ? (
                                        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                        this.props.context.state.userDetails
                                          .profilePicture[0]
                                      ) : (
                                        <Button
                                          shape="circle"
                                          size="big"
                                          block={true}
                                          style={{ backgroundColor: "#f9faff" }}
                                        >
                                          {userDetails.firstName &&
                                            userDetails.firstName
                                              .charAt(0)
                                              .toUpperCase()}
                                        </Button>
                                      )
                                    }
                                  />
                                </Link>
                              )}
                            </Popover>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row justify="space-between">
                      <Col span={12}>
                        <Link to="/">
                          {header?.headerLogo ? (
                            <img
                              src={
                                `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                header?.headerLogo
                              }
                              alt="logo"
                              className="logo-header"
                            />
                          ) : (
                            <img
                              src={logo1}
                              alt="logo"
                              className="logo-header"
                            />
                          )}
                        </Link>
                      </Col>
                      <Col className="notify-col">
                        <Link
                          to="/login"
                          onClick={this.handleLink}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            <LoginOutlined
                              style={{
                                fontSize: "18px",
                                color: header.tabColor,
                              }}
                            />{" "}
                            <span
                              style={{
                                fontSize: "18px",
                                color: header.tabColor,
                              }}
                            >
                              Login
                            </span>
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  )}
                </nav>
              </header>
            </div>
            <Row
              style={{
                background: header.headerbackground,
              }}
            >
              <Col
                xxl={{
                  span: userLogin && userLogin ? 3 : 3,
                  offset: userLogin && userLogin ? 0 : 0,
                }}
                xl={{
                  span: userLogin && userLogin ? 3 : 3,
                  offset: 0,
                }}
                lg={{
                  span: userLogin && userLogin ? 2 : 3,
                  offset: 0,
                }}
                md={{
                  span: userLogin && userLogin ? 3 : 4,
                  offset: 0,
                }}
                sm={{ span: 3, offset: 0 }}
                xs={{ span: 3, offset: 0 }}
                className="header-logo-class"
              >
                {userLogin && userLogin ? (
                  <Link
                    to="/"
                    onClick={this.handleLink}
                    className="img_response"
                  >
                    {header?.headerLogo ? (
                      <img
                        src={`https://${
                          process.env.REACT_APP_S3_BUCKET
                        }.s3.amazonaws.com/public/${
                          headerLogo !== "" ? headerLogo : header.headerLogo
                        }`}
                        alt="logo"
                        className="logo-header"
                      />
                    ) : (
                      <img
                        src={logo1}
                        alt="logo"
                        className="logo-header"
                        // style={{
                        //   height: window.screen.width > 1280 && "130px",
                        // }}
                      />
                    )}
                  </Link>
                ) : (
                  <Link to="/" onClick={this.handleLink}>
                    {header?.headerLogo ? (
                      <img
                        src={
                          `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                          header?.headerLogo
                        }
                        alt="logo"
                        className="logo-header"
                        // style={{
                        //   height: window.screen.width > 1280 && "130px",
                        // }}
                      />
                    ) : (
                      <img
                        src={logo1}
                        alt="logo"
                        className="logo-header"
                        // style={{
                        //   height: window.screen.width > 1280 && "130px",
                        // }}
                      />
                    )}
                  </Link>
                )}
              </Col>

              <Col
                xxl={{ span: userLogin && userLogin ? 15 : 16 }}
                xl={{
                  span: userLogin && userLogin ? 15 : 16,
                  offset: userLogin && userLogin ? 0 : 1,
                }}
                lg={{
                  span: userLogin && userLogin ? 16 : 16,
                  offset: userLogin && userLogin ? 0 : 1,
                }}
                md={{ span: 11 }}
                sm={{ span: 13 }}
                xs={{ span: 13 }}
                // className="items_Center_show"
                className="display-flex-end"
                style={{ marginTop: "2vw", marginBottom: "2vw" }}
              >
                {header.headerTabs?.length > 0 &&
                  header.headerTabs.map((eachTab) => (
                    <>
                      {eachTab.ActiveStatus && profileBasedURL
                        ? eachTab.label !== "Agents" && (
                            <Col>
                              <Link to={`${eachTab.tabPathname}`}>
                                <span
                                  className="header-menu-titles"
                                  style={{
                                    color: header.tabColor,
                                  }}
                                  onClick={this.setFilterSearchEmpty}
                                >
                                  {eachTab.label}
                                </span>
                              </Link>
                            </Col>
                          )
                        : eachTab.ActiveStatus && (
                            <Col>
                              <Link to={`${eachTab.tabPathname}`}>
                                <span
                                  className="header-menu-titles"
                                  style={{
                                    color: header.tabColor,
                                  }}
                                  onClick={this.setFilterSearchEmpty}
                                >
                                  {eachTab.label}
                                </span>
                              </Link>
                            </Col>
                          )}
                    </>
                  ))}
                {"headerTabs" in header === false && (
                  <>
                    <Col>
                      <Link to="/homes">
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                            // window.location.pathname === "/offMLSHomes"
                            //   ? "#fd7e14"
                            //   : userLogin
                            //   ? "#FFF"
                            //   : "black",
                          }}
                          onClick={this.setFilterSearchEmpty}
                        >
                          Homes
                        </span>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/offMLSHomes">
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                            // window.location.pathname === "/offMLSHomes"
                            //   ? "#fd7e14"
                            //   : userLogin
                            //   ? "#FFF"
                            //   : "black",
                          }}
                          onClick={this.setFilterSearchEmpty}
                        >
                          Off MLS Homes
                        </span>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/buyers">
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                          }}
                          onClick={this.setFilterSearchEmpty}
                        >
                          Buyers
                        </span>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/agent">
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                          }}
                          onClick={() => {
                            this.setFilterSearchEmpty();
                            this.handleLink();
                          }}
                        >
                          Agents
                        </span>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/lenders">
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                          }}
                          onClick={() => {
                            this.setFilterSearchEmpty();
                            this.handleLink();
                          }}
                        >
                          Lenders
                        </span>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/marketplace">
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                          }}
                          onClick={() => {
                            this.setFilterSearchEmpty();
                            this.handleLink();
                          }}
                        >
                          Marketplace
                        </span>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/about">
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                          }}
                          onClick={() => {
                            this.setFilterSearchEmpty();
                            this.handleLink();
                          }}
                        >
                          About Us
                        </span>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/whyJoinUs" onClick={this.handleclick}>
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                          }}
                          onClick={() => {
                            this.setFilterSearchEmpty();
                            this.handleLink();
                          }}
                        >
                          Why Join Us
                        </span>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/contactUs" onClick={this.handleclick}>
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                          }}
                          onClick={() => {
                            this.setFilterSearchEmpty();
                            this.handleLink();
                          }}
                        >
                          Contact Us
                        </span>
                      </Link>
                    </Col>
                  </>
                )}
              </Col>

              <Col
                className="favourties-header"
                xxl={{
                  span: userLogin && userLogin ? 6 : 4,
                }}
                xl={{
                  span: userLogin && userLogin ? 6 : 4,
                }}
                lg={{
                  span: userLogin && userLogin ? 6 : 4,
                }}
                md={{ span: 8 }}
                sm={{ span: 8 }}
                xs={{ span: 8 }}
              >
                {userLogin && userLogin ? (
                  <Row className="header-row">
                    <Col span={4} className="header-margin-desk">
                      <Link
                        to={`/favourties/${userDetails.id}`}
                        onClick={this.handleLink}
                      >
                        {/* <a href="#" className="head-example" /> */}
                        <HeartFilled
                          className="heart-header"
                          style={{ color: header.tabColor }}
                        />
                      </Link>
                    </Col>

                    <Col span={4} className="header-margin-desk">
                      {userDetails && userDetails.id && (
                        <Notifications
                          userId={userDetails.id}
                          color={header.tabColor}
                        />
                      )}
                    </Col>
                    <Col
                      span={16}
                      style={{
                        width: "100%",
                        marginTop: "1.9vw",
                        color: header.tabColor,
                        background: header.backgroundColor,
                      }}
                      className="header-profile font-family-style color-white"
                    >
                      <Popover
                        placement="bottom"
                        content={
                          <div style={{ width: "200px" }}>
                            <Row
                              style={{
                                cursor: "pointer",
                                marginBottom: "10px",
                              }}
                            >
                              <Col>
                                <UserOutlined
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "10px",
                                  }}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to={{
                                    pathname: `/viewProfile/${userDetails.id}`,
                                    state:
                                      userDetails.userProfile === "AG"
                                        ? "Agents"
                                        : userDetails.userProfile === "V"
                                        ? "Vendor"
                                        : userDetails.userProfile === "L"
                                        ? "Lender"
                                        : userDetails.userProfile === "B"
                                        ? "Buyer"
                                        : "Seller",
                                  }}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    fontSize: "16px",
                                  }}
                                  onClick={this.handleMyProfile}
                                >
                                  My Profile
                                </Link>
                              </Col>
                            </Row>
                            {userDetails.userProfile === "A" && (
                              <Row
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "10px",
                                }}
                              >
                                <Col>
                                  <UserOutlined
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  />
                                </Col>

                                <Col>
                                  <Link
                                    to={{
                                      pathname: `/login`,
                                      state:
                                        userDetails.userProfile === "AG"
                                          ? "Agents"
                                          : userDetails.userProfile === "V"
                                          ? "Vendor"
                                          : userDetails.userProfile === "L"
                                          ? "Lender"
                                          : userDetails.userProfile === "B"
                                          ? "Buyer"
                                          : "Seller",
                                    }}
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      marginLeft: "10px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Edit Login page
                                  </Link>
                                </Col>
                              </Row>
                            )}
                            {userDetails.userProfile === "B" ||
                            userDetails.userProfile === "S" ? (
                              <Row style={{ cursor: "pointer" }}>
                                <Col>
                                  <ShopOutlined
                                    style={{
                                      fontSize: "14px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <p
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Enable Vendor
                                  </p>
                                </Col>
                                <Col>
                                  <div style={{ marginLeft: "5px" }}>
                                    <Switch
                                      checked={showVendorConfirmation}
                                      checkedChildren="Yes"
                                      unCheckedChildren="No"
                                      onClick={() => {
                                        this.setState({
                                          showVendorConfirmation:
                                            !showVendorConfirmation,
                                        });
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : userDetails.userProfile === "L" ||
                              userDetails.userProfile === "V" ? (
                              <Row
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "10px",
                                }}
                              >
                                <Col>
                                  <ShopOutlined
                                    style={{
                                      fontSize: "14px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to={{
                                      pathname: `/sellerBuyer/viewProfile/${userDetails.id}`,
                                      state: {
                                        userDetails: userDetails,
                                        data: data1,
                                        authenticate: authenticate,
                                        currentPage: currentPage,
                                        count: count,
                                      },
                                    }}
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      fontSize: "16px",
                                    }}
                                    onClick={this.handleLink}
                                  >
                                    Seller/Buyer Profile
                                  </Link>
                                </Col>
                              </Row>
                            ) : (
                              <p></p>
                            )}

                            <Row
                              style={{
                                cursor: "pointer",
                                marginBottom: "10px",
                              }}
                            >
                              <Col>
                                <SettingOutlined
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "10px",
                                  }}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to={{
                                    pathname: `/myProfile/${
                                      userDetails && userDetails.id
                                    }`,
                                    state: {
                                      userDetails: userDetails,
                                      data: data1,
                                      authenticate: authenticate,
                                      currentPage: currentPage,
                                      count: count,
                                    },
                                  }}
                                  onClick={this.handleLink}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    fontSize: "16px",
                                  }}
                                >
                                  Profile Settings
                                </Link>
                              </Col>
                            </Row>
                            {userDetails.userProfile === "A" && (
                              <Row
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "10px",
                                }}
                              >
                                <Col>
                                  <img
                                    src={iconAdmin}
                                    alt="adminIcon"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <a
                                    href={`https://${orgDetails?.adminWebsite}/login?jwtCode=${tokenGenerated}`}
                                    target="_blank"
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      fontSize: "16px",
                                    }}
                                    rel="noopener noreferrer"
                                  >
                                    Admin Panel
                                  </a>
                                </Col>
                              </Row>
                            )}
                            {userDetails &&
                              (userDetails.userProfile === "AG" ||
                                userDetails.userProfile === "A" ||
                                userDetails.userProfile === "concierge") && (
                                <Row
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Col>
                                    <ShopOutlined
                                      style={{
                                        fontSize: "14px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to={{
                                        pathname: `/VirtualOffice/Dashboard`,
                                      }}
                                      onClick={this.handleLink}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                    >
                                      Virtual Office
                                    </Link>
                                  </Col>
                                </Row>
                              )}

                            <Row
                              onClick={this.handleLogout}
                              style={{ cursor: "pointer" }}
                            >
                              <Col>
                                <LogoutOutlined
                                  style={{
                                    color: "red",
                                    fontSize: "16px",
                                  }}
                                />
                              </Col>
                              <Col
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                  marginLeft: "10px",
                                  marginTop: "-5px",
                                }}
                              >
                                Logout
                              </Col>
                            </Row>
                          </div>
                        }
                        title={null}
                        trigger="hover"
                      >
                        {userDetails && userDetails.userProfile === "AG" ? (
                          <Link
                            to={{
                              pathname: `/viewProfile/${
                                userDetails && userDetails.id
                              }`,
                              state: {
                                userDetails: userDetails,
                                data: data1,
                                authenticate: authenticate,
                                currentPage: currentPage,
                                count: count,
                              },
                            }}
                            style={{ textDecoration: "none" }}
                            onClick={this.handleLink}
                          >
                            <Meta
                              avatar={
                                <Avatar
                                  src={
                                    fbUserProfile !== null ? (
                                      fbUserProfile
                                    ) : this.props.context.state.userDetails &&
                                      this.props.context.state.userDetails
                                        .profilePicture ? (
                                      `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                      this.props.context.state.userDetails
                                        .profilePicture[0]
                                    ) : (
                                      <Button
                                        shape="circle"
                                        size="big"
                                        block={true}
                                        className="header-img-btn"
                                      >
                                        {userDetails.firstName &&
                                          userDetails.firstName
                                            .charAt(0)
                                            .toUpperCase()}
                                      </Button>
                                    )
                                  }
                                />
                              }
                              title={
                                userDetails.firstName
                                  ? userDetails.firstName +
                                    " " +
                                    userDetails.lastName
                                  : userDetails.fullName
                              }
                              description={
                                userDetails.userProfile === "B"
                                  ? "Buyer"
                                  : userDetails.userProfile === "S"
                                  ? "Seller"
                                  : userDetails.userProfile === "AG"
                                  ? "Agent"
                                  : userDetails.userProfile === "V"
                                  ? "vendor"
                                  : userDetails.userProfile === "L"
                                  ? "Lender"
                                  : userDetails.userProfile === "A"
                                  ? "Admin"
                                  : userDetails.userProfile === "concierge" &&
                                    "concierge"
                              }
                            />
                          </Link>
                        ) : (
                          <Link
                            to={{
                              pathname: `/myProfile/${
                                userDetails && userDetails.id
                              }`,
                              state: {
                                userDetails: userDetails,
                                data: data1,
                                authenticate: authenticate,
                                currentPage: currentPage,
                                count: count,
                              },
                            }}
                            onClick={this.handleLink}
                            style={{ textDecoration: "none" }}
                          >
                            <Meta
                              avatar={
                                <Avatar
                                  src={
                                    fbUserProfile !== null ? (
                                      fbUserProfile
                                    ) : this.props.context.state.userDetails &&
                                      this.props.context.state.userDetails
                                        .profilePicture ? (
                                      `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                      this.props.context.state.userDetails
                                        .profilePicture[0]
                                    ) : (
                                      <Button
                                        shape="circle"
                                        size="big"
                                        block={true}
                                        className="header-img-btn"
                                      >
                                        {userDetails.firstName &&
                                          userDetails.firstName
                                            .charAt(0)
                                            .toUpperCase()}
                                      </Button>
                                    )
                                  }
                                />
                              }
                              title={
                                userDetails?.firstName
                                  ? userDetails?.firstName +
                                    " " +
                                    userDetails?.lastName
                                  : userDetails?.fullName
                              }
                              description={
                                userDetails.userProfile === "B"
                                  ? "Buyer"
                                  : userDetails.userProfile === "S"
                                  ? "Seller"
                                  : userDetails.userProfile === "AG"
                                  ? "Agent"
                                  : userDetails.userProfile === "V"
                                  ? "vendor"
                                  : userDetails.userProfile === "L"
                                  ? "Lender"
                                  : userDetails.userProfile === "A"
                                  ? "Admin"
                                  : userDetails.userProfile === "concierge" &&
                                    "concierge"
                              }
                            />
                          </Link>
                        )}
                      </Popover>
                      {loginUserProfile &&
                        loginUserProfile?.userProfile === "A" && (
                          <Col>
                            <SettingOutlined
                              className="setting_icon"
                              title="Update"
                              style={{ float: "right", color: "white" }}
                              onClick={() =>
                                this.setState(
                                  {
                                    localModalTest: true,
                                    headerTabsDetails: header.headerTabs,
                                  },
                                  this.props.context.openShowEditModal(true)
                                )
                              }
                            />
                          </Col>
                        )}
                    </Col>
                  </Row>
                ) : (
                  <Row className="header-margin-desk">
                    <Col span={8}>
                      <Link
                        to="/login"
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        <span
                          className="header-menu-titles"
                          style={{
                            color: header.tabColor,
                          }}
                        >
                          Login
                        </span>
                      </Link>
                    </Col>
                    <Col span={14}>
                      <HeaderButton
                        color={header.headerButton}
                        onClick={() => {
                          this.props.history.push("/register");
                        }}
                      >
                        {header.headerButton.buttonText}
                      </HeaderButton>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Modal
              className="modal-black-close"
              visible={showVendorConfirmation}
              title="Vendor profile change request"
              onOk={() => this.changeProfile()}
              onCancel={() =>
                this.setState({
                  showVendorConfirmation: false,
                })
              }
              footer={[
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      showVendorConfirmation: false,
                    })
                  }
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={updateLoading}
                  onClick={() => this.changeProfile()}
                >
                  Proceed
                </Button>,
              ]}
            >
              <p style={{ padding: "10px", textAlign: "justify" }}>
                Vendor profiles are for professionals who own a business that
                provides services in and around real estate purchases, sales,
                and home maintenance/repair. By changing your profile to a
                vendor, your business will be listed on the marketplace page
                (with a basic subscription) where consumers and agents will be
                able to find you and seek out your services. Please confirm to
                proceed.
              </p>
            </Modal>

            <Drawer
              title={"Theme Settings"}
              placement="right"
              width={
                window.screen.width >= "768"
                  ? "40%"
                  : window.screen.width > "501" && window.screen.width <= "767"
                  ? "50%"
                  : "100%"
              }
              mask={true}
              closable={true}
              getContainer={false}
              visible={showEditModal && localModalTest ? true : false}
              onClose={() =>
                this.setState(
                  {
                    localModalTest: false,
                    headerLogo: "",
                  },
                  this.props.context.openShowEditModal(false)
                )
              }
              headerStyle={{ textAlign: "center" }}
              bodyStyle={{ paddingBottom: 80 }}
            >
              <Row>
                <Col span={24}>
                  <Collapse defaultActiveKey={["1"]} accordion>
                    <Panel header="Header" key="1">
                      {this.headerMenu()}
                    </Panel>
                    <Panel header="Header Tabs" key="2">
                      {this.handleHeaderMenuTabs()}
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Drawer>
          </div>
        )}
      </>
    );
  }
}

export default withAppContext(withRouter(HeaderMenu));
