import {
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Button,
  Spin,
  Select,
  Drawer,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import Subscriptions from "../../Subscriptions/Subscriptions";
import { withAppContext } from "../../../store/contextStore";
import {
  update,
  deleteRecord,
  getDetails,
  noAuthlist,
} from "../../../api/Apis";
import RichText from "../../../common/RichText";

class VendorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: {
        cities: [],
        type: "",
      },
      sellerName: "",
      sellerPhone: "",
      sellerAddress: "",
      citySearch: [],
      saveDetailsFalse: false,
      zipCode: "",
      serviceAreas: [],
      spec: 0,
      zip: "",
      Ziplist: [],
      Addmore: false,
      speclist: [],
      zipCount: 0,
      showSubscriptions: false,
      dataLoadingFalse: false,
      richtTextBiofield: "",
      allUsersList: [],
      referedById: "",
      referedByName: "",
      handleZipErrorMsg: false,
      bussinessCity: "",
      bussinessState: "",
      bussinessCompanyZipCode: "",
      companyAddress: "",
      loadTotalData: false,
      oldCategories: [],
      deleteRecordId: [],
      newSpecialtiesAdded: [],
      optionsSelected: [],
      referedAgentName: "",
      enableAdduser: false,
      savednewsAgentName: false,
      addAgentName: "",
      addAgentEmail: "",
      agentsList: [],
      agentTowns: [],
    };
  }

  componentDidMount() {
    this.getAllUsers();
    this.getAllAgents();
    this.getPortfoliosList();
    const { userDetails } = this.props;
    const addAgentDefaultName = JSON.parse(localStorage.getItem("userDetails"));

    this.setState({
      companyAddress:
        userDetails && userDetails.bussinessAdress
          ? userDetails.bussinessAdress
          : "",

      referedAgentName:
        userDetails && userDetails.referedAgentName
          ? userDetails.referedAgentName
          : addAgentDefaultName && addAgentDefaultName.addUserName,
    });

    if (
      userDetails &&
      userDetails.newdata &&
      userDetails.newdata.zipCodes &&
      userDetails.newdata.zipCodes
    ) {
      this.setState({
        Ziplist: userDetails.newdata.zipCodes,
      });
    }
    if (
      userDetails &&
      userDetails.newdata &&
      userDetails.newdata.agentTowns &&
      userDetails.newdata.agentTowns.length > 0
    ) {
      this.setState({
        agentTowns:
          userDetails.data &&
          userDetails.data.newdata &&
          userDetails.data.newdata.agentTowns &&
          userDetails.data.newdata.agentTowns,
      });
    }
  }

  getAllUsers = async () => {
    try {
      const usersList = await noAuthlist("getUsers",{isActive:true});
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
        };
      });
      if (usersList.success) {
        this.setState({
          allUsersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  getAllAgents = async () => {
    try {
      const orgId = JSON.parse(localStorage.getItem("orgId"));
      let query = {
        orgId,
        isActive:true
      };
      const AllagentsList = await noAuthlist("getAgents", query);
      let singleAgent = AllagentsList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
          email: user.email,
        };
      });
      if (AllagentsList.success) {
        this.setState({
          agentsList: singleAgent,
        });
      } else {
        notification["error"]({
          message: AllagentsList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  getPortfoliosList = async (cancelProp) => {
    const { userDetails } = this.props.context.state;
    try {
      const response = await noAuthlist(
        "testimonials?userId=" + userDetails.id + "&dataType=portfolio"
      );
      if (response.success) {
        const oldCategories = [];
        const optionsSelected = [];
        response.data.map((eachPortfolio) =>
          oldCategories.push({
            label: eachPortfolio.portfolioName,
            id: eachPortfolio.id,
          })
        );
        response.data.map((eachPortfolio) =>
          optionsSelected.push(eachPortfolio.portfolioName)
        );
        this.setState({
          oldCategories,
          loadTotalData: true,
          optionsSelected,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
    }
  };

  handleZip = (value) => {
    this.setState({ zip: value.target.value, multiZip: value.target.value });
    if (this.state.Ziplist.includes(value.target.value)) {
      this.setState({
        handleZipErrorMsg: true,
      });
      notification["error"]({
        message:
          "You have already added " +
          value.target.value +
          " zip code. Please add another",
      });
    }
  };

  onReferedBySelect = (value, option) => {
    this.setState({
      referedById: option.value,
      referedByName: option.label,
    });
  };

  onFinish = (e) => {
    this.updateDetails(e);
  };

  handleRichtextBio = (bio) => {
    this.setState({
      richtTextBiofield: bio,
    });
  };

  updateDetails = async (data2) => {
    this.setState({
      saveDetailsFalse: true,
    });

    const { richtTextBiofield } = this.state;
    const { userDetails } = this.props.context.state;
    const id = userDetails.id;
    data2["AboutUs"] = richtTextBiofield ? richtTextBiofield : "";
    data2["bussinessAdress"] =
      this.state.companyAddress && this.state.companyAddress;

    const data = Object.assign(
      {},
      {
        entityData: {
          email: data2.email ? data2.email : "",
          firstName: data2.firstName ? data2.firstName : "",
          lastName: data2.lastName ? data2.lastName : "",
          BusinessName: data2.BusinessName ? data2.BusinessName : "",
          phone: data2.phone ? data2.phone : "",
          AboutUs: data2.AboutUs ? data2.AboutUs : "",
          LicenseTradeIDs: data2.LicenseTradeIDs ? data2.LicenseTradeIDs : "",
          Website: data2.Website ? data2.Website : "",
          bussinessAdress: data2.bussinessAdress ? data2.bussinessAdress : "",
          newdata: {
            zipCodes: this.state.Ziplist ? this.state.Ziplist : "",
            agentTowns: this.state.agentTowns,
          },
          portfolioName: this.state.newSpecialtiesAdded,
          referedByName: this.state.referedByName,
          referedById: this.state.referedById,
          referedAgentName: this.state.addAgentName
            ? this.state.addAgentName
            : this.state.referedAgentName,
        },
      }
    );

    const removeCategory = this.state.oldCategories.filter(
      (removeItem) => !this.state.optionsSelected.includes(removeItem.label)
    );
    removeCategory &&
      removeCategory.map((removeWithId) =>
        deleteRecord("testimonials", removeWithId.id)
          .then((response) => {
            if (response.success) {
            } else {
              notification["error"]({
                message: response.message,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
      );
    if (data.entityData.email && data.entityData.firstName) {
      const updatingUserDetails = await update("users", id, data);
      if (updatingUserDetails.success) {
        if (updatingUserDetails.success) {
          this.setState({
            editing: false,
            saveDetailsFalse: false,
          });

          notification["success"]({
            message: "Your profile has been updated",
            // description: "Your profile details updated successfully",
            duration: 3,
          });
        }
      }
      const userProfileDetails = await getDetails("users", id);
      localStorage.removeItem("userDetails");
      localStorage.setItem(
        "userDetails",
        JSON.stringify(userProfileDetails.data)
      );
      this.props.context.setUserDetails(userProfileDetails.data);
    }
  };

  onSelect = (result) => {
    const address = result.label.split(",");
    let vara = this.state.cities.cities;
    vara.push(address[0]);
    this.setState({
      cities: {
        cities: vara,
        type: "cities",
      },
    });
  };

  handleChange = () => {
    if (this.state.Ziplist.includes(this.state.zip)) {
      notification["error"]({
        message:
          "You have already added " +
          this.state.zip +
          " zip code. Please add another",
      });
    } else {
      this.handleZipTowns(this.state.zip);
      const Ziplist = [...this.state.Ziplist];
      Ziplist.push(this.state.zip);
      this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    }
  };
  handleZipTowns = (address) => {
    try {
      geocodeByAddress(address)
        .then((results) => {
          let address = results[0].address_components;

          let agentTownArray =
            this.state.agentTowns.length > 0 ? this.state.agentTowns : [];
          let townInfo = {
            cityName: "",
            stateName: "",
            zipCode: "",
          };

          for (let i = 0; i < address.length; i++) {
            if (address[i].types[0] === "locality") {
              townInfo["cityName"] = address[i].long_name;
            }
            if (address[i].types[0] === "administrative_area_level_1") {
              townInfo["stateName"] = address[i].long_name;
            }
            if (address[i].types[0] === "postal_code") {
              townInfo["zipCode"] = address[i].long_name;
            }
          }
          agentTownArray.push(townInfo);
          this.setState({
            agentTowns: agentTownArray,
          });
        })
        .catch((error) => console.error("Error", error));
    } catch (error) {
      console.error("Error", error);
    }
  };

  handleDeselect = (value) => {
    this.handleDeselectZipTowns(value);
    const deleteItem = this.state.Ziplist.indexOf(value);
    let presentItems = this.state.Ziplist.splice(deleteItem, 1);
    this.setState({ zipCount: presentItems.length });
  };

  handleDeselectZipTowns = (value) => {
    let tempAgentTowns = this.state.agentTowns;
    let result = tempAgentTowns.filter(
      (agentTownInfo) => !agentTownInfo.zipCode.includes(value)
    );
    this.setState({
      agentTowns: result,
    });
  };

  handleDeselect = (value) => {
    const deleteItem = this.state.Ziplist.indexOf(value);
    let presentItems = this.state.Ziplist.splice(deleteItem, 1);
    // this.state.Ziplist.splice(deleteItem, 1);
    this.setState({ zipCount: presentItems.length });
  };

  setLoadingfalse = () => {
    this.setState({
      dataLoadingFalse: false,
    });
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.setState({
      showSubscriptions: true,
    });
  };

  handleSelect = (address) => {
    this.setState(
      {
        city: "",
        addressState: "",
        zipCode: "",
      },
      () => {
        setTimeout(() => {
          this.getFormattedAddress(address);
        }, 600);
      }
    );
  };
  getFormattedAddress = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        const geoIdData = results[0];
        this.setState({
          geoIdData,
        });
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }
        this.setState({
          bussinessCity: cityValue,
          bussinessState: stateValue,
          bussinessCompanyZipCode: zipCodeValue,
          companyAddress:
            cityValue +
            ", " +
            stateValue +
            (zipCodeValue && ", ") +
            zipCodeValue,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  handlePortFolioImages = (value) => {
    this.setState({
      optionsSelected: [...this.state.optionsSelected, value],
    });
    if (!this.state.optionsSelected.includes(value)) {
      this.setState({
        newSpecialtiesAdded: [...this.state.newSpecialtiesAdded, value],
      });
    }
  };
  deleteSpecificCategory = (value) => {
    const optionsSelected = this.state.optionsSelected.filter(
      (deleteItem) => deleteItem !== value
    );
    const newSpecialtiesAdded = this.state.newSpecialtiesAdded.filter(
      (removeNewItem) => removeNewItem !== value
    );
    this.setState({
      optionsSelected,
      newSpecialtiesAdded,
    });
  };
  onReferedBySelectAgent = (value, option) => {
    this.setState({
      referedAgentName: option.label,
    });
  };
  saveNewAgent = () => {
    const { addAgentName, addAgentEmail } = this.state;
    if (addAgentName !== "" && addAgentEmail !== "") {
      let agentsList = this.state.agentsList;
      agentsList.push({
        label: addAgentName,
        email: addAgentEmail,
        value: addAgentName,
      });
      this.setState({
        agentsList,
        savednewsAgentName: true,
      });
    }
  };

  handleAddUser = () => {
    this.setState({
      enableAdduser: true,
    });
  };

  render() {
    const {
      saveDetailsFalse,
      loadTotalData,
      oldCategories,
      showSubscriptions,
      enableAdduser,
      addAgentName,
      addAgentEmail,
      savednewsAgentName,
    } = this.state;
    const { userDetails } = this.props.context.state;
    // const Category = [
    //   // { label: "Lenders", value: "Lenders" },
    //   { label: "Attorney Real Estate", value: "Attorney Real Estate" },
    //   { label: "Attorney Family Law", value: "Attorney Family Law" },
    //   { label: "Smoke Detector", value: "Smoke Detector" },
    //   { label: "Paving", value: "Paving" },
    //   { label: "Carpenter", value: "Carpenter" },
    //   { label: "Handy Person", value: "Handy Person" },
    //   { label: "Window Treatment", value: "Window Treatment" },
    //   { label: "Painter", value: "Painter" },
    //   { label: "Cleaner", value: "Cleaner" },
    //   { label: "Attorney", value: "Attorney" },
    //   { label: "Insurance", value: "Insurance" },
    //   { label: "Solar", value: "Solar" },
    //   { label: "Pest Control", value: "Pest Control" },
    //   { label: "Home Inspector", value: "Home Inspector" },
    //   { label: "Trash Out", value: "Trash Out" },
    //   { label: "Dumpsters", value: "Dumpsters" },
    //   { label: "Kitchen Remodel", value: "Kitchen Remodel" },
    //   { label: "Moving Company", value: "Moving Company" },
    //   { label: "Bath Remodel", value: "Bath Remodel" },
    //   { label: "Heating & Cooling (HVAC)", value: "Heating & Cooling (HVAC)" },
    //   { label: "Oil Companies", value: "Oil Companies" },
    //   { label: "Fencing", value: "Fencing" },
    //   { label: "Architect", value: "Architect" },
    //   { label: "Builder", value: "Builder" },
    //   {
    //     label: "Concrete/Foundation Repair",
    //     value: "Concrete/Foundation Repair",
    //   },
    //   { label: "Roofing Repair/Replace", value: "Roofing Repair/Replace" },
    //   { label: "Windows/Doors", value: "Windows/Doors" },
    //   { label: "Flooring", value: "Flooring" },
    //   { label: "Closet Systems", value: "Closet Systems" },
    //   { label: "Deck Builder", value: "Deck Builder" },
    //   { label: "Credit Repair", value: "Credit Repair" },
    //   { label: "Granite Counters", value: "Granite Counters" },
    //   { label: "Asbestos Removal", value: "Asbestos Removal" },
    //   { label: "Lead Paint Removal", value: "Lead Paint Removal" },
    //   { label: "Plowing", value: "Plowing" },
    //   { label: "Interior", value: "Interior" },
    //   { label: "Decorator", value: "Decorator" },
    //   { label: "Tree Removal", value: "Tree Removal" },
    //   { label: "Title V inspector", value: "Title V inspector" },
    //   { label: "Power Washing", value: "Power Washing" },
    //   { label: "Mosquito Control", value: "Mosquito Control" },
    //   { label: "Marketing", value: "Marketing" },
    //   { label: "Other", value: "Other" },
    // ];
 
    const Category = [
      { label: "Mortgage Lendor", value: "Mortgage Lendor" },
      { label: "Private Lendor", value: "Private Lendor" },
      { label: "Attorney", value: "Attorney" },
      { label: "Home Inspector", value: "Home Inspector" },
      { label: "Insurance", value: "Insurance" },
      { label: "Contractor+", value: "Contractor+" },
      { label: "Plumber", value: "Plumber" },
      { label: "Electrician", value: "Electrician" },
      { label: "Landscaper", value: "Landscaper" },
      { label: "Junk Removal", value: "Junk Removal" },
      { label: "Mold Remediation", value: "Mold Remediation" },
      {
        label: "Lead Paint Testing or Remediation",
        value: "Lead Paint Testing or Remediation",
      },
      { label: "Concrete or Crack Repair", value: "Concrete or Crack Repair" },
      { label: "Other", value: "Other" },
    ];
    const opts = [
      { label: "New Construction", value: "New Construction" },
      { label: "FHA Loans", value: "FHA Loans" },
      { label: "Renovation Loans", value: "Renovation Loans" },
      { label: "Conventional loans", value: "Conventional loans" },
      { label: "First Time Buyers", value: "First Time Buyers" },
      { label: "Investment Loans", value: "Investment Loans" },
      { label: "1031 Exchanges", value: "1031 Exchanges" },
      { label: "Commercial Loans", value: "Commercial Loans" },
      { label: "USDA Loans", value: "USDA Loans" },
      { label: "VA Loans", value: "VA Loans" },
      { label: "Jumbo Loans", value: "Jumbo Loans" },
      { label: "Direct Lender", value: "Direct Lender" },
      { label: "Mass Housing", value: "Mass Housing" },
    ];
    return (
      <>
        {/* <Spin spinning={loadTotalData}> */}
        <Form
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          size="large"
        >
          {userDetails && (
            <Spin spinning={saveDetailsFalse}>
              <Row>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 11, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="label-titles">First Name</div>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Full Name!",
                      },
                    ]}
                    initialValue={
                      userDetails
                        ? userDetails.firstName
                          ? userDetails.firstName
                          : ""
                        : ""
                    }
                  >
                    <Input
                      placeholder="Business Contact Person"
                      className="form-input"
                    />
                  </Form.Item>

                  <div className="label-titles">Business Name:</div>
                  <Form.Item
                    name="BusinessName"
                    initialValue={
                      userDetails
                        ? userDetails.BusinessName
                          ? userDetails.BusinessName
                          : ""
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter your Business Name"
                      className="form-input"
                    />
                  </Form.Item>

                  <div className="label-titles">License/Trade IDs:</div>
                  <Form.Item
                    name="LicenseTradeIDs"
                    initialValue={
                      userDetails
                        ? userDetails.LicenseTradeIDs
                          ? userDetails.LicenseTradeIDs
                          : ""
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter your License/Trade IDs"
                      className="form-input"
                    />
                  </Form.Item>
                  <div className="label-titles">Business Phone:</div>
                  <Form.Item
                    name="phone"
                    initialValue={
                      userDetails
                        ? userDetails.phone
                          ? userDetails.phone
                          : ""
                        : ""
                    }
                  >
                    <InputNumber
                      placeholder="Enter your business phone number"
                      className="form-input-number"
                      formatter={(value) => {
                        const cleaned = `${value}`.replace(/\D/g, "");
                        const match = cleaned.match(
                          /^(1|)?(\d{3})(\d{3})(\d{4})$/
                        );
                        if (match) {
                          const intlCode = match[1] ? "+1 " : "";
                          return [
                            intlCode,
                            "(",
                            match[2],
                            ") ",
                            match[3],
                            "-",
                            match[4],
                          ].join("");
                        }
                        return null;
                      }}
                    />
                  </Form.Item>
                  <div className="label-titles">Referred by</div>
                  <Form.Item name="referedBy">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      options={this.state.allUsersList}
                      onSelect={this.onReferedBySelect}
                      placeholder="Referred by"
                      defaultValue={
                        userDetails &&
                        userDetails.referedByName &&
                        userDetails.referedByName !== ""
                          ? userDetails.referedByName
                          : ""
                      }
                      filterOption={(inputValue, option) =>
                        option.label
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    ></Select>
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 11, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="label-titles">Last Name</div>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Full Name!",
                      },
                    ]}
                    initialValue={
                      userDetails
                        ? userDetails.lastName
                          ? userDetails.lastName
                          : ""
                        : ""
                    }
                  >
                    <Input
                      placeholder="Business Contact Person"
                      value=""
                      className="form-input"
                    />
                  </Form.Item>
                  <div className="label-titles">Business Email:</div>
                  <Form.Item
                    name="email"
                    initialValue={
                      userDetails
                        ? userDetails.email
                          ? userDetails.email
                          : ""
                        : ""
                    }
                  >
                    <Input
                      readOnly={true}
                      placeholder="email"
                      className="form-input"
                    />
                  </Form.Item>

                  {/* <div className="label-titles">Category:</div>
                <Form.Item
                  name="Ca"
                  initialValue={
                    userDetails
                      ? userDetails.licenseIds
                        ? userDetails.licenseIds
                        : ""
                      : ""
                  }
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select Category "
                  >
                    {Category.map((province) => (
                      <Select.Option
                        key={province.value}
                        value={province.value}
                      >
                        {province.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}

                  <div className="label-titles">Website:</div>
                  <Form.Item
                    name="Website"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Website!",
                      },
                    ]}
                    initialValue={
                      userDetails
                        ? userDetails.Website
                          ? userDetails.Website
                          : ""
                        : ""
                    }
                  >
                    <Input
                      placeholder="Website"
                      value=""
                      className="form-input"
                    />
                  </Form.Item>
                  <div className="label-titles">Business Address:</div>
                  <Form.Item className="google-form-search">
                    <GooglePlacesAutocomplete
                      className="form-input"
                      apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        defaultInputValue: `${
                          userDetails && userDetails.bussinessAdress
                            ? userDetails.bussinessAdress
                            : ""
                        }`,
                        placeholder: "Search Address...",
                        onChange: (address) => {
                          this.handleSelect(address);
                        },
                      }}
                    />
                  </Form.Item>
                  <>
                    <div className="label-titles">Add Agent</div>
                    <Form.Item name="AddAgent">
                      <Select
                        className="input-width"
                        showSearch
                        optionFilterProp="children"
                        options={this.state.agentsList}
                        onSelect={this.onReferedBySelectAgent}
                        filterOption={(inputValue, option) =>
                          option.label
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        value={this.state.referedAgentName}
                      ></Select>
                      {!savednewsAgentName && (
                        <Button
                          type="primary"
                          onClick={
                            enableAdduser
                              ? this.saveNewAgent
                              : this.handleAddUser
                          }
                          className="add-user-width"
                          icon={<PlusOutlined />}
                        >
                          {enableAdduser ? "Save Agent" : "Add Agent"}
                        </Button>
                      )}
                    </Form.Item>
                    {enableAdduser && (
                      <>
                        <Row>
                          <Col span={11}>
                            <div className="label-titles">Agent Name</div>
                            <Form.Item
                              name="AgentName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Agent Name!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Agent Name"
                                type="name"
                                value={addAgentName}
                                onChange={(e) =>
                                  this.setState({
                                    addAgentName: e.target.value,
                                  })
                                }
                                className="form-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={11} offset={1}>
                            <div className="label-titles">Agent Email</div>
                            <Form.Item
                              name="AgentEmail"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Agent Email!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Agent Email"
                                type="email"
                                value={addAgentEmail}
                                onChange={(e) =>
                                  this.setState({
                                    addAgentEmail: e.target.value,
                                  })
                                }
                                className="form-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                </Col>
                {loadTotalData && (
                  <Col
                    xl={{ span: 23, offset: 1 }}
                    lg={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <div className="label-titles">Service Category:</div>
                    <Form.Item name="SelectCategory">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Category "
                        onSelect={this.handlePortFolioImages}
                        mode="multiple"
                        allowClear
                        // value={
                        //   oldCategories && oldCategories.length > 0
                        //     ? oldCategories.map(
                        //         (eachCategory) => eachCategory.portfolioName
                        //       )
                        //     : []
                        // }
                        onDeselect={this.deleteSpecificCategory}
                        defaultValue={
                          oldCategories &&
                          oldCategories.map((Category) => Category.label)
                        }
                      >
                        {userDetails &&
                          (userDetails.userProfile === "V" ||
                            userDetails.userProfile === "B" ||
                            userDetails.userProfile === "S") &&
                          Category.map((province) => (
                            <Select.Option
                              key={province.value}
                              value={province.value}
                            >
                              {province.label}
                            </Select.Option>
                          ))}
                        {userDetails &&
                          userDetails.userProfile === "L" &&
                          opts.map((province) => (
                            <Select.Option
                              key={province.value}
                              value={province.value}
                            >
                              {province.label}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <>
                    <Button
                      type="link"
                      onClick={this.handleLink}
                      style={{ marginLeft: "-15px" }}
                    >
                      Upgrade your subscription to add more zip codes and be
                      visible on the marketplace page.
                    </Button>
                    <div className="label-titles">
                      Zip codes serviced
                      {userDetails &&
                        userDetails.subscriptionPack &&
                        userDetails.subscriptionPack.zipcodes && (
                          <p
                            style={{
                              float: "right",
                              marginBottom: "0px",
                              color: "red",
                            }}
                          >
                            {userDetails.subscriptionPack.zipcodes -
                              this.state.Ziplist.length}{" "}
                            Zipcodes left
                          </p>
                        )}
                    </div>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Form.List
                        name="multiZipCodesList"
                        initialValue={this.state.zip}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            <Form.Item
                              name="zip"
                              rules={[
                                {
                                  required: this.state.handleZipErrorMsg,
                                  message: "Please input your Username!",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                value={this.state.zip}
                                onChange={this.handleZip}
                                className="form-input-profile"
                              />
                              {/* {userDetails &&
                                userDetails.subscriptionPack &&
                                userDetails.subscriptionPack.zipcodes &&
                                (userDetails.subscriptionPack.zipcodes ==
                                this.state.Ziplist.length ? (
                                  <Button
                                    type="default"
                                    onClick={this.handleLink}
                                    className="upgrade-add-btn"
                                    icon={<PlusOutlined />}
                                    style={{
                                      background: "red",
                                      color: "white",
                                    }}
                                  >
                                    <span
                                      className="upgrade-add-more"
                                      style={{ color: "white" }}
                                    >
                                      {" "}
                                      Upgrade to Add More
                                    </span>
                                  </Button>
                                ) : ( */}
                              <Button
                                htmlType="submit"
                                onClick={(e) => {
                                  add();
                                  this.handleChange();
                                  e.preventDefault();
                                }}
                                className="upgrade-add-zip"
                                style={{
                                  borderTopRightRadius: "5px",
                                  borderBottomRightRadius: "5px",
                                  float: "right",
                                  background: "#3045d2 ",
                                  border: "#3045d2",
                                  color: "white",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  height: "50px",
                                }}
                                icon={<PlusOutlined />}
                                disabled={
                                  (userDetails.subscriptionPack &&
                                    userDetails.subscriptionPack.zipcodes &&
                                    userDetails.subscriptionPack.zipcodes ===
                                      this.state.Ziplist.length) ||
                                  this.state.zip.length === 0
                                    ? true
                                    : false
                                }
                              >
                                Add Zipcode
                              </Button>
                              {/* ))} */}
                            </Form.Item>
                            <Row>
                              {this.state.Ziplist.map((field, index) => (
                                <Form.Item required={false} key={field.key}>
                                  <Input
                                    readOnly
                                    style={{
                                      width: "70%",
                                      marginRight: "-50px",
                                    }}
                                    value={field}
                                    addonAfter={
                                      this.state.Ziplist.length >= 1 ? (
                                        <CloseOutlined
                                          className="dynamic-delete-button"
                                          onClick={() => {
                                            remove(field);
                                            this.handleDeselect(field);
                                          }}
                                          style={{ color: "red" }}
                                        />
                                      ) : null
                                    }
                                    className="form-input"
                                  />
                                </Form.Item>
                              ))}
                            </Row>
                          </>
                        )}
                      </Form.List>
                    </Form>
                  </>
                </Col>
                {/* <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  
                </Col> */}
                <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div id="About" className="form-titles">
                    About Us{" "}
                  </div>
                  <Form.Item name="userBio">
                    <RichText
                      handleRichtextBio={this.handleRichtextBio}
                      richtTextBiofieldProp={
                        userDetails.AboutUs ? userDetails.AboutUs : ""
                      }
                    />
                  </Form.Item>
                </Col>
                {/* <Col
                xl={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Spin spinning={false}>
                  <div className="form-titles">Business Logo: </div>
                  <Form.Item>
                    <Dragger
                      name="file"
                      maxCount={5}
                      multiple
                      // onChange={this.uploadHomeImages}
                      listType="picture"
                      // fileList={fileList && fileList}
                      beforeUpload={() => false}
                    >
                      <>
                        <p className="ant-upload-drag-icon">
                          <img
                            src={uploadImg}
                            alt="upload"
                            width="50px"
                            height="33px"
                          />
                        </p>
                        <p className="ant-upload-text">Drag & Drop image</p>
                      </>
                    </Dragger>
                  </Form.Item>
                </Spin>
              </Col>
              <Col
                xl={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Spin spinning={false}>
                  <div className="form-titles">
                    Portfolio Images: (can upload upto 5 only){" "}
                  </div>
                  <Form.Item>
                    <Dragger
                      name="file"
                      maxCount={5}
                      multiple
                      // onChange={this.uploadHomeImages}
                      listType="picture"
                      // fileList={fileList && fileList}
                      beforeUpload={() => false}
                    >
                      <>
                        <p className="ant-upload-drag-icon">
                          <img
                            src={uploadImg}
                            alt="upload"
                            width="50px"
                            height="33px"
                          />
                        </p>
                        <p className="ant-upload-text">Drag & Drop image</p>
                      </>
                    </Dragger>
                  </Form.Item>
                </Spin>
              </Col> */}
                <Col
                  xl={{ span: 8, offset: 1 }}
                  lg={{ span: 8, offset: 1 }}
                  md={{ span: 8, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="update-btn"
                  >
                    Update Profile
                  </Button>
                </Col>
              </Row>
            </Spin>
          )}
        </Form>
        {/* </Spin> */}

        <Drawer
          title={"Upgrade to Add More"}
          placement="bottom"
          width={"100%"}
          height={"100%"}
          mask={true}
          closable={true}
          getContainer={false}
          visible={showSubscriptions}
          onClose={() =>
            this.setState({
              showSubscriptions: false,
            })
          }
          headerStyle={{ textAlign: "center" }}
        >
          <Row>
            <Col span={24}>
              <Subscriptions setLoadingfalse={this.setLoadingfalse} />
            </Col>
          </Row>
        </Drawer>
      </>
    );
  }
}
export default withAppContext(withRouter(VendorProfile));
